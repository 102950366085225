import React from 'react'
import ClienteComponent from '../../Components/Cliente/Cliente'

const ClientesView = () => {
  return (
    <div>
      <ClienteComponent/>
    </div>
  )
}

export default ClientesView   