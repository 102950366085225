import React, { useEffect, useState } from 'react';
import { GetGenerico } from '../../Services/GenericoService';
import { POST } from '../../Services/UsuariosRiesgososService';
import {
    REACT_APP_LISTAR_RISKY_DETECTIONS,
    REACT_APP_ACEPTAR_RIESGO_USUARIO_RIESGOSO,
    REACT_APP_TOMAR_ACCION_USUARIO_RIESGOSO,
    REACT_APP_CODIGO_RESPUESTA_200
} from "../../environment";
import swal from "sweetalert";
import ReactDOM from "react-dom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const $ = require("jquery");
$.DataTable = require("datatables.net-bs4");
/**
 * Obtener el Fondo seleccionado en iniciar sesion
 **/
const fondoSeleccionado = JSON.parse(localStorage.getItem("fondoSeleccionado"));
function UsuariosRiesgosos() {
    const [cargando, setCargando] = useState(false);
    const [botones, setBotones] = useState(false);
    const [mostrarBotonTomarAccion, setMostrarBotonTomarAccion] = useState(false);
    const [mostrarBotonAceptarRiesgo, setMostrarBotonAceptarRiesgo] = useState(false);
    const [userIdLista, setListaIds] = useState([]);
    /**
      * Definicion de las columnas 
      **/
    let columnas = [
        { data: 'userDisplayName', name: "userDisplayName" },
        {
            data: 'activityDateTime',
            render: function (jsonDate) {
                var date = new Date(jsonDate);
                return date.toLocaleString();
            },
            name: "activityDateTime"
        },
        { data: 'ipAddress', name: "ipAddress" },
        {
            data: 'location',
            render: function (jsonlocation) {
                var location = jsonlocation.countryOrRegion + " - " + jsonlocation.city + " - Latitud: " + jsonlocation.geoCoordinates.latitude + " - Longitud: " + jsonlocation.geoCoordinates.longitude;
                return location;
            },
            name: "location"
        },
        {
            data: 'riskDetail',
            render: function (jsonRiskDetail) {
                var riskDetail = "";
                switch (jsonRiskDetail) {
                    case 0:
                        riskDetail = "Ninguna";
                        break;
                    case 1:
                        riskDetail = "Contraseña temporal generada por el administrador";
                        break;
                    case 2:
                        riskDetail = "Cambio de contraseña seguro realizado por el usuario";
                        break;
                    case 3:
                        riskDetail = "El usuario realizó un restablecimiento de contraseña seguro";
                        break;
                    case 4:
                        riskDetail = "Administrador confirmado inicio de sesión seguro";
                        break;
                    case 5:
                        riskDetail = "Ai confirmó inicio de sesión seguro";
                        break;
                    case 6:
                        riskDetail = "El usuario pasó MFA impulsado por la política basada en riesgos";
                        break;
                    case 7:
                        riskDetail = "Administrador descartó todo riesgo para el usuario";
                        break;
                    case 8:
                        riskDetail = "Administrador de inicio de sesión confirmado comprometido";
                        break;
                    case 9:
                        riskDetail = "Oculto";
                        break;
                    case 10:
                        riskDetail = "Administrador usuario confirmado comprometido";
                        break;
                    case 11:
                        riskDetail = "Valor futuro desconocido";
                        break;
                    case 12:
                        riskDetail = "Detección de administrador descartado de m365D";
                        break;
                }
                return riskDetail;
            },
            name: "riskDetail"
        },
        {
            data: 'riskState',
            render: function (jsonRiskState) {
                var riskState = "";
                switch (jsonRiskState) {
                    case 0:
                        riskState = "Ninguna";
                        break;
                    case 1:
                        riskState = "ConfirmadoSeguro";
                        break;
                    case 2:
                        riskState = "Remediado";
                        break;
                    case 3:
                        riskState = "Despedido";
                        break;
                    case 4:
                        riskState = "En riesgo";
                        break;
                    case 5:
                        riskState = "ConfirmadoComprometido";
                        break;
                    case 6:
                        riskState = "Valor futuro desconocido";
                        break;
                }
                return riskState;
            },
            name: "riskState"
        },
        {
            data: 'activity',
            render: function (jsonActivity) {
                var activity = "";
                switch (jsonActivity) {
                    case 0:
                        activity = "Iniciar sesión";
                        break;
                    case 1:
                        activity = "Usuario";
                        break;
                    case 2:
                        activity = "Valor futuro desconocido";
                        break;
                }
                return activity;
            },
            name: "activity"
        },
    ];
    useEffect(() => {
        setCargando(true);
        GetGenerico(REACT_APP_LISTAR_RISKY_DETECTIONS).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Error", res.json.mensaje, "error");
                    setCargando(false);
                    return;
                }
                if (res.json.length > 0) setBotones(true);

                $('#table1').DataTable({
                    processing: true,
                    data: res.json,
                    language: {
                        url: "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json",
                    },
                    columns: columnas
                });
                setCargando(false);
                var lista = [];
                res.json.forEach((log) => {
                    lista.push(log.userId);
                });
                setListaIds(lista);
            }
        });
    }, []);
    /**
     * 
     * @param {any} evento
     */
    function AceptarRiesgoUsuarioRiesgoso(evento) {
        evento.preventDefault();

        if (userIdLista.length == 0) {
            swal(
                fondoSeleccionado.nombreFondo,
                "No hay usuarios seleccionados.",
                "warning", { button: "Aceptar", }
            );
            return;
        }

        let data = {
            B2CIds: userIdLista,
        };

        swal({
            title: "Estás seguro/segura?",
            text: "Desea aceptar el riesgo para los usuarios seleccionados",
            icon: "warning",
            buttons: ["NO", "SÍ"],
            dangerMode: true,
        }).then((willAcept) => {
            if (willAcept) {
                setMostrarBotonAceptarRiesgo(true);
                POST(REACT_APP_ACEPTAR_RIESGO_USUARIO_RIESGOSO, data).then((res) => {
                    if (res) {
                        if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                            swal(fondoSeleccionado.nombreFondo, "Ha ocurrido un error al aceptar el riesgo para los usuario seleccionados", "error", { button: "Aceptar", });
                            setMostrarBotonAceptarRiesgo(false);
                            return;
                        }
                        setMostrarBotonAceptarRiesgo(false);
                        swal(fondoSeleccionado.nombreFondo, "¡Usuarios aceptados con éxito!", "success", { button: "Aceptar", }).then((value) => {
                            setCargando(true);
                            GetGenerico(REACT_APP_LISTAR_RISKY_DETECTIONS).then((res) => {
                                if (res) {
                                    if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                                        swal("Error", res.json.mensaje, "error", { button: "Aceptar", });
                                        setCargando(false);
                                        return;
                                    }
                                    if (res.json.length > 0) setBotones(true);

                                    var table = $('#table1').DataTable();
                                    //clear datatable
                                    table.clear().draw();
                                    //destroy datatable
                                    table.destroy();

                                    $('#table1').DataTable({
                                        processing: true,
                                        data: res.json,
                                        language: {
                                            url: "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json",
                                        },
                                        columns: columnas
                                    });
                                    setCargando(false);
                                }
                            });
                        });
                    }
                });
            }
        });
    }

    /**
    * 
    * @param {any} evento
    */

    function TomarAccionUsuarioRiesgoso(evento) {
        evento.preventDefault();

        if (userIdLista.length == 0) {
            swal(
                fondoSeleccionado.nombreFondo,
                "No hay usuarios seleccionados.",
                "warning", { button: "Aceptar", }
            );
            return;
        }

        let data = {
            B2CIds: userIdLista,
        };

        swal({
            title: "Estás seguro/segura?",
            text: "Desea tomar acción para los usuarios seleccionados",
            icon: "warning",
            buttons: ["NO", "SÍ"],
            dangerMode: true,
        }).then((willAcept) => {
            if (willAcept) {
                setMostrarBotonTomarAccion(true);
                POST(REACT_APP_TOMAR_ACCION_USUARIO_RIESGOSO, data).then((res) => {
                    if (res) {
                        if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                            swal(fondoSeleccionado.nombreFondo, "Ha ocurrido un error al tomar acción para los usuario seleccionados", "error", { button: "Aceptar", });
                            setMostrarBotonTomarAccion(false);
                            return;
                        }
                        setMostrarBotonTomarAccion(false);
                        swal(fondoSeleccionado.nombreFondo, "¡Usuarios editados con éxito!", "success", { button: "Aceptar", }).then((value) => {
                            setCargando(true);
                            GetGenerico(REACT_APP_LISTAR_RISKY_DETECTIONS).then((res) => {
                                if (res) {
                                    if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                                        swal("Error", res.json.mensaje, "error", { button: "Aceptar", });
                                        setCargando(false);
                                        return;
                                    }
                                    if (res.json.length > 0) setBotones(true);

                                    var table = $('#table1').DataTable();
                                    //clear datatable
                                    table.clear().draw();
                                    //destroy datatable
                                    table.destroy();

                                    $('#table1').DataTable({
                                        processing: true,
                                        data: res.json,
                                        language: {
                                            url: "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json",
                                        },
                                        columns: columnas
                                    });
                                    setCargando(false);
                                }
                            });
                        });
                    }
                });
            }
        });
    }

    return (
        <div className="mx-5">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a href="/admin">Inicio</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        <a href="/auditoria">Menú Auditoría</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Usuarios Riesgosos
                    </li>
                </ol>
            </nav>
            <center>
                <h2>Usuarios Riesgosos</h2>
            </center>
            <div>
                <div className="container" style={{ padding: "0 0 40px 0" }}>
                    <div className="row justify-content-center" hidden={cargando} disabled={botones}>
                        <div className="form-inline center">
                            <button
                                type="submit"
                                className="btn btn-warning border-radius"
                                disabled={mostrarBotonTomarAccion || mostrarBotonAceptarRiesgo}
                                onClick={TomarAccionUsuarioRiesgoso}
                            >
                                Tomar Acción {" "} <i className="fa fa-check" aria-hidden="true"></i>
                                <i className="fa fa-refresh fa-spin fa-fw" hidden={!mostrarBotonTomarAccion}></i>
                                <span className="sr-only">Loading...</span>
                            </button>
                        </div>
                        {" "}
                        <div className="form-inline center">
                            <button
                                type="submit"
                                className="btn btn-danger border-radius"
                                disabled={mostrarBotonAceptarRiesgo || mostrarBotonTomarAccion}
                                onClick={AceptarRiesgoUsuarioRiesgoso}
                            >
                                Aceptar Riesgo {" "} <i className="fa fa-warning" aria-hidden="true"></i>
                                <i className="fa fa-refresh fa-spin fa-fw" hidden={!mostrarBotonAceptarRiesgo}></i>
                                <span className="sr-only">Loading...</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row" hidden={!cargando}>
                    <div class="col-sm-4">
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                        <Skeleton />
                        <Skeleton count={2} />
                    </div>
                    <div class="col-sm-4">
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                        <Skeleton />
                        <Skeleton count={2} />
                    </div>
                    <div class="col-sm-4">
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                        <Skeleton />
                        <Skeleton count={2} />
                    </div>
                </div>
                <table id="table1" class="table table-striped table-bordered" style={{ width: "100%" }} hidden={cargando}>
                    <thead>
                        <tr>
                            <th>Nombre Usuario</th>
                            <th>Fecha Actividad</th>
                            <th>Dirección IP</th>
                            <th>Ubicación</th>
                            <th>Detalle Riesgo</th>
                            <th>Estado Riesgo</th>
                            <th>Actividad</th>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
    );
}
export default UsuariosRiesgosos;