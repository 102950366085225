import {
    REACT_APP_CODIGO_RESPUESTA_200,
    REACT_APP_CODIGO_RESPUESTA_401
} from "../environment";
import { BorrarLocalStorage } from "../Utils/BorrarInfoLocalStorage";
import swal from "sweetalert";

export const obtenerUsuarioPorMiNombreUsuario = async () => {
    try {
        var idFondo = localStorage.getItem("idFondo");
        const respuesta = await fetch(
            `api/ObtenerUsuarioPorMiNombreUsuario?fondo=${idFondo}`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (respuesta.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
            }).then((value) => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        if (respuesta.status != REACT_APP_CODIGO_RESPUESTA_200) {
            var respuestaJson = await respuesta.json();;

            swal({
                title: "",
                text: respuestaJson == null || respuestaJson == undefined ? "Ha ocurrido un error al obtener el usuario por mi nombre" : respuestaJson.mensaje,
                icon: "error",
            }).then((value) => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        if (respuesta.ok) {
            return respuesta.json();
        } else {
            //return respuesta.text();
            var mensaje = await respuesta.text();
            var objetoMensaje = null;
            try {
                objetoMensaje = JSON.parse(mensaje);
            } catch (err) {
                throw new Error(mensaje);
            }
            var error = new Error();
            error.mensaje = objetoMensaje.mensaje;
            throw error;
        }
    } catch (error) {
        throw error;
    }
};

export const obtenerPayloadToken = (jwt) => {
    var base64Url = jwt.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
};

export const obtenerFondosAsignadosUsuario = async () => {
    try {
        const respuesta = await fetch(
            "/api/ObtenerFondosAsignadosUsuario?fondo=1",
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (respuesta.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then((value) => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        if (respuesta.status != REACT_APP_CODIGO_RESPUESTA_200) {
            swal({
                title: "SAAG",
                text: "Ha ocurrido un error al obtener los fondos asignados",
                icon: "error",
                button: "Aceptar",
            }).then((value) => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        if (respuesta.ok) {
            return respuesta.json();
        } else {
            //return respuesta.text();
            var mensaje = await respuesta.text();
            var objetoMensaje = null;
            try {
                objetoMensaje = JSON.parse(mensaje);
            } catch (err) {
                throw new Error(mensaje);
            }
            var error = new Error();
            error.mensaje = objetoMensaje.mensaje;
            throw error;
        }
    } catch (error) {
        throw error;
    }
};

export const obtenerFondosActivos = async () => {
    try {
        var idFondo = localStorage.getItem("idFondo");
        const respuesta = await fetch(
            `api/ObtenerFondosActivos?fondo=${idFondo}`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (respuesta.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then((value) => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        if (respuesta.status != REACT_APP_CODIGO_RESPUESTA_200) {
            var respuestaJson = await respuesta.json();;

            swal({
                title: "",
                text: respuestaJson == null || respuestaJson == undefined ? "Ha ocurrido un error al obtener los fondos activos" : respuestaJson.mensaje,
                icon: "error",
            }).then((value) => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        if (respuesta.ok) {
            return respuesta.json();
        } else {
            //return respuesta.text();
            var mensaje = await respuesta.text();
            var objetoMensaje = null;
            try {
                objetoMensaje = JSON.parse(mensaje);
            } catch (err) {
                throw new Error(mensaje);
            }
            var error = new Error();
            error.mensaje = objetoMensaje.mensaje;
            throw error;
        }
    } catch (error) {
        throw error;
    }
};

export const obtenerInformacionSesion = async () => {
    var respuesta = await fetch("/seguridad/sesion", {
        method: "GET",
        credentials: "include"
    });
    if (respuesta.status == 200) {
        var jsonRespuesta = await respuesta.json();
        return jsonRespuesta;
    } else {
        return false;
    }
}