import React from "react";
import ReporteLineaCreditoComponent from "../../Components/ReporteLineaCredito/ReporteLineaCredito";

const ReporteLineaCreditoView = () => {
  return (
    <div>
      <ReporteLineaCreditoComponent />
    </div>
  );
};

export default ReporteLineaCreditoView;
