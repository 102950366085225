import React, { useState } from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import swal from "sweetalert";
import Table from 'react-bootstrap/Table';
import { obtenerLogs } from '../../Services/BitacoraService';
import moment from "moment";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
/**
 * Obtener el Fondo seleccionado en iniciar sesion
 **/
const fondoSeleccionado = JSON.parse(localStorage.getItem("fondoSeleccionado"));
function VerBitacora() {

    const [cargando, setCargando] = useState(false);
    const [datosBusqueda, setDatosBusqueda] = useState({
        modulo: "UsuarioEntity",
        identificadorRegistro: "",
        idUsuario: "",
        nombreUsuario: "",
        correoUsuario: "",
        cedulaUsuario: "",
        fechaDesde: "",
        fechaHasta: ""
    });
    const [logs, setLogs] = useState(null);

    function eventoCambioValor(evento) {
        setDatosBusqueda({ ...datosBusqueda, [evento.target.name]: evento.target.value });
    }

    function eventoBuscar(evento) {
        evento.preventDefault();
        setCargando(true);
        obtenerLogs(datosBusqueda.modulo,
            datosBusqueda.identificadorRegistro,
            datosBusqueda.idUsuario,
            datosBusqueda.nombreUsuario,
            datosBusqueda.cedulaUsuario,
            datosBusqueda.correoUsuario,
            datosBusqueda.fechaDesde,
            datosBusqueda.fechaHasta
        ).then((datos) => {
            if (datos.logs.length == 0) {
                swal(
                    fondoSeleccionado.nombreFondo,
                    "No se encontraron resultados.",
                    "warning", { button: "Aceptar", }
                );
                setCargando(false);
                return;
            }

            datos.logs.forEach((log) => log.objetoJSON = JSON.parse(log.objetoJSON));
            setLogs(datos);
            setCargando(false);
        }).catch(() => {
            setLogs(null);
            setCargando(false)
        });
    }

    return <div className="mx-5">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="/admin">Inicio</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    <a href="/auditoria">Menú Auditoría</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    Bitácoras de auditoría
                </li>
            </ol>
        </nav>
        <center>
            <h2>Bitácoras de modificaciones</h2>
        </center>

        {/* Cuadro del formulario de busqueda */}
        <form className="row border rounded pt-3 pb-3" onSubmit={eventoBuscar}>
            {/* Campo de modulo */}
            <div className="col-md-4 col-lg-4 col-sm-12 mt-2">
                <label htmlFor="modulo" className="form-label ml-2">Módulo</label>
                <select id="modulo" name="modulo" className="form-control" disabled={cargando} value={datosBusqueda.modulo} onChange={eventoCambioValor}>
                    <option value="UsuarioEntity">Usuarios</option>
                    <option value="AvalEntity">Avales</option>
                    <option value="EntidadFinancieraAutorizadaEntity">Entidades Financieras</option>
                    <option value="HonramientoEntity">Honramientos</option>
                    <option value="ParametroEntity">Parametros</option>
                    <option value="ProgramaEntity">Programas</option>
                    <option value="ProgramaIndividualEntity">Programas Individuales</option>
                    <option value="ProgramaCarteraEntity">Programas de Cartera</option>
                    <option value="ProgramaCarteraPerdidaEsperadaEntity">Programas de Cartera Pérdida Esperada</option>
                </select>
            </div>

            {/* Campo de identificador del registro */}
            <div className="col-md-6 col-lg-6 col-sm-12 mt-2">
                <label htmlFor="identificadorRegistro" className="form-label ml-2">Identificador del registro</label>
                <input className="form-control" id="identificadorRegistro" name="identificadorRegistro" placeholder="Ejemplos: Id Entidad Financiera Autorizada, Id aval, Id Honramiento" title="Ejemplos: Id Entidad Financiera Autorizada, Id aval, Id Honramiento" disabled={cargando} value={datosBusqueda.identificadorRegistro} onChange={eventoCambioValor}></input>
            </div>

            {/* Boton de buscar */}
            <div className="col-md-2 col-lg-2 col-sm-12 text-center mt-2">
                {cargando && <button disabled className="btn btn-primary mt-4" style={{ width: "73px" }}><i className="fa fa-refresh fa-spin"></i></button>}
                {!cargando && <button type="submit" className="btn btn-primary mt-4" style={{ width: "73px" }}>Buscar</button>}
            </div>

            {/* Campo de identificacion del usuario */}
            <div className="col-md-3 col-lg-3 col-sm-12 mt-2">
                <label htmlFor="idUsuario" className="form-label ml-2">Identificador del usuario</label>
                <input className="form-control" id="idUsuario" name="idUsuario" disabled={cargando} placeholder="Id generado por el B2C al usuario" title="Id generado por el B2C al usuario" value={datosBusqueda.idUsuario} onChange={eventoCambioValor}></input>
            </div>

            {/* Nombre del usuario */}
            <div className="col-md-3 col-lg-3 col-sm-12 mt-2">
                <label htmlFor="nombreUsuario" className="form-label ml-2">Nombre completo del usuario</label>
                <input className="form-control" id="nombreUsuario" name="nombreUsuario" placeholder="Nombre completo del usuario" title="Nombre del usuario" value={datosBusqueda.nombreUsuario} disabled={cargando} onChange={eventoCambioValor}></input>
            </div>

            {/* Correo del usuario */}
            <div className="col-md-3 col-lg-3 col-sm-12 mt-2">
                <label htmlFor="correoUsuario" className="form-label ml-2">Correo del usuario</label>
                <input className="form-control" id="correoUsuario" name="correoUsuario" placeholder="Correo del usuario" title="Correo del usuario" value={datosBusqueda.correoUsuario} disabled={cargando} onChange={eventoCambioValor}></input>
            </div>

            {/* Cedula del usuario */}
            <div className="col-md-3 col-lg-3 col-sm-12 mt-2">
                <label htmlFor="cedulaUsuario" className="form-label ml-2">Cédula del usuario</label>
                <input className="form-control" id="cedulaUsuario" name="cedulaUsuario" placeholder="Cédula del usuario" title="Cédula del usuario" value={datosBusqueda.cedulaUsuario} disabled={cargando} onChange={eventoCambioValor}></input>
            </div>

            {/* Fecha desde */}
            <div className="col-md-6 col-lg-6 col-sm-12 mt-2">
                <label htmlFor="fechaDesde" className="form-label ml-2">Fecha desde</label>
                <input type="datetime-local" className="form-control" id="fechaDesde" name="fechaDesde" value={datosBusqueda.fechaDesde} disabled={cargando} onChange={eventoCambioValor}></input>
            </div>

            {/* Fecha hasta */}
            <div className="col-md-6 col-lg-6 col-sm-12 mt-2">
                <label htmlFor="fechaHasta" className="form-label ml-2">Fecha hasta</label>
                <input type="datetime-local" className="form-control" id="fechaHasta" name="fechaHasta" value={datosBusqueda.fechaHasta} disabled={cargando} onChange={eventoCambioValor}></input>
            </div>

        </form>

        {cargando &&
            <div class="row mt-2 mb-4">
                <div class="col-sm-6">
                    <Skeleton height={65} />
                </div>
                <div class="col-sm-6">
                    <Skeleton height={65} />
                </div>
            </div>
        }

        {!cargando && logs &&
            <div className="row pt-3">
                <Accordion className="w-100">
                    {
                        logs.logs.map((log, indexLogs) => {
                            return (<Card key={indexLogs}>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey={`${indexLogs}`}>
                                        {log.nombreUsuario} ({log.cedulaUsuario}) - {log.correoUsuario} | {moment(log.fechaEjecucion).locale("en").format("YYYY-MM-DD HH:mm:ss")}
                                    </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey={`${indexLogs}`}>
                                    <Card.Body>
                                        <Table striped bordered hover className="text-center">
                                            <thead>
                                                <tr>
                                                    <th className="text-left">Atributo</th>
                                                    <th className="text-left">Valor</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="text-left">Latitud</td>
                                                    <td className="text-left"><a href={`https://www.google.com/maps/place/${log.latitud},${log.longitud}`} target="_blank">{log.latitud}</a></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left">Longitud</td>
                                                    <td className="text-left"><a href={`https://www.google.com/maps/place/${log.latitud},${log.longitud}`} target="_blank">{log.longitud}</a></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left">IP</td>
                                                    <td className="text-left"><a href={`https://es.infobyip.com/ip-${log.ip}.html`} target="_blank">{log.ip}</a></td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left">User-Agent</td>
                                                    <td className="text-left">{log.userAgent}</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left">Id Usuario</td>
                                                    <td className="text-left">{log.idUsuario}</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left">Nombre Usuario</td>
                                                    <td className="text-left">{log.nombreUsuario}</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left">Cédula Usuario</td>
                                                    <td className="text-left">{log.cedulaUsuario}</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-left">Correo Usuario</td>
                                                    <td className="text-left">{log.correoUsuario}</td>
                                                </tr>
                                                {Object.keys(log.objetoJSON).map((key, indexKeys) => {
                                                    switch (typeof (log.objetoJSON[key])) {
                                                        case "number":
                                                        case "string": {
                                                            return (<tr key={`${key}-${indexKeys}`}>
                                                                <td className="text-left">{key}</td>
                                                                <td className="text-left">{log.objetoJSON[key]}</td>
                                                            </tr>)
                                                        }
                                                        case "object": {
                                                            var objeto1 = log.objetoJSON[key];
                                                            if (objeto1) {
                                                                return (Object.keys(objeto1).map((keyObjeto1, indexObject1Key) => {
                                                                    switch (typeof (objeto1[keyObjeto1])) {
                                                                        case "number":
                                                                        case "string": {
                                                                            return (<tr key={`${keyObjeto1}-${indexObject1Key}`}>
                                                                                <td className="text-left">{key} - {keyObjeto1}</td>
                                                                                <td className="text-left">{objeto1[keyObjeto1]}</td>
                                                                            </tr>)
                                                                        }
                                                                        case "object": {
                                                                            var objeto2 = objeto1[keyObjeto1];
                                                                            if (objeto2) {
                                                                                return (Object.keys(objeto2).map((keyObjeto2, indexObject2Key) => {
                                                                                    switch (typeof (objeto2[keyObjeto2])) {
                                                                                        case "number":
                                                                                        case "string": {
                                                                                            return (<tr key={`${keyObjeto2}-${indexObject2Key}`}>
                                                                                                <td className="text-left">{key} - {keyObjeto1} - {keyObjeto2}</td>
                                                                                                <td className="text-left">{objeto2[keyObjeto2]}</td>
                                                                                            </tr>)
                                                                                        }
                                                                                    }
                                                                                }))
                                                                            }
                                                                        }
                                                                    }
                                                                })
                                                                )
                                                            }
                                                        }
                                                    }
                                                })}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>)
                        })
                    }
                </Accordion>
            </div>
        }
    </div>;
}

export default VerBitacora;