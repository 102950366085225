import {
    obtenerUsuarioPorMiNombreUsuario,
    obtenerFondosAsignadosUsuario,
    obtenerInformacionSesion,
} from "../../Services/LoginService";
import { ObtenerParametro } from "../../Services/ParametroService";
import { EditarAceptarTerminosCondiciones } from "../../Services/UsuarioService";
import { ObtenerIp, ObtenerGeolocalizacion } from "../../Utils/BaseSolicitudModel";
import {
    REACT_APP_ESTADO_CLIENTE_SUSPENDIDO,
    REACT_APP_FECHA_ACEPTAR_TERMINOS_CONDICIONES,
    REACT_APP_TERMINOS_CONDICIONES,
    REACT_APP_CODIGO_RESPUESTA_200
} from "../../environment";
import swal from "sweetalert";
import { Modal, Col, Container, Row, Button, Form } from "react-bootstrap";
import React, { useEffect, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function LoginComponent() {
    const [cargando, setCargando] = useState(true);
    const [indButton, setButton] = useState(false);
    const [modal, setModal] = useState(false);
    const [checkTerminosCondiciones, setCheckTerminosCondiciones] = useState(false);
    const [terminosCondiciones, setTerminosCondiciones] = useState("");
    const [modalTerminosCondiciones, setModalTerminosCondiciones] = useState(false);
    const [fondosLista, setFondosLista] = useState([]);
    const [idUsuario, setIdUsuario] = useState("");
    ObtenerIp();
    /**
      * Validar que la geolocalicaion del navegador se encuentre activa
      **/
    ObtenerGeolocalizacion();

    useEffect(() => {
        obtenerInformacionSesion().then((datosSesion) => {
            if (datosSesion && datosSesion.existeSesion && datosSesion.existeToken) {

                obtenerFondosAsignadosUsuario().then((res) => {
                    if (res) {
                        localStorage.setItem("fondosAsignados", JSON.stringify(res.fondosEntidadesFinancieras));
                        if (res.fondosEntidadesFinancieras.length == 1) {
                            var obtenerEFAOperadora = res.fondosEntidadesFinancieras[0].entidadFinancieraAutorizada.caracteristicas.filter(function (efa) {
                                if (efa.fondo != null) {
                                    return (
                                        efa.fondo.id == res.fondosEntidadesFinancieras[0].fondo.id
                                    );
                                }
                            });
                            if (obtenerEFAOperadora.length > 0 && obtenerEFAOperadora[0].estado.id == REACT_APP_ESTADO_CLIENTE_SUSPENDIDO) {
                                swal("Autenticación", "La entidad financiera a la que pertenece el fondo seleccionado se encuentra en estado: " + obtenerEFAOperadora[0].estado.valor + ". Por favor comunicarse con el administrador", "warning", { button: "Aceptar", })
                                    .then(() => {
                                        localStorage.clear();
                                        window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                                    });
                                return;
                            }
                            localStorage.setItem("idFondo", res.fondosEntidadesFinancieras[0].fondo.id);
                            localStorage.setItem("fondoSeleccionado", JSON.stringify(res.fondosEntidadesFinancieras[0].fondo));

                            //terminos y condiciones
                            ObtenerParametro(REACT_APP_FECHA_ACEPTAR_TERMINOS_CONDICIONES).then((res) => {
                                if (res) {
                                    if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                                        swal("Parametro", "Ha ocurrido un error al obtener el parámetro para los términos y condiciones ", "error");
                                        return;
                                    }
                                    const fechaActual = new Date();
                                    var fechaValor = new Date(res.json.valor);
                                    var fechaTerminosCondiciones = new Date(fechaValor.getFullYear(), fechaValor.getMonth(), fechaValor.getDate() + 1);
                                    fechaActual.setHours(0, 0, 0, 0);
                                    fechaTerminosCondiciones.setHours(0, 0, 0, 0);
                                    if (fechaActual.getTime() == fechaTerminosCondiciones.getTime()) {
                                        //Validar si usuario ya acepto los terminos y condiciones
                                        obtenerUsuarioPorMiNombreUsuario().then((res) => {
                                            if (res) {
                                                let idFondo = localStorage.getItem("idFondo");

                                                var valorTerminos = res.usuario.permisos.filter(function (efa) {
                                                    if (efa.fondo != null) {
                                                        return (
                                                            efa.fondo.id == idFondo
                                                        );
                                                    }
                                                });

                                                if (valorTerminos[0].aceptaTerminosCondiciones == undefined || valorTerminos[0].aceptaTerminosCondiciones == null) {
                                                    swal({
                                                        title: "Autenticación",
                                                        text: "Ha ocurrido un error al obtener la información del usuario",
                                                        icon: "warning",
                                                        button: "Aceptar",
                                                        dangerMode: true
                                                    }).then(() => {
                                                        localStorage.clear();
                                                        window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                                                    });
                                                    return;
                                                }
                                                setIdUsuario(res.usuario.b2CId);
                                                if (!valorTerminos[0].aceptaTerminosCondiciones) {
                                                    ObtenerParametro(REACT_APP_TERMINOS_CONDICIONES).then((res) => {
                                                        if (res) {
                                                            if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                                                                swal("Parametro", "Ha ocurrido un error al obtener el párrafo para los términos y condiciones ", "error");
                                                                return;
                                                            }

                                                            setCargando(false);
                                                            setTerminosCondiciones(res.json.valor);
                                                            setModalTerminosCondiciones(true);
                                                        }
                                                    });
                                                } else {
                                                    setCargando(true);

                                                    asignarValoresUsuario();
                                                }
                                            } else {
                                                localStorage.clear();
                                                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                                            }
                                        }).catch((err) => {
                                            if (err && err.mensaje) {
                                                swal({
                                                    title: "Autenticación",
                                                    text: err.mensaje,
                                                    icon: "warning",
                                                    button: "Aceptar",
                                                    dangerMode: true
                                                }).then(() => {
                                                    localStorage.clear();
                                                    window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                                                });
                                            } else {
                                                localStorage.clear();
                                                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                                            }
                                        });
                                    } else {
                                        asignarValoresUsuario();
                                    }
                                }
                            });
                        } else if (res.fondosEntidadesFinancieras.length == 2) {
                            setCargando(false);
                            setFondosLista(res.fondosEntidadesFinancieras);
                            setModal(true);
                        }
                    }
                });
            } else {
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            }
        });
    }, []);

    if (cargando && (!modal || !modalTerminosCondiciones)) {
        return (
            <>
                <div class="jumbotron text-center">
                    <h1>Cargando información de seguridad...</h1>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                        <Skeleton />
                        <Skeleton count={5} />
                    </div>
                    <div class="col-sm-4">
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                        <Skeleton />
                        <Skeleton count={5} />
                    </div>
                    <div class="col-sm-4">
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                        <Skeleton />
                        <Skeleton count={5} />
                    </div>
                </div>
            </>
        );
    } else {
        const selecionarFondo = (data) => {
            setCargando(true);
            setModal(false);
            var obtenerEFAOperadora = data.entidadFinancieraAutorizada.caracteristicas.filter(function (efa) {
                if (efa.fondo != null) {
                    return (
                        efa.fondo.id == data.fondo.id
                    );
                }
            });

            if (obtenerEFAOperadora.length > 0 && obtenerEFAOperadora[0].estado.id == REACT_APP_ESTADO_CLIENTE_SUSPENDIDO) {
                swal("Autenticación", "La entidad financiera a la que pertenece el fondo seleccionado se encuentra en estado: " + obtenerEFAOperadora[0].estado.valor + ". Por favor comunicarse con el administrador", "warning", { button: "Aceptar", });
                return;
            }

            localStorage.setItem("idFondo", data.fondo.id);
            localStorage.setItem("fondoSeleccionado", JSON.stringify(data.fondo));

            //Terminos y condiciones
            ObtenerParametro(REACT_APP_FECHA_ACEPTAR_TERMINOS_CONDICIONES).then((res) => {
                if (res) {
                    if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                        swal("Parametro", "Ha ocurrido un error al obtener el parámetro para los términos y condiciones ", "error");
                        return;
                    }
                    const fechaActual = new Date();
                    var fechaValor = new Date(res.json.valor);
                    var fechaTerminosCondiciones = new Date(fechaValor.getFullYear(), fechaValor.getMonth(), fechaValor.getDate() + 1);
                    fechaActual.setHours(0, 0, 0, 0);
                    fechaTerminosCondiciones.setHours(0, 0, 0, 0);
                    if (fechaActual.getTime() == fechaTerminosCondiciones.getTime()) {

                        //Validar si usuario ya acepto los terminos y condiciones
                        obtenerUsuarioPorMiNombreUsuario().then((res) => {
                            if (res) {

                                let idFondo = localStorage.getItem("idFondo");

                                var valorTerminos = res.usuario.permisos.filter(function (efa) {
                                    if (efa.fondo != null) {
                                        return (
                                            efa.fondo.id == idFondo
                                        );
                                    }
                                });

                                if (valorTerminos[0].aceptaTerminosCondiciones == undefined || valorTerminos[0].aceptaTerminosCondiciones == null) {
                                    swal({
                                        title: "Autenticación",
                                        text: "Ha ocurrido un error al obtener la información del usuario",
                                        icon: "warning",
                                        button: "Aceptar",
                                        dangerMode: true
                                    }).then(() => {
                                        localStorage.clear();
                                        window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                                    });
                                    return;
                                }
                                setIdUsuario(res.usuario.b2CId);
                                if (!valorTerminos[0].aceptaTerminosCondiciones) {
                                    ObtenerParametro(REACT_APP_TERMINOS_CONDICIONES).then((res) => {
                                        if (res) {
                                            if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                                                swal("Parametro", "Ha ocurrido un error al obtener el párrafo para los términos y condiciones ", "error");
                                                return;
                                            }

                                            setTerminosCondiciones(res.json.valor);
                                            setCargando(false);
                                            setModalTerminosCondiciones(true);
                                        }
                                    });
                                } else {
                                    setCargando(true);

                                    asignarValoresUsuario();
                                }
                            } else {
                                localStorage.clear();
                                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                            }
                        }).catch((err) => {
                            if (err && err.mensaje) {
                                swal({
                                    title: "Autenticación",
                                    text: err.mensaje,
                                    icon: "warning",
                                    button: "Aceptar",
                                    dangerMode: true
                                }).then(() => {
                                    localStorage.clear();
                                    window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                                });
                            } else {
                                localStorage.clear();
                                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                            }
                        });
                    } else {
                        setCargando(false);

                        asignarValoresUsuario();
                    }
                }
            });
        }
        const encargarseCambio = (event) => {
            setCheckTerminosCondiciones(event.target.checked);
        };
        const irDashboard = () => {
            if (!checkTerminosCondiciones) {
                swal(
                    "SAAG",
                    "Para continuar debe de aceptar los términos y condiciones",
                    "warning", { button: "Aceptar", }
                );
                return;
            }
            var data = {
                IdUsuario: idUsuario,
                TerminosCondiciones: checkTerminosCondiciones,
            };
            setButton(true);
            EditarAceptarTerminosCondiciones(data).then((res) => {
                if (res) {
                    if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                        swal("SAAG", res.json.mensaje, "warning", { button: "Aceptar", });
                        setButton(false);
                        return;
                    }
                    setButton(false);
                    setModalTerminosCondiciones(false);
                    asignarValoresUsuario();
                } else {
                    localStorage.clear();
                    window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                }
            });


        }
        const cerrarModal = () => {
            setModal(false);
            localStorage.clear();
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        }
        const cerrarModalTerminosCondiciones = () => {
            setModalTerminosCondiciones(false);
            localStorage.clear();
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        }
        return (<>
            <Modal
                size="lg"
                onHide={cerrarModalTerminosCondiciones}
                show={modalTerminosCondiciones}
                backdrop="static"
                keyboard={false}
                centered
                animation={true}
            >
                <Modal.Header closeButton style={{ backgroundColor: "#fb8208" }}>
                    <Modal.Title style={{ color: "white" }}>Términos y Condiciones</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Form.Row>
                        <Col xs={12} md={12}>
                            <Form.Group>
                                <textarea
                                    style={{ resize: "none" }}
                                    className="form-control"
                                    minLength="6"
                                    maxLength="4000"
                                    value={terminosCondiciones}
                                    required
                                    rows="10"
                                    disabled
                                ></textarea>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Col xs={4} md={4}></Col>
                        <Col xs={4} md={4}>
                            <Form.Group>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="checkTerminosCondiciones"
                                    id="checkTerminosCondiciones"
                                    onChange={encargarseCambio}
                                    value={checkTerminosCondiciones}
                                    checked={checkTerminosCondiciones}
                                />
                                <label>Acepto los términos y condiciones</label>
                            </Form.Group>
                        </Col>
                        <Col xs={4} md={4}></Col>
                    </Form.Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        disabled={indButton}
                        onClick={() => cerrarModalTerminosCondiciones()}
                    >
                        Cerrar
                        {" "}
                        <i className="fa fa-times" aria-hidden="true"></i>
                    </Button>
                    <Button
                        variant="primary"
                        disabled={indButton || !checkTerminosCondiciones}
                        onClick={() => irDashboard()}
                    >
                        <div
                            hidden={!indButton}
                            className="spinner-grow spinner-grow-sm text-light"
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </div>
                        Aceptar
                        {" "}
                        <i className="fa fa-check" aria-hidden="true"></i>
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                size="lg"
                onHide={cerrarModal}
                show={modal}
                backdrop="static"
                keyboard={false}
                centered
                animation={true}
            >
                <Modal.Header closeButton style={{ backgroundColor: "#fb8208" }}>
                    <Modal.Title style={{ color: "white" }}>Seleccione el Fondo</Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            {fondosLista.map(function (data, key) {
                                return (
                                    <Col key={key} xs={12} md={6} className="p-3 border bg-light" onClick={() => selecionarFondo(data)} style={{ borderRadius: "30px", cursor: "pointer" }}>
                                        <center>
                                            <img className="p-3" src={data.fondo.urlLogo} alt="logo" />
                                            <h4>{data.fondo.nombreFondo}</h4>
                                            <p>
                                                {data.fondo.leyenda}
                                            </p>
                                        </center>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </>);
    }
}
function asignarValoresUsuario() {
    let idFondo = localStorage.getItem("idFondo");
    obtenerUsuarioPorMiNombreUsuario().then((res) => {
        if (res) {
            localStorage.setItem("permisos", JSON.stringify(res.usuario.permisos));
            localStorage.setItem("idCentroEmpresarial", res.usuario.idCentroEmpresarial);
            localStorage.setItem("usuarioLDAP", res.usuario.correoElectronico);
            localStorage.setItem("idUsuarioCreador", res.usuario.b2CId);

            if (res.usuario.permisos.length == 1) {
                localStorage.setItem("idRol", res.usuario.permisos[0].rol.id);
                localStorage.setItem("nombreRol", res.usuario.permisos[0].rol.nombreRol);
                localStorage.setItem("idEstadoUsuarioLogueado", res.usuario.permisos[0].estado.id);
                localStorage.setItem("estadoUsuarioLogueado", res.usuario.permisos[0].estado.valor);
                localStorage.setItem("idOperadora", res.usuario.permisos[0].entidadFinancieraAutorizada.id);
                localStorage.setItem("nombreOperadora", res.usuario.permisos[0].entidadFinancieraAutorizada.nombreOperadora);
            } else if (res.usuario.permisos.length == 2) {
                let fondoFodemipyme = res.usuario.permisos.filter(function (usuario) {
                    return (
                        usuario.fondo.id == 1
                    );
                });
                let fondoFna = res.usuario.permisos.filter(function (usuario) {
                    return (
                        usuario.fondo.id == 2
                    );
                });
                localStorage.setItem("idRol", idFondo == 1 ? fondoFodemipyme[0].rol.id : fondoFna[0].rol.id);
                localStorage.setItem("nombreRol", idFondo == 1 ? fondoFodemipyme[0].rol.nombreRol : fondoFna[0].rol.nombreRol);
                localStorage.setItem("idEstadoUsuarioLogueado", idFondo == 1 ? fondoFodemipyme[0].estado.id : fondoFna[0].estado.id);
                localStorage.setItem("estadoUsuarioLogueado", idFondo == 1 ? fondoFodemipyme[0].estado.valor : fondoFna[0].estado.valor);
                localStorage.setItem("idOperadora", idFondo == 1 ? fondoFodemipyme[0].entidadFinancieraAutorizada.id : fondoFna[0].entidadFinancieraAutorizada.id);
                localStorage.setItem("nombreOperadora", idFondo == 1 ? fondoFodemipyme[0].entidadFinancieraAutorizada.nombreOperadora : fondoFna[0].entidadFinancieraAutorizada.nombreOperadora);
            }

            localStorage.setItem("isLoggedIn", 1);
            window.location.href = "/admin";
        } else {
            localStorage.clear();
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        }
    }).catch((err) => {
        if (err && err.mensaje) {
            swal({
                title: "Autenticación",
                text: err.mensaje,
                icon: "warning",
                button: "Aceptar",
                dangerMode: true
            }).then(() => {
                localStorage.clear();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
        } else {
            localStorage.clear();
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        }
    });
}

export default LoginComponent;