import { REACT_APP_CODIGO_RESPUESTA_401, REACT_APP_CODIGO_RESPUESTA_200 } from "../environment";
import { BorrarLocalStorage } from "../Utils/BorrarInfoLocalStorage";
import swal from "sweetalert";
/**
 * Obtener el Fondo seleccionado en iniciar sesion
 **/
const fondoSeleccionado = JSON.parse(localStorage.getItem("fondoSeleccionado"));
export const GET = async (metodo, request) => {
    try {
        const respuesta = await fetch(
            `/api/${metodo}?fondo=${localStorage.getItem("idFondo")}&id=${request.IdUsuario}&nombreUsuario=${request.NombreUsuario}&correoUsuario=${request.CorreoUsuario}&cedulaUsuario=${request.CedulaUsuario}&desde=${request.FechaDesde}&hasta=${request.FechaHasta}`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (respuesta.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
            }).then(() => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        if (respuesta.status != REACT_APP_CODIGO_RESPUESTA_200) {
            try {
                var respuestaJson = await respuesta.json();
                if (respuestaJson && respuestaJson.mensaje) {
                    swal({
                        title: fondoSeleccionado.nombreFondo,
                        text: respuestaJson.mensaje,
                        icon: "error",
                    });
                } else {
                    swal({
                        title: fondoSeleccionado.nombreFondo,
                        text: "Ha ocurrido un error al obtener los ingresos por usuarios, no se obtuvo respuesta del servidor",
                        icon: "error",
                    });
                }
                return;
            } catch (e) {
                swal({
                    title: fondoSeleccionado.nombreFondo,
                    text: "Ha ocurrido un error al obtener los ingresos por usuarios",
                    icon: "error",
                });
                throw e;
            }
        }
        return respuesta.json();
    } catch (error) {
        throw error;
    }
};