import {
    REACT_APP_CODIGO_RESPUESTA_401
} from "../environment";
import {
    ObtenerUserAgent,
    ObtenerNombreUsuario,
} from "../Utils/BaseSolicitudModel";
import { BorrarLocalStorage } from "../Utils/BorrarInfoLocalStorage";
import swal from "sweetalert";

export const CrearProgramaCartera = async (request) => {
    let userAgent = ObtenerUserAgent();
    let nombreUsuario = ObtenerNombreUsuario();
    try {
        var data = {
            IP: localStorage.getItem("ip"),
            UserAgent: userAgent,
            NombreUsuario: nombreUsuario,
            Latitud: localStorage.getItem("latitud"),
            Longitud: localStorage.getItem("longitud"),
            IdFondo: localStorage.getItem("idFondo"),
            IdCliente: request.IdCliente,
            MontoProgramaAsignado: request.MontoProgramaAsignado,
            FechaAprobacion: request.FechaAprobacion,
            FechaInicio: request.FechaInicio,
            VigenciaPrograma: request.VigenciaPrograma,
            MontoMaximoAval: request.MontoMaximoAval,
            PorcentajeMaximoAvalCredito: request.PorcentajeMaximoAvalCredito,
            PorcentajeComisionFormalizacion: request.PorcentajeComisionFormalizacion,
            PorcentajeComisionAdministracion:
                request.PorcentajeComisionAdministracion,
            IdUsuarioCreador: request.IdUsuarioCreador,
            IndicadorConsumoWSRiesgo: request.IndicadorConsumoWSRiesgo,
            NombrePrograma: request.NombrePrograma
        };
        const guardarProgramaCartera = await fetch(
            `/api/CrearProgramaCartera?fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        if (guardarProgramaCartera.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then((value) => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const respuesta = {
            json: "",
            codigo: 0,
        };
        respuesta.json = await guardarProgramaCartera.json();
        respuesta.codigo = guardarProgramaCartera.status;

        return respuesta;
    } catch (error) {
        throw error;
    }
};

export const ModificarProgramaCartera = async (request) => {
    let userAgent = ObtenerUserAgent();
    let nombreUsuario = ObtenerNombreUsuario();
    try {
        var data = {
            IP: localStorage.getItem("ip"),
            UserAgent: userAgent,
            NombreUsuario: nombreUsuario,
            Latitud: localStorage.getItem("latitud"),
            Longitud: localStorage.getItem("longitud"),
            IdPrograma: request.IdPrograma,
            IdFondo: localStorage.getItem("idFondo"),
            IdCliente: request.IdCliente,
            IdEstado: request.IdEstado,
            ActaAprobacion: request.ActaAprobacion,
            MontoProgramaAsignado: request.MontoProgramaAsignado,
            FechaAprobacion: request.FechaAprobacion,
            FechaInicio: request.FechaInicio,
            VigenciaPrograma: request.VigenciaPrograma,
            FechaFinalizacion: request.FechaFinalizacion,
            MontoMaximoAval: request.MontoMaximoAval,
            PorcentajeMaximoAvalCredito: request.PorcentajeMaximoAvalCredito,
            PorcentajeComisionFormalizacion: request.PorcentajeComisionFormalizacion,
            PorcentajeComisionAdministracion:
                request.PorcentajeComisionAdministracion,
            IdUsuarioCreador: request.IdUsuarioCreador,
            IndicadorConsumoWSRiesgo: request.IndicadorConsumoWSRiesgo,
            NombrePrograma: request.NombrePrograma
        };
        const modificarProgramaCartera = await fetch(
            `/api/ModificarProgramaCarteraNormal?fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        if (modificarProgramaCartera.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then((value) => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const respuesta = {
            json: "",
            codigo: 0,
        };
        respuesta.json = await modificarProgramaCartera.json();
        respuesta.codigo = modificarProgramaCartera.status;

        return respuesta;
    } catch (error) {
        throw error;
    }
};