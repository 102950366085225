import React from "react";
import ReporteProvisionesComponent from "../../Components/ReporteProvisiones/ReporteProvisiones";

const ReporteProvisionesView = () => {
  return (
    <div>
      <ReporteProvisionesComponent />
    </div>
  );
};

export default ReporteProvisionesView;
