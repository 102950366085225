import { obtenerFondosAsignadosUsuario } from "./LoginService";
import { BorrarLocalStorage } from "../Utils/BorrarInfoLocalStorage";
import {
    REACT_APP_ESTADO_CLIENTE_SUSPENDIDO
} from "../environment";
import swal from "sweetalert";
export const ObtenerEstadoCliente = () => {
    const idfondo = localStorage.getItem("idFondo");
    if (!idfondo) {
        BorrarLocalStorage();
        window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        return;
    }
    obtenerFondosAsignadosUsuario().then((res) => {
        if (res) {
            const fondoSeleccionado = JSON.parse(localStorage.getItem("fondoSeleccionado"));
            var obtenerFondo = res.fondosEntidadesFinancieras.filter(function (fondo) {
                return (
                    fondo.fondo.id == idfondo
                );
            });
            var obtenerEFAOperadora = obtenerFondo[0].entidadFinancieraAutorizada.caracteristicas.filter(function (efa) {
                if (efa.fondo != null) {
                    return (
                        efa.fondo.id == idfondo
                    );
                }
            });
            if (obtenerEFAOperadora.length > 0 && obtenerEFAOperadora[0].estado.id == REACT_APP_ESTADO_CLIENTE_SUSPENDIDO) {
                swal({
                    title: fondoSeleccionado.nombreFondo,
                    text: "La entidad financiera a la que pertenece el fondo seleccionado se encuentra en estado: " + obtenerEFAOperadora[0].estado.valor + ". Por favor comunicarse con el administrador",
                    icon: "warning",
                    button: "Aceptar",
                    dangerMode: true,
                })
                    .then(() => {
                        BorrarLocalStorage();
                        window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                        return;
                    });
            }
        }
    });
}