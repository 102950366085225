import React from "react";
import swal from "sweetalert";
import {
    REACT_APP_CATALOGO_OBTENER_ESTADO_PROGRAMAS,
    REACT_APP_API_URL,
    REACT_APP_CODE,
    REACT_APP_PROGRAMA_VIGENCIA_FODEMIPYME,
    REACT_APP_PROGRAMA_VIGENCIA_FNA,
    REACT_APP_CODIGO_RESPUESTA_200,
    REACT_APP_CODIGO_RESPUESTA_401,
    REACT_APP_ESTADO_CLIENTE_ACTIVO,
    REACT_APP_ROL_ADMINISTRATIVO,
    REACT_APP_ROL_INCLUSION,
    REACT_APP_ROL_OPERATIVO,
    REACT_APP_ROL_JEFATURA,
    REACT_APP_ROL_CONTABILIDAD,
    REACT_APP_ROL_SEGUIMIENTO,
    REACT_APP_ESTADO_PROGRAMA_ACTIVO,
    REACT_APP_ESTADO_PROGRAMA_INACTIVO,
    REACT_APP_ESTADO_PROGRAMA_DESESTIMADO,
    REACT_APP_ESTADO_PROGRAMA_RECHAZADO,
    REACT_APP_OPERADORA_FODEMIPYME,
    REACT_APP_PUEDE_CALCULAR_MORA_LEGAL,
    REACT_APP_VIGENCIA_MAX_PROG_INDIVIDUAL,
    REACT_APP_OBTENER_PORCENTAJE_MORA_LEGAL
} from "../../environment";
import { ObtenerCatalogo } from "../../Services/GenericoService";
import { MoraLegal } from "../../Services/AvalService";
import { ModificarProgramaIndividual } from "../../Services/ProgramaIndividualService";
import { ObtenerParametro } from "../../Services/ParametroService";
import moment from "moment";
import { LoginEstilos } from "../../Views/Login/LoginEstilos.css";
import { BorrarLocalStorage } from "../../Utils/BorrarInfoLocalStorage";
import { ObtenerUserAgent } from "../../Utils/BaseSolicitudModel";
import NumberFormat from "react-number-format";

const $ = require("jquery");
$.DataTable = require("datatables.net-bs4");
/**
 * Obtener el token de sesion
 **/
const tokenContent = localStorage.getItem("isLoggedIn");
/**
 * Obtener el Fondo seleccionado en iniciar sesion
 **/
const fondoSeleccionado = JSON.parse(localStorage.getItem("fondoSeleccionado"));
/**
 * Obtener el ID del Fondo seleccionado en iniciar sesion
 **/
const idFondo = localStorage.getItem("idFondo");
/**
 * Valida si es posible obtener la mora legal
 **/
const calcularMoralLegal = localStorage.getItem("calcularMoralLegal");
class EditarProgramaIndividualComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            idProgramaIndividual: 0,
            clientes: [],
            clienteSeleccionado: "",
            montoProgramaAsignado: "",
            fechaAprobacion: moment().locale("en").format("YYYY-MM-DD"),
            fechaInicio: moment().locale("en").format("YYYY-MM-DD"),
            vigenciaPrograma: 0,
            montoMaximoAval: "",
            porcentajeMaximoAvalCredito: "",
            porcentajeComisionFormalizacion: "",
            porcentajeComisionAdministracion: "",
            estados: [],
            estadoSeleccionado: "",
            indButton: false,
            indFechaAprobacion: false,
            indFechaInicio: false,
            indRechazadoDesestimado: false,
            indEstados: false,
            indMoraLegal: false,
            moraLegal: "",
            indicadorConsumoWSRiesgo: true,
            nombrePrograma: "",
            vigenciaMaximaFODEMIPYME: 0,
            vigenciaMaximaFNA: 0
        };
    }
    /**
     * Llamado inmediatamente después de montar un componente. 
     * Establecer el estado aquí activará la re-renderización.
     **/
    componentDidMount() {
        $("#guardando").hide();
        $("#spinEFA").show();
        const tokenContent = localStorage.getItem("isLoggedIn");
        const infoEditar = sessionStorage.getItem("infoProgramaIndividualEditar");
        if (!tokenContent) window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        if (!infoEditar) window.location.href = "/programas-individuales";
        const userAgent = ObtenerUserAgent();
        const infoEditarJson = JSON.parse(infoEditar);
        const idRol = localStorage.getItem("idRol");
        if (idRol != REACT_APP_ROL_ADMINISTRATIVO) {
            this.state.indEstados = true;
            this.setState({ indEstados: true });
        }
        if (infoEditarJson != null) {
            var inputWSRiesgo = document.querySelector(
                'input[id="indicadorConsumoWSRiesgo"]'
            );
            this.setState({ id: infoEditarJson.id });
            this.setState({
                idProgramaIndividual: infoEditarJson.detallePrograma.id,
            });

            inputWSRiesgo.checked =
                infoEditarJson.detallePrograma.indicadorConsumoWSRiesgo;
            this.setState({
                indicadorConsumoWSRiesgo:
                    infoEditarJson.detallePrograma.indicadorConsumoWSRiesgo,
            });

            this.setState({ clienteSeleccionado: infoEditarJson.cliente });
            this.setState({
                montoProgramaAsignado:
                    infoEditarJson.detallePrograma.montoProgramaAsignado,
            });
            this.setState({
                fechaAprobacion: moment(infoEditarJson.detallePrograma.fechaAprobacion)
                    .locale("en")
                    .format("YYYY-MM-DD"),
            });
            this.setState({
                fechaInicio: moment(infoEditarJson.detallePrograma.fechaInicio)
                    .locale("en")
                    .format("YYYY-MM-DD"),
            });
            this.setState({
                vigenciaPrograma: infoEditarJson.detallePrograma.vigenciaPrograma,
            });
            this.setState({
                nombrePrograma: infoEditarJson.detallePrograma.nombrePrograma,
            });
            this.setState({
                montoMaximoAval: infoEditarJson.detallePrograma.montoMaximoAval,
            });
            this.setState({
                porcentajeMaximoAvalCredito:
                    infoEditarJson.detallePrograma.porcentajeMaximoAvalCredito,
            });
            this.setState({
                porcentajeComisionFormalizacion:
                    infoEditarJson.detallePrograma.porcentajeComisionFormalizacion,
            });
            this.setState({
                porcentajeComisionAdministracion:
                    infoEditarJson.detallePrograma.porcentajeComisionAdministracion,
            });
            this.setState({ estadoSeleccionado: infoEditarJson.estado });
            if (infoEditarJson.estado.id == REACT_APP_ESTADO_PROGRAMA_DESESTIMADO || infoEditarJson.estado.id == REACT_APP_ESTADO_PROGRAMA_RECHAZADO) {
                this.state.indRechazadoDesestimado = true;
                this.setState({ indRechazadoDesestimado: true });
            }
            if (idRol == REACT_APP_ROL_INCLUSION || idRol == REACT_APP_ROL_OPERATIVO || idRol == REACT_APP_ROL_JEFATURA || idRol == REACT_APP_ROL_CONTABILIDAD) {
                this.state.indRechazadoDesestimado = true;
                this.setState({ indRechazadoDesestimado: true });
            }
        }
        this.cargarEstado();
        /**
         * Creacion del DataTable, para obtener los clientes
         **/
        $("#tabla1").DataTable({
            processing: true,
            serverSide: true,
            pageLength: 50,
            ajax: {
                url: `/api/ObtenerClientes?fondo=${localStorage.getItem("idFondo")}&ip=${localStorage.getItem("ip")}&latitud=${localStorage.getItem("latitud")}&longitud=${localStorage.getItem("longitud")}&userAgent=${userAgent}&usuario=${localStorage.getItem("idUsuarioCreador")}`,
                error: function (xhr, error, thrown) {
                    if (xhr.status == REACT_APP_CODIGO_RESPUESTA_401) {
                        swal({
                            title: "¡Sesión Expiró!",
                            text: "Por favor vuelva a iniciar sesión",
                            icon: "warning",
                            button: "Aceptar",
                        }).then((value) => {
                            BorrarLocalStorage();
                            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                        }); $("#spinEFA").show();
                        return;
                    }
                    if (xhr.status != REACT_APP_CODIGO_RESPUESTA_200) {
                        swal("Error al obtener los clientes", "", "error", { button: "Aceptar", });
                        $("#spinEFA").show();
                        return;
                    }
                },
                success: (data) => {
                    if (data != null) {
                        $("#spinEFA").hide();
                        var filtroClientesActivos = [];
                        var idClienteSeleccionado = this.state.clienteSeleccionado.id;
                        filtroClientesActivos = data.data.filter(function (cliente) {
                            var caract = [];
                            caract = cliente.caracteristicas.filter(function (val) {
                                return (
                                    val.fondo.id == idFondo
                                );
                            });
                            return (
                                caract[0].estado.id == REACT_APP_ESTADO_CLIENTE_ACTIVO || caract[0].entidadFinancieraAutorizada.id == idClienteSeleccionado
                            );
                        });
                        this.setState({
                            clientes: filtroClientesActivos,
                        });
                        if (calcularMoralLegal == REACT_APP_PUEDE_CALCULAR_MORA_LEGAL) {
                            ObtenerParametro(REACT_APP_OBTENER_PORCENTAJE_MORA_LEGAL).then((res) => {
                                if (res) {
                                    if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                                        swal("Mora Legal", "Ha ocurrido un error al obtener el porcentaje máximo de mora legal permitido. Por favor recargue de nuevo la página o bien, comuníquese con el adminsitrador.", "error", { button: "Aceptar", });
                                        return;
                                    }
                                    localStorage.setItem("porcentajeMoralLegal", res.json.valor);
                                    this.obtenerMoraLegal();
                                }
                            });
                        } else {
                            $("#spinMoraLegal").hide();
                        }
                    }
                },
                type: "POST",
            },
            columns: [{ data: "id", name: "id" }],
        });
        this.obtenerParametroVigencia();
    }
    /**
   * Obtener la vigencia maxima del programa
   **/
    obtenerParametroVigencia = () => {
        ObtenerParametro(REACT_APP_VIGENCIA_MAX_PROG_INDIVIDUAL).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Programa Individual", "Ha ocurrido un error al obtener el parámetro para obtener la vigencia programa (meses). Por favor recargue de nuevo la página o bien, comuníquese con el adminsitrador.", "error", { button: "Aceptar", });
                    return;
                }
                if (idFondo == REACT_APP_OPERADORA_FODEMIPYME) {
                    this.state.vigenciaMaximaFODEMIPYME = parseInt(res.json.valor);
                    this.setState({ vigenciaMaximaFODEMIPYME: parseInt(res.json.valor) });
                } else {
                    this.state.vigenciaMaximaFNA = parseInt(res.json.valor);
                    this.setState({ vigenciaMaximaFNA: parseInt(res.json.valor) });
                }
            }
        });
    }
    /**
     * Obtener la moral legal por Entidad Financiera Autorizada
     **/
    obtenerMoraLegal = () => {
        $("#spinMoraLegal").show();
        var data = {
            IdEntidadFinanciera: this.state.clienteSeleccionado.id,
        };
        MoraLegal(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    try {
                        var textoError = res.codigo == 500 ? `Si se ha presentado un error inesperado, por favor enviar el siguiente código de error al administrador de FODEMIPYME : ${res.json.identificacionSolicitud}` : res.json.mensaje + ". Código de error: " + res.json.identificacionSolicitud;
                        if (res.json && res.json.mensaje) {
                            swal("Mora Legal", textoError, "error", { button: "Aceptar", });
                        } else {
                            swal("Mora Legal", "Ha ocurrido un error al obtener la Mora Legal, no se obtuvo respuesta del servidor." + " Código de error: " + res.json.identificacionSolicitud, "error", { button: "Aceptar", });
                        }
                        this.state.indMoraLegal = true;
                        this.setState({ indMoraLegal: true });
                        $("#spinMoraLegal").hide();
                        return;
                    } catch (e) {
                        swal("Mora Legal", "Ha ocurrido un error al obtener la Mora Legal" + " Código de error: " + res.json.identificacionSolicitud, "error", { button: "Aceptar", });
                        this.state.indMoraLegal = true;
                        this.setState({ indMoraLegal: true });
                        $("#spinMoraLegal").hide();
                        throw e;
                    }
                    return;
                }
                $("#spinMoraLegal").hide();
                const porcentajeMoralLegal = parseInt(localStorage.getItem("porcentajeMoralLegal"));
                this.state.indMoraLegal = false;
                this.setState({ indMoraLegal: false });
                const moraLegal = parseFloat(res.json.moraLegal);
                this.setState({ moraLegal: moraLegal.toFixed(2) });
                if (moraLegal.toFixed(2) > porcentajeMoralLegal) {
                    swal(
                        "Mora Legal",
                        "La mora legal excede lo permito para crear programas con la Entidad Financiera Autorizada seleccionada.",
                        "warning", { button: "Aceptar", }
                    );
                    this.state.indMoraLegal = true;
                    this.setState({ indMoraLegal: true });
                    return;
                }
            }
        });
    };
    /**
     * Cargar estados
     **/
    cargarEstado = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_ESTADO_PROGRAMAS,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Error", res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                if (this.state.estadoSeleccionado.id == REACT_APP_ESTADO_PROGRAMA_DESESTIMADO) {
                    var nuevoEstados = [];
                    nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                        return estado.id == REACT_APP_ESTADO_PROGRAMA_DESESTIMADO;
                    });
                    this.setState({
                        estados: nuevoEstados,
                    });
                    return;
                }
                if (this.state.estadoSeleccionado.id == REACT_APP_ESTADO_PROGRAMA_RECHAZADO) {
                    var nuevoEstados = [];
                    nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                        return estado.id == REACT_APP_ESTADO_PROGRAMA_RECHAZADO;
                    });
                    this.setState({
                        estados: nuevoEstados,
                    });
                    return;
                }
                if (this.state.estadoSeleccionado.id == REACT_APP_ESTADO_PROGRAMA_ACTIVO) {
                    var nuevoEstados = [];
                    nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                        return (
                            estado.id == REACT_APP_ESTADO_PROGRAMA_ACTIVO ||
                            estado.id == REACT_APP_ESTADO_PROGRAMA_INACTIVO ||
                            estado.id == REACT_APP_ESTADO_PROGRAMA_DESESTIMADO ||
                            estado.id == REACT_APP_ESTADO_PROGRAMA_RECHAZADO
                        );
                    });
                    this.setState({
                        estados: nuevoEstados,
                    });
                    return;
                }
                if (this.state.estadoSeleccionado.id == REACT_APP_ESTADO_PROGRAMA_INACTIVO) {
                    var nuevoEstados = [];
                    nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                        return estado.id == REACT_APP_ESTADO_PROGRAMA_ACTIVO || estado.id == REACT_APP_ESTADO_PROGRAMA_INACTIVO;
                    });
                    this.setState({
                        estados: nuevoEstados,
                    });
                    return;
                }
                this.setState({
                    estados: res.json.catalogoValores,
                });
            }
        });
    };
    /**
     * 
     * @param {*} event 
     * @returns Alamacena en el State lo digitado en el formulario. 
     **/
    encargarseCambio = (event) => {
        var preg = /^([0-9]+\.?[0-9]{0,2})$/;

        if (event.target.name === "fechaAprobacion") {
            this.setState({ indFechaAprobacion: true });
        }
        if (event.target.name === "fechaInicio") {
            this.setState({ indFechaInicio: true });
        }
        if (event.target.name === "indicadorConsumoWSRiesgo") {
            this.setState({ [event.target.name]: event.target.checked });
            return;
        }
        if (event.target.name === "montoProgramaAsignado") {
            const soloNumerosMontoProgramaAsignado = preg.test(
                event.target.value.replace(/,/g, "")
            );

            if (!soloNumerosMontoProgramaAsignado) {
                swal(
                    "Editar Programa Individual",
                    "El monto del programa asignado no tiene un formato válido",
                    "warning", { button: "Aceptar", }
                );
                var montoProgramaAsignadoFloat = parseFloat(event.target.value.replace(/,/g, "")).toFixed(2);
                this.setState({ montoProgramaAsignado: montoProgramaAsignadoFloat });
                $("#guardando").hide();
                return;
            }
        }
        if (event.target.name === "montoMaximoAval") {
            const soloNumerosMontoMaximoAval = preg.test(
                event.target.value.replace(/,/g, "")
            );

            if (!soloNumerosMontoMaximoAval) {
                swal(
                    "Editar Programa Individual",
                    "El monto máximo  del aval no tiene un formato válido",
                    "warning", { button: "Aceptar", }
                );
                var montoMaximoAvalFloat = parseFloat(event.target.value.replace(/,/g, "")).toFixed(2);
                this.setState({ montoMaximoAval: montoMaximoAvalFloat });
                $("#guardando").hide();
                return;
            }
        }
        if (event.target.name === "porcentajeMaximoAvalCredito") {
            const soloNumerosPorcentajeMaximoAvalCredito = preg.test(
                event.target.value.replace(/,/g, "")
            );

            if (!soloNumerosPorcentajeMaximoAvalCredito) {
                swal(
                    "Editar Programa Individual",
                    "El % máximo aval en cada crédito no tiene un formato válido",
                    "warning", { button: "Aceptar", }
                );
                var porcentajeMaximoAvalCreditoFloat = parseFloat(event.target.value.replace(/,/g, "")).toFixed(2);
                this.setState({ porcentajeMaximoAvalCredito: porcentajeMaximoAvalCreditoFloat });
                $("#guardando").hide();
                return;
            }
        }
        if (event.target.name === "porcentajeComisionAdministracion") {
            const soloNumerosPorcentajeComisionAdministracion = preg.test(
                event.target.value.replace(/,/g, "")
            );

            if (!soloNumerosPorcentajeComisionAdministracion) {
                swal(
                    "Editar Programa Individual",
                    "El % comisión de Administración no tiene un formato válido",
                    "warning", { button: "Aceptar", }
                );
                var porcentajeComisionAdministracionFloat = parseFloat(event.target.value.replace(/,/g, "")).toFixed(2);
                this.setState({ porcentajeComisionAdministracion: porcentajeComisionAdministracionFloat });
                $("#guardando").hide();
                return;
            }
        }
        if (event.target.name === "porcentajeComisionFormalizacion") {
            const soloNumerosPorcentajeComisionFormalizacion = preg.test(
                event.target.value.replace(/,/g, "")
            );

            if (!soloNumerosPorcentajeComisionFormalizacion) {
                swal(
                    "Editar Programa Individual",
                    "El % comisión de formalización no tiene un formato válido",
                    "warning", { button: "Aceptar", }
                );
                var porcentajeComisionFormalizacionFloat = parseFloat(event.target.value.replace(/,/g, "")).toFixed(2);
                this.setState({ porcentajeComisionFormalizacion: porcentajeComisionFormalizacionFloat });
                $("#guardando").hide();
                return;
            }
        }
        this.setState({ [event.target.name]: event.target.value });
    };
    /**
     * 
     * @param {*} event 
     * @returns Cambiar la Entidad Financiera seleccionada
     **/
    cambioOperadora = (event) => {
        let operadora = this.state.clientes.filter(
            (operadora) => operadora.id == event.target.value
        );
        this.state.clienteSeleccionado = operadora[0];
        this.setState({ clienteSeleccionado: operadora[0] });
        if (calcularMoralLegal == REACT_APP_PUEDE_CALCULAR_MORA_LEGAL) this.obtenerMoraLegal();
    };
    /**
     * 
     * @param {*} event 
     * @returns Cambiar estado seleccionado
     **/
    cambioEstado = (event) => {
        let estado = this.state.estados.filter((x) => x.id == event.target.value);
        this.setState({ estadoSeleccionado: estado[0] });
    };
    /**
     * 
     * @param {*} event 
     * @returns Modificacion de un programa 
     **/
    modificarPrograma = (event) => {
        event.preventDefault();
        let idFondo = localStorage.getItem("idFondo");
        var numeros = /^([0-9]+\.?[0-9]{0,2})$/;
        const soloNumerosMontoAsignado = numeros.test(
            Number(this.state.montoProgramaAsignado) ? this.state.montoProgramaAsignado : this.state.montoProgramaAsignado.replace(/,/g, "")
        );
        const soloNumerosMontoMaximo = numeros.test(
            Number(this.state.montoMaximoAval) ? this.state.montoMaximoAval : this.state.montoMaximoAval.replace(/,/g, "")
        );
        $("#guardando").show();
        if (this.state.nombrePrograma === "") {
            swal(
                "Editar Programa Individual",
                "El Nombre del Programa es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.nombrePrograma.length < 5) {
            swal(
                "Editar Programa Individual",
                "El Nombre del Programa debe contener mínimo 5 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.nombrePrograma.length > 100) {
            swal(
                "Editar Programa Individual",
                "El Nombre del Programa debe contener máximo 100 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.montoProgramaAsignado == "") {
            swal(
                "Editar Programa Individual",
                "El monto del programa asignado es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (!soloNumerosMontoAsignado) {
            swal(
                "Editar Programa Individual",
                "El monto del programa asignado no tiene un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.montoMaximoAval == "") {
            swal(
                "Editar Programa Individual",
                "El monto máximo del es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (!soloNumerosMontoMaximo) {
            swal(
                "Editar Programa Individual",
                "El monto máximo del aval no tiene un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.vigenciaPrograma < 0) {
            swal(
                "Editar Programa Individual",
                "La vigencia del programa no puede ser menor a 0",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (idFondo == REACT_APP_OPERADORA_FODEMIPYME) {
            if (this.state.vigenciaPrograma > this.state.vigenciaMaximaFODEMIPYME) {
                swal(
                    "Editar Programa Individual",
                    "La vigencia del programa no puede ser mayor a " + this.state.vigenciaMaximaFODEMIPYME,
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                return;
            }
        } else {
            if (this.state.vigenciaPrograma > this.state.vigenciaMaximaFNA) {
                swal(
                    "Editar Programa Individual",
                    "La vigencia del programa no puede ser mayor a " + this.state.vigenciaMaximaFNA,
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                return;
            }
        }
        if (this.state.porcentajeComisionAdministracion == "") {
            swal(
                "Crear Programa Individual",
                "El porcentaje de adminstración es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.porcentajeComisionFormalizacion == "") {
            swal(
                "Crear Programa Individual",
                "El porcentaje de formalización es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.porcentajeMaximoAvalCredito == "") {
            swal(
                "Crear Programa Individual",
                "El porcentaje de máximo aval en cada crédito es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.porcentajeComisionAdministracion.toString().length > 5) {
            swal(
                "Editar Programa Individual",
                "El porcentaje de adminstración debe de contener como máximo 4 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.porcentajeComisionFormalizacion.toString().length > 5) {
            swal(
                "Editar Programa Individual",
                "El porcentaje de formalización debe de contener como máximo 4 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.porcentajeMaximoAvalCredito > 75) {
            swal(
                "Editar Programa Individual",
                "El porcentaje de máximo aval en cada crédito no puede superar 75 %",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        let data = {
            IdPrograma: this.state.id,
            IdCliente: this.state.clienteSeleccionado.id,
            IdEstado: this.state.estadoSeleccionado.id,
            MontoProgramaAsignado: Number(this.state.montoProgramaAsignado)
                ? this.state.montoProgramaAsignado
                : parseFloat(this.state.montoProgramaAsignado.replace(/,/g, "")),
            FechaAprobacion: this.state.fechaAprobacion,
            FechaInicio: this.state.fechaInicio,
            VigenciaPrograma: parseInt(this.state.vigenciaPrograma),
            MontoMaximoAval: Number(this.state.montoMaximoAval)
                ? this.state.montoMaximoAval
                : parseFloat(this.state.montoMaximoAval.replace(/,/g, "")),
            PorcentajeMaximoAvalCredito: this.state.porcentajeMaximoAvalCredito,
            PorcentajeComisionFormalizacion: this.state
                .porcentajeComisionFormalizacion,
            PorcentajeComisionAdministracion: this.state
                .porcentajeComisionAdministracion,
            IndicadorConsumoWSRiesgo: this.state.indicadorConsumoWSRiesgo,
            NombrePrograma: this.state.nombrePrograma
        };

        let texto = "Este programa SI va a consumir el modelo de riesgo";
        if (!this.state.indicadorConsumoWSRiesgo) {
            texto = "Este programa NO va a consumir el modelo de riesgo";
        }
        swal({
            title: "Estás seguro/segura?",
            text: texto,
            icon: "warning",
            buttons: ["NO", "SÍ"],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.setState({ indButton: true });
                ModificarProgramaIndividual(data).then((res) => {
                    if (res) {
                        if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                            swal("Error", res.json.mensaje, "error", { button: "Aceptar", });
                            $("#guardando").hide();
                            this.setState({ indButton: false });
                            return;
                        }
                        swal(
                            "Programa Individual",
                            "¡Programa editado con éxito!",
                            "success", { button: "Aceptar", }
                        ).then((value) => {
                            window.location.href = "/programas-individuales";
                        });
                        $("#guardando").hide();
                        this.setState({ indButton: false });
                    }
                });
            } else {
                $("#guardando").hide();
            }
        });
    };
    render() {
        const idRol = localStorage.getItem("idRol");
        const idFondo = localStorage.getItem("idFondo");
        if (idRol) {
            if (idRol == REACT_APP_ROL_SEGUIMIENTO)
                window.location.href = "/programas-individuales";
        } else {
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        }
        return (
            <div className="container text-center">
                <table id="tabla1"></table>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="/admin">Inicio</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            <a href="/mantenimiento">Mantenimientos</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            <a href="/mantenimiento-programas">Mantenimiento Programas</a>
                        </li>
                        <li className="breadcrumb-item">
                            <a href="/programas-individuales">Programas Individuales</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Editar Programa Individual
                        </li>
                    </ol>
                </nav>
                <div className="row">
                    <form className="form-crear" onSubmit={this.modificarPrograma}>
                        <h1 className="h3 mb-3 font-weight-normal">
                            Editar Programa Individual:{" "}
                            <b># {this.state.idProgramaIndividual}</b>
                        </h1>
                        {calcularMoralLegal == REACT_APP_PUEDE_CALCULAR_MORA_LEGAL ? (
                            <div className="row form-padding">
                                <div className="col-md-3"></div>
                                <div className="col-md-6">
                                    <label
                                        className={this.state.indMoraLegal ? "mora-legal-red" : ""}
                                    >
                                        Mora Legal: {this.state.moraLegal}
                                    </label>
                                    <span className="input-group-addon" id="spinMoraLegal">
                                        <i className="fa fa-refresh fa-spin"></i>
                                    </span>
                                </div>
                                <div className="col-md-3">
                                    <button
                                        type="button"
                                        className="btn btn-success border-radius"
                                        disabled={this.state.indRechazadoDesestimado}
                                        onClick={this.obtenerMoraLegal}
                                    >
                                        <i className="fa fa-refresh"></i>
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className="row form-padding"></div>
                        )}

                        <div className="row form-padding">
                            <div className="col-md-3"></div>
                            <div className="col-md-6">
                                <label>Moneda: CRC</label>
                            </div>
                            <div className="col-md-3"></div>
                        </div>
                        <div className="row form-padding">
                            <div className="col-md-3"></div>
                            <div className="col-md-6">
                                <label className="form-check-label">
                                    ¿Consumir Modelo de Riesgo?
                                </label>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="indicadorConsumoWSRiesgo"
                                        id="indicadorConsumoWSRiesgo"
                                        onChange={this.encargarseCambio}
                                        value={this.state.indicadorConsumoWSRiesgo}
                                        disabled={this.state.indRechazadoDesestimado}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                        </div>
                        <hr></hr>
                        <div className="row form-padding">
                            <div className="col-md-6">
                                <label>Entidad Operadora</label>
                                <select
                                    className="form-control"
                                    onChange={this.cambioOperadora}
                                    value={this.state.clienteSeleccionado.id}
                                    disabled={this.state.indRechazadoDesestimado}
                                >
                                    {this.state.clientes.map(function (data, key) {
                                        return (
                                            <option key={key} value={data.id}>
                                                {data.nombreOperadora}
                                            </option>
                                        );
                                    })}
                                </select>
                                <span className="input-group-addon" id="spinEFA">
                                    <i className="fa fa-refresh fa-spin"></i>
                                </span>
                            </div>
                            <div className="col-md-6">
                                <label>Nombre Programa</label>
                                <input
                                    type="text"
                                    name="nombrePrograma"
                                    className="form-control"
                                    value={this.state.nombrePrograma}
                                    onChange={this.encargarseCambio}
                                    disabled={this.state.indRechazadoDesestimado}
                                    required
                                    placeholder="Nombre Programa"
                                />
                            </div>
                        </div>
                        <div className="row form-padding">
                            <div className="col-md-6">
                                <label>Monto del Programa Asignado</label>
                                <NumberFormat
                                    thousandSeparator={true}
                                    type="text"
                                    className="form-control"
                                    name="montoProgramaAsignado"
                                    value={this.state.montoProgramaAsignado}
                                    onChange={this.encargarseCambio}
                                    disabled={this.state.indRechazadoDesestimado}
                                    required
                                    placeholder="Monto del Programa Asignado"
                                />
                            </div>
                            <div className="col-md-6">
                                <label>Monto Máximo del Aval</label>
                                <NumberFormat
                                    thousandSeparator={true}
                                    type="text"
                                    className="form-control"
                                    name="montoMaximoAval"
                                    value={this.state.montoMaximoAval}
                                    onChange={this.encargarseCambio}
                                    disabled={this.state.indRechazadoDesestimado}
                                    required
                                    placeholder="Monto Máximo del Aval"
                                />
                            </div>
                        </div>
                        <div className="row form-padding">
                            <div className="col-md-6">
                                <label>Fecha de Aprobación</label>
                                <input
                                    type="date"
                                    name="fechaAprobacion"
                                    className="form-control"
                                    value={this.state.fechaAprobacion}
                                    onChange={this.encargarseCambio}
                                    disabled={this.state.indRechazadoDesestimado}
                                    required
                                />
                            </div>
                            <div className="col-md-6">
                                <label>Fecha de Inicio</label>
                                <input
                                    type="date"
                                    name="fechaInicio"
                                    className="form-control"
                                    value={this.state.fechaInicio}
                                    onChange={this.encargarseCambio}
                                    disabled={this.state.indRechazadoDesestimado}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row form-padding">
                            <div className="col-md-3"></div>
                            {idFondo == REACT_APP_OPERADORA_FODEMIPYME ? (
                                <div className="col-md-6">
                                    <label>Vigencia Programa (meses)</label>
                                    <input
                                        type="number"
                                        name="vigenciaPrograma"
                                        min="0"
                                        max={this.state.vigenciaMaximaFODEMIPYME}
                                        className="form-control"
                                        value={this.state.vigenciaPrograma}
                                        onChange={this.encargarseCambio}
                                        disabled={this.state.indRechazadoDesestimado}
                                        required
                                        placeholder="Vigencia Programa"
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                </div>
                            ) : (
                                <div className="col-md-6">
                                    <label>Vigencia Programa (meses)</label>
                                    <input
                                        type="number"
                                        name="vigenciaPrograma"
                                        min="0"
                                        max={this.state.vigenciaMaximaFNA}
                                        className="form-control"
                                        value={this.state.vigenciaPrograma}
                                        onChange={this.encargarseCambio}
                                        disabled={this.state.indRechazadoDesestimado}
                                        required
                                        placeholder="Vigencia Programa"
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                </div>

                            )}
                            <div className="col-md-3"></div>
                        </div>
                        <div className="row form-padding">
                            <div className="col-md-4">
                                <label>% Máximo Aval en Cada Crédito</label>
                                <input
                                    type="number"
                                    name="porcentajeMaximoAvalCredito"
                                    min="0"
                                    max="75"
                                    step="any"
                                    className="form-control"
                                    value={this.state.porcentajeMaximoAvalCredito}
                                    onChange={this.encargarseCambio}
                                    disabled={this.state.indRechazadoDesestimado}
                                    required
                                    placeholder="%"
                                />
                            </div>
                            <div className="col-md-4">
                                <label>% Comisión de Formalización</label>
                                <input
                                    type="number"
                                    name="porcentajeComisionFormalizacion"
                                    min="0"
                                    max="100"
                                    step="any"
                                    className="form-control"
                                    value={this.state.porcentajeComisionFormalizacion}
                                    onChange={this.encargarseCambio}
                                    disabled={this.state.indRechazadoDesestimado}
                                    required
                                    placeholder="%"
                                />
                            </div>
                            <div className="col-md-4">
                                <label>% Comisión de Administración</label>
                                <input
                                    type="number"
                                    name="porcentajeComisionAdministracion"
                                    min="0"
                                    max="100"
                                    step="any"
                                    className="form-control"
                                    value={this.state.porcentajeComisionAdministracion}
                                    onChange={this.encargarseCambio}
                                    disabled={this.state.indRechazadoDesestimado}
                                    required
                                    placeholder="%"
                                />
                            </div>
                        </div>
                        <div className="row form-padding">
                            <div className="col">
                                <label>Estado</label>
                                <select
                                    className="form-control"
                                    onChange={this.cambioEstado}
                                    value={this.state.estadoSeleccionado.id}
                                    disabled={
                                        this.state.indRechazadoDesestimado || this.state.indEstados
                                    }
                                >
                                    {this.state.estados.map(function (data, key) {
                                        return (
                                            <option key={key} value={data.id}>
                                                {data.valor}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="btn btn-primary border-radius"
                            disabled={this.state.indButton}
                            hidden={
                                this.state.indRechazadoDesestimado || this.state.indMoraLegal
                            }
                        >
                            <span
                                id="guardando"
                                className="spinner-grow spinner-grow-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            <span id="guardando" className="sr-only">
                                Loading...
                            </span>
                            Guardar
                            {" "}
                            <i className="fa fa-edit" aria-hidden="true"></i>
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}
export default EditarProgramaIndividualComponent;
