import React, { useState } from 'react';
import { GET } from '../../Services/HistoricoPorUsuarioService';
import {
    REACT_APP_LISTAR_EVENTOS_HISTORICOS_USUARIOS,
} from "../../environment";
import swal from "sweetalert";
import ReactDOM from "react-dom";
import moment from "moment";
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
// Import as a module in your JS
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const $ = require("jquery");
$.DataTable = require("datatables.net-bs4");
/**
 * Obtener el Fondo seleccionado en iniciar sesion
 **/
const fondoSeleccionado = JSON.parse(localStorage.getItem("fondoSeleccionado"));

function HistoricoEventosPorUsuario() {
    const [cargando, setCargando] = useState(false);
    const [mostrarTabla, setMostrarTabla] = useState(false);

    const [datosBusqueda, setDatosBusqueda] = useState({
        nombreUsuario: "",
        correoUsuario: "",
        cedulaUsuario: "",
        idUsuario: "",
        fechaDesde: "",
        fechaHasta: ""
    });

    function eventoCambioValor(evento) {
        setDatosBusqueda({ ...datosBusqueda, [evento.target.name]: evento.target.value });
    }
    function eventoBuscar(evento) {
        evento.preventDefault();

        if (datosBusqueda.fechaDesde == null || datosBusqueda.fechaDesde == "") {
            swal(
                fondoSeleccionado.nombreFondo,
                "La fecha desde es inválida.",
                "warning", { button: "Aceptar", }
            );
            return;
        }

        if (datosBusqueda.fechaHasta == null || datosBusqueda.fechaHasta == "") {
            swal(
                fondoSeleccionado.nombreFondo,
                "La fecha hasta es inválida.",
                "warning", { button: "Aceptar", }
            );
            return;
        }

        var fechaInicio = moment(datosBusqueda.fechaDesde);
        var fechaFin = moment(datosBusqueda.fechaHasta);

        if (fechaInicio > fechaFin) {
            swal(
                fondoSeleccionado.nombreFondo,
                "Rango de fechas inválido.",
                "warning", { button: "Aceptar", }
            );
            return;
        }

        setCargando(true);
        setMostrarTabla(false);
        var data = {
            IdUsuario: datosBusqueda.idUsuario,
            FechaDesde: datosBusqueda.fechaDesde,
            FechaHasta: datosBusqueda.fechaHasta,
            NombreUsuario: datosBusqueda.nombreUsuario,
            CedulaUsuario: datosBusqueda.cedulaUsuario,
            CorreoUsuario: datosBusqueda.correoUsuario,
        };

        GET(REACT_APP_LISTAR_EVENTOS_HISTORICOS_USUARIOS, data).then((res) => {
            if (res) {
                if (res.length == 0) {
                    swal(
                        fondoSeleccionado.nombreFondo,
                        "No se encontraron resultados.",
                        "warning", { button: "Aceptar", }
                    );
                    setCargando(false);
                    return;
                }

                setMostrarTabla(true);

                var table = $('#table2').DataTable();
                //clear datatable
                table.clear().draw();
                //destroy datatable
                table.destroy();

                $('#table2').DataTable({
                    processing: true,
                    data: res,
                    language: {
                        url: "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json",
                    },
                    columns: [
                        { data: 'category', name: "category" },
                        {
                            data: 'activityDateTime',
                            render: function (jsonDate) {
                                var date = new Date(jsonDate);
                                return date.toLocaleString();
                            },
                            name: "activityDateTime"
                        },
                        { data: 'activityDisplayName', name: "activityDisplayName" }
                    ]
                });
            }
            setCargando(false);
        }).catch(() => setCargando(false));
    }
    return (
        <div className="mx-5">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a href="/admin">Inicio</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        <a href="/auditoria">Menú Auditoría</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Histórico Eventos Por Usuario
                    </li>
                </ol>
            </nav>
            <table id="tabla2"></table>
            <center>
                <h2>Histórico Eventos Por Usuario</h2>
            </center>
            <form className="row border rounded pt-3 pb-3" onSubmit={eventoBuscar}>

                {/* Campo de identificacion del usuario */}
                <div className="col-md-6 col-lg-6 col-sm-12 mt-2">
                    <label htmlFor="idUsuario" className="form-label ml-2">Identificador del usuario</label>
                    <input className="form-control" id="idUsuario" name="idUsuario" placeholder="Id generado por el B2C al usuario" title="Id generado por el B2C al usuario" disabled={cargando} value={datosBusqueda.idUsuario} onChange={eventoCambioValor}></input>
                </div>

                {/* Boton de buscar */}
                <div className="col-md-6 col-lg-6 col-sm-12 text-center mt-2">
                    {cargando && <button disabled className="btn btn-primary mt-4" style={{ width: "73px" }}><i className="fa fa-refresh fa-spin"></i></button>}
                    {!cargando && <button type="submit" className="btn btn-primary mt-4" style={{ width: "73px" }}>Buscar</button>}
                </div>

                {/* Nombre del usuario */}
                <div className="col-md-4 col-lg-4 col-sm-12 mt-2">
                    <label htmlFor="nombreUsuario" className="form-label ml-2">Nombre completo del usuario</label>
                    <input className="form-control" id="nombreUsuario" name="nombreUsuario" placeholder="Nombre del usuario" title="Nombre del usuario" value={datosBusqueda.nombreUsuario} disabled={cargando} onChange={eventoCambioValor}></input>
                </div>

                {/* Correo del usuario */}
                <div className="col-md-4 col-lg-4 col-sm-12 mt-2">
                    <label htmlFor="correoUsuario" className="form-label ml-2">Correo del usuario</label>
                    <input className="form-control" id="correoUsuario" name="correoUsuario" placeholder="Correo del usuario" title="Correo del usuario" value={datosBusqueda.correoUsuario} disabled={cargando} onChange={eventoCambioValor}></input>
                </div>

                {/* Cedula del usuario */}
                <div className="col-md-4 col-lg-4 col-sm-12 mt-2">
                    <label htmlFor="cedulaUsuario" className="form-label ml-2">Cédula del usuario</label>
                    <input className="form-control" id="cedulaUsuario" name="cedulaUsuario" placeholder="Cédula del usuario" title="Cédula del usuario" value={datosBusqueda.cedulaUsuario} disabled={cargando} onChange={eventoCambioValor}></input>
                </div>

                {/* Fecha desde */}
                <div className="col-md-6 col-lg-6 col-sm-12 mt-2">
                    <label htmlFor="fechaDesde" className="form-label ml-2">Fecha desde</label>
                    <input type="datetime-local" className="form-control" id="fechaDesde" name="fechaDesde" value={datosBusqueda.fechaDesde} disabled={cargando} onChange={eventoCambioValor}></input>
                </div>

                {/* Fecha hasta */}
                <div className="col-md-6 col-lg-6 col-sm-12 mt-2">
                    <label htmlFor="fechaHasta" className="form-label ml-2">Fecha hasta</label>
                    <input type="datetime-local" className="form-control" id="fechaHasta" name="fechaHasta" value={datosBusqueda.fechaHasta} disabled={cargando} onChange={eventoCambioValor}></input>
                </div>
            </form>

            {cargando &&
                <div class="row mt-2 mb-4">
                    <div class="col-sm-6">
                        <Skeleton height={65} />
                    </div>
                    <div class="col-sm-6">
                        <Skeleton height={65} />
                    </div>
                </div>
            }
            {mostrarTabla &&
                <div className="mt-4 pt-3">
                    <table id="table2" class="table table-striped table-bordered" style={{ width: "100%" }}>
                        <thead>
                            <tr>
                                <th>Categoría</th>
                                <th>Fecha Actividad</th>
                                <th>Nombre Actividad</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            }
        </div>
    );
}

export default HistoricoEventosPorUsuario;