import React, { Component } from "react";
import {
    REACT_APP_API_URL,
    REACT_APP_CODE,
    REACT_APP_CODIGO_RESPUESTA_200,
    REACT_APP_CODIGO_RESPUESTA_401,
    REACT_APP_ROL_ADMINISTRATIVO,
    REACT_APP_ROL_CONTABILIDAD,
    REACT_APP_ROL_SEGUIMIENTO,
    REACT_APP_ROL_REPRESENTANTE_FODEMIPYME,
    REACT_APP_MAXIMO_DIAS_RANGO_FECHA_PERMITIDO_REPORTES
} from "../../environment";
import { GenerarReporteProvisionesExcel } from "../../Services/ReporteServices";
import swal from "sweetalert";
import { BorrarLocalStorage } from "../../Utils/BorrarInfoLocalStorage";
import { ObtenerUserAgent } from "../../Utils/BaseSolicitudModel";
import moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import jszip from "jszip";
window.jszip = jszip;
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const $ = require("jquery");

$.DataTable = require("datatables.net-bs4");
require("datatables.net-responsive");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.print");
const userAgent = ObtenerUserAgent();
export default class ReporteProvisionesComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fechaInicio: moment().locale("en").format("YYYY-MM-DD"),
            fechaFin: moment().locale("en").format("YYYY-MM-DD"),
            datatable: null,
            token: null,
            mostrarDescargarReporte: false,
        };
    }
    /**
     * Llamado inmediatamente después de montar un componente. 
     * Establecer el estado aquí activará la re-renderización.
     **/
    componentDidMount() {
        /**
         * Obtener el token de sesion
         **/
        const tokenContent = localStorage.getItem("isLoggedIn");
        if (!tokenContent) window.location.href = `https://${window.location.host}/seguridad/logout-b2c`
        this.setState({ token: tokenContent });
        /**
         * Creacion del DataTable
         **/
        $('#tabla tfoot th').each(function () {
            var title = $(this).text();
            $(this).html('<input type="text" class="columnaFiltroTabla" id="' + title + '" placeholder="Filtrar ' + title + '" />');
        });
        this.state.datatable = $("#tabla").DataTable({
            lengthChange: true,
            dom: "lBfrtip",
            processing: true,
            buttons: [],
            serverSide: true,
            language: {
                searchPlaceholder: "Buscar...",
                url: "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json",
            },
            initComplete: function () {
                // Apply the search
                this.api()
                    .columns()
                    .every(function () {
                        var that = this;
                        $('.columnaFiltroTabla', this.footer()).on('change clear', function () {
                            if (that.search() !== this.value) {
                                that.search(this.value).draw();
                            }
                        });

                        var table = $('#tabla').DataTable();
                        $(".dataTables_filter input")
                            .off()
                            .on('change clear', function (e) {
                                if (table.search() !== this.value) {
                                    table.search(this.value)
                                        .draw();
                                }
                            });
                    });
            },
            ajax: {
                url: `/api/ReporteProvisiones?fondo=${localStorage.getItem("idFondo")}&fechaInicio=${this.state.fechaInicio}&fechaFin=${this.state.fechaFin}&ip=${localStorage.getItem("ip")}&latitud=${localStorage.getItem("latitud")}&longitud=${localStorage.getItem("longitud")}&userAgent=${userAgent}&usuario=${localStorage.getItem("idUsuarioCreador")}`,
                error: function (xhr, error, thrown) {
                    if (xhr.status == REACT_APP_CODIGO_RESPUESTA_401) {
                        swal({
                            title: "¡Sesión Expiró!",
                            text: "Por favor vuelva a iniciar sesión",
                            icon: "warning",
                            button: "Aceptar",
                        }).then((value) => {
                            BorrarLocalStorage();
                            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`
                        });

                        return;
                    }
                    if (xhr.status != REACT_APP_CODIGO_RESPUESTA_200) {
                        swal(
                            "Error al obtener el reporte general de avales",
                            thrown,
                            "error", { button: "Aceptar", }
                        );
                    }
                },
                type: "POST",
            },
            columns: [
                {
                    data: "nombreEntidadFinancieraAutorizada",
                    name: "nombreEntidadFinancieraAutorizada",
                },
                {
                    data: "tipoPrograma",
                    name: "tipoPrograma",
                },
                {
                    data: "nombreBeneficiario",
                    name: "nombreBeneficiario",
                },
                {
                    data: "cedulaBeneficiario",
                    name: "cedulaBeneficiario",
                },
                {
                    data: "estado",
                    name: "estado",
                },
                { data: "moneda", name: "moneda" },
                {
                    data: "saldoAvalado",
                    render: function (saldoAvalado) {
                        return saldoAvalado.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    },
                    name: "saldoAvalado",
                },
                {
                    data: "diasAtraso",
                    name: "diasAtraso",
                },
                {
                    data: "montoEstimacion",
                    render: function (montoEstimacion) {
                        if (montoEstimacion != null) {
                            return montoEstimacion.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        } else {
                            return "-";
                        }
                    },
                    name: "montoEstimacion",
                },
                {
                    data: "fechaCreacion",
                    render: function (fechaCreacion) {
                        return moment(fechaCreacion)
                            .locale("en")
                            .format("YYYY-MM-DD");
                    },
                    name: "fechaCreacion",
                },
                {
                    data: "fechaFormalizacion",
                    render: function (fechaFormalizacion) {
                        if (fechaFormalizacion != null) {
                            return moment(fechaFormalizacion)
                                .locale("en")
                                .format("YYYY-MM-DD");
                        } else {
                            return "-";
                        }
                    },
                    name: "fechaFormalizacion",
                },
                {
                    data: "fechaHonramiento",
                    render: function (fechaHonramiento) {
                        if (fechaHonramiento != null) {
                            return moment(fechaHonramiento)
                                .locale("en")
                                .format("YYYY-MM-DD");
                        } else {
                            return "-";
                        }
                    },
                    name: "fechaHonramiento",
                },
            ],
        });
    }
    /**
    * 
    * @param {*} event 
    * @returns Alamacena en el State lo que digitado en el formulario. 
    **/
    encargarseCambio = (event) => {
        if (event.target.name === "fechaInicio") {
            this.state.fechaInicio = event.target.value
        }
        if (event.target.name === "fechaFin") {
            this.state.fechaFin = event.target.value
        }
        this.setState({ [event.target.name]: event.target.value });
    }
    /**
   * 
   * @param {*} event 
   * @returns Genera la informacion en el datatable. 
   **/
    buscarReporte = (event) => {
        event.preventDefault();
        if (this.state.fechaInicio == null || this.state.fechaInicio == "") {
            swal(
                "Reporte Provisiones",
                "La fecha de inicio es inválida",
                "error", { button: "Aceptar", }
            );
            return;
        }
        if (this.state.fechaFin == null || this.state.fechaFin == "") {
            swal(
                "Reporte Provisiones",
                "La fecha fin es inválida",
                "error", { button: "Aceptar", }
            );
            return;
        }
        var fechaInicio = moment(this.state.fechaInicio);
        var fechaFin = moment(this.state.fechaFin);

        if (fechaInicio > fechaFin) {
            swal(
                "Reporte Provisiones",
                "Rango de fechas inválido.",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        var diasDiferencia = fechaFin.diff(fechaInicio, 'days');
        if (diasDiferencia > REACT_APP_MAXIMO_DIAS_RANGO_FECHA_PERMITIDO_REPORTES) {
            swal(
                "Reporte Provisiones",
                "Está excediendo el  rango permitido de 3 meses de diferencia entre la fecha de inicio y fecha fin.",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        this.state.datatable.ajax.url(`/api/ReporteProvisiones?fondo=${localStorage.getItem("idFondo")}&fechaInicio=${this.state.fechaInicio}&fechaFin=${this.state.fechaFin}&ip=${localStorage.getItem("ip")}&latitud=${localStorage.getItem("latitud")}&longitud=${localStorage.getItem("longitud")}&userAgent=${userAgent}&usuario=${localStorage.getItem("idUsuarioCreador")}`).load();
    };
    /**
   * 
   * @param {*} event 
   * @returns Generar el documento de excel. 
   **/
    generarReporte = (event) => {
        var fechaInicio = moment(this.state.fechaInicio);
        var fechaFin = moment(this.state.fechaFin);

        if (fechaInicio > fechaFin) {
            swal(
                "Reporte Provisiones",
                "Rango de fechas inválido.",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        var diasDiferencia = fechaFin.diff(fechaInicio, 'days');
        if (diasDiferencia > REACT_APP_MAXIMO_DIAS_RANGO_FECHA_PERMITIDO_REPORTES) {
            swal(
                "Reporte Provisiones",
                "Está excediendo el  rango permitido de 3 meses de diferencia entre la fecha de inicio y fecha fin.",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        var data = {
            FechaInicio: this.state.fechaInicio,
            FechaFin: this.state.fechaFin,
            EntidadFinanciera: document.getElementById("Entidad Financiera Autorizada").value,
            Programa: document.getElementById("Programa").value,
            NombreBeneficiario: document.getElementById("Nombre Beneficiario").value,
            CedulaBeneficiario: document.getElementById("Cédula Beneficiario").value,
            EstadoCredito: document.getElementById("Estado Crédito").value,
            Moneda: document.getElementById("Moneda").value,
            SaldoAvalado: document.getElementById("Saldo Avalado").value,
            DiasAtraso: document.getElementById("Días de Atraso").value,
            MontoEstimacion: document.getElementById("Monto de Estimación").value,
            FechaCreacion: document.getElementById("Fecha Creación").value,
            FechaFormalizacion: document.getElementById("Fecha Formalización").value,
            FechaHonramiento: document.getElementById("Fecha Honramiento").value,
            SearchValue: document.getElementById("tabla_filter").children[0].children[0].value
        };
        GenerarReporteProvisionesExcel(data, (estado) => {
            switch (estado) {
                case 0:
                    this.setState({ mostrarDescargarReporte: true });
                    break;
                case 1:
                    this.setState({ mostrarDescargarReporte: false });
                    break;
                case 2:
                    this.setState({ mostrarDescargarReporte: false });
                    swal(
                        "Reporte provisiones",
                        "Ha ocurrrido un error inesperado, por favor intente de nuevo",
                        "error", { button: "Aceptar", }
                    );
                    break;
            }
        });
    };
    render() {
        const idRol = localStorage.getItem("idRol");
        if (idRol) {
            if (idRol != REACT_APP_ROL_ADMINISTRATIVO && idRol != REACT_APP_ROL_CONTABILIDAD && idRol != REACT_APP_ROL_SEGUIMIENTO && idRol != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME)
                window.location.href = "/mantenimiento-reportes";
        } else {
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`
        }
        switch (idRol) {
            //REACT_APP_ROL_ADMINISTRATIVO
            case "1":
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Reporte Provisiones</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/mantenimiento-reportes">Reportes</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Reporte Provisiones
                                </li>
                            </ol>
                        </nav>
                        <nav className="navbar navbar-light">
                            <button type="button" className="btn btn-success border-radius" onClick={this.generarReporte} disabled={this.state.mostrarDescargarReporte}>
                                <i className="fa fa-file-excel-o" aria-hidden="true"></i>{" "}
                                Exportar Excel {" "}
                                <i className="fa fa-refresh fa-spin fa-fw" hidden={!this.state.mostrarDescargarReporte}></i>
                                <span className="sr-only">Loading...</span>
                            </button>
                        </nav>
                        <div className="container" style={{ padding: "0 0 40px 0" }}>
                            <div className="row justify-content-center">
                                <div className="form-inline center">
                                    <div className="form-group" style={{ padding: "0 20px 0 0" }}>
                                        <label>Fecha Inicio</label>
                                        <input
                                            type="date"
                                            name="fechaInicio"
                                            id="fechaInicio"
                                            className="form-control"
                                            value={this.state.fechaInicio}
                                            disabled={this.state.mostrarDescargarReporte}
                                            onChange={this.encargarseCambio} />
                                    </div>
                                    <div className="form-group" style={{ padding: "0 20px 0 0" }}>
                                        <label>Fecha Fin</label>
                                        <input
                                            type="date"
                                            name="fechaFin"
                                            id="fechaFin"
                                            className="form-control"
                                            value={this.state.fechaFin}
                                            disabled={this.state.mostrarDescargarReporte}
                                            onChange={this.encargarseCambio} />
                                    </div>
                                    <button
                                        type="submit"
                                        className="btn btn-primary border-radius"
                                        disabled={this.state.mostrarDescargarReporte}
                                        onClick={this.buscarReporte}
                                    >
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Programa</th>
                                        <th>Nombre Beneficiario</th>
                                        <th>Cédula Beneficiario</th>
                                        <th>Estado Crédito</th>
                                        <th>Moneda</th>
                                        <th>Saldo Avalado</th>
                                        <th>Días de Atraso</th>
                                        <th>Monto de Estimación</th>
                                        <th>Fecha Creación</th>
                                        <th>Fecha Formalización</th>
                                        <th>Fecha Honramiento</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Programa</th>
                                        <th>Nombre Beneficiario</th>
                                        <th>Cédula Beneficiario</th>
                                        <th>Estado Crédito</th>
                                        <th>Moneda</th>
                                        <th>Saldo Avalado</th>
                                        <th>Días de Atraso</th>
                                        <th>Monto de Estimación</th>
                                        <th>Fecha Creación</th>
                                        <th>Fecha Formalización</th>
                                        <th>Fecha Honramiento</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                );
            //REACT_APP_ROL_CONTABILIDAD
            case "6": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Reporte Provisiones</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/mantenimiento-reportes">Reportes</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Reporte Provisiones
                                </li>
                            </ol>
                        </nav>
                        <nav className="navbar navbar-light">
                            <button type="button" className="btn btn-success border-radius" onClick={this.generarReporte} disabled={this.state.mostrarDescargarReporte}>
                                <i className="fa fa-file-excel-o" aria-hidden="true"></i>{" "}
                                Exportar Excel {" "}
                                <i className="fa fa-refresh fa-spin fa-fw" hidden={!this.state.mostrarDescargarReporte}></i>
                                <span className="sr-only">Loading...</span>
                            </button>
                        </nav>
                        <div className="container" style={{ padding: "0 0 40px 0" }}>
                            <div className="row justify-content-center">
                                <div className="form-inline center">
                                    <div className="form-group" style={{ padding: "0 20px 0 0" }}>
                                        <label>Fecha Inicio</label>
                                        <input
                                            type="date"
                                            name="fechaInicio"
                                            id="fechaInicio"
                                            className="form-control"
                                            value={this.state.fechaInicio}
                                            disabled={this.state.mostrarDescargarReporte}
                                            onChange={this.encargarseCambio} />
                                    </div>
                                    <div className="form-group" style={{ padding: "0 20px 0 0" }}>
                                        <label>Fecha Fin</label>
                                        <input
                                            type="date"
                                            name="fechaFin"
                                            id="fechaFin"
                                            className="form-control"
                                            value={this.state.fechaFin}
                                            disabled={this.state.mostrarDescargarReporte}
                                            onChange={this.encargarseCambio} />
                                    </div>
                                    <button
                                        type="submit"
                                        className="btn btn-primary border-radius"
                                        disabled={this.state.mostrarDescargarReporte}
                                        onClick={this.buscarReporte}
                                    >
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Programa</th>
                                        <th>Nombre Beneficiario</th>
                                        <th>Cédula Beneficiario</th>
                                        <th>Estado Crédito</th>
                                        <th>Moneda</th>
                                        <th>Saldo Avalado</th>
                                        <th>Días de Atraso</th>
                                        <th>Monto de Estimación</th>
                                        <th>Fecha Creación</th>
                                        <th>Fecha Formalización</th>
                                        <th>Fecha Honramiento</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Programa</th>
                                        <th>Nombre Beneficiario</th>
                                        <th>Cédula Beneficiario</th>
                                        <th>Estado Crédito</th>
                                        <th>Moneda</th>
                                        <th>Saldo Avalado</th>
                                        <th>Días de Atraso</th>
                                        <th>Monto de Estimación</th>
                                        <th>Fecha Creación</th>
                                        <th>Fecha Formalización</th>
                                        <th>Fecha Honramiento</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_SEGUIMIENTO
            case "7": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Reporte Provisiones</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/mantenimiento-reportes">Reportes</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Reporte Provisiones
                                </li>
                            </ol>
                        </nav>
                        <nav className="navbar navbar-light">
                            <button type="button" className="btn btn-success border-radius" onClick={this.generarReporte} disabled={this.state.mostrarDescargarReporte}>
                                <i className="fa fa-file-excel-o" aria-hidden="true"></i>{" "}
                                Exportar Excel {" "}
                                <i className="fa fa-refresh fa-spin fa-fw" hidden={!this.state.mostrarDescargarReporte}></i>
                                <span className="sr-only">Loading...</span>
                            </button>
                        </nav>
                        <div className="container" style={{ padding: "0 0 40px 0" }}>
                            <div className="row justify-content-center">
                                <div className="form-inline center">
                                    <div className="form-group" style={{ padding: "0 20px 0 0" }}>
                                        <label>Fecha Inicio</label>
                                        <input
                                            type="date"
                                            name="fechaInicio"
                                            id="fechaInicio"
                                            className="form-control"
                                            value={this.state.fechaInicio}
                                            disabled={this.state.mostrarDescargarReporte}
                                            onChange={this.encargarseCambio} />
                                    </div>
                                    <div className="form-group" style={{ padding: "0 20px 0 0" }}>
                                        <label>Fecha Fin</label>
                                        <input
                                            type="date"
                                            name="fechaFin"
                                            id="fechaFin"
                                            className="form-control"
                                            value={this.state.fechaFin}
                                            disabled={this.state.mostrarDescargarReporte}
                                            onChange={this.encargarseCambio} />
                                    </div>
                                    <button
                                        type="submit"
                                        className="btn btn-primary border-radius"
                                        disabled={this.state.mostrarDescargarReporte}
                                        onClick={this.buscarReporte}
                                    >
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Programa</th>
                                        <th>Nombre Beneficiario</th>
                                        <th>Cédula Beneficiario</th>
                                        <th>Estado Crédito</th>
                                        <th>Moneda</th>
                                        <th>Saldo Avalado</th>
                                        <th>Días de Atraso</th>
                                        <th>Monto de Estimación</th>
                                        <th>Fecha Creación</th>
                                        <th>Fecha Formalización</th>
                                        <th>Fecha Honramiento</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Programa</th>
                                        <th>Nombre Beneficiario</th>
                                        <th>Cédula Beneficiario</th>
                                        <th>Estado Crédito</th>
                                        <th>Moneda</th>
                                        <th>Saldo Avalado</th>
                                        <th>Días de Atraso</th>
                                        <th>Monto de Estimación</th>
                                        <th>Fecha Creación</th>
                                        <th>Fecha Formalización</th>
                                        <th>Fecha Honramiento</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_REPRESENTANTE_FODEMIPYME
            case "8":
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Reporte Provisiones</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/mantenimiento-reportes">Reportes</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Reporte Provisiones
                                </li>
                            </ol>
                        </nav>
                        <nav className="navbar navbar-light">
                            <button type="button" className="btn btn-success border-radius" onClick={this.generarReporte} disabled={this.state.mostrarDescargarReporte}>
                                <i className="fa fa-file-excel-o" aria-hidden="true"></i>{" "}
                                Exportar Excel {" "}
                                <i className="fa fa-refresh fa-spin fa-fw" hidden={!this.state.mostrarDescargarReporte}></i>
                                <span className="sr-only">Loading...</span>
                            </button>
                        </nav>
                        <div className="container" style={{ padding: "0 0 40px 0" }}>
                            <div className="row justify-content-center">
                                <div className="form-inline center">
                                    <div className="form-group" style={{ padding: "0 20px 0 0" }}>
                                        <label>Fecha Inicio</label>
                                        <input
                                            type="date"
                                            name="fechaInicio"
                                            id="fechaInicio"
                                            className="form-control"
                                            value={this.state.fechaInicio}
                                            disabled={this.state.mostrarDescargarReporte}
                                            onChange={this.encargarseCambio} />
                                    </div>
                                    <div className="form-group" style={{ padding: "0 20px 0 0" }}>
                                        <label>Fecha Fin</label>
                                        <input
                                            type="date"
                                            name="fechaFin"
                                            id="fechaFin"
                                            className="form-control"
                                            value={this.state.fechaFin}
                                            disabled={this.state.mostrarDescargarReporte}
                                            onChange={this.encargarseCambio} />
                                    </div>
                                    <button
                                        type="submit"
                                        className="btn btn-primary border-radius"
                                        disabled={this.state.mostrarDescargarReporte}
                                        onClick={this.buscarReporte}
                                    >
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Programa</th>
                                        <th>Nombre Beneficiario</th>
                                        <th>Cédula Beneficiario</th>
                                        <th>Estado Crédito</th>
                                        <th>Moneda</th>
                                        <th>Saldo Avalado</th>
                                        <th>Días de Atraso</th>
                                        <th>Monto de Estimación</th>
                                        <th>Fecha Creación</th>
                                        <th>Fecha Formalización</th>
                                        <th>Fecha Honramiento</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Programa</th>
                                        <th>Nombre Beneficiario</th>
                                        <th>Cédula Beneficiario</th>
                                        <th>Estado Crédito</th>
                                        <th>Moneda</th>
                                        <th>Saldo Avalado</th>
                                        <th>Días de Atraso</th>
                                        <th>Monto de Estimación</th>
                                        <th>Fecha Creación</th>
                                        <th>Fecha Formalización</th>
                                        <th>Fecha Honramiento</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                );
        }
    }
}
