import {
    REACT_APP_CODIGO_RESPUESTA_401
} from "../environment";
import { BorrarLocalStorage } from "../Utils/BorrarInfoLocalStorage";
import swal from "sweetalert";
export const GetGenerico = async (metodo) => {
    try {
        const obtenerInformacion = await fetch(
            `/api/${metodo}?fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (obtenerInformacion.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then(() => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const respuesta = {
            json: "",
            codigo: 0,
        };

        respuesta.json = await obtenerInformacion.json();
        respuesta.codigo = obtenerInformacion.status;
        return respuesta;
    } catch (error) {
        throw error;
    }
};
export const ObtenerCatalogo = async (request) => {
    var data = {
        idCatalogo: request.idCatalogo,
    };
    try {
        const obtenerInformacion = await fetch(
            `/api/ListarCatalogoValores?fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        if (obtenerInformacion.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then(() => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const respuesta = {
            json: "",
            codigo: 0,
        };

        respuesta.json = await obtenerInformacion.json();
        respuesta.codigo = obtenerInformacion.status;

        return respuesta;
    } catch (error) {
        throw error;
    }
};
export const DescargarArchivo = async (nombreArchivo) => {
    try {
        const respuesta = await fetch(
            `/api/ObtenerArchivo?nombreArchivo=${nombreArchivo}&fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (respuesta.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then(() => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const res = {
            json: "",
            codigo: 0,
        };

        res.json = await respuesta.text();
        window.location.assign(res.json);
        res.codigo = respuesta.status;
        return res;
    } catch (error) {
        throw error;
    }
};