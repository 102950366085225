import React from 'react'
import CrearProgramaCarteraComponent from '../../Components/ProgramaCartera/CrearProgramaCartera'

const CrearProgramaCarteraView = () => {
  return (
      <div>
        <CrearProgramaCarteraComponent/>
      </div>
  )
}

export default CrearProgramaCarteraView   