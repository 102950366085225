import React from 'react'
import EditarProgramaIndividualComponent from '../../Components/ProgramaIndividual/EditarProgramaIndividual'

const EditarProgramaIndividualView = () => {
  return (
      <div>
        <EditarProgramaIndividualComponent/>
      </div>
  )
}

export default EditarProgramaIndividualView   