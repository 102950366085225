import { REACT_APP_CODIGO_RESPUESTA_401 } from "../environment";
import { ObtenerUserAgent } from "../Utils/BaseSolicitudModel";
import { BorrarLocalStorage } from "../Utils/BorrarInfoLocalStorage";
import swal from "sweetalert";

export const CrearUsuario = async (request) => {
    let ip = localStorage.getItem("ip");
    let userAgent = ObtenerUserAgent();
    let latitud = localStorage.getItem("latitud");
    let longitud = localStorage.getItem("longitud");
    try {
        var data = {
            IP: ip,
            UserAgent: userAgent,
            Latitud: latitud,
            Longitud: longitud,
            NuevoNombreUsuario: request.NuevoNombreUsuario,
            NombrePersona: request.NombrePersona,
            ApellidoPersona: request.ApellidoPersona,
            CedulaPersona: request.CedulaPersona,
            CorreoElectronico: request.CorreoElectronico,
            IdEntidadFinancieraAutorizada: request.IdEntidadFinancieraAutorizada,
            IdRol: request.IdRol,
            IdUsuarioCreador: request.IdUsuarioCreador,
            IdTipoIdentificacionUsuario: request.IdTipoIdentificacionUsuario,
            IdCentroEmpresarial: request.IdCentroEmpresarial,
            EstadoCivil: request.EstadoCivil,
            GradoAcademico: request.GradoAcademico,
            Profesion: request.Profesion,
            Direccion: request.Direccion,
            IdFondos: request.IdFondos
        };
        const guardarUsuario = await fetch(
            `/api/CrearUsuario?fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        if (guardarUsuario.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
            }).then(() => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const respuesta = {
            json: "",
            codigo: 0,
        };
        respuesta.json = await guardarUsuario.json();
        respuesta.codigo = guardarUsuario.status;

        return respuesta;
    } catch (error) {
        throw error;
    }
};
export const ModificarUsuario = async (request) => {
    let ip = localStorage.getItem("ip");
    let userAgent = ObtenerUserAgent();
    let latitud = localStorage.getItem("latitud");
    let longitud = localStorage.getItem("longitud");
    try {
        var data = {
            IP: ip,
            UserAgent: userAgent,
            NombreUsuario: request.NombreUsuario,
            NombrePersona: request.NombrePersona,
            ApellidoPersona: request.ApellidoPersona,
            CedulaPersona: request.CedulaPersona,
            CorreoElectronico: request.CorreoElectronico,
            Latitud: latitud,
            Longitud: longitud,
            Id: request.Id,
            IdEntidadFinancieraAutorizada: request.IdEntidadFinancieraAutorizada,
            IdEstado: request.IdEstado,
            IdRol: request.IdRol,
            IdUsuario: request.IdUsuario,
            IdTipoIdentificacionUsuario: request.IdTipoIdentificacionUsuario,
            IdCentroEmpresarial: request.IdCentroEmpresarial,
            EstadoCivil: request.EstadoCivil,
            GradoAcademico: request.GradoAcademico,
            Profesion: request.Profesion,
            Direccion: request.Direccion,
            Permisos: request.Permisos
        };
        const modificarUsuario = await fetch(
            `/api/ModificarUsuario?fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        if (modificarUsuario.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
            }).then(() => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const respuesta = {
            json: "",
            codigo: 0,
        };
        respuesta.json = await modificarUsuario.json();
        respuesta.codigo = modificarUsuario.status;

        return respuesta;
    } catch (error) {
        throw error;
    }
};
export const ListarProvincias = async () => {
    try {
        const respuesta = await fetch(
            `/api/ListarSoloProvincias?fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (respuesta.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
            }).then(() => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
        }
        const res = {
            json: "",
            codigo: 0,
        };

        res.json = await respuesta.json();
        res.codigo = respuesta.status;
        return res;
    } catch (error) {
        throw error;
    }
};
export const ListarCentrosEmpresariales = async () => {
    try {
        const respuesta = await fetch(
            `/api/ListarCentrosEmpresariales?fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (respuesta.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
            }).then(() => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
        }
        const res = {
            json: "",
            codigo: 0,
        };

        res.json = await respuesta.json();
        res.codigo = respuesta.status;
        return res;
    } catch (error) {
        throw error;
    }
};
export const DesactivarMFAUsuario = async (request) => {
    try {
        const desactivarMFA = await fetch(
            `/api/DesactivarMFAUsuario?id=${request.Id}&fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (desactivarMFA.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
            }).then(() => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const respuesta = {
            codigo: 0,
        };
        respuesta.codigo = desactivarMFA.status;

        return respuesta;
    } catch (error) {
        throw error;
    }
};
export const ListarIngresosPorUsuario = async (request) => {
    try {

        const respuesta = await fetch(
            `/api/ListarIngresosPorUsuario?fondo=${localStorage.getItem("idFondo")}&id=${request.Id}`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (respuesta.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
            }).then(() => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
        }
        const res = {
            json: "",
            codigo: 0,
        };

        res.json = await respuesta.json();
        res.codigo = respuesta.status;
        return res;
    } catch (error) {
        throw error;
    }
};
export const ListarEventosUsuario = async (request) => {
    try {

        const respuesta = await fetch(
            `/api/ListarEventosUsuario?fondo=${localStorage.getItem("idFondo")}&id=${request.Id}`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (respuesta.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
            }).then(() => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
        }
        const res = {
            json: "",
            codigo: 0,
        };

        res.json = await respuesta.json();
        res.codigo = respuesta.status;
        return res;
    } catch (error) {
        throw error;
    }
};
export const ReestablecerClaveUsuario = async (request) => {
    try {
        const desactivarMFA = await fetch(
            `/api/ReestablecerClaveUsuario?id=${request.Id}&fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (desactivarMFA.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
            }).then(() => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const respuesta = {
            codigo: 0,
        };
        respuesta.codigo = desactivarMFA.status;

        return respuesta;
    } catch (error) {
        throw error;
    }
};
export const EditarAceptarTerminosCondiciones = async (request) => {

    try {
        var data = {
            IdUsuario: request.IdUsuario,
            TerminosCondiciones: request.TerminosCondiciones
        };
        const respuesta = await fetch(
            `/api/EditarAceptarTerminosCondiciones?fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        if (respuesta.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then((value) => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const res = {
            json: "",
            codigo: 0,
        };

        res.json = await respuesta.json();
        res.codigo = respuesta.status;
        return res;
    } catch (error) {
        throw error;
    }
};