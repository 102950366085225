import React from 'react'
import CrearProgramaCarteraPEComponent from '../../Components/ProgramaCarteraPE/CrearProgramaCarteraPE'

const CrearProgramaCarteraPEView = () => {
  return (
      <div>
        <CrearProgramaCarteraPEComponent/>
      </div>
  )
}

export default CrearProgramaCarteraPEView   