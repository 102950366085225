import React, { Component } from "react";
import {
    REACT_APP_ROL_OPERATIVO,
    REACT_APP_ROL_CONTABILIDAD
} from "../../environment";
class MantenimientoComponent extends Component {
    componentDidMount() {
        const tokenContent = localStorage.getItem("isLoggedIn");
        if (!tokenContent) window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
    }
    irMantenimientoUsuarios = () => {
        window.location.href = "/usuarios";
    };
    irMantenimientoClientes = () => {
        window.location.href = "/clientes";
    };
    irMantenimientoProgramas = () => {
        window.location.href = "/mantenimiento-programas";
    };
    irMantenimientoParametros = () => {
        window.location.href = "/mantenimiento-parametros";
    };
    render() {
        const idRol = localStorage.getItem("idRol");
        if (idRol) {
            if (idRol == REACT_APP_ROL_OPERATIVO)
                window.location.href = "/admin";
        } else {
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        }
        switch (idRol) {
            //REACT_APP_ROL_ADMINISTRATIVO
            case "1": {
                return (
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Mantenimientos
                                </li>
                            </ol>
                        </nav>
                        <div className="row">
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irMantenimientoUsuarios}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-user"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Usuarios</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irMantenimientoClientes}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-users"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Clientes</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irMantenimientoProgramas}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-fw fa-product-hunt"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Programas</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irMantenimientoParametros}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-fw fa-cog"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Par&aacute;metros</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_EJECUTIVO
            case "2": {
                return (
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Mantenimientos
                                </li>
                            </ol>
                        </nav>
                        <div className="row">
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irMantenimientoClientes}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-users"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Clientes</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irMantenimientoProgramas}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-fw fa-product-hunt"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Programas</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_INCLUSION
            case "3": {
                return (
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Mantenimientos
                                </li>
                            </ol>
                        </nav>
                        <div className="row">
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irMantenimientoProgramas}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-fw fa-product-hunt"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Programas</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_JEFATURA
            case "5": {
                return (
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Mantenimientos
                                </li>
                            </ol>
                        </nav>
                        <div className="row">
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irMantenimientoUsuarios}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-user"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Usuarios</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_CONTABILIDAD
            case "6": {
                return (
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Mantenimientos
                                </li>
                            </ol>
                        </nav>
                        <div className="row">
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irMantenimientoUsuarios}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-user"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Usuarios</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irMantenimientoProgramas}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-fw fa-product-hunt"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Programas</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_SEGUIMIENTO
            case "7": {
                return (
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Mantenimientos
                                </li>
                            </ol>
                        </nav>
                        <div className="row">
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irMantenimientoUsuarios}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-user"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Usuarios</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irMantenimientoClientes}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-users"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Clientes</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irMantenimientoProgramas}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-fw fa-product-hunt"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Programas</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_REPRESENTANTE_FODEMIPYME
            case "8": {
                return (
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Mantenimientos
                                </li>
                            </ol>
                        </nav>
                        <div className="row">
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irMantenimientoClientes}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-users"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Clientes</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irMantenimientoProgramas}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-fw fa-product-hunt"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Programas</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_AUDITOR
            case "9": {
                return (
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Mantenimientos
                                </li>
                            </ol>
                        </nav>
                        <div className="row">
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irMantenimientoUsuarios}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-user"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Usuarios</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }
}
export default MantenimientoComponent;