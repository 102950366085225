import React from 'react'

const MainLayout = ({children}) => {
  return (
    <div>
      <main>{children}</main>
    </div>
  )
}

export default MainLayout