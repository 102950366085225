import React from 'react'
import EditarProgramaCarteraPEComponent from '../../Components/ProgramaCarteraPE/EditarProgramaCarteraPE'

const EditarProgramaCarteraPEView = () => {
  return (
      <div>
        <EditarProgramaCarteraPEComponent/>
      </div>
  )
}

export default EditarProgramaCarteraPEView   