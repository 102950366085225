import React from "react";
import LoginComponent from "../../Components/Login/Login";
import { LoginEstilos } from "./LoginEstilos.css";
const Login = () => {
  return (
    <div className="container text-center padding-top">
      <LoginComponent />
    </div>
  );
};

export default Login;
