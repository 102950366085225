import React from 'react'
import ReporteComponent from '../../Components/Reporte/Reporte'

const ReportesView = () => {
  return (
      <div>
          <ReporteComponent/>
      </div>
  )
}

export default ReportesView   