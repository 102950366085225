import React from 'react'
import EditarUsuarioComponent from '../../Components/Usuario/EditarUsuario'

const EditarUsuarioView = () => {
  return (
      <div>
          <EditarUsuarioComponent/>
      </div>
  )
}

export default EditarUsuarioView   