import React from "react";
import {
    Container,
    Button,
    Accordion,
    Card,
    Tabs,
    Tab,
    Row,
    Col,
    Form,
} from "react-bootstrap";
import swal from "sweetalert";
import moment from "moment";
import {
    REACT_APP_CATALOGO_OBTENER_TIPOS_PROGRAMAS,
    REACT_APP_CATALOGO_OBTENER_TIPOS_MONEDA,
    REACT_APP_CATALOGO_OBTENER_TIPOS_IDENTIFICACION,
    REACT_APP_CATALOGO_OBTENER_TIPOS_SECTO_ECONOMICO,
    REACT_APP_CATALOGO_OBTENER_TIPOS_NIVELES,
    REACT_APP_CATALOGO_OBTENER_TAMANO_EMPRESA,
    REACT_APP_CATALOGO_OBTENER_TIPOS_GARANTIAS,
    REACT_APP_CATALOGO_OBTENER_FUENTE_REFERENCIA_INFORMACION,
    REACT_APP_CATALOGO_OBTENER_ESTADO_CCSS,
    REACT_APP_CATALOGO_OBTENER_TRIBUTACION_DIRECTA,
    REACT_APP_CATALOGO_OBTENER_RIESGO_TRABAJO,
    REACT_APP_CATALOGO_OBTENER_TAMANO_EMPRESA_PYMPA_MAG,
    REACT_APP_OBTENER_LINEAS_CREDITO,
    REACT_APP_TIPO_IDENTIFICACION_PERSONA_FISICA_NACIONAL,
    REACT_APP_TIPO_IDENTIFICACION_PERSONA_JURIDICA_NACIONAL,
    REACT_APP_TIPO_IDENTIFICACION_EXTRANJERO_RESIDENTE,
    REACT_APP_CODIGO_RESPUESTA_200,
    REACT_APP_CODIGO_RESPUESTA_401,
    REACT_APP_CODIGO_RESPUESTA_500,
    REACT_APP_CEDULA_BP,
    REACT_APP_OPERADORA_FODEMIPYME,
    REACT_APP_OPERADORA_FNA,
    REACT_APP_CATALOGO_OBTENER_FNA_TIPO_BENEFICIO,
    REACT_APP_CATALOGO_OBTENER_FNA_MEJORAS_CONDICIONES_PAGO,
    REACT_APP_TIPO_PROGRAMA_INDIVIDUAL,
    REACT_APP_TIPO_PROGRAMA_CARTERA_NORMAL,
    REACT_APP_TIPO_PROGRAMA_CARTERA_PERDIDA_ESPERADA,
    REACT_APP_NATURALEZA_AVAL,
    REACT_APP_NATURALEZA_LINEA_CREDITO,
    REACT_APP_NATURALEZA_AVAL_LINEA_CREDITO,
    REACT_APP_ROL_INCLUSION,
    REACT_APP_AVAL_ESTADO_FORMALIZADO,
    REACT_APP_LONGITUD_CAMPO_MONTOS
} from "../../environment";
import { ObtenerCatalogo, GetGenerico } from "../../Services/GenericoService";
import { BorrarLocalStorage } from "../../Utils/BorrarInfoLocalStorage";
import { ObtenerUserAgent } from "../../Utils/BaseSolicitudModel";
import {
    obtenerProvincias,
    ObtenerCodigosCIIU,
    CrearAval,
} from "../../Services/AvalService";
import NumberFormat from "react-number-format";
const $ = require("jquery");
/**
 * Obtener el Fondo seleccionado en iniciar sesion
 **/
const fondoSeleccionado = JSON.parse(localStorage.getItem("fondoSeleccionado"));
/**
 * Obtener el ID del Fondo seleccionado en iniciar sesion
 **/
const idFondo = localStorage.getItem("idFondo");
const userAgent = ObtenerUserAgent();
class CrearAvalComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            numeroOperacion: "",
            monedasLista: [],
            monedaSeleccionada: "",
            tiposProgramasLista: [],
            tipoProgramaSeleccionado: "",
            tiposSolicitudLista: [
                {
                    id: 0,
                    valor: "Aval",
                },
                {
                    id: 1,
                    valor: "Línea de Crédito",
                },
                {
                    id: 2,
                    valor: "Aval + Línea de Crédito",
                },
            ],
            idNaturaleza: 0,
            tipoSolicitudSeleccionado: "",
            programasLista: [],
            programaSeleccionado: "",
            lineasCreditoLista: [],
            lineaCreditoSeleccionada: "",
            estadosLista: [],
            estadoSeleccionado: "",
            montoCredito: "",
            idOficio: "",
            nombreSolicitante: "",
            tiposIdentificacionLista: [],
            tiposIdentificacionSeleccionada: "",
            numeroIdentificacionSolicitante: "",
            tablaGarantiasAportadas: [],
            provinciaLista: [],
            provinciaSeleccionada: "",
            cantonLista: [],
            cantonSeleccionado: "",
            distritoLista: [],
            distritoSeleccionado: "",
            direccion: "",
            actividadEconomica: "",
            sectorEconomicoLista: [],
            sectorEconomicoSeleccionado: "",
            codigoCIIU: "",
            codigoCIIULista: [],
            codigoCIIUSeleccionado: "",
            divisionCIIULista: [],
            divisionCIIUSeleccionado: "",
            grupoCIIULista: [],
            grupoCIIUSeleccionado: "",
            seccionCIIULista: [],
            seccionCIIUSeleccionado: "",
            claseCIIUULista: [],
            claseCIIUUSeleccionado: "",
            ciiuuLista: [],
            ciiuSeleccionado: "",
            nivelPagoHistoricoSBDLista: [],
            nivelPagoHistoricoSBDSeleccionado: "",
            puntajeFinalDeudorSBD: "",
            nivelCICLista: [],
            nivelCICSeleccionado: "",
            puntajeCIC: "",
            nivelHistoricoPagoEntidadLista: [],
            nivelHistoricoPagoEntidadSeleccionado: "",
            tamanoEmpresaLista: [],
            tamanoEmpresaSeleccionada: "",
            tipoGarantiaAdicionalLista: [],
            tipoGarantiaAdicionalSeleccionada: "",
            annosExperienciaEmpresa: "",
            impactoEmpleoMujeres: 0,
            impactoEmpleoHombres: 0,
            totalImpactoEmpleo: 0,
            planInversion: "",
            totalIngresoAnual: "",
            totalEgresoAnual: "",
            montoFlujoOperativo: "",
            fuenteReferenciaInformacionIngresosLista: [],
            fuenteReferenciaInformacionIngresosSeleccionado: "",
            fuenteReferenciaInformacionEgresosLista: [],
            fuenteReferenciaInformacionEgresosSeleccionado: "",
            cuotaMensualCreditoCIC: "",
            cuotaMensualOtrosPrestamo: "",
            totalCuotasMensual: "",
            montoAnualCreditoCIC: "",
            montoAnualOtrosPrestamos: "",
            montoTotalCuotasAnuales: "",
            garantias: [],
            montoTotalGarantiasAportadasCliente: "",
            porcentajeTotalGarantiasAportadasSolicitante: 0,
            montoTotalAvalSolicitado: "",
            porcentajeTotalAvalSolicitado: 0,
            totalAvalYGarantias: 0,
            porcentajeTotalAvalYGarantias: 0,
            tasaInteresAnual: "",
            plazoCreditoMeses: "",
            cuotaPeriodica: "",
            frecuenciaPeriodica: "",
            cuotaMensualNuevoCredito: "",
            cuotaAnualNuevoCredito: "",
            ejecutivoCreadorLista: [],
            ejecutivoCreadorSeleccionado: "",
            nombreArchivoFotoCedulaPersonaFisica: null,
            nombreArchivoFotoCedulaPersonaJuridica: null,
            nombreArchivoFotoCedulaRepresentantePersonaJuridica: null,
            nombreArchivoCertificacionCCSS: null,
            estadoCertificacionCCSSLista: [],
            estadoCertificacionCCSSSeleccionado: "",
            fechaEmisionCertificacionCCSS: moment().locale("en").format("YYYY-MM-DD"),
            nombreArchivoTributacionDirecta: null,
            estadoTributacionDirectaLista: [],
            estadoTributacionDirectaSeleccionada: "",
            nombreArchivoRiesgosTrabajo: null,
            riesgosTrabajoLista: [],
            riesgosTrabajoSeleccionado: "",
            nombreArchivoCertificacionPymePympa: null,
            fechaVencimientoCertificacionPymePympa: moment()
                .locale("en")
                .format("YYYY-MM-DD"),
            tamanoPymePympaLista: [],
            tamanoPymePympaSeleccionado: "",
            consultaAbiertaCICSUGEF: false,
            nombreArchivoActaAprobacionCredito: null,
            nombreArchivoEstudioRegistralBienesMuebles: null,
            nombreArchivoExcluirGarantiaBienMuebleInmueble: null,
            nombreArchivoFacturaProforma: null,
            nombreArchivoInformeAvaluo: null,
            nombreArchivoCICSugef: null,
            observaciones: "",
            historicoEstadosLista: [],
            historicoEstadosSeleccionado: "",
            descripcionGarantia: "",
            valorGarantia: "",
            porcentajeMaximoAceptacion: "",
            montoMaximoAceptacion: "",
            montoDefinitivoResponsabilidad: "",
            porcentajeResponsabilidad: 0,
            indIrPaso2: true,
            indIrPaso3: true,
            llave: "informacionBasica",
            indButton: false,
            tipoGarantiaSeleccionadaTabla: "",
            token: "",
            fechaConstitucion: moment().locale("en").format("YYYY-MM-DD"),
            tipoCreditoLista: [
                {
                    id: 0,
                    valor: "Normal",
                },
                {
                    id: 1,
                    valor: "Ballon",
                },
            ],
            tipoCreditoSeleccionado: "",
            plazoBallonMeses: "",
            tienePlazoBallon: false,
            numeroSolicitudBancaria: "",
            numeroOperacionBancaria: "",
            indEntidadBPFODEMIPYME: false,
            indTipoSolicitud: false,
            diferenciasMonto: 0,
            diferenciaPorcentaje: 0,
            //FNA
            fnaTipoBeneficionLista: [],
            fnaTipoBeneficioSeleccionado: "",
            mejoraCondicionesLista: [],
            mejoraCondicionesSeleccionado: "",
            indCondicionesPago: false,
            indDescripcionMejoraCondicionesPago: false,
            //Campo 1
            idTiposBeneficios: [],
            idMejoraCondicion: 0,
            descripcionMejoraCondicion: "",
            //Campo 2
            demostroAfectacionCOVID: true,
            //Campo 3
            realizaActividadDentroDelPais: true,
            //Campo 4
            compromisoConservacionEmpleo: true,
            //Campo 5
            beneficiaroPerteneceConglomerado: false,
            //Campo 6
            morosidadPrevioCOVIDMenor60: true,
            //Campo 7
            avalSustituyeGarantia: false,
            //Campo 8
            clienteConCapacidadPago: true,
            //Campo 9
            archivoCuadroComparativo: null
        };
    }
    /**
     * Llamado inmediatamente después de montar un componente. 
     * Establecer el estado aquí activará la re-renderización.
     **/
    componentDidMount() {
        localStorage.removeItem("programasIndividualesLista");
        localStorage.removeItem("programasCarteraLista");
        localStorage.removeItem("programasCarteraPELista");
        localStorage.removeItem("lineasCreditoLista");
        $("#floppy").show();
        $("#guardando").hide();
        $("#programas").hide();
        $("#spinProgramas").show();
        $("#lineasCredito").hide();
        $("#tabla_garantias").hide();
        $("#spinMejoraCondiciones").hide();
        const tokenContent = localStorage.getItem("isLoggedIn");
        const monedaLista = localStorage.getItem("monedaLista");
        const fnaTipoBeneficioLista = localStorage.getItem("fnaTipoBeneficioLista");
        const tiposProgramasLista = localStorage.getItem("tiposProgramasLista");
        const tiposIdentificacionLista = localStorage.getItem(
            "tiposIdentificacionLista"
        );
        const sectorEconomicoLista = localStorage.getItem("sectorEconomicoLista");
        const nivelesLista = localStorage.getItem("nivelesLista");
        const tipoGarantiaAdicionalLista = localStorage.getItem(
            "tipoGarantiaAdicionalLista"
        );
        const fuenteReferenciaInformacionLista = localStorage.getItem(
            "fuenteReferenciaInformacionLista"
        );
        const ubicacionGeograficaLista = localStorage.getItem(
            "ubicacionGeograficaLista"
        );
        const estadoCertificacionCCSSLista = localStorage.getItem(
            "estadoCertificacionCCSSLista"
        );
        const estadoTributacionDirectaLista = localStorage.getItem(
            "estadoTributacionDirectaLista"
        );
        const riesgosTrabajoLista = localStorage.getItem("riesgosTrabajoLista");
        const tamanoPymePympaLista = localStorage.getItem("tamanoPymePympaLista");
        const codigosCIIULista = localStorage.getItem("codigosCIIULista");
        if (!tokenContent) {
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        }
        this.state.token = tokenContent;
        this.setState({ token: tokenContent });
        this.setState({
            tipoSolicitudSeleccionado: this.state.tiposSolicitudLista[0],
        });
        const monedaListaJson = JSON.parse(monedaLista);
        const fnaTipoBeneficioListaJson = JSON.parse(fnaTipoBeneficioLista);
        const tiposProgramasListaJson = JSON.parse(tiposProgramasLista);
        const tiposIdentificacionListaJson = JSON.parse(tiposIdentificacionLista);
        const sectorEconomicoListaJson = JSON.parse(sectorEconomicoLista);
        const nivelesListaJson = JSON.parse(nivelesLista);
        const tipoGarantiaAdicionalListaJson = JSON.parse(
            tipoGarantiaAdicionalLista
        );
        const fuenteReferenciaInformacionListaJson = JSON.parse(
            fuenteReferenciaInformacionLista
        );
        const ubicacionGeograficaListaJson = JSON.parse(ubicacionGeograficaLista);
        const estadoCertificacionCCSSListaJson = JSON.parse(
            estadoCertificacionCCSSLista
        );
        const estadoTributacionDirectaListaJson = JSON.parse(
            estadoTributacionDirectaLista
        );
        const riesgosTrabajoListaJson = JSON.parse(riesgosTrabajoLista);
        const tamanoPymePympaListaJson = JSON.parse(tamanoPymePympaLista);
        const codigosCIIUListaJson = JSON.parse(codigosCIIULista);
        if (monedaListaJson == null) {
            this.cargarMoneda();
        } else {
            this.setState({
                monedasLista: monedaListaJson,
            });
            this.setState({ monedaSeleccionada: monedaListaJson[0] });
        }
        if (idFondo == REACT_APP_OPERADORA_FNA) {
            if (fnaTipoBeneficioListaJson == null) {
                this.cargarTipoBeneficio();
            } else {
                this.setState({
                    fnaTipoBeneficionLista: fnaTipoBeneficioListaJson,
                });
                this.setState({ fnaTipoBeneficioSeleccionado: fnaTipoBeneficioListaJson[0] });
            }
        }
        if (tiposProgramasListaJson == null) {
            this.cargarTipoPrograma();
        } else {
            this.setState({
                tiposProgramasLista: tiposProgramasListaJson,
            });
            this.setState({
                tipoProgramaSeleccionado: tiposProgramasListaJson[0],
            });
            $("#programas").show();
            $("#spinProgramas").hide();
            this.cargarProgramaIndividual();
        }
        this.cargarTipoIdentificacion();
        if (sectorEconomicoListaJson == null) {
            this.cargarSectorEconomico();
        } else {
            this.setState({
                sectorEconomicoLista: sectorEconomicoListaJson,
            });
            this.setState({
                sectorEconomicoSeleccionado: sectorEconomicoListaJson[0],
            });
        }
        if (nivelesListaJson == null) {
            this.cargarNiveles();
        } else {
            this.setState({
                nivelPagoHistoricoSBDLista: nivelesListaJson,
            });
            this.setState({
                nivelPagoHistoricoSBDSeleccionado: nivelesListaJson[0],
            });
            this.setState({
                nivelCICLista: nivelesListaJson,
            });
            this.setState({
                nivelCICSeleccionado: nivelesListaJson[0],
            });
            this.setState({
                nivelHistoricoPagoEntidadLista: nivelesListaJson,
            });
            this.setState({
                nivelHistoricoPagoEntidadSeleccionado: nivelesListaJson[0],
            });
        }
        this.cargarTamanoEmpresa();
        if (tipoGarantiaAdicionalListaJson == null) {
            this.cargarTipoGarantia();
        } else {
            this.setState({
                tipoGarantiaAdicionalLista: tipoGarantiaAdicionalListaJson,
            });
            this.setState({
                tipoGarantiaAdicionalSeleccionada: tipoGarantiaAdicionalListaJson[0],
            });
            this.setState({
                tipoGarantiaSeleccionadaTabla: tipoGarantiaAdicionalListaJson[0],
            });
        }
        if (fuenteReferenciaInformacionListaJson == null) {
            this.cargarFuenteReferencia();
        } else {
            this.setState({
                fuenteReferenciaInformacionIngresosLista: fuenteReferenciaInformacionListaJson,
            });
            this.setState({
                fuenteReferenciaInformacionIngresosSeleccionado:
                    fuenteReferenciaInformacionListaJson[0],
            });
            this.setState({
                fuenteReferenciaInformacionEgresosLista: fuenteReferenciaInformacionListaJson,
            });
            this.setState({
                fuenteReferenciaInformacionEgresosSeleccionado:
                    fuenteReferenciaInformacionListaJson[0],
            });
        }
        if (ubicacionGeograficaListaJson == null) {
            this.cargarUbicacionGeografica();
        } else {
            this.setState({
                provinciaLista: ubicacionGeograficaListaJson,
            });
            this.setState({
                provinciaSeleccionada: ubicacionGeograficaListaJson[0],
            });
            this.setState({
                cantonLista: ubicacionGeograficaListaJson[0].cantones,
            });
            this.setState({
                cantonSeleccionado: ubicacionGeograficaListaJson[0].cantones[0],
            });
            this.setState({
                distritoLista: ubicacionGeograficaListaJson[0].cantones[0].distritos,
            });
            this.setState({
                distritoSeleccionado:
                    ubicacionGeograficaListaJson[0].cantones[0].distritos[0],
            });
        }
        if (idFondo == REACT_APP_OPERADORA_FODEMIPYME) {
            if (estadoCertificacionCCSSListaJson == null) {
                this.cargarEstadoCCSS();
            } else {
                this.setState({
                    estadoCertificacionCCSSLista: estadoCertificacionCCSSListaJson,
                });
                this.setState({
                    estadoCertificacionCCSSSeleccionado:
                        estadoCertificacionCCSSListaJson[0],
                });
            }
            if (estadoTributacionDirectaListaJson == null) {
                this.cargarTributacionDirecta();
            } else {
                this.setState({
                    estadoTributacionDirectaLista: estadoTributacionDirectaListaJson,
                });
                this.setState({
                    estadoTributacionDirectaSeleccionada:
                        estadoTributacionDirectaListaJson[0],
                });
            }
            if (riesgosTrabajoListaJson == null) {
                this.cargarRiesgoTrabajo();
            } else {
                this.setState({
                    riesgosTrabajoLista: riesgosTrabajoListaJson,
                });
                this.setState({
                    riesgosTrabajoSeleccionado: riesgosTrabajoListaJson[0],
                });
            }
            if (tamanoPymePympaListaJson == null) {
                this.cargarTrabajoEmpresaPYMPAMAG();
            } else {
                this.setState({
                    tamanoPymePympaLista: tamanoPymePympaListaJson,
                });
                this.setState({
                    tamanoPymePympaSeleccionado: tamanoPymePympaListaJson[0],
                });
            }
        }

        if (codigosCIIUListaJson == null) {
            this.cargarCodigosCIIU();
        } else {
            let seccionLista = [];
            let divisionLista = [];
            let grupoLista = [];
            let claseLista = [];
            let codigoLista = "";
            codigosCIIUListaJson.filter(function (value) {
                seccionLista.push(value.seccion);
            });
            let seccionSinRepetidos = this.sinRepetidos(seccionLista);
            this.setState({ seccionCIIULista: seccionSinRepetidos });
            this.setState({ seccionCIIUSeleccionado: seccionSinRepetidos[0] });
            divisionLista = codigosCIIUListaJson
                .filter((seccion) => {
                    return seccion.seccion == seccionSinRepetidos[0];
                })
                .map((x) => {
                    return x.division;
                });
            let divisionSinRepetidos = this.sinRepetidos(divisionLista);
            this.setState({ divisionCIIULista: divisionSinRepetidos });
            this.setState({
                divisionCIIUSeleccionado: divisionSinRepetidos[0],
            });
            grupoLista = codigosCIIUListaJson
                .filter((division) => {
                    return division.division == divisionSinRepetidos[0];
                })
                .map((x) => {
                    return x.grupo;
                });
            let grupoSinRepetidos = this.sinRepetidos(grupoLista);
            this.setState({
                grupoCIIULista: grupoSinRepetidos,
            });
            this.setState({
                grupoCIIUSeleccionado: grupoSinRepetidos[0],
            });
            claseLista = codigosCIIUListaJson
                .filter((grupo) => {
                    return grupo.grupo == grupoSinRepetidos[0];
                })
                .map((x) => {
                    return x.clase;
                });
            let claseSinRepetidos = this.sinRepetidos(claseLista);
            this.setState({
                claseCIIUULista: claseSinRepetidos,
            });
            this.setState({
                claseCIIUUSeleccionado: claseSinRepetidos[0],
            });
            codigoLista = codigosCIIUListaJson
                .filter((clase) => {
                    return clase.clase == claseSinRepetidos[0];
                })
                .map((x) => {
                    return x.codigo;
                });
            let codigoSinRepetidos = this.sinRepetidos(codigoLista);
            this.setState({
                codigoCIIU: codigoSinRepetidos[0],
            });
        }
    }
    /**
     * 
     * @param {*} lista 
     * @returns Lista de objeto no repetidos
     */
    sinRepetidos = (lista) => {
        var objeto = {};
        return lista.filter(function (item) {
            return objeto.hasOwnProperty(item) ? false : (objeto[item] = true);
        });
    };
    /**
     * Listar el tamaño de la empresa PYMPAMAG
     */
    cargarTrabajoEmpresaPYMPAMAG = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_TAMANO_EMPRESA_PYMPA_MAG,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Error al obtener catálogo tamaño empresa pympa mag", res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem(
                    "tamanoPymePympaLista",
                    JSON.stringify(res.json.catalogoValores)
                );
                this.setState({
                    tamanoPymePympaLista: res.json.catalogoValores,
                });
                this.setState({
                    tamanoPymePympaSeleccionado: res.json.catalogoValores[0],
                });
            }
        });
    };
    /**
     * Listar el catalogo de riesgo de trabajo
     */
    cargarRiesgoTrabajo = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_RIESGO_TRABAJO,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Error al obtener catálogo riesgo trabajo", res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem(
                    "riesgosTrabajoLista",
                    JSON.stringify(res.json.catalogoValores)
                );
                this.setState({
                    riesgosTrabajoLista: res.json.catalogoValores,
                });
                this.setState({
                    riesgosTrabajoSeleccionado: res.json.catalogoValores[0],
                });
            }
        });
    };
    /**
     * Listar el catalogo de tributacion directa
     */
    cargarTributacionDirecta = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_TRIBUTACION_DIRECTA,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Error al obtener catálogo tributación directa", res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem(
                    "estadoTributacionDirectaLista",
                    JSON.stringify(res.json.catalogoValores)
                );
                this.setState({
                    estadoTributacionDirectaLista: res.json.catalogoValores,
                });
                this.setState({
                    estadoTributacionDirectaSeleccionada: res.json.catalogoValores[0],
                });
            }
        });
    };
    /**
     * Listar el catalogo de estados de certificacion de la CCSS
     */
    cargarEstadoCCSS = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_ESTADO_CCSS,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Error al obtener catálogo estado certificación CCSS", res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem(
                    "estadoCertificacionCCSSLista",
                    JSON.stringify(res.json.catalogoValores)
                );
                this.setState({
                    estadoCertificacionCCSSLista: res.json.catalogoValores,
                });
                this.setState({
                    estadoCertificacionCCSSSeleccionado: res.json.catalogoValores[0],
                });
            }
        });
    };
    /**
     * Listar el catalogo de mejoras en condiciones de pago
     */
    cargarMejorasCondicionesPago = () => {
        $("#spinMejoraCondiciones").show();
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_FNA_MEJORAS_CONDICIONES_PAGO,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Error al obtener las mejoras en la condiciones de pago", res.json.mensaje, "error", { button: "Aceptar", });
                    $("#spinMejoraCondiciones").hide();
                    return;
                }
                $("#spinMejoraCondiciones").hide();
                localStorage.setItem(
                    "mejoraCondicionesLista",
                    JSON.stringify(res.json.catalogoValores)
                );
                this.setState({
                    mejoraCondicionesLista: res.json.catalogoValores,
                });
                this.setState({ mejoraCondicionesSeleccionado: res.json.catalogoValores[0] });
            }
        });
    };
    /**
     * Listar el catalogo de tipos de beneficio
     */
    cargarTipoBeneficio = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_FNA_TIPO_BENEFICIO,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Error al obtener los tipos de beneficio", res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem(
                    "fnaTipoBeneficioLista",
                    JSON.stringify(res.json.catalogoValores)
                );
                this.setState({
                    fnaTipoBeneficionLista: res.json.catalogoValores,
                });
                this.setState({ fnaTipoBeneficioSeleccionado: res.json.catalogoValores[0] });
            }
        });
    };
    /**
     * Listar el catalogo de tipo de moneda 
     */
    cargarMoneda = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_TIPOS_MONEDA,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Error al obtener el tipo de moneda", res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem(
                    "monedaLista",
                    JSON.stringify(res.json.catalogoValores)
                );
                this.setState({
                    monedasLista: res.json.catalogoValores,
                });
                this.setState({ monedaSeleccionada: res.json.catalogoValores[0] });
            }
        });
    };
    /**
     * Listar el catalogo de tipos de programa
     */
    cargarTipoPrograma = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_TIPOS_PROGRAMAS,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Error al cargar los tipos de programa", res.json.mensaje, "error", { button: "Aceptar", });
                    $("#programas").hide();
                    $("#spinProgramas").show();
                    return;
                }
                $("#programas").show();
                $("#spinProgramas").hide();
                localStorage.setItem(
                    "tiposProgramasLista",
                    JSON.stringify(res.json.catalogoValores)
                );
                this.setState({
                    tiposProgramasLista: res.json.catalogoValores,
                });
                this.setState({
                    tipoProgramaSeleccionado: res.json.catalogoValores[0],
                });
                this.cargarProgramaIndividual();
            }
        });
    };
    /**
     * Listar el catalogo de tipos de identificacion
     */
    cargarTipoIdentificacion = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_TIPOS_IDENTIFICACION,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Error al obtener los tipos de identificación", res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem(
                    "tiposIdentificacionLista",
                    JSON.stringify(res.json.catalogoValores)
                );
                localStorage.setItem(
                    "tipoIdentificacionInicial",
                    JSON.stringify(res.json.catalogoValores[0])
                );
                this.setState({
                    tiposIdentificacionLista: res.json.catalogoValores,
                });
                this.setState({
                    tiposIdentificacionSeleccionada: res.json.catalogoValores[0],
                });
            }
        });
    };
    /**
     * Listar catalogo de los tipos de sector economico
     */
    cargarSectorEconomico = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_TIPOS_SECTO_ECONOMICO,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Error al obtener catálogo tipos de sector económico ", res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem(
                    "sectorEconomicoLista",
                    JSON.stringify(res.json.catalogoValores)
                );
                this.setState({
                    sectorEconomicoLista: res.json.catalogoValores,
                });
                this.setState({
                    sectorEconomicoSeleccionado: res.json.catalogoValores[0],
                });
            }
        });
    };
    /**
     * Listar catalogo de tipos de niveles
     */
    cargarNiveles = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_TIPOS_NIVELES,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Error al obtener catálogo tipos de niveles", res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem(
                    "nivelesLista",
                    JSON.stringify(res.json.catalogoValores)
                );
                this.setState({
                    nivelPagoHistoricoSBDLista: res.json.catalogoValores,
                });
                this.setState({
                    nivelPagoHistoricoSBDSeleccionado: res.json.catalogoValores[0],
                });
                this.setState({
                    nivelCICLista: res.json.catalogoValores,
                });
                this.setState({
                    nivelCICSeleccionado: res.json.catalogoValores[0],
                });
                this.setState({
                    nivelHistoricoPagoEntidadLista: res.json.catalogoValores,
                });
                this.setState({
                    nivelHistoricoPagoEntidadSeleccionado: res.json.catalogoValores[0],
                });
            }
        });
    };
    /**
     * Listar catalogo para obtener el tamano de empresa
     */
    cargarTamanoEmpresa = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_TAMANO_EMPRESA,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Error al obtener catálogo tamaño empresa", res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                this.setState({
                    tamanoEmpresaLista: res.json.catalogoValores,
                });
                this.setState({
                    tamanoEmpresaSeleccionada: res.json.catalogoValores[0],
                });
            }
        });
    };
    /**
     * Listar el catalogo de tipos de garantia
     */
    cargarTipoGarantia = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_TIPOS_GARANTIAS,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Error al obtener catálogo tipos de garantías", res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem(
                    "tipoGarantiaAdicionalLista",
                    JSON.stringify(res.json.catalogoValores)
                );
                this.setState({
                    tipoGarantiaAdicionalLista: res.json.catalogoValores,
                });
                this.setState({
                    tipoGarantiaAdicionalSeleccionada: res.json.catalogoValores[0],
                });
                this.setState({
                    tipoGarantiaSeleccionadaTabla: res.json.catalogoValores[0],
                });
            }
        });
    };
    /**
     * Listar catalogo de referencia de informacion
     */
    cargarFuenteReferencia = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_FUENTE_REFERENCIA_INFORMACION,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Error al obtener catálogo de referencia información", res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem(
                    "fuenteReferenciaInformacionLista",
                    JSON.stringify(res.json.catalogoValores)
                );
                this.setState({
                    fuenteReferenciaInformacionIngresosLista: res.json.catalogoValores,
                });
                this.setState({
                    fuenteReferenciaInformacionIngresosSeleccionado:
                        res.json.catalogoValores[0],
                });
                this.setState({
                    fuenteReferenciaInformacionEgresosLista: res.json.catalogoValores,
                });
                this.setState({
                    fuenteReferenciaInformacionEgresosSeleccionado:
                        res.json.catalogoValores[0],
                });
            }
        });
    };
    /**
     * Listar catalogo de ubicacion geografica
     */
    cargarUbicacionGeografica = () => {
        obtenerProvincias().then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Error al obtener catálogo de provincias, cantones, distritos", res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem(
                    "ubicacionGeograficaLista",
                    JSON.stringify(res.json.provincias)
                );
                this.setState({
                    provinciaLista: res.json.provincias,
                });
                this.setState({
                    provinciaSeleccionada: res.json.provincias[0],
                });
                this.setState({
                    cantonLista: res.json.provincias[0].cantones,
                });
                this.setState({
                    cantonSeleccionado: res.json.provincias[0].cantones[0],
                });
                this.setState({
                    distritoLista: res.json.provincias[0].cantones[0].distritos,
                });
                this.setState({
                    distritoSeleccionado: res.json.provincias[0].cantones[0].distritos[0],
                });
            }
        });
    };
    /**
     * Listar catalogo de codigos CIIU
     */
    cargarCodigosCIIU = () => {
        ObtenerCodigosCIIU().then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Error al obtener catálogo de códigos CIIU", res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem(
                    "codigosCIIULista",
                    JSON.stringify(res.json.codigos)
                );
                let seccionLista = [];
                let divisionLista = [];
                let grupoLista = [];
                let claseLista = [];
                let codigoLista = "";
                res.json.codigos.filter(function (value) {
                    seccionLista.push(value.seccion);
                });
                let seccionSinRepetidos = this.sinRepetidos(seccionLista);
                this.setState({ seccionCIIULista: seccionSinRepetidos });
                this.setState({ seccionCIIUSeleccionado: seccionSinRepetidos[0] });
                divisionLista = res.json.codigos
                    .filter((seccion) => {
                        return seccion.seccion == seccionSinRepetidos[0];
                    })
                    .map((x) => {
                        return x.division;
                    });
                let divisionSinRepetidos = this.sinRepetidos(divisionLista);
                this.setState({ divisionCIIULista: divisionSinRepetidos });
                this.setState({
                    divisionCIIUSeleccionado: divisionSinRepetidos[0],
                });
                grupoLista = res.json.codigos
                    .filter((division) => {
                        return division.division == divisionSinRepetidos[0];
                    })
                    .map((x) => {
                        return x.grupo;
                    });
                let grupoSinRepetidos = this.sinRepetidos(grupoLista);
                this.setState({
                    grupoCIIULista: grupoSinRepetidos,
                });
                this.setState({
                    grupoCIIUSeleccionado: grupoSinRepetidos[0],
                });
                claseLista = res.json.codigos
                    .filter((grupo) => {
                        return grupo.grupo == grupoSinRepetidos[0];
                    })
                    .map((x) => {
                        return x.clase;
                    });
                let claseSinRepetidos = this.sinRepetidos(claseLista);
                this.setState({
                    claseCIIUULista: claseSinRepetidos,
                });
                this.setState({
                    claseCIIUUSeleccionado: claseSinRepetidos[0],
                });
                codigoLista = res.json.codigos
                    .filter((clase) => {
                        return clase.clase == claseSinRepetidos[0];
                    })
                    .map((x) => {
                        return x.codigo;
                    });
                let codigoSinRepetidos = this.sinRepetidos(codigoLista);
                this.setState({
                    codigoCIIU: codigoSinRepetidos[0],
                });
            }
        });
    };
    /**
     * Listar programas individuales
     */
    cargarProgramaIndividual = () => {
        $("#tabla2").DataTable({
            retrieve: true,
            processing: true,
            serverSide: true,
            pageLength: 500,
            ajax: {
                url: `/api/ListarProgramasIndividuales?fondo=${localStorage.getItem("idFondo")}&ip=${localStorage.getItem("ip")}&latitud=${localStorage.getItem("latitud")}&longitud=${localStorage.getItem("longitud")}&userAgent=${userAgent}&usuario=${localStorage.getItem("idUsuarioCreador")}`,
                error: function (xhr, error, thrown) {
                    if (xhr.status == REACT_APP_CODIGO_RESPUESTA_401) {
                        swal({
                            title: "¡Sesión Expiró!",
                            text: "Por favor vuelva a iniciar sesión",
                            icon: "warning",
                            button: "Aceptar",
                        }).then((value) => {
                            BorrarLocalStorage();
                            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                        });
                        return;
                    }
                    if (xhr.status != REACT_APP_CODIGO_RESPUESTA_200) {
                        swal("Error al obtener los programas individuales", "", "error", { button: "Aceptar", });
                    }
                },
                success: (data) => {
                    if (data != null) {
                        const idEstadoActivo = 8;
                        let programaActivos = data.data.filter(
                            (x) => x.estado.id == idEstadoActivo
                        );
                        localStorage.setItem(
                            "programasIndividualesLista",
                            JSON.stringify(programaActivos)
                        );
                        this.setState({
                            programasLista: programaActivos,
                        });
                        if (programaActivos.length == 0) {
                            this.state.programaSeleccionado = "";
                            this.setState({
                                programaSeleccionado: "",
                            });
                            swal("Programas individuales", "No existen programas individuales activos en su entidad financiera", "warning", { button: "Aceptar", });
                            return;
                        }
                        this.state.programaSeleccionado = programaActivos[0];
                        this.setState({ programaSeleccionado: programaActivos[0] });
                        if (
                            this.state.programaSeleccionado.cliente.cedulaJuridica ==
                            REACT_APP_CEDULA_BP
                        ) {
                            this.state.indEntidadBPFODEMIPYME = true;
                            this.setState({ indEntidadBPFODEMIPYME: true });
                        } else {
                            this.state.indEntidadBPFODEMIPYME = false;
                            this.setState({ indEntidadBPFODEMIPYME: false });
                        }
                    }
                },
                type: "POST",
            },
            columns: [{ data: "id", name: "id" }],
        });
    };
    /**
     * Listar programas de cartera
     */
    cargarProgramaCartera = () => {
        $("#tabla3").DataTable({
            retrieve: true,
            processing: true,
            serverSide: true,
            pageLength: 500,
            ajax: {
                url: `/api/ListarProgramasCartera?fondo=${localStorage.getItem("idFondo")}&ip=${localStorage.getItem("ip")}&latitud=${localStorage.getItem("latitud")}&longitud=${localStorage.getItem("longitud")}&userAgent=${userAgent}&usuario=${localStorage.getItem("idUsuarioCreador")}`,
                error: function (xhr, error, thrown) {
                    if (xhr.status == REACT_APP_CODIGO_RESPUESTA_401) {
                        swal({
                            title: "¡Sesión Expiró!",
                            text: "Por favor vuelva a iniciar sesión",
                            icon: "warning",
                            button: "Aceptar",
                        }).then(() => {
                            BorrarLocalStorage();
                            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                        });
                        return;
                    }
                    if (xhr.status != REACT_APP_CODIGO_RESPUESTA_200) {
                        swal("Error al obtener los programas de cartera", "", "error", { button: "Aceptar", });
                    }
                },
                success: (data) => {
                    if (data != null) {
                        const idEstadoActivo = 8;
                        let programaActivos = data.data.filter(
                            (x) => x.estado.id == idEstadoActivo
                        );
                        localStorage.setItem(
                            "programasCarteraLista",
                            JSON.stringify(programaActivos)
                        );
                        this.setState({
                            programasLista: programaActivos,
                        });
                        if (programaActivos.length == 0) {
                            this.state.programaSeleccionado = "";
                            this.setState({
                                programaSeleccionado: "",
                            });
                            swal("Programas de cartera", "No existen programas de cartera activos en su entidad financiera", "warning", { button: "Aceptar", });
                            $("#lineasCredito").hide();
                            let tipoSolicitud = this.state.tiposSolicitudLista.filter(
                                (x) => x.id == 0
                            );
                            this.setState({ tipoSolicitudSeleccionado: tipoSolicitud[0] });
                            this.limpiarTotalesGarantiasAportadas();
                            this.limpiarInformacionBasica();
                            this.limpiarGarantias();

                            return;
                        }
                        this.state.programaSeleccionado = programaActivos[0];
                        this.setState({ programaSeleccionado: programaActivos[0] });
                        if (
                            this.state.programaSeleccionado.cliente.cedulaJuridica ==
                            REACT_APP_CEDULA_BP
                        ) {
                            this.state.indEntidadBPFODEMIPYME = true;
                            this.setState({ indEntidadBPFODEMIPYME: true });
                        } else {
                            this.state.indEntidadBPFODEMIPYME = false;
                            this.setState({ indEntidadBPFODEMIPYME: false });
                        }

                        // Aval + Linea de Credito
                        if (this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_AVAL_LINEA_CREDITO) {
                            const lineasCreditoLista = localStorage.getItem("lineasCreditoLista");
                            const lineasCreditoListaJson = JSON.parse(lineasCreditoLista);
                            if (lineasCreditoListaJson == null) {
                                this.cargarLineasCreditos();
                            } else {
                                var lineaCreditoXProgramaXEstadoFormalizado = [];
                                var idTipoProgramaSeleccionado = this.state.tipoProgramaSeleccionado.id;
                                lineaCreditoXProgramaXEstadoFormalizado = lineasCreditoListaJson.filter(function (linea) {
                                    return (
                                        linea.tipoPrograma.id == idTipoProgramaSeleccionado && linea.estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO
                                    );
                                });

                                if (lineaCreditoXProgramaXEstadoFormalizado.length == 0) {
                                    swal(
                                        "Crear Aval",
                                        "No se encontraron líneas de crédito disponibles.",
                                        "warning", { button: "Aceptar", }
                                    );
                                    $("#lineasCredito").hide();
                                    let tipoSolicitud = this.state.tiposSolicitudLista.filter(
                                        (x) => x.id == 0
                                    );
                                    this.setState({ tipoSolicitudSeleccionado: tipoSolicitud[0] });
                                    return;
                                }

                                this.setState({
                                    lineasCreditoLista: lineaCreditoXProgramaXEstadoFormalizado,
                                });
                                this.state.lineaCreditoSeleccionada = lineaCreditoXProgramaXEstadoFormalizado[0];
                                this.setState({ lineaCreditoSeleccionada: lineaCreditoXProgramaXEstadoFormalizado[0] });

                                this.state.nombreSolicitante = lineaCreditoXProgramaXEstadoFormalizado[0].nombreSolicitante;
                                this.setState({ nombreSolicitante: lineaCreditoXProgramaXEstadoFormalizado[0].nombreSolicitante });

                                this.state.numeroIdentificacionSolicitante = lineaCreditoXProgramaXEstadoFormalizado[0].numeroIdentificacionSolicitante;
                                this.setState({ numeroIdentificacionSolicitante: lineaCreditoXProgramaXEstadoFormalizado[0].numeroIdentificacionSolicitante });

                                this.state.tiposIdentificacionSeleccionada = lineaCreditoXProgramaXEstadoFormalizado[0].tipoIdentificacionSolicitante;
                                this.setState({ tiposIdentificacionSeleccionada: lineaCreditoXProgramaXEstadoFormalizado[0].tipoIdentificacionSolicitante });

                                /** */
                                let porcTotalGarantiasAportadas = lineaCreditoXProgramaXEstadoFormalizado[0].porcentajeTotalGarantiasAportadasSolicitante;

                                this.state.porcentajeTotalGarantiasAportadasSolicitante = lineaCreditoXProgramaXEstadoFormalizado[0].porcentajeTotalGarantiasAportadasSolicitante.toFixed(2);
                                this.setState({ porcentajeTotalGarantiasAportadasSolicitante: lineaCreditoXProgramaXEstadoFormalizado[0].porcentajeTotalGarantiasAportadasSolicitante.toFixed(2) });

                                let totalGarantiasAportadas = parseFloat(this.state.montoCredito.replace(/,/g, "")) * (porcTotalGarantiasAportadas / 100);

                                totalGarantiasAportadas = totalGarantiasAportadas.toFixed(2);

                                this.state.montoTotalGarantiasAportadasCliente = totalGarantiasAportadas;

                                let totalAval =
                                    parseFloat(this.state.montoCredito.replace(/,/g, "")) -
                                    this.state.montoTotalGarantiasAportadasCliente;

                                totalAval = totalAval.toFixed(2);
                                this.state.montoTotalAvalSolicitado = totalAval;

                                let totalPorcAval = 100 - this.state.porcentajeTotalGarantiasAportadasSolicitante;

                                totalPorcAval = totalPorcAval.toFixed(2);
                                this.state.porcentajeTotalAvalSolicitado = totalPorcAval;
                                this.setState({ montoTotalAvalSolicitado: totalAval });
                                this.setState({ porcentajeTotalAvalSolicitado: totalPorcAval });
                                /** */

                                this.asignarProgramaALineaCredito();
                            }
                            this.state.idNaturaleza = 1;
                            this.setState({ idNaturaleza: 1 });
                        }
                    }
                },
                type: "POST",
            },
            columns: [{ data: "id", name: "id" }],
        });
    };
    /**
     * Listar programas de perdida esperada
     */
    cargarProgramaCarteraPE = () => {
        $("#tabla4").DataTable({
            retrieve: true,
            processing: true,
            serverSide: true,
            pageLength: 500,
            ajax: {
                url: `/api/ListarProgramasCarteraPerdidaEsperada?fondo=${localStorage.getItem("idFondo")}&ip=${localStorage.getItem("ip")}&latitud=${localStorage.getItem("latitud")}&longitud=${localStorage.getItem("longitud")}&userAgent=${userAgent}&usuario=${localStorage.getItem("idUsuarioCreador")}`,
                error: function (xhr, error, thrown) {
                    if (xhr.status == REACT_APP_CODIGO_RESPUESTA_401) {
                        swal({
                            title: "¡Sesión Expiró!",
                            text: "Por favor vuelva a iniciar sesión",
                            icon: "warning",
                            button: "Aceptar",
                        }).then(() => {
                            BorrarLocalStorage();
                            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                        });
                        return;
                    }
                    if (xhr.status != REACT_APP_CODIGO_RESPUESTA_200) {
                        swal(
                            "Error al obtener los programas de cartera de pérdida esperada",
                            "",
                            "error", { button: "Aceptar", }
                        );
                    }
                },
                success: (data) => {
                    if (data != null) {
                        const idEstadoActivo = 8;
                        let programaActivos = data.data.filter(
                            (x) => x.estado.id == idEstadoActivo
                        );
                        localStorage.setItem(
                            "programasCarteraPELista",
                            JSON.stringify(programaActivos)
                        );
                        this.setState({
                            programasLista: programaActivos,
                        });
                        if (programaActivos.length == 0) {
                            this.state.programaSeleccionado = "";
                            this.setState({
                                programaSeleccionado: "",
                            });
                            swal("Programas de cartera de pérdida esperada", "No existen programas de cartera de pérdida esperada activos en su entidad financiera", "warning", { button: "Aceptar", });
                            $("#lineasCredito").hide();
                            let tipoSolicitud = this.state.tiposSolicitudLista.filter(
                                (x) => x.id == 0
                            );
                            this.setState({ tipoSolicitudSeleccionado: tipoSolicitud[0] });
                            this.limpiarTotalesGarantiasAportadas();
                            this.limpiarInformacionBasica();
                            this.limpiarGarantias();

                            return;
                        }
                        this.state.programaSeleccionado = programaActivos[0];
                        this.setState({ programaSeleccionado: programaActivos[0] });
                        if (
                            this.state.programaSeleccionado.cliente.cedulaJuridica ==
                            REACT_APP_CEDULA_BP
                        ) {
                            this.state.indEntidadBPFODEMIPYME = true;
                            this.setState({ indEntidadBPFODEMIPYME: true });
                        } else {
                            this.state.indEntidadBPFODEMIPYME = false;
                            this.setState({ indEntidadBPFODEMIPYME: false });
                        }

                        // Aval + Linea de Credito
                        if (this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_AVAL_LINEA_CREDITO && idFondo == REACT_APP_OPERADORA_FNA) {
                            const lineasCreditoLista = localStorage.getItem("lineasCreditoLista");
                            const lineasCreditoListaJson = JSON.parse(lineasCreditoLista);
                            if (lineasCreditoListaJson == null) {
                                this.cargarLineasCreditos();
                            } else {
                                var lineaCreditoXProgramaXEstadoFormalizado = [];
                                var idTipoProgramaSeleccionado = this.state.tipoProgramaSeleccionado.id;
                                lineaCreditoXProgramaXEstadoFormalizado = lineasCreditoListaJson.filter(function (linea) {
                                    return (
                                        linea.tipoPrograma.id == idTipoProgramaSeleccionado && linea.estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO
                                    );
                                });

                                if (lineaCreditoXProgramaXEstadoFormalizado.length == 0) {
                                    swal(
                                        "Crear Aval",
                                        "No se encontraron líneas de crédito disponibles.",
                                        "warning", { button: "Aceptar", }
                                    );
                                    $("#lineasCredito").hide();
                                    let tipoSolicitud = this.state.tiposSolicitudLista.filter(
                                        (x) => x.id == 0
                                    );
                                    this.setState({ tipoSolicitudSeleccionado: tipoSolicitud[0] });
                                    return;
                                }

                                this.setState({
                                    lineasCreditoLista: lineaCreditoXProgramaXEstadoFormalizado,
                                });
                                this.state.lineaCreditoSeleccionada = lineaCreditoXProgramaXEstadoFormalizado[0];
                                this.setState({ lineaCreditoSeleccionada: lineaCreditoXProgramaXEstadoFormalizado[0] });

                                this.state.nombreSolicitante = lineaCreditoXProgramaXEstadoFormalizado[0].nombreSolicitante;
                                this.setState({ nombreSolicitante: lineaCreditoXProgramaXEstadoFormalizado[0].nombreSolicitante });

                                this.state.numeroIdentificacionSolicitante = lineaCreditoXProgramaXEstadoFormalizado[0].numeroIdentificacionSolicitante;
                                this.setState({ numeroIdentificacionSolicitante: lineaCreditoXProgramaXEstadoFormalizado[0].numeroIdentificacionSolicitante });

                                this.state.tiposIdentificacionSeleccionada = lineaCreditoXProgramaXEstadoFormalizado[0].tipoIdentificacionSolicitante;
                                this.setState({ tiposIdentificacionSeleccionada: lineaCreditoXProgramaXEstadoFormalizado[0].tipoIdentificacionSolicitante });

                                /** */
                                let porcTotalGarantiasAportadas = lineaCreditoXProgramaXEstadoFormalizado[0].porcentajeTotalGarantiasAportadasSolicitante;

                                this.state.porcentajeTotalGarantiasAportadasSolicitante = lineaCreditoXProgramaXEstadoFormalizado[0].porcentajeTotalGarantiasAportadasSolicitante.toFixed(2);
                                this.setState({ porcentajeTotalGarantiasAportadasSolicitante: lineaCreditoXProgramaXEstadoFormalizado[0].porcentajeTotalGarantiasAportadasSolicitante.toFixed(2) });

                                let totalGarantiasAportadas = parseFloat(this.state.montoCredito.replace(/,/g, "")) * (porcTotalGarantiasAportadas / 100);

                                totalGarantiasAportadas = totalGarantiasAportadas.toFixed(2);

                                this.state.montoTotalGarantiasAportadasCliente = totalGarantiasAportadas;

                                let totalAval =
                                    parseFloat(this.state.montoCredito.replace(/,/g, "")) -
                                    this.state.montoTotalGarantiasAportadasCliente;

                                totalAval = totalAval.toFixed(2);
                                this.state.montoTotalAvalSolicitado = totalAval;

                                let totalPorcAval = 100 - this.state.porcentajeTotalGarantiasAportadasSolicitante;

                                totalPorcAval = totalPorcAval.toFixed(2);
                                this.state.porcentajeTotalAvalSolicitado = totalPorcAval;
                                this.setState({ montoTotalAvalSolicitado: totalAval });
                                this.setState({ porcentajeTotalAvalSolicitado: totalPorcAval });
                                /** */

                                this.asignarProgramaALineaCredito();
                            }
                            this.state.idNaturaleza = 1;
                            this.setState({ idNaturaleza: 1 });
                        }
                    }
                },
                type: "POST",
            },
            columns: [{ data: "id", name: "id" }],
        });
    };
    /**
     * Listar lineas de creditos
     */
    cargarLineasCreditos = () => {
        GetGenerico(REACT_APP_OBTENER_LINEAS_CREDITO).then(
            (res) => {
                if (res) {
                    if (res.json.lineasCredito.length == 0) {
                        swal(
                            "Crear Aval",
                            "No se encontraron líneas de crédito disponibles.",
                            "warning", { button: "Aceptar", }
                        );
                        $("#lineasCredito").hide();
                        let tipoSolicitud = this.state.tiposSolicitudLista.filter(
                            (x) => x.id == 0
                        );
                        this.setState({ tipoSolicitudSeleccionado: tipoSolicitud[0] });
                        return;
                    }
                    if (res.json.lineasCredito.length > 0) {
                        localStorage.setItem(
                            "lineasCreditoLista",
                            JSON.stringify(res.json.lineasCredito)
                        );
                        var lineaCreditoXProgramaXEstadoFormalizado = [];
                        var idTipoProgramaSeleccionado = this.state.tipoProgramaSeleccionado.id;
                        lineaCreditoXProgramaXEstadoFormalizado = res.json.lineasCredito.filter(function (linea) {
                            return (
                                linea.tipoPrograma.id == idTipoProgramaSeleccionado && linea.estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO
                            );
                        });

                        if (lineaCreditoXProgramaXEstadoFormalizado.length == 0) {
                            swal(
                                "Crear Aval",
                                "No se encontraron líneas de crédito disponibles.",
                                "warning", { button: "Aceptar", }
                            );
                            $("#lineasCredito").hide();
                            let tipoSolicitud = this.state.tiposSolicitudLista.filter(
                                (x) => x.id == 0
                            );
                            this.setState({ tipoSolicitudSeleccionado: tipoSolicitud[0] });
                            this.limpiarTotalesGarantiasAportadas();
                            this.limpiarInformacionBasica();
                            this.limpiarGarantias();
                            return;
                        }

                        this.state.lineasCreditoLista = lineaCreditoXProgramaXEstadoFormalizado;
                        this.setState({
                            lineasCreditoLista: lineaCreditoXProgramaXEstadoFormalizado,
                            lineaCreditoSeleccionada: lineaCreditoXProgramaXEstadoFormalizado[0],
                        });

                        this.state.nombreSolicitante = lineaCreditoXProgramaXEstadoFormalizado[0].nombreSolicitante;
                        this.setState({ nombreSolicitante: lineaCreditoXProgramaXEstadoFormalizado[0].nombreSolicitante });

                        this.state.numeroIdentificacionSolicitante = lineaCreditoXProgramaXEstadoFormalizado[0].numeroIdentificacionSolicitante;
                        this.setState({ numeroIdentificacionSolicitante: lineaCreditoXProgramaXEstadoFormalizado[0].numeroIdentificacionSolicitante });

                        this.state.tiposIdentificacionSeleccionada = lineaCreditoXProgramaXEstadoFormalizado[0].tipoIdentificacionSolicitante;
                        this.setState({ tiposIdentificacionSeleccionada: lineaCreditoXProgramaXEstadoFormalizado[0].tipoIdentificacionSolicitante });

                        this.state.porcentajeTotalGarantiasAportadasSolicitante = lineaCreditoXProgramaXEstadoFormalizado[0].porcentajeTotalGarantiasAportadasSolicitante.toFixed(2);
                        this.setState({ porcentajeTotalGarantiasAportadasSolicitante: lineaCreditoXProgramaXEstadoFormalizado[0].porcentajeTotalGarantiasAportadasSolicitante.toFixed(2) });

                        this.state.indIrPaso3 = false;
                        this.setState({ indIrPaso3: false });

                        this.limpiarGarantias();
                        this.asignarProgramaALineaCredito();
                    }
                }
            }
        );
    };
    /**
     * 
     * @param {*} event 
     */
    cambioEstado = (event) => {
        let estado = this.state.estadosLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ estadoSeleccionado: estado[0] });
    };
    /**
     * 
     * @param {*} k 
     */
    cambiarTab = (k) => {
        this.setState({ llave: k });
    };
    /**
     * 
     * @param {*} event 
     */
    cambioMoneda = (event) => {
        let moneda = this.state.monedasLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ monedaSeleccionada: moneda[0] });
    };
    /**
     * 
     * @param {*} event 
     */
    cambioTipoPrograma = (event) => {
        let programa = this.state.tiposProgramasLista.filter(
            (x) => x.id == event.target.value
        );
        this.state.tipoProgramaSeleccionado = programa[0];
        this.setState({ tipoProgramaSeleccionado: programa[0] });

        if (event.target.value == REACT_APP_TIPO_PROGRAMA_INDIVIDUAL) {
            this.state.indTipoSolicitud = false;
            this.setState({ indTipoSolicitud: false });
            const programasIndividualesLista = localStorage.getItem(
                "programasIndividualesLista"
            );
            const programasIndividualesListaJson = JSON.parse(
                programasIndividualesLista
            );
            if (programasIndividualesListaJson != null) {
                this.setState({
                    programasLista: programasIndividualesListaJson,
                });

                if (programasIndividualesListaJson.length == 0) {
                    this.state.programaSeleccionado = "";
                    this.setState({
                        programaSeleccionado: "",
                    });
                    swal("Programas individuales", "No existen programas individuales activos en su entidad financiera", "warning", { button: "Aceptar", });
                    return;
                }

                this.state.programaSeleccionado = programasIndividualesListaJson[0];
                this.setState({
                    programaSeleccionado: programasIndividualesListaJson[0],
                });
                if (
                    this.state.programaSeleccionado.cliente.cedulaJuridica == REACT_APP_CEDULA_BP
                ) {
                    this.state.indEntidadBPFODEMIPYME = true;
                    this.setState({ indEntidadBPFODEMIPYME: true });
                } else {
                    this.state.indEntidadBPFODEMIPYME = false;
                    this.setState({ indEntidadBPFODEMIPYME: false });
                }

            }
            // Aval + Linea de Credito
            if (this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_AVAL_LINEA_CREDITO) {
                const lineasCreditoLista = localStorage.getItem("lineasCreditoLista");
                const lineasCreditoListaJson = JSON.parse(lineasCreditoLista);
                if (lineasCreditoListaJson == null) {
                    this.cargarLineasCreditos();
                } else {
                    var lineaCreditoXProgramaXEstadoFormalizado = [];
                    lineaCreditoXProgramaXEstadoFormalizado = lineasCreditoListaJson.filter(function (linea) {
                        return (
                            linea.tipoPrograma.id == programa[0].id && linea.estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO
                        );
                    });

                    if (lineaCreditoXProgramaXEstadoFormalizado.length == 0) {
                        swal(
                            "Crear Aval",
                            "No se encontraron líneas de crédito disponibles.",
                            "warning", { button: "Aceptar", }
                        );
                        $("#lineasCredito").hide();
                        let tipoSolicitud = this.state.tiposSolicitudLista.filter(
                            (x) => x.id == 0
                        );
                        this.setState({ tipoSolicitudSeleccionado: tipoSolicitud[0] });

                        this.limpiarTotalesGarantiasAportadas();
                        this.limpiarInformacionBasica();
                        return;
                    }

                    this.state.lineasCreditoLista = lineaCreditoXProgramaXEstadoFormalizado;
                    this.setState({
                        lineasCreditoLista: lineaCreditoXProgramaXEstadoFormalizado,
                    });
                    this.state.lineaCreditoSeleccionada = lineaCreditoXProgramaXEstadoFormalizado[0];
                    this.setState({ lineaCreditoSeleccionada: lineaCreditoXProgramaXEstadoFormalizado[0] });

                    this.state.nombreSolicitante = lineaCreditoXProgramaXEstadoFormalizado[0].nombreSolicitante;
                    this.setState({ nombreSolicitante: lineaCreditoXProgramaXEstadoFormalizado[0].nombreSolicitante });

                    this.state.numeroIdentificacionSolicitante = lineaCreditoXProgramaXEstadoFormalizado[0].numeroIdentificacionSolicitante;
                    this.setState({ numeroIdentificacionSolicitante: lineaCreditoXProgramaXEstadoFormalizado[0].numeroIdentificacionSolicitante });

                    this.state.tiposIdentificacionSeleccionada = lineaCreditoXProgramaXEstadoFormalizado[0].tipoIdentificacionSolicitante;
                    this.setState({ tiposIdentificacionSeleccionada: lineaCreditoXProgramaXEstadoFormalizado[0].tipoIdentificacionSolicitante });

                    /** */
                    let porcTotalGarantiasAportadas = lineaCreditoXProgramaXEstadoFormalizado[0].porcentajeTotalGarantiasAportadasSolicitante;

                    this.state.porcentajeTotalGarantiasAportadasSolicitante = lineaCreditoXProgramaXEstadoFormalizado[0].porcentajeTotalGarantiasAportadasSolicitante.toFixed(2);
                    this.setState({ porcentajeTotalGarantiasAportadasSolicitante: lineaCreditoXProgramaXEstadoFormalizado[0].porcentajeTotalGarantiasAportadasSolicitante.toFixed(2) });

                    let totalGarantiasAportadas = parseFloat(this.state.montoCredito.replace(/,/g, "")) * (porcTotalGarantiasAportadas / 100);

                    totalGarantiasAportadas = totalGarantiasAportadas.toFixed(2);

                    this.state.montoTotalGarantiasAportadasCliente = totalGarantiasAportadas;

                    let totalAval =
                        parseFloat(this.state.montoCredito.replace(/,/g, "")) -
                        this.state.montoTotalGarantiasAportadasCliente;

                    totalAval = totalAval.toFixed(2);
                    this.state.montoTotalAvalSolicitado = totalAval;

                    let totalPorcAval = 100 - this.state.porcentajeTotalGarantiasAportadasSolicitante;

                    totalPorcAval = totalPorcAval.toFixed(2);
                    this.state.porcentajeTotalAvalSolicitado = totalPorcAval;
                    this.setState({ montoTotalAvalSolicitado: totalAval });
                    this.setState({ porcentajeTotalAvalSolicitado: totalPorcAval });
                    /** */

                    this.asignarProgramaALineaCredito();
                }
                this.state.idNaturaleza = 1;
                this.setState({ idNaturaleza: 1 });
            }
        }
        if (event.target.value == REACT_APP_TIPO_PROGRAMA_CARTERA_NORMAL) {
            this.state.indTipoSolicitud = false;
            this.setState({ indTipoSolicitud: false });
            const programasCarteraLista = localStorage.getItem(
                "programasCarteraLista"
            );
            const programasCarteraListaJson = JSON.parse(programasCarteraLista);

            if (programasCarteraListaJson == null) {
                this.cargarProgramaCartera();
                return;
            }
            this.state.programasLista = programasCarteraListaJson;
            this.setState({
                programasLista: programasCarteraListaJson,
            });
            if (programasCarteraListaJson.length == 0) {
                this.state.programaSeleccionado = "";
                this.setState({
                    programaSeleccionado: "",
                });
                swal("Programas de cartera", "No existen programas de cartera activos en su entidad financiera", "warning", { button: "Aceptar", });

                $("#lineasCredito").hide();
                let tipoSolicitud = this.state.tiposSolicitudLista.filter(
                    (x) => x.id == 0
                );
                this.state.tipoSolicitudSeleccionado = tipoSolicitud[0];
                this.setState({ tipoSolicitudSeleccionado: tipoSolicitud[0] });
                this.limpiarTotalesGarantiasAportadas();
                this.limpiarInformacionBasica();
                this.limpiarGarantias();
                return;
            }

            this.state.programaSeleccionado = programasCarteraListaJson[0];
            this.setState({
                programaSeleccionado: programasCarteraListaJson[0],
            });
            if (
                this.state.programaSeleccionado.cliente.cedulaJuridica == REACT_APP_CEDULA_BP
            ) {
                this.state.indEntidadBPFODEMIPYME = true;
                this.setState({ indEntidadBPFODEMIPYME: true });
            } else {
                this.state.indEntidadBPFODEMIPYME = false;
                this.setState({ indEntidadBPFODEMIPYME: false });
            }


            // Aval + Linea de Credito
            if (this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_AVAL_LINEA_CREDITO) {
                const lineasCreditoLista = localStorage.getItem("lineasCreditoLista");
                const lineasCreditoListaJson = JSON.parse(lineasCreditoLista);
                if (lineasCreditoListaJson == null) {
                    this.cargarLineasCreditos();
                } else {
                    var lineaCreditoXProgramaXEstadoFormalizado = [];
                    lineaCreditoXProgramaXEstadoFormalizado = lineasCreditoListaJson.filter(function (linea) {
                        return (
                            linea.tipoPrograma.id == programa[0].id && linea.estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO
                        );
                    });

                    if (lineaCreditoXProgramaXEstadoFormalizado.length == 0) {
                        swal(
                            "Crear Aval",
                            "No se encontraron líneas de crédito disponibles.",
                            "warning", { button: "Aceptar", }
                        );
                        $("#lineasCredito").hide();
                        let tipoSolicitud = this.state.tiposSolicitudLista.filter(
                            (x) => x.id == 0
                        );
                        this.setState({ tipoSolicitudSeleccionado: tipoSolicitud[0] });

                        this.limpiarTotalesGarantiasAportadas();
                        this.limpiarInformacionBasica();
                        return;
                    }

                    this.state.lineasCreditoLista = lineaCreditoXProgramaXEstadoFormalizado;
                    this.setState({
                        lineasCreditoLista: lineaCreditoXProgramaXEstadoFormalizado,
                    });
                    this.state.lineaCreditoSeleccionada = lineaCreditoXProgramaXEstadoFormalizado[0];
                    this.setState({ lineaCreditoSeleccionada: lineaCreditoXProgramaXEstadoFormalizado[0] });

                    this.state.nombreSolicitante = lineaCreditoXProgramaXEstadoFormalizado[0].nombreSolicitante;
                    this.setState({ nombreSolicitante: lineaCreditoXProgramaXEstadoFormalizado[0].nombreSolicitante });

                    this.state.numeroIdentificacionSolicitante = lineaCreditoXProgramaXEstadoFormalizado[0].numeroIdentificacionSolicitante;
                    this.setState({ numeroIdentificacionSolicitante: lineaCreditoXProgramaXEstadoFormalizado[0].numeroIdentificacionSolicitante });

                    this.state.tiposIdentificacionSeleccionada = lineaCreditoXProgramaXEstadoFormalizado[0].tipoIdentificacionSolicitante;
                    this.setState({ tiposIdentificacionSeleccionada: lineaCreditoXProgramaXEstadoFormalizado[0].tipoIdentificacionSolicitante });

                    /** */
                    let porcTotalGarantiasAportadas = lineaCreditoXProgramaXEstadoFormalizado[0].porcentajeTotalGarantiasAportadasSolicitante;

                    this.state.porcentajeTotalGarantiasAportadasSolicitante = lineaCreditoXProgramaXEstadoFormalizado[0].porcentajeTotalGarantiasAportadasSolicitante.toFixed(2);
                    this.setState({ porcentajeTotalGarantiasAportadasSolicitante: lineaCreditoXProgramaXEstadoFormalizado[0].porcentajeTotalGarantiasAportadasSolicitante.toFixed(2) });

                    let totalGarantiasAportadas = parseFloat(this.state.montoCredito.replace(/,/g, "")) * (porcTotalGarantiasAportadas / 100);

                    totalGarantiasAportadas = totalGarantiasAportadas.toFixed(2);

                    this.state.montoTotalGarantiasAportadasCliente = totalGarantiasAportadas;

                    let totalAval =
                        parseFloat(this.state.montoCredito.replace(/,/g, "")) -
                        this.state.montoTotalGarantiasAportadasCliente;

                    totalAval = totalAval.toFixed(2);
                    this.state.montoTotalAvalSolicitado = totalAval;

                    let totalPorcAval = 100 - this.state.porcentajeTotalGarantiasAportadasSolicitante;

                    totalPorcAval = totalPorcAval.toFixed(2);
                    this.state.porcentajeTotalAvalSolicitado = totalPorcAval;
                    this.setState({ montoTotalAvalSolicitado: totalAval });
                    this.setState({ porcentajeTotalAvalSolicitado: totalPorcAval });
                    /** */

                    this.asignarProgramaALineaCredito();
                }
                this.state.idNaturaleza = 1;
                this.setState({ idNaturaleza: 1 });
            }
        }
        if (event.target.value == REACT_APP_TIPO_PROGRAMA_CARTERA_PERDIDA_ESPERADA) {
            if (idFondo == REACT_APP_OPERADORA_FODEMIPYME) {
                this.state.indTipoSolicitud = true;
                this.setState({ indTipoSolicitud: true });

                $("#lineasCredito").hide();
                this.state.idNaturaleza = 0;
                this.setState({ idNaturaleza: 0 });

                let tipoSolicitud = this.state.tiposSolicitudLista.filter(
                    (x) => x.id == 0
                );
                this.state.tipoSolicitudSeleccionado = tipoSolicitud[0];
                this.setState({ tipoSolicitudSeleccionado: tipoSolicitud[0] });

                this.state.indIrPaso3 = true;
                this.setState({ indIrPaso3: true });
                this.limpiarGarantias();
                this.limpiarTotalesGarantiasAportadas();

                this.limpiarInformacionBasica();
            } else {
                this.state.indTipoSolicitud = false;
                this.setState({ indTipoSolicitud: false });
            }

            const programasCarteraPELista = localStorage.getItem(
                "programasCarteraPELista"
            );
            const programasCarteraPEListaJson = JSON.parse(programasCarteraPELista);

            if (programasCarteraPEListaJson == null) {
                this.cargarProgramaCarteraPE();
                return;
            }
            this.state.programasLista = programasCarteraPEListaJson;
            this.setState({
                programasLista: programasCarteraPEListaJson,
            });
            if (programasCarteraPEListaJson.length == 0) {
                this.state.programaSeleccionado = "";
                this.setState({
                    programaSeleccionado: "",
                });
                swal("Programas de cartera de pérdida esperada", "No existen programas de cartera de pérdida esperada activos en su entidad financiera", "warning", { button: "Aceptar", });

                $("#lineasCredito").hide();
                let tipoSolicitud = this.state.tiposSolicitudLista.filter(
                    (x) => x.id == 0
                );
                this.state.tipoSolicitudSeleccionado = tipoSolicitud[0];
                this.setState({ tipoSolicitudSeleccionado: tipoSolicitud[0] });
                this.limpiarTotalesGarantiasAportadas();
                this.limpiarInformacionBasica();
                this.limpiarGarantias();
                return;
            }

            this.state.programaSeleccionado = programasCarteraPEListaJson[0];
            this.setState({
                programaSeleccionado: programasCarteraPEListaJson[0],
            });
            if (
                this.state.programaSeleccionado.cliente.cedulaJuridica == REACT_APP_CEDULA_BP
            ) {
                this.state.indEntidadBPFODEMIPYME = true;
                this.setState({ indEntidadBPFODEMIPYME: true });
            } else {
                this.state.indEntidadBPFODEMIPYME = false;
                this.setState({ indEntidadBPFODEMIPYME: false });
            }


            // Aval + Linea de Credito
            if (this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_AVAL_LINEA_CREDITO && idFondo == REACT_APP_OPERADORA_FNA) {
                const lineasCreditoLista = localStorage.getItem("lineasCreditoLista");
                const lineasCreditoListaJson = JSON.parse(lineasCreditoLista);
                if (lineasCreditoListaJson == null) {
                    this.cargarLineasCreditos();
                } else {
                    var lineaCreditoXProgramaXEstadoFormalizado = [];
                    lineaCreditoXProgramaXEstadoFormalizado = lineasCreditoListaJson.filter(function (linea) {
                        return (
                            linea.tipoPrograma.id == programa[0].id && linea.estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO
                        );
                    });

                    if (lineaCreditoXProgramaXEstadoFormalizado.length == 0) {
                        swal(
                            "Crear Aval",
                            "No se encontraron líneas de crédito disponibles.",
                            "warning", { button: "Aceptar", }
                        );
                        $("#lineasCredito").hide();
                        let tipoSolicitud = this.state.tiposSolicitudLista.filter(
                            (x) => x.id == 0
                        );
                        this.setState({ tipoSolicitudSeleccionado: tipoSolicitud[0] });

                        this.limpiarTotalesGarantiasAportadas();
                        this.limpiarInformacionBasica();
                        return;
                    }

                    this.state.lineasCreditoLista = lineaCreditoXProgramaXEstadoFormalizado;
                    this.setState({
                        lineasCreditoLista: lineaCreditoXProgramaXEstadoFormalizado,
                    });
                    this.state.lineaCreditoSeleccionada = lineaCreditoXProgramaXEstadoFormalizado[0];
                    this.setState({ lineaCreditoSeleccionada: lineaCreditoXProgramaXEstadoFormalizado[0] });

                    this.state.nombreSolicitante = lineaCreditoXProgramaXEstadoFormalizado[0].nombreSolicitante;
                    this.setState({ nombreSolicitante: lineaCreditoXProgramaXEstadoFormalizado[0].nombreSolicitante });

                    this.state.numeroIdentificacionSolicitante = lineaCreditoXProgramaXEstadoFormalizado[0].numeroIdentificacionSolicitante;
                    this.setState({ numeroIdentificacionSolicitante: lineaCreditoXProgramaXEstadoFormalizado[0].numeroIdentificacionSolicitante });

                    this.state.tiposIdentificacionSeleccionada = lineaCreditoXProgramaXEstadoFormalizado[0].tipoIdentificacionSolicitante;
                    this.setState({ tiposIdentificacionSeleccionada: lineaCreditoXProgramaXEstadoFormalizado[0].tipoIdentificacionSolicitante });

                    /** */
                    let porcTotalGarantiasAportadas = lineaCreditoXProgramaXEstadoFormalizado[0].porcentajeTotalGarantiasAportadasSolicitante;

                    this.state.porcentajeTotalGarantiasAportadasSolicitante = lineaCreditoXProgramaXEstadoFormalizado[0].porcentajeTotalGarantiasAportadasSolicitante.toFixed(2);
                    this.setState({ porcentajeTotalGarantiasAportadasSolicitante: lineaCreditoXProgramaXEstadoFormalizado[0].porcentajeTotalGarantiasAportadasSolicitante.toFixed(2) });

                    let totalGarantiasAportadas = parseFloat(this.state.montoCredito.replace(/,/g, "")) * (porcTotalGarantiasAportadas / 100);

                    totalGarantiasAportadas = totalGarantiasAportadas.toFixed(2);

                    this.state.montoTotalGarantiasAportadasCliente = totalGarantiasAportadas;

                    let totalAval =
                        parseFloat(this.state.montoCredito.replace(/,/g, "")) -
                        this.state.montoTotalGarantiasAportadasCliente;

                    totalAval = totalAval.toFixed(2);
                    this.state.montoTotalAvalSolicitado = totalAval;

                    let totalPorcAval = 100 - this.state.porcentajeTotalGarantiasAportadasSolicitante;

                    totalPorcAval = totalPorcAval.toFixed(2);
                    this.state.porcentajeTotalAvalSolicitado = totalPorcAval;
                    this.setState({ montoTotalAvalSolicitado: totalAval });
                    this.setState({ porcentajeTotalAvalSolicitado: totalPorcAval });
                    /** */

                    this.asignarProgramaALineaCredito();
                }
                this.state.idNaturaleza = 1;
                this.setState({ idNaturaleza: 1 });
            }
        }
    };
    /**
     * 
     * @param {*} event 
     */
    cambioPrograma = (event) => {
        let programa = this.state.programasLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ programaSeleccionado: programa[0] });
        if (this.state.programaSeleccionado.cliente.cedulaJuridica == REACT_APP_CEDULA_BP) {
            this.state.indEntidadBPFODEMIPYME = true;
            this.setState({ indEntidadBPFODEMIPYME: true });
        } else {
            this.state.indEntidadBPFODEMIPYME = false;
            this.setState({ indEntidadBPFODEMIPYME: false });
        }
    };
    /**
     * 
     * @param {*} event 
     */
    cambioTipoSolicitud = (event) => {
        let tipoSolicitud = this.state.tiposSolicitudLista.filter(
            (x) => x.id == event.target.value
        );
        if (tipoSolicitud[0].id == REACT_APP_NATURALEZA_AVAL_LINEA_CREDITO) {
            $("#lineasCredito").show();
            const lineasCreditoLista = localStorage.getItem("lineasCreditoLista");
            const lineasCreditoListaJson = JSON.parse(lineasCreditoLista);
            if (lineasCreditoListaJson == null) {
                this.cargarLineasCreditos();
            } else {
                var lineaCreditoXProgramaXEstadoFormalizado = [];
                var idTipoProgramaSeleccionado = this.state.tipoProgramaSeleccionado.id;
                lineaCreditoXProgramaXEstadoFormalizado = lineasCreditoListaJson.filter(function (linea) {
                    return (
                        linea.tipoPrograma.id == idTipoProgramaSeleccionado && linea.estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO
                    );
                });

                if (lineaCreditoXProgramaXEstadoFormalizado.length == 0) {
                    swal(
                        "Crear Aval",
                        "No se encontraron líneas de crédito disponibles.",
                        "warning", { button: "Aceptar", }
                    );
                    $("#lineasCredito").hide();
                    let tipoSolicitud = this.state.tiposSolicitudLista.filter(
                        (x) => x.id == 0
                    );
                    this.setState({ tipoSolicitudSeleccionado: tipoSolicitud[0] });
                    this.limpiarTotalesGarantiasAportadas();
                    this.limpiarInformacionBasica();
                    this.limpiarGarantias();
                    return;
                }

                this.state.lineasCreditoLista = lineaCreditoXProgramaXEstadoFormalizado;
                this.setState({
                    lineasCreditoLista: lineaCreditoXProgramaXEstadoFormalizado,
                });
                this.state.lineaCreditoSeleccionada = lineaCreditoXProgramaXEstadoFormalizado[0];
                this.setState({ lineaCreditoSeleccionada: lineaCreditoXProgramaXEstadoFormalizado[0] });

                this.state.nombreSolicitante = lineaCreditoXProgramaXEstadoFormalizado[0].nombreSolicitante;
                this.setState({ nombreSolicitante: lineaCreditoXProgramaXEstadoFormalizado[0].nombreSolicitante });

                this.state.numeroIdentificacionSolicitante = lineaCreditoXProgramaXEstadoFormalizado[0].numeroIdentificacionSolicitante;
                this.setState({ numeroIdentificacionSolicitante: lineaCreditoXProgramaXEstadoFormalizado[0].numeroIdentificacionSolicitante });

                this.state.tiposIdentificacionSeleccionada = lineaCreditoXProgramaXEstadoFormalizado[0].tipoIdentificacionSolicitante;
                this.setState({ tiposIdentificacionSeleccionada: lineaCreditoXProgramaXEstadoFormalizado[0].tipoIdentificacionSolicitante });

                this.state.porcentajeTotalGarantiasAportadasSolicitante = lineaCreditoXProgramaXEstadoFormalizado[0].porcentajeTotalGarantiasAportadasSolicitante.toFixed(2);
                this.setState({ porcentajeTotalGarantiasAportadasSolicitante: lineaCreditoXProgramaXEstadoFormalizado[0].porcentajeTotalGarantiasAportadasSolicitante.toFixed(2) });

                this.limpiarGarantias();
                this.asignarProgramaALineaCredito();
            }
            this.state.idNaturaleza = 1;
            this.setState({ idNaturaleza: 1 });
            this.state.indIrPaso3 = false;
            this.setState({ indIrPaso3: false });
        } else {
            $("#lineasCredito").hide();
            this.state.idNaturaleza = 0;
            this.setState({ idNaturaleza: 0 });

            //Limpiar Nombre y Cedula
            this.state.lineaCreditoSeleccionada = "";
            this.setState({ lineaCreditoSeleccionada: "" });

            this.state.nombreSolicitante = "";
            this.setState({ nombreSolicitante: "" });

            this.state.numeroIdentificacionSolicitante = "";
            this.setState({ numeroIdentificacionSolicitante: "" });

            const tipoIdentificacionInicial = localStorage.getItem("tipoIdentificacionInicial");

            this.state.tiposIdentificacionSeleccionada = JSON.parse(tipoIdentificacionInicial);
            this.setState({ tiposIdentificacionSeleccionada: JSON.parse(tipoIdentificacionInicial) });
            this.limpiarTotalesGarantiasAportadas();
            this.limpiarInformacionBasica();
            this.state.indIrPaso3 = true;
            this.setState({ indIrPaso3: true });
        }

        if (tipoSolicitud[0].id == REACT_APP_NATURALEZA_LINEA_CREDITO || tipoSolicitud[0].id == REACT_APP_NATURALEZA_AVAL_LINEA_CREDITO) {
            let tipoCreditoNormal = 0;
            let tipoCredito = this.state.tipoCreditoLista.filter(
                (x) => x.id == tipoCreditoNormal
            );
            this.state.tipoCreditoSeleccionado = tipoCredito[0];
            this.setState({ tipoCreditoSeleccionado: tipoCredito[0] });

            this.state.tienePlazoBallon = false;
            this.setState({ tienePlazoBallon: false });
            this.state.plazoBallonMeses = "";
            this.setState({ plazoBallonMeses: "" });
            this.calcularCuotaNivelada();
        }

        this.setState({ tipoSolicitudSeleccionado: tipoSolicitud[0] });
    };
    /**
     * 
     * @param {*} event 
     */
    cambioLineaCredito = (event) => {
        let lineaCredito = this.state.lineasCreditoLista.filter(
            (x) => x.id == event.target.value
        );
        //Programa asignado a la Linea

        if (lineaCredito.length > 0) {
            var idProgramaSeleccionado = lineaCredito[0].programa.detallePrograma.id

            if (this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_INDIVIDUAL) {
                const programasIndividualesLista = localStorage.getItem(
                    "programasIndividualesLista"
                );
                const programasIndividualesListaJson = JSON.parse(
                    programasIndividualesLista
                );
                if (programasIndividualesListaJson != null) {
                    if (programasIndividualesListaJson.length > 0) {

                        let programa = programasIndividualesListaJson.filter(
                            (x) => x.detallePrograma.id == idProgramaSeleccionado
                        );

                        this.state.programaSeleccionado = programa[0];
                        this.setState({
                            programaSeleccionado: programa[0],
                        });
                    }
                }
            }

            if (this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_CARTERA_NORMAL) {
                const programasCarteraLista = localStorage.getItem(
                    "programasCarteraLista"
                );
                const programasCarteraListaJson = JSON.parse(programasCarteraLista);
                if (programasCarteraListaJson == null) {
                    this.cargarProgramaCartera();
                } else {
                    if (programasCarteraListaJson.length > 0) {
                        let programa = programasCarteraListaJson.filter(
                            (x) => x.detallePrograma.id == idProgramaSeleccionado
                        );

                        this.state.programaSeleccionado = programa[0];
                        this.setState({
                            programaSeleccionado: programa[0],
                        });
                    }
                }
            }

            if (this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_CARTERA_PERDIDA_ESPERADA) {
                const programasCarteraPELista = localStorage.getItem(
                    "programasCarteraPELista"
                );
                const programasCarteraPEListaJson = JSON.parse(programasCarteraPELista);
                if (programasCarteraPEListaJson == null) {
                    this.cargarProgramaCarteraPE();
                } else {
                    this.setState({
                        programasLista: programasCarteraPEListaJson,
                    });
                    if (programasCarteraPEListaJson.length > 0) {
                        let programa = programasCarteraPEListaJson.filter(
                            (x) => x.detallePrograma.id == idProgramaSeleccionado
                        );

                        this.state.programaSeleccionado = programa[0];
                        this.setState({
                            programaSeleccionado: programa[0],
                        });
                    }
                }
            }
        }

        this.state.lineaCreditoSeleccionada = lineaCredito[0];
        this.setState({ lineaCreditoSeleccionada: lineaCredito[0] });

        this.state.nombreSolicitante = lineaCredito[0].nombreSolicitante;
        this.setState({ nombreSolicitante: lineaCredito[0].nombreSolicitante });

        this.state.numeroIdentificacionSolicitante = lineaCredito[0].numeroIdentificacionSolicitante;
        this.setState({ numeroIdentificacionSolicitante: lineaCredito[0].numeroIdentificacionSolicitante });

        this.state.tiposIdentificacionSeleccionada = lineaCredito[0].tipoIdentificacionSolicitante;
        this.setState({ tiposIdentificacionSeleccionada: lineaCredito[0].tipoIdentificacionSolicitante });

        let porcTotalGarantiasAportadas = lineaCredito[0].porcentajeTotalGarantiasAportadasSolicitante;

        this.state.porcentajeTotalGarantiasAportadasSolicitante = lineaCredito[0].porcentajeTotalGarantiasAportadasSolicitante.toFixed(2);
        this.setState({ porcentajeTotalGarantiasAportadasSolicitante: lineaCredito[0].porcentajeTotalGarantiasAportadasSolicitante.toFixed(2) });

        let totalGarantiasAportadas = parseFloat(this.state.montoCredito.replace(/,/g, "")) * (porcTotalGarantiasAportadas / 100);

        totalGarantiasAportadas = totalGarantiasAportadas.toFixed(2);

        this.state.montoTotalGarantiasAportadasCliente = totalGarantiasAportadas;

        let totalAval =
            parseFloat(this.state.montoCredito.replace(/,/g, "")) -
            this.state.montoTotalGarantiasAportadasCliente;

        totalAval = totalAval.toFixed(2);
        this.state.montoTotalAvalSolicitado = totalAval;

        let totalPorcAval = 100 - this.state.porcentajeTotalGarantiasAportadasSolicitante;

        totalPorcAval = totalPorcAval.toFixed(2);
        this.state.porcentajeTotalAvalSolicitado = totalPorcAval;
        this.setState({ montoTotalAvalSolicitado: totalAval });
        this.setState({ porcentajeTotalAvalSolicitado: totalPorcAval });
    };
    /**
     * Asignar mismo programa que la linea de credito
     */
    asignarProgramaALineaCredito = () => {
        let lineaCredito = this.state.lineasCreditoLista.filter(
            (x) => x.id == this.state.lineaCreditoSeleccionada.id
        );

        //Programa asignado a la Linea

        if (lineaCredito.length > 0) {
            var idProgramaSeleccionado = lineaCredito[0].programa.detallePrograma.id

            if (this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_INDIVIDUAL) {
                const programasIndividualesLista = localStorage.getItem(
                    "programasIndividualesLista"
                );
                const programasIndividualesListaJson = JSON.parse(
                    programasIndividualesLista
                );
                if (programasIndividualesListaJson != null) {
                    if (programasIndividualesListaJson.length > 0) {

                        let programa = programasIndividualesListaJson.filter(
                            (x) => x.detallePrograma.id == idProgramaSeleccionado
                        );

                        this.state.programaSeleccionado = programa[0];
                        this.setState({
                            programaSeleccionado: programa[0],
                        });
                    }
                }
            }

            if (this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_CARTERA_NORMAL) {
                const programasCarteraLista = localStorage.getItem(
                    "programasCarteraLista"
                );
                const programasCarteraListaJson = JSON.parse(programasCarteraLista);
                if (programasCarteraListaJson == null) {
                    this.cargarProgramaCartera();
                } else {
                    if (programasCarteraListaJson.length > 0) {
                        let programa = programasCarteraListaJson.filter(
                            (x) => x.detallePrograma.id == idProgramaSeleccionado
                        );

                        this.state.programaSeleccionado = programa[0];
                        this.setState({
                            programaSeleccionado: programa[0],
                        });
                    }
                }
            }

            if (this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_CARTERA_PERDIDA_ESPERADA) {
                const programasCarteraPELista = localStorage.getItem(
                    "programasCarteraPELista"
                );
                const programasCarteraPEListaJson = JSON.parse(programasCarteraPELista);
                if (programasCarteraPEListaJson == null) {
                    this.cargarProgramaCarteraPE();
                } else {
                    this.setState({
                        programasLista: programasCarteraPEListaJson,
                    });
                    if (programasCarteraPEListaJson.length > 0) {
                        let programa = programasCarteraPEListaJson.filter(
                            (x) => x.detallePrograma.id == idProgramaSeleccionado
                        );

                        this.state.programaSeleccionado = programa[0];
                        this.setState({
                            programaSeleccionado: programa[0],
                        });
                    }
                }
            }
        }
    }
    /**
     * Limpiar informacon de linea de credito
     */
    limpiarInformacionBasica = () => {

        this.state.lineaCreditoSeleccionada = "";
        this.setState({ lineaCreditoSeleccionada: "" });

        this.state.nombreSolicitante = "";
        this.setState({ nombreSolicitante: "" });

        this.state.numeroIdentificacionSolicitante = "";
        this.setState({ numeroIdentificacionSolicitante: "" });

        const tipoIdentificacionInicial = localStorage.getItem("tipoIdentificacionInicial");

        this.state.tiposIdentificacionSeleccionada = JSON.parse(tipoIdentificacionInicial);
        this.setState({ tiposIdentificacionSeleccionada: JSON.parse(tipoIdentificacionInicial) });
    };
    limpiarGarantias = () => {
        let totalGarantiasAportadas = parseFloat(this.state.montoCredito.replace(/,/g, "")) * (this.state.porcentajeTotalGarantiasAportadasSolicitante / 100);

        totalGarantiasAportadas = totalGarantiasAportadas.toFixed(2);

        this.state.montoTotalGarantiasAportadasCliente = totalGarantiasAportadas;

        let totalAval =
            parseFloat(this.state.montoCredito.replace(/,/g, "")) -
            this.state.montoTotalGarantiasAportadasCliente;

        totalAval = totalAval.toFixed(2);
        this.state.montoTotalAvalSolicitado = totalAval;

        let totalPorcAval = 100 - this.state.porcentajeTotalGarantiasAportadasSolicitante;

        totalPorcAval = totalPorcAval.toFixed(2);
        this.state.porcentajeTotalAvalSolicitado = totalPorcAval;
        this.state.tablaGarantiasAportadas = [];

        this.setState(this.state);
    };
    limpiarTotalesGarantiasAportadas = () => {
        this.state.porcentajeTotalGarantiasAportadasSolicitante = 0;
        this.state.montoTotalGarantiasAportadasCliente = 0;

        let totalPorcAval = 100 - this.state.porcentajeTotalGarantiasAportadasSolicitante;

        totalPorcAval = totalPorcAval.toFixed(2);
        this.state.porcentajeTotalAvalSolicitado = totalPorcAval;
        this.setState({ porcentajeTotalAvalSolicitado: totalPorcAval });

        let totalAval =
            parseFloat(this.state.montoCredito.replace(/,/g, "")) -
            this.state.montoTotalGarantiasAportadasCliente;
        totalAval = totalAval.toFixed(2);
        this.state.montoTotalAvalSolicitado = totalAval;

        this.setState({ montoTotalAvalSolicitado: totalAval });
    };
    /**
     * 
     * @param {*} event 
     */
    cambioTipoIdentificacion = (event) => {
        let identificacion = this.state.tiposIdentificacionLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ tiposIdentificacionSeleccionada: identificacion[0] });
    };
    /**
     * 
     * @param {*} event 
     */
    cambioProvincia = (event) => {
        let provincia = this.state.provinciaLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ provinciaSeleccionada: provincia[0] });
        this.setState({
            cantonLista: provincia[0].cantones,
        });
        this.setState({
            cantonSeleccionado: provincia[0].cantones[0],
        });
        this.setState({
            distritoLista: provincia[0].cantones[0].distritos,
        });
        this.setState({
            distritoSeleccionado: provincia[0].cantones[0].distritos[0],
        });
    };
    /**
     * 
     * @param {*} event 
     */
    cambioCanton = (event) => {
        let canton = this.state.cantonLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ cantonSeleccionado: canton[0] });

        this.setState({
            distritoLista: canton[0].distritos,
        });
        this.setState({
            distritoSeleccionado: canton[0].distritos[0],
        });
    };
    /**
     * 
     * @param {*} event 
     */
    cambioDistrito = (event) => {
        let distrito = this.state.distritoLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ distritoSeleccionado: distrito[0] });
    };
    /**
     * 
     * @param {*} event 
     */
    cambioSectorEconomico = (event) => {
        let sector = this.state.sectorEconomicoLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ sectorEconomicoSeleccionado: sector[0] });
    };
    /**
     * 
     * @param {*} event 
     */
    cambioCodigoCIIUU = (event) => {
        let codigoCIIUU = this.state.codigoCIIULista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ codigoCIIUSeleccionado: codigoCIIUU[0] });
    };
    /**
     * 
     * @param {*} event 
     */
    cambioSesionCIIUU = (event) => {
        const codigosCIIULista = localStorage.getItem("codigosCIIULista");
        const codigosCIIUListaJson = JSON.parse(codigosCIIULista);
        let divisionLista = [];
        let grupoLista = [];
        let claseLista = [];
        let codigoLista = [];
        let seccionCIIUU = this.state.seccionCIIULista.filter(
            (x) => x == event.target.value
        );
        divisionLista = codigosCIIUListaJson
            .filter((seccion) => {
                return seccion.seccion == seccionCIIUU[0];
            })
            .map((x) => {
                return x.division;
            });
        let divisionSinRepetidos = this.sinRepetidos(divisionLista);
        this.setState({ divisionCIIULista: divisionSinRepetidos });
        this.setState({
            divisionCIIUSeleccionado: divisionSinRepetidos[0],
        });
        this.setState({ seccionCIIUSeleccionado: seccionCIIUU[0] });
        grupoLista = codigosCIIUListaJson
            .filter((division) => {
                return division.division == divisionSinRepetidos[0];
            })
            .map((x) => {
                return x.grupo;
            });
        let grupoSinRepetidos = this.sinRepetidos(grupoLista);
        this.setState({
            grupoCIIULista: grupoSinRepetidos,
        });
        this.setState({
            grupoCIIUSeleccionado: grupoSinRepetidos[0],
        });
        claseLista = codigosCIIUListaJson
            .filter((grupo) => {
                return grupo.grupo == grupoSinRepetidos[0];
            })
            .map((x) => {
                return x.clase;
            });
        let claseSinRepetidos = this.sinRepetidos(claseLista);
        this.setState({
            claseCIIUULista: claseSinRepetidos,
        });
        this.setState({
            claseCIIUUSeleccionado: claseSinRepetidos[0],
        });
        codigoLista = codigosCIIUListaJson
            .filter((clase) => {
                return clase.clase == claseSinRepetidos[0];
            })
            .map((x) => {
                return x.codigo;
            });
        let codigoSinRepetidos = this.sinRepetidos(codigoLista);
        this.setState({
            codigoCIIU: codigoSinRepetidos[0],
        });
    };
    /**
     * 
     * @param {*} event 
     */
    cambioDivisionCIIUU = (event) => {
        const codigosCIIULista = localStorage.getItem("codigosCIIULista");
        const codigosCIIUListaJson = JSON.parse(codigosCIIULista);
        let grupoLista = [];
        let claseLista = [];
        let codigoLista = [];
        let divisionCIIUU = this.state.divisionCIIULista.filter(
            (x) => x == event.target.value
        );
        grupoLista = codigosCIIUListaJson
            .filter((division) => {
                return division.division == divisionCIIUU[0];
            })
            .map((x) => {
                return x.grupo;
            });
        let grupoSinRepetidos = this.sinRepetidos(grupoLista);
        this.setState({
            grupoCIIULista: grupoSinRepetidos,
        });
        this.setState({
            grupoCIIUSeleccionado: grupoSinRepetidos[0],
        });
        claseLista = codigosCIIUListaJson
            .filter((grupo) => {
                return grupo.grupo == grupoSinRepetidos[0];
            })
            .map((x) => {
                return x.clase;
            });
        let claseSinRepetidos = this.sinRepetidos(claseLista);
        this.setState({
            claseCIIUULista: claseSinRepetidos,
        });
        this.setState({
            claseCIIUUSeleccionado: claseSinRepetidos[0],
        });
        codigoLista = codigosCIIUListaJson
            .filter((clase) => {
                return clase.clase == claseSinRepetidos[0];
            })
            .map((x) => {
                return x.codigo;
            });
        let codigoSinRepetidos = this.sinRepetidos(codigoLista);
        this.setState({
            codigoCIIU: codigoSinRepetidos[0],
        });
        this.setState({ divisionCIIUSeleccionado: divisionCIIUU[0] });
    };
    /**
     * 
     * @param {*} event 
     */
    cambioGrupoCIIUU = (event) => {
        const codigosCIIULista = localStorage.getItem("codigosCIIULista");
        const codigosCIIUListaJson = JSON.parse(codigosCIIULista);
        let claseLista = [];
        let codigoLista = [];
        let grupoCIIUU = this.state.grupoCIIULista.filter(
            (x) => x == event.target.value
        );
        claseLista = codigosCIIUListaJson
            .filter((grupo) => {
                return grupo.grupo == grupoCIIUU[0];
            })
            .map((x) => {
                return x.clase;
            });
        let claseSinRepetidos = this.sinRepetidos(claseLista);
        this.setState({
            claseCIIUULista: claseSinRepetidos,
        });
        this.setState({
            claseCIIUUSeleccionado: claseSinRepetidos[0],
        });
        codigoLista = codigosCIIUListaJson
            .filter((clase) => {
                return clase.clase == claseSinRepetidos[0];
            })
            .map((x) => {
                return x.codigo;
            });
        let codigoSinRepetidos = this.sinRepetidos(codigoLista);
        this.setState({
            codigoCIIU: codigoSinRepetidos[0],
        });
        this.setState({ grupoCIIUSeleccionado: grupoCIIUU[0] });
    };
    /**
     * 
     * @param {*} event 
     */
    cambioClaseCIIUU = (event) => {
        const codigosCIIULista = localStorage.getItem("codigosCIIULista");
        const codigosCIIUListaJson = JSON.parse(codigosCIIULista);
        let codigoLista = [];
        let claseCIIUU = this.state.claseCIIUULista.filter(
            (x) => x == event.target.value
        );
        codigoLista = codigosCIIUListaJson
            .filter((clase) => {
                return clase.clase == claseCIIUU[0];
            })
            .map((x) => {
                return x.codigo;
            });
        let codigoSinRepetidos = this.sinRepetidos(codigoLista);
        this.setState({
            codigoCIIU: codigoSinRepetidos[0],
        });
        this.setState({ claseCIIUUSeleccionado: claseCIIUU[0] });
    };
    /**
     * 
     * @param {*} event 
     */
    cambioCIIUU = (event) => {
        let ciiuu = this.state.ciiuuLista.filter((x) => x == event.target.value);
        this.setState({ ciiuSeleccionado: ciiuu[0] });
    };
    /**
     * 
     * @param {*} event 
     */
    cambioNivelComportamientoPagoSBD = (event) => {
        let nivelSBD = this.state.nivelPagoHistoricoSBDLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ nivelPagoHistoricoSBDSeleccionado: nivelSBD[0] });
    };
    /**
     * 
     * @param {*} event 
     */
    cambioNivelCIC = (event) => {
        let nivelCIC = this.state.nivelCICLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ nivelCICSeleccionado: nivelCIC[0] });
    };
    /**
     * 
     * @param {*} event 
     */
    cambioNivelHistoricoPagoEntidad = (event) => {
        let nivelHistorico = this.state.nivelHistoricoPagoEntidadLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ nivelHistoricoPagoEntidadSeleccionado: nivelHistorico[0] });
    };
    /**
     * 
     * @param {*} event 
     */
    cambioTamanoEmpresa = (event) => {
        let tamanoEmpresa = this.state.tamanoEmpresaLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ tamanoEmpresaSeleccionada: tamanoEmpresa[0] });
    };
    /**
     * 
     * @param {*} event 
     */
    cambioTipoGarantiaAdicional = (event) => {
        let tipoGarantia = this.state.tipoGarantiaAdicionalLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ tipoGarantiaAdicionalSeleccionada: tipoGarantia[0] });
    };
    /**
     * 
     * @param {*} event 
     */
    cambioMejoraCondicionesPagos = (event) => {
        let mejoraCondicionPago = this.state.mejoraCondicionesLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ mejoraCondicionesSeleccionado: mejoraCondicionPago[0] });
    };
    /**
     * 
     * @param {*} event 
     */
    cambioFNATipoBeneficio = (event) => {
        this.state.idTiposBeneficios = Array.from(event.target.selectedOptions, (item) => item.value);

        let tipoBeneficioMejora = this.state.idTiposBeneficios.filter(
            (x) => x == 95
        );
        let tipoBeneficioCualquierOtra = this.state.idTiposBeneficios.filter(
            (x) => x == 96
        );
        if (tipoBeneficioMejora.length > 0) {
            this.state.indCondicionesPago = true;
            const mejoraCondicionesListaJson = JSON.parse(localStorage.getItem("mejoraCondicionesLista"));
            if (mejoraCondicionesListaJson == null) {
                this.cargarMejorasCondicionesPago();
            } else {
                this.state.mejoraCondicionesLista = mejoraCondicionesListaJson;
                this.state.mejoraCondicionesSeleccionado = mejoraCondicionesListaJson[0];
            }
        } else {
            this.state.indCondicionesPago = false;
        }

        if (tipoBeneficioCualquierOtra.length > 0) {
            this.state.indDescripcionMejoraCondicionesPago = true;
        } else {
            this.state.indDescripcionMejoraCondicionesPago = false;
            this.state.descripcionMejoraCondicion = "";
        }
        this.setState(this.state);
    };
    /**
     * 
     * @param {*} event 
     */
    cambioTipoGarantiaTabla = (event) => {
        let tipoGarantia = this.state.tipoGarantiaAdicionalLista.filter(
            (x) => x.id == event.target.value
        );
        this.state.tipoGarantiaSeleccionadaTabla = tipoGarantia[0];
        this.setState({ tipoGarantiaSeleccionadaTabla: tipoGarantia[0] });
    };
    /**
     * 
     * @param {*} event 
     */
    cambioTipoCredito = (event) => {
        let tipoCredito = this.state.tipoCreditoLista.filter(
            (x) => x.id == event.target.value
        );
        if (tipoCredito[0].id == 1) {
            this.state.tienePlazoBallon = true;
            this.setState({ tienePlazoBallon: true });
        } else {
            this.state.tienePlazoBallon = false;
            this.setState({ tienePlazoBallon: false });
            this.state.plazoBallonMeses = "";
            this.setState({ plazoBallonMeses: "" });
            this.calcularCuotaNivelada();
        }
        this.setState({ tipoCreditoSeleccionado: tipoCredito[0] });
    };
    /**
     * Calcular la cuota del credito
     */
    calcularCuotaNivelada = () => {
        // tasa anual
        var t = this.state.tasaInteresAnual;
        // plazo meses
        var p = this.state.plazoCreditoMeses;
        if (this.state.tienePlazoBallon) {
            p = this.state.plazoBallonMeses;
        }
        p = "0" + p;
        // frecuencia pago
        var fp = 1;
        //monto
        var monto = parseFloat(this.state.montoCredito.replace(/,/g, ""));

        var ft = 0;

        var cuotaMensual = 0;

        var divisionPotencia = p / fp;

        ft = (t / 1200) * 1;
        ft = ft.toFixed(14);
        ft = parseFloat(ft);

        var sumaFT = ft + 1;

        var potencia = sumaFT ** divisionPotencia;

        var resultadoNumerador = ft * potencia;

        var resultadoDenominador = potencia - 1;

        var divisionFormula = resultadoNumerador / resultadoDenominador;

        var cuotaMensual = divisionFormula * monto;

        this.state.cuotaPeriodica = cuotaMensual.toFixed(2);
        this.setState({ cuotaPeriodica: cuotaMensual.toFixed(2) });
        this.state.cuotaMensualNuevoCredito = cuotaMensual.toFixed(2);
        this.setState({ cuotaMensualNuevoCredito: cuotaMensual.toFixed(2) });
        let cuotaAnual = cuotaMensual * 12;
        this.setState({ cuotaAnualNuevoCredito: cuotaAnual.toFixed(2) });
    };
    /**
     * 
     * @param {*} event 
     */
    cambioFuenteReferenciaIngreso = (event) => {
        let referenciaIngresos = this.state.fuenteReferenciaInformacionIngresosLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({
            fuenteReferenciaInformacionIngresosSeleccionado: referenciaIngresos[0],
        });
    };
    /**
     * 
     * @param {*} event 
     */
    cambioFuenteReferenciaEgreso = (event) => {
        let referenciaEgresos = this.state.fuenteReferenciaInformacionEgresosLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({
            fuenteReferenciaInformacionEgresosSeleccionado: referenciaEgresos[0],
        });
    };
    /**
     * 
     * @param {*} event 
     */
    cambioEjecutivoCreador = (event) => {
        let ejecutivo = this.state.ejecutivoCreadorLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({
            ejecutivoCreadorSeleccionado: ejecutivo[0],
        });
    };
    /**
     * 
     * @param {*} event 
     */
    cambioCertificacionCCSS = (event) => {
        let estadoCCSS = this.state.estadoCertificacionCCSSLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({
            estadoCertificacionCCSSSeleccionado: estadoCCSS[0],
        });
    };
    /**
     * 
     * @param {*} event 
     */
    cambioEstadoTributacion = (event) => {
        let estado = this.state.estadoTributacionDirectaLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({
            estadoTributacionDirectaSeleccionada: estado[0],
        });
    };
    /**
     * 
     * @param {*} event 
     */
    cambioRiesgoTrabajo = (event) => {
        let riesgo = this.state.riesgosTrabajoLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({
            riesgosTrabajoSeleccionado: riesgo[0],
        });
    };
    /**
     * 
     * @param {*} event 
     */
    cambioTamanoPymePymPa = (event) => {
        let tamano = this.state.tamanoPymePympaLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({
            tamanoPymePympaSeleccionado: tamano[0],
        });
    };
    /**
     * 
     * @param {*} event 
     */
    cambionConsultaCICSUGEF = (event) => {
        if (event.target.value === "SI") {
            this.state.consultaAbiertaCICSUGEF = true;
            this.setState({ consultaAbiertaCICSUGE: true });
        } else {
            this.state.consultaAbiertaCICSUGEF = false;
            this.setState({ consultaAbiertaCICSUGE: false });
        }
    };
    /**
     * 
     * @param {*} event 
     */
    cambionAfectacionCOVID19 = (event) => {
        if (event.target.value === "SI") {
            this.state.demostroAfectacionCOVID = true;
        } else {
            this.state.demostroAfectacionCOVID = false;
            swal(
                "Parámetros de Análisis - Campo 2",
                "La selección realizada hace que esta solicitud no pueda ser beneficiaria de un aval de FNA",
                "warning", { button: "Aceptar", }
            );
        }
        this.setState(this.state);
    };
    /**
     * 
     * @param {*} event 
     */
    cambionActividadDentroPais = (event) => {
        if (event.target.value === "Dentro del país") {
            this.state.realizaActividadDentroDelPais = true;
        } else {
            this.state.realizaActividadDentroDelPais = false;
            swal(
                "Parámetros de Análisis - Campo 3",
                "La selección realizada hace que esta solicitud no pueda ser beneficiaria de un aval de FNA",
                "warning", { button: "Aceptar", }
            );
        }
        this.setState(this.state);
    };
    /**
     * 
     * @param {*} event 
     */
    cambionConservacionEmpleo = (event) => {
        if (event.target.value === "SI") {
            this.state.compromisoConservacionEmpleo = true;
        } else {
            this.state.compromisoConservacionEmpleo = false;
            swal(
                "Parámetros de Análisis - Campo 4",
                "La selección realizada hace que esta solicitud no pueda ser beneficiaria de un aval de FNA",
                "warning", { button: "Aceptar", }
            );
        }
        this.setState(this.state);
    };
    /**
     * 
     * @param {*} event 
     */
    cambionBeneficiarioConglomerado = (event) => {
        if (event.target.value === "SI") {
            this.state.beneficiaroPerteneceConglomerado = true;
            swal(
                "Parámetros de Análisis - Campo 5",
                "La selección realizada hace que esta solicitud no pueda ser beneficiaria de un aval de FNA",
                "warning", { button: "Aceptar", }
            );
        } else {
            this.state.beneficiaroPerteneceConglomerado = false;
        }
        this.setState(this.state);
    };
    /**
     * 
     * @param {*} event 
     */
    cambionMorosidadPrevioCOVID = (event) => {
        if (event.target.value === "SI") {
            this.state.morosidadPrevioCOVIDMenor60 = true;
        } else {
            this.state.morosidadPrevioCOVIDMenor60 = false;
            swal(
                "Parámetros de Análisis - Campo 6",
                "La selección realizada hace que esta solicitud no pueda ser beneficiaria de un aval de FNA",
                "warning", { button: "Aceptar", }
            );
        }
        this.setState(this.state);
    };
    /**
     * 
     * @param {*} event 
     */
    cambioAvalSustituyeGarantia = (event) => {
        if (event.target.value === "Sustituir garantía") {
            this.state.avalSustituyeGarantia = true;
            swal(
                "Parámetros de Análisis - Campo 7",
                "La selección realizada hace que esta solicitud no pueda ser beneficiaria de un aval de FNA",
                "warning", { button: "Aceptar", }
            );
        } else {
            this.state.avalSustituyeGarantia = false;
        }
        this.setState(this.state);
    };
    /**
     * 
     * @param {*} event 
     */
    cambioClienteCapacidadPago = (event) => {
        if (event.target.value === "SI") {
            this.state.clienteConCapacidadPago = true;
        } else {
            this.state.clienteConCapacidadPago = false;
            swal(
                "Parámetros de Análisis - Campo 8",
                "La selección realizada hace que esta solicitud no pueda ser beneficiaria de un aval de FNA",
                "warning", { button: "Aceptar", }
            );
        }
        this.setState(this.state);
    };
    /**
     * 
     * @param {*} event 
     * @returns Alamacena en el State lo digitado en el formulario. 
     **/
    encargarseCambio = (event) => {
        var preg = /^([0-9]+\.?[0-9]{0,2})$/;
        var pregPuntajes = /^([0-9]+\.?[0-9]{0,4})$/;

        if (event.target.name === "cuotaMensualCreditoCIC") {

            const soloNumerosCuotaMensualCreditoCIC = preg.test(
                event.target.value.replace(/,/g, "")
            );

            if (!soloNumerosCuotaMensualCreditoCIC) {
                swal(
                    "Crear Aval",
                    "Las cuotas mensuales de créditos en el CIC no tienen un formato válido",
                    "warning", { button: "Aceptar", }
                );
                var cuotaMensualCreditoCICFloat = parseFloat(event.target.value.replace(/,/g, "")).toFixed(2);
                this.setState({ cuotaMensualCreditoCIC: cuotaMensualCreditoCICFloat });
                return;
            }

            let cuotaMensulaCICFloat = parseFloat(
                event.target.value.replace(/,/g, "")
            ).toFixed(2);
            let cuotaMensualPrestamosFloat = parseFloat(
                this.state.cuotaMensualOtrosPrestamo.replace(/,/g, "")
            ).toFixed(2);
            let totalCuotasMensual =
                parseFloat(cuotaMensulaCICFloat) +
                parseFloat(cuotaMensualPrestamosFloat);
            totalCuotasMensual = parseFloat(totalCuotasMensual.toFixed(2));
            let totalCuotaAnualCIC = parseFloat(cuotaMensulaCICFloat) * 12;
            this.setState({ totalCuotasMensual: totalCuotasMensual });
            this.setState({ montoAnualCreditoCIC: totalCuotaAnualCIC.toFixed(2) });

            var totalCuotasAnualCICFixed = parseFloat(totalCuotaAnualCIC.toFixed(2));

            var totalCuotasAnualPrestamosFixed = parseFloat(
                this.state.montoAnualOtrosPrestamos
            );

            var sumaTotalCuotasAnual =
                totalCuotasAnualPrestamosFixed + totalCuotasAnualCICFixed;

            var sumaTotalCuotasAnualFloat = parseFloat(sumaTotalCuotasAnual).toFixed(2);

            this.setState({
                montoTotalCuotasAnuales: sumaTotalCuotasAnualFloat,
            });
        }
        if (event.target.name === "cuotaMensualOtrosPrestamo") {

            const soloNumerosCuotaMensualOtrosPrestamo = preg.test(
                event.target.value.replace(/,/g, "")
            );

            if (!soloNumerosCuotaMensualOtrosPrestamo) {
                swal(
                    "Crear Aval",
                    "Las cuotas mensuales de préstamos con otros acreedores no tienen un formato válido",
                    "warning", { button: "Aceptar", }
                );
                var cuotaMensualOtrosPrestamoFloat = parseFloat(event.target.value.replace(/,/g, "")).toFixed(2);
                this.setState({ cuotaMensualOtrosPrestamo: cuotaMensualOtrosPrestamoFloat });
                return;
            }

            let cuotaPrestamosFloat = parseFloat(
                event.target.value.replace(/,/g, "")
            ).toFixed(2);
            let cuotaCICFloat = parseFloat(
                this.state.cuotaMensualCreditoCIC.replace(/,/g, "")
            ).toFixed(2);
            let totalCuotasMensual =
                parseFloat(cuotaPrestamosFloat) + parseFloat(cuotaCICFloat);
            totalCuotasMensual = parseFloat(totalCuotasMensual.toFixed(2));
            let totalCuotaAnualOtrosPrestamos = parseFloat(cuotaPrestamosFloat) * 12;
            this.setState({ totalCuotasMensual: totalCuotasMensual });
            this.setState({
                montoAnualOtrosPrestamos: totalCuotaAnualOtrosPrestamos.toFixed(2),
            });

            var totalCuotasAnualPrestamosFixed = parseFloat(
                totalCuotaAnualOtrosPrestamos.toFixed(2)
            );

            var totalCuotasAnualCICFixed = parseFloat(
                this.state.montoAnualCreditoCIC
            );

            var sumaTotalCuotasAnual =
                totalCuotasAnualPrestamosFixed + totalCuotasAnualCICFixed;

            var sumaTotalCuotasAnualFloat = parseFloat(sumaTotalCuotasAnual).toFixed(2);

            this.setState({
                montoTotalCuotasAnuales: sumaTotalCuotasAnualFloat,
            });
        }
        if (event.target.name === "totalIngresoAnual") {

            const soloNumerosTotalIngresoAnual = preg.test(
                event.target.value.replace(/,/g, "")
            );

            if (!soloNumerosTotalIngresoAnual) {
                swal(
                    "Crear Aval",
                    "El monto de ingresos anuales no tiene un formato válido",
                    "warning", { button: "Aceptar", }
                );
                var totalIngresoAnualFloat = parseFloat(event.target.value.replace(/,/g, "")).toFixed(2);
                this.setState({ totalIngresoAnual: totalIngresoAnualFloat });
                $("#guardando").hide();
                return;
            }

            let ingresoFloat = parseFloat(event.target.value.replace(/,/g, ""));
            let egresoFloat = parseFloat(
                this.state.totalEgresoAnual.replace(/,/g, "")
            );
            let totalFlujo = ingresoFloat - egresoFloat;
            totalFlujo = parseFloat(totalFlujo.toFixed(2));
            this.setState({ montoFlujoOperativo: totalFlujo });
        }
        if (event.target.name === "totalEgresoAnual") {

            const soloNumerosTotalEgresoAnual = preg.test(
                event.target.value.replace(/,/g, "")
            );

            if (!soloNumerosTotalEgresoAnual) {
                swal(
                    "Crear Aval",
                    "El monto de egresos anuales no tiene un formato válido",
                    "warning", { button: "Aceptar", }
                );
                var totalEgresoAnualFloat = parseFloat(event.target.value.replace(/,/g, "")).toFixed(2);
                this.setState({ totalEgresoAnual: totalEgresoAnualFloat });
                $("#guardando").hide();
                return;
            }

            let ingresoFloat = parseFloat(
                this.state.totalIngresoAnual.replace(/,/g, "")
            );
            let egresoFloat = parseFloat(event.target.value.replace(/,/g, ""));
            let totalFlujo = ingresoFloat - egresoFloat;
            totalFlujo = parseFloat(totalFlujo.toFixed(2));
            this.setState({ montoFlujoOperativo: totalFlujo });
        }
        if (event.target.name === "impactoEmpleoMujeres") {
            let totalImpacto =
                parseInt(event.target.value) +
                parseInt(this.state.impactoEmpleoHombres);
            this.setState({ totalImpactoEmpleo: totalImpacto });
        }
        if (event.target.name === "impactoEmpleoHombres") {
            let totalImpacto =
                parseInt(event.target.value) +
                parseInt(this.state.impactoEmpleoMujeres);
            this.setState({ totalImpactoEmpleo: totalImpacto });
        }
        if (event.target.name === "valorGarantia") {
            let producto =
                parseFloat(event.target.value.replace(/,/g, "")).toFixed(2) *
                parseFloat(this.state.porcentajeMaximoAceptacion).toFixed(2);

            let montoMaximoAceptacion = producto / 100;

            montoMaximoAceptacion = montoMaximoAceptacion.toFixed(2);

            this.setState({
                montoMaximoAceptacion: montoMaximoAceptacion,
            });
        }
        if (event.target.name === "porcentajeMaximoAceptacion") {
            let producto =
                parseFloat(event.target.value) *
                parseFloat(this.state.valorGarantia.replace(/,/g, "")).toFixed(2);
            let montoMaximoAceptacion = producto / 100;
            this.setState({
                montoMaximoAceptacion: montoMaximoAceptacion.toFixed(2),
            });
        }
        if (event.target.name === "montoDefinitivoResponsabilidad") {
            let porc = parseFloat(event.target.value.replace(/,/g, "")).toFixed(2);
            porc = parseFloat(porc);
            let x = porc * 0.999999999999999;
            let y =
                x.toFixed(2) / parseFloat(this.state.montoCredito.replace(/,/g, ""));
            let z = y * 100;

            this.setState({ porcentajeResponsabilidad: z.toFixed(2) });
        }
        if (event.target.name === "cuotaMensualNuevoCredito") {
            let cuotaAnual = parseInt(event.target.value.replace(/,/g, "")) * 12;
            this.setState({ cuotaAnualNuevoCredito: cuotaAnual });
        }
        if (event.target.name === "montoCredito") {
            this.state.montoCredito = event.target.value;
            const soloNumerosMontoCredito = preg.test(
                event.target.value.replace(/,/g, "")
            );

            if (!soloNumerosMontoCredito) {
                swal(
                    "Crear Aval",
                    "El monto del crédito no tiene un formato válido",
                    "warning", { button: "Aceptar", }
                );
                var montoCreditoFloat = parseFloat(event.target.value.replace(/,/g, "")).toFixed(2);
                this.setState({ montoCredito: montoCreditoFloat });
                return;
            }

            //recalcular el % reponsabilidad de todas las garantias
            if (this.state.tablaGarantiasAportadas.length > 0) {
                var nuevaTablaGarantias = [];
                let porcTotalGarantias = 0;
                let totalPorcAval = 0;
                nuevaTablaGarantias = this.state.tablaGarantiasAportadas;

                nuevaTablaGarantias.map((garantia, index) => {

                    let porc = parseFloat(garantia.montoDefinitivoResponsabilidad.replace(/,/g, "")).toFixed(2);
                    porc = parseFloat(porc);
                    let x = porc * 0.999999999999999;
                    let y =
                        x.toFixed(2) / parseFloat(this.state.montoCredito.replace(/,/g, ""));
                    let z = y * 100;

                    garantia.porcentajeResponsabilidad = z.toFixed(2);


                    porcTotalGarantias = porcTotalGarantias += parseFloat(garantia.porcentajeResponsabilidad);

                });
                this.setState({ tablaGarantiasAportadas: nuevaTablaGarantias });

                porcTotalGarantias = parseFloat(porcTotalGarantias).toFixed(2);

                this.state.porcentajeTotalGarantiasAportadasSolicitante = porcTotalGarantias;
                this.setState({
                    porcentajeTotalGarantiasAportadasSolicitante: porcTotalGarantias,
                });


                totalPorcAval =
                    100 - this.state.porcentajeTotalGarantiasAportadasSolicitante;

                totalPorcAval = totalPorcAval.toFixed(2);
                this.setState({ porcentajeTotalAvalSolicitado: totalPorcAval });
            }

            if (this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_AVAL_LINEA_CREDITO) {
                let totalGarantiasAportadas = parseFloat(this.state.montoCredito.replace(/,/g, "")) * (this.state.porcentajeTotalGarantiasAportadasSolicitante / 100);

                totalGarantiasAportadas = totalGarantiasAportadas.toFixed(2);

                this.state.montoTotalGarantiasAportadasCliente = totalGarantiasAportadas;

                let totalAval =
                    parseFloat(this.state.montoCredito.replace(/,/g, "")) -
                    this.state.montoTotalGarantiasAportadasCliente;

                totalAval = totalAval.toFixed(2);
                this.state.montoTotalAvalSolicitado = totalAval;
                this.setState({ montoTotalGarantiasAportadasCliente: totalGarantiasAportadas });
                this.setState({ montoTotalAvalSolicitado: totalAval });
            } else {
                let totalAval =
                    parseFloat(this.state.montoCredito.replace(/,/g, "")) -
                    this.state.montoTotalGarantiasAportadasCliente;
                this.state.montoTotalAvalSolicitado = totalAval;
                this.setState({ montoTotalAvalSolicitado: totalAval });
            }

            if (this.state.montoCredito === "" || parseInt(this.state.montoCredito) === 0) {
                this.state.indIrPaso2 = true;
                this.setState({ indIrPaso2: true });
            } else {
                this.state.indIrPaso2 = false;
                this.setState({ indIrPaso2: false });
            }
            this.calcularCuotaNivelada();
        }
        if (event.target.name === "tasaInteresAnual") {

            const soloNumerosTasaInteresAnual = preg.test(
                event.target.value.replace(/,/g, "")
            );

            if (!soloNumerosTasaInteresAnual) {
                swal(
                    "Garantías Aportadas",
                    "La tasa de intéres anual no tiene un formato válido",
                    "warning", { button: "Aceptar", }
                );
                var tasaInteresAnualFloat = parseFloat(event.target.value.replace(/,/g, "")).toFixed(2);
                this.setState({ tasaInteresAnual: tasaInteresAnualFloat });
                return;
            }

            // tasa anual
            var t = event.target.value;
            // plazo meses
            var p = this.state.plazoCreditoMeses;
            if (this.state.tienePlazoBallon) {
                p = this.state.plazoBallonMeses;
            }
            p = "0" + p;
            // frecuencia pago
            var fp = 1;
            //monto
            var monto = parseFloat(this.state.montoCredito.replace(/,/g, ""));

            var ft = 0;

            var cuotaMensual = 0;

            var divisionPotencia = p / fp;

            ft = (t / 1200) * 1;
            ft = ft.toFixed(14);
            ft = parseFloat(ft);

            var sumaFT = ft + 1;

            var potencia = sumaFT ** divisionPotencia;

            var resultadoNumerador = ft * potencia;

            var resultadoDenominador = potencia - 1;

            var divisionFormula = resultadoNumerador / resultadoDenominador;

            var cuotaMensual = divisionFormula * monto;

            this.state.cuotaPeriodica = cuotaMensual.toFixed(2);
            this.setState({ cuotaPeriodica: cuotaMensual.toFixed(2) });
            this.state.cuotaMensualNuevoCredito = cuotaMensual.toFixed(2);
            this.setState({ cuotaMensualNuevoCredito: cuotaMensual.toFixed(2) });
            let cuotaAnual = cuotaMensual * 12;
            this.setState({ cuotaAnualNuevoCredito: cuotaAnual.toFixed(2) });
        }
        if (event.target.name === "plazoCreditoMeses") {
            // tasa anual
            var t = this.state.tasaInteresAnual;
            // plazo meses
            var p = event.target.value;
            if (this.state.tienePlazoBallon) {
                p = this.state.plazoBallonMeses;
            }
            p = "0" + p;
            // frecuencia pago
            var fp = 1;
            //monto
            var monto = parseFloat(this.state.montoCredito.replace(/,/g, ""));

            var ft = 0;

            var cuotaMensual = 0;

            var divisionPotencia = p / fp;

            ft = (t / 1200) * 1;
            ft = ft.toFixed(14);
            ft = parseFloat(ft);

            var sumaFT = ft + 1;

            var potencia = sumaFT ** divisionPotencia;

            var resultadoNumerador = ft * potencia;

            var resultadoDenominador = potencia - 1;

            var divisionFormula = resultadoNumerador / resultadoDenominador;

            var cuotaMensual = divisionFormula * monto;

            this.state.cuotaPeriodica = cuotaMensual.toFixed(2);
            this.setState({ cuotaPeriodica: cuotaMensual.toFixed(2) });
            this.state.cuotaMensualNuevoCredito = cuotaMensual.toFixed(2);
            this.setState({ cuotaMensualNuevoCredito: cuotaMensual.toFixed(2) });
            let cuotaAnual = cuotaMensual * 12;
            this.setState({ cuotaAnualNuevoCredito: cuotaAnual.toFixed(2) });
        }
        if (event.target.name === "plazoBallonMeses") {
            // tasa anual
            var t = this.state.tasaInteresAnual;
            // plazo meses
            var p = event.target.value;
            p = "0" + p;
            // frecuencia pago
            var fp = 1;
            //monto
            var monto = parseFloat(this.state.montoCredito.replace(/,/g, ""));

            var ft = 0;

            var cuotaMensual = 0;

            var divisionPotencia = p / fp;

            ft = (t / 1200) * 1;
            ft = ft.toFixed(14);
            ft = parseFloat(ft);

            var sumaFT = ft + 1;

            var potencia = sumaFT ** divisionPotencia;

            var resultadoNumerador = ft * potencia;

            var resultadoDenominador = potencia - 1;

            var divisionFormula = resultadoNumerador / resultadoDenominador;

            var cuotaMensual = divisionFormula * monto;

            this.state.cuotaPeriodica = cuotaMensual.toFixed(2);
            this.setState({ cuotaPeriodica: cuotaMensual.toFixed(2) });
            this.state.cuotaMensualNuevoCredito = cuotaMensual.toFixed(2);
            this.setState({ cuotaMensualNuevoCredito: cuotaMensual.toFixed(2) });
            let cuotaAnual = cuotaMensual * 12;
            this.setState({ cuotaAnualNuevoCredito: cuotaAnual.toFixed(2) });
        }
        if (
            event.target.name === "numeroIdentificacionSolicitante" &&
            event.target.value.length > 17
        ) {
            swal(
                "SAAG",
                "Ha sobrepasado la cantidad máxima de caracteres permitidos para el número de identificación",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        if (event.target.name === "numeroIdentificacionSolicitante") {
            const soloNumeros = new RegExp(/^[0-9]*$/);
            const soloNumerosIdentificacion = soloNumeros.test(event.target.value);
            if (!soloNumerosIdentificacion) {
                swal("Crear Aval", "Número Identificación no tiene un formato válido", "warning", { button: "Aceptar", });
                return;
            }
        }
        if (event.target.name === "valorGarantia") {
            const soloNumerosValorGarantia = preg.test(
                event.target.value.replace(/,/g, "")
            );

            if (!soloNumerosValorGarantia) {
                swal(
                    "Garantías Aportadas",
                    "El monto del valor no tiene un formato válido",
                    "warning", { button: "Aceptar", }
                );
                var montoValorGarantiaFloat = parseFloat(event.target.value.replace(/,/g, "")).toFixed(2);
                this.setState({ valorGarantia: montoValorGarantiaFloat });
                return;
            }
        }
        if (event.target.name === "porcentajeMaximoAceptacion") {
            const soloNumerosPorcentajeMaximoAceptacion = preg.test(
                event.target.value.replace(/,/g, "")
            );

            if (!soloNumerosPorcentajeMaximoAceptacion) {
                swal(
                    "Garantías Aportadas",
                    "El % máximo de aceptación no tiene un formato válido",
                    "warning", { button: "Aceptar", }
                );
                var porcentajeMaximoAceptacionFloat = parseFloat(event.target.value.replace(/,/g, "")).toFixed(2);
                this.setState({ porcentajeMaximoAceptacion: porcentajeMaximoAceptacionFloat });
                return;
            }
        }
        if (event.target.name === "montoDefinitivoResponsabilidad") {
            const soloNumerosMontoDefinitivoResponsabilidad = preg.test(
                event.target.value.replace(/,/g, "")
            );

            if (!soloNumerosMontoDefinitivoResponsabilidad) {
                swal(
                    "Garantías Aportadas",
                    "El monto definitivo de responsabilidad no tiene un formato válido",
                    "warning", { button: "Aceptar", }
                );
                var montoDefinitivoResponsabilidadFloat = parseFloat(event.target.value.replace(/,/g, "")).toFixed(2);
                this.setState({ montoDefinitivoResponsabilidad: montoDefinitivoResponsabilidadFloat });
                return;
            }
        }
        if (event.target.name == "puntajeFinalDeudorSBD") {
            const soloNumerosPuntajeFinalDeudorSBD = pregPuntajes.test(
                event.target.value.replace(/,/g, "")
            );

            if (!soloNumerosPuntajeFinalDeudorSBD) {
                swal(
                    "Crear Aval",
                    "El puntaje final del deudor SBD no tiene un formato válido",
                    "warning", { button: "Aceptar", }
                );
                var puntajeFinalDeudorSBDFloat = parseFloat(event.target.value.replace(/,/g, "")).toFixed(4);
                this.setState({ puntajeFinalDeudorSBD: puntajeFinalDeudorSBDFloat });
                return;
            }
        }
        if (event.target.name === "puntajeCIC") {
            const soloNumerosPuntajeCIC = pregPuntajes.test(
                event.target.value.replace(/,/g, "")
            );

            if (!soloNumerosPuntajeCIC) {
                swal(
                    "Crear Aval",
                    "El puntaje final final CIC no tiene un formato válido",
                    "warning", { button: "Aceptar", }
                );
                var puntajeCICFloat = parseFloat(event.target.value.replace(/,/g, "")).toFixed(4);
                this.setState({ puntajeCIC: puntajeCICFloat });
                return;
            }
        }
        this.setState({ [event.target.name]: event.target.value });
    };
    /**
     * 
     * @param {*} event 
     * @returns Alamacena en el State los archivos seleccionados
     */
    encargarseCambioArchivos = (event) => {
        if (event.target.files.length === 0) {
            return;
        }
        if (event.target.files[0].type != "application/pdf") {
            swal(
                "Paso 3 - Documentación Adjunta",
                "El archivo tiene que ser de tipo PDF",
                "error", { button: "Aceptar", }
            );
            return;
        }
        this.setState({ [event.target.name]: event.target.files[0] });
    };
    /**
     * 
     * @param {*} event 
     * @returns Alamacena en el State los archivos seleccionados para FNA
     */
    encargarseCambioArchivosFNA = (event) => {
        if (event.target.files.length === 0) {
            this.setState({ [event.target.name]: null });
            return;
        }
        if (event.target.files[0].type != "application/pdf") {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El archivo tiene que ser de tipo PDF",
                "error", { button: "Aceptar", }
            );
            return;
        }
        this.setState({ [event.target.name]: event.target.files[0] });
    };
    /**
     * 
     * @param {*} event 
     * @returns Agregar una nueva garantia
     */
    agregarColumna = (event) => {
        event.preventDefault();
        var numeros = /^([0-9]+\.?[0-9]{0,2})$/;
        const soloNumerosValorGarantia = numeros.test(this.state.valorGarantia.replace(/,/g, ""));
        const soloNumerosMontoMaximoAceptacion = numeros.test(
            this.state.montoMaximoAceptacion.replace(/,/g, "")
        );
        const soloNumerosMontoDefinitivoResponsabilidad = numeros.test(
            this.state.montoDefinitivoResponsabilidad.replace(/,/g, "")
        );
        if (this.state.descripcionGarantia === "") {
            swal("Garantías Aportadas", "La descripción es requerida", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }
        if (this.state.descripcionGarantia.toString().length < 5) {
            swal(
                "Garantías Aportadas",
                "La descripción debe contener un mínimo de 5 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.descripcionGarantia.toString().length > 100) {
            swal(
                "Garantías Aportadas",
                "La descripción debe contener un máximo de 100 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.valorGarantia === "" || this.state.valorGarantia == 0) {
            swal("Garantías Aportadas", "Valor es requerido", "error", { button: "Aceptar", });
            return;
        }
        if (!soloNumerosValorGarantia) {
            swal("Garantías Aportadas", "Valor no tiene un formato válido", "error", { button: "Aceptar", });
            return;
        }
        var valorGarantiaFloat = parseFloat(
            this.state.valorGarantia.replace(/,/g, "")
        ).toFixed(2);
        valorGarantiaFloat = parseFloat(valorGarantiaFloat);
        if (valorGarantiaFloat > REACT_APP_LONGITUD_CAMPO_MONTOS) {
            swal(
                "Garantías Aportadas",
                "El valor no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (
            this.state.montoCredito === "" ||
            parseFloat(this.state.montoCredito.replace(/,/g, "")) == 0
        ) {
            swal("Información Básica", "El monto del crédito es requerido", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }
        if (
            this.state.montoMaximoAceptacion === "" ||
            this.state.montoMaximoAceptacion == 0
        ) {
            swal(
                "Garantías Aportadas",
                "Monto Máximo Aceptación es requerido",
                "error", { button: "Aceptar", }
            );
            return;
        }
        if (!soloNumerosMontoMaximoAceptacion) {
            swal(
                "Garantías Aportadas",
                "Monto Máximo Aceptación no tiene un formato válido",
                "error", { button: "Aceptar", }
            );
            return;
        }
        if (this.state.montoMaximoAceptacion > REACT_APP_LONGITUD_CAMPO_MONTOS) {
            swal(
                "Garantías Aportadas",
                "Monto Máximo Aceptación no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (
            this.state.montoDefinitivoResponsabilidad === "" ||
            this.state.montoDefinitivoResponsabilidad == 0
        ) {
            swal(
                "Garantías Aportadas",
                "Monto Definitivo Responsabilidad es requerido",
                "error", { button: "Aceptar", }
            );
            return;
        }
        if (!soloNumerosMontoDefinitivoResponsabilidad) {
            swal(
                "Garantías Aportadas",
                "Monto Definitivo Responsabilidad no tiene un formato válido",
                "error", { button: "Aceptar", }
            );
            return;
        }
        montoDefinitivoResponsabilidadFloat = parseFloat(
            this.state.montoDefinitivoResponsabilidad.replace(/,/g, "")
        ).toFixed(2);

        montoDefinitivoResponsabilidadFloat = parseFloat(
            montoDefinitivoResponsabilidadFloat
        );
        if (montoDefinitivoResponsabilidadFloat > REACT_APP_LONGITUD_CAMPO_MONTOS) {
            swal(
                "Garantías Aportadas",
                "Monto Definitivo Responsabilidad no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (
            this.state.porcentajeResponsabilidad === "" ||
            this.state.porcentajeResponsabilidad == 0
        ) {
            swal(
                "Garantías Aportadas",
                "Porcentaje Responsabilidad es requerido",
                "error", { button: "Aceptar", }
            );
            return;
        }
        if (this.state.porcentajeResponsabilidad < 0) {
            swal(
                "Garantías Aportadas",
                "Porcentaje Responsabilidad no puede ser negativo",
                "error", { button: "Aceptar", }
            );
            return;
        }
        if (this.state.porcentajeResponsabilidad > 100) {
            swal(
                "Garantías Aportadas",
                "Porcentaje Responsabilidad no puede ser mayor a 100",
                "error", { button: "Aceptar", }
            );
            return;
        }
        if (
            this.state.porcentajeMaximoAceptacion === "" ||
            this.state.porcentajeMaximoAceptacion == 0
        ) {
            swal("Garantías Aportadas", "% Máximo Aceptación es requerido", "error", { button: "Aceptar", });
            return;
        }
        if (this.state.porcentajeMaximoAceptacion < 0) {
            swal(
                "Garantías Aportadas",
                "% Máximo Aceptación no puede ser negativo",
                "error", { button: "Aceptar", }
            );
            return;
        }
        if (this.state.porcentajeMaximoAceptacion > 100) {
            swal(
                "Garantías Aportadas",
                "% Máximo Aceptación no puede ser mayor a 100",
                "error", { button: "Aceptar", }
            );
            return;
        }
        var montoDefinitivoResponsabilidadFloat = parseFloat(
            this.state.montoDefinitivoResponsabilidad.replace(/,/g, "")
        ).toFixed(2);

        montoDefinitivoResponsabilidadFloat = parseFloat(
            montoDefinitivoResponsabilidadFloat
        );

        var montoMaximoAceptacionFloat = Number(this.state.montoMaximoAceptacion)
            ? this.state.montoMaximoAceptacion
            : parseFloat(
                this.state.montoMaximoAceptacion.replace(/,/g, "")
            ).toFixed(2);

        montoMaximoAceptacionFloat = parseFloat(montoMaximoAceptacionFloat);

        if (montoDefinitivoResponsabilidadFloat > montoMaximoAceptacionFloat) {
            swal(
                "Garantías Aportadas",
                "El monto definitivo de responsabilidad no puede ser mayor al monto máximo de aceptación",
                "error", { button: "Aceptar", }
            );
            return;
        }

        if (montoDefinitivoResponsabilidadFloat !== montoMaximoAceptacionFloat) {
            swal(
                "Garantías Aportadas",
                "Si el monto definitivo de responsabilidad es distinto al monto máximo de aceptación, por favor dejar una observación indicando el motivo de la diferencia entre dichos montos.",
                "warning", { button: "Aceptar", }
            );
        }
        var nuevoRow = {
            tipoGarantia: this.state.tipoGarantiaSeleccionadaTabla,
            descripcion: this.state.descripcionGarantia,
            valor: parseFloat(this.state.valorGarantia.replace(/,/g, "")).toFixed(2),
            porcentajeMaximoAceptacion: this.state.porcentajeMaximoAceptacion,
            montoMaximoAceptacion: this.state.montoMaximoAceptacion,
            montoDefinitivoResponsabilidad: parseFloat(
                this.state.montoDefinitivoResponsabilidad.replace(/,/g, "")
            ).toFixed(2),
            porcentajeResponsabilidad: this.state.porcentajeResponsabilidad,
        };
        let totalGarantiasAportadas =
            this.state.montoTotalGarantiasAportadasCliente == ""
                ? 0
                : this.state.montoTotalGarantiasAportadasCliente;
        let montoDefinitivoFloat = parseFloat(
            this.state.montoDefinitivoResponsabilidad.replace(/,/g, "")
        ).toFixed(2);
        let montoTotalGarantias =
            parseFloat(totalGarantiasAportadas) + parseFloat(montoDefinitivoFloat);

        if (
            montoTotalGarantias >
            parseFloat(this.state.montoCredito.replace(/,/g, ""))
        ) {
            swal(
                "Garantías Aportadas",
                "El monto total de las garantías aportadas por el cliente no puede superar el monto del crédito",
                "error", { button: "Aceptar", }
            );
            return;
        }

        montoTotalGarantias = parseFloat(montoTotalGarantias).toFixed(2);

        this.setState({ montoTotalGarantiasAportadasCliente: montoTotalGarantias });

        let porcTotalGarantias =
            parseFloat(this.state.porcentajeTotalGarantiasAportadasSolicitante) +
            parseFloat(this.state.porcentajeResponsabilidad);

        porcTotalGarantias = parseFloat(porcTotalGarantias).toFixed(2);

        this.state.porcentajeTotalGarantiasAportadasSolicitante = porcTotalGarantias;
        this.setState({
            porcentajeTotalGarantiasAportadasSolicitante: porcTotalGarantias,
        });

        let totalAval =
            parseFloat(this.state.montoCredito.replace(/,/g, "")) -
            montoTotalGarantias;
        totalAval = totalAval.toFixed(2);

        this.state.montoTotalAvalSolicitado = totalAval;
        this.setState({ montoTotalAvalSolicitado: totalAval });

        let totalPorcAval =
            100 - this.state.porcentajeTotalGarantiasAportadasSolicitante;

        totalPorcAval = totalPorcAval.toFixed(2);
        this.setState({ porcentajeTotalAvalSolicitado: totalPorcAval });

        let diferenciasMonto = totalAval - totalAval;
        this.setState({ diferenciasMonto: diferenciasMonto });

        let diferenciaPorcentaje = 100 - porcTotalGarantias - totalPorcAval;

        diferenciaPorcentaje = diferenciaPorcentaje.toFixed(2);
        this.setState({ diferenciaPorcentaje: diferenciaPorcentaje });

        $("#tabla_garantias").show();
        this.state.tablaGarantiasAportadas.push(nuevoRow);
        if (this.state.tablaGarantiasAportadas.length > 0) {
            this.state.indIrPaso3 = false;
            this.setState({ indIrPaso3: false });
        } else {
            this.state.indIrPaso3 = true;
            this.setState({ indIrPaso3: true });
        }

        var actualizarLista = this.state.tablaGarantiasAportadas;
        this.state.tipoGarantiaSeleccionadaTabla = this.state.tipoGarantiaAdicionalLista[0];
        this.setState({
            tipoGarantiaSeleccionadaTabla: this.state.tipoGarantiaAdicionalLista[0],
        });
        this.setState({ tablaGarantiasAportadas: actualizarLista });
        this.setState({ descripcionGarantia: "" });
        this.setState({ valorGarantia: "" });
        this.setState({ porcentajeMaximoAceptacion: "" });
        this.setState({ montoMaximoAceptacion: "" });
        this.setState({ montoDefinitivoResponsabilidad: "" });
        this.setState({ porcentajeResponsabilidad: 0 });
    };
    /**
     * 
     * @param {*} index 
     * @returns Borrar una nueva garantia
     */
    borrarColumna = (index) => {
        let columnaEliminar = this.state.tablaGarantiasAportadas[index];
        let montoTotalGarantias =
            this.state.montoTotalGarantiasAportadasCliente -
            columnaEliminar.montoDefinitivoResponsabilidad;

        montoTotalGarantias = montoTotalGarantias.toFixed(2);

        this.setState({ montoTotalGarantiasAportadasCliente: montoTotalGarantias });

        let porcTotalGarantias =
            this.state.porcentajeTotalGarantiasAportadasSolicitante -
            columnaEliminar.porcentajeResponsabilidad;
        this.setState({
            porcentajeTotalGarantiasAportadasSolicitante: porcTotalGarantias.toFixed(
                2
            ),
        });

        let totalAval =
            parseFloat(this.state.montoCredito.replace(/,/g, "")) -
            montoTotalGarantias;

        totalAval = totalAval.toFixed(2);

        this.setState({ montoTotalAvalSolicitado: totalAval });

        let totalPorcAval = 100 - porcTotalGarantias;

        totalPorcAval = totalPorcAval.toFixed(2);

        this.setState({ porcentajeTotalAvalSolicitado: totalPorcAval });


        let diferenciasMonto = totalAval - totalAval;
        this.setState({ diferenciasMonto: diferenciasMonto });

        let diferenciaPorcentaje = 100 - porcTotalGarantias - totalPorcAval;

        diferenciaPorcentaje = diferenciaPorcentaje.toFixed(2);
        this.setState({ diferenciaPorcentaje: diferenciaPorcentaje });

        this.state.tablaGarantiasAportadas.splice(index, 1);
        if (this.state.tablaGarantiasAportadas.length > 0) {
            this.state.indIrPaso3 = false;
            this.setState({ indIrPaso3: false });
        } else {
            this.state.indIrPaso3 = true;
            this.setState({ indIrPaso3: true });
        }
        var nuevaLista = this.state.tablaGarantiasAportadas;
        if (nuevaLista.length == 0) {
            $("#tabla_garantias").hide();
        }
        this.setState({ tablaGarantiasAportadas: nuevaLista });
    };
    /**
     * 
     * @param {*} event 
     * @returns Crear aval nuevo
     */
    crearAval = (event) => {
        event.preventDefault();
        $("#guardando").show();
        $("#floppy").hide();
        var soloNumeros = /^([0-9]+\.?[0-9]{0,2})$/;
        const numeros = new RegExp(/^[0-9,.]*$/);

        const soloNumerosCedula = new RegExp(/^[0-9]*$/);
        const soloNumerosIdentificacion = soloNumerosCedula.test(this.state.numeroIdentificacionSolicitante);
        const soloNumerosMontoCredito = soloNumeros.test(this.state.montoCredito.replace(/,/g, ""));
        const soloNumerosIngresoAnual = soloNumeros.test(this.state.totalIngresoAnual.replace(/,/g, ""));
        const soloNumerosEgresoAnual = soloNumeros.test(this.state.totalEgresoAnual.replace(/,/g, ""));
        const soloNumerosFlujoOperativo = numeros.test(
            this.state.montoFlujoOperativo
        );
        const soloNumerosCuotaMensualCIC = soloNumeros.test(
            this.state.cuotaMensualCreditoCIC.replace(/,/g, "")
        );
        const soloNumerosCuotaMensualOtrosPrestamos = soloNumeros.test(
            this.state.cuotaMensualOtrosPrestamo.replace(/,/g, "")
        );
        const soloNumerosTotalCuotaMensual = numeros.test(
            this.state.totalCuotasMensual
        );
        const soloNumerosTotalAnualCreditoCIC = numeros.test(
            this.state.montoAnualCreditoCIC
        );
        const soloNumerosTotalAnualOtrosPrestamos = numeros.test(
            this.state.montoAnualOtrosPrestamos
        );
        const soloNumerosTotalCuotasAnuales = numeros.test(
            this.state.montoTotalCuotasAnuales
        );
        const letras = new RegExp(/^[a-zA-ZáéíñóúüÁÉÍÑÓÚÜ 0-9 ,.-]*$/);
        const soloLetrasSolicitante = letras.test(this.state.nombreSolicitante);

        let contadorGarantiasNecesitanObservacion = 0;
        if (this.state.tipoProgramaSeleccionado === "") {
            swal("Información Básica", "El tipo de programa es requerido", "error", { button: "Aceptar", });
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (
            this.state.tipoProgramaSeleccionado != "" &&
            this.state.programaSeleccionado === ""
        ) {
            swal("Información Básica", "El programa es requerido", "error", { button: "Aceptar", });
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }

        if (this.state.programaSeleccionado.cliente.cedulaJuridica == REACT_APP_CEDULA_BP) {
            if (this.state.numeroSolicitudBancaria == "") {
                swal(
                    "Paso 1 - Información Cliente",
                    "El número de solicitud bancaria es requerido",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
            if (this.state.numeroSolicitudBancaria.length < 3) {
                swal(
                    "Paso 1 - Información Cliente",
                    "El número de solicitud bancaria debe contener mínimo 3 caracteres",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
            if (this.state.numeroSolicitudBancaria.length > 100) {
                swal(
                    "Paso 1 - Información Cliente",
                    "El número de solicitud bancaria debe contener máximo 100 caracteres",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
            if (this.state.numeroOperacionBancaria == "") {
                swal(
                    "Paso 1 - Información Cliente",
                    "El número de operación bancaria es requerido",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
            if (this.state.numeroOperacionBancaria.length < 3) {
                swal(
                    "Paso 1 - Información Cliente",
                    "El número de solicitud bancaria debe contener mínimo 3 caracteres",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
            if (this.state.numeroOperacionBancaria.length > 100) {
                swal(
                    "Paso 1 - Información Cliente",
                    "El número de solicitud bancaria debe contener máximo 100 caracteres",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
        }
        if (
            this.state.montoCredito === "" ||
            parseFloat(this.state.montoCredito.replace(/,/g, "")) == 0
        ) {
            swal("Información Básica", "El monto del crédito es requerido", "error", { button: "Aceptar", });
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (!soloNumerosMontoCredito) {
            swal(
                "Información Básica",
                "El monto del crédito no tiene un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (
            parseFloat(this.state.montoCredito.replace(/,/g, "")).toFixed(2) >
            REACT_APP_LONGITUD_CAMPO_MONTOS
        ) {
            swal(
                "Información Básica",
                "El monto del crédito no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (parseFloat(this.state.montoCredito.replace(/,/g, "")) < 0) {
            swal(
                "Información Básica",
                "El monto del crédito no puede ser negativo",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (idFondo == REACT_APP_OPERADORA_FODEMIPYME) {
            if (this.state.idOficio === "") {
                swal("Información Básica", "El id de oficio es requerido", "error", { button: "Aceptar", });
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
            if (this.state.idOficio.length < 5) {
                swal(
                    "Información Básica",
                    "El id de oficio debe tener mínimo 5 caracteres",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
            if (this.state.idOficio.length > 100) {
                swal(
                    "Información Básica",
                    "El id de oficio debe tener máximo 100 caracteres",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
        }
        if (this.state.numeroOperacion === "") {
            swal(
                "Paso 1 - Información Cliente",
                "El número de operación es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.numeroOperacion.length < 3) {
            swal(
                "Paso 1 - Información Cliente",
                "El número de operación debe contener mínimo 3 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.numeroOperacion.length > 100) {
            swal(
                "Paso 1 - Información Cliente",
                "El número de operación debe contener máximo 100 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        //Informacion Basica
        if (this.state.tipoSolicitudSeleccionado === "") {
            swal("Información Básica", "El tipo de solicitud es requerido", "error", { button: "Aceptar", });
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (
            this.state.tipoSolicitudSeleccionado.id == "2" &&
            this.state.lineaCreditoSeleccionada === ""
        ) {
            swal("Información Básica", "La línea de crédito es requerida", "error", { button: "Aceptar", });
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.monedaSeleccionada === "") {
            swal("Información Básica", "La moneda es requerida", "error", { button: "Aceptar", });
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.nombreSolicitante === "") {
            swal(
                "Paso 1 - Información Cliente",
                "El nombre del cliente es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.nombreSolicitante.length < 5) {
            swal(
                "Paso 1 - Información Cliente",
                "El nombre del cliente debe contener mínimo 5 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.nombreSolicitante.length > 100) {
            swal(
                "Paso 1 - Información Cliente",
                "El nombre del cliente debe contener máximo 100 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (!soloLetrasSolicitante) {
            swal(
                "Paso 1 - Información Cliente",
                "Nombre del cliente inválido. Caracteres permitidos: , . -",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.tiposIdentificacionSeleccionada === "") {
            swal(
                "Paso 1 - Información Cliente",
                "El tipo de identificación es requerida",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.numeroIdentificacionSolicitante === "") {
            swal(
                "Paso 1 - Información Cliente",
                "El número de identificación es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (!soloNumerosIdentificacion) {
            swal(
                "Paso 1 - Información Cliente",
                "Número Identificación no tiene un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.tiposIdentificacionSeleccionada.id == REACT_APP_TIPO_IDENTIFICACION_PERSONA_FISICA_NACIONAL) {
            if (Number(this.state.numeroIdentificacionSolicitante)
                ? this.state.numeroIdentificacionSolicitante.toString().length !== 9
                : this.state.numeroIdentificacionSolicitante.length !== 9) {
                swal(
                    "Paso 1 - Información Cliente",
                    "La Cédula Física Nacional debe de contener 9 caracteres",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
        }
        if (this.state.tiposIdentificacionSeleccionada.id == REACT_APP_TIPO_IDENTIFICACION_PERSONA_JURIDICA_NACIONAL) {
            if (Number(this.state.numeroIdentificacionSolicitante)
                ? this.state.numeroIdentificacionSolicitante.toString().length !== 10
                : this.state.numeroIdentificacionSolicitante.length !== 10) {
                swal(
                    "Paso 1 - Información Cliente",
                    "La Cédula Jurídica debe de contener 10 caracteres",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
        }
        if (this.state.tiposIdentificacionSeleccionada.id == REACT_APP_TIPO_IDENTIFICACION_EXTRANJERO_RESIDENTE) {
            if (Number(this.state.numeroIdentificacionSolicitante)
                ? this.state.numeroIdentificacionSolicitante.toString().length !== 12
                : this.state.numeroIdentificacionSolicitante.length !== 12) {
                swal(
                    "Paso 1 - Información Cliente",
                    "La Cédula Extrajero Residente debe de contener 12 caracteres",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
        }
        if (this.state.provinciaSeleccionada === "") {
            swal(
                "Paso 1 - Información Cliente",
                "La provincia es requerida",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.cantonSeleccionado === "") {
            swal("Paso 1 - Información Cliente", "El cantón es requerido", "error", { button: "Aceptar", });
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.distritoSeleccionado === "") {
            swal("Paso 1 - Información Cliente", "El distrito es requerido", "error", { button: "Aceptar", });
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.direccion === "") {
            swal(
                "Paso 1 - Información Cliente",
                "La dirección es requerida",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.direccion.length < 5) {
            swal(
                "Paso 1 - Información Cliente",
                "La dirección debe contener mínimo 5 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.direccion.length > 100) {
            swal(
                "Paso 1 - Información Cliente",
                "La dirección debe contener máximo 100 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.actividadEconomica === "") {
            swal(
                "Paso 1 - Información Cliente",
                "La actividad económica es requerida",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.actividadEconomica.length < 5) {
            swal(
                "Paso 1 - Información Cliente",
                "La actividad económica debe contener mínimo 5 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.actividadEconomica.length > 100) {
            swal(
                "Paso 1 - Información Cliente",
                "La actividad económica debe contener máximo 100 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.sectorEconomicoSeleccionado === "") {
            swal(
                "Paso 1 - Información Cliente",
                "El sector económico es requerida",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.seccionCIIUSeleccionado === "") {
            swal(
                "Paso 1 - Información Cliente",
                "La sección CIIU es requerida",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.divisionCIIUSeleccionado === "") {
            swal(
                "Paso 1 - Información Cliente",
                "La división CIIU es requerida",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.grupoCIIUSeleccionado === "") {
            swal(
                "Paso 1 - Información Cliente",
                "El grupo CIIU es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.claseCIIUUSeleccionado === "") {
            swal(
                "Paso 1 - Información Cliente",
                "La clase CIIU es requerida",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.codigoCIIU === "") {
            swal(
                "Paso 1 - Información Cliente",
                "El código CIIU es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        //Fin Informacion Basica
        //Parametros de Analisis
        if (this.state.nivelCICSeleccionado === "") {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El nivel CIC es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.puntajeCIC === "") {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El puntaje final CIC es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.puntajeCIC < 0) {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El puntaje final CIC no puede ser negativo",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.puntajeCIC > 9) {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El puntaje final CIC no puede ser mayor a 9",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.puntajeFinalDeudorSBD === "") {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El puntaje final del deudor SBD es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (parseInt(this.state.puntajeFinalDeudorSBD) < 0) {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El puntaje final del deudor SBD no puede ser negativo",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.puntajeFinalDeudorSBD > 9) {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El puntaje final del deudor SBD no puede ser mayor a 9",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.nivelPagoHistoricoSBDSeleccionado === "") {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El nivel de comportamiento de pago SBD es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.annosExperienciaEmpresa === "") {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "Los años de experiencia de la empresa son requeridos",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.annosExperienciaEmpresa < 0) {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "Los años de experiencia de la empresa no puede ser negativo",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.annosExperienciaEmpresa.length > 3) {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "Los años de experiencia de la empresa no pueden superar los 3 dígitos",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.tipoGarantiaAdicionalSeleccionada === "") {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El tipo de garantía adicional al aval es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.tamanoEmpresaSeleccionada === "") {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El tamaño de la empresa es requerida",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.nivelHistoricoPagoEntidadSeleccionado === "") {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El Nivel Histórico de Pago Entidad es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.totalImpactoEmpleo === "") {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El total de impacto es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.totalImpactoEmpleo < 0) {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El total de impacto no puede ser negativo",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.totalImpactoEmpleo.length > 5) {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El total de impacto no es válido. Excede los 5 dígitos",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (
            this.state.impactoEmpleoMujeres === "" ||
            this.state.impactoEmpleoMujeres == null
        ) {
            swal(
                "Paso 1 - CIC SUGEF",
                "El impacto de empleo (mujeres) es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.impactoEmpleoMujeres < 0) {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El impacto de empleo (mujeres) no puede ser negativo",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.impactoEmpleoMujeres.length > 5) {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El impacto de empleo (mujeres) no es válido. Excede los 5 dígitos",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.impactoEmpleoHombres < 0) {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El impacto de empleo (hombres) no puede ser negativo",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.impactoEmpleoHombres.length > 5) {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El impacto de empleo (hombres) no es válido. Excede los 5 dígitos",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.planInversion === "") {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El plan de inversión es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.planInversion.length < 5) {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El plan de inversión debe de contener mínimo 5 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.planInversion.length > 100) {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El plan de inversión debe de contener máximo 100 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (idFondo == REACT_APP_OPERADORA_FNA) {
            if (this.state.idTiposBeneficios.length == 0) {
                swal("Parámetros de Análisis - Campo 1", "El tipo de beneficio indicado por el operador es requerido", "error", { button: "Aceptar", });
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
            if (this.state.indCondicionesPago && this.state.mejoraCondicionesSeleccionado == "") {
                swal("Parámetros de Análisis - Campo 1", "La mejoras en condiciones de pago es requerido", "error", { button: "Aceptar", });
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
            if (this.state.indDescripcionMejoraCondicionesPago) {
                if (this.state.descripcionMejoraCondicion == "") {
                    swal("Parámetros de Análisi - Campo 1", "Si se seleccionó la opción d. Cualquier otra. Debe de agregar una descripción", "error", { button: "Aceptar", });
                    $("#guardando").hide();
                    $("#floppy").show();
                    return;
                }
                if (this.state.descripcionMejoraCondicion.length < 5) {
                    swal("Parámetros de Análisis - Campo 1", "Si se seleccionó la opción d. Cualquier otra. Debe de agregar una descripción y debe de contener mínimo 5 caracteres", "error", { button: "Aceptar", });
                    $("#guardando").hide();
                    $("#floppy").show();
                    return;
                }
                if (this.state.descripcionMejoraCondicion.length > 300) {
                    swal("Parámetros de Análisis - Campo 1", "Si se seleccionó la opción d. Cualquier otra. Debe de agregar una descripción y debe de contener máximo 300 caracteres", "error", { button: "Aceptar", });
                    $("#guardando").hide();
                    $("#floppy").show();
                    return;
                }
            }

            if (!this.state.demostroAfectacionCOVID) {
                swal(
                    "Parámetros de Análisis - Campo 2",
                    "La selección realizada hace que esta solicitud no pueda ser beneficiaria de un aval de FNA",
                    "warning", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
            if (!this.state.realizaActividadDentroDelPais) {
                swal(
                    "Parámetros de Análisis - Campo 3",
                    "La selección realizada hace que esta solicitud no pueda ser beneficiaria de un aval de FNA",
                    "warning", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
            if (!this.state.compromisoConservacionEmpleo) {
                swal(
                    "Parámetros de Análisis - Campo 4",
                    "La selección realizada hace que esta solicitud no pueda ser beneficiaria de un aval de FNA",
                    "warning", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
            if (this.state.beneficiaroPerteneceConglomerado) {
                swal(
                    "Parámetros de Análisis - Campo 5",
                    "La selección realizada hace que esta solicitud no pueda ser beneficiaria de un aval de FNA",
                    "warning", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
            if (!this.state.morosidadPrevioCOVIDMenor60) {
                swal(
                    "Parámetros de Análisis - Campo 6",
                    "La selección realizada hace que esta solicitud no pueda ser beneficiaria de un aval de FNA",
                    "warning", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
            if (this.state.avalSustituyeGarantia) {
                swal(
                    "Parámetros de Análisis - Campo 7",
                    "La selección realizada hace que esta solicitud no pueda ser beneficiaria de un aval de FNA",
                    "warning", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
            if (!this.state.clienteConCapacidadPago) {
                swal(
                    "Parámetros de Análisis - Campo 8",
                    "La selección realizada hace que esta solicitud no pueda ser beneficiaria de un aval de FNA",
                    "warning", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
            if (this.state.archivoCuadroComparativo === null) {
                swal(
                    "Parámetros de Análisis - Campo 9",
                    "El archivo del cuadro comparativo de las condiciones financieras antes y después del aval es requerido",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }

        }
        //Fin Prametros de Analisis
        //Informacion Financiera
        if (this.state.totalIngresoAnual === "") {
            swal(
                "Paso 1 - Información Financiera",
                "Ingresos anuales es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (!soloNumerosIngresoAnual) {
            swal(
                "Paso 1 - Información Financiera",
                "El monto ingresos anuales no tiene un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#floppy").show();
            $("#guardando").hide();
            return;
        }
        if (
            parseFloat(this.state.totalIngresoAnual.replace(/,/g, "")).toFixed(2) >
            REACT_APP_LONGITUD_CAMPO_MONTOS
        ) {
            swal(
                "Paso 1 - Información Financiera",
                "Ingresos anuales no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.totalEgresoAnual === "") {
            swal(
                "Paso 1 - Información Financiera",
                "Egresos anuales es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (
            parseFloat(this.state.totalEgresoAnual.replace(/,/g, "")).toFixed(2) >
            REACT_APP_LONGITUD_CAMPO_MONTOS
        ) {
            swal(
                "Paso 1 - Información Financiera",
                "Egresos anuales no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (!soloNumerosEgresoAnual) {
            swal(
                "Paso 1 - Información Financiera",
                "El monto egresos anuales no tiene un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#floppy").show();
            $("#guardando").hide();
            return;
        }
        if (this.state.montoFlujoOperativo === "" || parseInt(this.state.montoFlujoOperativo) == 0) {
            swal(
                "Paso 1 - Información Financiera",
                "Flujo operativo es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (!soloNumerosFlujoOperativo) {
            swal(
                "Paso 1 - Información Financiera",
                "El monto flujo operativo no tiene un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#floppy").show();
            $("#guardando").hide();
            return;
        }
        if (this.state.montoFlujoOperativo > REACT_APP_LONGITUD_CAMPO_MONTOS) {
            swal(
                "Paso 1 - Información Financiera",
                "Flujo operativo no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.fuenteReferenciaInformacionIngresosSeleccionado === "") {
            swal(
                "Paso 1 - Información Financiera",
                "Fuente de referencia información para los ingresos es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.fuenteReferenciaInformacionEgresosSeleccionado === "") {
            swal(
                "Paso 1 - Información Financiera",
                "Fuente de referencia información para los egresos es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        //Fin Informacion Financiera
        //Informacion Crediticia
        if (this.state.cuotaMensualCreditoCIC === "") {
            swal(
                "Paso 1 - Información Crediticia",
                "Las cuotas mensuales de créditos en el CIC es requerida",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (!soloNumerosCuotaMensualCIC) {
            swal(
                "Paso 1 - Información Financiera",
                "Las cuotas mensuales de créditos en el CIC no tienen un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#floppy").show();
            $("#guardando").hide();
            return;
        }
        if (
            parseFloat(this.state.cuotaMensualCreditoCIC.replace(/,/g, "")).toFixed(
                2
            ) > REACT_APP_LONGITUD_CAMPO_MONTOS
        ) {
            swal(
                "Paso 1 - Información Crediticia",
                "Las cuotas mensuales de créditos en el CIC no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.cuotaMensualOtrosPrestamo === "") {
            swal(
                "Paso 1 - Información Crediticia",
                "Las cuotas mensuales de otros préstamos es requerida",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (!soloNumerosCuotaMensualOtrosPrestamos) {
            swal(
                "Paso 1 - Información Financiera",
                "Las cuotas mensuales de otros préstamos no tienen un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#floppy").show();
            $("#guardando").hide();
            return;
        }
        if (
            parseFloat(
                this.state.cuotaMensualOtrosPrestamo.replace(/,/g, "")
            ).toFixed(2) > REACT_APP_LONGITUD_CAMPO_MONTOS
        ) {
            swal(
                "Paso 1 - Información Crediticia",
                "Las cuotas mensuales de otros préstamos no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.totalCuotasMensual === "") {
            swal(
                "Paso 1 - Información Crediticia",
                "Monto de las sumatorias de las cuotas actuales es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (!soloNumerosTotalCuotaMensual) {
            swal(
                "Paso 1 - Información Financiera",
                "Monto de las sumatorias de las cuotas actuales no tiene un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#floppy").show();
            $("#guardando").hide();
            return;
        }
        if (this.state.totalCuotasMensual > REACT_APP_LONGITUD_CAMPO_MONTOS) {
            swal(
                "Paso 1 - Información Crediticia",
                "Monto de las sumatorias de las cuotas actuales no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.montoAnualCreditoCIC === "") {
            swal(
                "Paso 1 - Información Crediticia",
                "Monto anual crédito CIC es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (!soloNumerosTotalAnualCreditoCIC) {
            swal(
                "Paso 1 - Información Financiera",
                "Monto anual crédito CIC no tiene un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#floppy").show();
            $("#guardando").hide();
            return;
        }
        if (this.state.montoAnualCreditoCIC > REACT_APP_LONGITUD_CAMPO_MONTOS) {
            swal(
                "Paso 1 - Información Crediticia",
                "Monto anual crédito CIC no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.montoAnualOtrosPrestamos === "") {
            swal(
                "Paso 1 - Información Crediticia",
                "Monto anual otros préstamos es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (!soloNumerosTotalAnualOtrosPrestamos) {
            swal(
                "Paso 1 - Información Financiera",
                "Monto anual de otros préstamos no tiene un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#floppy").show();
            $("#guardando").hide();
            return;
        }
        if (this.state.montoAnualOtrosPrestamos > REACT_APP_LONGITUD_CAMPO_MONTOS) {
            swal(
                "Paso 1 - Información Crediticia",
                "Monto anual otros préstamos no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.montoTotalCuotasAnuales === "") {
            swal(
                "Paso 1 - Información Crediticia",
                "Monto total de cuotas anuales es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (!soloNumerosTotalCuotasAnuales) {
            swal(
                "Paso 1 - Información Financiera",
                "Monto total de cuotas anuales no tiene un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#floppy").show();
            $("#guardando").hide();
            return;
        }
        if (this.state.montoTotalCuotasAnuales > REACT_APP_LONGITUD_CAMPO_MONTOS) {
            swal(
                "Paso 1 - Información Crediticia",
                "Monto total de cuotas anuales no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        //Fin Informacion Crediticia
        // Garantias Aportadas
        if (this.state.tipoSolicitudSeleccionado.id != REACT_APP_NATURALEZA_AVAL_LINEA_CREDITO) {
            if (this.state.tablaGarantiasAportadas.length == 0) {
                swal(
                    "Paso 2 - Garantías Aportadas",
                    "Las garantías aportadas por el cliente son requeridas",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
            this.state.tablaGarantiasAportadas.map((garantia, index) => {
                var montoDefinitivoResponsabilidadFloat = parseFloat(
                    garantia.montoDefinitivoResponsabilidad.replace(/,/g, "")
                ).toFixed(2);

                montoDefinitivoResponsabilidadFloat = parseFloat(
                    montoDefinitivoResponsabilidadFloat
                );

                var montoMaximoAceptacionFloat = parseFloat(
                    garantia.montoMaximoAceptacion.replace(/,/g, "")
                ).toFixed(2);

                montoMaximoAceptacionFloat = parseFloat(montoMaximoAceptacionFloat);

                if (montoDefinitivoResponsabilidadFloat !== montoMaximoAceptacionFloat && this.state.observaciones == "") {
                    if (this.state.observaciones == "") {
                        contadorGarantiasNecesitanObservacion = contadorGarantiasNecesitanObservacion + 1;
                    }
                }
            });

            if (contadorGarantiasNecesitanObservacion > 0) {
                swal(
                    "Garantías Aportadas",
                    "Si el monto definitivo de responsabilidad es distinto al monto máximo de aceptación, es requerido agregar una observación indicando el motivo de la diferencia entre dichos montos.",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }

        }
        if (this.state.tienePlazoBallon) {
            if (this.state.plazoBallonMeses == "") {
                swal(
                    "Garantías aportadas por el cliente",
                    "El plazo ballon es requerido, si el tipo de crédito seleccionado es Ballon",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
        }
        // Fin Garantias Aportadas
        //Documentacion Adjunta
        if (idFondo == REACT_APP_OPERADORA_FODEMIPYME) {
            if (this.state.estadoCertificacionCCSSSeleccionado === "") {
                swal(
                    "Paso 3 - Documentación Adjunta",
                    "Estado con la CCSS es requerido",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
            if (this.state.riesgosTrabajoSeleccionado === "") {
                swal(
                    "Paso 3 - Documentación Adjunta",
                    "Tipo de riesgo de trabajo es requerido",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
            if (this.state.estadoTributacionDirectaSeleccionada === "") {
                swal(
                    "Paso 3 - Documentación Adjunta",
                    "Tipo de tributación directa es requerido",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
        }
        //Fin Documentacion Adjunta
        let idUsuarioCrea = localStorage.getItem("idUsuarioCreador");
        let idEntidadFinanciera = localStorage.getItem("idOperadora");
        let idCentroEmpresarial = JSON.parse(
            localStorage.getItem("idCentroEmpresarial")
        );
        let data = {
            NumeroOperacion: this.state.numeroOperacion,
            IdLineaCredito:
                this.state.lineaCreditoSeleccionada != ""
                    ? parseInt(this.state.lineaCreditoSeleccionada.id)
                    : null,
            IdTipoPrograma: parseInt(this.state.tipoProgramaSeleccionado.id),
            IdPrograma: parseInt(this.state.programaSeleccionado.id),
            Naturaleza: parseInt(this.state.tipoSolicitudSeleccionado.id),
            IdEntidadFinancieraAutorizada: parseInt(idEntidadFinanciera),
            IdMoneda: parseInt(this.state.monedaSeleccionada.id),
            MontoCredito: parseFloat(this.state.montoCredito.replace(/,/g, "")),
            OficioCondicionPYMEPYMPA: idFondo == REACT_APP_OPERADORA_FODEMIPYME ? this.state.idOficio : null,
            NombreSolicitante: this.state.nombreSolicitante,
            IdTipoIdentificacionSolicitante: parseInt(
                this.state.tiposIdentificacionSeleccionada.id
            ),
            NumeroIdentificacionSolicitante: parseInt(
                this.state.numeroIdentificacionSolicitante
            ),
            IdProvincia: parseInt(this.state.provinciaSeleccionada.id),
            IdCanton: parseInt(this.state.cantonSeleccionado.id),
            IdDistrito: parseInt(this.state.distritoSeleccionado.id),
            Direccion: this.state.direccion,
            ActividadEconomica: this.state.actividadEconomica,
            IdSectorEconomico: parseInt(this.state.sectorEconomicoSeleccionado.id),
            CodigoCIIU: this.state.codigoCIIU,
            ClaseCIIU: this.state.claseCIIUUSeleccionado,
            DivisionCIIU: this.state.divisionCIIUSeleccionado,
            GrupoCIIU: this.state.grupoCIIUSeleccionado,
            SeccionCIIU: this.state.seccionCIIUSeleccionado,
            IdNivelPagoHistoricoSBD: parseInt(
                this.state.nivelPagoHistoricoSBDSeleccionado.id
            ),
            PuntajeFinalDeudorSBD: parseFloat(
                this.state.puntajeFinalDeudorSBD
            ).toFixed(4),
            IdNivelCIC: parseInt(this.state.nivelCICSeleccionado.id),
            PuntajeCIC: parseFloat(this.state.puntajeCIC).toFixed(4),
            IdNivelHistoricoPagoEntidad: parseInt(
                this.state.nivelHistoricoPagoEntidadSeleccionado.id
            ),
            IdTamanoEmpresa: parseInt(this.state.tamanoEmpresaSeleccionada.id),
            IdTipoGarantia: parseInt(this.state.tipoGarantiaAdicionalSeleccionada.id),
            AnnosExperienciaEmpresa: parseInt(this.state.annosExperienciaEmpresa),
            ImpactoEmpleoMujeres: parseInt(this.state.impactoEmpleoMujeres),
            ImpactoEmpleoHombres: parseInt(this.state.impactoEmpleoHombres),
            TotalImpactoEmpleo: parseInt(this.state.totalImpactoEmpleo),
            PlanInversion: this.state.planInversion,
            TotalIngresoAnual: parseFloat(
                this.state.totalIngresoAnual.replace(/,/g, "")
            ),
            TotalEgresoAnual: parseFloat(
                this.state.totalEgresoAnual.replace(/,/g, "")
            ),
            MontoFlujoOperativo: this.state.montoFlujoOperativo,
            IdFuenteReferenciaInformacionIngresos: parseInt(
                this.state.fuenteReferenciaInformacionIngresosSeleccionado.id
            ),
            IdFuenteReferenciaInformacionEgresos: parseInt(
                this.state.fuenteReferenciaInformacionEgresosSeleccionado.id
            ),
            CuotaMensualCreditoCIC: parseFloat(
                this.state.cuotaMensualCreditoCIC.replace(/,/g, "")
            ),
            CuotaMensualOtrosPrestamo: parseFloat(
                this.state.cuotaMensualOtrosPrestamo.replace(/,/g, "")
            ),
            TotalCuotasMensual: this.state.totalCuotasMensual,
            MontoAnualCreditoCIC: this.state.montoAnualCreditoCIC,
            MontoAnualOtrosPrestamos: this.state.montoAnualOtrosPrestamos,
            MontoTotalCuotasAnuales: this.state.montoTotalCuotasAnuales,
            Garantias: this.state.tablaGarantiasAportadas,
            MontoTotalGarantiasAportadasCliente: this.state
                .montoTotalGarantiasAportadasCliente,
            PorcentajeTotalGarantiasAportadasSolicitante: parseFloat(
                this.state.porcentajeTotalGarantiasAportadasSolicitante
            ),
            MontoTotalAvalSolicitado: this.state.montoTotalAvalSolicitado,
            PorcentajeTotalAvalSolicitado: parseFloat(
                this.state.porcentajeTotalAvalSolicitado
            ),
            TasaInteresAnual: this.state.tasaInteresAnual,
            PlazoCreditoMeses: parseInt(this.state.plazoCreditoMeses),
            FrecuenciaPeriodicaCuota: parseInt(this.state.frecuenciaPeriodica),
            CuotaPeriodicaNuevoCredito: parseFloat(
                this.state.cuotaMensualNuevoCredito.replace(/,/g, "")
            ),
            CuotaAnualNuevoCredito: this.state.cuotaAnualNuevoCredito,
            IdEjecutivoCreador: idUsuarioCrea,
            NombreArchivoFotoCedulaPersonaFisica: this.state
                .nombreArchivoFotoCedulaPersonaFisica,
            NombreArchivoFotoCedulaPersonaJuridica: this.state
                .nombreArchivoFotoCedulaPersonaJuridica,
            NombreArchivoFotoCedulaRepresentantePersonaJuridica: this.state
                .nombreArchivoFotoCedulaRepresentantePersonaJuridica,
            NombreArchivoCertificacionCCSS: idFondo == REACT_APP_OPERADORA_FODEMIPYME ? this.state.nombreArchivoCertificacionCCSS : null,
            IdEstadoCertificacionCCSS: idFondo == REACT_APP_OPERADORA_FODEMIPYME ? parseInt(
                this.state.estadoCertificacionCCSSSeleccionado.id
            ) : null,
            FechaEmisionCertificacionCCSS: idFondo == REACT_APP_OPERADORA_FODEMIPYME ? this.state.fechaEmisionCertificacionCCSS : null,
            NombreArchivoTributacionDirecta: idFondo == REACT_APP_OPERADORA_FODEMIPYME ? this.state
                .nombreArchivoTributacionDirecta : null,
            IdEstadoTributacionDirecta: idFondo == REACT_APP_OPERADORA_FODEMIPYME ? parseInt(
                this.state.estadoTributacionDirectaSeleccionada.id
            ) : null,
            NombreArchivoRiesgosTrabajo: idFondo == REACT_APP_OPERADORA_FODEMIPYME ? this.state.nombreArchivoRiesgosTrabajo : null,
            IdRiesgosTrabajo: idFondo == REACT_APP_OPERADORA_FODEMIPYME ? parseInt(this.state.riesgosTrabajoSeleccionado.id) : null,
            NombreArchivoCertificacionPymePympa: idFondo == REACT_APP_OPERADORA_FODEMIPYME ? this.state.nombreArchivoCertificacionPymePympa : null,
            FechaVencimientoCertificacionPymePympa: idFondo == REACT_APP_OPERADORA_FODEMIPYME ? this.state.fechaVencimientoCertificacionPymePympa : null,
            IdTamanoPymePympa: idFondo == REACT_APP_OPERADORA_FODEMIPYME ? parseInt(this.state.tamanoPymePympaSeleccionado.id) : null,
            ConsultaAbiertaCICSUGEF: this.state.consultaAbiertaCICSUGEF,
            NombreArchivoActaAprobacionCredito: this.state
                .nombreArchivoActaAprobacionCredito,
            NombreArchivoEstudioRegistralBienesMuebles: this.state
                .nombreArchivoEstudioRegistralBienesMuebles,
            NombreArchivoExcluirGarantiaBienMuebleInmueble: this.state
                .nombreArchivoExcluirGarantiaBienMuebleInmueble,
            NombreArchivoFacturaProforma: this.state.nombreArchivoFacturaProforma,
            NombreArchivoInformeAvaluo: this.state.nombreArchivoInformeAvaluo,
            NombreArchivoCICSugef: this.state.nombreArchivoCICSugef,
            Observaciones: this.state.observaciones,
            IdCentroEmpresarial:
                idCentroEmpresarial != null ? idCentroEmpresarial : "",
            TienePlazoBallon: this.state.tienePlazoBallon,
            PlazoBallonMeses: this.state.tienePlazoBallon
                ? parseInt(this.state.plazoBallonMeses)
                : "",
            NumeroOperacionBancaria:
                this.state.programaSeleccionado.cliente.cedulaJuridica == REACT_APP_CEDULA_BP
                    ? this.state.numeroOperacionBancaria
                    : "",
            NumeroSolicitudBancaria:
                this.state.programaSeleccionado.cliente.cedulaJuridica == REACT_APP_CEDULA_BP
                    ? this.state.numeroSolicitudBancaria
                    : "",
            //FNA
            IdTiposBeneficios: idFondo == REACT_APP_OPERADORA_FNA ? this.state.idTiposBeneficios : null,
            IdMejoraCondicion: idFondo == REACT_APP_OPERADORA_FNA ? this.state.indCondicionesPago ? this.state.mejoraCondicionesSeleccionado.id : null : null,
            DescripcionMejoraCondicion: idFondo == REACT_APP_OPERADORA_FNA ? this.state.indDescripcionMejoraCondicionesPago ? this.state.descripcionMejoraCondicion : null : null,
            DemostroAfectacionCOVID: idFondo == REACT_APP_OPERADORA_FNA ? this.state.demostroAfectacionCOVID : null,
            RealizaActividadDentroDelPais: idFondo == REACT_APP_OPERADORA_FNA ? this.state.realizaActividadDentroDelPais : null,
            CompromisoConservacionEmpleo: idFondo == REACT_APP_OPERADORA_FNA ? this.state.compromisoConservacionEmpleo : null,
            BeneficiaroPerteneceConglomerado: idFondo == REACT_APP_OPERADORA_FNA ? this.state.beneficiaroPerteneceConglomerado : null,
            MorosidadPrevioCOVIDMenor60: idFondo == REACT_APP_OPERADORA_FNA ? this.state.morosidadPrevioCOVIDMenor60 : null,
            AvalSustituyeGarantia: idFondo == REACT_APP_OPERADORA_FNA ? this.state.avalSustituyeGarantia : null,
            ClienteConCapacidadPago: idFondo == REACT_APP_OPERADORA_FNA ? this.state.clienteConCapacidadPago : null,
            ArchivoCuadroComparativo: idFondo == REACT_APP_OPERADORA_FNA ? this.state.archivoCuadroComparativo : null,
        };
        this.setState({ indButton: true });
        let texto = "¡Aval creado con éxito!";
        let redireccionarVista = "/avales";
        // Linea de Credito
        if (
            this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_LINEA_CREDITO
        ) {
            texto = "¡Línea de crédito creada con éxito!";
            redireccionarVista = "/lineas-creditos";
        }
        // Aval + Linea de Credito
        if (
            this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_AVAL_LINEA_CREDITO
        ) {
            texto = "¡Aval + Línea de crédito creada con éxito!";
            var idLinea = this.state.lineaCreditoSeleccionada != ""
                ? parseInt(this.state.lineaCreditoSeleccionada.id)
                : null;
            sessionStorage.setItem("idLineaCredito", idLinea);
            redireccionarVista = "/sub-creditos/" + idLinea;
        }

        CrearAval(data).then((res) => {
            if (res) {
                this.setState({ indButton: false });
                swal(fondoSeleccionado.nombreFondo, texto, "success", { button: "Aceptar", }).then(
                    () => {
                        window.location.href = redireccionarVista;
                    }
                );
            }
            this.setState({ indButton: false });
            $("#guardando").hide();
            $("#floppy").show();
        });
    };
    render() {
        const idRol = localStorage.getItem("idRol");
        const idFondo = localStorage.getItem("idFondo");
        if (idRol) {
            const rol = parseInt(idRol);
            if (rol != REACT_APP_ROL_INCLUSION) {
                window.location.href = "/avales";
            }
        } else {
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        }
        return (
            <div className="container text-center">
                <table id="tabla1"></table>
                <table id="tabla2"></table>
                <table id="tabla3"></table>
                <table id="tabla4"></table>
                <table id="tabla5"></table>
                <center>
                    <h2>Crear Aval</h2>
                </center>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="/admin">Inicio</a>
                        </li>
                        <li className="breadcrumb-item">
                            <a href="/avales">Avales</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Crear Aval
                        </li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col-md-12">
                        <Tabs
                            activeKey={this.state.llave}
                            transition={false}
                            onSelect={(k) => this.cambiarTab(k)}
                            id="noanim-tab-example"
                        >
                            <Tab
                                eventKey="informacionBasica"
                                title="Paso 1. Información Básica"
                            >
                                <Container fluid="md" style={{ padding: "20px 0 10px 0" }}>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Row>
                                                <Col xs={12} md={4}>
                                                    <Form.Group>
                                                        <Form.Label>Elija Moneda</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={this.cambioMoneda}
                                                            disabled={this.state.indButton}
                                                        >
                                                            {this.state.monedasLista.map(function (
                                                                data,
                                                                key
                                                            ) {
                                                                return (
                                                                    <option key={key} value={data.id}>
                                                                        {data.valor}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={4}>
                                                    <Form.Group>
                                                        <Form.Label>Elija Programa</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={this.cambioTipoPrograma}
                                                            disabled={this.state.indButton}
                                                        >
                                                            {this.state.tiposProgramasLista.map(function (
                                                                data,
                                                                key
                                                            ) {
                                                                return (
                                                                    <option key={key} value={data.id}>
                                                                        {data.valor}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={4}>
                                                    <Form.Group>
                                                        <Form.Label>Tipo Solicitud</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={this.cambioTipoSolicitud}
                                                            disabled={
                                                                this.state.indButton ||
                                                                this.state.indTipoSolicitud
                                                            }
                                                            value={this.state.tipoSolicitudSeleccionado.id}
                                                        >
                                                            {this.state.tiposSolicitudLista.map(function (
                                                                data,
                                                                key
                                                            ) {
                                                                return (
                                                                    <option key={key} value={data.id}>
                                                                        {data.valor}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            <Form.Row>
                                                <Col xs={12} md={4}></Col>
                                                <Col xs={12} md={4}>
                                                    <Form.Group id="programas">
                                                        <Form.Label>Programas</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={this.cambioPrograma}
                                                            disabled={this.state.indButton || this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_AVAL_LINEA_CREDITO}
                                                            value={this.state.programaSeleccionado.id}
                                                        >
                                                            {this.state.programasLista.map(function (
                                                                data,
                                                                key
                                                            ) {
                                                                return (
                                                                    <option key={key} value={data.id}>
                                                                        {data.detallePrograma.id} - {" "}
                                                                        {data.cliente.nombreOperadora} - {" "}
                                                                        {data.detallePrograma.nombrePrograma}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <span className="input-group-addon" id="spinProgramas">
                                                        <i className="fa fa-refresh fa-spin"></i>
                                                    </span>
                                                </Col>
                                                <Col xs={12} md={4}>
                                                    <Form.Group id="lineasCredito">
                                                        <Form.Label>Líneas de Crédito</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={this.cambioLineaCredito}
                                                            disabled={this.state.indButton}
                                                            value={this.state.lineaCreditoSeleccionada.id}
                                                        >
                                                            {this.state.lineasCreditoLista.map(function (
                                                                data,
                                                                key
                                                            ) {
                                                                return (
                                                                    <option key={key} value={data.id}>
                                                                        {data.id} - {data.nombreSolicitante}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            <hr />
                                            <Form.Row>
                                                <Col xs={12} md={4}>
                                                    <Form.Group>
                                                        <Form.Label>Monto Crédito</Form.Label>
                                                        <NumberFormat
                                                            thousandSeparator={true}
                                                            type="text"
                                                            className="form-control"
                                                            name="montoCredito"
                                                            value={this.state.montoCredito}
                                                            onChange={this.encargarseCambio}
                                                            required
                                                            min="0"
                                                            max="999999999999"
                                                            placeholder="0"
                                                            disabled={this.state.indButton}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={4} hidden={idFondo != REACT_APP_OPERADORA_FODEMIPYME}>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            ID Oficio Condición PYME o PYMPA
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="idOficio"
                                                            className="form-control"
                                                            onChange={this.encargarseCambio}
                                                            placeholder="Id Oficio"
                                                            value={this.state.idOficio}
                                                            required
                                                            disabled={this.state.indButton}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={4}>
                                                    <Form.Group>
                                                        <Form.Label>Número Operación</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="numeroOperacion"
                                                            className="form-control"
                                                            onChange={this.encargarseCambio}
                                                            placeholder="Número Operación"
                                                            value={this.state.numeroOperacion}
                                                            required
                                                            disabled={this.state.indButton}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={2}></Col>
                                            </Form.Row>
                                            <Form.Row>
                                                <Col xs={12} md={4}></Col>
                                                <Col xs={12} md={4}>
                                                    {this.state.indEntidadBPFODEMIPYME ? (
                                                        <Form.Group>
                                                            <Form.Label>Número Solicitud Bancaria</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="numeroSolicitudBancaria"
                                                                className="form-control"
                                                                onChange={this.encargarseCambio}
                                                                placeholder="Número Solicitud Bancaria"
                                                                value={this.state.numeroSolicitudBancaria}
                                                                required
                                                                disabled={this.state.indButton}
                                                            />
                                                        </Form.Group>
                                                    ) : (
                                                        <Form.Group></Form.Group>
                                                    )}
                                                </Col>
                                                <Col xs={12} md={4}>
                                                    {this.state.indEntidadBPFODEMIPYME ? (
                                                        <Form.Group>
                                                            <Form.Label>Número Operación Bancaria</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="numeroOperacionBancaria"
                                                                className="form-control"
                                                                onChange={this.encargarseCambio}
                                                                placeholder="Número Operación Bancaria"
                                                                value={this.state.numeroOperacionBancaria}
                                                                required
                                                                disabled={this.state.indButton}
                                                            />
                                                        </Form.Group>
                                                    ) : (
                                                        <Form.Group></Form.Group>
                                                    )}
                                                </Col>
                                                <Col xs={12} md={2}></Col>
                                            </Form.Row>
                                            <hr />
                                            <Row>
                                                <Col md={6}>
                                                    <Accordion>
                                                        <Card>
                                                            <Card.Header>
                                                                <Accordion.Toggle
                                                                    as={Button}
                                                                    variant="link"
                                                                    eventKey="InfoCliente"
                                                                >
                                                                    Información del Cliente{" "}
                                                                </Accordion.Toggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="InfoCliente">
                                                                <Card.Body>
                                                                    <Form.Row>
                                                                        <Col xs={12} md={12}>
                                                                            <h4>Información del Cliente</h4>
                                                                            <p>Información básica del cliente</p>
                                                                            <Form.Group>
                                                                                <Form.Label>Nombre Cliente</Form.Label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    name="nombreSolicitante"
                                                                                    className="form-control"
                                                                                    placeholder="Nombre Cliente"
                                                                                    onChange={this.encargarseCambio}
                                                                                    value={this.state.nombreSolicitante}
                                                                                    title="El nombre del cliente debe contener solo letras. Min: 5 caracteres. Max: 100 caracteres"
                                                                                    required
                                                                                    disabled={this.state.indButton}
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col xs={12} md={6}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    Tipo Identificación
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={
                                                                                        this.cambioTipoIdentificacion
                                                                                    }
                                                                                    disabled={this.state.indButton}
                                                                                    value={this.state.tiposIdentificacionSeleccionada.id}
                                                                                >
                                                                                    {this.state.tiposIdentificacionLista.map(
                                                                                        function (data, key) {
                                                                                            return (
                                                                                                <option
                                                                                                    key={key}
                                                                                                    value={data.id}
                                                                                                >
                                                                                                    {data.valor}
                                                                                                </option>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col xs={12} md={6}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    Número Identificación
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    min="0"
                                                                                    max="999"
                                                                                    name="numeroIdentificacionSolicitante"
                                                                                    className="form-control"
                                                                                    onChange={this.encargarseCambio}
                                                                                    placeholder="0"
                                                                                    value={
                                                                                        this.state
                                                                                            .numeroIdentificacionSolicitante
                                                                                    }
                                                                                    required
                                                                                    disabled={this.state.indButton}
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row>
                                                                        <Col xs={12} md={4}>
                                                                            <Form.Group>
                                                                                <Form.Label>Provincia</Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={this.cambioProvincia}
                                                                                    disabled={this.state.indButton}
                                                                                >
                                                                                    {this.state.provinciaLista.map(
                                                                                        function (data, key) {
                                                                                            return (
                                                                                                <option
                                                                                                    key={key}
                                                                                                    value={data.id}
                                                                                                >
                                                                                                    {data.nombre}
                                                                                                </option>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col xs={12} md={4}>
                                                                            <Form.Group>
                                                                                <Form.Label>Cantón</Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={this.cambioCanton}
                                                                                    disabled={this.state.indButton}
                                                                                >
                                                                                    {this.state.cantonLista.map(function (
                                                                                        data,
                                                                                        key
                                                                                    ) {
                                                                                        return (
                                                                                            <option key={key} value={data.id}>
                                                                                                {data.nombre}
                                                                                            </option>
                                                                                        );
                                                                                    })}
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col xs={12} md={4}>
                                                                            <Form.Group>
                                                                                <Form.Label>Distrito</Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={this.cambioDistrito}
                                                                                    disabled={this.state.indButton}
                                                                                >
                                                                                    {this.state.distritoLista.map(
                                                                                        function (data, key) {
                                                                                            return (
                                                                                                <option
                                                                                                    key={key}
                                                                                                    value={data.id}
                                                                                                >
                                                                                                    {data.nombre}
                                                                                                </option>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row>
                                                                        <Col xs={12} md={12}>
                                                                            <Form.Group>
                                                                                <Form.Label>Dirección</Form.Label>
                                                                                <Form.Control
                                                                                    as="textarea"
                                                                                    name="direccion"
                                                                                    minLength="5"
                                                                                    maxLength="100"
                                                                                    value={this.state.direccion}
                                                                                    onChange={this.encargarseCambio}
                                                                                    required
                                                                                    rows={3}
                                                                                    disabled={this.state.indButton}
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row>
                                                                        <Col xs={12} md={12}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    Actividad Económica
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    as="textarea"
                                                                                    name="actividadEconomica"
                                                                                    minLength="5"
                                                                                    maxLength="100"
                                                                                    value={this.state.actividadEconomica}
                                                                                    onChange={this.encargarseCambio}
                                                                                    required
                                                                                    rows={3}
                                                                                    disabled={this.state.indButton}
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row>
                                                                        <Col xs={12} md={3}></Col>
                                                                        <Col xs={12} md={6}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    Sector Económico
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={this.cambioSectorEconomico}
                                                                                    disabled={this.state.indButton}
                                                                                >
                                                                                    {this.state.sectorEconomicoLista.map(
                                                                                        function (data, key) {
                                                                                            return (
                                                                                                <option
                                                                                                    key={key}
                                                                                                    value={data.id}
                                                                                                >
                                                                                                    {data.valor}
                                                                                                </option>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col xs={12} md={3}></Col>
                                                                    </Form.Row>
                                                                    <Form.Row>
                                                                        <Col xs={12} md={12}>
                                                                            <Form.Group>
                                                                                <Form.Label>Sección CIIU</Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={this.cambioSesionCIIUU}
                                                                                    disabled={this.state.indButton}
                                                                                >
                                                                                    {this.state.seccionCIIULista.map(
                                                                                        function (data, key) {
                                                                                            return (
                                                                                                <option key={key} value={data}>
                                                                                                    {data}
                                                                                                </option>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col xs={12} md={12}>
                                                                            <Form.Group>
                                                                                <Form.Label>División CIIU</Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={this.cambioDivisionCIIUU}
                                                                                    disabled={this.state.indButton}
                                                                                >
                                                                                    {this.state.divisionCIIULista.map(
                                                                                        function (data, key) {
                                                                                            return (
                                                                                                <option key={key} value={data}>
                                                                                                    {data}
                                                                                                </option>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row>
                                                                        <Col xs={12} md={12}>
                                                                            <Form.Group>
                                                                                <Form.Label>Grupo CIIU</Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={this.cambioGrupoCIIUU}
                                                                                    disabled={this.state.indButton}
                                                                                >
                                                                                    {this.state.grupoCIIULista.map(
                                                                                        function (data, key) {
                                                                                            return (
                                                                                                <option key={key} value={data}>
                                                                                                    {data}
                                                                                                </option>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col xs={12} md={12}>
                                                                            <Form.Group>
                                                                                <Form.Label>Clase CIIU</Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={this.cambioClaseCIIUU}
                                                                                    disabled={this.state.indButton}
                                                                                >
                                                                                    {this.state.claseCIIUULista.map(
                                                                                        function (data, key) {
                                                                                            return (
                                                                                                <option key={key} value={data}>
                                                                                                    {data}
                                                                                                </option>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row>
                                                                        <Col xs={12} md={3}></Col>
                                                                        <Col xs={12} md={6}>
                                                                            <Form.Group>
                                                                                <Form.Label>Código CIIU</Form.Label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    name="codigoCIIU"
                                                                                    className="form-control"
                                                                                    placeholder="Código CIIU"
                                                                                    value={this.state.codigoCIIU}
                                                                                    onChange={this.encargarseCambio}
                                                                                    disabled
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col xs={12} md={3}></Col>
                                                                    </Form.Row>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>
                                                </Col>
                                                <Col md={6}>
                                                    <Accordion>
                                                        <Card>
                                                            <Card.Header>
                                                                <Accordion.Toggle
                                                                    as={Button}
                                                                    variant="link"
                                                                    eventKey="CIC"
                                                                >
                                                                    Parámetros de Análisis{" "}
                                                                </Accordion.Toggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="CIC">
                                                                <Card.Body>
                                                                    <Form.Row>
                                                                        <Col xs={12} md={12}>
                                                                            <h4>Parámetros de Análisis</h4>
                                                                            <p>Reporte crediticio para la entidad</p>
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row>
                                                                        <Col xs={12} md={6}>
                                                                            <Form.Group>
                                                                                <Form.Label
                                                                                    style={{ fontSize: "15px" }}
                                                                                >
                                                                                    Nivel Comportamiento Pago SBD
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={
                                                                                        this
                                                                                            .cambioNivelComportamientoPagoSBD
                                                                                    }
                                                                                    disabled={this.state.indButton}
                                                                                >
                                                                                    {this.state.nivelPagoHistoricoSBDLista.map(
                                                                                        function (data, key) {
                                                                                            return (
                                                                                                <option
                                                                                                    key={key}
                                                                                                    value={data.id}
                                                                                                >
                                                                                                    {data.valor}
                                                                                                </option>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col xs={12} md={6}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    Puntaje Final del Deudor SBD
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    min="0"
                                                                                    max="9"
                                                                                    step="any"
                                                                                    name="puntajeFinalDeudorSBD"
                                                                                    value={
                                                                                        this.state.puntajeFinalDeudorSBD
                                                                                    }
                                                                                    onChange={this.encargarseCambio}
                                                                                    className="form-control"
                                                                                    placeholder="0"
                                                                                    required
                                                                                    disabled={this.state.indButton}
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row>
                                                                        <Col xs={12} md={6}>
                                                                            <Form.Group>
                                                                                <Form.Label
                                                                                    style={{ fontSize: "15px" }}
                                                                                >
                                                                                    Nivel CIC
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={this.cambioNivelCIC}
                                                                                    disabled={this.state.indButton}
                                                                                >
                                                                                    {this.state.nivelCICLista.map(
                                                                                        function (data, key) {
                                                                                            return (
                                                                                                <option
                                                                                                    key={key}
                                                                                                    value={data.id}
                                                                                                >
                                                                                                    {data.valor}
                                                                                                </option>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col xs={12} md={6}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    Puntaje Final CIC
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    min="0"
                                                                                    max="9"
                                                                                    name="puntajeCIC"
                                                                                    step="any"
                                                                                    value={this.state.puntajeCIC}
                                                                                    onChange={this.encargarseCambio}
                                                                                    className="form-control"
                                                                                    placeholder="0"
                                                                                    required
                                                                                    disabled={this.state.indButton}
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row>
                                                                        <Col xs={12} md={4}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    Nivel Histórico de Pago Entidad
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={
                                                                                        this.cambioNivelHistoricoPagoEntidad
                                                                                    }
                                                                                    disabled={this.state.indButton}
                                                                                >
                                                                                    {this.state.nivelHistoricoPagoEntidadLista.map(
                                                                                        function (data, key) {
                                                                                            return (
                                                                                                <option
                                                                                                    key={key}
                                                                                                    value={data.id}
                                                                                                >
                                                                                                    {data.valor}
                                                                                                </option>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col xs={12} md={4}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    Tamaño <br></br>Empresa
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={this.cambioTamanoEmpresa}
                                                                                    disabled={this.state.indButton}
                                                                                >
                                                                                    {this.state.tamanoEmpresaLista.map(
                                                                                        function (data, key) {
                                                                                            return (
                                                                                                <option
                                                                                                    key={key}
                                                                                                    value={data.id}
                                                                                                >
                                                                                                    {data.valor}
                                                                                                </option>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col xs={12} md={4}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    Tipo Garantía Adicional al Aval
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={
                                                                                        this.cambioTipoGarantiaAdicional
                                                                                    }
                                                                                    disabled={this.state.indButton}
                                                                                >
                                                                                    {this.state.tipoGarantiaAdicionalLista.map(
                                                                                        function (data, key) {
                                                                                            return (
                                                                                                <option
                                                                                                    key={key}
                                                                                                    value={data.id}
                                                                                                >
                                                                                                    {data.valor}
                                                                                                </option>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row>
                                                                        <Col xs={12} md={3}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    Años Experiencia Empresa
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    min="0"
                                                                                    name="annosExperienciaEmpresa"
                                                                                    onChange={this.encargarseCambio}
                                                                                    value={
                                                                                        this.state.annosExperienciaEmpresa
                                                                                    }
                                                                                    className="form-control"
                                                                                    placeholder="0"
                                                                                    required
                                                                                    disabled={this.state.indButton}
                                                                                    onKeyPress={(event) => {
                                                                                        if (!/[0-9]/.test(event.key)) {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col xs={12} md={3}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    Impacto <br></br>Empleo (Mujeres)
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    min="0"
                                                                                    max="999"
                                                                                    name="impactoEmpleoMujeres"
                                                                                    onChange={this.encargarseCambio}
                                                                                    value={
                                                                                        this.state.impactoEmpleoMujeres
                                                                                    }
                                                                                    className="form-control"
                                                                                    placeholder="0"
                                                                                    required
                                                                                    disabled={this.state.indButton}
                                                                                    pattern="[0-9]{10}"
                                                                                    onKeyPress={(event) => {
                                                                                        if (!/[0-9]/.test(event.key)) {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col xs={12} md={3}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    Impacto Empleo (Hombres)
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    min="0"
                                                                                    max="999"
                                                                                    name="impactoEmpleoHombres"
                                                                                    onChange={this.encargarseCambio}
                                                                                    value={
                                                                                        this.state.impactoEmpleoHombres
                                                                                    }
                                                                                    className="form-control"
                                                                                    placeholder="0"
                                                                                    required
                                                                                    disabled={this.state.indButton}
                                                                                    onKeyPress={(event) => {
                                                                                        if (!/[0-9]/.test(event.key)) {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col xs={12} md={3}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    Total <br></br>Impacto Empleo
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    min="0"
                                                                                    disabled
                                                                                    name="totalImpactoEmpleo"
                                                                                    onChange={this.encargarseCambio}
                                                                                    value={this.state.totalImpactoEmpleo}
                                                                                    className="form-control"
                                                                                    placeholder="0"
                                                                                    required
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row>
                                                                        <Col xs={12} md={12}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    Plan de Inversión
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    as="textarea"
                                                                                    minLength="5"
                                                                                    maxLength="100"
                                                                                    name="planInversion"
                                                                                    onChange={this.encargarseCambio}
                                                                                    value={this.state.planInversion}
                                                                                    rows={3}
                                                                                    disabled={this.state.indButton}
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row hidden={idFondo == REACT_APP_OPERADORA_FODEMIPYME}>
                                                                        <Col xs={12} md={12}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    1. Tipo de beneficio indicado por el operador (presione CTRL para seleccionar varios elementos)
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    multiple={true}
                                                                                    as="select"
                                                                                    onChange={
                                                                                        this.cambioFNATipoBeneficio
                                                                                    }
                                                                                    disabled={this.state.indButton}
                                                                                >
                                                                                    {this.state.fnaTipoBeneficionLista.map(
                                                                                        function (data, key) {
                                                                                            return (
                                                                                                <option
                                                                                                    key={key}
                                                                                                    value={data.id}
                                                                                                >
                                                                                                    {data.valor}
                                                                                                </option>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row hidden={!this.state.indCondicionesPago}>
                                                                        <Col xs={12} md={12}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    Mejoras en las condiciones de pago:
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={
                                                                                        this.cambioMejoraCondicionesPagos
                                                                                    }
                                                                                    disabled={this.state.indButton}
                                                                                >
                                                                                    {this.state.mejoraCondicionesLista.map(
                                                                                        function (data, key) {
                                                                                            return (
                                                                                                <option
                                                                                                    key={key}
                                                                                                    value={data.id}
                                                                                                >
                                                                                                    {data.valor}
                                                                                                </option>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </Form.Control>
                                                                                <span className="input-group-addon" id="spinMejoraCondiciones">
                                                                                    <i className="fa fa-refresh fa-spin"></i>
                                                                                </span>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row hidden={!this.state.indDescripcionMejoraCondicionesPago}>
                                                                        <Col xs={12} md={12}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    Agregar una descripción
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    as="textarea"
                                                                                    minLength="5"
                                                                                    maxLength="300"
                                                                                    name="descripcionMejoraCondicion"
                                                                                    onChange={this.encargarseCambio}
                                                                                    value={this.state.descripcionMejoraCondicion}
                                                                                    rows={3}
                                                                                    disabled={this.state.indButton}
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <hr hidden={idFondo == REACT_APP_OPERADORA_FODEMIPYME}></hr>
                                                                    <Form.Row hidden={idFondo == REACT_APP_OPERADORA_FODEMIPYME}>
                                                                        <Col xs={12} md={12}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    2. Demostró afectación con COVID 19
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={
                                                                                        this.cambionAfectacionCOVID19
                                                                                    }
                                                                                    disabled={this.state.indButton}
                                                                                >
                                                                                    <option id="SI">SI</option>
                                                                                    <option id="NO">NO</option>
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row hidden={idFondo == REACT_APP_OPERADORA_FODEMIPYME}>
                                                                        <Col xs={12} md={12}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    3. La actividad económica se realiza:
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={
                                                                                        this.cambionActividadDentroPais
                                                                                    }
                                                                                    disabled={this.state.indButton}
                                                                                >
                                                                                    <option id="Dentro del país">Dentro del país</option>
                                                                                    <option id="Fuera del país">Fuera del país</option>
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row hidden={idFondo == REACT_APP_OPERADORA_FODEMIPYME}>
                                                                        <Col xs={12} md={12}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    4. El cliente a suscrito el compromiso para la conservación del empleo
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={
                                                                                        this.cambionConservacionEmpleo
                                                                                    }
                                                                                    disabled={this.state.indButton}
                                                                                >
                                                                                    <option id="SI">SI</option>
                                                                                    <option id="NO">NO</option>
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row hidden={idFondo == REACT_APP_OPERADORA_FODEMIPYME}>
                                                                        <Col xs={12} md={12}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    5. El beneficiario pertenece a un conglomerado financiero según la definición del BCCR
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={
                                                                                        this.cambionBeneficiarioConglomerado
                                                                                    }
                                                                                    disabled={this.state.indButton}
                                                                                >
                                                                                    <option id="NO">NO</option>
                                                                                    <option id="SI">SI</option>
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row hidden={idFondo == REACT_APP_OPERADORA_FODEMIPYME}>
                                                                        <Col xs={12} md={12}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    6. El beneficiario presentaba previo a la declaratoria de emergencia nacional generada por la pandemia del COVID-19 CPH bueno o aceptable en el CIC, y una morosidad menor o igual a 60 días
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={
                                                                                        this.cambionMorosidadPrevioCOVID
                                                                                    }
                                                                                    disabled={this.state.indButton}
                                                                                >
                                                                                    <option id="SI">SI</option>
                                                                                    <option id="NO">NO</option>
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row hidden={idFondo == REACT_APP_OPERADORA_FODEMIPYME}>
                                                                        <Col xs={12} md={12}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    7. En esta solicitud el aval fue utilizado para
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={
                                                                                        this.cambioAvalSustituyeGarantia
                                                                                    }
                                                                                    disabled={this.state.indButton}
                                                                                >
                                                                                    <option id="Complementar la garantía existente">Complementar la garantía existente</option>
                                                                                    <option id="Sustituir garantía">Sustituir garantía</option>
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row hidden={idFondo == REACT_APP_OPERADORA_FODEMIPYME}>
                                                                        <Col xs={12} md={12}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    8. El cliente cuenta con capacidad de pago verificada y documentada para atender las condiciones del nuevo compromiso
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={
                                                                                        this.cambioClienteCapacidadPago
                                                                                    }
                                                                                    disabled={this.state.indButton}
                                                                                >
                                                                                    <option id="SI">SI</option>
                                                                                    <option id="NO">NO</option>
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row hidden={idFondo == REACT_APP_OPERADORA_FODEMIPYME}>
                                                                        <Col xs={12} md={12}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    9. Cargar cuadro comparativo de las condiciones financieras antes y después del aval
                                                                                </Form.Label>
                                                                                <Form.File
                                                                                    name="archivoCuadroComparativo"
                                                                                    onChange={this.encargarseCambioArchivosFNA}
                                                                                    accept="application/pdf"
                                                                                    disabled={this.state.indButton}
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Form.Row>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>
                                                </Col>
                                            </Row>
                                            <hr></hr>
                                            <Row>
                                                <Col md={6}>
                                                    <Accordion>
                                                        <Card>
                                                            <Card.Header>
                                                                <Accordion.Toggle
                                                                    as={Button}
                                                                    variant="link"
                                                                    eventKey="InfoFinanciera"
                                                                >
                                                                    Información Financiera
                                                                </Accordion.Toggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="InfoFinanciera">
                                                                <Card.Body>
                                                                    <Row>
                                                                        <Col xs={12} md={12}>
                                                                            <h4>Información Financiera</h4>
                                                                            <p>Datos anuales en Colones</p>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xs={12} md={4}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    Ingresos Anuales
                                                                                </Form.Label>
                                                                                <NumberFormat
                                                                                    thousandSeparator={true}
                                                                                    type="text"
                                                                                    min="0"
                                                                                    max="999999999999"
                                                                                    className="form-control"
                                                                                    name="totalIngresoAnual"
                                                                                    value={this.state.totalIngresoAnual}
                                                                                    onChange={this.encargarseCambio}
                                                                                    required
                                                                                    placeholder="0"
                                                                                    disabled={this.state.indButton}
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col xs={12} md={8}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    Indicar Fuente Referencia Información
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={
                                                                                        this.cambioFuenteReferenciaIngreso
                                                                                    }
                                                                                    disabled={this.state.indButton}
                                                                                >
                                                                                    {this.state.fuenteReferenciaInformacionIngresosLista.map(
                                                                                        function (data, key) {
                                                                                            return (
                                                                                                <option
                                                                                                    key={key}
                                                                                                    value={data.id}
                                                                                                >
                                                                                                    {data.valor}
                                                                                                </option>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xs={12} md={4}>
                                                                            <Form.Group>
                                                                                <Form.Label>Egresos Anuales</Form.Label>
                                                                                <NumberFormat
                                                                                    thousandSeparator={true}
                                                                                    type="text"
                                                                                    min="0"
                                                                                    max="999999999999"
                                                                                    className="form-control"
                                                                                    name="totalEgresoAnual"
                                                                                    value={this.state.totalEgresoAnual}
                                                                                    onChange={this.encargarseCambio}
                                                                                    required
                                                                                    placeholder="0"
                                                                                    disabled={this.state.indButton}
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col xs={12} md={8}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    Indicar Fuente Referencia Información
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={
                                                                                        this.cambioFuenteReferenciaEgreso
                                                                                    }
                                                                                    disabled={this.state.indButton}
                                                                                >
                                                                                    {this.state.fuenteReferenciaInformacionEgresosLista.map(
                                                                                        function (data, key) {
                                                                                            return (
                                                                                                <option
                                                                                                    key={key}
                                                                                                    value={data.id}
                                                                                                >
                                                                                                    {data.valor}
                                                                                                </option>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xs={12} md={4}>
                                                                            <Form.Group>
                                                                                <Form.Label>Flujo Operativo</Form.Label>
                                                                                <NumberFormat
                                                                                    thousandSeparator={true}
                                                                                    type="text"
                                                                                    min="0"
                                                                                    max="999999999999"
                                                                                    className="form-control"
                                                                                    name="montoFlujoOperativo"
                                                                                    value={this.state.montoFlujoOperativo}
                                                                                    onChange={this.encargarseCambio}
                                                                                    required
                                                                                    placeholder="0"
                                                                                    disabled
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col xs={12} md={8}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    <strong>Monto Flujo Operativo</strong>
                                                                                </Form.Label>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Row>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>
                                                </Col>
                                                <Col md={6}>
                                                    <Accordion>
                                                        <Card>
                                                            <Card.Header>
                                                                <Accordion.Toggle
                                                                    as={Button}
                                                                    variant="link"
                                                                    eventKey="InfoCrediticia"
                                                                >
                                                                    Información Crediticia
                                                                </Accordion.Toggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="InfoCrediticia">
                                                                <Card.Body>
                                                                    <Row>
                                                                        <Col xs={12} md={12}>
                                                                            <h4>Información Crediticia</h4>
                                                                            <p>Datos mensuales en Colones</p>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xs={12} md={4}>
                                                                            <p>
                                                                                Sumatoria de cuotas mensuales de
                                                                                créditos en el CIC (No incluir la cuota
                                                                                del nuevo crédito)
                                                                            </p>
                                                                        </Col>
                                                                        <Col xs={12} md={4}>
                                                                            <Form.Group>
                                                                                <Form.Label>Monto Mensual</Form.Label>
                                                                                <NumberFormat
                                                                                    thousandSeparator={true}
                                                                                    type="text"
                                                                                    min="0"
                                                                                    max="999999999999"
                                                                                    className="form-control"
                                                                                    name="cuotaMensualCreditoCIC"
                                                                                    value={
                                                                                        this.state.cuotaMensualCreditoCIC
                                                                                    }
                                                                                    onChange={this.encargarseCambio}
                                                                                    required
                                                                                    placeholder="0"
                                                                                    disabled={this.state.indButton}
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col xs={12} md={4}>
                                                                            <Form.Group>
                                                                                <Form.Label>Monto Anual</Form.Label>
                                                                                <NumberFormat
                                                                                    thousandSeparator={true}
                                                                                    type="text"
                                                                                    min="0"
                                                                                    max="999999999999"
                                                                                    className="form-control"
                                                                                    name="montoAnualCreditoCIC"
                                                                                    value={
                                                                                        this.state.montoAnualCreditoCIC
                                                                                    }
                                                                                    onChange={this.encargarseCambio}
                                                                                    required
                                                                                    placeholder="0"
                                                                                    disabled
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xs={12} md={4}>
                                                                            <p>
                                                                                Sumatoria de cuotas mensuales de
                                                                                préstamos con otros acreedores
                                                                            </p>
                                                                        </Col>
                                                                        <Col xs={12} md={4}>
                                                                            <Form.Group>
                                                                                <Form.Label>Monto Mensual</Form.Label>
                                                                                <NumberFormat
                                                                                    thousandSeparator={true}
                                                                                    type="text"
                                                                                    min="0"
                                                                                    max="999999999999"
                                                                                    className="form-control"
                                                                                    name="cuotaMensualOtrosPrestamo"
                                                                                    value={
                                                                                        this.state.cuotaMensualOtrosPrestamo
                                                                                    }
                                                                                    onChange={this.encargarseCambio}
                                                                                    required
                                                                                    placeholder="0"
                                                                                    disabled={this.state.indButton}
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col xs={12} md={4}>
                                                                            <Form.Group>
                                                                                <Form.Label>Monto Anual</Form.Label>
                                                                                <NumberFormat
                                                                                    thousandSeparator={true}
                                                                                    type="text"
                                                                                    min="0"
                                                                                    max="999999999999"
                                                                                    className="form-control"
                                                                                    name="montoAnualOtrosPrestamos"
                                                                                    value={
                                                                                        this.state.montoAnualOtrosPrestamos
                                                                                    }
                                                                                    onChange={this.encargarseCambio}
                                                                                    required
                                                                                    placeholder="0"
                                                                                    disabled
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xs={12} md={4}>
                                                                            <p>
                                                                                Monto de las sumatorias de las cuotas
                                                                                actuales
                                                                            </p>
                                                                        </Col>
                                                                        <Col xs={12} md={4}>
                                                                            <Form.Group>
                                                                                <Form.Label>Monto Mensual</Form.Label>
                                                                                <NumberFormat
                                                                                    thousandSeparator={true}
                                                                                    type="text"
                                                                                    min="0"
                                                                                    max="999999999999"
                                                                                    className="form-control"
                                                                                    name="totalCuotasMensual"
                                                                                    value={this.state.totalCuotasMensual}
                                                                                    onChange={this.encargarseCambio}
                                                                                    required
                                                                                    placeholder="0"
                                                                                    disabled
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col xs={12} md={4}>
                                                                            <Form.Group>
                                                                                <Form.Label>Monto Anual</Form.Label>
                                                                                <NumberFormat
                                                                                    thousandSeparator={true}
                                                                                    type="text"
                                                                                    min="0"
                                                                                    max="999999999999"
                                                                                    className="form-control"
                                                                                    name="montoTotalCuotasAnuales"
                                                                                    value={
                                                                                        this.state.montoTotalCuotasAnuales
                                                                                    }
                                                                                    onChange={this.encargarseCambio}
                                                                                    required
                                                                                    placeholder="0"
                                                                                    disabled
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Row>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Container>
                            </Tab>
                            <Tab
                                eventKey="garantiasApotadas"
                                title="Paso 2. Garantías Aportadas por el Cliente"
                                disabled={this.state.indIrPaso2}
                            >
                                <Container fluid="md" style={{ padding: "20px 0 10px 0" }}>
                                    <Row hidden={this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_AVAL_LINEA_CREDITO}>
                                        <Col md={12}>
                                            <Row>
                                                {" "}
                                                <Col xs={12} md={4}>
                                                    <Form.Group>
                                                        <Form.Label>Tipo de Garantía</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={this.cambioTipoGarantiaTabla}
                                                            value={
                                                                this.state.tipoGarantiaSeleccionadaTabla.id
                                                            }
                                                            disabled={this.state.indButton}
                                                        >
                                                            {this.state.tipoGarantiaAdicionalLista.map(
                                                                function (data, key) {
                                                                    return (
                                                                        <option key={key} value={data.id}>
                                                                            {data.valor}
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={4}>
                                                    <Form.Group>
                                                        <Form.Label>Descripción</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="descripcionGarantia"
                                                            className="form-control"
                                                            placeholder="Descripción"
                                                            minLength="5"
                                                            maxLength="100"
                                                            onChange={this.encargarseCambio}
                                                            value={this.state.descripcionGarantia}
                                                            disabled={this.state.indButton}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={4}>
                                                    <Form.Group>
                                                        <Form.Label>Valor</Form.Label>
                                                        <NumberFormat
                                                            thousandSeparator={true}
                                                            type="text"
                                                            min="0"
                                                            max="999999999999"
                                                            className="form-control"
                                                            name="valorGarantia"
                                                            value={this.state.valorGarantia}
                                                            onChange={this.encargarseCambio}
                                                            required
                                                            placeholder="0"
                                                            disabled={this.state.indButton}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} md={3}>
                                                    <Form.Group>
                                                        <Form.Label>% Máximo Aceptación</Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            name="porcentajeMaximoAceptacion"
                                                            className="form-control"
                                                            placeholder="0"
                                                            min="0"
                                                            max="100"
                                                            onChange={this.encargarseCambio}
                                                            value={this.state.porcentajeMaximoAceptacion}
                                                            disabled={this.state.indButton}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <Form.Group>
                                                        <Form.Label>Monto Máximo Aceptación</Form.Label>
                                                        <NumberFormat
                                                            thousandSeparator={true}
                                                            type="text"
                                                            min="0"
                                                            max="999999999999"
                                                            step="0.01"
                                                            className="form-control"
                                                            name="montoMaximoAceptacion"
                                                            value={this.state.montoMaximoAceptacion}
                                                            onChange={this.encargarseCambio}
                                                            required
                                                            placeholder="0"
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Monto Definitivo Responsabilidad
                                                        </Form.Label>
                                                        <NumberFormat
                                                            thousandSeparator={true}
                                                            type="text"
                                                            min="0"
                                                            max="999999999999"
                                                            step="0.01"
                                                            className="form-control"
                                                            name="montoDefinitivoResponsabilidad"
                                                            value={this.state.montoDefinitivoResponsabilidad}
                                                            onChange={this.encargarseCambio}
                                                            required
                                                            placeholder="0"
                                                            disabled={this.state.indButton}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <Form.Group>
                                                        <Form.Label>% Responsabilidad</Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            name="porcentajeResponsabilidad"
                                                            className="form-control"
                                                            placeholder="0"
                                                            min="0"
                                                            max="999999999999"
                                                            disabled
                                                            onChange={this.encargarseCambio}
                                                            value={this.state.porcentajeResponsabilidad}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} md={4}></Col>
                                                <Col xs={12} md={4}>
                                                    <center>
                                                        <button
                                                            type="button"
                                                            onClick={this.agregarColumna}
                                                            className="btn btn-primary pull-left border-radius"
                                                            disabled={this.state.indButton}
                                                        >
                                                            Agregar Garantía{" "}
                                                            <i className="fa fa-plus" aria-hidden="true"></i>
                                                        </button>
                                                    </center>
                                                </Col>{" "}
                                                <Col xs={12} md={4}></Col>{" "}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr hidden={this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_AVAL_LINEA_CREDITO || this.state.tablaGarantiasAportadas.length == 0} />
                                    <div hidden={this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_AVAL_LINEA_CREDITO || this.state.tablaGarantiasAportadas.length == 0} className="table-responsive">
                                        <table
                                            id="tabla_garantias"
                                            className="table table-striped table-bordered"
                                            style={{ width: "100%" }}
                                        >
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Tipo de Garantía</th>
                                                    <th className="text-center">Descripción</th>
                                                    <th className="text-center">Valor</th>
                                                    <th className="text-center">% Máximo Aceptación</th>
                                                    <th className="text-center">Monto Máximo Aceptación</th>
                                                    <th className="text-center">
                                                        Monto Definitivo Responsabilidad
                                                    </th>
                                                    <th className="text-center">% Responsabilidad</th>
                                                    <th className="text-center">Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.tablaGarantiasAportadas.map((c, index) => {
                                                    return (
                                                        <tr id={c.id}>
                                                            <td>{c.tipoGarantia.valor}</td>
                                                            <td>{c.descripcion}</td>
                                                            <td>
                                                                {parseFloat(c.valor)
                                                                    .toFixed(2)
                                                                    .toString()
                                                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                            </td>
                                                            <td>
                                                                {parseFloat(
                                                                    c.porcentajeMaximoAceptacion
                                                                ).toFixed(2)}
                                                            </td>
                                                            <td>
                                                                {parseFloat(c.montoMaximoAceptacion)
                                                                    .toFixed(2)
                                                                    .toString()
                                                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                            </td>
                                                            <td>
                                                                {parseFloat(c.montoDefinitivoResponsabilidad)
                                                                    .toFixed(2)
                                                                    .toString()
                                                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                            </td>
                                                            <td>
                                                                {parseFloat(
                                                                    c.porcentajeResponsabilidad
                                                                ).toFixed(2)}
                                                            </td>
                                                            <td>
                                                                <a
                                                                    className="btn btn-primary btn-sm border-radius"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => this.borrarColumna(index)}
                                                                >
                                                                    <i className="fa fa-minus-circle"></i>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <hr hidden={this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_AVAL_LINEA_CREDITO} />
                                    <Container fluid="md" style={{ padding: "20px 0 10px 0" }}>
                                        <Row>
                                            <Col xs={12} md={4}></Col>
                                            <Col xs={12} md={8}>
                                                <Row>
                                                    <Col xs={12} md={4}>
                                                        <p>
                                                            Total de las garantías aportadas por el cliente:
                                                        </p>
                                                    </Col>
                                                    <Col xs={12} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>Monto Total</Form.Label>
                                                            <NumberFormat
                                                                thousandSeparator={true}
                                                                type="text"
                                                                className="form-control"
                                                                name="montoTotalGarantiasAportadasCliente"
                                                                value={
                                                                    this.state.montoTotalGarantiasAportadasCliente
                                                                }
                                                                disabled
                                                                min="0"
                                                                placeholder="0"
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>% Total</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                min="0"
                                                                className="form-control"
                                                                placeholder="0"
                                                                value={
                                                                    this.state
                                                                        .porcentajeTotalGarantiasAportadasSolicitante
                                                                }
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Container fluid="md" style={{ padding: "20px 0 10px 0" }}>
                                        <Row>
                                            <Col xs={12} md={4}></Col>
                                            <Col xs={12} md={8}>
                                                <Row>
                                                    <Col xs={12} md={4}>
                                                        <p>Monto Aval Solicitado:</p>
                                                    </Col>
                                                    <Col xs={12} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>Monto Total</Form.Label>
                                                            <NumberFormat
                                                                thousandSeparator={true}
                                                                type="text"
                                                                className="form-control"
                                                                name="montoTotalAvalSolicitado"
                                                                value={this.state.montoTotalAvalSolicitado}
                                                                onChange={this.encargarseCambio}
                                                                disabled
                                                                min="0"
                                                                max="999999999999"
                                                                placeholder="0"
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>% Total</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                min="0"
                                                                max="999999999999"
                                                                className="form-control"
                                                                placeholder="0"
                                                                name="porcentajeTotalAvalSolicitado"
                                                                value={this.state.porcentajeTotalAvalSolicitado}
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Container fluid="md" style={{ padding: "20px 0 10px 0" }}>
                                        <Row>
                                            <Col xs={12} md={4}></Col>
                                            <Col xs={12} md={8}>
                                                <Row>
                                                    <Col xs={12} md={4}>
                                                        <p>Totales:</p>
                                                    </Col>
                                                    <Col xs={12} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>Monto Total</Form.Label>
                                                            <NumberFormat
                                                                thousandSeparator={true}
                                                                type="text"
                                                                className="form-control"
                                                                name="montoCredito"
                                                                value={this.state.montoCredito}
                                                                onChange={this.encargarseCambio}
                                                                disabled
                                                                min="0"
                                                                max="999999999999"
                                                                placeholder="0"
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>% Total</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                min="0"
                                                                max="999999999999"
                                                                className="form-control"
                                                                placeholder="0"
                                                                value={100.0}
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Container fluid="md" style={{ padding: "20px 0 10px 0" }}>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <Form.Row>
                                                    <Col xs={12} md={12}>
                                                        <div className="table-responsive">
                                                            <table
                                                                className="table table-striped table-bordered"
                                                                style={{ width: "100%" }}
                                                            >
                                                                <thead>
                                                                    <tr>
                                                                        <th colSpan="3">Cuadro Resumen</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ width: "50%" }}>
                                                                            Monto Total del Crédito
                                                                        </td>
                                                                        <td>{this.state.montoCredito.toString()
                                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                                        <td>100%</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ width: "50%" }}>
                                                                            Total del monto de responsabilidades de
                                                                            las garantías aportadas por el cliente
                                                                        </td>
                                                                        <td>
                                                                            {this.state.montoTotalGarantiasAportadasCliente
                                                                                .toString()
                                                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                this.state
                                                                                    .porcentajeTotalGarantiasAportadasSolicitante
                                                                            }{" "}
                                                                            %
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ width: "50%" }}>
                                                                            Total del monto del AVAL solicitado(En
                                                                            caso del avales superiores al 50% del
                                                                            crédito. FODEMIPYME valorará y aprobará el
                                                                            crédito)
                                                                        </td>
                                                                        <td>
                                                                            {this.state.montoTotalAvalSolicitado
                                                                                .toString()
                                                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                        </td>
                                                                        <td>
                                                                            {this.state.porcentajeTotalAvalSolicitado}{" "}
                                                                            %
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ width: "50%" }}>
                                                                            Diferencias
                                                                        </td>
                                                                        <td>{this.state.diferenciasMonto.toString()
                                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                                        <td>{this.state.diferenciaPorcentaje.toString()
                                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Col>
                                                </Form.Row>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Container fluid="md" style={{ padding: "20px 0 10px 0" }}>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <Form.Row>
                                                    <Col xs={12} md={12}>
                                                        <div className="table-responsive">
                                                            <table
                                                                className="table table-striped table-bordered"
                                                                style={{ width: "100%" }}
                                                            >
                                                                <thead>
                                                                    <tr>
                                                                        <td colSpan="3">Condiciones del Crédito</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Tipo de crédito</td>
                                                                        <td>
                                                                            <Form.Control
                                                                                as="select"
                                                                                onChange={this.cambioTipoCredito}
                                                                                value={this.state.tipoCreditoSeleccionado.id}
                                                                                disabled={this.state.indButton || this.state.tipoSolicitudSeleccionado.id != REACT_APP_NATURALEZA_AVAL}
                                                                            >
                                                                                {this.state.tipoCreditoLista.map(
                                                                                    function (data, key) {
                                                                                        return (
                                                                                            <option key={key} value={data.id}>
                                                                                                {data.valor}
                                                                                            </option>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </Form.Control>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Tasa de Intéres anual</td>
                                                                        <td style={{ width: "30%" }}>
                                                                            {" "}
                                                                            <Form.Group>
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    min="0"
                                                                                    max="100"
                                                                                    step="any"
                                                                                    className="form-control"
                                                                                    placeholder="0"
                                                                                    name="tasaInteresAnual"
                                                                                    onChange={this.encargarseCambio}
                                                                                    value={this.state.tasaInteresAnual}
                                                                                    disabled={this.state.indButton}
                                                                                />
                                                                            </Form.Group>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Plazo del Crédito en meses</td>
                                                                        <td style={{ width: "30%" }}>
                                                                            <Form.Group>
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    min="0"
                                                                                    className="form-control"
                                                                                    placeholder="0"
                                                                                    name="plazoCreditoMeses"
                                                                                    onChange={this.encargarseCambio}
                                                                                    value={this.state.plazoCreditoMeses}
                                                                                    disabled={this.state.indButton}
                                                                                    onKeyPress={(event) => {
                                                                                        if (!/[0-9]/.test(event.key)) {
                                                                                            event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </Form.Group>
                                                                        </td>
                                                                    </tr>
                                                                    {this.state.tienePlazoBallon ? (
                                                                        <tr>
                                                                            <td>Plazo Ballon en meses</td>
                                                                            <td style={{ width: "30%" }}>
                                                                                {this.state.tienePlazoBallon ? (
                                                                                    <Form.Group>
                                                                                        <Form.Control
                                                                                            type="number"
                                                                                            min="0"
                                                                                            className="form-control"
                                                                                            placeholder="0"
                                                                                            name="plazoBallonMeses"
                                                                                            onChange={this.encargarseCambio}
                                                                                            value={this.state.plazoBallonMeses}
                                                                                            disabled={this.state.indButton}
                                                                                            onKeyPress={(event) => {
                                                                                                if (!/[0-9]/.test(event.key)) {
                                                                                                    event.preventDefault();
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </Form.Group>
                                                                                ) : (
                                                                                    <Form.Group></Form.Group>
                                                                                )}
                                                                            </td>
                                                                        </tr>

                                                                    ) : (
                                                                        <tr></tr>
                                                                    )}
                                                                    <tr>
                                                                        <td>
                                                                            Cuota financiera mensual del nuevo crédito
                                                                        </td>
                                                                        <td style={{ width: "30%" }}>
                                                                            <Form.Group>
                                                                                <NumberFormat
                                                                                    thousandSeparator={true}
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="cuotaMensualNuevoCredito"
                                                                                    value={
                                                                                        this.state.cuotaMensualNuevoCredito
                                                                                    }
                                                                                    onChange={this.encargarseCambio}
                                                                                    disabled
                                                                                    min="0"
                                                                                    max="999999999999"
                                                                                    placeholder="0"
                                                                                />
                                                                            </Form.Group>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            Cuota financiera anual del nuevo crédito
                                                                        </td>
                                                                        <td style={{ width: "30%" }}>
                                                                            <Form.Group>
                                                                                <NumberFormat
                                                                                    thousandSeparator={true}
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="cuotaAnualNuevoCredito"
                                                                                    value={
                                                                                        this.state.cuotaAnualNuevoCredito
                                                                                    }
                                                                                    disabled
                                                                                    min="0"
                                                                                    max="999999999999"
                                                                                    placeholder="0"
                                                                                />
                                                                            </Form.Group>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Col>
                                                </Form.Row>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Container>
                            </Tab>
                            <Tab
                                eventKey="documentacionAdjunta"
                                title="Paso 3. Documentación Adjunta Solicitud Aval"
                                disabled={this.state.indIrPaso3}
                            >
                                <Container fluid="md" style={{ padding: "20px 0 10px 0" }}>
                                    <Row style={{ padding: "0 0 20px 0" }}>
                                        <Col xs={12} md={6}>
                                            {" "}
                                            <Form.Label>
                                                Copia del documento de identificación del cliente físico
                                            </Form.Label>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group>
                                                <Form.File
                                                    name="nombreArchivoFotoCedulaPersonaFisica"
                                                    onChange={this.encargarseCambioArchivos}
                                                    accept="application/pdf"
                                                    disabled={this.state.indButton}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row style={{ padding: "0 0 20px 0" }}>
                                        <Col xs={12} md={6}>
                                            {" "}
                                            <Form.Label>
                                                Para personas jurídicas, copia u original de la
                                                personería jurídica registral o notarial, con no más de
                                                un mes de emisión
                                            </Form.Label>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group>
                                                <Form.File
                                                    name="nombreArchivoFotoCedulaPersonaJuridica"
                                                    onChange={this.encargarseCambioArchivos}
                                                    accept="application/pdf"
                                                    disabled={this.state.indButton}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row style={{ padding: "0 0 20px 0" }}>
                                        <Col xs={12} md={6}>
                                            {" "}
                                            <Form.Label>
                                                Para personas jurídicas, copia del documento de
                                                identificación del apoderado o apoderados que la
                                                representan
                                            </Form.Label>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group>
                                                <Form.File
                                                    name="nombreArchivoFotoCedulaRepresentantePersonaJuridica"
                                                    onChange={this.encargarseCambioArchivos}
                                                    accept="application/pdf"
                                                    disabled={this.state.indButton}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row hidden={idFondo != REACT_APP_OPERADORA_FODEMIPYME}>
                                        <Col xs={12} md={6}>
                                            <Row style={{ padding: "0 0 20px 0" }}>
                                                <Col xs={12} md={6}>
                                                    {" "}
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Requisitos de formalidad (1 de 3){" "}
                                                        </Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <Form.Label>
                                                        {" "}
                                                        <strong>Certificaciones de la CCSS</strong>
                                                    </Form.Label>
                                                    <Form.Group>
                                                        <Form.File
                                                            name="nombreArchivoCertificacionCCSS"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled={this.state.indButton}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Row style={{ padding: "0 0 20px 0" }}>
                                                <Col xs={12} md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Estado</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={this.cambioCertificacionCCSS}
                                                            disabled={this.state.indButton}
                                                        >
                                                            {this.state.estadoCertificacionCCSSLista.map(
                                                                function (data, key) {
                                                                    return (
                                                                        <option key={key} value={data.id}>
                                                                            {data.valor}
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <Form.Group>
                                                        <Form.Label>Fecha emisión o consulta</Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            name="fechaEmisionCertificacionCCSS"
                                                            className="form-control"
                                                            onChange={this.encargarseCambio}
                                                            value={this.state.fechaEmisionCertificacionCCSS}
                                                            required
                                                            disabled={this.state.indButton}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr hidden={idFondo != REACT_APP_OPERADORA_FODEMIPYME} />
                                    <Row hidden={idFondo != REACT_APP_OPERADORA_FODEMIPYME}>
                                        <Col xs={12} md={6}>
                                            <Row style={{ padding: "0 0 20px 0" }}>
                                                <Col xs={12} md={6}>
                                                    {" "}
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Requisitos de formalidad (2 de 3){" "}
                                                        </Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <Form.Label>
                                                        {" "}
                                                        <strong>Tributación directa</strong>
                                                    </Form.Label>
                                                    <Form.Group>
                                                        <Form.File
                                                            name="nombreArchivoTributacionDirecta"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled={this.state.indButton}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Row style={{ padding: "0 0 20px 0" }}>
                                                <Col xs={12} md={12}>
                                                    <Form.Group>
                                                        <Form.Label></Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={this.cambioEstadoTributacion}
                                                            disabled={this.state.indButton}
                                                        >
                                                            {this.state.estadoTributacionDirectaLista.map(
                                                                function (data, key) {
                                                                    return (
                                                                        <option key={key} value={data.id}>
                                                                            {data.valor}
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr hidden={idFondo != REACT_APP_OPERADORA_FODEMIPYME} />
                                    <Row hidden={idFondo != REACT_APP_OPERADORA_FODEMIPYME}>
                                        <Col xs={12} md={6}>
                                            <Row style={{ padding: "0 0 20px 0" }}>
                                                <Col xs={12} md={6}>
                                                    {" "}
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Requisitos de formalidad (3 de 3){" "}
                                                        </Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <Form.Label>
                                                        {" "}
                                                        <strong>Riesgos de trabajo</strong>
                                                    </Form.Label>
                                                    <Form.Group>
                                                        <Form.File
                                                            name="nombreArchivoRiesgosTrabajo"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled={this.state.indButton}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Row style={{ padding: "0 0 20px 0" }}>
                                                <Col xs={12} md={12}>
                                                    <Form.Group>
                                                        <Form.Label></Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={this.cambioRiesgoTrabajo}
                                                            disabled={this.state.indButton}
                                                        >
                                                            {this.state.riesgosTrabajoLista.map(function (
                                                                data,
                                                                key
                                                            ) {
                                                                return (
                                                                    <option key={key} value={data.id}>
                                                                        {data.valor}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr hidden={idFondo != REACT_APP_OPERADORA_FODEMIPYME} />
                                    <Row hidden={idFondo != REACT_APP_OPERADORA_FODEMIPYME}>
                                        <Col xs={12} md={8}>
                                            <Row style={{ padding: "0 0 20px 0" }}>
                                                <Col xs={12} md={6}>
                                                    {" "}
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Certificación de condición PYME, emitida por el
                                                            MEIC o MAG según corresponda
                                                        </Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <Form.Label> </Form.Label>
                                                    <Form.Group>
                                                        <Form.File
                                                            name="nombreArchivoCertificacionPymePympa"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled={this.state.indButton}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Row style={{ padding: "0 0 20px 0" }}>
                                                <Col xs={12} md={12}>
                                                    <Form.Group>
                                                        <Form.Label>Fecha vencimiento</Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            name="fechaVencimientoCertificacionPymePympa"
                                                            className="form-control"
                                                            onChange={this.encargarseCambio}
                                                            value={
                                                                this.state
                                                                    .fechaVencimientoCertificacionPymePympa
                                                            }
                                                            required
                                                            disabled={this.state.indButton}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr hidden={idFondo != REACT_APP_OPERADORA_FODEMIPYME} />
                                    <Row hidden={idFondo != REACT_APP_OPERADORA_FODEMIPYME}>
                                        <Col xs={12} md={8}>
                                            <Row style={{ padding: "0 0 20px 0" }}>
                                                <Col xs={12} md={6}>
                                                    {" "}
                                                    <Form.Group>
                                                        <Form.Label>
                                                            En caso de certificación condición PYMPA del MAG,
                                                            adjuntar el cálculo del tamaño de la empresa
                                                        </Form.Label>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Row style={{ padding: "0 0 20px 0" }}>
                                                <Col xs={12} md={12}>
                                                    <Form.Group>
                                                        <Form.Label>Tamaño empresa</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={this.cambioTamanoPymePymPa}
                                                            disabled={this.state.indButton}
                                                        >
                                                            {this.state.tamanoPymePympaLista.map(function (
                                                                data,
                                                                key
                                                            ) {
                                                                return (
                                                                    <option key={key} value={data.id}>
                                                                        {data.valor}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr hidden={idFondo != REACT_APP_OPERADORA_FODEMIPYME} />
                                    <Row style={{ padding: "0 0 20px 0" }}>
                                        <Col xs={12} md={4}>
                                            {" "}
                                            <Form.Label>Consulta abierta del CIC de SUGEF</Form.Label>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Form.Group>
                                                <Form.File
                                                    name="nombreArchivoCICSugef"
                                                    onChange={this.encargarseCambioArchivos}
                                                    accept="application/pdf"
                                                    disabled={this.state.indButton}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Form.Group>
                                                <Form.Control
                                                    as="select"
                                                    onChange={this.cambionConsultaCICSUGEF}
                                                    disabled={this.state.indButton}
                                                >
                                                    <option></option>
                                                    <option id="SI">SI</option>
                                                    <option id="NO">No</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row style={{ padding: "0 0 20px 0" }}>
                                        <Col xs={12} md={6}>
                                            {" "}
                                            <Form.Label>
                                                Copia del documento o acta de aprobación del crédito
                                            </Form.Label>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group>
                                                <Form.File
                                                    name="nombreArchivoActaAprobacionCredito"
                                                    onChange={this.encargarseCambioArchivos}
                                                    accept="application/pdf"
                                                    disabled={this.state.indButton}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row style={{ padding: "0 0 20px 0" }}>
                                        <Col xs={12} md={6}>
                                            {" "}
                                            <Form.Label>
                                                Estudio registral de bienes muebles, actualizado a la
                                                fecha de aprobación del crédito
                                            </Form.Label>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group>
                                                <Form.File
                                                    name="nombreArchivoEstudioRegistralBienesMuebles"
                                                    onChange={this.encargarseCambioArchivos}
                                                    accept="application/pdf"
                                                    disabled={this.state.indButton}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row style={{ padding: "0 0 20px 0" }}>
                                        <Col xs={12} md={6}>
                                            {" "}
                                            <Form.Label>
                                                Estudio registral de bienes inmuebles, actualizado a la
                                                fecha de aprobación del crédito
                                            </Form.Label>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group>
                                                <Form.File
                                                    name="nombreArchivoExcluirGarantiaBienMuebleInmueble"
                                                    onChange={this.encargarseCambioArchivos}
                                                    accept="application/pdf"
                                                    disabled={this.state.indButton}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row style={{ padding: "0 0 20px 0" }}>
                                        <Col xs={12} md={6}>
                                            {" "}
                                            <Form.Label>Se adjunta factura proforma</Form.Label>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group>
                                                <Form.File
                                                    name="nombreArchivoFacturaProforma"
                                                    onChange={this.encargarseCambioArchivos}
                                                    accept="application/pdf"
                                                    disabled={this.state.indButton}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row style={{ padding: "0 0 20px 0" }}>
                                        <Col xs={12} md={6}>
                                            {" "}
                                            <Form.Label>Se adjunta informe de avalúo</Form.Label>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Form.Group>
                                                <Form.File
                                                    name="nombreArchivoInformeAvaluo"
                                                    onChange={this.encargarseCambioArchivos}
                                                    accept="application/pdf"
                                                    disabled={this.state.indButton}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row style={{ padding: "0 0 20px 0" }}>
                                        <Col xs={12} md={12}>
                                            <Form.Group>
                                                <Form.Label>Observaciones</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    name="observaciones"
                                                    minLength="5"
                                                    maxLength="1000"
                                                    onChange={this.encargarseCambio}
                                                    value={this.state.observaciones}
                                                    rows={3}
                                                    disabled={this.state.indButton}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Container>
                            </Tab>
                        </Tabs>
                    </div>
                    <button
                        className="btn-flotante border-radius"
                        onClick={this.crearAval}
                        disabled={this.state.indButton}
                    >
                        <div id="guardando" className="spinner-border text-light" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <i
                            id="floppy"
                            className="fa fa-floppy-o"
                            aria-hidden="true"
                            style={{ color: "white" }}
                        ></i>
                    </button>
                </div>
            </div>
        );
    }
}

export default CrearAvalComponent;
