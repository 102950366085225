import React from "react";
import EditarClienteComponent from "../../Components/Cliente/EditarCliente";

const CrearClienteView = () => {
  return (
    <div>
      <EditarClienteComponent />
    </div>
  );
};

export default CrearClienteView;
