import React from 'react'
import CrearProgramaIndividualComponent from '../../Components/ProgramaIndividual/CrearProgramaIndividual'

const CrearProgramaIndividualView = () => {
  return (
      <div>
        <CrearProgramaIndividualComponent/>
      </div>
  )
}

export default CrearProgramaIndividualView   