import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Login from "./Views/Login/Login";
import AdminView from "./Views/AdminView/AdminView";
import AvalesView from "./Views/AvalesView/AvalesView";
import HonramientosView from "./Views/HonramientosView/HonramientosView";
import CrearHonramientoView from "./Views/HonramientosView/CrearHonramientoView";
import EditarHonramientoView from "./Views/HonramientosView/EditarHonramientoView";
import UsuariosView from "./Views/UsuariosView/UsuariosView";
import ClientesView from "./Views/ClientesView/ClientesView";
import ProgramaIndividualView from "./Views/ProgramaIndividualView/ProgramaIndividualView";
import ProgramaCarteraView from "./Views/ProgramaCarteraView/ProgramaCarteraView";
import ProgramaCarteraPEView from "./Views/ProgramaCarteraPEView/ProgramaCarteraPEView";
import NotFound from "./Views/NotFound";
import AppRoute from "./Components/Layouts/StandardLayout/AppRoute";
import AdminLayout from "./Components/Layouts/StandardLayout/AdminLayout";
import MainLayout from "./Components/Layouts/StandardLayout/MainLayout";
import CrearUsuarioView from "./Views/UsuariosView/CrearUsuarioView";
import EditarUsuarioView from "./Views/UsuariosView/EditarUsuarioView";
import ReportesView from "./Views/ReportesView/ReportesView";
import CrearClienteView from "./Views/ClientesView/CrearClienteView";
import EditarClienteView from "./Views/ClientesView/EditarClienteView";
import CrearProgramaIndividualView from "./Views/ProgramaIndividualView/CrearProgramaIndividualView";
import EditarProgramaIndividualView from "./Views/ProgramaIndividualView/EditarProgramaIndividualView";
import CrearProgramaCarteraView from "./Views/ProgramaCarteraView/CrearProgramaCarteraView";
import EditarProgramaCarteraView from "./Views/ProgramaCarteraView/EditarProgramaCarteraView";
import CrearProgramaCarteraPEView from "./Views/ProgramaCarteraPEView/CrearProgramaCarteraPEView";
import EditarProgramaCarteraPEView from "./Views/ProgramaCarteraPEView/EditarProgramaCarteraView";
import CrearAvalPruebaView from "./Views/AvalesView/CrearAvalPruebaView";
import EditarAvalView from "./Views/AvalesView/EditarAvalView";
import CrearAvalView from "./Views/AvalesView/CrearAvalView";
import MantenimientoView from "./Views/MantenimientoView/MantenimientoView";
import MantenimientoProgramasView from "./Views/MantenimientoProgramasView/MantenimientoProgramasView";
import LineaCreditoView from "./Views/LineaCreditoView/LineaCreditoView";
import EditarLineaCreditoView from "./Views/LineaCreditoView/EditarLineaCreditoView";
import MantenimientoReportesView from "./Views/MantenimientoReportesView/MantenimientoReportesView";
import ReporteGeneralAvalesView from "./Views/ReporteGeneralAvalesView/ReporteGeneralAvalesView";
import ReporteImpactoCualitativoView from "./Views/ReporteImpactoCualitativoView/ReporteImpactoCualitativoView";
import ReporteGestionFODEMIPYMEView from "./Views/ReporteGestionFODEMIPYMEView/ReporteGestionFODEMIPYMEView";
import ReporteHonramientosView from "./Views/ReporteHonramientosView/ReporteHonramientosView";
import ReporteProvisionesView from "./Views/ReporteProvisionesView/ReporteProvisionesView";
import ReporteLineaCreditoView from "./Views/ReporteLineaCreditoView/ReporteLineaCreditoView";
import SubCreditoView from "./Views/LineaCreditoView/SubCreditoView";
import MantenimientoParametrosView from "./Views/MantenimientoParametrosView/MantenimientoParametrosView";
import EditarParametroView from "./Views/ParametroView/EditarParametroView";
import VerBitacora from "./Components/Bitacora/VerBitacora";
import UsuariosRiesgosos from "./Components/UsuariosRiesgosos/UsuariosRiesgosos";
import MantenimientoAuditoriaView from "./Views/MantenimientoAuditoriaView/MantenimientoAuditoriaView";
import HistoricoIngresosPorUsuario from "./Components/HistoricoPorUsuario/HistoricoIngresosPorUsuario";
import HistoricoEventosPorUsuario from "./Components/HistoricoPorUsuario/HistoricoEventosPorUsuario";
import VerBitacoraResgistroConsulta from "./Components/Bitacora/VerBitacoraResgistroConsulta";
import HistoricoDeteccionesRiesgosasPorUsuario from "./Components/HistoricoPorUsuario/HistoricoDeteccionesRiesgosasPorUsuario";
import { ObtenerEstadoCliente } from "./Services/GlobalService";
const tokenContent = localStorage.getItem("isLoggedIn");

export default class App extends Component {
    componentDidMount() {
        if (tokenContent) this.setState({ isLoggedIn: true });
    }
    constructor(props) {
        super(props);
        this.state = { isLoggedIn: false };
    }
    render() {
        const isLoggedIn = this.state.isLoggedIn;
        let mostrarDashboard;
        let mostrarUsuarios;
        let mostrarUsuarioCrear;
        let mostrarUsuarioEditar;
        let mostrarClientes;
        let mostrarClienteCrear;
        let mostrarClienteEditar;
        let mostrarProgramaIndividual;
        let mostrarProgramaIndividualCrear;
        let mostrarProgramaIndividualEdiar;
        let mostrarProgramaCartera;
        let mostrarProgramaCarteraCrear;
        let mostrarProgramaCarteraEditar;
        let mostrarProgramaCarteraPE;
        let mostrarProgramaCarteraPECrear;
        let mostrarProgramaCarteraPEEditar;
        let mostrarAvales;
        let mostrarAvalesPruebaCrear;
        let mostrarAvalesCrear;
        let mostrarAvalesEdiar;
        let mostrarHonramientos;
        let mostrarHonramientosCrear;
        let mostrarHonramientosEditar;
        let mostrarReportes;
        let mostrarMantenimiento;
        let mostrarMantenimientoProgramas;
        let mostrarLineaCredito;
        let mostrarLineaCreditoEditar;
        let mostrarMantenimientoReportes;
        let mostrarReporteGeneralAvales;
        let mostrarReporteImpactoCualitativo;
        let mostrarReporteGestionFODEMIPYME;
        let mostrarReporteHonramientos;
        let mostrarReporteProvisiones;
        let mostrarReporteLineaCredito;
        let mostrarSubCredito;
        let mostrarMantenimientoParametros;
        let mostrarParametroEditar;
        let mostrarBitacora;
        let mostrarUsuariosRiesgosos;
        let mostrarMantenimientoAuditoria;
        let mostrarHistoricoIngresosPorUsuario;
        let mostrarHistoricoEventosPorUsuario;
        let mostrarBitacoraRegistroConsulta;
        let mostrarHistoricoDeteccionesRiesgosasPorUsuario; 
        if (isLoggedIn) {
            ObtenerEstadoCliente();
            mostrarReporteLineaCredito = (
                <AppRoute
                    path="/reporte-linea-credito"
                    component={ReporteLineaCreditoView}
                    layout={AdminLayout}
                />
            );
            mostrarReporteProvisiones = (
                <AppRoute
                    path="/reporte-provisiones"
                    component={ReporteProvisionesView}
                    layout={AdminLayout}
                />
            );
            mostrarReporteHonramientos = (
                <AppRoute
                    path="/reporte-honramiento"
                    component={ReporteHonramientosView}
                    layout={AdminLayout}
                />
            );
            mostrarReporteGeneralAvales = (
                <AppRoute
                    path="/reporte-general-avales"
                    component={ReporteGeneralAvalesView}
                    layout={AdminLayout}
                />
            );
            mostrarReporteImpactoCualitativo = (
                <AppRoute
                    path="/reporte-impacto-cualitativo"
                    component={ReporteImpactoCualitativoView}
                    layout={AdminLayout}
                />
            );
            mostrarReporteGestionFODEMIPYME = (
                <AppRoute
                    path="/reporte-gestion-fodemipyme"
                    component={ReporteGestionFODEMIPYMEView}
                    layout={AdminLayout}
                />
            );
            mostrarMantenimientoReportes = (
                <AppRoute
                    path="/mantenimiento-reportes"
                    component={MantenimientoReportesView}
                    layout={AdminLayout}
                />
            );
            mostrarSubCredito = (
                <AppRoute
                    path="/sub-creditos/:lineaCreditoId"
                    component={SubCreditoView}
                    layout={AdminLayout}
                />
            );
            mostrarLineaCredito = (
                <AppRoute
                    path="/lineas-creditos"
                    component={LineaCreditoView}
                    layout={AdminLayout}
                />
            );
            mostrarLineaCreditoEditar = (
                <AppRoute
                    path="/linea-credito/:lineaCreditoId"
                    component={EditarLineaCreditoView}
                    layout={AdminLayout}
                />
            );
            mostrarMantenimientoProgramas = (
                <AppRoute
                    path="/mantenimiento-programas"
                    component={MantenimientoProgramasView}
                    layout={AdminLayout}
                />
            );
            mostrarMantenimiento = (
                <AppRoute
                    path="/mantenimiento"
                    component={MantenimientoView}
                    layout={AdminLayout}
                />
            );
            mostrarDashboard = (
                <AppRoute path="/admin" component={AdminView} layout={AdminLayout} />
            );
            mostrarAvalesCrear = (
                <AppRoute
                    path="/aval/crear"
                    component={CrearAvalView}
                    layout={AdminLayout}
                />
            );
            mostrarAvales = (
                <AppRoute path="/avales" component={AvalesView} layout={AdminLayout} />
            );
            mostrarAvalesEdiar = (
                <AppRoute
                    path="/aval/:avalId"
                    component={EditarAvalView}
                    layout={AdminLayout}
                />
            );
            mostrarAvalesPruebaCrear = (
                <AppRoute
                    path="/aval/crear-prueba"
                    component={CrearAvalPruebaView}
                    layout={AdminLayout}
                />
            );
            mostrarHonramientos = (
                <AppRoute
                    path="/honramientos"
                    component={HonramientosView}
                    layout={AdminLayout}
                />
            );
            mostrarHonramientosEditar = (
                <AppRoute
                    path="/honramiento/:honramientoId"
                    component={EditarHonramientoView}
                    layout={AdminLayout}
                />
            );
            mostrarClientes = (
                <AppRoute
                    path="/clientes"
                    component={ClientesView}
                    layout={AdminLayout}
                />
            );
            mostrarClienteCrear = (
                <AppRoute
                    path="/cliente/crear"
                    component={CrearClienteView}
                    layout={AdminLayout}
                />
            );
            mostrarClienteEditar = (
                <AppRoute
                    path="/cliente/:clienteId"
                    component={EditarClienteView}
                    layout={AdminLayout}
                />
            );
            mostrarUsuarios = (
                <AppRoute
                    path="/usuarios"
                    component={UsuariosView}
                    layout={AdminLayout}
                />
            );
            mostrarUsuarioCrear = (
                <AppRoute
                    path="/usuario/crear"
                    component={CrearUsuarioView}
                    layout={AdminLayout}
                />
            );
            mostrarUsuarioEditar = (
                <AppRoute
                    path="/usuario/:usuarioId"
                    component={EditarUsuarioView}
                    layout={AdminLayout}
                />
            );
            mostrarProgramaIndividual = (
                <AppRoute
                    path="/programas-individuales"
                    component={ProgramaIndividualView}
                    layout={AdminLayout}
                />
            );
            mostrarProgramaIndividualCrear = (
                <AppRoute
                    path="/programa-individual/crear"
                    component={CrearProgramaIndividualView}
                    layout={AdminLayout}
                />
            );
            mostrarProgramaIndividualEdiar = (
                <AppRoute
                    path="/programa-individual/:programaIndividualId"
                    component={EditarProgramaIndividualView}
                    layout={AdminLayout}
                />
            );
            mostrarProgramaCartera = (
                <AppRoute
                    path="/programas-carteras"
                    component={ProgramaCarteraView}
                    layout={AdminLayout}
                />
            );
            mostrarProgramaCarteraCrear = (
                <AppRoute
                    path="/programa-cartera/crear"
                    component={CrearProgramaCarteraView}
                    layout={AdminLayout}
                />
            );
            mostrarProgramaCarteraEditar = (
                <AppRoute
                    path="/programa-cartera/:programaCarteraId"
                    component={EditarProgramaCarteraView}
                    layout={AdminLayout}
                />
            );
            mostrarProgramaCarteraPE = (
                <AppRoute
                    path="/programas-perdidas"
                    component={ProgramaCarteraPEView}
                    layout={AdminLayout}
                />
            );
            mostrarProgramaCarteraPECrear = (
                <AppRoute
                    path="/programa-perdida/crear"
                    component={CrearProgramaCarteraPEView}
                    layout={AdminLayout}
                />
            );
            mostrarProgramaCarteraPEEditar = (
                <AppRoute
                    path="/programa-perdida/:programaCarteraPerdidaId"
                    component={EditarProgramaCarteraPEView}
                    layout={AdminLayout}
                />
            );
            mostrarReportes = (
                <AppRoute
                    path="/reportes"
                    component={ReportesView}
                    layout={AdminLayout}
                />
            );
            mostrarMantenimientoParametros = (
                <AppRoute
                    path="/mantenimiento-parametros"
                    component={MantenimientoParametrosView}
                    layout={AdminLayout}
                />
            );
            mostrarParametroEditar = (
                <AppRoute
                    path="/parametro/editar"
                    component={EditarParametroView}
                    layout={AdminLayout}
                />
            );
            mostrarMantenimientoAuditoria = (
                <AppRoute
                    path="/auditoria"
                    component={MantenimientoAuditoriaView}
                    layout={AdminLayout}
                />
            );
            mostrarBitacora = (
                <AppRoute
                    path="/bitacora"
                    component={VerBitacora}
                    layout={AdminLayout}>
                </AppRoute>
            );
            mostrarUsuariosRiesgosos = (
                <AppRoute
                    path="/usuarios-riesgosos"
                    component={UsuariosRiesgosos}
                    layout={AdminLayout}>
                </AppRoute>
            );
            mostrarHistoricoIngresosPorUsuario = (
                <AppRoute
                    path="/historico-ingresos-usuario"
                    component={HistoricoIngresosPorUsuario}
                    layout={AdminLayout}>
                </AppRoute>
            );
            mostrarHistoricoEventosPorUsuario = (
                <AppRoute
                    path="/historico-eventos-usuario"
                    component={HistoricoEventosPorUsuario}
                    layout={AdminLayout}>
                </AppRoute>
            );

            mostrarBitacoraRegistroConsulta = (
                <AppRoute
                    path="/bitacora-registro-consulta"
                    component={VerBitacoraResgistroConsulta}
                    layout={AdminLayout}>
                </AppRoute>
            );

            mostrarHistoricoDeteccionesRiesgosasPorUsuario = (
                <AppRoute
                    path="/historico-detecciones-riesgosas-usuario"
                    component={HistoricoDeteccionesRiesgosasPorUsuario}
                    layout={AdminLayout}>
                </AppRoute>
            );
        }
        return (
            <Router>
                <Switch>
                    <AppRoute exact path="/" component={Login} layout={MainLayout} />
                    {mostrarMantenimientoProgramas}
                    {mostrarMantenimiento}
                    {mostrarDashboard}
                    {mostrarAvales}
                    {mostrarAvalesPruebaCrear}
                    {mostrarAvalesCrear}
                    {mostrarAvalesEdiar}
                    {mostrarHonramientos}
                    {mostrarHonramientosEditar}
                    {mostrarClientes}
                    {mostrarClienteCrear}
                    {mostrarClienteEditar}
                    {mostrarUsuarios}
                    {mostrarUsuarioCrear}
                    {mostrarUsuarioEditar}
                    {mostrarProgramaIndividual}
                    {mostrarProgramaIndividualCrear}
                    {mostrarProgramaIndividualEdiar}
                    {mostrarProgramaCartera}
                    {mostrarProgramaCarteraCrear}
                    {mostrarProgramaCarteraEditar}
                    {mostrarProgramaCarteraPE}
                    {mostrarProgramaCarteraPECrear}
                    {mostrarProgramaCarteraPEEditar}
                    {mostrarReportes}
                    {mostrarLineaCredito}
                    {mostrarLineaCreditoEditar}
                    {mostrarSubCredito}
                    {mostrarMantenimientoReportes}
                    {mostrarReporteGeneralAvales}
                    {mostrarReporteImpactoCualitativo}
                    {mostrarReporteGestionFODEMIPYME}
                    {mostrarReporteHonramientos}
                    {mostrarReporteProvisiones}
                    {mostrarReporteLineaCredito}
                    {mostrarMantenimientoParametros}
                    {mostrarParametroEditar}
                    {mostrarMantenimientoAuditoria}
                    {mostrarBitacora}
                    {mostrarUsuariosRiesgosos}
                    {mostrarHistoricoIngresosPorUsuario}
                    {mostrarHistoricoEventosPorUsuario}
                    {mostrarBitacoraRegistroConsulta}
                    {mostrarHistoricoDeteccionesRiesgosasPorUsuario}
                    <AppRoute path="*" component={NotFound} layout={MainLayout} />
                </Switch>
            </Router>
        );
    }
}