import React from "react";
import ReporteGestionFODEMIPYMEComponent from "../../Components/ReporteGestionFODEMIPYME/ReporteGestionFODEMIPYME";

const ReporteGestionFODEMIPYMEView = () => {
  return (
    <div>
      <ReporteGestionFODEMIPYMEComponent />
    </div>
  );
};

export default ReporteGestionFODEMIPYMEView;
