export const REACT_APP_API_URL = "https://fd-saag-dev-api.azurefd.net";
export const REACT_APP_URL_DESCARGAR_ARCHIVO = "https://fd-saag-dev-api.azurefd.net/DescargarArchivo?code=34GeHbO5S8uaqa69tys7E0LgnAYeHcDfS/j5wB47tNjOJd/h3mjofg==&nombreArchivo=";
export const REACT_APP_CODE = "34GeHbO5S8uaqa69tys7E0LgnAYeHcDfS/j5wB47tNjOJd/h3mjofg==";
export const REACT_APP_LOGIN_URL = "https://bpdcdesb2c.b2clogin.com/bpdcdesb2c.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SAAG_DEV_Login&client_id=6417c9cf-17f9-4e6c-9886-a7ee4218caf0&scope=openid&response_type=id_token&prompt=login";
export const REACT_APP_OBTENER_LINEAS_CREDITO = "ListarLineasCreditoDisponibles";
export const REACT_APP_OBTENER_CLIENTES = "ObtenerClientes";
export const REACT_APP_OBTENER_ROLES_FODEMIPYME = "ObtenerRolesFondo";
export const REACT_APP_OBTENER_ROLES_OPERADORA = "ObtenerRolesOperadora";
export const REACT_APP_OBTENER_USUARIO_POR_NOMBRE_USUARIO = "ObtenerUsuarioPorMiNombreUsuario";
export const REACT_APP_LISTAR_RISKY_DETECTIONS = "ListarRiskyDetections";
export const REACT_APP_ACEPTAR_RIESGO_USUARIO_RIESGOSO = "AceptarRiesgoUsuarioRiesgoso";
export const REACT_APP_TOMAR_ACCION_USUARIO_RIESGOSO = "TomarAccionUsuarioRiesgoso";
export const REACT_APP_CALCULAR_MORA_LEGAL = "CALCULAR_MORA_LEGAL";
export const REACT_APP_OBTENER_PORCENTAJE_MORA_LEGAL = "PORCENTAJE_MORA_LEGAL";
export const REACT_APP_VIGENCIA_MAX_PROG_INDIVIDUAL = "VIGENCIA_MAX_PROG_INDIVIDUAL";
export const REACT_APP_VIGENCIA_MAX_PROG_CARTERA = "VIGENCIA_MAX_PROG_CARTERA";
export const REACT_APP_VIGENCIA_MAX_PROG_CARTERA_PERDIDA_ESPERADA = "VIGENCIA_MAX_PROG_CARTERA_PERDIDA_ESPERADA";
export const REACT_APP_TIEMPO_EXPIRACION_CLAVE = "TIEMPO_EXPIRACION_CLAVE";
export const REACT_APP_LARGO_CLAVE = "LARGO_CLAVE";
export const REACT_APP_CANTIDAD_MINIMA_CARACTERES = "CANTIDAD_MINIMA_CARACTERES";
export const REACT_APP_CANTIDAD_MAXIMA_CARACTERES = "CANTIDAD_MAXIMA_CARACTERES";
export const REACT_APP_CANTIDAD_CARACTERES_ESPECIALES = "CANTIDAD_CARACTERES_ESPECIALES";
export const REACT_APP_ASUNTO_CLAVE_TEMPORAL = "ASUNTO_CLAVE_TEMPORAL";
export const REACT_APP_TIEMPO_INACTIVACION_USUARIO = "TIEMPO_INACTIVACION_USUARIO";
export const REACT_APP_MOROSIDAD_DIA_HABIL = "MOROSIDAD_DIA_HABIL";
export const REACT_APP_FECHA_ACEPTAR_TERMINOS_CONDICIONES = "FECHA_ACEPTAR_TERMINOS_CONDICIONES";
export const REACT_APP_TERMINOS_CONDICIONES = "TERMINOS_CONDICIONES";
export const REACT_APP_LISTAR_INGRESOS_HISTORICOS_USUARIOS = "ListarIngresosHistoricosPorUsuario";
export const REACT_APP_LISTAR_EVENTOS_HISTORICOS_USUARIOS = "ListarEventosHistoricosPorUsuario";
export const REACT_APP_LISTAR_DETECCIONES_RIESGOSAS_USUARIOS = "ListarRiskyDetectionsHistoricoPorUsuario";
export const REACT_APP_URL_BITACORA = "/bitacora";
export const REACT_APP_URL_BITACORA_REGISTRO_CONSULTA = "/bitacora-registro-consulta";
export const REACT_APP_URL_USUARIOS_RIESGOSOS = "/usuarios-riesgosos";
export const REACT_APP_URL_HISTORICO_INGRESOS_USUARIOS = "/historico-ingresos-usuario";
export const REACT_APP_URL_HISTORICO_EVENTOS_USUARIOS = "/historico-eventos-usuario";
export const REACT_APP_URL_HISTORICO_DETECCIONES_RIESGOSAS_USUARIOS = "/historico-detecciones-riesgosas-usuario";
export const REACT_APP_PUEDE_CALCULAR_MORA_LEGAL = 1;
export const REACT_APP_CATALOGO_OBTENER_ESTADO = 1;
export const REACT_APP_CATALOGO_OBTENER_ESTADO_CLIENTE = 2;
export const REACT_APP_OBTENER_FRECUENCIAS = 3;
export const REACT_APP_CATALOGO_OBTENER_ESTADO_PROGRAMAS = 4;
export const REACT_APP_CATALOGO_OBTENER_TIPOS_PROGRAMAS = 5;
export const REACT_APP_CATALOGO_OBTENER_TIPOS_MONEDA = 6;
export const REACT_APP_CATALOGO_OBTENER_TIPOS_IDENTIFICACION = 7;
export const REACT_APP_CATALOGO_OBTENER_TIPOS_SECTO_ECONOMICO = 8;
export const REACT_APP_CATALOGO_OBTENER_TIPOS_NIVELES = 9;
export const REACT_APP_CATALOGO_OBTENER_TAMANO_EMPRESA = 10;
export const REACT_APP_CATALOGO_OBTENER_TIPOS_GARANTIAS = 11;
export const REACT_APP_CATALOGO_OBTENER_FUENTE_REFERENCIA_INFORMACION = 12;
export const REACT_APP_CATALOGO_OBTENER_ESTADO_CCSS = 13;
export const REACT_APP_CATALOGO_OBTENER_TRIBUTACION_DIRECTA = 14;
export const REACT_APP_CATALOGO_OBTENER_RIESGO_TRABAJO = 15;
export const REACT_APP_CATALOGO_OBTENER_TAMANO_EMPRESA_PYMPA_MAG = 16;
export const REACT_APP_CATALOGO_OBTENER_ESTADO_AVAL = 17;
export const REACT_APP_CATALOGO_OBTENER_TIPO_IDENTIFICACION = 18;
export const REACT_APP_CATALOGO_OBTENER_FNA_TIPO_BENEFICIO = 20;
export const REACT_APP_CATALOGO_OBTENER_FNA_MEJORAS_CONDICIONES_PAGO = 21;
export const REACT_APP_AVAL_ESTADO_SOLICITADO = 61;
export const REACT_APP_AVAL_ESTADO_DEBE_CONDICIONADO_FODEMIPYME = 62;
export const REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME = 63;
export const REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD = 64;
export const REACT_APP_AVAL_ESTADO_ACEPTADO = 65;
export const REACT_APP_AVAL_ESTADO_PROCESANDO_MENSUALMENTE = 66;
export const REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA = 67;
export const REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA = 68;
export const REACT_APP_AVAL_ESTADO_EN_ATRASO = 69;
export const REACT_APP_AVAL_ESTADO_FINALIZADO = 70;
export const REACT_APP_AVAL_ESTADO_IRRECUPERABLE = 71;
export const REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD = 76;
export const REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD = 77;
export const REACT_APP_AVAL_ESTADO_AUTORIZADO = 78;
export const REACT_APP_AVAL_ESTADO_FORMALIZADO = 79;
export const REACT_APP_AVAL_ESTADO_ARREGLO_PAGO = 80;
export const REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO = 81;
export const REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA = 82;
export const REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD = 83;
export const REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD = 84;
export const REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME = 85;
export const REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME = 86;
export const REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO = 87;
export const REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE = 88;
export const REACT_APP_AVAL_ESTADO_HONRAMIENTO_RECUPERABLE = 89;
export const REACT_APP_AVAL_ESTADO_ATRASO_EFA = 102;
export const REACT_APP_MONEDA_COLONES = 15;
export const REACT_APP_MONEDA_DOLARES = 16;
export const REACT_APP_TIPO_PROGRAMA_INDIVIDUAL = 12;
export const REACT_APP_TIPO_PROGRAMA_CARTERA_NORMAL = 13;
export const REACT_APP_TIPO_PROGRAMA_CARTERA_PERDIDA_ESPERADA = 14;
export const REACT_APP_ROL_ADMINISTRATIVO = 1;
export const REACT_APP_ROL_EJECUTIVO = 2;
export const REACT_APP_ROL_INCLUSION = 3;
export const REACT_APP_ROL_OPERATIVO = 4;
export const REACT_APP_ROL_JEFATURA = 5;
export const REACT_APP_ROL_CONTABILIDAD = 6;
export const REACT_APP_ROL_SEGUIMIENTO = 7;
export const REACT_APP_ROL_REPRESENTANTE_FODEMIPYME = 8;
export const REACT_APP_ROL_AUDITOR = 9;
export const REACT_APP_CODIGO_RESPUESTA_200 = 200;
export const REACT_APP_CODIGO_RESPUESTA_400 = 400;
export const REACT_APP_CODIGO_RESPUESTA_401 = 401;
export const REACT_APP_CODIGO_RESPUESTA_404 = 404;
export const REACT_APP_CODIGO_RESPUESTA_500 = 500;
export const REACT_APP_CEDULA_BP = 4000042152;
export const REACT_APP_OPERADORA_FODEMIPYME = 1;
export const REACT_APP_OPERADORA_FNA = 2;
export const REACT_APP_NATURALEZA_AVAL = 0;
export const REACT_APP_NATURALEZA_LINEA_CREDITO = 1;
export const REACT_APP_NATURALEZA_AVAL_LINEA_CREDITO = 2;
export const REACT_APP_TIPO_IDENTIFICACION_PERSONA_FISICA_NACIONAL = 17;
export const REACT_APP_TIPO_IDENTIFICACION_PERSONA_JURIDICA_NACIONAL = 18;
export const REACT_APP_TIPO_IDENTIFICACION_EXTRANJERO_RESIDENTE = 19;
export const REACT_APP_PROGRAMA_VIGENCIA_FODEMIPYME = 240;
export const REACT_APP_PROGRAMA_VIGENCIA_FNA = 240;
export const REACT_APP_FONDO_ESTADO_ACTIVO = 90;
export const REACT_APP_ESTADO_CLIENTE_ACTIVO = 3;
export const REACT_APP_ESTADO_CLIENTE_SUSPENDIDO = 4;
export const REACT_APP_ESTADO_PROGRAMA_ACTIVO = 8;
export const REACT_APP_ESTADO_PROGRAMA_INACTIVO = 9;
export const REACT_APP_ESTADO_PROGRAMA_DESESTIMADO = 72;
export const REACT_APP_ESTADO_PROGRAMA_RECHAZADO = 73;
export const REACT_APP_IDENTIFICACION_USUARIO_FISICA_NACIONAL = 74;
export const REACT_APP_IDENTIFICACION_USUARIO_DIMEX = 75;
export const REACT_APP_ESTADO_USUARIO_ACTIVO = 1;
export const REACT_APP_MAXIMO_DIAS_RANGO_FECHA_PERMITIDO_REPORTES = 92;
export const REACT_APP_LONGITUD_CAMPO_MONTOS = 999999999999;
export const REACT_APP_LISTA_MENU_BITACORA =
    [
        {
            "Titulo": "Bit\u00e1cora",
            "Url": REACT_APP_URL_BITACORA,
            "Icon": "fa fa-history"
        },
        {
            "Titulo": "Bit\u00e1cora Registro Consulta",
            "Url": REACT_APP_URL_BITACORA_REGISTRO_CONSULTA,
            "Icon": "fa fa-list"
        },
        {
            "Titulo": "Usuarios Riesgosos",
            "Url": REACT_APP_URL_USUARIOS_RIESGOSOS,
            "Icon": "fa fa-user-times"
        },
        {
            "Titulo": "Hist\u00f3rico Ingresos Usuarios",
            "Url": REACT_APP_URL_HISTORICO_INGRESOS_USUARIOS,
            "Icon": "fa fa-sign-in"
        },
        {
            "Titulo": "Hist\u00f3rico Eventos Usuarios",
            "Url": REACT_APP_URL_HISTORICO_EVENTOS_USUARIOS,
            "Icon": "fa fa-list-alt"
        },
        {
            "Titulo": "Hist\u00f3rico Detecciones Riesgosas",
            "Url": REACT_APP_URL_HISTORICO_DETECCIONES_RIESGOSAS_USUARIOS,
            "Icon": "fa fa-user-secret"
        }
    ];
export const REACT_APP_LISTA_PARAMETROS_MODIFICAR =
    [
        {
            "Titulo": "Tiempo Expiraci\u00f3n Clave",
            "Llave": REACT_APP_TIEMPO_EXPIRACION_CLAVE
        },
        {
            "Titulo": "Largo Clave",
            "Llave": REACT_APP_LARGO_CLAVE
        },
        {
            "Titulo": "Cantidad M\u00ednima Caracteres",
            "Llave": REACT_APP_CANTIDAD_MINIMA_CARACTERES
        },
        {
            "Titulo": "Cantidad M\u00e1xima Caracteres",
            "Llave": REACT_APP_CANTIDAD_MAXIMA_CARACTERES
        },
        {
            "Titulo": "Cantidad Caracteres Especiales",
            "Llave": REACT_APP_CANTIDAD_CARACTERES_ESPECIALES
        },
        {
            "Titulo": "Asunto Clave Temporal",
            "Llave": REACT_APP_ASUNTO_CLAVE_TEMPORAL
        },
        {
            "Titulo": "Tiempo Inactivaci\u00f3n Usuario",
            "Llave": REACT_APP_TIEMPO_INACTIVACION_USUARIO
        },
        {
            "Titulo": "Fecha T\u00e9rminos y Condiciones",
            "Llave": REACT_APP_FECHA_ACEPTAR_TERMINOS_CONDICIONES
        },
        {
            "Titulo": "T\u00e9rminos y Condiciones",
            "Llave": REACT_APP_TERMINOS_CONDICIONES
        }
    ];
export const REACT_APP_LISTA_MODULOS_BITACORA =
    [
        {
            "Titulo": "Avales",
            "Value": "ListarAvales"
        },
        {
            "Titulo": "L\u00EDneas de Cr\u00E9dito",
            "Value": "ListarLineasCredito"
        },
        {
            "Titulo": "SubCr\u00E9ditos",
            "Value": "ListarSubCreditosLineaCredito"
        },
        {
            "Titulo": "Honramientos",
            "Value": "ListarHonramientos"
        },
        {
            "Titulo": "Clientes",
            "Value": "ListarClientes"
        },
        {
            "Titulo": "Listar Usuarios",
            "Value": "ListarUsuarios"
        },
        {
            "Titulo": "Programas Individuales",
            "Value": "ListarProgramasIndividuales"
        },
        {
            "Titulo": "Programas de Cartera",
            "Value": "ListarProgramasCartera"
        },
        {
            "Titulo": "Programas de Cartera de P\u00E9rdida Esperada",
            "Value": "ListarProgramasCarteraPerdidaEsperada"
        },
        {
            "Titulo": "Reporte General de Avales",
            "Value": "ReporteGeneralAvales"
        },
        {
            "Titulo": "Reporte Impacto Cualitativo",
            "Value": "ReporteImpactoCualitativo"
        },
        {
            "Titulo": "Reporte Gesti\u00F3n Fodemipyme",
            "Value": "ReporteGestionFodemipyme"
        },
        {
            "Titulo": "Reporte de Honramientos",
            "Value": "ReporteHonramientos"
        },
        {
            "Titulo": "Reporte Provisiones",
            "Value": "ReporteProvisiones"
        },
        {
            "Titulo": "Reporte L\u00EDnea de Cr\u00E9dito",
            "Value": "ReporteLineaCredito"
        },
        {
            "Titulo": "Generar Excel Reporte General de Avales",
            "Value": "GenerarReporteGeneralAvales"
        },
        {
            "Titulo": "Generar Excel Reporte Cualitativo",
            "Value": "GenerarReporteCualitativo"
        },
        {
            "Titulo": "Generar Excel Reporte Gesti\u00F3n Fodemipyme",
            "Value": "GenerarReporteGestionFodemipyme"
        },
        {
            "Titulo": "Generar Excel Reporte Honramiento",
            "Value": "GenerarReporteHonramiento"
        },
        {
            "Titulo": "Generar Excel Reporte Provisiones",
            "Value": "GenerarReporteProvisiones"
        },
        {
            "Titulo": "Generar Excel Reporte L\u00EDneas de Cr\u00E9dito",
            "Value": "GenerarReporteLineasCredito"
        }
    ];