import React, { Component } from "react";
import { CrearCliente } from "../../Services/ClienteService";
import {
    REACT_APP_CODIGO_RESPUESTA_200,
    REACT_APP_OPERADORA_FODEMIPYME,
    REACT_APP_OPERADORA_FNA,
    REACT_APP_ROL_EJECUTIVO
} from "../../environment";
import swal from "sweetalert";
import {
    Tabs,
    Tab
} from "react-bootstrap";
/**
 * Obtener el Fondo seleccionado en iniciar sesion
 */
const fondoSeleccionado = JSON.parse(localStorage.getItem("fondoSeleccionado"));
const $ = require("jquery");
class CrearClienteComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            llave: "",
            indIrFODEMIPYME: true,
            indIrFNA: true,
            tituloBoton: "",
            nombreOperadora: "",
            cedulaJuridica: "",
            indButton: false,
            caracteristicasModel: [],
            caracteristicasFODEMIPYMEModel: {
                IdFondo: 1,
                Direccion: "",
                Telefono1: "",
                Telefono2: "",
                Telefono3: "",
                CorreosAdministrativos: "",
                NombreRepresentante: "",
                CorreoRemitirInformacion: "",
                CorreoOperativo: "",
                NombreOperativo: "",
                TelefonoOperativo: "",
                IdUsuarioCreador: "",
                IBANColones: "",
                IBANDolares: ""
            },
            caracteristicasFNAModel: {
                IdFondo: 2,
                Direccion: "",
                Telefono1: "",
                Telefono2: "",
                Telefono3: "",
                CorreosAdministrativos: "",
                NombreRepresentante: "",
                CorreoRemitirInformacion: "",
                CorreoOperativo: "",
                NombreOperativo: "",
                TelefonoOperativo: "",
                IdUsuarioCreador: "",
                IBANColones: "",
                IBANDolares: ""
            },
        };
    }
    /**
     * Llamado inmediatamente después de montar un componente. 
     * Establecer el estado aquí activará la re-renderización.
     */
    componentDidMount() {
        $("#guardando").hide();
        /**
         * Validar Token de sesion
         */
        const tokenContent = localStorage.getItem("isLoggedIn");
        if (!tokenContent) {
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        }
        const idFondo = localStorage.getItem("idFondo");
        if (idFondo == REACT_APP_OPERADORA_FODEMIPYME) {
            this.state.indIrFNA = false;
            this.state.tituloBoton = " Fondo Nacional Avales";
            this.state.llave = "FODEMIPYME";
        } else {
            this.state.indIrFODEMIPYME = false;
            this.state.tituloBoton = " FODEMIPYME";
            this.state.llave = "FNA";
        }
    }
    /**
     * 
     * @param {*} event 
     * @returns Alamacena en el State lo digitado en el formulario.
     */
    encargarseCambio = (event) => {
        if (
            event.target.name === "cedulaJuridica" &&
            event.target.value.length > 10
        ) {
            swal(
                "Crear Cliente",
                "Ha sobrepasado la cantidad máxima de caracteres permitidos para la cédula jurídica",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        if (this.state.indIrFNA) {
            if (event.target.name == "caracteristicasFNAModel.Direccion") this.state.caracteristicasFNAModel.Direccion = event.target.value;
            if (event.target.name == "caracteristicasFNAModel.Telefono1") {
                if (
                    event.target.name === "caracteristicasFNAModel.Telefono1" &&
                    event.target.value.length > 8
                ) {
                    swal(
                        "Crear Cliente",
                        "Ha sobrepasado la cantidad máxima de caracteres permitidos en teléfono 1 para Fondo Nacional de Avales",
                        "warning", { button: "Aceptar", }
                    );
                    return;
                }
                this.state.caracteristicasFNAModel.Telefono1 = event.target.value;
            }

            if (event.target.name == "caracteristicasFNAModel.Telefono2") {
                if (
                    event.target.name === "caracteristicasFNAModel.Telefono2" &&
                    event.target.value.length > 8
                ) {
                    swal(
                        "Crear Cliente",
                        "Ha sobrepasado la cantidad máxima de caracteres permitidos en teléfono 2 para Fondo Nacional de Avales",
                        "warning", { button: "Aceptar", }
                    );
                    return;
                }
                this.state.caracteristicasFNAModel.Telefono2 = event.target.value;
            }
            if (event.target.name == "caracteristicasFNAModel.Telefono3") {
                if (
                    event.target.name === "caracteristicasFNAModel.Telefono3" &&
                    event.target.value.length > 8
                ) {
                    swal(
                        "Crear Cliente",
                        "Ha sobrepasado la cantidad máxima de caracteres permitidos en teléfono 3 para Fondo Nacional de Avales",
                        "warning", { button: "Aceptar", }
                    );
                    return;
                }
                this.state.caracteristicasFNAModel.Telefono3 = event.target.value;
            }
            if (event.target.name == "caracteristicasFNAModel.CorreosAdministrativos") this.state.caracteristicasFNAModel.CorreosAdministrativos = event.target.value;
            if (event.target.name == "caracteristicasFNAModel.NombreRepresentante") this.state.caracteristicasFNAModel.NombreRepresentante = event.target.value;
            if (event.target.name == "caracteristicasFNAModel.CorreoRemitirInformacion") this.state.caracteristicasFNAModel.CorreoRemitirInformacion = event.target.value;
            if (event.target.name == "caracteristicasFNAModel.CorreoOperativo") this.state.caracteristicasFNAModel.CorreoOperativo = event.target.value;
            if (event.target.name == "caracteristicasFNAModel.NombreOperativo") this.state.caracteristicasFNAModel.NombreOperativo = event.target.value;
            if (event.target.name == "caracteristicasFNAModel.TelefonoOperativo") {
                if (
                    event.target.name === "caracteristicasFNAModel.TelefonoOperativo" &&
                    event.target.value.length > 8
                ) {
                    swal(
                        "Crear Cliente",
                        "Ha sobrepasado la cantidad máxima de caracteres permitidos en teléfono operativo para Fondo Nacional de Avales",
                        "warning", { button: "Aceptar", }
                    );
                    return;
                }
                this.state.caracteristicasFNAModel.TelefonoOperativo = event.target.value;
            }
            if (event.target.name == "caracteristicasFNAModel.IBANColones") {
                if (
                    event.target.name === "caracteristicasFNAModel.IBANColones" &&
                    event.target.value.length > 20
                ) {
                    swal(
                        "Crear Cliente",
                        "Ha sobrepasado la cantidad máxima de caracteres permitidos en IBAN Colones para Fondo Nacional de Avales",
                        "warning", { button: "Aceptar", }
                    );
                    return;
                }
                this.state.caracteristicasFNAModel.IBANColones = event.target.value;
            }
            if (event.target.name == "caracteristicasFNAModel.IBANDolares") {
                if (
                    event.target.name === "caracteristicasFNAModel.IBANDolares" &&
                    event.target.value.length > 20
                ) {
                    swal(
                        "Crear Cliente",
                        "Ha sobrepasado la cantidad máxima de caracteres permitidos en IBAN Dólares para Fondo Nacional de Avales",
                        "warning", { button: "Aceptar", }
                    );
                    return;
                }
                this.state.caracteristicasFNAModel.IBANDolares = event.target.value;
            }
            this.setState(this.state.caracteristicasFNAModel);
        }
        if (this.state.indIrFODEMIPYME) {
            if (event.target.name == "caracteristicasFODEMIPYMEModel.Direccion") this.state.caracteristicasFODEMIPYMEModel.Direccion = event.target.value;
            if (event.target.name == "caracteristicasFODEMIPYMEModel.Telefono1") {
                if (
                    event.target.name === "caracteristicasFODEMIPYMEModel.Telefono1" &&
                    event.target.value.length > 8
                ) {
                    swal(
                        "Crear Cliente",
                        "Ha sobrepasado la cantidad máxima de caracteres permitidos en teléfono 1 para FODEMIPYME",
                        "warning", { button: "Aceptar", }
                    );
                    return;
                }
                this.state.caracteristicasFODEMIPYMEModel.Telefono1 = event.target.value;
            }
            if (event.target.name == "caracteristicasFODEMIPYMEModel.Telefono2") {
                if (
                    event.target.name === "caracteristicasFODEMIPYMEModel.Telefono2" &&
                    event.target.value.length > 8
                ) {
                    swal(
                        "Crear Cliente",
                        "Ha sobrepasado la cantidad máxima de caracteres permitidos en teléfono 2 para FODEMIPYME",
                        "warning", { button: "Aceptar", }
                    );
                    return;
                }
                this.state.caracteristicasFODEMIPYMEModel.Telefono2 = event.target.value;
            }
            if (event.target.name == "caracteristicasFODEMIPYMEModel.Telefono3") {
                if (
                    event.target.name === "caracteristicasFODEMIPYMEModel.Telefono3" &&
                    event.target.value.length > 8
                ) {
                    swal(
                        "Crear Cliente",
                        "Ha sobrepasado la cantidad máxima de caracteres permitidos en teléfono 3 para FODEMIPYME",
                        "warning", { button: "Aceptar", }
                    );
                    return;
                }
                this.state.caracteristicasFODEMIPYMEModel.Telefono3 = event.target.value;
            }
            if (event.target.name == "caracteristicasFODEMIPYMEModel.CorreosAdministrativos") this.state.caracteristicasFODEMIPYMEModel.CorreosAdministrativos = event.target.value;
            if (event.target.name == "caracteristicasFODEMIPYMEModel.NombreRepresentante") this.state.caracteristicasFODEMIPYMEModel.NombreRepresentante = event.target.value;
            if (event.target.name == "caracteristicasFODEMIPYMEModel.CorreoRemitirInformacion") this.state.caracteristicasFODEMIPYMEModel.CorreoRemitirInformacion = event.target.value;
            if (event.target.name == "caracteristicasFODEMIPYMEModel.CorreoOperativo") this.state.caracteristicasFODEMIPYMEModel.CorreoOperativo = event.target.value;
            if (event.target.name == "caracteristicasFODEMIPYMEModel.NombreOperativo") this.state.caracteristicasFODEMIPYMEModel.NombreOperativo = event.target.value;
            if (event.target.name == "caracteristicasFODEMIPYMEModel.TelefonoOperativo") {
                if (
                    event.target.name === "caracteristicasFODEMIPYMEModel.TelefonoOperativo" &&
                    event.target.value.length > 8
                ) {
                    swal(
                        "Crear Cliente",
                        "Ha sobrepasado la cantidad máxima de caracteres permitidos en teléfono operativo para FODEMIPYME",
                        "warning", { button: "Aceptar", }
                    );
                    return;
                }
                this.state.caracteristicasFODEMIPYMEModel.TelefonoOperativo = event.target.value;
            }
            if (event.target.name == "caracteristicasFODEMIPYMEModel.IBANColones") {
                if (
                    event.target.name === "caracteristicasFODEMIPYMEModel.IBANColones" &&
                    event.target.value.length > 20
                ) {
                    swal(
                        "Crear Cliente",
                        "Ha sobrepasado la cantidad máxima de caracteres permitidos en IBAN Colones para FODEMIPYME",
                        "warning", { button: "Aceptar", }
                    );
                    return;
                }
                this.state.caracteristicasFODEMIPYMEModel.IBANColones = event.target.value;
            }
            if (event.target.name == "caracteristicasFODEMIPYMEModel.IBANDolares") {
                if (
                    event.target.name === "caracteristicasFODEMIPYMEModel.IBANDolares" &&
                    event.target.value.length > 20
                ) {
                    swal(
                        "Crear Cliente",
                        "Ha sobrepasado la cantidad máxima de caracteres permitidos en IBAN Colones para FODEMIPYME",
                        "warning", { button: "Aceptar", }
                    );
                    return;
                }
                this.state.caracteristicasFODEMIPYMEModel.IBANDolares = event.target.value;
            }
            this.setState(this.state.caracteristicasFODEMIPYMEModel);
        }
        this.setState({ [event.target.name]: event.target.value });
    };
    /**
     * 
     * @param {*} k 
     * @returns El tab seleccionado
     */
    cambiarTab = (k) => {
        this.setState({ llave: k });
    };
    /**
     * Habilitar el formulario de FODEMIPYME
     */
    agregarFODEMIPYME = () => {
        this.setState({ indIrFODEMIPYME: true });
    };
    /**
     * Habilitar el formulario de Fondo Nacional de Avales
     */
    agregarFNA = () => {
        this.setState({ indIrFNA: true });
    };
    /**
     * Cancelar el formulario de FODEMIPYME
     */
    cancelarAgregarFODEMIPYME = () => {
        this.setState({ indIrFODEMIPYME: false });
        this.state.caracteristicasFODEMIPYMEModel = {
            IdFondo: REACT_APP_OPERADORA_FODEMIPYME,
            Direccion: "",
            Telefono1: "",
            Telefono2: "",
            Telefono3: "",
            CorreosAdministrativos: "",
            NombreRepresentante: "",
            CorreoRemitirInformacion: "",
            CorreoOperativo: "",
            NombreOperativo: "",
            TelefonoOperativo: "",
            IdUsuarioCreador: "",
            IBANColones: "",
            IBANDolares: ""
        };
    };
    /**
     * Cancelar el formulario de Fondo Nacional de Avales
     */
    cancelarAgregarFNA = () => {
        this.setState({ indIrFNA: false });
        this.state.caracteristicasFNAModel = {
            IdFondo: REACT_APP_OPERADORA_FNA,
            Direccion: "",
            Telefono1: "",
            Telefono2: "",
            Telefono3: "",
            CorreosAdministrativos: "",
            NombreRepresentante: "",
            CorreoRemitirInformacion: "",
            CorreoOperativo: "",
            NombreOperativo: "",
            TelefonoOperativo: "",
            IdUsuarioCreador: "",
            IBANColones: "",
            IBANDolares: ""
        };
        this.setState(this.state.caracteristicasFNAModel);
    };
    /**
     * Restablecer las cuentas IBAN
     */
    restablecerCuentasIBAN = () => {
        if (this.state.caracteristicasFNAModel.IBANColones.length == 22) {
            this.state.caracteristicasFNAModel.IBANColones = this.state.caracteristicasFNAModel.IBANColones.substr(2);
            this.state.caracteristicasFNAModel.IBANDolares = this.state.caracteristicasFNAModel.IBANDolares.substr(2);
        }

        if (this.state.caracteristicasFODEMIPYMEModel.IBANColones.length == 22) {
            this.state.caracteristicasFODEMIPYMEModel.IBANColones = this.state.caracteristicasFODEMIPYMEModel.IBANColones.substr(2);
            this.state.caracteristicasFODEMIPYMEModel.IBANDolares = this.state.caracteristicasFODEMIPYMEModel.IBANDolares.substr(2);
        }
    };
    /**
     * 
     * @param {*} event 
     * @returns Validar los formularios y crear el cliente
     */
    crearCliente = (event) => {
        event.preventDefault();
        $("#guardando").show();
        const letras = new RegExp(/^[a-zA-ZáéíñóúüÁÉÍÑÓÚÜ ]*$/);
        const correo = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const correos = /^(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/;
        const soloLetrasOperadora = letras.test(this.state.nombreOperadora);
        if (this.state.nombreOperadora == "") {
            swal("Crear Cliente", "El nombre de la operadora requerida", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }
        if (this.state.nombreOperadora.length < 5) {
            swal(
                "Crear Cliente",
                "El nombre de la operadora debe contener mínimo 5 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.nombreOperadora.length > 100) {
            swal(
                "Crear Cliente",
                "El nombre de la operadora debe contener máximo 100 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (!soloLetrasOperadora) {
            swal(
                "Crear Cliente",
                "Nombre de la operadora debe contener solo letras",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.cedulaJuridica == "") {
            swal("Crear Cliente", "La Cédula Jurídica es requerida", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }
        if (this.state.cedulaJuridica.length != 10) {
            swal(
                "Crear Cliente",
                "La Cédula Jurídica debe contener 10 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        /**
         * Validar el formulario de FODEMIPYME si se encuentra habilitado
         */
        if (this.state.indIrFODEMIPYME) {
            const soloLetrasRepresentante = letras.test(this.state.caracteristicasFODEMIPYMEModel.NombreRepresentante);
            const soloLetrasOperativo = letras.test(this.state.caracteristicasFODEMIPYMEModel.NombreOperativo);
            const correoValido = correo.test(this.state.caracteristicasFODEMIPYMEModel.CorreoRemitirInformacion);
            const correoOperativoValido = correo.test(this.state.caracteristicasFODEMIPYMEModel.CorreoOperativo);
            const correosValidos = correos.test(this.state.caracteristicasFODEMIPYMEModel.CorreosAdministrativos);
            if (this.state.caracteristicasFODEMIPYMEModel.Direccion.length < 5) {
                swal(
                    "Crear Cliente",
                    "La dirección debe contener mínimo 5 caracteres para FODEMIPYME",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (this.state.caracteristicasFODEMIPYMEModel.Direccion.length > 100) {
                swal(
                    "Crear Cliente",
                    "La dirección debe contener máximo 100 caracteres para FODEMIPYME",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (this.state.caracteristicasFODEMIPYMEModel.Telefono1 == "" || this.state.caracteristicasFODEMIPYMEModel.Telefono1 == null) {
                swal("Crear Cliente", "La Primer Teléfono es requerido para FODEMIPYME", "error", { button: "Aceptar", });
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (
                this.state.caracteristicasFODEMIPYMEModel.Telefono1 != null &&
                this.state.caracteristicasFODEMIPYMEModel.Telefono1.toString().length < 8
            ) {
                swal(
                    "Crear Cliente",
                    "El primer teléfono debe contener 8 dígitos como mínimo para FODEMIPYME",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (
                this.state.caracteristicasFODEMIPYMEModel.Telefono1 != null &&
                this.state.caracteristicasFODEMIPYMEModel.Telefono1.toString().length > 8
            ) {
                swal(
                    "Crear Cliente",
                    "El primer teléfono debe contener 8 dígitos como máximo para FODEMIPYME",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (this.state.caracteristicasFODEMIPYMEModel.Telefono2 != null && this.state.caracteristicasFODEMIPYMEModel.Telefono2 != "") {
                if (this.state.caracteristicasFODEMIPYMEModel.Telefono2.toString().length < 8) {
                    swal(
                        "Crear Cliente",
                        "El segundo teléfono debe contener 8 dígitos como mínimo para FODEMIPYME",
                        "error", { button: "Aceptar", }
                    );
                    $("#guardando").hide();
                    this.restablecerCuentasIBAN();
                    return;
                }
            }
            if (this.state.caracteristicasFODEMIPYMEModel.Telefono2 != null && this.state.caracteristicasFODEMIPYMEModel.Telefono2 != "") {
                if (this.state.caracteristicasFODEMIPYMEModel.Telefono2.toString().length > 8) {
                    swal(
                        "Crear Cliente",
                        "El segundo teléfono debe contener 8 dígitos como máximo para FODEMIPYME",
                        "error", { button: "Aceptar", }
                    );
                    $("#guardando").hide();
                    this.restablecerCuentasIBAN();
                    return;
                }
            }
            if (this.state.caracteristicasFODEMIPYMEModel.Telefono3 != null && this.state.caracteristicasFODEMIPYMEModel.Telefono3 != "") {
                if (this.state.caracteristicasFODEMIPYMEModel.Telefono3.toString().length < 8) {
                    swal(
                        "Crear Cliente",
                        "El tercer teléfono debe contener 8 dígitos como mínimo para FODEMIPYME",
                        "error", { button: "Aceptar", }
                    );
                    $("#guardando").hide();
                    this.restablecerCuentasIBAN();
                    return;
                }
            }
            if (this.state.caracteristicasFODEMIPYMEModel.Telefono3 != null && this.state.caracteristicasFODEMIPYMEModel.Telefono3 != "") {
                if (this.state.caracteristicasFODEMIPYMEModel.Telefono3.toString().length > 8) {
                    swal(
                        "Crear Cliente",
                        "El tercer teléfono debe contener 8 dígitos como máximo para FODEMIPYME",
                        "error", { button: "Aceptar", }
                    );
                    $("#guardando").hide();
                    this.restablecerCuentasIBAN();
                    return;
                }
            }
            if (this.state.caracteristicasFODEMIPYMEModel.CorreosAdministrativos.length < 5) {
                swal(
                    "Crear Cliente",
                    "Los correos administrativos deben contener mínimo 5 caracteres para FODEMIPYME",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (this.state.caracteristicasFODEMIPYMEModel.CorreosAdministrativos.length > 100) {
                swal(
                    "Crear Cliente",
                    "Los correos administrativos deben contener máximo 100 caracteres para FODEMIPYME",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (this.state.caracteristicasFODEMIPYMEModel.NombreRepresentante == "") {
                swal("Crear Cliente", "Nombre del representante es requerido para FODEMIPYME", "error", { button: "Aceptar", });
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (!soloLetrasRepresentante) {
                swal(
                    "Crear Cliente",
                    "Nombre del representante debe contener solo letras para FODEMIPYME",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (this.state.caracteristicasFODEMIPYMEModel.CorreoRemitirInformacion == "") {
                swal("Crear Cliente", "El correo del remitente es requerido para FODEMIPYME", "error", { button: "Aceptar", });
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (!correoValido) {
                swal(
                    "Crear Cliente",
                    "El correo del remitente debe contener un formato válido para FODEMIPYME",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (!correosValidos) {
                swal(
                    "Crear Cliente",
                    "El correo o los correos adminstrativos debe contener un formato válido para FODEMIPYME",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (this.state.caracteristicasFODEMIPYMEModel.NombreOperativo == "") {
                swal("Crear Cliente", "Nombre del operativo es requerido para FODEMIPYME", "error", { button: "Aceptar", });
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (!soloLetrasOperativo) {
                swal(
                    "Crear Cliente",
                    "Nombre del operativo debe contener solo letras para FODEMIPYME",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (this.state.caracteristicasFODEMIPYMEModel.CorreoOperativo == "") {
                swal("Crear Cliente", "El correo del operativo es requerido para FODEMIPYME", "error", { button: "Aceptar", });
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (!correoOperativoValido) {
                swal(
                    "Crear Cliente",
                    "El correo del operativo debe contener un formato válido para FODEMIPYME",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (
                this.state.caracteristicasFODEMIPYMEModel.TelefonoOperativo == "" ||
                this.state.caracteristicasFODEMIPYMEModel.TelefonoOperativo == null
            ) {
                swal("Crear Cliente", "El teléfono del operativo es requerido para FODEMIPYME", "error", { button: "Aceptar", });
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (
                this.state.caracteristicasFODEMIPYMEModel.TelefonoOperativo != null &&
                this.state.caracteristicasFODEMIPYMEModel.TelefonoOperativo.toString().length < 8
            ) {
                swal(
                    "Crear Cliente",
                    "El teléfono del operativo debe contener 8 dígitos como mínimo para FODEMIPYME",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (
                this.state.caracteristicasFODEMIPYMEModel.TelefonoOperativo != null &&
                this.state.caracteristicasFODEMIPYMEModel.TelefonoOperativo.toString().length > 8
            ) {
                swal(
                    "Crear Cliente",
                    "El teléfono del operativo debe contener 8 dígitos como máximo para FODEMIPYME",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (this.state.caracteristicasFODEMIPYMEModel.IBANColones == "" || this.state.caracteristicasFODEMIPYMEModel.IBANColones == null) {
                swal("Crear Cliente", "La cuenta IBAN Colones es requerida para FODEMIPYME", "error", { button: "Aceptar", });
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (this.state.caracteristicasFODEMIPYMEModel.IBANColones.toString().length < 20) {
                swal(
                    "Crear Cliente",
                    "La cuenta IBAN Colones debe contener 20 dígitos para FODEMIPYME",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (this.state.caracteristicasFODEMIPYMEModel.IBANColones.toString().length > 20) {
                swal(
                    "Crear Cliente",
                    "La cuenta IBAN Colones debe contener 20 dígitos para FODEMIPYME",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }

            if (this.state.caracteristicasFODEMIPYMEModel.IBANDolares == "" || this.state.caracteristicasFODEMIPYMEModel.IBANDolares == null) {
                swal("Crear Cliente", "La cuenta IBAN Dólares es requerida para FODEMIPYME", "error", { button: "Aceptar", });
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (this.state.caracteristicasFODEMIPYMEModel.IBANDolares.toString().length < 20) {
                swal(
                    "Crear Cliente",
                    "La cuenta IBAN Dólares debe contener 20 dígitos para FODEMIPYME",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (this.state.caracteristicasFODEMIPYMEModel.IBANDolares.toString().length > 20) {
                swal(
                    "Crear Cliente",
                    "La cuenta IBAN Dólares debe contener 20 dígitos para FODEMIPYME",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            this.state.caracteristicasFODEMIPYMEModel.IdUsuarioCreador = localStorage.getItem("idUsuarioCreador");
            this.state.caracteristicasFODEMIPYMEModel.IBANColones = "CR" + this.state.caracteristicasFODEMIPYMEModel.IBANColones;
            this.state.caracteristicasFODEMIPYMEModel.IBANDolares = "CR" + this.state.caracteristicasFODEMIPYMEModel.IBANDolares;
            this.setState(this.state.caracteristicasFODEMIPYMEModel);
            var arregloFODEMIPYME = this.state.caracteristicasModel.filter(function (cliente) {
                return (
                    cliente.IdFondo == REACT_APP_OPERADORA_FODEMIPYME
                );
            });
            if (arregloFODEMIPYME.length == 0) {
                this.state.caracteristicasModel.push(this.state.caracteristicasFODEMIPYMEModel);
            }
        }
        /**
         * Validar el formulario de Fondo Nacional de Avales si se encuentra habilitado
         */
        if (this.state.indIrFNA) {
            const soloLetrasRepresentante = letras.test(this.state.caracteristicasFNAModel.NombreRepresentante);
            const soloLetrasOperativo = letras.test(this.state.caracteristicasFNAModel.NombreOperativo);
            const correoValido = correo.test(this.state.caracteristicasFNAModel.CorreoRemitirInformacion);
            const correoOperativoValido = correo.test(this.state.caracteristicasFNAModel.CorreoOperativo);
            const correosValidos = correos.test(this.state.caracteristicasFNAModel.CorreosAdministrativos);
            if (this.state.caracteristicasFNAModel.Direccion.length < 5) {
                swal(
                    "Crear Cliente",
                    "La dirección debe contener mínimo 5 caracteres para Fondo Nacional de Avales",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (this.state.caracteristicasFNAModel.Direccion.length > 100) {
                swal(
                    "Crear Cliente",
                    "La dirección debe contener máximo 100 caracteres para Fondo Nacional de Avales",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (this.state.caracteristicasFNAModel.Telefono1 == "" || this.state.caracteristicasFNAModel.Telefono1 == null) {
                swal("Crear Cliente", "La Primer Teléfono es requerido para Fondo Nacional de Avales", "error", { button: "Aceptar", });
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (
                this.state.caracteristicasFNAModel.Telefono1 != null &&
                this.state.caracteristicasFNAModel.Telefono1.toString().length < 8
            ) {
                swal(
                    "Crear Cliente",
                    "El primer teléfono debe contener 8 dígitos como mínimo para Fondo Nacional de Avales",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (
                this.state.caracteristicasFNAModel.Telefono1 != null &&
                this.state.caracteristicasFNAModel.Telefono1.toString().length > 8
            ) {
                swal(
                    "Crear Cliente",
                    "El primer teléfono debe contener 8 dígitos como máximo para Fondo Nacional de Avales",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (this.state.caracteristicasFNAModel.Telefono2 != null && this.state.caracteristicasFNAModel.Telefono2 != "") {
                if (this.state.caracteristicasFNAModel.Telefono2.toString().length < 8) {
                    swal(
                        "Crear Cliente",
                        "El segundo teléfono debe contener 8 dígitos como mínimo para Fondo Nacional de Avales",
                        "error", { button: "Aceptar", }
                    );
                    $("#guardando").hide();
                    this.restablecerCuentasIBAN();
                    return;
                }
            }
            if (this.state.caracteristicasFNAModel.Telefono2 != null && this.state.caracteristicasFNAModel.Telefono2 != "") {
                if (this.state.caracteristicasFNAModel.Telefono2.toString().length > 8) {
                    swal(
                        "Crear Cliente",
                        "El segundo teléfono debe contener 8 dígitos como máximo para Fondo Nacional de Avales",
                        "error", { button: "Aceptar", }
                    );
                    $("#guardando").hide();
                    this.restablecerCuentasIBAN();
                    return;
                }
            }
            if (this.state.caracteristicasFNAModel.Telefono3 != null && this.state.caracteristicasFNAModel.Telefono3 != "") {
                if (this.state.caracteristicasFNAModel.Telefono3.toString().length < 8) {
                    swal(
                        "Crear Cliente",
                        "El tercer teléfono debe contener 8 dígitos como mínimo para Fondo Nacional de Avales",
                        "error", { button: "Aceptar", }
                    );
                    $("#guardando").hide();
                    this.restablecerCuentasIBAN();
                    return;
                }
            }
            if (this.state.caracteristicasFNAModel.Telefono3 != null && this.state.caracteristicasFNAModel.Telefono3 != "") {
                if (this.state.caracteristicasFNAModel.Telefono3.toString().length > 8) {
                    swal(
                        "Crear Cliente",
                        "El tercer teléfono debe contener 8 dígitos como máximo para Fondo Nacional de Avales",
                        "error", { button: "Aceptar", }
                    );
                    $("#guardando").hide();
                    this.restablecerCuentasIBAN();
                    return;
                }
            }
            if (this.state.caracteristicasFNAModel.CorreosAdministrativos.length < 5) {
                swal(
                    "Crear Cliente",
                    "Los correos administrativos deben contener mínimo 5 caracteres para Fondo Nacional de Avales",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (this.state.caracteristicasFNAModel.CorreosAdministrativos.length > 100) {
                swal(
                    "Crear Cliente",
                    "Los correos administrativos deben contener máximo 100 caracteres para Fondo Nacional de Avales",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (this.state.caracteristicasFNAModel.NombreRepresentante == "") {
                swal("Crear Cliente", "Nombre del representante es requerido para Fondo Nacional de Avales", "error", { button: "Aceptar", });
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (!soloLetrasRepresentante) {
                swal(
                    "Crear Cliente",
                    "Nombre del representante debe contener solo letras para Fondo Nacional de Avales",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (this.state.caracteristicasFNAModel.CorreoRemitirInformacion == "") {
                swal("Crear Cliente", "El correo del remitente es requerido para Fondo Nacional de Avales", "error", { button: "Aceptar", });
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (!correoValido) {
                swal(
                    "Crear Cliente",
                    "El correo del remitente debe contener un formato válido para Fondo Nacional de Avales",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (!correosValidos) {
                swal(
                    "Crear Cliente",
                    "El correo o los correos adminstrativos debe contener un formato válido para Fondo Nacional de Avales",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (this.state.caracteristicasFNAModel.NombreOperativo == "") {
                swal("Crear Cliente", "Nombre del operativo es requerido para Fondo Nacional de Avales", "error", { button: "Aceptar", });
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (!soloLetrasOperativo) {
                swal(
                    "Crear Cliente",
                    "Nombre del operativo debe contener solo letras para Fondo Nacional de Avales",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (this.state.caracteristicasFNAModel.CorreoOperativo == "") {
                swal("Crear Cliente", "El correo del operativo es requerido para Fondo Nacional de Avales", "error", { button: "Aceptar", });
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (!correoOperativoValido) {
                swal(
                    "Crear Cliente",
                    "El correo del operativo debe contener un formato válido para Fondo Nacional de Avales",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (
                this.state.caracteristicasFNAModel.TelefonoOperativo == "" ||
                this.state.caracteristicasFNAModel.TelefonoOperativo == null
            ) {
                swal("Crear Cliente", "El teléfono del operativo es requerido para Fondo Nacional de Avales", "error", { button: "Aceptar", });
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (
                this.state.caracteristicasFNAModel.TelefonoOperativo != null &&
                this.state.caracteristicasFNAModel.TelefonoOperativo.toString().length < 8
            ) {
                swal(
                    "Crear Cliente",
                    "El teléfono del operativo debe contener 8 dígitos como mínimo para Fondo Nacional de Avales",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (
                this.state.caracteristicasFNAModel.TelefonoOperativo != null &&
                this.state.caracteristicasFNAModel.TelefonoOperativo.toString().length > 8
            ) {
                swal(
                    "Crear Cliente",
                    "El teléfono del operativo debe contener 8 dígitos como máximo para Fondo Nacional de Avales",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (this.state.caracteristicasFNAModel.IBANColones == "" || this.state.caracteristicasFNAModel.IBANColones == null) {
                swal("Crear Cliente", "La cuenta IBAN Colones es requerida para Fondo Nacional de Avales", "error", { button: "Aceptar", });
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (this.state.caracteristicasFNAModel.IBANColones.toString().length < 20) {
                swal(
                    "Crear Cliente",
                    "La cuenta IBAN Colones debe contener 20 dígitos para Fondo Nacional de Avales",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (this.state.caracteristicasFNAModel.IBANColones.toString().length > 20) {
                swal(
                    "Crear Cliente",
                    "La cuenta IBAN Colones debe contener 20 dígitos para Fondo Nacional de Avales",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }

            if (this.state.caracteristicasFNAModel.IBANDolares == "" || this.state.caracteristicasFNAModel.IBANDolares == null) {
                swal("Crear Cliente", "La cuenta IBAN Dólares es requerida para Fondo Nacional de Avales", "error", { button: "Aceptar", });
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (this.state.caracteristicasFNAModel.IBANDolares.toString().length < 20) {
                swal(
                    "Crear Cliente",
                    "La cuenta IBAN Dólares debe contener 20 dígitos para Fondo Nacional de Avales",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            if (this.state.caracteristicasFNAModel.IBANDolares.toString().length > 20) {
                swal(
                    "Crear Cliente",
                    "La cuenta IBAN Dólares debe contener 20 dígitos para Fondo Nacional de Avales",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                this.restablecerCuentasIBAN();
                return;
            }
            this.state.caracteristicasFNAModel.IdUsuarioCreador = localStorage.getItem("idUsuarioCreador");
            this.state.caracteristicasFNAModel.IBANColones = "CR" + this.state.caracteristicasFNAModel.IBANColones;
            this.state.caracteristicasFNAModel.IBANDolares = "CR" + this.state.caracteristicasFNAModel.IBANDolares;
            this.setState(this.state.caracteristicasFNAModel);

            var arregloFNA = this.state.caracteristicasModel.filter(function (cliente) {
                return (
                    cliente.IdFondo == REACT_APP_OPERADORA_FNA
                );
            });
            if (arregloFNA.length == 0) {
                this.state.caracteristicasModel.push(this.state.caracteristicasFNAModel);
            }
        }
        let data = {
            IdFondo: localStorage.getItem("idFondo"),
            NombreOperadora: this.state.nombreOperadora,
            CedulaJuridica: parseInt(this.state.cedulaJuridica),
            Caracteristicas: this.state.caracteristicasModel
        };
        this.setState({ indButton: true });
        CrearCliente(data).then((res) => {
            if (res) {
                this.restablecerCuentasIBAN();
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    $("#guardando").hide();
                    this.setState({ indButton: false });
                    return;
                }
                $("#guardando").hide();
                this.setState({ indButton: false });
                swal(fondoSeleccionado.nombreFondo, "¡Cliente creado con éxito!", "success", { button: "Aceptar", }).then(
                    (value) => {
                        window.location.href = "/clientes";
                    }
                );
            }
        });
    };
    render() {
        /**
         * Obtener el rol del usuario
         */
        const idRol = localStorage.getItem("idRol");
        /**
         * Obtener el id del Fondo del inicio sesion
         */
        const idFondo = localStorage.getItem("idFondo");
        const permisosJson = JSON.parse(localStorage.getItem("permisos"));
        if (idRol) {
            const rol = parseInt(idRol);
            if (rol != REACT_APP_ROL_EJECUTIVO) window.location.href = "/clientes";
        } else {
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        }
        return (
            <div className="container text-center">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="/admin">Inicio</a>
                        </li>
                        <li className="breadcrumb-item">
                            <a href="/mantenimiento">Mantenimientos</a>
                        </li>
                        <li className="breadcrumb-item">
                            <a href="/clientes">Clientes</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Crear Cliente
                        </li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col-md-12">
                        <button
                            className="btn btn-primary border-radius"
                            onClick={idFondo == REACT_APP_OPERADORA_FODEMIPYME ? this.agregarFNA : this.agregarFODEMIPYME}
                            hidden={permisosJson.length == 1}
                        >
                            <i
                                id="floppy"
                                className="fa fa-plus-square"
                                aria-hidden="true"
                                style={{ color: "white" }}
                            ></i>
                            {this.state.tituloBoton}
                        </button>
                        <button
                            type="submit"
                            className="btn btn-danger border-radius"
                            onClick={idFondo == REACT_APP_OPERADORA_FODEMIPYME ? this.cancelarAgregarFNA : this.cancelarAgregarFODEMIPYME}
                            hidden={idFondo == REACT_APP_OPERADORA_FODEMIPYME && !this.state.indIrFNA || idFondo == REACT_APP_OPERADORA_FNA && !this.state.indIrFODEMIPYME}
                        >
                            <i
                                id="floppy"
                                className="fa fa-trash-o"
                                aria-hidden="true"
                                style={{ color: "white" }}
                            ></i>
                            {" "}Cancelar
                        </button>
                    </div>
                    <form className="form-crear" onSubmit={this.crearCliente} novalidate>
                        <h1 className="h3 mb-3 font-weight-normal">Crear Cliente</h1>
                        <div className="row form-padding">
                            <div className="col-md-6">
                                <label>Entidad Operadora</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    minLength="5"
                                    maxLength="100"
                                    name="nombreOperadora"
                                    value={this.state.nombreOperadora}
                                    onChange={this.encargarseCambio}
                                    required
                                    placeholder="Entidad operadora"
                                    title="La Entidad Operadora debe contener mínimo 5 y máximo 100 caracteres"
                                />
                            </div>
                            <div className="col-md-6">
                                <label>Cédula Jurídica</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    min="0"
                                    minLength="10"
                                    maxLength="10"
                                    name="cedulaJuridica"
                                    value={this.state.cedulaJuridica}
                                    onChange={this.encargarseCambio}
                                    required
                                    placeholder="Ejemplo: 3002398412"
                                    title="Este tipo de persona tendrá 3 (Persona Jurídica) o 4 (Institución Autónoma) como primera posición de la cédula y debe contener un máximo 10 de caracteres."
                                />
                            </div>
                        </div>
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={this.state.llave}
                            onSelect={(k) => this.cambiarTab(k)}
                            className="mb-3"
                        >
                            <Tab eventKey="FODEMIPYME" title="FODEMIPYME" disabled={!this.state.indIrFODEMIPYME}>
                                {(idFondo == REACT_APP_OPERADORA_FODEMIPYME || idFondo == REACT_APP_OPERADORA_FNA) && this.state.indIrFODEMIPYME ? (
                                    <div className="row form-padding">
                                        <div className="col">
                                            <label>Dirección</label>
                                            <textarea
                                                className="form-control"
                                                minLength="5"
                                                maxLength="100"
                                                name="caracteristicasFODEMIPYMEModel.Direccion"
                                                value={this.state.caracteristicasFODEMIPYMEModel.Direccion}
                                                onChange={this.encargarseCambio}
                                                rows="3"
                                                placeholder="Dirección"
                                                title="La Dirección debe contener mínimo 5 y máximo 100 caracteres"
                                            ></textarea>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row form-padding">
                                        <div className="col">
                                            <label>Dirección</label>
                                            <textarea
                                                className="form-control"
                                                name="caracteristicasFODEMIPYMEModel.Direccion"
                                                value={this.state.caracteristicasFODEMIPYMEModel.Direccion}
                                                onChange={this.encargarseCambio}
                                                rows="3"
                                                placeholder="Dirección"
                                                disabled
                                            ></textarea>
                                        </div>
                                    </div>
                                )}
                                <div className="row form-padding">
                                    {(idFondo == REACT_APP_OPERADORA_FODEMIPYME || idFondo == REACT_APP_OPERADORA_FNA) && this.state.indIrFODEMIPYME ? (
                                        <div className="col-md-4">
                                            <label>Teléfono 1</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                min="0"
                                                minLength="8"
                                                maxLength="8"
                                                name="caracteristicasFODEMIPYMEModel.Telefono1"
                                                value={this.state.caracteristicasFODEMIPYMEModel.Telefono1}
                                                onChange={this.encargarseCambio}
                                                placeholder="Teléfono 1"
                                                title="El Teléfono 1 debe contener 8 caracteres"
                                            />
                                        </div>
                                    ) : (
                                        <div className="col-md-4">
                                            <label>Teléfono 1</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                min="0"
                                                name="caracteristicasFODEMIPYMEModel.Telefono1"
                                                value={this.state.caracteristicasFODEMIPYMEModel.Telefono1}
                                                onChange={this.encargarseCambio}
                                                disabled
                                                placeholder="Teléfono 1"
                                            />
                                        </div>
                                    )}
                                    {(idFondo == REACT_APP_OPERADORA_FODEMIPYME || idFondo == REACT_APP_OPERADORA_FNA) && this.state.indIrFODEMIPYME ? (
                                        <div className="col-md-4">
                                            <label>Teléfono 2</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                min="0"
                                                minLength="8"
                                                maxLength="8"
                                                name="caracteristicasFODEMIPYMEModel.Telefono2"
                                                value={this.state.caracteristicasFODEMIPYMEModel.Telefono2}
                                                onChange={this.encargarseCambio}
                                                placeholder="Teléfono 2"
                                                title="El Teléfono 2 debe contener 8 caracteres"
                                            />
                                        </div>
                                    ) : (
                                        <div className="col-md-4">
                                            <label>Teléfono 2</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                min="0"
                                                name="caracteristicasFODEMIPYMEModel.Telefono2"
                                                value={this.state.caracteristicasFODEMIPYMEModel.Telefono2}
                                                onChange={this.encargarseCambio}
                                                placeholder="Teléfono 2"
                                                disabled
                                            />
                                        </div>
                                    )}
                                    {(idFondo == REACT_APP_OPERADORA_FODEMIPYME || idFondo == REACT_APP_OPERADORA_FNA) && this.state.indIrFODEMIPYME ? (
                                        <div className="col-md-4">
                                            <label>Teléfono 3</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                min="0"
                                                minLength="8"
                                                maxLength="8"
                                                name="caracteristicasFODEMIPYMEModel.Telefono3"
                                                value={this.state.caracteristicasFODEMIPYMEModel.Telefono3}
                                                onChange={this.encargarseCambio}
                                                placeholder="Teléfono 3"
                                                title="El Teléfono 3 debe contener 8 caracteres"
                                            />
                                        </div>
                                    ) : (
                                        <div className="col-md-4">
                                            <label>Teléfono 3</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                min="0"
                                                name="caracteristicasFODEMIPYMEModel.Telefono3"
                                                value={this.state.caracteristicasFODEMIPYMEModel.Telefono3}
                                                onChange={this.encargarseCambio}
                                                placeholder="Teléfono 3"
                                                disabled
                                            />
                                        </div>
                                    )}
                                </div>
                                {(idFondo == REACT_APP_OPERADORA_FODEMIPYME || idFondo == REACT_APP_OPERADORA_FNA) && this.state.indIrFODEMIPYME ? (
                                    <div className="row form-padding">
                                        <div className="col">
                                            <label>Correos Administrativos</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="caracteristicasFODEMIPYMEModel.CorreosAdministrativos"
                                                minLength="5"
                                                maxLength="100"
                                                value={this.state.caracteristicasFODEMIPYMEModel.CorreosAdministrativos}
                                                onChange={this.encargarseCambio}
                                                placeholder="jhon@midominio.com;doe@midominio.com"
                                                title="Los Correos Administrativos debe tener un formato correcto. Ejemplo: jhon@midominio.com;doe@midominio.com"
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row form-padding">
                                        <div className="col">
                                            <label>Correos Administrativos</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="caracteristicasFODEMIPYMEModel.CorreosAdministrativos"
                                                value={this.state.caracteristicasFODEMIPYMEModel.CorreosAdministrativos}
                                                onChange={this.encargarseCambio}
                                                disabled
                                                placeholder="jhon@midominio.com;doe@midominio.com"
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="row form-padding">
                                    {(idFondo == REACT_APP_OPERADORA_FODEMIPYME || idFondo == REACT_APP_OPERADORA_FNA) && this.state.indIrFODEMIPYME ? (
                                        <div className="col-md-6">
                                            <label>Representante</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="caracteristicasFODEMIPYMEModel.NombreRepresentante"
                                                minLength="3"
                                                maxLength="100"
                                                value={this.state.caracteristicasFODEMIPYMEModel.NombreRepresentante}
                                                onChange={this.encargarseCambio}
                                                placeholder="Representante"
                                                title="Nombre del representante debe contener solo letras"
                                            />
                                        </div>
                                    ) : (
                                        <div className="col-md-6">
                                            <label>Representante</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="caracteristicasFODEMIPYMEModel.NombreRepresentante"
                                                value={this.state.caracteristicasFODEMIPYMEModel.NombreOperativo}
                                                onChange={this.encargarseCambio}
                                                disabled
                                                placeholder="Representante"
                                            />
                                        </div>
                                    )}
                                    {(idFondo == REACT_APP_OPERADORA_FODEMIPYME || idFondo == REACT_APP_OPERADORA_FNA) && this.state.indIrFODEMIPYME ? (
                                        <div className="col-md-6">
                                            <label>Correo para remitir información</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                name="caracteristicasFODEMIPYMEModel.CorreoRemitirInformacion"
                                                minLength="5"
                                                maxLength="100"
                                                value={this.state.caracteristicasFODEMIPYMEModel.CorreoRemitirInformacion}
                                                onChange={this.encargarseCambio}
                                                placeholder="jhon@midominio.com"
                                                title="El Correo para remitir información debe tener un formato correcto. Ejemplo: jhon@midominio.com"
                                            />
                                        </div>
                                    ) : (
                                        <div className="col-md-6">
                                            <label>Correo para remitir información</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                name="caracteristicasFODEMIPYMEModel.CorreoRemitirInformacion"
                                                value={this.state.caracteristicasFODEMIPYMEModel.CorreoRemitirInformacion}
                                                onChange={this.encargarseCambio}
                                                disabled
                                                placeholder="jhon@midominio.com"
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="row form-padding">
                                    {(idFondo == REACT_APP_OPERADORA_FODEMIPYME || idFondo == REACT_APP_OPERADORA_FNA) && this.state.indIrFODEMIPYME ? (
                                        <div className="col-md-6">
                                            <label>IBAN Colones</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="inputGroup-sizing-sm">
                                                        CR
                                                    </span>
                                                </div>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    min="0"
                                                    minLength="20"
                                                    maxLength="20"
                                                    name="caracteristicasFODEMIPYMEModel.IBANColones"
                                                    value={this.state.caracteristicasFODEMIPYMEModel.IBANColones}
                                                    onChange={this.encargarseCambio}
                                                    placeholder="IBAN Colones"
                                                    itle="Una cuenta IBAN en colones debe contener 20 dígitos"
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="col-md-6">
                                            <label>IBAN Colones</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="inputGroup-sizing-sm">
                                                        CR
                                                    </span>
                                                </div>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    min="0"
                                                    name="caracteristicasFODEMIPYMEModel.IBANColones"
                                                    value={this.state.caracteristicasFODEMIPYMEModel.IBANColones}
                                                    onChange={this.encargarseCambio}
                                                    placeholder="IBAN Colones"
                                                    title="Una cuenta IBAN en colones debe contener 20 dígitos"
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {(idFondo == REACT_APP_OPERADORA_FODEMIPYME || idFondo == REACT_APP_OPERADORA_FNA) && this.state.indIrFODEMIPYME ? (
                                        <div className="col-md-6">
                                            <label>IBAN Dólares</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="inputGroup-sizing-sm">
                                                        CR
                                                    </span>
                                                </div>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    min="0"
                                                    minLength="20"
                                                    maxLength="20"
                                                    name="caracteristicasFODEMIPYMEModel.IBANDolares"
                                                    value={this.state.caracteristicasFODEMIPYMEModel.IBANDolares}
                                                    onChange={this.encargarseCambio}
                                                    placeholder="IBAN Dólares"
                                                    title="Una cuenta IBAN en dólares debe contener 20 dígitos"
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="col-md-6">
                                            <label>IBAN Dólares</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="inputGroup-sizing-sm">
                                                        CR
                                                    </span>
                                                </div>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    min="0"
                                                    name="caracteristicasFODEMIPYMEModel.IBANDolares"
                                                    value={this.state.caracteristicasFODEMIPYMEModel.IBANDolares}
                                                    onChange={this.encargarseCambio}
                                                    placeholder="IBAN Dólares"
                                                    title="Una cuenta IBAN tiene 20 dígitos"
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <hr></hr>
                                <div className="row form-padding">
                                    <div className="col-md-3"></div>
                                    <div className="col-md-6">
                                        <label>Contacto en la Organización</label>
                                    </div>
                                    <div className="col-md-3"></div>
                                </div>
                                <div className="row form-padding">
                                    {(idFondo == REACT_APP_OPERADORA_FODEMIPYME || idFondo == REACT_APP_OPERADORA_FNA) && this.state.indIrFODEMIPYME ? (
                                        <div className="col-md-6">
                                            <label>Nombre Operativo</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="caracteristicasFODEMIPYMEModel.NombreOperativo"
                                                minLength="3"
                                                maxLength="100"
                                                value={this.state.caracteristicasFODEMIPYMEModel.NombreOperativo}
                                                onChange={this.encargarseCambio}
                                                placeholder="Nombre Operativo"
                                                title="Nombre del Operativo debe contener solo letras"
                                            />
                                        </div>
                                    ) : (
                                        <div className="col-md-6">
                                            <label>Nombre Operativo</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="caracteristicasFODEMIPYMEModel.NombreOperativo"
                                                value={this.state.caracteristicasFODEMIPYMEModel.NombreOperativo}
                                                onChange={this.encargarseCambio}
                                                disabled
                                                placeholder="Nombre Operativo"
                                                title="Nombre del Operativo debe contener solo letras"
                                            />
                                        </div>
                                    )}
                                    {(idFondo == REACT_APP_OPERADORA_FODEMIPYME || idFondo == REACT_APP_OPERADORA_FNA) && this.state.indIrFODEMIPYME ? (
                                        <div className="col-md-6">
                                            <label>Correo operativo</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                name="caracteristicasFODEMIPYMEModel.CorreoOperativo"
                                                minLength="5"
                                                maxLength="100"
                                                value={this.state.caracteristicasFODEMIPYMEModel.CorreoOperativo}
                                                onChange={this.encargarseCambio}
                                                placeholder="operativo@midominio.com"
                                                title="El Correo Operativo debe tener un formato válido"
                                            />
                                        </div>
                                    ) : (
                                        <div className="col-md-6">
                                            <label>Correo operativo</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                name="caracteristicasFODEMIPYMEModel.CorreoOperativo"
                                                value={this.state.caracteristicasFODEMIPYMEModel.CorreoOperativo}
                                                onChange={this.encargarseCambio}
                                                placeholder="operativo@midominio.com"
                                                disabled
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="row form-padding">
                                    <div className="col-md-3"></div>
                                    {(idFondo == REACT_APP_OPERADORA_FODEMIPYME || idFondo == REACT_APP_OPERADORA_FNA) && this.state.indIrFODEMIPYME ? (
                                        <div className="col-md-6">
                                            <label>Teléfono Operativo</label>
                                            <input
                                                type="number"
                                                min="0"
                                                minLength="8"
                                                maxLength="8"
                                                name="caracteristicasFODEMIPYMEModel.TelefonoOperativo"
                                                className="form-control"
                                                value={this.state.caracteristicasFODEMIPYMEModel.TelefonoOperativo}
                                                onChange={this.encargarseCambio}
                                                placeholder="Teléfono Operativo"
                                                title="El Teléfono Operativo debe contener 8 caracteres"
                                            />
                                        </div>
                                    ) : (
                                        <div className="col-md-6">
                                            <label>Teléfono Operativo</label>
                                            <input
                                                type="number"
                                                min="0"
                                                name="caracteristicasFODEMIPYMEModel.TelefonoOperativo"
                                                className="form-control"
                                                value={this.state.caracteristicasFODEMIPYMEModel.TelefonoOperativo}
                                                onChange={this.encargarseCambio}
                                                disabled
                                                placeholder="Teléfono Operativo"
                                            />
                                        </div>
                                    )}
                                    <div className="col-md-3"></div>
                                </div>
                            </Tab>
                            <Tab eventKey="FNA" title="FNA" disabled={!this.state.indIrFNA}>
                                {idFondo == REACT_APP_OPERADORA_FODEMIPYME && !this.state.indIrFNA ? (
                                    <div className="row form-padding">
                                        <div className="col">
                                            <label>Dirección</label>
                                            <textarea
                                                className="form-control"
                                                name="caracteristicasFNAModel.Direccion"
                                                value={this.state.caracteristicasFNAModel.Direccion}
                                                onChange={this.encargarseCambio}
                                                rows="3"
                                                placeholder="Dirección"
                                                disabled
                                            ></textarea>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row form-padding">
                                        <div className="col">
                                            <label>Dirección</label>
                                            <textarea
                                                className="form-control"
                                                id="exampleFormControlTextarea1"
                                                minLength="5"
                                                maxLength="100"
                                                name="caracteristicasFNAModel.Direccion"
                                                value={this.state.caracteristicasFNAModel.Direccion}
                                                onChange={this.encargarseCambio}
                                                rows="3"
                                                placeholder="Dirección"
                                                title="La Dirección debe contener mínimo 5 y máximo 100 caracteres"
                                            ></textarea>
                                        </div>
                                    </div>
                                )}
                                <div className="row form-padding">
                                    {idFondo == REACT_APP_OPERADORA_FODEMIPYME && !this.state.indIrFNA ? (
                                        <div className="col-md-4">
                                            <label>Teléfono 1</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name="caracteristicasFNAModel.Telefono1"
                                                min="0"
                                                value={this.state.caracteristicasFNAModel.Telefono1}
                                                onChange={this.encargarseCambio}
                                                placeholder="Teléfono 1"
                                                disabled
                                            />
                                        </div>
                                    ) : (
                                        <div className="col-md-4">
                                            <label>Teléfono 1</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                min="0"
                                                minLength="8"
                                                maxLength="8"
                                                name="caracteristicasFNAModel.Telefono1"
                                                value={this.state.caracteristicasFNAModel.Telefono1}
                                                onChange={this.encargarseCambio}
                                                placeholder="Teléfono 1"
                                                title="El Teléfono 1 debe contener 8 caracteres"
                                            />
                                        </div>
                                    )}
                                    {idFondo == REACT_APP_OPERADORA_FODEMIPYME && !this.state.indIrFNA ? (
                                        <div className="col-md-4">
                                            <label>Teléfono 2</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name="caracteristicasFNAModel.Telefono2"
                                                min="0"
                                                value={this.state.caracteristicasFNAModel.Telefono2}
                                                onChange={this.encargarseCambio}
                                                placeholder="Teléfono 2"
                                                disabled
                                            />
                                        </div>
                                    ) : (
                                        <div className="col-md-4">
                                            <label>Teléfono 2</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                min="0"
                                                minLength="8"
                                                maxLength="8"
                                                name="caracteristicasFNAModel.Telefono2"
                                                value={this.state.caracteristicasFNAModel.Telefono2}
                                                onChange={this.encargarseCambio}
                                                placeholder="Teléfono 2"
                                                title="El Teléfono 2 debe contener 8 caracteres"
                                            />
                                        </div>
                                    )}
                                    {idFondo == REACT_APP_OPERADORA_FODEMIPYME && !this.state.indIrFNA ? (
                                        <div className="col-md-4">
                                            <label>Teléfono 3</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name="caracteristicasFNAModel.Telefono3"
                                                min="0"
                                                value={this.state.caracteristicasFNAModel.Telefono3}
                                                onChange={this.encargarseCambio}
                                                placeholder="Teléfono 3"
                                                disabled
                                            />
                                        </div>
                                    ) : (
                                        <div className="col-md-4">
                                            <label>Teléfono 3</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                min="0"
                                                minLength="8"
                                                maxLength="8"
                                                name="caracteristicasFNAModel.Telefono3"
                                                value={this.state.caracteristicasFNAModel.Telefono3}
                                                onChange={this.encargarseCambio}
                                                placeholder="Teléfono 3"
                                                title="El Teléfono 3 debe contener 8 caracteres"
                                            />
                                        </div>
                                    )}
                                </div>
                                {idFondo == REACT_APP_OPERADORA_FODEMIPYME && !this.state.indIrFNA ? (
                                    <div className="row form-padding">
                                        <div className="col">
                                            <label>Correos Administrativos</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="caracteristicasFNAModel.CorreosAdministrativos"
                                                value={this.state.caracteristicasFNAModel.CorreosAdministrativos}
                                                onChange={this.encargarseCambio}
                                                placeholder="jhon@midominio.com;doe@midominio.com"
                                                disabled
                                                title="Los Correos Administrativos debe tener un formato correcto. Ejemplo: jhon@midominio.com;doe@midominio.com"
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row form-padding">
                                        <div className="col">
                                            <label>Correos Administrativos</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="caracteristicasFNAModel.CorreosAdministrativos"
                                                minLength="5"
                                                maxLength="100"
                                                value={this.state.caracteristicasFNAModel.CorreosAdministrativos}
                                                onChange={this.encargarseCambio}
                                                placeholder="jhon@midominio.com;doe@midominio.com"
                                                title="Los Correos Administrativos debe tener un formato correcto. Ejemplo: jhon@midominio.com;doe@midominio.com"
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="row form-padding">
                                    {idFondo == REACT_APP_OPERADORA_FODEMIPYME && !this.state.indIrFNA ? (
                                        <div className="col-md-6">
                                            <label>Representante</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="caracteristicasFNAModel.NombreRepresentante"
                                                value={this.state.caracteristicasFNAModel.NombreRepresentante}
                                                onChange={this.encargarseCambio}
                                                placeholder="Representante"
                                                disabled
                                            />
                                        </div>
                                    ) : (
                                        <div className="col-md-6">
                                            <label>Representante</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="caracteristicasFNAModel.NombreRepresentante"
                                                minLength="3"
                                                maxLength="100"
                                                value={this.state.caracteristicasFNAModel.NombreRepresentante}
                                                onChange={this.encargarseCambio}
                                                placeholder="Representante"
                                                title="Nombre del representante debe contener solo letras"
                                            />
                                        </div>
                                    )}
                                    {idFondo == REACT_APP_OPERADORA_FODEMIPYME && !this.state.indIrFNA ? (
                                        <div className="col-md-6">
                                            <label>Correo para remitir información</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                name="caracteristicasFNAModel.CorreoRemitirInformacion"
                                                value={this.state.caracteristicasFNAModel.CorreoRemitirInformacion}
                                                onChange={this.encargarseCambio}
                                                placeholder="jhon@midominio.com"
                                                disabled
                                            />
                                        </div>
                                    ) : (
                                        <div className="col-md-6">
                                            <label>Correo para remitir información</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                name="caracteristicasFNAModel.CorreoRemitirInformacion"
                                                minLength="5"
                                                maxLength="100"
                                                value={this.state.caracteristicasFNAModel.CorreoRemitirInformacion}
                                                onChange={this.encargarseCambio}
                                                placeholder="jhon@midominio.com"
                                                title="El Correo para remitir información debe tener un formato correcto. Ejemplo: jhon@midominio.com"
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="row form-padding">
                                    {idFondo == REACT_APP_OPERADORA_FODEMIPYME && !this.state.indIrFNA ? (
                                        <div className="col-md-6">
                                            <label>IBAN Colones</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="inputGroup-sizing-sm">
                                                        CR
                                                    </span>
                                                </div>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    name="caracteristicasFNAModel.IBANColones"
                                                    min="0"
                                                    value={this.state.caracteristicasFNAModel.IBANColones}
                                                    onChange={this.encargarseCambio}
                                                    placeholder="IBAN Colones"
                                                    title="Una cuenta IBAN tiene 20 dígitos"
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="col-md-6">
                                            <label>IBAN Colones</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="inputGroup-sizing-sm">
                                                        CR
                                                    </span>
                                                </div>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    min="0"
                                                    minLength="20"
                                                    maxLength="20"
                                                    name="caracteristicasFNAModel.IBANColones"
                                                    value={this.state.caracteristicasFNAModel.IBANColones}
                                                    onChange={this.encargarseCambio}
                                                    placeholder="IBAN Colones"
                                                    title="La cuenta IBAN en colones debe contener 20 dígitos"
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {idFondo == REACT_APP_OPERADORA_FODEMIPYME && !this.state.indIrFNA ? (
                                        <div className="col-md-6">
                                            <label>IBAN Dólares</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="inputGroup-sizing-sm">
                                                        CR
                                                    </span>
                                                </div>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    name="caracteristicasFNAModel.IBANDolares"
                                                    min="0"
                                                    value={this.state.caracteristicasFNAModel.IBANDolares}
                                                    onChange={this.encargarseCambio}
                                                    placeholder="IBAN Dólares"
                                                    title="Una cuenta IBAN en dólares debe contener 20 dígitos"
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="col-md-6">
                                            <label>IBAN Dólares</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="inputGroup-sizing-sm">
                                                        CR
                                                    </span>
                                                </div>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    min="0"
                                                    minLength="20"
                                                    maxLength="20"
                                                    name="caracteristicasFNAModel.IBANDolares"
                                                    value={this.state.caracteristicasFNAModel.IBANDolares}
                                                    onChange={this.encargarseCambio}
                                                    placeholder="IBAN Dólares"
                                                    title="Una cuenta IBAN en dólares debe contener 20 dígitos"
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <hr></hr>
                                <div className="row form-padding">
                                    <div className="col-md-3"></div>
                                    <div className="col-md-6">
                                        <label>Contacto en la Organización</label>
                                    </div>
                                    <div className="col-md-3"></div>
                                </div>
                                <div className="row form-padding">
                                    {idFondo == REACT_APP_OPERADORA_FODEMIPYME && !this.state.indIrFNA ? (
                                        <div className="col-md-6">
                                            <label>Nombre Operativo</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="caracteristicasFNAModel.NombreOperativo"
                                                value={this.state.caracteristicasFNAModel.NombreOperativo}
                                                onChange={this.encargarseCambio}
                                                placeholder="Nombre Operativo"
                                                disabled
                                                title="Nombre del Operativo debe contener solo letras"
                                            />
                                        </div>
                                    ) : (
                                        <div className="col-md-6">
                                            <label>Nombre Operativo</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="caracteristicasFNAModel.NombreOperativo"
                                                minLength="3"
                                                maxLength="100"
                                                value={this.state.caracteristicasFNAModel.NombreOperativo}
                                                onChange={this.encargarseCambio}
                                                placeholder="Nombre Operativo"
                                                title="Nombre del Operativo debe contener solo letras"
                                            />
                                        </div>
                                    )}
                                    {idFondo == REACT_APP_OPERADORA_FODEMIPYME && !this.state.indIrFNA ? (
                                        <div className="col-md-6">
                                            <label>Correo operativo</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                name="caracteristicasFNAModel.CorreoOperativo"
                                                value={this.state.caracteristicasFNAModel.CorreoOperativo}
                                                onChange={this.encargarseCambio}
                                                placeholder="operativo@midominio.com"
                                                disabled
                                            />
                                        </div>
                                    ) : (
                                        <div className="col-md-6">
                                            <label>Correo operativo</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                name="caracteristicasFNAModel.CorreoOperativo"
                                                minLength="5"
                                                maxLength="100"
                                                value={this.state.caracteristicasFNAModel.CorreoOperativo}
                                                onChange={this.encargarseCambio}
                                                placeholder="operativo@midominio.com"
                                                title="El Correo Operativo debe tener un formato válido"
                                            />
                                        </div>
                                    )}
                                </div>
                                {idFondo == REACT_APP_OPERADORA_FODEMIPYME && !this.state.indIrFNA ? (
                                    <div className="row form-padding">
                                        <div className="col-md-3"></div>
                                        <div className="col-md-6">
                                            <label>Teléfono Operativo</label>
                                            <input
                                                type="number"
                                                min="0"
                                                name="caracteristicasFNAModel.TelefonoOperativo"
                                                className="form-control"
                                                value={this.state.caracteristicasFNAModel.TelefonoOperativo}
                                                onChange={this.encargarseCambio}
                                                placeholder="Teléfono Operativo"
                                                disabled
                                            />
                                        </div>
                                        <div className="col-md-3"></div>
                                    </div>
                                ) : (
                                    <div className="row form-padding">
                                        <div className="col-md-3"></div>
                                        <div className="col-md-6">
                                            <label>Teléfono Operativo</label>
                                            <input
                                                type="number"
                                                min="0"
                                                minLength="8"
                                                maxLength="8"
                                                name="caracteristicasFNAModel.TelefonoOperativo"
                                                className="form-control"
                                                value={this.state.caracteristicasFNAModel.TelefonoOperativo}
                                                onChange={this.encargarseCambio}
                                                placeholder="Teléfono Operativo"
                                                title="El Teléfono Operativo debe contener 8 caracteres"
                                            />
                                        </div>
                                        <div className="col-md-3"></div>
                                    </div>
                                )}
                            </Tab>
                        </Tabs>
                        <button
                            type="submit"
                            disabled={this.state.indButton}
                            className="btn btn-primary border-radius"
                        >
                            <span
                                id="guardando"
                                className="spinner-grow spinner-grow-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            <span id="guardando" className="sr-only">
                                Loading...
                            </span>
                            Guardar
                            {" "}
                            <i className="fa fa-edit" aria-hidden="true"></i>
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}
export default CrearClienteComponent;
