import React from 'react'
import CrearClienteComponent from '../../Components/Cliente/CrearCliente'

const CrearClienteView = () => {
  return (
      <div>
        <CrearClienteComponent/>
      </div>
  )
}

export default CrearClienteView   