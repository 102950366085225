import {
    REACT_APP_CODIGO_RESPUESTA_401,
    REACT_APP_CODIGO_RESPUESTA_400
} from "../environment";
import {
    ObtenerUserAgent,
    ObtenerNombreUsuario,
} from "../Utils/BaseSolicitudModel";
import { BorrarLocalStorage } from "../Utils/BorrarInfoLocalStorage";
import swal from "sweetalert";

export const ObtenerParametro = async (parametro) => {
    try {
        const parametroRespuesta = await fetch(
            `/api/ObtenerParametro?parametro=${parametro}&fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (parametroRespuesta.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
            }).then(() => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const respuesta = {
            json: "",
            codigo: 0,
        };
        respuesta.json = await parametroRespuesta.json();
        respuesta.codigo = parametroRespuesta.status;

        return respuesta;
    } catch (error) {
        throw error;
    }
};

export const ModificarParametro = async (request) => {
    let userAgent = ObtenerUserAgent();
    let nombreUsuario = ObtenerNombreUsuario();
    try {
        var data = {
            IP: localStorage.getItem("ip"),
            UserAgent: userAgent,
            NombreUsuario: nombreUsuario,
            Latitud: localStorage.getItem("latitud"),
            Longitud: localStorage.getItem("longitud"),
            IdFondo: localStorage.getItem("idFondo"),
            Valor: request.Valor,
            Llave: request.Llave
        };
        const modificarParametro = await fetch(
            `/api/ModificarParametro?fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        if (modificarParametro.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
            }).then(() => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const respuesta = {
            json: "",
            codigo: 0,
        };
        if (modificarParametro.status == REACT_APP_CODIGO_RESPUESTA_400) respuesta.json = await modificarParametro.json();

        respuesta.codigo = modificarParametro.status;

        return respuesta;
    } catch (error) {
        throw error;
    }
}