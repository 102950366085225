import React from 'react'
import ProgramaCarteraPEComponent from '../../Components/ProgramaCarteraPE/ProgramaCarteraPE'
const ProgramaCarteraPEViewView = () => {
  return (
    <div>
      <ProgramaCarteraPEComponent/>
    </div>
  )
}

export default ProgramaCarteraPEViewView    