import React, { Component } from "react";
import {
    REACT_APP_ROL_ADMINISTRATIVO,
    REACT_APP_ROL_AUDITOR,
    REACT_APP_LISTA_MENU_BITACORA
} from "../../environment";

class MantenimientoAuditoriaComponent extends Component {
    componentDidMount() {
        const tokenContent = localStorage.getItem("isLoggedIn");
        if (!tokenContent) window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
    }
    render() {
        const idRol = localStorage.getItem("idRol");
        if (idRol) {
            if (idRol != REACT_APP_ROL_ADMINISTRATIVO && idRol != REACT_APP_ROL_AUDITOR)
                window.location.href = "/admin";
        } else {
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        }
        /**
         * Redirecciona a la vista de bitacora
         **/
        const irAuditoriaView = (url) => {
            window.location.href = url;
        }
        switch (idRol) {
            //REACT_APP_ROL_ADMINISTRATIVO
            case "1": {
                return (
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">
                                    Auditoría
                                </li>
                            </ol>
                        </nav>
                        <div className="row">
                            {REACT_APP_LISTA_MENU_BITACORA.map(function (data, key) {
                                return (
                                    <div key={key} className="col-lg-4 col-sm-6 mt-4">
                                        <div
                                            className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                            onClick={() => irAuditoriaView(data.Url)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="card-body">
                                                <i
                                                    className={data.Icon}
                                                    aria-hidden="true"
                                                    style={{ fontSize: "35px" }}
                                                ></i>
                                            </div>
                                            <div className="px-4 pb-2">
                                                <h5>{data.Titulo}</h5>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_AUDITOR
            case "9": {
                return (
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">
                                    Auditoría
                                </li>
                            </ol>
                        </nav>
                        <div className="row">
                            {REACT_APP_LISTA_MENU_BITACORA.map(function (data, key) {
                                return (
                                    <div key={key} className="col-lg-4 col-sm-6 mt-4">
                                        <div
                                            className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                            onClick={() => irAuditoriaView(data.Url)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div className="card-body">
                                                <i
                                                    className="fa fa-history"
                                                    aria-hidden="true"
                                                    style={{ fontSize: "35px" }}
                                                ></i>
                                            </div>
                                            <div className="px-4 pb-2">
                                                <h5>{data.Titulo}</h5>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            }
        }
    }
}

export default MantenimientoAuditoriaComponent;