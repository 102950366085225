import React from "react";
import ReporteImpactoCualitativoComponent from "../../Components/ReporteImpactoCualitativo/ReporteImpactoCualitativo";

const ReporteImpactoCualitativoView = () => {
  return (
    <div>
      <ReporteImpactoCualitativoComponent />
    </div>
  );
};

export default ReporteImpactoCualitativoView;
