import React, { Component } from "react";
import {
    REACT_APP_ROL_ADMINISTRATIVO,
    REACT_APP_LISTA_PARAMETROS_MODIFICAR
} from "../../environment";
class MantenimientoParametrosComponent extends Component {
    /**
    * Llamado inmediatamente después de montar un componente. 
    * Establecer el estado aquí activará la re-renderización.
    **/
    componentDidMount() {
        const tokenContent = localStorage.getItem("isLoggedIn");
        if (!tokenContent) window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        sessionStorage.removeItem("valorParametro");
    }
    render() {
        const idRol = localStorage.getItem("idRol");
        if (idRol) {
            if (idRol != REACT_APP_ROL_ADMINISTRATIVO)
                window.location.href = "/mantenimiento";
        } else {
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        }
        const irParametroView = (valor) => {
            sessionStorage.setItem("valorParametro", valor);
            window.location.href = "/parametro/editar";
        }
        switch (idRol) {
            case "1": {
                return (
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">
                                    <a href="/mantenimiento">Mantenimientos</a>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">
                                    Mantenimiento Par&aacute;metros
                                </li>
                            </ol>
                        </nav>
                        <div class="row">
                            {REACT_APP_LISTA_PARAMETROS_MODIFICAR.map(function (data, key) {
                                return (

                                    <div key={key} class="col-lg-4 col-sm-6 mt-4">
                                        <div
                                            class="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                            onClick={() => irParametroView(data.Llave)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div class="card-body">
                                                <i
                                                    class="fa fa-fw fa-unlock-alt"
                                                    style={{ fontSize: "35px" }}
                                                    aria-hidden="true"
                                                ></i>
                                            </div>
                                            <div class="px-4 pb-2">
                                                <h5>{data.Titulo}</h5>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            }
        }
    }
}
export default MantenimientoParametrosComponent;