import React, { Component } from "react";
import { ObtenerIp } from "../../Utils/BaseSolicitudModel";
import { ObtenerParametro } from "../../Services/ParametroService";
import swal from "sweetalert";
import {
    REACT_APP_CALCULAR_MORA_LEGAL,
    REACT_APP_CODIGO_RESPUESTA_200,
    REACT_APP_OBTENER_PORCENTAJE_MORA_LEGAL,
    REACT_APP_MOROSIDAD_DIA_HABIL
} from "../../environment";
class DashboardComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { usuarioLDAP: "" };
    }
    /**
     * Llamado inmediatamente después de montar un componente. 
     * Establecer el estado aquí activará la re-renderización.
     **/
    componentDidMount() {
        const usuarioLDAP = localStorage.getItem("usuarioLDAP");
        const fondoSeleccionado = JSON.parse(localStorage.getItem("fondoSeleccionado"));
        localStorage.removeItem("calcularMoralLegal");
        localStorage.removeItem("porcentajeMoralLegal");
        ObtenerIp();
        this.obtenerMoraLegal();
        this.obtenerMorosidadDiaHabil();
        this.setState({ usuarioLDAP: usuarioLDAP });
    }
    /**
     * Obtener parametro dia_habil
     */
    obtenerMorosidadDiaHabil = () => {
        ObtenerParametro(REACT_APP_MOROSIDAD_DIA_HABIL).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Obtener Parámetro", "Ha ocurrido un error al obtener el parámetro para los días hábiles. Por favor recargue de nuevo la página o bien, comuníquese con el adminsitrador.", "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem("MOROSIDAD_DIA_HABIL", res.json.valor);
            }
        });
    }
    /**
     * Valida si se permite calcular la mora legal
     */
    obtenerMoraLegal = () => {
        ObtenerParametro(REACT_APP_CALCULAR_MORA_LEGAL).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Mora Legal", "Ha ocurrido un error al obtener el parámetro para calcular la mora legal. Por favor recargue de nuevo la página o bien, comuníquese con el adminsitrador.", "error");
                    return;
                }
                ObtenerParametro(REACT_APP_OBTENER_PORCENTAJE_MORA_LEGAL).then((res) => {
                    if (res) {
                        if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                            swal("Mora Legal", "Ha ocurrido un error al obtener el porcentaje máximo de mora legal permitido. Por favor recargue de nuevo la página o bien, comuníquese con el adminsitrador.", "error");
                            return;
                        }
                        localStorage.setItem("porcentajeMoralLegal", res.json.valor);
                    }
                });
                localStorage.setItem("calcularMoralLegal", res.json.valor);
            }
        });
    }
    /**
     * Redirecciona a la vista de mantenimientos
     **/
    irMantenimiento = () => {
        window.location.href = "/mantenimiento";
    };
    /**
     * Redirecciona a la vista de avales
     **/
    irAvales = () => {
        window.location.href = "/avales";
    };
    /**
     * Redirecciona a la vista de honramientos
     **/
    irHonramientos = () => {
        window.location.href = "/honramientos";
    };
    /**
     * Redirecciona a la vista de lineas de credito
     **/
    irLineasCredito = () => {
        window.location.href = "/lineas-creditos";
    };
    /**
     * Redirecciona a la vista de mantenimiento de reportes
     **/
    irReportes = () => {
        window.location.href = "/mantenimiento-reportes";
    };
    /**
     * Redirecciona a la vista de bitacora
     **/
    irAuditoria = () => {
        window.location.href = "/auditoria";
    };
    render() {
        const idRol = localStorage.getItem("idRol");
        if (!idRol) window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        switch (idRol) {
            //REACT_APP_ROL_ADMINISTRATIVO
            case "1": {
                return (
                    <div>
                        <div className="row">
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irAvales}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-file"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Avales</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irLineasCredito}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-credit-card-alt"
                                            aria-hidden="true"
                                            style={{ fontSize: "35px" }}
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Líneas de Créditos</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irHonramientos}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-file-text"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Honramientos</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irMantenimiento}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-wrench"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Mantenimientos</h5>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-sm-6 mt-4"
                                onClick={this.irReportes}
                                style={{ cursor: "pointer" }}
                            >
                                <div className="card card-menu text-center hover-shadow-lg hover-translate-y-n10">
                                    <div className="card-body">
                                        <i
                                            className="fa fa-bar-chart"
                                            aria-hidden="true"
                                            style={{ fontSize: "35px" }}
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Reportes</h5>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-sm-6 mt-4"
                                onClick={this.irAuditoria}
                                style={{ cursor: "pointer" }}
                            >
                                <div className="card card-menu text-center hover-shadow-lg hover-translate-y-n10">
                                    <div className="card-body">
                                        <i
                                            className="fa fa-lock"
                                            aria-hidden="true"
                                            style={{ fontSize: "35px" }}
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Auditoría</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_EJECUTIVO
            case "2": {
                return (
                    <div>
                        <div className="row">
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irAvales}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-file"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Avales</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irLineasCredito}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-credit-card-alt"
                                            aria-hidden="true"
                                            style={{ fontSize: "35px" }}
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Líneas de Créditos</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irHonramientos}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-file-text"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Honramientos</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irMantenimiento}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-wrench"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Mantenimientos</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_INCLUSION
            case "3": {
                return (
                    <div>
                        <div className="row">
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irAvales}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-file"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Avales</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irLineasCredito}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-credit-card-alt"
                                            aria-hidden="true"
                                            style={{ fontSize: "35px" }}
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Líneas de Créditos</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irHonramientos}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-file-text"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Honramientos</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irMantenimiento}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-wrench"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Mantenimientos</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_OPERATIVO
            case "4": {
                return (
                    <div>
                        <div className="row">
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irAvales}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-file"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Avales</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irLineasCredito}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-credit-card-alt"
                                            aria-hidden="true"
                                            style={{ fontSize: "35px" }}
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Líneas de Créditos</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irHonramientos}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-file-text"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Honramientos</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_JEFATURA
            case "5": {
                return (
                    <div>
                        <div className="row">
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irAvales}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-file"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Avales</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irLineasCredito}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-credit-card-alt"
                                            aria-hidden="true"
                                            style={{ fontSize: "35px" }}
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Líneas de Créditos</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irHonramientos}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-file-text"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Honramientos</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irMantenimiento}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-wrench"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Mantenimientos</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_CONTABILIDAD
            case "6": {
                return (
                    <div>
                        <div className="row">
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irAvales}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-file"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Avales</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irLineasCredito}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-credit-card-alt"
                                            aria-hidden="true"
                                            style={{ fontSize: "35px" }}
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Líneas de Créditos</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irHonramientos}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-file-text"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Honramientos</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irMantenimiento}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-wrench"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Mantenimientos</h5>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-sm-6 mt-4"
                                onClick={this.irReportes}
                                style={{ cursor: "pointer" }}
                            >
                                <div className="card card-menu text-center hover-shadow-lg hover-translate-y-n10">
                                    <div className="card-body">
                                        <i
                                            className="fa fa-bar-chart"
                                            aria-hidden="true"
                                            style={{ fontSize: "35px" }}
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Reportes</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_SEGUIMIENTO
            case "7": {
                return (
                    <div>
                        <div className="row">
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irAvales}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-file"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Avales</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irLineasCredito}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-credit-card-alt"
                                            aria-hidden="true"
                                            style={{ fontSize: "35px" }}
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Líneas de Créditos</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irHonramientos}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-file-text"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Honramientos</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irMantenimiento}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-wrench"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Mantenimientos</h5>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-sm-6 mt-4"
                                onClick={this.irReportes}
                                style={{ cursor: "pointer" }}
                            >
                                <div className="card card-menu text-center hover-shadow-lg hover-translate-y-n10">
                                    <div className="card-body">
                                        <i
                                            className="fa fa-bar-chart"
                                            aria-hidden="true"
                                            style={{ fontSize: "35px" }}
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Reportes</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_REPRESENTANTE_FODEMIPYME
            case "8": {
                return (
                    <div>
                        <div className="row">
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irAvales}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-file"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Avales</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irLineasCredito}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-credit-card-alt"
                                            aria-hidden="true"
                                            style={{ fontSize: "35px" }}
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Líneas de Créditos</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irHonramientos}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-file-text"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Honramientos</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irMantenimiento}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-wrench"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Mantenimientos</h5>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-sm-6 mt-4"
                                onClick={this.irReportes}
                                style={{ cursor: "pointer" }}
                            >
                                <div className="card card-menu text-center hover-shadow-lg hover-translate-y-n10">
                                    <div className="card-body">
                                        <i
                                            className="fa fa-bar-chart"
                                            aria-hidden="true"
                                            style={{ fontSize: "35px" }}
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Reportes</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_AUDITOR
            case "9": {
                return (
                    <div>
                        <div className="row">
                            <div className="col-lg-4 col-sm-6 mt-4">
                                <div
                                    className="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                                    onClick={this.irMantenimiento}
                                    style={{ cursor: "pointer" }}
                                >
                                    <div className="card-body">
                                        <i
                                            className="fa fa-wrench"
                                            style={{ fontSize: "35px" }}
                                            aria-hidden="true"
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Mantenimientos</h5>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="col-lg-4 col-sm-6 mt-4"
                                onClick={this.irAuditoria}
                                style={{ cursor: "pointer" }}
                            >
                                <div className="card card-menu text-center hover-shadow-lg hover-translate-y-n10">
                                    <div className="card-body">
                                        <i
                                            className="fa fa-lock"
                                            aria-hidden="true"
                                            style={{ fontSize: "35px" }}
                                        ></i>
                                    </div>
                                    <div className="px-4 pb-2">
                                        <h5>Auditoría</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }
}
export default DashboardComponent;