import React from "react";
import CrearAvalComponent from "../../Components/Aval/CrearAval";

const CrearAvalView = () => {
  return (
    <div>
      <CrearAvalComponent />
    </div>
  );
};

export default CrearAvalView;
