import React from 'react'
import EditarProgramaCarteraComponent from '../../Components/ProgramaCartera/EditarProgramaCartera'

const EditarProgramaCarteraView = () => {
  return (
      <div>
        <EditarProgramaCarteraComponent/>
      </div>
  )
}

export default EditarProgramaCarteraView   