export const BorrarLocalStorage = () => {
  localStorage.removeItem("id_token");
  localStorage.removeItem("latitud");
  localStorage.removeItem("longitud");
  localStorage.removeItem("usuarioLDAP");
  localStorage.removeItem("idRol");
  localStorage.removeItem("nombreRol");
  localStorage.removeItem("idOperadora");
  localStorage.removeItem("nombreOperadora");
  localStorage.removeItem("idUsuarioCreador");
  localStorage.removeItem("ip");
  localStorage.removeItem("monedaLista");
  localStorage.removeItem("tiposProgramasLista");
  localStorage.removeItem("tiposIdentificacionLista");
  localStorage.removeItem("sectorEconomicoLista");
  localStorage.removeItem("nivelesLista");
  localStorage.removeItem("tamanoEmpresaLista");
  localStorage.removeItem("tipoGarantiaAdicionalLista");
  localStorage.removeItem("fuenteReferenciaInformacionLista");
  localStorage.removeItem("ubicacionGeograficaLista");
  localStorage.removeItem("estadoCertificacionCCSSLista");
  localStorage.removeItem("estadoTributacionDirectaLista");
  localStorage.removeItem("riesgosTrabajoLista");
  localStorage.removeItem("tamanoPymePympaLista");
  localStorage.removeItem("programasIndividualesLista");
  localStorage.removeItem("codigosCIIULista");
  localStorage.removeItem("estadosLista");
  localStorage.removeItem("ejecutivoLista");
  localStorage.removeItem("estadosListaLineaCredito");
  localStorage.removeItem("idCentroEmpresarial");
  localStorage.removeItem("centrosEmpresarialesLista");
  localStorage.removeItem("provinciasLista");
  localStorage.removeItem("programasCarteraLista");
  localStorage.removeItem("programasCarteraPELista");
  localStorage.removeItem("administradoresFodemipymeLista");
  localStorage.removeItem("ejecutivosFodemipymeLista");
  localStorage.removeItem("estadoUsuarioLogueado");
  localStorage.removeItem("idEstadoUsuarioLogueado");
  localStorage.removeItem("tipoIdentificacionInicial");
  localStorage.removeItem("session_id");
  localStorage.removeItem("lineasCreditoDisponibleLista");
  localStorage.removeItem("lineasCreditoLista");
  localStorage.removeItem("idfondo");
  localStorage.removeItem("ambosFondos");
  localStorage.removeItem("fondosActivos");
  localStorage.removeItem("fnaTipoBeneficioLista");
  localStorage.removeItem("mejoraCondicionesLista");
  localStorage.removeItem("calcularMoralLegal");
  localStorage.removeItem("porcentajeMoralLegal");
  localStorage.removeItem("fondosAsignados");
  localStorage.removeItem("MOROSIDAD_DIA_HABIL");
  sessionStorage.removeItem("infoUsuarioEditar");
  sessionStorage.removeItem("infoClienteEditar");
  sessionStorage.removeItem("infoProgramaIndividualEditar");
  sessionStorage.removeItem("infoProgramaCarteraEditar");
  sessionStorage.removeItem("infoProgramaCarteraPerdidaEditar");
  sessionStorage.removeItem("infoAvalEditar");
  sessionStorage.removeItem("infoProgramaIndividualEditar");
  sessionStorage.removeItem("infoLineaCreditoEditar");
  sessionStorage.removeItem("idLineaCredito");
  sessionStorage.removeItem("nombreSolicitanteTemporal");
  sessionStorage.removeItem("numeroIdentificacionSolicitanteTemporal");
  sessionStorage.removeItem("tipoIdentificacionTemporal");
};
