import {
    REACT_APP_CODIGO_RESPUESTA_200,
    REACT_APP_CODIGO_RESPUESTA_401,
    REACT_APP_CODIGO_RESPUESTA_400
} from "../environment";
import {
    ObtenerUserAgent,
    ObtenerNombreUsuario,
} from "../Utils/BaseSolicitudModel";
import { BorrarLocalStorage } from "../Utils/BorrarInfoLocalStorage";
import swal from "sweetalert";
/**
 * Obtener el Fondo seleccionado en iniciar sesion
 **/
const fondoSeleccionado = JSON.parse(localStorage.getItem("fondoSeleccionado"));
export const CrearAval = async (request) => {
    let userAgent = ObtenerUserAgent();
    let nombreUsuario = ObtenerNombreUsuario();
    try {
        var data = {
            IP: localStorage.getItem("ip"),
            UserAgent: userAgent,
            NombreUsuario: nombreUsuario,
            Latitud: localStorage.getItem("latitud"),
            Longitud: localStorage.getItem("longitud"),
            IdFondo: localStorage.getItem("idFondo"),
            NumeroOperacion: request.NumeroOperacion,
            IdLineaCredito: request.IdLineaCredito,
            OficioCondicionPYMEPYMPA: request.OficioCondicionPYMEPYMPA,
            Naturaleza: request.Naturaleza,
            IdTipoPrograma: request.IdTipoPrograma,
            IdPrograma: request.IdPrograma,
            IdEntidadFinancieraAutorizada: request.IdEntidadFinancieraAutorizada,
            IdMoneda: request.IdMoneda,
            MontoCredito: request.MontoCredito,
            NombreSolicitante: request.NombreSolicitante,
            IdTipoIdentificacionSolicitante: request.IdTipoIdentificacionSolicitante,
            NumeroIdentificacionSolicitante: request.NumeroIdentificacionSolicitante,
            IdProvincia: request.IdProvincia,
            IdCanton: request.IdCanton,
            IdDistrito: request.IdDistrito,
            Direccion: request.Direccion,
            ActividadEconomica: request.ActividadEconomica,
            IdSectorEconomico: request.IdSectorEconomico,
            ClaseCIIU: request.ClaseCIIU,
            CodigoCIIU: request.CodigoCIIU,
            DivisionCIIU: request.DivisionCIIU,
            GrupoCIIU: request.GrupoCIIU,
            SeccionCIIU: request.SeccionCIIU,
            IdNivelPagoHistoricoSBD: request.IdNivelPagoHistoricoSBD,
            PuntajeFinalDeudorSBD: request.PuntajeFinalDeudorSBD,
            IdNivelPagoHistoricoCIC: request.IdNivelCIC,
            PuntajeFinalDeudorCIC: request.PuntajeCIC,
            IdNivelHistoricoPagoEntidad: request.IdNivelHistoricoPagoEntidad,
            IdTamanoEmpresa: request.IdTamanoEmpresa,
            IdTipoGarantia: request.IdTipoGarantia,
            AnnosExperienciaEmpresa: request.AnnosExperienciaEmpresa,
            ImpactoEmpleoMujeres: request.ImpactoEmpleoMujeres,
            ImpactoEmpleoHombres: request.ImpactoEmpleoHombres,
            TotalImpactoEmpleo: request.TotalImpactoEmpleo,
            PlanInversion: request.PlanInversion,
            TotalIngresoAnual: request.TotalIngresoAnual,
            TotalEgresoAnual: request.TotalEgresoAnual,
            MontoFlujoOperativo: request.MontoFlujoOperativo,
            IdFuenteReferenciaInformacionIngresos:
                request.IdFuenteReferenciaInformacionIngresos,
            IdFuenteReferenciaInformacionEgresos:
                request.IdFuenteReferenciaInformacionEgresos,
            CuotaMensualCreditoCIC: request.CuotaMensualCreditoCIC,
            CuotaMensualOtrosPrestamo: request.CuotaMensualOtrosPrestamo,
            TotalCuotasMensual: request.TotalCuotasMensual,
            MontoAnualCreditoCIC: request.MontoAnualCreditoCIC,
            MontoAnualOtrosPrestamos: request.MontoAnualOtrosPrestamos,
            MontoTotalCuotasAnuales: request.MontoTotalCuotasAnuales,
            Garantias: request.Garantias,
            MontoTotalGarantiasAportadasCliente:
                request.MontoTotalGarantiasAportadasCliente,
            PorcentajeTotalGarantiasAportadasSolicitante:
                request.PorcentajeTotalGarantiasAportadasSolicitante,
            MontoTotalAvalSolicitado: request.MontoTotalAvalSolicitado,
            PorcentajeTotalAvalSolicitado: request.PorcentajeTotalAvalSolicitado,
            TasaInteresAnual: request.TasaInteresAnual,
            PlazoCreditoMeses: request.PlazoCreditoMeses,
            FrecuenciaPeriodicaCuota: request.FrecuenciaPeriodicaCuota,
            CuotaPeriodicaNuevoCredito: request.CuotaPeriodicaNuevoCredito,
            CuotaAnualNuevoCredito: request.CuotaAnualNuevoCredito,
            IdEjecutivoCreador: request.IdEjecutivoCreador,
            IdEstadoCertificacionCCSS: request.IdEstadoCertificacionCCSS,
            FechaEmisionCertificacionCCSS: request.FechaEmisionCertificacionCCSS,
            IdEstadoTributacionDirecta: request.IdEstadoTributacionDirecta,
            IdRiesgosTrabajo: request.IdRiesgosTrabajo,
            FechaVencimientoCertificacionPymePympa:
                request.FechaVencimientoCertificacionPymePympa,
            IdTamanoPymePympa: request.IdTamanoPymePympa,
            ConsultaAbiertaCICSUGEF: request.ConsultaAbiertaCICSUGEF,
            Observaciones: request.Observaciones,
            IdCentroEmpresarial: request.IdCentroEmpresarial,
            NumeroOperacionBancaria: request.NumeroOperacionBancaria,
            NumeroSolicitudBancaria: request.NumeroSolicitudBancaria,
            TienePlazoBallon: request.TienePlazoBallon,
            PlazoBallonMeses: request.PlazoBallonMeses,
            //FNA
            IdTiposBeneficios: request.IdTiposBeneficios,
            IdMejoraCondicion: request.IdMejoraCondicion,
            DescripcionMejoraCondicion: request.DescripcionMejoraCondicion,
            DemostroAfectacionCOVID: request.DemostroAfectacionCOVID,
            RealizaActividadDentroDelPais: request.RealizaActividadDentroDelPais,
            CompromisoConservacionEmpleo: request.CompromisoConservacionEmpleo,
            BeneficiaroPerteneceConglomerado: request.BeneficiaroPerteneceConglomerado,
            MorosidadPrevioCOVIDMenor60: request.MorosidadPrevioCOVIDMenor60,
            AvalSustituyeGarantia: request.AvalSustituyeGarantia,
            ClienteConCapacidadPago: request.ClienteConCapacidadPago
        };
        let formData = new FormData();
        formData.append("Solicitud", JSON.stringify(data));
        formData.append(
            "ArchivoFotoCedulaPersonaFisica",
            request.NombreArchivoFotoCedulaPersonaFisica
        );
        formData.append(
            "ArchivoFotoCedulaPersonaJuridica",
            request.NombreArchivoFotoCedulaPersonaJuridica
        );
        formData.append(
            "ArchivoFotoCedulaRepresentantePersonaJuridica",
            request.NombreArchivoFotoCedulaRepresentantePersonaJuridica
        );
        formData.append(
            "ArchivoCertificacionCCSS",
            request.NombreArchivoCertificacionCCSS
        );
        formData.append(
            "ArchivoTributacionDirecta",
            request.NombreArchivoTributacionDirecta
        );
        formData.append(
            "ArchivoRiesgosTrabajo",
            request.NombreArchivoRiesgosTrabajo
        );
        formData.append(
            "ArchivoCertificacionPymePympa",
            request.NombreArchivoCertificacionPymePympa
        );
        formData.append("ArchivoInformeAvaluo", request.NombreArchivoInformeAvaluo);
        formData.append(
            "ArchivoFacturaProforma",
            request.NombreArchivoFacturaProforma
        );
        formData.append(
            "ArchivoExcluirGarantiaBienMuebleInmueble",
            request.NombreArchivoExcluirGarantiaBienMuebleInmueble
        );
        formData.append(
            "ArchivoEstudioRegistralBienesMuebles",
            request.NombreArchivoEstudioRegistralBienesMuebles
        );
        formData.append(
            "ArchivoActaAprobacionCredito",
            request.NombreArchivoActaAprobacionCredito
        );
        formData.append(
            "ArchivoCuadroComparativo",
            request.ArchivoCuadroComparativo
        );
        formData.append(
            "ArchivoCICSugef",
            request.NombreArchivoCICSugef
        );
        const respuesta = await fetch(
            `/api/CrearAval?fondo=${localStorage.getItem("idFondo")}`,
            {
                timeout: 60000,
                method: "POST",
                credentials: "include",
                body: formData,
            }
        );
        if (respuesta.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then((value) => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }

        if (respuesta.status != REACT_APP_CODIGO_RESPUESTA_200) {
            try {
                var respuestaJson = await respuesta.json();
                var textoError = respuesta.status == 500 ? `Si se ha presentado un error inesperado, por favor enviar el siguiente código de error al administrador de FODEMIPYME : ${respuestaJson.identificacionSolicitud}` : respuestaJson.mensaje;
                if (respuestaJson && respuestaJson.mensaje) {
                    swal({
                        title: fondoSeleccionado.nombreFondo,
                        text: textoError,
                        icon: "error",
                    });
                } else {
                    swal({
                        title: fondoSeleccionado.nombreFondo,
                        text: "Ha ocurrido un error al guardar el aval, no se obtuvo respuesta del servidor",
                        icon: "error",
                    });
                }
                return;
            } catch (e) {
                swal({
                    title: fondoSeleccionado.nombreFondo,
                    text: "Ha ocurrido un error al guardar el aval",
                    icon: "error",
                });
                throw e;
            }
        }
        return respuesta;
    } catch (error) {
        throw error;
    }
};
export const ModificarAval = async (request) => {
    let userAgent = ObtenerUserAgent();
    let nombreUsuario = ObtenerNombreUsuario();
    try {
        var data = {
            IP: localStorage.getItem("ip"),
            UserAgent: userAgent,
            NombreUsuario: nombreUsuario,
            Latitud: localStorage.getItem("latitud"),
            Longitud: localStorage.getItem("longitud"),
            IdFondo: localStorage.getItem("idFondo"),
            IdAval: request.Id,
            NumeroOperacion: request.NumeroOperacion,
            IdLineaCredito: request.IdLineaCredito,
            IdTipoPrograma: request.IdTipoPrograma,
            IdPrograma: request.IdPrograma,
            IdEntidadFinancieraAutorizada: request.IdEntidadFinancieraAutorizada,
            IdMoneda: request.IdMoneda,
            OficioCondicionPYMEPYMPA: request.OficioCondicionPYMEPYMPA,
            Naturaleza: request.Naturaleza,
            MontoCredito: request.MontoCredito,
            NombreSolicitante: request.NombreSolicitante,
            IdTipoIdentificacionSolicitante: request.IdTipoIdentificacionSolicitante,
            NumeroIdentificacionSolicitante: request.NumeroIdentificacionSolicitante,
            IdProvincia: request.IdProvincia,
            IdCanton: request.IdCanton,
            IdDistrito: request.IdDistrito,
            Direccion: request.Direccion,
            ActividadEconomica: request.ActividadEconomica,
            IdSectorEconomico: request.IdSectorEconomico,
            ClaseCIIU: request.ClaseCIIU,
            CodigoCIIU: request.CodigoCIIU,
            DivisionCIIU: request.DivisionCIIU,
            GrupoCIIU: request.GrupoCIIU,
            SeccionCIIU: request.SeccionCIIU,
            IdEstado: request.IdEstado,
            IdNivelPagoHistoricoSBD: request.IdNivelPagoHistoricoSBD,
            PuntajeFinalDeudorSBD: request.PuntajeFinalDeudorSBD,
            IdNivelPagoHistoricoCIC: request.IdNivelCIC,
            PuntajeFinalDeudorCIC: request.PuntajeCIC,
            IdNivelHistoricoPagoEntidad: request.IdNivelHistoricoPagoEntidad,
            IdTamanoEmpresa: request.IdTamanoEmpresa,
            IdTipoGarantia: request.IdTipoGarantia,
            AnnosExperienciaEmpresa: request.AnnosExperienciaEmpresa,
            ImpactoEmpleoMujeres: request.ImpactoEmpleoMujeres,
            ImpactoEmpleoHombres: request.ImpactoEmpleoHombres,
            TotalImpactoEmpleo: request.TotalImpactoEmpleo,
            PlanInversion: request.PlanInversion,
            TotalIngresoAnual: request.TotalIngresoAnual,
            TotalEgresoAnual: request.TotalEgresoAnual,
            MontoFlujoOperativo: request.MontoFlujoOperativo,
            IdFuenteReferenciaInformacionIngresos:
                request.IdFuenteReferenciaInformacionIngresos,
            IdFuenteReferenciaInformacionEgresos:
                request.IdFuenteReferenciaInformacionEgresos,
            CuotaMensualCreditoCIC: request.CuotaMensualCreditoCIC,
            CuotaMensualOtrosPrestamo: request.CuotaMensualOtrosPrestamo,
            TotalCuotasMensual: request.TotalCuotasMensual,
            MontoAnualCreditoCIC: request.MontoAnualCreditoCIC,
            MontoAnualOtrosPrestamos: request.MontoAnualOtrosPrestamos,
            MontoTotalCuotasAnuales: request.MontoTotalCuotasAnuales,
            Garantias: request.Garantias,
            MontoTotalGarantiasAportadasCliente:
                request.MontoTotalGarantiasAportadasCliente,
            PorcentajeTotalGarantiasAportadasSolicitante:
                request.PorcentajeTotalGarantiasAportadasSolicitante,
            MontoTotalAvalSolicitado: request.MontoTotalAvalSolicitado,
            PorcentajeTotalAvalSolicitado: request.PorcentajeTotalAvalSolicitado,
            TasaInteresAnual: request.TasaInteresAnual,
            PlazoCreditoMeses: request.PlazoCreditoMeses,
            FrecuenciaPeriodicaCuota: request.FrecuenciaPeriodicaCuota,
            CuotaPeriodicaNuevoCredito: request.CuotaPeriodicaNuevoCredito,
            CuotaAnualNuevoCredito: request.CuotaAnualNuevoCredito,
            IdEstadoCertificacionCCSS: request.IdEstadoCertificacionCCSS,
            FechaEmisionCertificacionCCSS: request.FechaEmisionCertificacionCCSS,
            IdEstadoTributacionDirecta: request.IdEstadoTributacionDirecta,
            IdRiesgosTrabajo: request.IdRiesgosTrabajo,
            FechaVencimientoCertificacionPymePympa:
                request.FechaVencimientoCertificacionPymePympa,
            IdTamanoPymePympa: request.IdTamanoPymePympa,
            ConsultaAbiertaCICSUGEF: request.ConsultaAbiertaCICSUGEF,
            IdEjecutivo: request.IdEjecutivo,
            TienePlazoBallon: request.TienePlazoBallon,
            PlazoBallonMeses: request.PlazoBallonMeses,
            ObservacionArregloPago: request.ObservacionArregloPago,
            //FNA
            IdTiposBeneficios: request.IdTiposBeneficios,
            IdMejoraCondicion: request.IdMejoraCondicion,
            DescripcionMejoraCondicion: request.DescripcionMejoraCondicion,
            DemostroAfectacionCOVID: request.DemostroAfectacionCOVID,
            RealizaActividadDentroDelPais: request.RealizaActividadDentroDelPais,
            CompromisoConservacionEmpleo: request.CompromisoConservacionEmpleo,
            BeneficiaroPerteneceConglomerado: request.BeneficiaroPerteneceConglomerado,
            MorosidadPrevioCOVIDMenor60: request.MorosidadPrevioCOVIDMenor60,
            AvalSustituyeGarantia: request.AvalSustituyeGarantia,
            ClienteConCapacidadPago: request.ClienteConCapacidadPago
        };
        let formData = new FormData();
        formData.append("Solicitud", JSON.stringify(data));
        formData.append(
            "ArchivoFotoCedulaPersonaFisica",
            request.NombreArchivoFotoCedulaPersonaFisica
        );
        formData.append(
            "ArchivoFotoCedulaPersonaJuridica",
            request.NombreArchivoFotoCedulaPersonaJuridica
        );
        formData.append(
            "ArchivoFotoCedulaRepresentantePersonaJuridica",
            request.NombreArchivoFotoCedulaRepresentantePersonaJuridica
        );
        formData.append(
            "ArchivoCertificacionCCSS",
            request.NombreArchivoCertificacionCCSS
        );
        formData.append(
            "ArchivoTributacionDirecta",
            request.NombreArchivoTributacionDirecta
        );
        formData.append(
            "ArchivoRiesgosTrabajo",
            request.NombreArchivoRiesgosTrabajo
        );
        formData.append(
            "ArchivoCertificacionPymePympa",
            request.NombreArchivoCertificacionPymePympa
        );
        formData.append("ArchivoInformeAvaluo", request.NombreArchivoInformeAvaluo);
        formData.append(
            "ArchivoFacturaProforma",
            request.NombreArchivoFacturaProforma
        );
        formData.append(
            "ArchivoExcluirGarantiaBienMuebleInmueble",
            request.NombreArchivoExcluirGarantiaBienMuebleInmueble
        );
        formData.append(
            "ArchivoEstudioRegistralBienesMuebles",
            request.NombreArchivoEstudioRegistralBienesMuebles
        );
        formData.append(
            "ArchivoActaAprobacionCredito",
            request.NombreArchivoActaAprobacionCredito
        );
        formData.append("ArchivoContrato", request.NombreArchivoContrato);
        formData.append("ArchivoPagare", request.NombreArchivoPagare);
        formData.append(
            "ArchivoComprobantePagoComisionFormalizacion",
            request.NombreArchivoComprobantePagoComisionFormalizacion
        );
        formData.append(
            "ArchivoCuadroComparativo",
            request.ArchivoCuadroComparativo
        );
        formData.append(
            "ArchivoCICSugef",
            request.NombreArchivoCICSugef
        );
        const respuesta = await fetch(
            `/api/ModificarAval?fondo=${localStorage.getItem("idFondo")}`,
            {
                timeout: 60000,
                method: "POST",
                credentials: "include",
                body: formData,
            }
        );
        if (respuesta.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then((value) => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        if (respuesta.status != REACT_APP_CODIGO_RESPUESTA_200) {
            try {
                var respuestaJson = await respuesta.json();
                var textoError = respuesta.status == 500 ? `Si se ha presentado un error inesperado, por favor enviar el siguiente código de error al administrador de FODEMIPYME : ${respuestaJson.identificacionSolicitud}` : respuestaJson.mensaje;
                if (respuestaJson && respuestaJson.mensaje) {
                    swal({
                        title: fondoSeleccionado.nombreFondo,
                        text: textoError,
                        icon: "error",
                    });
                } else {
                    swal({
                        title: fondoSeleccionado.nombreFondo,
                        text: "Ha ocurrido un error al guardar el aval, no se obtuvo respuesta del servidor",
                        icon: "error",
                    });
                }
                return;
            } catch (e) {
                swal({
                    title: fondoSeleccionado.nombreFondo,
                    text: "Ha ocurrido un error al guardar el aval",
                    icon: "error",
                });
                throw e;
            }
        }
        return respuesta;
    } catch (error) {
        throw error;
    }
};
export const obtenerProvincias = async () => {
    try {
        const respuesta = await fetch(
            `/api/ObtenerProvincias?fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (respuesta.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then((value) => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const res = {
            json: "",
            codigo: 0,
        };

        res.json = await respuesta.json();
        res.codigo = respuesta.status;
        return res;
    } catch (error) {
        throw error;
    }
};
export const ObtenerCodigosCIIU = async () => {
    try {
        const respuesta = await fetch(
            `/api/ObtenerCodigosCIIU?fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (respuesta.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then((value) => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const res = {
            json: "",
            codigo: 0,
        };

        res.json = await respuesta.json();
        res.codigo = respuesta.status;
        return res;
    } catch (error) {
        throw error;
    }
};
export const DescargarArchivo = async (nombreArchivo) => {
    try {
        const respuesta = await fetch(
            `/api/ObtenerArchivo?nombreArchivo=${nombreArchivo}&fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        if (respuesta.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then((value) => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const res = {
            json: "",
            codigo: 0,
        };

        res.json = await respuesta.text();
        window.location.assign(res.json);
        res.codigo = respuesta.status;
        return res;
    } catch (error) {
        throw error;
    }
};
export const CargaMensualAval = async (request) => {
    let userAgent = ObtenerUserAgent();
    let nombreUsuario = ObtenerNombreUsuario();
    try {
        var data = {
            IP: localStorage.getItem("ip"),
            UserAgent: userAgent,
            NombreUsuario: nombreUsuario,
            Latitud: localStorage.getItem("latitud"),
            Longitud: localStorage.getItem("longitud"),
            IdFondo: localStorage.getItem("idFondo"),
            IdEntidadFinanciera: request.IdEntidadFinanciera,
            IdEjecutivo: request.IdEjecutivo,
        };
        let formData = new FormData();
        formData.append("Solicitud", JSON.stringify(data));
        formData.append("ArchivoExcel", request.ArchivoExcel);

        const cargaMasiva = await fetch(
            `/api/CargaReporteMensual?fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "POST",
                credentials: "include",
                body: formData,
            }
        );
        if (cargaMasiva.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then((value) => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const respuesta = {
            json: "",
            codigo: 0,
        };
        respuesta.json = await cargaMasiva.json();
        respuesta.codigo = cargaMasiva.status;

        return respuesta;
    } catch (error) {
        throw error;
    }
};
export const CargaMensualIndividualAval = async (request) => {
    let userAgent = ObtenerUserAgent();
    let nombreUsuario = ObtenerNombreUsuario();
    try {
        var data = {
            IP: localStorage.getItem("ip"),
            UserAgent: userAgent,
            NombreUsuario: nombreUsuario,
            Latitud: localStorage.getItem("latitud"),
            Longitud: localStorage.getItem("longitud"),
            IdFondo: localStorage.getItem("idFondo"),
            IdEntidadFinanciera: request.IdEntidadFinanciera,
            IdEjecutivo: request.IdEjecutivo,
            NumeroOperacion: request.NumeroOperacion,
            IdPrograma: request.IdPrograma,
            IndPagoTotal: request.IndPagoTotal
        };

        const modificarAval = await fetch(
            `/api/CargaReporteManual?fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        if (modificarAval.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then((value) => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const respuesta = {
            json: "",
            codigo: 0,
        };
        respuesta.json = await modificarAval.json();
        respuesta.codigo = modificarAval.status;

        return respuesta;
    } catch (error) {
        throw error;
    }
};
export const MoraLegal = async (request) => {
    try {

        const moraLegal = await fetch(
            `/api/ObtenerMoraLegal?IdEntidadFinanciera=${request.IdEntidadFinanciera}&fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                }
            }
        );
        if (moraLegal.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then((value) => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }

        const respuesta = {
            json: "",
            codigo: 0,
        };
        respuesta.json = await moraLegal.json();
        respuesta.codigo = moraLegal.status;

        return respuesta;
    } catch (error) {
        throw error;
    }
};
export const CalcularMoraLegal = async (parametro) => {
    try {

        const moraLegal = await fetch(
            `/api/ObtenerParametro?parametro=${parametro}&fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (moraLegal.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then((value) => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const respuesta = {
            json: "",
            codigo: 0,
        };
        respuesta.json = await moraLegal.json();
        respuesta.codigo = moraLegal.status;

        return respuesta;
    } catch (error) {
        throw error;
    }
};
export const ServicioWSRiesgoFodemipyme = async (request) => {
    let ip = localStorage.getItem("ip");
    let userAgent = ObtenerUserAgent();
    let nombreUsuario = ObtenerNombreUsuario();
    let latitud = localStorage.getItem("latitud");
    let longitud = localStorage.getItem("longitud");
    try {
        var data = {
            IP: ip,
            UserAgent: userAgent,
            NombreUsuario: nombreUsuario,
            Latitud: latitud,
            Longitud: longitud,
            ClaseCIIU: request.ClaseCIIU,
            CodigoCIIU: request.CodigoCIIU,
            DivisionCIIU: request.DivisionCIIU,
            GrupoCIIU: request.GrupoCIIU,
            SeccionCIIU: request.SeccionCIIU,
            AnnosExperienciaEmpresa: request.AnnosExperienciaEmpresa,
            CuotaAnualNuevoCredito: request.CuotaAnualNuevoCredito,
            MontoFlujoOperativo: request.MontoFlujoOperativo,
            MontoTotalCuotasAnuales: request.MontoTotalCuotasAnuales,
            IdNivelPagoHistoricoCIC: request.IdNivelPagoHistoricoCIC,
            PuntajeFinalDeudorCIC: request.PuntajeFinalDeudorCIC,
            IdNivelPagoHistoricoSBD: request.IdNivelPagoHistoricoSBD,
            IdNivelHistoricoPagoEntidad: request.IdNivelHistoricoPagoEntidad,
            NumeroIdentificacionSolicitante: request.NumeroIdentificacionSolicitante,
            OficioCondicionPYMEPYMPA: request.OficioCondicionPYMEPYMPA,
            IdTipoIdentificacionSolicitante: request.IdTipoIdentificacionSolicitante,
            PuntajeFinalDeudorSBD: request.PuntajeFinalDeudorSBD,
            IdTamanoEmpresa: request.IdTamanoEmpresa,
            IdTipoGarantia: request.IdTipoGarantia,
            PorcentajeTotalAvalSolicitado: request.PorcentajeTotalAvalSolicitado,
            NombreOperadora: request.NombreOperadora,
        };

        const wsRiesgo = await fetch(
            `/api/ServicioWSRiesgoFodemipyme?fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        if (wsRiesgo.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then((value) => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const respuesta = {
            json: "",
            codigo: 0,
        };
        respuesta.json = await wsRiesgo.json();
        respuesta.codigo = wsRiesgo.status;

        return respuesta;
    } catch (error) {
        throw error;
    }
};
export const ObtenerObservacionesXAval = async (request) => {
    try {
        const respuesta = await fetch(
            `/api/ObtenerObservacionesXAval?IdAval=${request.IdAval}&fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (respuesta.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then((value) => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const res = {
            json: "",
            codigo: 0,
        };

        res.json = await respuesta.json();
        res.codigo = respuesta.status;
        return res;
    } catch (error) {
        throw error;
    }
};
export const AgregarObservacion = async (request) => {
    let userAgent = ObtenerUserAgent();
    let nombreUsuario = ObtenerNombreUsuario();

    try {
        var data = {
            IP: localStorage.getItem("ip"),
            UserAgent: userAgent,
            NombreUsuario: nombreUsuario,
            Latitud: localStorage.getItem("latitud"),
            Longitud: localStorage.getItem("longitud"),
            IdAval: request.IdAval,
            IdEjecutivo: request.IdEjecutivo,
            Observacion: request.Observacion,
        };
        const respuesta = await fetch(
            `/api/AgregarObservacion?fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        if (respuesta.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then((value) => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const res = {
            json: "",
            codigo: 0,
        };

        res.json = await respuesta.json();
        res.codigo = respuesta.status;
        return res;
    } catch (error) {
        throw error;
    }
};
export const EditarObservacion = async (request) => {
    let userAgent = ObtenerUserAgent();
    let nombreUsuario = ObtenerNombreUsuario();

    try {
        var data = {
            IP: localStorage.getItem("ip"),
            UserAgent: userAgent,
            NombreUsuario: nombreUsuario,
            Latitud: localStorage.getItem("latitud"),
            Longitud: localStorage.getItem("longitud"),
            Id: request.Id,
            IdAval: request.IdAval,
            IdEjecutivo: request.IdEjecutivo,
            Observacion: request.Observacion,
        };
        const respuesta = await fetch(
            `/api/EditarObservacion?fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        if (respuesta.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then((value) => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const res = {
            json: "",
            codigo: 0,
        };

        res.json = await respuesta.json();
        res.codigo = respuesta.status;
        return res;
    } catch (error) {
        throw error;
    }
};
export const ObtenerUsuarioPorID = async (request) => {
    var data = {
        IdUsuario: request.IdUsuario,
        IdFondo: localStorage.getItem("idFondo")
    };
    try {
        const obtenerInformcion = await fetch(
            `/api/ObtenerUsuarioPorID?fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        if (obtenerInformcion.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then((value) => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const respuesta = {
            json: "",
            codigo: 0,
        };

        respuesta.json = await obtenerInformcion.json();
        respuesta.codigo = obtenerInformcion.status;

        return respuesta;
    } catch (error) {
        throw error;
    }
};
export const ListarEjecutivosFodemipyme = async () => {
    try {
        const respuesta = await fetch(
            `/api/ListarEjecutivosFondo?fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (respuesta.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then((value) => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const res = {
            json: "",
            codigo: 0,
        };

        res.json = await respuesta.json();
        res.codigo = respuesta.status;
        return res;
    } catch (error) {
        throw error;
    }
};
export const ListarAdministradoresFodemipyme = async () => {
    try {
        const respuesta = await fetch(
            `/api/ListarAdministradoresFondo?fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (respuesta.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then((value) => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const res = {
            json: "",
            codigo: 0,
        };

        res.json = await respuesta.json();
        res.codigo = respuesta.status;
        return res;
    } catch (error) {
        throw error;
    }
};
export const ActualizacionSaldosIndividual = async (request) => {
    let ip = localStorage.getItem("ip");
    let userAgent = ObtenerUserAgent();
    let nombreUsuario = ObtenerNombreUsuario();
    try {
        var data = {
            IP: ip,
            UserAgent: userAgent,
            NombreUsuario: nombreUsuario,
            Latitud: localStorage.getItem("latitud"),
            Longitud: localStorage.getItem("longitud"),
            IdFondo: localStorage.getItem("idFondo"),
            IdEntidadFinanciera: request.IdEntidadFinanciera,
            IdEjecutivo: request.IdEjecutivo,
            NumeroOperacion: request.NumeroOperacion,
            IdPrograma: request.IdPrograma,
            ComisionCuota: request.ComisionCuota,
            SaldoCredito: request.SaldoCredito,
            DiasAtraso: request.DiasAtraso
        };

        const modificarSaldoIndividual = await fetch(
            `/api/ActualizacionSaldosIndividual?fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        if (modificarSaldoIndividual.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then((value) => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const respuesta = {
            json: "",
            codigo: 0,
        };
        respuesta.json = await modificarSaldoIndividual.json();
        respuesta.codigo = modificarSaldoIndividual.status;

        return respuesta;
    } catch (error) {
        throw error;
    }
};
