import React from "react";
import swal from "sweetalert";
import {
    REACT_APP_OBTENER_ROLES_FODEMIPYME,
    REACT_APP_OBTENER_ROLES_OPERADORA,
    REACT_APP_CATALOGO_OBTENER_ESTADO,
    REACT_APP_CATALOGO_OBTENER_TIPO_IDENTIFICACION,
    REACT_APP_CEDULA_BP,
    REACT_APP_FONDO_ESTADO_ACTIVO,
    REACT_APP_CODIGO_RESPUESTA_200,
    REACT_APP_CODIGO_RESPUESTA_401,
    REACT_APP_OPERADORA_FODEMIPYME,
    REACT_APP_OPERADORA_FNA,
    REACT_APP_ROL_ADMINISTRATIVO,
    REACT_APP_ROL_CONTABILIDAD,
    REACT_APP_ROL_SEGUIMIENTO,
    REACT_APP_ROL_REPRESENTANTE_FODEMIPYME,
    REACT_APP_ROL_AUDITOR,
    REACT_APP_ESTADO_CLIENTE_ACTIVO,
    REACT_APP_ROL_EJECUTIVO,
    REACT_APP_IDENTIFICACION_USUARIO_FISICA_NACIONAL,
    REACT_APP_IDENTIFICACION_USUARIO_DIMEX
} from "../../environment";
import { GetGenerico, ObtenerCatalogo } from "../../Services/GenericoService";
import { ObtenerPersonaPadron } from "../../Services/PadronService";
import { BorrarLocalStorage } from "../../Utils/BorrarInfoLocalStorage";
import { ObtenerUserAgent } from "../../Utils/BaseSolicitudModel";
import {
    ModificarUsuario,
    ListarProvincias,
    ListarCentrosEmpresariales,
    ListarIngresosPorUsuario,
    ListarEventosUsuario
} from "../../Services/UsuarioService";
import {
    Tabs,
    Tab
} from "react-bootstrap";
const $ = require("jquery");
$.DataTable = require("datatables.net-bs4");
/**
 * Obtener el Fondo seleccionado en iniciar sesion
 **/
const fondoSeleccionado = JSON.parse(localStorage.getItem("fondoSeleccionado"));
/**
 * Obtener el Id del fondo en iniciar sesion
 **/
const idFondo = localStorage.getItem("idFondo");
class EditarUsuarioComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            clientes: [],
            clienteSeleccionado: "",
            roles: [],
            rolSeleccionado: "",
            clave: "",
            nombrePersona: "",
            apellidoPersona: "",
            cedulaPersona: "",
            correoElectronico: "",
            estados: [],
            estadoSeleccionado: "",
            tipoIdentificacionLista: [],
            tipoIdentificacionSeleccionado: {
                id: ""
            },
            indButton: false,
            provinciasLista: [],
            provinciaSeleccionada: "",
            centrosEmpresarialesLista: [],
            ccLista: [],
            centroEmpresarialSelecionado: "",
            idEmpresarialSelecionado: 0,
            estadoCivil: "",
            gradoAcademico: "",
            profesion: "",
            direccion: "",
            indicadorFODEMIPYME: null,
            indicadorFNA: null,
            fondosAgregados: [],
            fondosActivos: [],
            permisos: [],
            permisoEditar: "",
            dataFODEMIPYME: {
                IdEntidadFinancieraAutorizada: 1,
                IdEstado: 0,
                IdFondo: 1,
                IdRol: 0
            },
            dataFNA: {
                IdEntidadFinancieraAutorizada: 2,
                IdEstado: 0,
                IdFondo: 2,
                IdRol: 0
            },
            efaFODEActiva: true,
            efaFNAActiva: true,
            llave: "REGISTRO",
            indLog: false,
            indEventos: false
        };
    }
    modificarUsuario = (event) => {
        this.editarUsuario(event);
    };
    /**
     * Llamado inmediatamente después de montar un componente. 
     * Establecer el estado aquí activará la re-renderización.
     **/
    componentDidMount() {
        $("#guardando").hide();
        $("#spinRol").hide();
        $("#spinEstado").hide();
        $("#spinEFA").show();
        $("#spinNombre").hide();
        $("#spinApellido").hide();
        $("#mostrarCentroEmpresariales").hide();
        $("#mostrarBoton").hide();
        const tokenContent = localStorage.getItem("isLoggedIn");
        const idFondo = localStorage.getItem("idFondo");
        const infoEditar = sessionStorage.getItem("infoUsuarioEditar");
        const provinciasLista = localStorage.getItem("provinciasLista");
        if (!tokenContent) {
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        }
        if (!infoEditar) {
            window.location.href = "/usuarios";
        }
        const userAgent = ObtenerUserAgent();
        const fondosActivosLocal = localStorage.getItem("fondosActivos");
        const fondosActivosLocalJson = JSON.parse(fondosActivosLocal);
        this.state.fondosActivos = fondosActivosLocalJson;
        this.setState({ fondosActivos: fondosActivosLocalJson });

        this.state.fondosActivos.forEach((fondo) => {
            this.state.fondosAgregados.push(fondo.id);
        })

        const provinciasListaJson = JSON.parse(provinciasLista);
        const infoEditarJson = JSON.parse(infoEditar);
        if (infoEditarJson != null) {
            var inputIndicadorFODEMIPYME = document.querySelector(
                'input[id="indicadorFODEMIPYME"]'
            );
            var inputIndicadorFNA = document.querySelector(
                'input[id="indicadorFNA"]'
            );

            this.setState({ id: infoEditarJson.b2CId });
            this.setState({ estadoCivil: infoEditarJson.estadoCivil });
            this.setState({ direccion: infoEditarJson.direccion });
            this.setState({ gradoAcademico: infoEditarJson.gradoAcademico });
            this.setState({ profesion: infoEditarJson.profesion });
            this.setState({ nombrePersona: infoEditarJson.nombrePersona });
            this.setState({ apellidoPersona: infoEditarJson.apellidoPersona });
            this.setState({ cedulaPersona: infoEditarJson.cedulaPersona });
            this.setState({ correoElectronico: infoEditarJson.correoElectronico });

            var permiso = infoEditarJson.permisos.filter(function (permiso) {
                return (
                    permiso.fondo.id == idFondo
                );
            });

            this.state.permisoEditar = permiso[0];
            this.setState({ permisoEditar: permiso[0] });
            this.setState({ rolSeleccionado: this.state.permisoEditar.rol });
            this.setState({
                tipoIdentificacionSeleccionado:
                    this.state.permisoEditar.usuario.tipoIdentificacionUsuario,
            });
            this.setState({
                clienteSeleccionado: this.state.permisoEditar.entidadFinancieraAutorizada,
            });
            this.setState({ estadoSeleccionado: this.state.permisoEditar.estado });
            this.setState({
                idEmpresarialSelecionado: this.state.permisoEditar.usuario.idCentroEmpresarial,
            });
            this.state.idEmpresarialSelecionado = this.state.permisoEditar.usuario.idCentroEmpresarial;
            if (infoEditarJson.permisos.length == 1) {
                var permisosActivos = infoEditarJson.permisos.filter(function (permiso) {
                    return (
                        permiso.fondo.id == idFondo
                    );
                });
                if (permisosActivos[0].fondo.id == REACT_APP_OPERADORA_FODEMIPYME) {
                    this.state.dataFODEMIPYME.IdEntidadFinancieraAutorizada = permisosActivos[0].entidadFinancieraAutorizada.id;
                    this.state.dataFODEMIPYME.IdEstado = permisosActivos[0].estado.id;
                    this.state.dataFODEMIPYME.IdRol = permisosActivos[0].rol.id;

                    var obtenerEFAOperadoraFODEMIPYME = permisosActivos[0].entidadFinancieraAutorizada.caracteristicas.filter(function (efa) {
                        if (efa.fondo != null) {
                            return (
                                efa.fondo.id == REACT_APP_OPERADORA_FODEMIPYME
                            );
                        }
                    });

                    if (obtenerEFAOperadoraFODEMIPYME[0].estado.id == REACT_APP_ESTADO_CLIENTE_ACTIVO) {
                        this.state.permisos.push(this.state.dataFODEMIPYME);
                    } else {
                        this.state.efaFODEActiva = false;
                        this.setState({ efaFODEActiva: false });
                    }

                    inputIndicadorFODEMIPYME.checked =
                        true;
                    this.setState({
                        indicadorFODEMIPYME:
                            true,
                    });
                    inputIndicadorFNA.checked =
                        false;
                    this.setState({
                        indicadorFNA:
                            false,
                    });
                } else {
                    this.state.dataFNA.IdEntidadFinancieraAutorizada = permisosActivos[0].entidadFinancieraAutorizada.id;
                    this.state.dataFNA.IdEstado = permisosActivos[0].estado.id;
                    this.state.dataFNA.IdRol = permisosActivos[0].rol.id;

                    var obtenerEFAOperadoraFNA = permisosActivos[0].entidadFinancieraAutorizada.caracteristicas.filter(function (efa) {
                        if (efa.fondo != null) {
                            return (
                                efa.fondo.id == REACT_APP_OPERADORA_FNA
                            );
                        }
                    });

                    if (obtenerEFAOperadoraFNA[0].estado.id == REACT_APP_ESTADO_CLIENTE_ACTIVO) {
                        this.state.permisos.push(this.state.dataFNA);
                    } else {
                        this.state.efaFNAActiva = false;
                        this.setState({ efaFNAActiva: false });
                    }


                    inputIndicadorFODEMIPYME.checked =
                        false;
                    this.setState({
                        indicadorFODEMIPYME:
                            false,
                    });
                    inputIndicadorFNA.checked =
                        true;
                    this.setState({
                        indicadorFNA:
                            true,
                    });
                }
            } else if (infoEditarJson.permisos.length == 2) {
                //Validar permiso de FODEMIPYME
                let fondoFodemipyme = infoEditarJson.permisos.filter(function (usuario) {
                    return (
                        usuario.fondo.id == REACT_APP_OPERADORA_FODEMIPYME
                    );
                });
                this.state.dataFODEMIPYME.IdEntidadFinancieraAutorizada = fondoFodemipyme[0].entidadFinancieraAutorizada.id;
                this.state.dataFODEMIPYME.IdEstado = fondoFodemipyme[0].estado.id;
                this.state.dataFODEMIPYME.IdRol = fondoFodemipyme[0].rol.id;

                var obtenerEFAOperadoraFODEMIPYME = fondoFodemipyme[0].entidadFinancieraAutorizada.caracteristicas.filter(function (efa) {
                    if (efa.fondo != null) {
                        return (
                            efa.fondo.id == REACT_APP_OPERADORA_FODEMIPYME
                        );
                    }
                });
                this.state.permisos.push(this.state.dataFODEMIPYME);

                this.setState(this.state.dataFODEMIPYME);

                //Validar permiso de FNA
                let fondoFNA = infoEditarJson.permisos.filter(function (usuario) {
                    return (
                        usuario.fondo.id == REACT_APP_OPERADORA_FNA
                    );
                });

                this.state.dataFNA.IdEntidadFinancieraAutorizada = fondoFNA[0].entidadFinancieraAutorizada.id;
                this.state.dataFNA.IdEstado = fondoFNA[0].estado.id;
                this.state.dataFNA.IdRol = fondoFNA[0].rol.id;

                var obtenerEFAOperadoraFNA = fondoFNA[0].entidadFinancieraAutorizada.caracteristicas.filter(function (efa) {
                    if (efa.fondo != null) {
                        return (
                            efa.fondo.id == REACT_APP_OPERADORA_FNA
                        );
                    }
                });
                this.state.permisos.push(this.state.dataFNA);
                this.setState(this.state.dataFNA);

                inputIndicadorFODEMIPYME.checked =
                    true;
                this.setState({
                    indicadorFODEMIPYME:
                        true,
                });

                inputIndicadorFNA.checked =
                    true;
                this.setState({
                    indicadorFNA:
                        true,
                });
            }
            if (this.state.permisoEditar.rol.id == REACT_APP_ROL_EJECUTIVO && idFondo == REACT_APP_OPERADORA_FODEMIPYME) this.setState({ efaFNAActiva: false });
            if (this.state.permisoEditar.rol.id == REACT_APP_ROL_EJECUTIVO && idFondo == REACT_APP_OPERADORA_FODEMIPYME && this.state.permisos.filter((fondo) => fondo.IdFondo == REACT_APP_OPERADORA_FNA).length > 0) {
                let fondo = this.state.fondosActivos.filter((fondo) => fondo.id == REACT_APP_OPERADORA_FNA);
                var arregloPermisos = [];
                arregloPermisos = this.state.permisos;
                var index = arregloPermisos.findIndex(function (item, i) {
                    return item.IdFondo === fondo[0].efaFondo.id
                });
                arregloPermisos.splice(index, 1);
                this.state.permisos = arregloPermisos;
                this.setState({ permisos: arregloPermisos });
                this.setState({ efaFNAActiva: false });
                this.setState({ indicadorFNA: false });
            }

            if (this.state.permisoEditar.rol.id == REACT_APP_ROL_EJECUTIVO && idFondo == REACT_APP_OPERADORA_FNA) this.setState({ efaFODEActiva: false });
            if (this.state.permisoEditar.rol.id == REACT_APP_ROL_EJECUTIVO && idFondo == REACT_APP_OPERADORA_FNA && this.state.permisos.filter((fondo) => fondo.IdFondo == REACT_APP_OPERADORA_FODEMIPYME).length > 0) {
                let fondo = this.state.fondosActivos.filter((fondo) => fondo.id == REACT_APP_OPERADORA_FODEMIPYME);
                var arregloPermisos = [];
                arregloPermisos = this.state.permisos;
                var index = arregloPermisos.findIndex(function (item, i) {
                    return item.IdFondo === fondo[0].efaFondo.id
                });
                arregloPermisos.splice(index, 1);
                this.state.permisos = arregloPermisos;
                this.setState({ permisos: arregloPermisos });
                this.setState({ efaFODEActiva: false });
                this.setState({ indicadorFODEMIPYME: false });
            }

            if (
                this.state.permisoEditar.entidadFinancieraAutorizada.cedulaJuridica == REACT_APP_CEDULA_BP
            ) {
                if (provinciasListaJson == null) {
                    this.cargarProvinciaPrimeraVez();
                } else {
                    this.state.provinciasLista = provinciasListaJson;
                    this.setState({
                        provinciasLista: provinciasListaJson,
                        provinciaSeleccionada: provinciasListaJson[0],
                    });
                }
                $("#mostrarCentroEmpresariales").show();
                if (this.state.idEmpresarialSelecionado == null) {
                    this.cargarCentrosEmpresariales();
                } else {
                    this.cargarCentrosEmpresarialesPrimeraVez();
                }
            }
        }
        this.cargarEstado();
        this.cargarTiposIdentificacionUsuario();
        this.ListarIngresosPorUsuario(infoEditarJson.b2CId);
        this.ListarEventosUsuario(infoEditarJson.b2CId);
        $("#tabla1").DataTable({
            processing: true,
            serverSide: true,
            pageLength: 50,
            ajax: {
                url: `/api/ObtenerClientes?fondo=${localStorage.getItem("idFondo")}&ip=${localStorage.getItem("ip")}&latitud=${localStorage.getItem("latitud")}&longitud=${localStorage.getItem("longitud")}&userAgent=${userAgent}&usuario=${localStorage.getItem("idUsuarioCreador")}`,
                error: function (xhr, thrown) {
                    if (xhr.status === REACT_APP_CODIGO_RESPUESTA_401) {
                        swal({
                            title: "¡Sesión Expiró!",
                            text: "Por favor vuelva a iniciar sesión",
                            icon: "warning",
                        }).then(() => {
                            BorrarLocalStorage();
                            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                        });
                        return;
                    }
                    if (xhr.status !== REACT_APP_CODIGO_RESPUESTA_200) {
                        swal("Error al obtener los clientes", thrown, "Ha ocurrido un error al obtener los clientes.", { button: "Aceptar", });
                        $("#spinEFA").hide();
                        $("#mostrarBoton").hide();
                        return;
                    }
                },
                success: (data) => {
                    var filtroClientes = [];
                    var estadoActivo = 3;
                    var idClienteSeleccionado = this.state.clienteSeleccionado.id;
                    if (data != null) {
                        $("#mostrarBoton").show();
                        $("#spinEFA").hide();
                        var idFondo = localStorage.getItem("idFondo");
                        filtroClientes = data.data.filter(function (cliente) {
                            var caract = [];
                            caract = cliente.caracteristicas.filter(function (val) {
                                return (
                                    val.fondo.id == idFondo
                                );
                            });
                            return (
                                caract[0].estado.id == estadoActivo || caract[0].entidadFinancieraAutorizada.id == idClienteSeleccionado
                            );
                        });
                        this.setState({
                            clientes: filtroClientes,
                        });
                    }
                    this.listarRoles();
                },
                type: "POST",
            },
            columns: [{ data: "id", name: "id" }],
        });
    }
    ListarIngresosPorUsuario = (b2cid) => {
        let data = {
            Id: b2cid
        };
        this.setState({ indLog: true });
        ListarIngresosPorUsuario(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    this.setState({ indLog: false });
                    return;
                }
                $('#table1').DataTable({
                    language: {
                        url: "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json",
                    },
                    data: res.json,
                    columns: [
                        {
                            data: 'createdDateTime',
                            render: function (jsonDate) {
                                var date = new Date(jsonDate);
                                return date.toLocaleString();
                            },
                            name: "createdDateTime"
                        },
                        {
                            data: 'deviceDetail',
                            render: function (jsonDevice) {
                                var device = jsonDevice.browser + " - " + jsonDevice.operatingSystem;
                                return device;
                            },
                            name: "deviceDetail"
                        },
                        { data: 'ipAddress', name: "ipAddress" },
                        {
                            data: 'location',
                            render: function (jsonlocation) {
                                var location = jsonlocation.countryOrRegion + " - " + jsonlocation.city + " - Latitud: " + jsonlocation.geoCoordinates.latitude + " - Longitud: " + jsonlocation.geoCoordinates.longitude;
                                return location;
                            },
                            name: "location"
                        },
                        {
                            data: 'riskDetail',
                            render: function (jsonRiskDetail) {
                                var riskDetail = "";
                                switch (jsonRiskDetail) {
                                    case 0:
                                        riskDetail = "Ninguna";
                                        break;
                                    case 1:
                                        riskDetail = "Contraseña temporal generada por el administrador";
                                        break;
                                    case 2:
                                        riskDetail = "Cambio de contraseña seguro realizado por el usuario";
                                        break;
                                    case 3:
                                        riskDetail = "El usuario realizó un restablecimiento de contraseña seguro";
                                        break;
                                    case 4:
                                        riskDetail = "Administrador confirmado inicio de sesión seguro";
                                        break;
                                    case 5:
                                        riskDetail = "Ai confirmó inicio de sesión seguro";
                                        break;
                                    case 6:
                                        riskDetail = "El usuario pasó MFA impulsado por la política basada en riesgos";
                                        break;
                                    case 7:
                                        riskDetail = "Administrador descartó todo riesgo para el usuario";
                                        break;
                                    case 8:
                                        riskDetail = "Administrador de inicio de sesión confirmado comprometido";
                                        break;
                                    case 9:
                                        riskDetail = "Oculto";
                                        break;
                                    case 10:
                                        riskDetail = "Administrador usuario confirmado comprometido";
                                        break;
                                    case 11:
                                        riskDetail = "Valor futuro desconocido";
                                        break;
                                    case 12:
                                        riskDetail = "Detección de administrador descartado de m365D";
                                        break;
                                }
                                return riskDetail;
                            },
                            name: "riskDetail"
                        },
                        {
                            data: 'riskState',
                            render: function (jsonRiskState) {
                                var riskState = "";
                                switch (jsonRiskState) {
                                    case 0:
                                        riskState = "Ninguna";
                                        break;
                                    case 1:
                                        riskState = "ConfirmadoSeguro";
                                        break;
                                    case 2:
                                        riskState = "Remediado";
                                        break;
                                    case 3:
                                        riskState = "Despedido";
                                        break;
                                    case 4:
                                        riskState = "En riesgo";
                                        break;
                                    case 5:
                                        riskState = "ConfirmadoComprometido";
                                        break;
                                    case 6:
                                        riskState = "Valor futuro desconocido";
                                        break;
                                }
                                return riskState;
                            },
                            name: "riskState"
                        },
                    ]
                });
                this.setState({ indLog: false });
            }
        });
    };
    ListarEventosUsuario = (b2cid) => {
        let data = {
            Id: b2cid
        };
        this.setState({ indEventos: true });
        ListarEventosUsuario(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    this.setState({ indEventos: false });
                    return;
                }
                $('#table2').DataTable({
                    data: res.json,
                    language: {
                        url: "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json",
                    },
                    columns: [
                        { data: 'category', name: "category" },
                        {
                            data: 'activityDateTime',
                            render: function (jsonDate) {
                                var date = new Date(jsonDate);
                                return date.toLocaleString();
                            },
                            name: "activityDateTime"
                        },
                        { data: 'activityDisplayName', name: "activityDisplayName" }
                    ]
                });
                this.setState({ indEventos: false });
            }
        });
    }
    /**
     * Listar los centros empresariales
     **/
    cargarCentrosEmpresarialesPrimeraVez = () => {
        const centrosEmpresarialesLista = localStorage.getItem(
            "centrosEmpresarialesLista"
        );
        const centrosEmpresarialesListaJson = JSON.parse(centrosEmpresarialesLista);
        if (centrosEmpresarialesListaJson == null) {
            ListarCentrosEmpresariales().then((res) => {
                if (res) {
                    if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                        swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                        return;
                    }
                    localStorage.setItem(
                        "centrosEmpresarialesLista",
                        JSON.stringify(res.json.centrosEmpresariales)
                    );
                    this.state.centrosEmpresarialesLista = res.json.centrosEmpresariales;
                    this.setState({
                        centrosEmpresarialesLista: res.json.centrosEmpresariales,
                    });
                    let ceEmpresarial = this.state.centrosEmpresarialesLista.filter(
                        (cEmpresarial) =>
                            cEmpresarial.id == this.state.idEmpresarialSelecionado
                    );
                    this.state.centroEmpresarialSelecionado = ceEmpresarial[0];
                    this.setState({ centroEmpresarialSelecionado: ceEmpresarial[0] });
                    let provincia = this.state.provinciasLista.filter(
                        (provincia) => provincia.id == ceEmpresarial[0].provincia.id
                    );
                    this.setState({
                        provinciaSeleccionada: provincia[0],
                    });
                    this.state.provinciaSeleccionada = provincia[0];
                    let centroEmpresarial = this.state.centrosEmpresarialesLista.filter(
                        (cEmpresarial) => cEmpresarial.idProvincia == provincia[0].id
                    );
                    this.state.ccLista = centroEmpresarial;
                    this.setState({
                        ccLista: centroEmpresarial,
                    });
                }
            });
        } else {
            this.state.centrosEmpresarialesLista = centrosEmpresarialesListaJson;
            this.setState({
                centrosEmpresarialesLista: centrosEmpresarialesListaJson,
            });
            let ceEmpresarial = this.state.centrosEmpresarialesLista.filter(
                (cEmpresarial) => cEmpresarial.id == this.state.idEmpresarialSelecionado
            );
            this.state.centroEmpresarialSelecionado = ceEmpresarial[0];
            this.setState({ centroEmpresarialSelecionado: ceEmpresarial[0] });
            let provincia = this.state.provinciasLista.filter(
                (provincia) => provincia.id == ceEmpresarial[0].provincia.id
            );
            this.setState({
                provinciaSeleccionada: provincia[0],
            });
            this.state.provinciaSeleccionada = provincia[0];
            let centroEmpresarial = this.state.centrosEmpresarialesLista.filter(
                (cEmpresarial) => cEmpresarial.idProvincia == provincia[0].id
            );
            this.setState({
                ccLista: centroEmpresarial,
            });
        }
    };
    /**
     * Listar provincias
     **/
    cargarProvinciaPrimeraVez = () => {
        ListarProvincias().then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem(
                    "provinciasLista",
                    JSON.stringify(res.json.provincias)
                );
                this.state.provinciasLista = res.json.provincias;
                this.state.provinciaSeleccionada = this.state.provinciasLista[0];
                this.setState({
                    provinciasLista: res.json.provincias,
                    provinciaSeleccionada: res.json.provincias[0],
                });
                $("#mostrarCentroEmpresariales").show();
            }
        });
    };
    /**
     * Listar los provincias
     **/
    cargarProvincias = () => {
        ListarProvincias().then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem(
                    "provinciasLista",
                    JSON.stringify(res.json.provincias)
                );
                this.state.provinciasLista = res.json.provincias;
                this.state.provinciaSeleccionada = this.state.provinciasLista[0];
                this.setState({
                    provinciasLista: res.json.provincias,
                    provinciaSeleccionada: res.json.provincias[0],
                });
                $("#mostrarCentroEmpresariales").show();
                this.cargarCentrosEmpresariales();
            }
        });
    };
    /**
     * 
     * @param {*} event
     * @returns Listar roles 
     */
    listarRoles = (event) => {
        $("#spinRol").show();
        if (this.state.clienteSeleccionado.id === REACT_APP_OPERADORA_FODEMIPYME || this.state.clienteSeleccionado.id === REACT_APP_OPERADORA_FNA) {
            GetGenerico(REACT_APP_OBTENER_ROLES_FODEMIPYME).then(
                (res) => {
                    if (res) {
                        if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                            swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                            return;
                        }
                        this.setState({
                            roles: res.json.roles,
                        });
                        $("#spinRol").hide();
                    }
                }
            );
        } else {
            GetGenerico(REACT_APP_OBTENER_ROLES_OPERADORA).then(
                (res) => {
                    if (res) {
                        if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                            swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                            return;
                        }
                        this.setState({
                            roles: res.json.roles,
                        });
                        $("#spinRol").hide();
                    }
                }
            );
        }
    };
    /**
     * Listar estados
     */
    cargarEstado = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_ESTADO,
        };
        $("#spinEstado").show();
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    $("#spinEstado").hide();
                    return;
                }
                this.setState({
                    estados: res.json.catalogoValores,
                });
                $("#spinEstado").hide();
            }
        });
    };
    /**
     * Listar los tipos de identificacion
     */
    cargarTiposIdentificacionUsuario = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_TIPO_IDENTIFICACION,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                this.setState({
                    tipoIdentificacionLista: res.json.catalogoValores,
                });
            }
        });
    };
    /**
     * 
     * @param {*} event 
     * @returns Alamacena en el State lo digitado en el formulario. 
     **/
    encargarseCambio = (event) => {
        if (event.target.name === "indicadorFODEMIPYME") {
            if (!event.target.checked && !this.state.indicadorFNA) {
                swal(
                    "Editar Usuario",
                    "Al menos un fondo es requerido",
                    "warning", { button: "Aceptar", }
                );
                return;
            }

            let fondo = this.state.permisos.filter((fondo) => fondo.IdFondo == REACT_APP_OPERADORA_FODEMIPYME);
            if (fondo.length == 0 && event.target.checked) {
                this.state.dataFODEMIPYME.IdEntidadFinancieraAutorizada = this.state.clienteSeleccionado.id == REACT_APP_OPERADORA_FNA ? 1 : this.state.clienteSeleccionado.id;
                this.state.dataFODEMIPYME.IdEstado = this.state.estadoSeleccionado.id;
                this.state.dataFODEMIPYME.IdRol = this.state.rolSeleccionado.id;
                this.state.permisos.push(this.state.dataFODEMIPYME);
            } else if (fondo.length > 0 && !event.target.checked) {
                var arregloPermisos = [];
                arregloPermisos = this.state.permisos;
                var index = arregloPermisos.findIndex(function (item, i) {
                    return item.IdFondo === fondo[0].IdFondo
                });
                arregloPermisos.splice(index, 1);
                this.state.permisos = arregloPermisos;
                this.setState({ permisos: arregloPermisos });
            }

            this.setState({ [event.target.name]: event.target.checked });
            return;
        }
        if (event.target.name === "indicadorFNA") {
            if (!event.target.checked && !this.state.indicadorFODEMIPYME) {
                swal(
                    "Editar Usuario",
                    "Al menos un fondo es requerido",
                    "warning", { button: "Aceptar", }
                );
                return;
            }


            let fondo = this.state.permisos.filter((fondo) => fondo.IdFondo == 2);
            if (fondo.length == 0 && event.target.checked) {
                this.state.dataFNA.IdEntidadFinancieraAutorizada = this.state.clienteSeleccionado.id == REACT_APP_OPERADORA_FODEMIPYME ? 2 : this.state.clienteSeleccionado.id;
                this.state.dataFNA.IdEstado = this.state.estadoSeleccionado.id;
                this.state.dataFNA.IdRol = this.state.rolSeleccionado.id;
                this.state.permisos.push(this.state.dataFNA);
            } else if (fondo.length > 0 && !event.target.checked) {
                var arregloPermisos = [];
                arregloPermisos = this.state.permisos;
                var index = arregloPermisos.findIndex(function (item, i) {
                    return item.IdFondo === fondo[0].IdFondo
                });
                arregloPermisos.splice(index, 1);
                this.state.permisos = arregloPermisos;
                this.setState({ permisos: arregloPermisos });
            }

            this.setState({ [event.target.name]: event.target.checked });
            return;
        }
        if (event.target.name === "cedulaPersona" &&
            event.target.value.length == 9 &&
            this.state.tipoIdentificacionSeleccionado.id == REACT_APP_IDENTIFICACION_USUARIO_FISICA_NACIONAL) {
            this.buscarPersonaPadron(event.target.value);
        }
        this.setState({ [event.target.name]: event.target.value });
    };
    /**
     * 
     * @param {*} event 
     * @returns Buscar persona en el padron. 
     **/
    buscarPersonaPadron = (cedula) => {
        let data = {
            Cedula: cedula,
        };
        this.setState({ indButton: true });
        $("#spinNombre").show();
        $("#spinApellido").show();
        ObtenerPersonaPadron(data).then((res) => {
            if (res) {
                this.state.nombrePersona = res.nombre;
                this.setState({ nombrePersona: res.nombre });

                this.state.apellidoPersona = res.apellido1 + " " + res.apellido2;
                this.setState({ apellidoPersona: res.apellido1 + " " + res.apellido2 });
            } else {
                this.setState({ nombrePersona: "" });
                this.setState({ apellidoPersona: "" });
            }
            $("#spinNombre").hide();
            $("#spinApellido").hide();
            this.setState({ indButton: false });
        });
    };
    /**
     * Listar Centros Empresariales
     */
    cargarCentrosEmpresariales = () => {
        const centrosEmpresarialesLista = localStorage.getItem(
            "centrosEmpresarialesLista"
        );
        const centrosEmpresarialesListaJson = JSON.parse(centrosEmpresarialesLista);
        if (centrosEmpresarialesListaJson == null) {
            ListarCentrosEmpresariales().then((res) => {
                if (res) {
                    if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                        swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                        return;
                    }
                    localStorage.setItem(
                        "centrosEmpresarialesLista",
                        JSON.stringify(res.json.centrosEmpresariales)
                    );
                    this.state.centrosEmpresarialesLista = res.json.centrosEmpresariales;
                    this.setState({
                        centrosEmpresarialesLista: res.json.centrosEmpresariales,
                    });
                    let centroEmpresarial = this.state.centrosEmpresarialesLista.filter(
                        (cEmpresarial) =>
                            cEmpresarial.idProvincia == this.state.provinciasLista[0].id
                    );
                    this.setState({
                        ccLista: centroEmpresarial,
                    });
                }
            });
        } else {
            this.state.centrosEmpresarialesLista = centrosEmpresarialesListaJson;
            this.setState({
                centrosEmpresarialesLista: centrosEmpresarialesListaJson,
            });
            let centroEmpresarial = this.state.centrosEmpresarialesLista.filter(
                (cEmpresarial) =>
                    cEmpresarial.idProvincia == this.state.provinciasLista[0].id
            );
            this.setState({
                ccLista: centroEmpresarial,
                centroEmpresarialSelecionado: centroEmpresarial[0],
            });
        }
    };
    /**
     * 
     * @param {*} event
     * @returns Cambiar el centro empresarial seleccionado 
     */
    cambioCentroEmpresarial = (event) => {
        let centro = this.state.ccLista.filter(
            (centro) => centro.id == event.target.value
        );
        this.setState({ centroEmpresarialSelecionado: centro[0] });
    };
    /**
       * 
       * @param {*} event
       * @returns Cambiar la provincia seleccionada 
       */
    cambioProvincias = (event) => {
        let provincia = this.state.provinciasLista.filter(
            (provincia) => provincia.id == event.target.value
        );
        this.setState({ provinciaSeleccionada: provincia[0] });

        let centroEmpresarial = this.state.centrosEmpresarialesLista.filter(
            (cEmpresarial) => cEmpresarial.idProvincia == provincia[0].id
        );
        this.setState({
            ccLista: centroEmpresarial,
            centroEmpresarialSelecionado: centroEmpresarial[0],
        });
    };
    /**
       * 
       * @param {*} event
       * @returns Cambiar la operadora seleccionada 
       */
    cambioOperadora = (event) => {
        $("#spinRol").show();
        var clienteAnterior = this.state.clienteSeleccionado.id;
        let operadora = this.state.clientes.filter(
            (x) => x.id == event.target.value
        );
        //FODEMIPYME
        var obtenerEFAOperadoraFODEMIPYME = operadora[0].caracteristicas.filter(function (efa) {
            if (efa.fondo != null) {
                return (
                    efa.fondo.id == REACT_APP_OPERADORA_FODEMIPYME
                );
            }
        });

        var permisoFODEMIPYME = this.state.permisos.filter((fondo) => fondo.IdFondo == REACT_APP_OPERADORA_FODEMIPYME);

        if (obtenerEFAOperadoraFODEMIPYME.length > 0 && obtenerEFAOperadoraFODEMIPYME[0].estado.id == REACT_APP_ESTADO_CLIENTE_ACTIVO && permisoFODEMIPYME.length == 0 && this.state.indicadorFODEMIPYME) {
            this.state.dataFODEMIPYME.IdEntidadFinancieraAutorizada = operadora[0].id;
            this.state.permisos.push(this.state.dataFODEMIPYME);
            this.state.efaFODEActiva = true;
            this.setState({ efaFODEActiva: true });
        } else {
            if (this.state.indicadorFODEMIPYME && this.state.efaFODEActiva) {
                if (operadora[0].id == REACT_APP_OPERADORA_FODEMIPYME || operadora[0].id == REACT_APP_OPERADORA_FNA) {
                    this.state.permisos.filter((fondo) => fondo.IdFondo == REACT_APP_OPERADORA_FODEMIPYME)[0].IdEntidadFinancieraAutorizada = REACT_APP_OPERADORA_FODEMIPYME;
                } else {
                    this.state.permisos.filter((fondo) => fondo.IdFondo == REACT_APP_OPERADORA_FODEMIPYME)[0].IdEntidadFinancieraAutorizada = operadora[0].id;
                }
            }
        }

        //FNA
        var obtenerEFAOperadoraFNA = operadora[0].caracteristicas.filter(function (efa) {
            if (efa.fondo != null) {
                return (
                    efa.fondo.id == REACT_APP_OPERADORA_FNA
                );
            }
        });

        var permisoFNA = this.state.permisos.filter((fondo) => fondo.IdFondo == REACT_APP_OPERADORA_FNA);

        if (obtenerEFAOperadoraFNA.length > 0 && obtenerEFAOperadoraFNA[0].estado.id == REACT_APP_ESTADO_CLIENTE_ACTIVO && permisoFNA.length == 0 && this.state.indicadorFNA) {
            this.state.dataFNA.IdEntidadFinancieraAutorizada = operadora[0].id;
            this.state.permisos.push(this.state.dataFNA);
            this.state.efaFNAActiva = true;
            this.setState({ efaFNAActiva: true });
        } else {
            if (this.state.indicadorFNA && this.state.efaFNAActiva) {
                if (operadora[0].id == REACT_APP_OPERADORA_FODEMIPYME || operadora[0].id == REACT_APP_OPERADORA_FNA) {
                    this.state.permisos.filter((fondo) => fondo.IdFondo == REACT_APP_OPERADORA_FNA)[0].IdEntidadFinancieraAutorizada = REACT_APP_OPERADORA_FNA;
                } else {
                    this.state.permisos.filter((fondo) => fondo.IdFondo == REACT_APP_OPERADORA_FNA)[0].IdEntidadFinancieraAutorizada = operadora[0].id;
                }
            }
        }

        this.setState({ clienteSeleccionado: operadora[0] });

        if (operadora[0].cedulaJuridica == REACT_APP_CEDULA_BP) {
            const provinciasLista = localStorage.getItem("provinciasLista");
            const provinciasListaJson = JSON.parse(provinciasLista);
            if (provinciasListaJson == null) {
                this.cargarProvincias();
            } else {
                this.state.provinciasLista = provinciasListaJson;
                this.setState({
                    provinciasLista: provinciasListaJson,
                    provinciaSeleccionada: provinciasListaJson[0],
                });
                $("#mostrarCentroEmpresariales").show();
                this.cargarCentrosEmpresariales();
            }
        } else {
            $("#mostrarCentroEmpresariales").hide();
            this.setState({ ccLista: [], centroEmpresarialSelecionado: "" });
        }
        // validar fondo activo
        let fondo = this.state.fondosActivos.filter((fondo) => fondo.id == event.target.value);
        if (fondo.length > 0 && fondo[0].estado.id == REACT_APP_FONDO_ESTADO_ACTIVO) {
            GetGenerico(REACT_APP_OBTENER_ROLES_FODEMIPYME).then(
                (res) => {
                    if (res) {
                        if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                            swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                            return;
                        }
                        $("#spinRol").hide();
                        this.state.roles = res.json.roles;
                        if (this.state.clienteSeleccionado.id == REACT_APP_OPERADORA_FODEMIPYME || this.state.clienteSeleccionado.id == REACT_APP_OPERADORA_FNA) {

                            this.state.rolSeleccionado = res.json.roles[0];

                            if (this.state.indicadorFODEMIPYME && this.state.efaFODEActiva) this.state.permisos.filter((fondo) => fondo.IdFondo == REACT_APP_OPERADORA_FODEMIPYME)[0].IdRol = res.json.roles[0].id;

                            if (this.state.indicadorFNA && this.state.efaFNAActiva) this.state.permisos.filter((fondo) => fondo.IdFondo == REACT_APP_OPERADORA_FNA)[0].IdRol = res.json.roles[0].id;
                        }

                        if (!this.state.indicadorFNA) {
                            this.state.efaFNAActiva = true;
                            this.state.indicadorFNA = true;
                            let fondo = this.state.fondosActivos.filter((fondo) => fondo.id == REACT_APP_OPERADORA_FNA);
                            this.state.dataFNA.IdFondo = fondo[0].id;
                            this.state.dataFNA.IdRol = this.state.rolSeleccionado.id;
                            this.state.dataFNA.IdEntidadFinancieraAutorizada = this.state.clienteSeleccionado.id;
                            this.state.dataFNA.IdEstado = this.state.estadoSeleccionado.id;

                            this.state.permisos.push(this.state.dataFNA);
                        }

                        if (!this.state.indicadorFODEMIPYME) {
                            this.state.efaFODEActiva = true;
                            this.state.indicadorFODEMIPYME = true;
                            let fondo = this.state.fondosActivos.filter((fondo) => fondo.id == REACT_APP_OPERADORA_FODEMIPYME);
                            this.state.dataFODEMIPYME.IdFondo = fondo[0].id;
                            this.state.dataFODEMIPYME.IdRol = this.state.rolSeleccionado.id;
                            this.state.dataFODEMIPYME.IdEntidadFinancieraAutorizada = this.state.clienteSeleccionado.id;
                            this.state.dataFODEMIPYME.IdEstado = this.state.estadoSeleccionado.id;

                            this.state.permisos.push(this.state.dataFODEMIPYME);
                        }

                        this.setState({
                            roles: res.json.roles,
                        });
                    }
                }
            );
        } else {
            GetGenerico(REACT_APP_OBTENER_ROLES_OPERADORA).then(
                (res) => {
                    if (res) {
                        if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                            swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                            return;
                        }
                        $("#spinRol").hide();
                        this.state.roles = res.json.roles;
                        if (clienteAnterior == REACT_APP_OPERADORA_FODEMIPYME || clienteAnterior == REACT_APP_OPERADORA_FNA) {

                            this.state.rolSeleccionado = res.json.roles[0];

                            if (this.state.indicadorFODEMIPYME && this.state.efaFODEActiva) this.state.permisos.filter((fondo) => fondo.IdFondo == REACT_APP_OPERADORA_FODEMIPYME)[0].IdRol = res.json.roles[0].id;

                            if (this.state.indicadorFNA && this.state.efaFNAActiva) this.state.permisos.filter((fondo) => fondo.IdFondo == REACT_APP_OPERADORA_FNA)[0].IdRol = res.json.roles[0].id;

                            if (!this.state.indicadorFNA) {
                                this.state.efaFNAActiva = true;
                                this.state.indicadorFNA = true;
                                let fondo = this.state.fondosActivos.filter((fondo) => fondo.id == REACT_APP_OPERADORA_FNA);
                                this.state.dataFNA.IdFondo = fondo[0].id;
                                this.state.dataFNA.IdRol = this.state.rolSeleccionado.id;
                                this.state.dataFNA.IdEntidadFinancieraAutorizada = this.state.clienteSeleccionado.id;
                                this.state.dataFNA.IdEstado = this.state.estadoSeleccionado.id;

                                this.state.permisos.push(this.state.dataFNA);
                            }

                            if (!this.state.indicadorFODEMIPYME) {
                                this.state.efaFODEActiva = true;
                                this.state.indicadorFODEMIPYME = true;
                                let fondo = this.state.fondosActivos.filter((fondo) => fondo.id == REACT_APP_OPERADORA_FODEMIPYME);
                                this.state.dataFODEMIPYME.IdFondo = fondo[0].id;
                                this.state.dataFODEMIPYME.IdRol = this.state.rolSeleccionado.id;
                                this.state.dataFODEMIPYME.IdEntidadFinancieraAutorizada = this.state.clienteSeleccionado.id;
                                this.state.dataFODEMIPYME.IdEstado = this.state.estadoSeleccionado.id;

                                this.state.permisos.push(this.state.dataFODEMIPYME);
                            }

                        }
                        this.setState({
                            roles: res.json.roles,
                        });
                    }
                }
            );
        }
    };
    /**
     * 
     * @param {*} event
     * @returns Cambiar el rol seleccionado 
     */
    cambioRole = (event) => {
        var rolAnterior = this.state.rolSeleccionado.id;
        let rol = this.state.roles.filter((rol) => rol.id == event.target.value);
        this.setState({ rolSeleccionado: rol[0] });
        if (rol[0].id == REACT_APP_ROL_EJECUTIVO) {
            if (idFondo == REACT_APP_OPERADORA_FODEMIPYME) {
                if (!this.state.indicadorFODEMIPYME) {
                    let fondo = this.state.fondosActivos.filter((fondo) => fondo.id == REACT_APP_OPERADORA_FODEMIPYME);
                    this.state.dataFODEMIPYME.IdFondo = fondo[0].id;
                    this.state.dataFODEMIPYME.IdRol = rol[0].id;
                    this.state.dataFODEMIPYME.IdEntidadFinancieraAutorizada = this.state.clienteSeleccionado.id;
                    this.state.dataFODEMIPYME.IdEstado = this.state.estadoSeleccionado.id;

                    this.state.permisos.push(this.state.dataFODEMIPYME);
                    this.state.indicadorFODEMIPYME = true;
                    this.setState({ indicadorFODEMIPYME: true });
                }
                if (this.state.indicadorFNA) {
                    let fondo = this.state.fondosActivos.filter((fondo) => fondo.id == REACT_APP_OPERADORA_FNA);
                    var arregloPermisos = [];
                    arregloPermisos = this.state.permisos;
                    var index = arregloPermisos.findIndex(function (item, i) {
                        return item.IdFondo === fondo[0].efaFondo.id
                    });
                    arregloPermisos.splice(index, 1);
                    this.state.permisos = arregloPermisos;
                    this.setState({ permisos: arregloPermisos });
                    this.state.indicadorFNA = false;
                    this.setState({ indicadorFNA: false });
                    this.state.efaFNAActiva = false;
                    this.setState({ efaFNAActiva: false });
                }
            } else {
                this.setState({ efaFNAActiva: true });
            }

            if (idFondo == REACT_APP_OPERADORA_FNA) {
                this.setState({ efaFODEActiva: true });
                if (!this.state.indicadorFNA) {
                    let fondo = this.state.fondosActivos.filter((fondo) => fondo.id == REACT_APP_OPERADORA_FNA);
                    this.state.dataFNA.IdFondo = fondo[0].id;
                    this.state.dataFNA.IdRol = rol[0].id;
                    this.state.dataFNA.IdEntidadFinancieraAutorizada = this.state.clienteSeleccionado.id;
                    this.state.dataFNA.IdEstado = this.state.estadoSeleccionado.id;

                    this.state.permisos.push(this.state.dataFNA);
                    this.state.indicadorFNA = true;
                    this.setState({ indicadorFNA: true });
                }
                if (this.state.indicadorFODEMIPYME) {
                    let fondo = this.state.fondosActivos.filter((fondo) => fondo.id == REACT_APP_OPERADORA_FODEMIPYME);
                    var arregloPermisos = [];
                    arregloPermisos = this.state.permisos;
                    var index = arregloPermisos.findIndex(function (item, i) {
                        return item.IdFondo === fondo[0].efaFondo.id
                    });
                    arregloPermisos.splice(index, 1);
                    this.state.permisos = arregloPermisos;
                    this.setState({ permisos: arregloPermisos });
                    this.state.indicadorFODEMIPYME = false;
                    this.setState({ indicadorFODEMIPYME: false });
                    this.state.efaFODEActiva = false;
                    this.setState({ efaFODEActiva: false });
                }
            } else {
                this.setState({ efaFODEActiva: true });
            }
        } else {
            if (idFondo == REACT_APP_OPERADORA_FODEMIPYME && rolAnterior == REACT_APP_ROL_EJECUTIVO && !this.state.indicadorFNA) {
                let fondo = this.state.fondosActivos.filter((fondo) => fondo.id == REACT_APP_OPERADORA_FNA);
                this.state.dataFNA.IdFondo = fondo[0].id;
                this.state.dataFNA.IdRol = rol[0].id;
                this.state.dataFNA.IdEntidadFinancieraAutorizada = REACT_APP_OPERADORA_FNA;
                this.state.dataFNA.IdEstado = this.state.estadoSeleccionado.id;

                this.state.permisos.push(this.state.dataFNA);
                this.state.indicadorFNA = true;
                this.setState({ indicadorFNA: true });
                this.state.efaFNAActiva = true;
                this.setState({ efaFNAActiva: true });
            }

            if (idFondo == REACT_APP_OPERADORA_FNA && rolAnterior == REACT_APP_ROL_EJECUTIVO && !this.state.indicadorFODEMIPYME) {
                let fondo = this.state.fondosActivos.filter((fondo) => fondo.id == REACT_APP_OPERADORA_FODEMIPYME);
                this.state.dataFODEMIPYME.IdFondo = fondo[0].id;
                this.state.dataFODEMIPYME.IdRol = rol[0].id;
                this.state.dataFODEMIPYME.IdEntidadFinancieraAutorizada = REACT_APP_OPERADORA_FODEMIPYME;
                this.state.dataFODEMIPYME.IdEstado = this.state.estadoSeleccionado.id;

                this.state.permisos.push(this.state.dataFODEMIPYME);
                this.state.indicadorFODEMIPYME = true;
                this.setState({ indicadorFODEMIPYME: true });
                this.state.efaFODEActiva = true;
                this.setState({ efaFODEActiva: true });
            }
        }

        if (this.state.indicadorFODEMIPYME) {
            this.state.permisos.filter((fondo) => fondo.IdFondo == REACT_APP_OPERADORA_FODEMIPYME)[0].IdRol = rol[0].id;
        }
        if (this.state.indicadorFNA) {
            this.state.permisos.filter((fondo) => fondo.IdFondo == REACT_APP_OPERADORA_FNA)[0].IdRol = rol[0].id;
        }
    };
    /**
     * 
     * @param {*} event
     * @returns Cambiar el estado seleccionado 
     */
    cambioEstado = (event) => {
        let estado = this.state.estados.filter((x) => x.id == event.target.value);

        if (this.state.indicadorFODEMIPYME && this.state.efaFODEActiva) this.state.permisos.filter((fondo) => fondo.IdFondo == REACT_APP_OPERADORA_FODEMIPYME)[0].IdEstado = estado[0].id;

        if (this.state.indicadorFNA && this.state.efaFNAActiva) this.state.permisos.filter((fondo) => fondo.IdFondo == REACT_APP_OPERADORA_FNA)[0].IdEstado = estado[0].id;

        this.setState({ estadoSeleccionado: estado[0] });
    };
    /**
     * 
     * @param {*} event
     * @returns Cambiar el tipo de identificacion seleccionado 
     */
    cambioTipoIdentificacion = (event) => {
        let tipoIdentificacion = this.state.tipoIdentificacionLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ tipoIdentificacionSeleccionado: tipoIdentificacion[0] });
        this.setState({ nombrePersona: "" });
        this.setState({ apellidoPersona: "" });
        this.setState({ cedulaPersona: "" });
    };
    /**
       * 
       * @param {*} k 
       * @returns El tab seleccionado
       */
    cambiarTab = (k) => {
        this.setState({ llave: k });
    };
    /**
     * 
     * @param {*} event 
     * @returns Modificar un usuario
     */
    editarUsuario = (event) => {
        event.preventDefault();
        $("#guardando").show();
        const letras = new RegExp(/^[a-zA-ZáéíñóúüÁÉÍÑÓÚÜ ]*$/);
        const correo = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const correoValido = correo.test(this.state.correoElectronico);
        const nombreValido = letras.test(this.state.nombrePersona);
        const apellidoValido = letras.test(this.state.apellidoPersona);

        if (!this.state.indicadorFODEMIPYME && !this.state.indicadorFNA) {
            swal("Crear Usuario", "Al menos un fondo es requerido", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }

        if (this.state.nombrePersona === "") {
            swal("Editar Usuario", "El nombre es requerido", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }
        if (this.state.apellidoPersona === "") {
            swal("Editar Usuario", "El apellido es requerido", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }
        if (this.state.correoElectronico === "") {
            swal("Editar Usuario", "El correo electrónico es requerido", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }
        if (!correoValido) {
            swal("Editar Usuario", "El correo electrónico no es válido", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }
        if (!nombreValido) {
            swal("Editar Usuario", "El nombre debe contener solo letras", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }
        if (!apellidoValido) {
            swal("Editar Usuario", "El apellido debe contener solo letras", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }
        if (this.state.cedulaPersona === "") {
            swal("Editar Usuario", "La Cédula es requerida", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }
        if (
            this.state.tipoIdentificacionSeleccionado.id == REACT_APP_IDENTIFICACION_USUARIO_FISICA_NACIONAL &&
            (this.state.cedulaPersona.toString().length != 9)
        ) {
            swal(
                "Editar Cliente",
                "La Cédula Física debe contener 9 dígitos",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (
            this.state.tipoIdentificacionSeleccionado.id == REACT_APP_IDENTIFICACION_USUARIO_DIMEX &&
            (this.state.cedulaPersona.toString().length != 12)
        ) {
            swal(
                "Editar Cliente",
                "La Cédula DIMEX debe contener 12 dígitos",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.clienteSeleccionado === "") {
            swal("Editar Usuario", "La Entidad Operadora es requerida", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }
        if (this.state.rolSeleccionado === "") {
            swal("Editar Usuario", "La rol es requerido", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }
        if (this.state.rolSeleccionado.id == REACT_APP_ROL_EJECUTIVO && this.state.permisos.length > 1) {
            swal("Editar Usuario", "El rol ejecutivo debe de pertenecer únicamente a un fondo", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }
        if (this.state.estadoSeleccionado === "") {
            swal("Editar Usuario", "La estado es requerido", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }
        if (this.state.clienteSeleccionado.cedulaJuridica == REACT_APP_CEDULA_BP) {
            if (this.state.centroEmpresarialSelecionado == "") {
                swal("Crear Usuario", "El Centro Empresarial es requerido", "error", { button: "Aceptar", });
                $("#guardando").hide();
                return;
            }
        }
        if (
            this.state.rolSeleccionado.id == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME &&
            this.state.estadoSeleccionado.id == 1 &&
            this.state.direccion == ""
        ) {
            swal(
                "Crear Usuario",
                "La dirección es requerida cuando el rol seleccionado es: Representante Fodemipyme",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (
            this.state.rolSeleccionado.id == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME &&
            this.state.estadoSeleccionado.id == 1 &&
            this.state.estadoCivil == ""
        ) {
            swal(
                "Crear Usuario",
                "Estado civil es requerido cuando el rol seleccionado es: Representante Fodemipyme",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (
            this.state.rolSeleccionado.id == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME &&
            this.state.estadoSeleccionado.id == 1 &&
            this.state.gradoAcademico == ""
        ) {
            swal(
                "Crear Usuario",
                "El grado académico es requerido cuando el rol seleccionado es: Representante Fodemipyme",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (
            this.state.rolSeleccionado.id == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME &&
            this.state.estadoSeleccionado.id == 1 &&
            this.state.profesion == ""
        ) {
            swal(
                "Crear Usuario",
                "La profesión es requerida cuando el rol seleccionado es: Representante Fodemipyme",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }

        var idClienteSeleccionado = this.state.clienteSeleccionado.id;
        if (this.state.clienteSeleccionado.id == 1 && this.state.permisos.length == 1 && this.state.permisos[0].IdFondo == 1) {
            idClienteSeleccionado = this.state.clienteSeleccionado.id;
        } else if (this.state.clienteSeleccionado.id == 2 && this.state.permisos.length == 1 && this.state.permisos[0].IdFondo == 2) {
            idClienteSeleccionado = this.state.clienteSeleccionado.id;
        } else if ((this.state.clienteSeleccionado.id == 1 || this.state.clienteSeleccionado.id == 2) && this.state.permisos.length == 2) {
            idClienteSeleccionado = null;
        } else if (this.state.clienteSeleccionado.id == 1 && this.state.permisos.length == 1 && this.state.permisos[0].IdFondo == 2) {
            idClienteSeleccionado = REACT_APP_OPERADORA_FNA;
        } else if (this.state.clienteSeleccionado.id == 2 && this.state.permisos.length == 1 && this.state.permisos[0].IdFondo == 1) {
            idClienteSeleccionado = REACT_APP_OPERADORA_FODEMIPYME;
        } else if (this.state.clienteSeleccionado.id > 2) {
            idClienteSeleccionado = this.state.clienteSeleccionado.id;
        }
        let data = {
            IdUsuario: this.state.id,
            IdEntidadFinancieraAutorizada: idClienteSeleccionado,
            NombrePersona: this.state.nombrePersona,
            ApellidoPersona: this.state.apellidoPersona,
            CedulaPersona: this.state.cedulaPersona,
            CorreoElectronico: this.state.correoElectronico,
            IdRol: this.state.rolSeleccionado.id,
            IdEstado: this.state.estadoSeleccionado.id,
            IdTipoIdentificacionUsuario: this.state.tipoIdentificacionSeleccionado.id,
            IdCentroEmpresarial:
                this.state.clienteSeleccionado.cedulaJuridica == REACT_APP_CEDULA_BP
                    ? this.state.centroEmpresarialSelecionado.id
                    : null,
            EstadoCivil:
                this.state.rolSeleccionado.id == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME ? this.state.estadoCivil : null,
            GradoAcademico:
                this.state.rolSeleccionado.id == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME ? this.state.gradoAcademico : null,
            Profesion:
                this.state.rolSeleccionado.id == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME ? this.state.profesion : null,
            Direccion:
                this.state.rolSeleccionado.id == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME ? this.state.direccion : null,
            Permisos: this.state.permisos
        };
        this.setState({ indButton: true });
        ModificarUsuario(data).then((res) => {
            if (res) {
                if (res.codigo !== REACT_APP_CODIGO_RESPUESTA_200) {
                    try {
                        var textoError = res.codigo == 500 ? `Si se ha presentado un error inesperado, por favor enviar el siguiente código de error al administrador de FODEMIPYME : ${res.json.identificacionSolicitud}` : res.json.mensaje + ". Código de error: " + res.json.identificacionSolicitud;
                        if (res.json && res.json.mensaje) {
                            swal(fondoSeleccionado.nombreFondo, textoError, "error", { button: "Aceptar", });
                        } else {
                            swal(fondoSeleccionado.nombreFondo, "Ha ocurrido un error al modificar el usuario, no se obtuvo respuesta del servidor." + " Código de error: " + res.json.identificacionSolicitud, "error", { button: "Aceptar", });
                        }
                        $("#guardando").hide();
                        this.setState({ indButton: false });
                        return;
                    } catch (e) {
                        swal(fondoSeleccionado.nombreFondo, "Ha ocurrido un error al modificar el usuario" + " Código de error: " + res.json.identificacionSolicitud, "error", { button: "Aceptar", });
                        $("#guardando").hide();
                        this.setState({ indButton: false });
                        throw e;
                    }
                    return;
                }
                swal("Usuario", "¡Usuario editado con éxito!", "success", { button: "Aceptar", }).then(
                    (value) => {
                        window.location.href = "/usuarios";
                    }
                );
                $("#guardando").hide();
                this.setState({ indButton: false });
            }
        });
    };
    render() {
        const idRol = localStorage.getItem("idRol");
        if (idRol) {
            if (idRol != REACT_APP_ROL_ADMINISTRATIVO &&
                idRol != REACT_APP_ROL_CONTABILIDAD &&
                idRol != REACT_APP_ROL_SEGUIMIENTO &&
                idRol != REACT_APP_ROL_AUDITOR) window.location.href = "/admin";
        } else {
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        }
        switch (idRol) {
            //REACT_APP_ROL_ADMINISTRATIVO
            case "1":
                return (
                    <div className="container text-center padding-top">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/mantenimiento">Mantenimientos</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/usuarios">Usuarios</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Editar Usuario
                                </li>
                            </ol>
                        </nav>
                        <div>
                            <div>
                                <Tabs
                                    id="controlled-tab-example"
                                    activeKey={this.state.llave}
                                    onSelect={(k) => this.cambiarTab(k)}
                                    className="mb-3 mt-2"
                                >
                                    <Tab eventKey="REGISTRO" title="Registro">
                                        <form className="form-crear" onSubmit={this.editarUsuario}>
                                            <h1 className="h3 mb-3 font-weight-normal">
                                                Editar Usuario: <b> {this.state.correoElectronico}</b>
                                            </h1>
                                            <div className="row form-padding">
                                                <div className="col-md-4">
                                                    <label>Entidad Operadora</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={this.cambioOperadora}
                                                        value={this.state.clienteSeleccionado.id}
                                                        disabled={this.state.indButton}
                                                    >
                                                        {this.state.clientes.map(function (data, key) {
                                                            return (
                                                                <option key={key} value={data.id}>
                                                                    {data.nombreOperadora}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <span className="input-group-addon" id="spinEFA">
                                                        <i className="fa fa-refresh fa-spin"></i>
                                                    </span>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Rol</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={this.cambioRole}
                                                        value={this.state.rolSeleccionado.id}
                                                        disabled={this.state.indButton}
                                                    >
                                                        {this.state.roles.map(function (data, key) {
                                                            return (
                                                                <option key={key} value={data.id}>
                                                                    {data.nombreRol}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <span className="input-group-addon" id="spinRol">
                                                        <i className="fa fa-refresh fa-spin"></i>
                                                    </span>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Estado</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={this.cambioEstado}
                                                        value={this.state.estadoSeleccionado.id}
                                                        disabled={this.state.indButton}
                                                    >
                                                        {this.state.estados.map(function (data, key) {
                                                            return (
                                                                <option key={key} value={data.id}>
                                                                    {data.valor}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <span className="input-group-addon" id="spinEstado">
                                                        <i className="fa fa-refresh fa-spin"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row form-padding" id="mostrarCentroEmpresariales">
                                                <div className="col-md-6">
                                                    <label>Provincias</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={this.cambioProvincias}
                                                        value={this.state.provinciaSeleccionada.id}
                                                        disabled={this.state.indButton}
                                                    >
                                                        {this.state.provinciasLista.map(function (data, key) {
                                                            return (
                                                                <option key={key} value={data.id}>
                                                                    {data.nombre}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Oficinas comerciales</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={this.cambioCentroEmpresarial}
                                                        value={this.state.centroEmpresarialSelecionado.id}
                                                        disabled={this.state.indButton}
                                                    >
                                                        {this.state.ccLista.map(function (data, key) {
                                                            return (
                                                                <option key={key} value={data.id}>
                                                                    {data.centroEmpresarial}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row form-padding">
                                                <div className="col-md-6">
                                                    <label>Tipo de Identificación</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={this.cambioTipoIdentificacion}
                                                        value={this.state.tipoIdentificacionSeleccionado.id}
                                                        disabled={this.state.indButton}
                                                    >
                                                        {this.state.tipoIdentificacionLista.map(function (
                                                            data,
                                                            key
                                                        ) {
                                                            return (
                                                                <option key={key} value={data.id}>
                                                                    {data.valor}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Cédula Identificación</label>
                                                    <input
                                                        type="number"
                                                        name="cedulaPersona"
                                                        className="form-control"
                                                        min="0"
                                                        minLength="9"
                                                        maxLength="12"
                                                        value={this.state.cedulaPersona}
                                                        onChange={this.encargarseCambio}
                                                        required
                                                        placeholder="Cédula Identificación"
                                                        title="(Persona Física) debe contener un máximo 9 de caracteres. (Persona DIMEX) debe contener un máximo 12 de caracteres"
                                                        disabled={this.state.indButton}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row form-padding">
                                                <div className="col-md-6">
                                                    <label>Nombre </label>
                                                    <input
                                                        type="text"
                                                        name="nombrePersona"
                                                        className="form-control"
                                                        value={this.state.nombrePersona}
                                                        onChange={this.encargarseCambio}
                                                        minLength={3}
                                                        maxLength={100}
                                                        required
                                                        placeholder="Nombre"
                                                        title="El nombre debe contener solo letras"
                                                        disabled={this.state.tipoIdentificacionSeleccionado.id == REACT_APP_IDENTIFICACION_USUARIO_FISICA_NACIONAL}
                                                    />
                                                    <span className="input-group-addon" id="spinNombre">
                                                        <i className="fa fa-refresh fa-spin"></i>
                                                    </span>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Apellidos </label>
                                                    <input
                                                        type="text"
                                                        name="apellidoPersona"
                                                        className="form-control"
                                                        value={this.state.apellidoPersona}
                                                        onChange={this.encargarseCambio}
                                                        minLength={3}
                                                        maxLength={100}
                                                        required
                                                        placeholder="Apellidos"
                                                        title="Los apellidos deben contener solo letras"
                                                        disabled={this.state.tipoIdentificacionSeleccionado.id == REACT_APP_IDENTIFICACION_USUARIO_FISICA_NACIONAL}
                                                    />
                                                    <span className="input-group-addon" id="spinApellido">
                                                        <i className="fa fa-refresh fa-spin"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row form-padding">
                                                <div className="col-md-6">
                                                    <label>Correo Electrónico</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        name="correoElectronico"
                                                        minLength="5"
                                                        maxLength="100"
                                                        value={this.state.correoElectronico}
                                                        onChange={this.encargarseCambio}
                                                        required
                                                        placeholder="jhon@midominio.com"
                                                        title="El correo debe tener un formato correcto. Ejemplo: jhon@midominio.com"
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Fondos</label>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="indicadorFODEMIPYME"
                                                            id="indicadorFODEMIPYME"
                                                            onChange={this.encargarseCambio}
                                                            value={this.state.indicadorFODEMIPYME}
                                                            checked={this.state.indicadorFODEMIPYME}
                                                            disabled={!this.state.efaFODEActiva || this.state.indButton}
                                                        />
                                                        <label className="form-check-label">
                                                            FODEMIPYME
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="indicadorFNA"
                                                            id="indicadorFNA"
                                                            onChange={this.encargarseCambio}
                                                            value={this.state.indicadorFNA}
                                                            checked={this.state.indicadorFNA}
                                                            disabled={!this.state.efaFNAActiva || this.state.indButton}
                                                        />
                                                        <label className="form-check-label">
                                                            FNA
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="row form-padding"
                                                hidden={this.state.rolSeleccionado.id != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME}
                                            >
                                                <div className="col-md-3"></div>
                                                <div className="col-md-6">
                                                    <label style={{ color: "blue" }}>
                                                        Información Representante Legal
                                                    </label>
                                                </div>
                                                <div className="col-md-3"></div>
                                            </div>
                                            <div
                                                className="row form-padding"
                                                hidden={this.state.rolSeleccionado.id != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME}
                                            >
                                                <div className="col">
                                                    <label>Dirección</label>
                                                    <textarea
                                                        className="form-control"
                                                        minLength="5"
                                                        maxLength="100"
                                                        name="direccion"
                                                        value={this.state.direccion}
                                                        onChange={this.encargarseCambio}
                                                        rows="3"
                                                        placeholder="Dirección"
                                                        title="La Dirección debe contener mínimo 5 y máximo 100 caracteres"
                                                        disabled={this.state.indButton}
                                                    ></textarea>
                                                </div>
                                            </div>
                                            <div
                                                className="row form-padding"
                                                hidden={this.state.rolSeleccionado.id != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME}
                                            >
                                                <div className="col-md-6">
                                                    <label>Grado Académico</label>
                                                    <input
                                                        type="text"
                                                        name="gradoAcademico"
                                                        className="form-control"
                                                        value={this.state.gradoAcademico}
                                                        onChange={this.encargarseCambio}
                                                        minLength="5"
                                                        maxLength="50"
                                                        title="El grado académico debe contener mínimo 5 y máximo 50 caracteres"
                                                        disabled={this.state.indButton}
                                                        placeholder="Grado Académico"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Profesión</label>
                                                    <input
                                                        type="text"
                                                        name="profesion"
                                                        className="form-control"
                                                        value={this.state.profesion}
                                                        onChange={this.encargarseCambio}
                                                        minLength="5"
                                                        maxLength="50"
                                                        title="La profesión debe contener mínimo 5 y máximo 50 caracteres"
                                                        disabled={this.state.indButton}
                                                        placeholder="Profesión"
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="row form-padding"
                                                hidden={this.state.rolSeleccionado.id != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME}
                                            >
                                                <div className="col-md-3"></div>
                                                <div className="col-md-6">
                                                    <label>Estado civil</label>
                                                    <input
                                                        type="text"
                                                        name="estadoCivil"
                                                        className="form-control"
                                                        value={this.state.estadoCivil}
                                                        onChange={this.encargarseCambio}
                                                        minLength="5"
                                                        maxLength="50"
                                                        title="El civil debe contener mínimo 5 y máximo 50 caracteres"
                                                        disabled={this.state.indButton}
                                                        placeholder="Estado civil"
                                                    />
                                                </div>
                                                <div className="col-md-3"></div>
                                            </div>
                                            <button
                                                type="submit"
                                                className="btn btn-primary border-radius"
                                                disabled={this.state.indButton}
                                                id="mostrarBoton"
                                            >
                                                <span
                                                    id="guardando"
                                                    className="spinner-grow spinner-grow-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                                <span id="guardando" className="sr-only">
                                                    Loading...
                                                </span>
                                                Guardar
                                                {" "}
                                                <i className="fa fa-edit" aria-hidden="true"></i>
                                            </button>
                                        </form>
                                    </Tab>
                                    <Tab eventKey="LOG" title="Log Ingresos" disabled={this.state.indLog}>
                                        <h1 className="h3 mb-3 font-weight-normal">
                                            Log de ingresos: <b> {this.state.correoElectronico}</b>
                                        </h1>
                                        <h3 className="h5 mb-3 font-weight-normal" style={{ color: "blue" }}>
                                            Se mostrará información de los últimos 7 días. {" "} <i class="fa fa-info-circle" aria-hidden="true"></i>
                                        </h3>
                                        <table id="table1" class="table table-striped table-bordered" style={{ width: "100%" }}>
                                            <thead>
                                                <tr>
                                                    <th>Fecha Creación</th>
                                                    <th>Detalle Dispositivo</th>
                                                    <th>Dirección IP</th>
                                                    <th>Ubicación</th>
                                                    <th>Detalle Riesgo</th>
                                                    <th>Estado Riesgo</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </Tab>
                                    <Tab eventKey="RISKY" title="Eventos de Usuario" disabled={this.state.indEventos}>
                                        <h1 className="h3 mb-3 font-weight-normal">
                                            Log de eventos: <b> {this.state.correoElectronico}</b>
                                        </h1>
                                        <h3 className="h5 mb-3 font-weight-normal" style={{ color: "blue" }}>
                                            Se mostrará información de los últimos 7 días. {" "} <i class="fa fa-info-circle" aria-hidden="true"></i>
                                        </h3>
                                        <table id="table2" class="table table-striped table-bordered" style={{ width: "100%" }}>
                                            <thead>
                                                <tr>
                                                    <th>Categoría</th>
                                                    <th>Fecha Actividad</th>
                                                    <th>Nombre Actividad</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                        <table id="tabla1"></table>
                    </div>
                );
            //REACT_APP_ROL_CONTABILIDAD
            case "6":
                return (
                    <div className="container text-center padding-top">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/mantenimiento">Mantenimientos</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/usuarios">Usuarios</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Editar Usuario
                                </li>
                            </ol>
                        </nav>
                        <div className="row">
                            <div className="form-crear">
                                <Tabs
                                    id="controlled-tab-example"
                                    activeKey={this.state.llave}
                                    onSelect={(k) => this.cambiarTab(k)}
                                    className="mb-3 mt-2"
                                >
                                    <Tab eventKey="REGISTRO" title="REGISTRO">
                                        <form className="form-crear" onSubmit={this.editarUsuario}>
                                            <h1 className="h3 mb-3 font-weight-normal">
                                                Editar Usuario: <b> {this.state.correoElectronico}</b>
                                            </h1>
                                            <div className="row form-padding">
                                                <div className="col-md-4">
                                                    <label>Entidad Operadora</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={this.cambioOperadora}
                                                        value={this.state.clienteSeleccionado.id}
                                                        disabled={this.state.indButton}
                                                    >
                                                        {this.state.clientes.map(function (data, key) {
                                                            return (
                                                                <option key={key} value={data.id}>
                                                                    {data.nombreOperadora}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <span className="input-group-addon" id="spinEFA">
                                                        <i className="fa fa-refresh fa-spin"></i>
                                                    </span>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Rol</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={this.cambioRole}
                                                        value={this.state.rolSeleccionado.id}
                                                        disabled={this.state.indButton}
                                                    >
                                                        {this.state.roles.map(function (data, key) {
                                                            return (
                                                                <option key={key} value={data.id}>
                                                                    {data.nombreRol}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <span className="input-group-addon" id="spinRol">
                                                        <i className="fa fa-refresh fa-spin"></i>
                                                    </span>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Estado</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={this.cambioEstado}
                                                        value={this.state.estadoSeleccionado.id}
                                                        disabled={this.state.indButton}
                                                    >
                                                        {this.state.estados.map(function (data, key) {
                                                            return (
                                                                <option key={key} value={data.id}>
                                                                    {data.valor}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <span className="input-group-addon" id="spinEstado">
                                                        <i className="fa fa-refresh fa-spin"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row form-padding" id="mostrarCentroEmpresariales">
                                                <div className="col-md-6">
                                                    <label>Provincias</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={this.cambioProvincias}
                                                        value={this.state.provinciaSeleccionada.id}
                                                        disabled={this.state.indButton}
                                                    >
                                                        {this.state.provinciasLista.map(function (data, key) {
                                                            return (
                                                                <option key={key} value={data.id}>
                                                                    {data.nombre}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Oficinas comerciales</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={this.cambioCentroEmpresarial}
                                                        value={this.state.centroEmpresarialSelecionado.id}
                                                        disabled={this.state.indButton}
                                                    >
                                                        {this.state.ccLista.map(function (data, key) {
                                                            return (
                                                                <option key={key} value={data.id}>
                                                                    {data.centroEmpresarial}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row form-padding">
                                                <div className="col-md-6">
                                                    <label>Tipo de Identificación</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={this.cambioTipoIdentificacion}
                                                        value={this.state.tipoIdentificacionSeleccionado.id}
                                                        disabled={this.state.indButton}
                                                    >
                                                        {this.state.tipoIdentificacionLista.map(function (
                                                            data,
                                                            key
                                                        ) {
                                                            return (
                                                                <option key={key} value={data.id}>
                                                                    {data.valor}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Cédula Identificación</label>
                                                    <input
                                                        type="number"
                                                        name="cedulaPersona"
                                                        className="form-control"
                                                        min="0"
                                                        minLength="9"
                                                        maxLength="12"
                                                        value={this.state.cedulaPersona}
                                                        onChange={this.encargarseCambio}
                                                        required
                                                        placeholder="Cédula Identificación"
                                                        title="(Persona Física) debe contener un máximo 9 de caracteres. (Persona DIMEX) debe contener un máximo 12 de caracteres"
                                                        disabled={this.state.indButton}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row form-padding">
                                                <div className="col-md-6">
                                                    <label>Nombre </label>
                                                    <input
                                                        type="text"
                                                        name="nombrePersona"
                                                        className="form-control"
                                                        value={this.state.nombrePersona}
                                                        onChange={this.encargarseCambio}
                                                        minLength={3}
                                                        maxLength={100}
                                                        required
                                                        placeholder="Nombre"
                                                        title="El nombre debe contener solo letras"
                                                        disabled={this.state.tipoIdentificacionSeleccionado.id == REACT_APP_IDENTIFICACION_USUARIO_FISICA_NACIONAL}
                                                    />
                                                    <span className="input-group-addon" id="spinNombre">
                                                        <i className="fa fa-refresh fa-spin"></i>
                                                    </span>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Apellidos </label>
                                                    <input
                                                        type="text"
                                                        name="apellidoPersona"
                                                        className="form-control"
                                                        value={this.state.apellidoPersona}
                                                        onChange={this.encargarseCambio}
                                                        minLength={3}
                                                        maxLength={100}
                                                        required
                                                        placeholder="Apellidos"
                                                        title="Los apellidos deben contener solo letras"
                                                        disabled={this.state.tipoIdentificacionSeleccionado.id == REACT_APP_IDENTIFICACION_USUARIO_FISICA_NACIONAL}
                                                    />
                                                    <span className="input-group-addon" id="spinApellido">
                                                        <i className="fa fa-refresh fa-spin"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row form-padding">
                                                <div className="col-md-6">
                                                    <label>Correo Electrónico</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        name="correoElectronico"
                                                        minLength="5"
                                                        maxLength="100"
                                                        value={this.state.correoElectronico}
                                                        onChange={this.encargarseCambio}
                                                        required
                                                        placeholder="jhon@midominio.com"
                                                        title="El correo debe tener un formato correcto. Ejemplo: jhon@midominio.com"
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Fondos</label>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="indicadorFODEMIPYME"
                                                            id="indicadorFODEMIPYME"
                                                            onChange={this.encargarseCambio}
                                                            value={this.state.indicadorFODEMIPYME}
                                                            checked={this.state.indicadorFODEMIPYME}
                                                            disabled={!this.state.efaFODEActiva || this.state.indButton}
                                                        />
                                                        <label className="form-check-label">
                                                            FODEMIPYME
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="indicadorFNA"
                                                            id="indicadorFNA"
                                                            onChange={this.encargarseCambio}
                                                            value={this.state.indicadorFNA}
                                                            checked={this.state.indicadorFNA}
                                                            disabled={!this.state.efaFNAActiva || this.state.indButton}
                                                        />
                                                        <label className="form-check-label">
                                                            FNA
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="row form-padding"
                                                hidden={this.state.rolSeleccionado.id != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME}
                                            >
                                                <div className="col-md-3"></div>
                                                <div className="col-md-6">
                                                    <label style={{ color: "blue" }}>
                                                        Información Representante Legal
                                                    </label>
                                                </div>
                                                <div className="col-md-3"></div>
                                            </div>
                                            <div
                                                className="row form-padding"
                                                hidden={this.state.rolSeleccionado.id != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME}
                                            >
                                                <div className="col">
                                                    <label>Dirección</label>
                                                    <textarea
                                                        className="form-control"
                                                        minLength="5"
                                                        maxLength="100"
                                                        name="direccion"
                                                        value={this.state.direccion}
                                                        onChange={this.encargarseCambio}
                                                        rows="3"
                                                        placeholder="Dirección"
                                                        title="La Dirección debe contener mínimo 5 y máximo 100 caracteres"
                                                        disabled={this.state.indButton}
                                                    ></textarea>
                                                </div>
                                            </div>
                                            <div
                                                className="row form-padding"
                                                hidden={this.state.rolSeleccionado.id != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME}
                                            >
                                                <div className="col-md-6">
                                                    <label>Grado Académico</label>
                                                    <input
                                                        type="text"
                                                        name="gradoAcademico"
                                                        className="form-control"
                                                        value={this.state.gradoAcademico}
                                                        onChange={this.encargarseCambio}
                                                        minLength="5"
                                                        maxLength="50"
                                                        title="El grado académico debe contener mínimo 5 y máximo 50 caracteres"
                                                        disabled={this.state.indButton}
                                                        placeholder="Grado Académico"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Profesión</label>
                                                    <input
                                                        type="text"
                                                        name="profesion"
                                                        className="form-control"
                                                        value={this.state.profesion}
                                                        onChange={this.encargarseCambio}
                                                        minLength="5"
                                                        maxLength="50"
                                                        title="La profesión debe contener mínimo 5 y máximo 50 caracteres"
                                                        disabled={this.state.indButton}
                                                        placeholder="Profesión"
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="row form-padding"
                                                hidden={this.state.rolSeleccionado.id != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME}
                                            >
                                                <div className="col-md-3"></div>
                                                <div className="col-md-6">
                                                    <label>Estado civil</label>
                                                    <input
                                                        type="text"
                                                        name="estadoCivil"
                                                        className="form-control"
                                                        value={this.state.estadoCivil}
                                                        onChange={this.encargarseCambio}
                                                        minLength="5"
                                                        maxLength="50"
                                                        title="El civil debe contener mínimo 5 y máximo 50 caracteres"
                                                        disabled={this.state.indButton}
                                                        placeholder="Estado civil"
                                                    />
                                                </div>
                                                <div className="col-md-3"></div>
                                            </div>
                                            <button
                                                type="submit"
                                                className="btn btn-primary border-radius"
                                                disabled={this.state.indButton}
                                            >
                                                <span
                                                    id="guardando"
                                                    className="spinner-grow spinner-grow-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                                <span id="guardando" className="sr-only">
                                                    Loading...
                                                </span>
                                                Guardar
                                                {" "}
                                                <i className="fa fa-edit" aria-hidden="true"></i>
                                            </button>
                                        </form>
                                    </Tab>
                                    <Tab eventKey="LOG" title="LOG">
                                        <h1 className="h3 mb-3 font-weight-normal">
                                            Log de Usuario: <b> {this.state.correoElectronico}</b>
                                        </h1>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                        <table id="tabla1"></table>
                    </div>
                );
            //REACT_APP_ROL_SEGUIMIENTO
            case "7":
                return (
                    <div className="container text-center padding-top">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/mantenimiento">Mantenimientos</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/usuarios">Usuarios</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Editar Usuario
                                </li>
                            </ol>
                        </nav>
                        <div className="row">
                            <div className="form-crear">
                                <Tabs
                                    id="controlled-tab-example"
                                    activeKey={this.state.llave}
                                    onSelect={(k) => this.cambiarTab(k)}
                                    className="mb-3 mt-2"
                                >
                                    <Tab eventKey="REGISTRO" title="REGISTRO">
                                        <form className="form-crear" onSubmit={this.editarUsuario}>
                                            <h1 className="h3 mb-3 font-weight-normal">
                                                Editar Usuario: <b> {this.state.correoElectronico}</b>
                                            </h1>
                                            <div className="row form-padding">
                                                <div className="col-md-4">
                                                    <label>Entidad Operadora</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={this.cambioOperadora}
                                                        value={this.state.clienteSeleccionado.id}
                                                        disabled={this.state.indButton}
                                                    >
                                                        {this.state.clientes.map(function (data, key) {
                                                            return (
                                                                <option key={key} value={data.id}>
                                                                    {data.nombreOperadora}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <span className="input-group-addon" id="spinEFA">
                                                        <i className="fa fa-refresh fa-spin"></i>
                                                    </span>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Rol</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={this.cambioRole}
                                                        value={this.state.rolSeleccionado.id}
                                                        disabled={this.state.indButton}
                                                    >
                                                        {this.state.roles.map(function (data, key) {
                                                            return (
                                                                <option key={key} value={data.id}>
                                                                    {data.nombreRol}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <span className="input-group-addon" id="spinRol">
                                                        <i className="fa fa-refresh fa-spin"></i>
                                                    </span>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Estado</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={this.cambioEstado}
                                                        value={this.state.estadoSeleccionado.id}
                                                        disabled={this.state.indButton}
                                                    >
                                                        {this.state.estados.map(function (data, key) {
                                                            return (
                                                                <option key={key} value={data.id}>
                                                                    {data.valor}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <span className="input-group-addon" id="spinEstado">
                                                        <i className="fa fa-refresh fa-spin"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row form-padding" id="mostrarCentroEmpresariales">
                                                <div className="col-md-6">
                                                    <label>Provincias</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={this.cambioProvincias}
                                                        value={this.state.provinciaSeleccionada.id}
                                                        disabled={this.state.indButton}
                                                    >
                                                        {this.state.provinciasLista.map(function (data, key) {
                                                            return (
                                                                <option key={key} value={data.id}>
                                                                    {data.nombre}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Oficinas comerciales</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={this.cambioCentroEmpresarial}
                                                        value={this.state.centroEmpresarialSelecionado.id}
                                                        disabled={this.state.indButton}
                                                    >
                                                        {this.state.ccLista.map(function (data, key) {
                                                            return (
                                                                <option key={key} value={data.id}>
                                                                    {data.centroEmpresarial}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row form-padding">
                                                <div className="col-md-6">
                                                    <label>Tipo de Identificación</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={this.cambioTipoIdentificacion}
                                                        value={this.state.tipoIdentificacionSeleccionado.id}
                                                        disabled={this.state.indButton}
                                                    >
                                                        {this.state.tipoIdentificacionLista.map(function (
                                                            data,
                                                            key
                                                        ) {
                                                            return (
                                                                <option key={key} value={data.id}>
                                                                    {data.valor}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Cédula Identificación</label>
                                                    <input
                                                        type="number"
                                                        name="cedulaPersona"
                                                        className="form-control"
                                                        min="0"
                                                        minLength="9"
                                                        maxLength="12"
                                                        value={this.state.cedulaPersona}
                                                        onChange={this.encargarseCambio}
                                                        required
                                                        placeholder="Cédula Identificación"
                                                        title="(Persona Física) debe contener un máximo 9 de caracteres. (Persona DIMEX) debe contener un máximo 12 de caracteres"
                                                        disabled={this.state.indButton}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row form-padding">
                                                <div className="col-md-6">
                                                    <label>Nombre </label>
                                                    <input
                                                        type="text"
                                                        name="nombrePersona"
                                                        className="form-control"
                                                        value={this.state.nombrePersona}
                                                        onChange={this.encargarseCambio}
                                                        minLength={3}
                                                        maxLength={100}
                                                        required
                                                        placeholder="Nombre"
                                                        title="El nombre debe contener solo letras"
                                                        disabled={this.state.tipoIdentificacionSeleccionado.id == REACT_APP_IDENTIFICACION_USUARIO_FISICA_NACIONAL}
                                                    />
                                                    <span className="input-group-addon" id="spinNombre">
                                                        <i className="fa fa-refresh fa-spin"></i>
                                                    </span>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Apellidos </label>
                                                    <input
                                                        type="text"
                                                        name="apellidoPersona"
                                                        className="form-control"
                                                        value={this.state.apellidoPersona}
                                                        onChange={this.encargarseCambio}
                                                        minLength={3}
                                                        maxLength={100}
                                                        required
                                                        placeholder="Apellidos"
                                                        title="Los apellidos deben contener solo letras"
                                                        disabled={this.state.tipoIdentificacionSeleccionado.id == REACT_APP_IDENTIFICACION_USUARIO_FISICA_NACIONAL}
                                                    />
                                                    <span className="input-group-addon" id="spinApellido">
                                                        <i className="fa fa-refresh fa-spin"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row form-padding">
                                                <div className="col-md-6">
                                                    <label>Correo Electrónico</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        name="correoElectronico"
                                                        minLength="5"
                                                        maxLength="100"
                                                        value={this.state.correoElectronico}
                                                        onChange={this.encargarseCambio}
                                                        required
                                                        placeholder="jhon@midominio.com"
                                                        title="El correo debe tener un formato correcto. Ejemplo: jhon@midominio.com"
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Fondos</label>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="indicadorFODEMIPYME"
                                                            id="indicadorFODEMIPYME"
                                                            onChange={this.encargarseCambio}
                                                            value={this.state.indicadorFODEMIPYME}
                                                            checked={this.state.indicadorFODEMIPYME}
                                                            disabled={!this.state.efaFODEActiva || this.state.indButton}
                                                        />
                                                        <label className="form-check-label">
                                                            FODEMIPYME
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="indicadorFNA"
                                                            id="indicadorFNA"
                                                            onChange={this.encargarseCambio}
                                                            value={this.state.indicadorFNA}
                                                            checked={this.state.indicadorFNA}
                                                            disabled={!this.state.efaFNAActiva || this.state.indButton}
                                                        />
                                                        <label className="form-check-label">
                                                            FNA
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="row form-padding"
                                                hidden={this.state.rolSeleccionado.id != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME}
                                            >
                                                <div className="col-md-3"></div>
                                                <div className="col-md-6">
                                                    <label style={{ color: "blue" }}>
                                                        Información Representante Legal
                                                    </label>
                                                </div>
                                                <div className="col-md-3"></div>
                                            </div>
                                            <div
                                                className="row form-padding"
                                                hidden={this.state.rolSeleccionado.id != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME}
                                            >
                                                <div className="col">
                                                    <label>Dirección</label>
                                                    <textarea
                                                        className="form-control"
                                                        minLength="5"
                                                        maxLength="100"
                                                        name="direccion"
                                                        value={this.state.direccion}
                                                        onChange={this.encargarseCambio}
                                                        rows="3"
                                                        placeholder="Dirección"
                                                        title="La Dirección debe contener mínimo 5 y máximo 100 caracteres"
                                                        disabled={this.state.indButton}
                                                    ></textarea>
                                                </div>
                                            </div>
                                            <div
                                                className="row form-padding"
                                                hidden={this.state.rolSeleccionado.id != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME}
                                            >
                                                <div className="col-md-6">
                                                    <label>Grado Académico</label>
                                                    <input
                                                        type="text"
                                                        name="gradoAcademico"
                                                        className="form-control"
                                                        value={this.state.gradoAcademico}
                                                        onChange={this.encargarseCambio}
                                                        minLength="5"
                                                        maxLength="50"
                                                        title="El grado académico debe contener mínimo 5 y máximo 50 caracteres"
                                                        disabled={this.state.indButton}
                                                        placeholder="Grado Académico"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Profesión</label>
                                                    <input
                                                        type="text"
                                                        name="profesion"
                                                        className="form-control"
                                                        value={this.state.profesion}
                                                        onChange={this.encargarseCambio}
                                                        minLength="5"
                                                        maxLength="50"
                                                        title="La profesión debe contener mínimo 5 y máximo 50 caracteres"
                                                        disabled={this.state.indButton}
                                                        placeholder="Profesión"
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="row form-padding"
                                                hidden={this.state.rolSeleccionado.id != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME}
                                            >
                                                <div className="col-md-3"></div>
                                                <div className="col-md-6">
                                                    <label>Estado civil</label>
                                                    <input
                                                        type="text"
                                                        name="estadoCivil"
                                                        className="form-control"
                                                        value={this.state.estadoCivil}
                                                        onChange={this.encargarseCambio}
                                                        minLength="5"
                                                        maxLength="50"
                                                        title="El civil debe contener mínimo 5 y máximo 50 caracteres"
                                                        disabled={this.state.indButton}
                                                        placeholder="Estado civil"
                                                    />
                                                </div>
                                                <div className="col-md-3"></div>
                                            </div>
                                            <button
                                                type="submit"
                                                className="btn btn-primary border-radius"
                                                disabled={this.state.indButton}
                                            >
                                                <span
                                                    id="guardando"
                                                    className="spinner-grow spinner-grow-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                                <span id="guardando" className="sr-only">
                                                    Loading...
                                                </span>
                                                Guardar
                                                {" "}
                                                <i className="fa fa-edit" aria-hidden="true"></i>
                                            </button>
                                        </form>
                                    </Tab>
                                    <Tab eventKey="LOG" title="LOG">
                                        <h1 className="h3 mb-3 font-weight-normal">
                                            Log de Usuario: <b> {this.state.correoElectronico}</b>
                                        </h1>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                        <table id="tabla1"></table>
                    </div>
                );
            //REACT_APP_ROL_AUDITOR
            case "9":
                return (
                    <div className="container text-center padding-top">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/mantenimiento">Mantenimientos</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/usuarios">Usuarios</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Editar Usuario
                                </li>
                            </ol>
                        </nav>
                        <div>
                            <div>
                                <Tabs
                                    id="controlled-tab-example"
                                    activeKey={this.state.llave}
                                    onSelect={(k) => this.cambiarTab(k)}
                                    className="mb-3 mt-2"
                                >
                                    <Tab eventKey="REGISTRO" title="Registro">
                                        <form className="form-crear" onSubmit={this.editarUsuario}>
                                            <h1 className="h3 mb-3 font-weight-normal">
                                                Editar Usuario: <b> {this.state.correoElectronico}</b>
                                            </h1>
                                            <div className="row form-padding">
                                                <div className="col-md-4">
                                                    <label>Entidad Operadora</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={this.cambioOperadora}
                                                        value={this.state.clienteSeleccionado.id}
                                                        disabled
                                                    >
                                                        {this.state.clientes.map(function (data, key) {
                                                            return (
                                                                <option key={key} value={data.id}>
                                                                    {data.nombreOperadora}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <span className="input-group-addon" id="spinEFA">
                                                        <i className="fa fa-refresh fa-spin"></i>
                                                    </span>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Rol</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={this.cambioRole}
                                                        value={this.state.rolSeleccionado.id}
                                                        disabled
                                                    >
                                                        {this.state.roles.map(function (data, key) {
                                                            return (
                                                                <option key={key} value={data.id}>
                                                                    {data.nombreRol}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <span className="input-group-addon" id="spinRol">
                                                        <i className="fa fa-refresh fa-spin"></i>
                                                    </span>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Estado</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={this.cambioEstado}
                                                        value={this.state.estadoSeleccionado.id}
                                                        disabled
                                                    >
                                                        {this.state.estados.map(function (data, key) {
                                                            return (
                                                                <option key={key} value={data.id}>
                                                                    {data.valor}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <span className="input-group-addon" id="spinEstado">
                                                        <i className="fa fa-refresh fa-spin"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row form-padding" id="mostrarCentroEmpresariales">
                                                <div className="col-md-6">
                                                    <label>Provincias</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={this.cambioProvincias}
                                                        value={this.state.provinciaSeleccionada.id}
                                                        disabled
                                                    >
                                                        {this.state.provinciasLista.map(function (data, key) {
                                                            return (
                                                                <option key={key} value={data.id}>
                                                                    {data.nombre}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Oficinas comerciales</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={this.cambioCentroEmpresarial}
                                                        value={this.state.centroEmpresarialSelecionado.id}
                                                        disabled
                                                    >
                                                        {this.state.ccLista.map(function (data, key) {
                                                            return (
                                                                <option key={key} value={data.id}>
                                                                    {data.centroEmpresarial}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row form-padding">
                                                <div className="col-md-6">
                                                    <label>Tipo de Identificación</label>
                                                    <select
                                                        className="form-control"
                                                        onChange={this.cambioTipoIdentificacion}
                                                        value={this.state.tipoIdentificacionSeleccionado.id}
                                                        disabled
                                                    >
                                                        {this.state.tipoIdentificacionLista.map(function (
                                                            data,
                                                            key
                                                        ) {
                                                            return (
                                                                <option key={key} value={data.id}>
                                                                    {data.valor}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Cédula Identificación</label>
                                                    <input
                                                        type="number"
                                                        name="cedulaPersona"
                                                        className="form-control"
                                                        min="0"
                                                        minLength="9"
                                                        maxLength="12"
                                                        value={this.state.cedulaPersona}
                                                        onChange={this.encargarseCambio}
                                                        required
                                                        placeholder="Cédula Identificación"
                                                        title="(Persona Física) debe contener un máximo 9 de caracteres. (Persona DIMEX) debe contener un máximo 12 de caracteres"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="row form-padding">
                                                <div className="col-md-6">
                                                    <label>Nombre </label>
                                                    <input
                                                        type="text"
                                                        name="nombrePersona"
                                                        className="form-control"
                                                        value={this.state.nombrePersona}
                                                        onChange={this.encargarseCambio}
                                                        minLength={3}
                                                        maxLength={100}
                                                        required
                                                        placeholder="Nombre"
                                                        title="El nombre debe contener solo letras"
                                                        disabled
                                                    />
                                                    <span className="input-group-addon" id="spinNombre">
                                                        <i className="fa fa-refresh fa-spin"></i>
                                                    </span>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Apellidos </label>
                                                    <input
                                                        type="text"
                                                        name="apellidoPersona"
                                                        className="form-control"
                                                        value={this.state.apellidoPersona}
                                                        onChange={this.encargarseCambio}
                                                        minLength={3}
                                                        maxLength={100}
                                                        required
                                                        placeholder="Apellidos"
                                                        title="Los apellidos deben contener solo letras"
                                                        disabled
                                                    />
                                                    <span className="input-group-addon" id="spinApellido">
                                                        <i className="fa fa-refresh fa-spin"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row form-padding">
                                                <div className="col-md-6">
                                                    <label>Correo Electrónico</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        name="correoElectronico"
                                                        minLength="5"
                                                        maxLength="100"
                                                        value={this.state.correoElectronico}
                                                        onChange={this.encargarseCambio}
                                                        required
                                                        placeholder="jhon@midominio.com"
                                                        title="El correo debe tener un formato correcto. Ejemplo: jhon@midominio.com"
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Fondos</label>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="indicadorFODEMIPYME"
                                                            id="indicadorFODEMIPYME"
                                                            onChange={this.encargarseCambio}
                                                            value={this.state.indicadorFODEMIPYME}
                                                            checked={this.state.indicadorFODEMIPYME}
                                                            disabled
                                                        />
                                                        <label className="form-check-label">
                                                            FODEMIPYME
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="indicadorFNA"
                                                            id="indicadorFNA"
                                                            onChange={this.encargarseCambio}
                                                            value={this.state.indicadorFNA}
                                                            checked={this.state.indicadorFNA}
                                                            disabled
                                                        />
                                                        <label className="form-check-label">
                                                            FNA
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="row form-padding"
                                                hidden={this.state.rolSeleccionado.id != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME}
                                            >
                                                <div className="col-md-3"></div>
                                                <div className="col-md-6">
                                                    <label style={{ color: "blue" }}>
                                                        Información Representante Legal
                                                    </label>
                                                </div>
                                                <div className="col-md-3"></div>
                                            </div>
                                            <div
                                                className="row form-padding"
                                                hidden={this.state.rolSeleccionado.id != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME}
                                            >
                                                <div className="col">
                                                    <label>Dirección</label>
                                                    <textarea
                                                        className="form-control"
                                                        minLength="5"
                                                        maxLength="100"
                                                        name="direccion"
                                                        value={this.state.direccion}
                                                        onChange={this.encargarseCambio}
                                                        rows="3"
                                                        placeholder="Dirección"
                                                        title="La Dirección debe contener mínimo 5 y máximo 100 caracteres"
                                                        disabled
                                                    ></textarea>
                                                </div>
                                            </div>
                                            <div
                                                className="row form-padding"
                                                hidden={this.state.rolSeleccionado.id != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME}
                                            >
                                                <div className="col-md-6">
                                                    <label>Grado Académico</label>
                                                    <input
                                                        type="text"
                                                        name="gradoAcademico"
                                                        className="form-control"
                                                        value={this.state.gradoAcademico}
                                                        onChange={this.encargarseCambio}
                                                        minLength="5"
                                                        maxLength="50"
                                                        title="El grado académico debe contener mínimo 5 y máximo 50 caracteres"
                                                        disabled
                                                        placeholder="Grado Académico"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Profesión</label>
                                                    <input
                                                        type="text"
                                                        name="profesion"
                                                        className="form-control"
                                                        value={this.state.profesion}
                                                        onChange={this.encargarseCambio}
                                                        minLength="5"
                                                        maxLength="50"
                                                        title="La profesión debe contener mínimo 5 y máximo 50 caracteres"
                                                        disabled
                                                        placeholder="Profesión"
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="row form-padding"
                                                hidden={this.state.rolSeleccionado.id != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME}
                                            >
                                                <div className="col-md-3"></div>
                                                <div className="col-md-6">
                                                    <label>Estado civil</label>
                                                    <input
                                                        type="text"
                                                        name="estadoCivil"
                                                        className="form-control"
                                                        value={this.state.estadoCivil}
                                                        onChange={this.encargarseCambio}
                                                        minLength="5"
                                                        maxLength="50"
                                                        title="El civil debe contener mínimo 5 y máximo 50 caracteres"
                                                        disabled
                                                        placeholder="Estado civil"
                                                    />
                                                </div>
                                                <div className="col-md-3"></div>
                                            </div>
                                        </form>
                                    </Tab>
                                    <Tab eventKey="LOG" title="Log Ingresos" disabled={this.state.indLog}>
                                        <h1 className="h3 mb-3 font-weight-normal">
                                            Log de ingresos: <b> {this.state.correoElectronico}</b>
                                        </h1>
                                        <h3 className="h5 mb-3 font-weight-normal" style={{ color: "blue" }}>
                                            Se mostrará información de los últimos 7 días. {" "} <i class="fa fa-info-circle" aria-hidden="true"></i>
                                        </h3>
                                        <table id="table1" class="table table-striped table-bordered" style={{ width: "100%" }}>
                                            <thead>
                                                <tr>
                                                    <th>Fecha Creación</th>
                                                    <th>Detalle Dispositivo</th>
                                                    <th>Dirección IP</th>
                                                    <th>Ubicación</th>
                                                    <th>Detalle Riesgo</th>
                                                    <th>Estado Riesgo</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </Tab>
                                    <Tab eventKey="RISKY" title="Eventos de Usuario" disabled={this.state.indEventos}>
                                        <h1 className="h3 mb-3 font-weight-normal">
                                            Log de eventos: <b> {this.state.correoElectronico}</b>
                                        </h1>
                                        <h3 className="h5 mb-3 font-weight-normal" style={{ color: "blue" }}>
                                            Se mostrará información de los últimos 7 días. {" "} <i class="fa fa-info-circle" aria-hidden="true"></i>
                                        </h3>
                                        <table id="table2" class="table table-striped table-bordered" style={{ width: "100%" }}>
                                            <thead>
                                                <tr>
                                                    <th>Categoría</th>
                                                    <th>Fecha Actividad</th>
                                                    <th>Nombre Actividad</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                        <table id="tabla1"></table>
                    </div>
                );
        }
    }
}
export default EditarUsuarioComponent;