import React from "react";
import MantenimientoReportesComponent from "../../Components/MantenimientoReportes/MantenimientoReportes";

const MantenimientoReportesView = () => {
  return (
    <div>
      <MantenimientoReportesComponent />
    </div>
  );
};

export default MantenimientoReportesView;
