import React from "react";
import CrearAvalPruebaComponent from "../../Components/Aval/CrearAvalPrueba";

const CrearAvalPruebaView = () => {
  return (
    <div>
      <CrearAvalPruebaComponent />
    </div>
  );
};

export default CrearAvalPruebaView;
