import React, { Component } from "react";
import {
    Container,
    Row,
    Col,
    Form,
} from "react-bootstrap";
import {
    REACT_APP_URL_DESCARGAR_ARCHIVO,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE,
    REACT_APP_CODIGO_RESPUESTA_200,
    REACT_APP_CODIGO_RESPUESTA_400,
    REACT_APP_CODIGO_RESPUESTA_401,
    REACT_APP_CODIGO_RESPUESTA_404,
    REACT_APP_CODIGO_RESPUESTA_500,
    REACT_APP_ROL_ADMINISTRATIVO,
    REACT_APP_ROL_EJECUTIVO,
    REACT_APP_ROL_INCLUSION,
    REACT_APP_ROL_OPERATIVO,
    REACT_APP_ROL_JEFATURA,
    REACT_APP_ROL_SEGUIMIENTO,
    REACT_APP_ROL_REPRESENTANTE_FODEMIPYME,
    REACT_APP_CATALOGO_OBTENER_ESTADO_AVAL,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_RECUPERABLE,
    REACT_APP_OPERADORA_FODEMIPYME,
    REACT_APP_OPERADORA_FNA,
    REACT_APP_LONGITUD_CAMPO_MONTOS,
    REACT_APP_ROL_CONTABILIDAD
} from "../../environment";
import swal from "sweetalert";
import moment from "moment";
import {
    ModificarHornamiento,
    ObtenerObservacionesXHonramiento,
    AgregarObservacionHonramiento,
    EditarObservacionHonramiento
} from "../../Services/HonramientoService";
import {
    ObtenerUsuarioPorID,
    ListarEjecutivosFodemipyme,
    ListarAdministradoresFodemipyme
} from "../../Services/AvalService";
import { ObtenerCatalogo } from "../../Services/GenericoService";
import { BorrarLocalStorage } from "../../Utils/BorrarInfoLocalStorage";
import { ObtenerUserAgent } from "../../Utils/BaseSolicitudModel";
import NumberFormat from "react-number-format";
const $ = require("jquery");
$.DataTable = require("datatables.net-bs4");
/**
 * Obtener el ID del Rol del usuario que inicio sesion
 **/
const idRol = localStorage.getItem("idRol");
/**
 * Obtener el ID del usuario que inicio sesion
 **/
const idUsuarioConectado = localStorage.getItem("idUsuarioCreador");
/**
 * Obtener el nombre del usuario que inicio sesion
 **/
const nombreUsuarioConectado = localStorage.getItem("usuarioLDAP");
/**
 * Obtener el Fondo seleccionado en iniciar sesion
 **/
const fondoSeleccionado = JSON.parse(localStorage.getItem("fondoSeleccionado"));
/**
 * Obtener el ID del Fondo seleccionado en iniciar sesion
 **/
const idFondo = localStorage.getItem("idFondo");
const userAgent = ObtenerUserAgent();
export default class EditarHonramientoComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            idAval: 0,
            montoHonramiento: 0,
            diasMorosidad: 0,
            tieneDemanda: false,
            nombreArchivoDemanda: null,
            indNombreArchivoDemanda: false,
            indArchivoDemanda: false,
            nombreArchivoEvidenciaHonramiento: null,
            indNombreArchivoEvidenciaHonramiento: false,
            indArchivoEvidenciaHonramiento: false,
            esIrrecuperable: null,
            fechaPresentacionDemanda: moment().locale("en").format("YYYY-MM-DD"),
            fechaCreacionHonramiento: moment().locale("en").format("YYYY-MM-DD"),
            indButton: false,
            token: "",
            indFechaPresentacionDemanda: false,
            historicoObservaciones: [],
            indMostrarHistoricoObservaciones: false,
            indEditarObservaciones: false,
            idObservacion: 0,
            observacion: "",
            estadosLista: [],
            estadoSeleccionado: "",
            ejecutivoLista: [],
            ejecutivoSeleccionado: "",
            usuariosListaDatatable: [],
            usuarioAsignado: "",
            idUsuarioAsignado: 0,
            historicoLista: [],
            historicoSeleccionado: "",
            indNoEditar: false,
            indNoEditarEstado: false,
            indNoEditarEjecutivo: false,
            indNoEditarObservaciones: false,
            idOperadora: 0,
            ocultarBotonGuardar: false,
            programaSeleccionado: 0,
            idInclusorReciente: null,
            idJefaturaReciente: null,
            idOperativoReciente: null,
            idEjecutivoFodemipymeReciente: null,
            idAdminFodemipymeReciente: null,
            indHonramientoConDemanda: false,
            indHonramientoIrrecuperable: false,
            indNoEditarFechaDemanda: false,
            indArchivoIrrecuperable: false,
            indArchivoInputIrrecuperable: false,
            nombreArchivoEvidenciaIrrecuperable: null,
            indDescargarArchivoEvidencia: false,
            indDescargarArchivoDemanda: false,
            indDescargarArchivoIrrecuperable: false
        };
    }
    /**
     * Llamado inmediatamente después de montar un componente. 
     * Establecer el estado aquí activará la re-renderización.
     **/
    componentDidMount() {
        $("#floppy").show();
        $("#guardando").hide();
        $("#nombreArchivoDemanda").hide();
        $("#nombreArchivoEvidenciaHonramiento").hide();
        $("#irrecuperable").hide();
        $("#spinEstado").show();
        $("#spinUsuario").show();
        const idOperadora = localStorage.getItem("idOperadora");
        const tokenContent = localStorage.getItem("isLoggedIn");
        const infoEditar = sessionStorage.getItem("infoHonramientoEditar");
        if (!tokenContent) {
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        }
        this.state.token = tokenContent;
        this.setState({ token: tokenContent });
        if (!infoEditar) window.location.href = "/honramientos";
        const infoEditarJson = JSON.parse(infoEditar);
        if (infoEditarJson != null) {

            if (infoEditarJson.nombreArchivoDemanda != null) {
                this.setState({ indNombreArchivoDemanda: true });
            }

            if (infoEditarJson.archivoEvidenciaHonramiento != null) {
                this.setState({ indArchivoEvidenciaHonramiento: true });
            }

            if (infoEditarJson.archivoEvidenciaIrrecuperable != null) {
                this.setState({ indArchivoInputIrrecuperable: true });
            }

            this.setState({ id: infoEditarJson.id });
            this.state.idAval = infoEditarJson.aval.id;
            this.setState({ idAval: infoEditarJson.aval.id });

            this.state.idOperadora = idOperadora;
            this.setState({ idOperadora: idOperadora });

            this.state.estadoSeleccionado = infoEditarJson.estado;
            this.setState({ estadoSeleccionado: infoEditarJson.estado });

            if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA ||
                infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_RECUPERABLE ||
                infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE) {
                this.state.indHonramientoConDemanda = true;
                this.setState({ indHonramientoConDemanda: true });

                this.state.indNoEditarFechaDemanda = true;
                this.setState({ indNoEditarFechaDemanda: true });
            }

            if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE) {
                this.state.indHonramientoIrrecuperable = true;
                this.setState({ indHonramientoIrrecuperable: true });
            }

            this.state.programaSeleccionado = infoEditarJson.aval.programa;
            this.setState({ programaSeleccionado: infoEditarJson.aval.programa });

            this.state.usuarioAsignado = infoEditarJson.ejecutivo;
            this.setState({ usuarioAsignado: infoEditarJson.ejecutivo });

            this.state.idAdminFodemipymeReciente =
                infoEditarJson.idAdminFodemipymeReciente;
            this.setState({
                idAdminFodemipymeReciente: infoEditarJson.idAdminFodemipymeReciente,
            });

            this.state.idEjecutivoFodemipymeReciente =
                infoEditarJson.idEjecutivoFodemipymeReciente;
            this.setState({
                idEjecutivoFodemipymeReciente:
                    infoEditarJson.idEjecutivoFodemipymeReciente,
            });

            this.state.idInclusorReciente = infoEditarJson.idInclusorReciente;
            this.setState({ idInclusorReciente: infoEditarJson.idInclusorReciente });

            this.state.idJefaturaReciente = infoEditarJson.idJefaturaReciente;
            this.setState({ idJefaturaReciente: infoEditarJson.idJefaturaReciente });

            this.state.idOperativoReciente = infoEditarJson.idOperativoReciente;
            this.setState({
                idOperativoReciente: infoEditarJson.idOperativoReciente,
            });

            this.state.idUsuarioAsignado = infoEditarJson.ejecutivo.b2CId;
            this.setState({ idUsuarioAsignado: infoEditarJson.ejecutivo.b2CId });

            this.state.historicoLista = infoEditarJson.historicoEstados;
            this.setState({ historicoLista: infoEditarJson.historicoEstados });

            this.setState({ tieneDemanda: infoEditarJson.tieneDemanda });

            this.setState({
                nombreArchivoDemanda: infoEditarJson.nombreArchivoDemanda,
            });
            this.setState({ nombreArchivoEvidenciaHonramiento: infoEditarJson.archivoEvidenciaHonramiento });
            this.setState({ nombreArchivoEvidenciaIrrecuperable: infoEditarJson.archivoEvidenciaIrrecuperable });
            this.setState({
                montoHonramiento: infoEditarJson.montoHonramiento.toFixed(2),
            });
            this.setState({
                diasMorosidad: infoEditarJson.diasMorosidad,
            });
            if (infoEditarJson.fechaPresentacionDemanda != null) {
                this.setState({
                    fechaPresentacionDemanda: moment(
                        infoEditarJson.fechaPresentacionDemanda
                    )
                        .locale("en")
                        .format("YYYY-MM-DD"),
                });
            } else {
                this.setState({
                    fechaPresentacionDemanda: null
                });
            }
            if (idRol != REACT_APP_ROL_INCLUSION) {
                this.state.indNoEditar = true;
                this.setState({ indNoEditar: true });
                this.state.indNoEditarEstado = false;
                this.state.indNoEditarEjecutivo = false;
                this.setState({ indNoEditarEstado: false });
                this.setState({ indNoEditarEjecutivo: false });
                this.state.indNoEditarObservaciones = false;
                this.setState({ indNoEditarObservaciones: false });
            }
        }
        this.ObtenerObservacionesXHonramiento();
        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME &&
            (idRol == REACT_APP_ROL_ADMINISTRATIVO || idRol == REACT_APP_ROL_SEGUIMIENTO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME)
        ) {
            this.cargarEjecutivosFodemipyme();
        } else if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO ||
            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA ||
            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE ||
            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_RECUPERABLE) {
            this.cargarAdministradoresFodemipyme();
        } else {
            this.cargarUsuarios();
        }
        this.cargarEstado();
    }
    /**
     * Obtener las observaciones por ID honramiento
     **/
    ObtenerObservacionesXHonramiento = () => {
        var data = {
            IdAval: this.state.idAval,
        };
        ObtenerObservacionesXHonramiento(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                if (res.json.observaciones.length > 0) {
                    this.setState({ indMostrarHistoricoObservaciones: true });
                }
                this.setState({ historicoObservaciones: res.json.observaciones });
            }
        });
    };
    /**
     * 
     * @returns Una nueva observacion
     **/
    AgregarObservacionHonramiento = () => {
        let idUsuarioCrea = localStorage.getItem("idUsuarioCreador");
        if (this.state.observacion === "") {
            swal(
                "Honramiento",
                "La observación es requerida",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        var data = {
            IdAval: this.state.idAval,
            IdEjecutivo: idUsuarioCrea,
            Observacion: this.state.observacion,
        };
        this.setState({ indButton: true });
        AgregarObservacionHonramiento(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    this.setState({ indButton: false });
                    return;
                }
                if (res.json.indicadorExitoso) {
                    swal(
                        "Observaciones",
                        "¡Observación creada con éxito!",
                        "success", { button: "Aceptar", }
                    ).then(() => {
                        this.ObtenerObservacionesXHonramiento();
                        this.setState({ indButton: false });
                    });

                    this.setState({ observacion: "" });
                    this.ObtenerObservacionesXHonramiento();
                } else {
                    this.setState({ indButton: false });
                    swal("Observaciones", res.json.mensaje, "success", { button: "Aceptar", });
                }
            }
        });
    };
    /**
     * 
     * @returns Edicion de una observacion
     **/
    EditarObservacionHonramiento = () => {
        if (this.state.observacion === "") {
            swal(
                "Honramiento",
                "La observación es requerida",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        var data = {
            Id: this.state.idObservacion,
            IdAval: this.state.idAval,
            IdEjecutivo: idUsuarioConectado,
            Observacion: this.state.observacion,
        };
        EditarObservacionHonramiento(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    this.setState({ indButton: false });
                    return;
                }
                if (res.json.indicadorExitoso) {
                    this.setState({ indButton: false });
                    swal(
                        "Observaciones",
                        "¡Observación editada con éxito!",
                        "success", { button: "Aceptar", }
                    ).then(() => {
                        this.ObtenerObservacionesXHonramiento();
                    });

                    this.setState({ observacion: "" });
                    this.ObtenerObservacionesXHonramiento();
                    this.CancelarEdicionObservacion();
                } else {
                    this.setState({ indButton: false });
                    swal("Observaciones", res.json.mensaje, "success", { button: "Aceptar", });
                }
            }
        });
    };
    /**
     * Habilitar la edicion de observaciones
     **/
    MostrarEditarObservaciones = (observacion, index) => {
        this.setState({ indEditarObservaciones: true });
        this.setState({ observacion: observacion.observaciones });
        this.setState({ idObservacion: observacion.id });
    };
    /**
     * Deshabilitar la edicion de observaciones
     **/
    CancelarEdicionObservacion = () => {
        this.setState({ indEditarObservaciones: false });
        this.setState({ observacion: "" });
        this.setState({ idObservacion: 0 });
    };
    /**
     * Listar los usuarios con rol administrativo
     **/
    cargarAdministradoresFodemipyme = () => {
        ListarAdministradoresFodemipyme().then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    $("#spinUsuario").show();
                    return;
                }
                $("#spinUsuario").hide();
                var filtroUsuarios = [];
                if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO && idRol == REACT_APP_ROL_ADMINISTRATIVO) {
                    filtroUsuarios = res.json.administradoresFodemipyme.filter(function (usuario) {
                        var usuarioSelecionado = [];
                        usuarioSelecionado = usuario.permisos.filter(function (permiso) {
                            return (
                                permiso.fondo.id == idFondo
                            );
                        });
                        return (
                            usuarioSelecionado[0].rol.id == REACT_APP_ROL_ADMINISTRATIVO
                        );
                    });
                } else {
                    filtroUsuarios = res.json.administradoresFodemipyme;
                }

                localStorage.setItem(
                    "ejecutivoLista",
                    JSON.stringify(filtroUsuarios)
                );
                let usuarioAsignado = this.state.usuarioAsignado;
                this.state.ejecutivoSeleccionado = usuarioAsignado;

                this.setState({
                    ejecutivoLista: filtroUsuarios,
                    ejecutivoSeleccionado: this.state.ejecutivoSeleccionado,
                });
            }
        });
    };
    /**
   * Listar los usuarios con rol ejecutivo
   **/
    cargarEjecutivosFodemipyme = () => {
        ListarEjecutivosFodemipyme().then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    $("#spinUsuario").show();
                    return;
                }
                $("#spinUsuario").hide();
                localStorage.setItem(
                    "ejecutivoLista",
                    JSON.stringify(res.json.ejecutivosFondo)
                );
                let usuarioAsignado = this.state.usuarioAsignado;
                this.state.ejecutivoSeleccionado = usuarioAsignado;

                this.setState({
                    ejecutivoLista: res.json.ejecutivosFondo,
                    ejecutivoSeleccionado: this.state.ejecutivoSeleccionado,
                });
            }
        });
    };
    /**
     * Listar los usuarios
     **/
    cargarUsuarios = () => {
        $("#tabla1").DataTable({
            processing: true,
            serverSide: true,
            pageLength: 1000,
            ajax: {
                url: `/api/ListarUsuarios?fondo=${localStorage.getItem("idFondo")}&ip=${localStorage.getItem("ip")}&latitud=${localStorage.getItem("latitud")}&longitud=${localStorage.getItem("longitud")}&userAgent=${userAgent}&usuario=${localStorage.getItem("idUsuarioCreador")}`,
                error: function (xhr, error, thrown) {
                    if (xhr.status == REACT_APP_CODIGO_RESPUESTA_401) {
                        swal({
                            title: "¡Sesión Expiró!",
                            text: "Por favor vuelva a iniciar sesión",
                            icon: "warning",
                            button: "Aceptar",
                        }).then((value) => {
                            BorrarLocalStorage();
                            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                        });
                        return;
                    }
                    if (xhr.status == REACT_APP_CODIGO_RESPUESTA_404) {
                        swal("Error al obtener los usuarios", "", "error", { button: "Aceptar", });
                        $("#spinUsuario").show();
                        return;
                    }
                },
                success: (data) => {
                    if (data != null) {
                        $("#spinUsuario").hide();
                        this.state.usuariosListaDatatable = data.data;
                        let idUsuarioLogueado = localStorage.getItem("idUsuarioCreador");
                        let idRol = localStorage.getItem("idRol");
                        var filtroUsuarios = [];
                        var ejecutivo = this.state.historicoLista;
                        let origen = ejecutivo[0].usuarioOrigen;
                        let ultimoRegistro = ejecutivo[ejecutivo.length - 1];
                        let usuarioOrigen = ultimoRegistro.usuarioOrigen;
                        let usuarioDestino = ultimoRegistro.usuarioDestino;
                        let idUsuarioAsignado = this.state.idUsuarioAsignado;
                        let usuarioAsignado = this.state.usuarioAsignado;
                        let idCliente = this.state.programaSeleccionado.cliente.id;
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD) {
                            var usuarioAsignadoPermiso = [];
                            usuarioAsignadoPermiso = this.state.usuarioAsignado.permisos.filter(function (permiso) {
                                return (
                                    permiso.fondo.id == idFondo
                                );
                            });
                            if (idRol == REACT_APP_ROL_JEFATURA && usuarioAsignadoPermiso[0].rol.id == REACT_APP_ROL_INCLUSION) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSeleccionado = [];
                                    usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSeleccionado[0].entidadFinancieraAutorizada.id == idCliente &&
                                        usuarioSeleccionado[0].rol.id == REACT_APP_ROL_INCLUSION
                                    );
                                });
                            } else if (idRol == REACT_APP_ROL_JEFATURA && usuarioAsignadoPermiso[0].rol.id == REACT_APP_ROL_OPERATIVO) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSeleccionado = [];
                                    usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSeleccionado[0].entidadFinancieraAutorizada.id == idCliente &&
                                        usuarioSeleccionado[0].rol.id == REACT_APP_ROL_OPERATIVO
                                    );
                                });
                            } else {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSeleccionado = [];
                                    usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSeleccionado[0].entidadFinancieraAutorizada.id == idCliente &&
                                        (usuarioSeleccionado[0].rol.id == REACT_APP_ROL_OPERATIVO || usuarioSeleccionado[0].rol.id == REACT_APP_ROL_INCLUSION) &&
                                        usuario.b2CId == idUsuarioLogueado
                                    );
                                });
                            }
                            if (idRol == REACT_APP_ROL_ADMINISTRATIVO || idRol == REACT_APP_ROL_SEGUIMIENTO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME || idRol == REACT_APP_ROL_CONTABILIDAD) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    return (
                                        usuario.b2CId == usuarioAsignado.b2CId
                                    );
                                });
                            }
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD) {
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSeleccionado = [];
                                    usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSeleccionado[0].entidadFinancieraAutorizada.id == idCliente &&
                                        usuarioSeleccionado[0].rol.id == REACT_APP_ROL_INCLUSION
                                    );
                                });
                            } else {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSeleccionado = [];
                                    usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSeleccionado[0].entidadFinancieraAutorizada.id == idCliente &&
                                        usuarioSeleccionado[0].rol.id == REACT_APP_ROL_INCLUSION &&
                                        usuario.b2CId == usuarioDestino.b2CId
                                    );
                                });
                            }
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME) {
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSeleccionado = [];
                                    usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSeleccionado[0].entidadFinancieraAutorizada.id == idCliente &&
                                        usuarioSeleccionado[0].rol.id == REACT_APP_ROL_INCLUSION
                                    );
                                });
                            } else {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSeleccionado = [];
                                    usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSeleccionado[0].entidadFinancieraAutorizada.id == idCliente &&
                                        usuarioSeleccionado[0].rol.id == REACT_APP_ROL_INCLUSION &&
                                        usuario.b2CId == usuarioDestino.b2CId
                                    );
                                });
                            }
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME) {
                            if (idRol == REACT_APP_ROL_SEGUIMIENTO || idRol == REACT_APP_ROL_CONTABILIDAD) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    return (
                                        usuario.b2CId == usuarioAsignado.b2CId
                                    );
                                });
                            } else {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSeleccionado = [];
                                    usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSeleccionado[0].rol.id == REACT_APP_ROL_EJECUTIVO &&
                                        usuario.b2CId == idUsuarioLogueado
                                    );
                                });
                            }
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO) {
                            if (idRol == REACT_APP_ROL_ADMINISTRATIVO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    return (
                                        usuario.b2CId == usuarioAsignado.b2CId
                                    );
                                });
                            }
                            if (idRol == REACT_APP_ROL_OPERATIVO) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSeleccionado = [];
                                    usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSeleccionado[0].rol.id == idRol &&
                                        usuario.b2CId == idUsuarioLogueado
                                    );
                                });
                            }
                            if (idRol == REACT_APP_ROL_SEGUIMIENTO || idRol == REACT_APP_ROL_CONTABILIDAD) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    return (
                                        usuario.b2CId == usuarioAsignado.b2CId
                                    );
                                });
                            }
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA) {
                            if (idRol == REACT_APP_ROL_INCLUSION) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSeleccionado = [];
                                    usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSeleccionado[0].entidadFinancieraAutorizada.id == idCliente &&
                                        usuarioSeleccionado[0].rol.id == REACT_APP_ROL_INCLUSION &&
                                        usuario.b2CId == idUsuarioLogueado
                                    );
                                });
                            }
                            if (idRol == REACT_APP_ROL_OPERATIVO) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSeleccionado = [];
                                    usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSeleccionado[0].entidadFinancieraAutorizada.id == idCliente &&
                                        (usuario.b2CId == idUsuarioLogueado || usuario.b2CId == origen.b2CId)
                                    );
                                });
                            }
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSeleccionado = [];
                                    usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSeleccionado[0].entidadFinancieraAutorizada.id == idCliente &&
                                        usuarioSeleccionado[0].rol.id == REACT_APP_ROL_INCLUSION
                                    );
                                });
                            }
                            if (idRol == REACT_APP_ROL_ADMINISTRATIVO || idRol == REACT_APP_ROL_SEGUIMIENTO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME || idRol == REACT_APP_ROL_CONTABILIDAD) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    return (
                                        usuario.b2CId == usuarioAsignado.b2CId
                                    );
                                });
                            }
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA) {
                            filtroUsuarios = data.data.filter(function (usuario) {
                                return (
                                    usuario.b2CId == usuarioAsignado.b2CId
                                );
                            });
                        }
                        if (filtroUsuarios.length == 1) {
                            this.state.ejecutivoSeleccionado = filtroUsuarios[0];
                        } else {
                            this.state.ejecutivoSeleccionado = usuarioAsignado;
                        }
                        this.setState({
                            ejecutivoLista: filtroUsuarios,
                            ejecutivoSeleccionado: this.state.ejecutivoSeleccionado,
                            usuariosListaDatatable: data.data
                        });
                    }
                },
                type: "POST",
            },
            columns: [{ data: "b2CId", name: "b2CId" }],
        });
    };
    /**
     * Listar los estados de honramientos
     **/
    cargarEstado = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_ESTADO_AVAL,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_400) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    $("#spinEstado").show();
                    return;
                }
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_500) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    $("#spinEstado").show();
                    return;
                }
                $("#spinEstado").hide();
                let idUsuarioLogueado = localStorage.getItem("idUsuarioCreador");
                if (this.state.idOperadora != REACT_APP_OPERADORA_FODEMIPYME && this.state.idOperadora != REACT_APP_OPERADORA_FNA) {
                    var nuevoEstados = [];
                    var ejecutivo = this.state.historicoLista;
                    let ultimoRegistro = ejecutivo[ejecutivo.length - 1];
                    var ultimoUsuarioOrigen = [];
                    ultimoUsuarioOrigen = ultimoRegistro.usuarioOrigen.permisos.filter(function (permiso) {
                        return (
                            permiso.fondo.id == idFondo
                        );
                    });
                    if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD) {
                        if (idRol == REACT_APP_ROL_INCLUSION) {
                            nuevoEstados = res.json.catalogoValores.filter(function (
                                estado
                            ) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD;
                            });
                        }
                        if (idRol == REACT_APP_ROL_INCLUSION && ultimoUsuarioOrigen[0].rol.id == REACT_APP_ROL_INCLUSION) {
                            this.state.indNoEditarEstado = true;
                            this.setState({ indNoEditarEstado: true });
                        }
                        if (idRol == REACT_APP_ROL_OPERATIVO) {
                            nuevoEstados = res.json.catalogoValores.filter(function (
                                estado
                            ) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME;
                            });
                        }
                        if (
                            idRol == REACT_APP_ROL_OPERATIVO &&
                            this.state.idUsuarioAsignado != idUsuarioConectado
                        ) {
                            let texto =
                                nombreUsuarioConectado +
                                ", usted debe asignarse este honramiento para continuar con el flujo.";
                            swal(fondoSeleccionado.nombreFondo, texto, "warning", { button: "Aceptar", });
                            this.state.indNoEditarEstado = true;
                            this.setState({ indNoEditarEstado: true });
                        }
                        if (idRol == REACT_APP_ROL_JEFATURA) {
                            nuevoEstados = res.json.catalogoValores.filter(function (
                                estado
                            ) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD;
                            });
                            this.state.indNoEditarEstado = true;
                            this.setState({ indNoEditarEstado: true });
                        }
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD) {
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD;
                        });
                        if (idRol == REACT_APP_ROL_JEFATURA) {
                            this.state.indNoEditarEstado = true;
                            this.setState({ indNoEditarEstado: true });
                        }
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME) {
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME || estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME;
                        });
                        if (idRol == REACT_APP_ROL_JEFATURA) {
                            this.state.indNoEditarEstado = true;
                            this.setState({ indNoEditarEstado: true });
                        }
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO) {
                        this.state.indNoEditar = true;
                        this.setState({ indNoEditar: true });
                        this.state.indNoEditarEstado = true;
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEstado: true });
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarObservaciones = true;
                        this.setState({ indNoEditarObservaciones: true });
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO || estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA;
                        });
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA) {
                        if (idRol == REACT_APP_ROL_INCLUSION) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indDocumentacionFormalizacion = false;
                            this.setState({ indDocumentacionFormalizacion: false });
                            nuevoEstados = res.json.catalogoValores.filter(function (
                                estado
                            ) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA || estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA;
                            });
                        }
                        if (idRol == REACT_APP_ROL_OPERATIVO) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indDocumentacionFormalizacion = false;
                            this.setState({ indDocumentacionFormalizacion: false });
                            nuevoEstados = res.json.catalogoValores.filter(function (
                                estado
                            ) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA || estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA;
                            });
                        }
                        if (idRol == REACT_APP_ROL_JEFATURA) {
                            this.state.indNoEditarEstado = true;
                            this.setState({ indNoEditarEstado: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (
                                estado
                            ) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA;
                            });
                        }
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA) {
                        this.state.indNoEditar = true;
                        this.setState({ indNoEditar: true });
                        this.state.indNoEditarEstado = true;
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEstado: true });
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarObservaciones = true;
                        this.setState({ indNoEditarObservaciones: true });
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA || estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE;
                        });

                        if (idRol == REACT_APP_ROL_INCLUSION) {
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA;
                            });
                        }
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_RECUPERABLE) {
                        this.state.indNoEditar = true;
                        this.setState({ indNoEditar: true });
                        this.state.indNoEditarEstado = true;
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEstado: true });
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarObservaciones = true;
                        this.setState({ indNoEditarObservaciones: true });
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_RECUPERABLE;
                        });
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE) {
                        this.state.indNoEditar = true;
                        this.setState({ indNoEditar: true });
                        this.state.indNoEditarEstado = true;
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEstado: true });
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarObservaciones = true;
                        this.setState({ indNoEditarObservaciones: true });
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE;
                        });
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                } else {
                    if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD) {
                        if (idRol == REACT_APP_ROL_SEGUIMIENTO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME || idRol == REACT_APP_ROL_CONTABILIDAD) {
                            $("#botonModificar").hide();
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indButton = true;
                            this.setState({ indButton: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                        }
                        if (idRol == REACT_APP_ROL_ADMINISTRATIVO) {
                            $("#botonModificar").hide();
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indButton = true;
                            this.setState({ indButton: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                        }
                        var nuevoEstados = [];
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD;
                        });
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD) {
                        if (idRol == REACT_APP_ROL_ADMINISTRATIVO || idRol == REACT_APP_ROL_SEGUIMIENTO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME || idRol == REACT_APP_ROL_CONTABILIDAD) {
                            $("#botonModificar").hide();
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indButton = true;
                            this.setState({ indButton: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                        }
                        var nuevoEstados = [];
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD;
                        });
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME) {
                        if (idRol == REACT_APP_ROL_ADMINISTRATIVO || idRol == REACT_APP_ROL_SEGUIMIENTO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME || idRol == REACT_APP_ROL_CONTABILIDAD) {
                            $("#botonModificar").hide();
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indButton = true;
                            this.setState({ indButton: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                        }
                        var nuevoEstados = [];
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME;
                        });
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME) {
                        var nuevoEstados = [];
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return (
                                estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME ||
                                estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME ||
                                estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO
                            );
                        });

                        if (idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME) {
                            this.state.indNoEditarEstado = true;
                            this.setState({ indNoEditarEstado: true });
                        }

                        if (idRol == REACT_APP_ROL_ADMINISTRATIVO) {
                            $("#botonModificar").show();
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indButton = false;
                            this.setState({ indButton: false });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = false;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: false });
                            this.state.indNoEditarObservaciones = false;
                            this.setState({ indNoEditarObservaciones: false });
                        }

                        if (idRol == REACT_APP_ROL_SEGUIMIENTO || idRol == REACT_APP_ROL_CONTABILIDAD) {
                            $("#botonModificar").hide();
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indButton = true;
                            this.setState({ indButton: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            var nuevoEstados = [];
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME;
                            });
                        }
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO) {
                        var nuevoEstados = [];
                        if (
                            idRol == REACT_APP_ROL_ADMINISTRATIVO
                            && this.state.idUsuarioAsignado != idUsuarioConectado
                        ) {
                            this.state.indNoEditarEstado = true;
                            this.setState({ indNoEditarEstado: true });
                        }

                        if (idRol == REACT_APP_ROL_ADMINISTRATIVO && this.state.idUsuarioAsignado == idUsuarioConectado) {
                            $("#botonModificar").show();
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indButton = false;
                            this.setState({ indButton: false });
                            this.state.indNoEditarEstado = false;
                            this.setState({ indNoEditarEstado: false });
                            this.state.indNoEditarEjecutivo = false;
                            this.setState({ indNoEditarEjecutivo: false });
                            this.state.indNoEditarObservaciones = false;
                            this.setState({ indNoEditarObservaciones: false });
                        }

                        if (idRol == REACT_APP_ROL_SEGUIMIENTO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME || idRol == REACT_APP_ROL_CONTABILIDAD) {
                            $("#botonModificar").hide();
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indButton = true;
                            this.setState({ indButton: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                        }
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO || estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA;
                        });
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA) {
                        var nuevoEstados = [];
                        if (idRol == REACT_APP_ROL_ADMINISTRATIVO || idRol == REACT_APP_ROL_SEGUIMIENTO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME || idRol == REACT_APP_ROL_CONTABILIDAD) {
                            $("#botonModificar").hide();
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indButton = true;
                            this.setState({ indButton: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                        }

                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA || estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA;
                        });
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA) {
                        if (
                            idRol == REACT_APP_ROL_ADMINISTRATIVO
                            && this.state.idUsuarioAsignado != idUsuarioConectado
                        ) {
                            this.state.indNoEditarEstado = true;
                            this.setState({ indNoEditarEstado: true });
                        }

                        if (idRol == REACT_APP_ROL_ADMINISTRATIVO && this.state.idUsuarioAsignado == idUsuarioConectado) {
                            $("#botonModificar").show();
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indButton = false;
                            this.setState({ indButton: false });
                            this.state.indNoEditarEstado = false;
                            this.setState({ indNoEditarEstado: false });
                            this.state.indNoEditarEjecutivo = false;
                            this.setState({ indNoEditarEjecutivo: false });
                            this.state.indNoEditarObservaciones = false;
                            this.setState({ indNoEditarObservaciones: false });
                        }

                        if (idRol == REACT_APP_ROL_SEGUIMIENTO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME || idRol == REACT_APP_ROL_CONTABILIDAD) {
                            $("#botonModificar").hide();
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indButton = true;
                            this.setState({ indButton: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                        }
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA || estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_RECUPERABLE || estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE;
                        });
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_RECUPERABLE ||
                        this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE) {
                        $("#botonModificar").hide();
                        this.state.indNoEditar = true;
                        this.setState({ indNoEditar: true });
                        this.state.indButton = true;
                        this.setState({ indButton: true });
                        this.state.indNoEditarEstado = true;
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEstado: true });
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarObservaciones = true;
                        this.setState({ indNoEditarObservaciones: true });

                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_RECUPERABLE;
                        });
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                }
            }
        });
    };
    /**
     * 
     * @param {*} event
     * @returns Cambiar el estado seleccionado 
     **/
    cambioEstadoAval = (event) => {
        let estado = this.state.estadosLista.filter(
            (x) => x.id == event.target.value
        );
        this.state.estadoSeleccionado = estado[0];
        this.setState({ estadoSeleccionado: estado[0] });

        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA) {
            this.state.indHonramientoConDemanda = true;
            this.setState({ indHonramientoConDemanda: true });
        }

        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA) {
            this.state.indHonramientoConDemanda = false;
            this.setState({ indHonramientoConDemanda: false });

            this.state.fechaPresentacionDemanda = null;
            this.setState({ fechaPresentacionDemanda: null });

            this.state.nombreArchivoDemanda = null;
            this.setState({ nombreArchivoDemanda: null });

            document.getElementById("nombreArchivoDemandaInputFile").value = null;
        }

        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE) {
            this.state.indHonramientoIrrecuperable = true;
            this.setState({ indHonramientoIrrecuperable: true });
        }

        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_RECUPERABLE ||
            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA) {
            this.state.indHonramientoIrrecuperable = false;
            this.setState({ indHonramientoIrrecuperable: false });

            this.state.nombreArchivoEvidenciaIrrecuperable = null;
            this.setState({ nombreArchivoEvidenciaIrrecuperable: null });
        }
        this.cambioEjecutivoXEstado();
    };
    /**
     * 
     * @param {*} event
     * @returns Cambiar el usuario segun el estado seleccionado
     **/
    cambioEjecutivoXEstado = () => {
        let idUsuarioLogueado = localStorage.getItem("idUsuarioCreador");
        const ejecutivoListaJson = this.state.usuariosListaDatatable;

        let ejecutivos = [];

        if (
            idRol == REACT_APP_ROL_OPERATIVO &&
            (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD ||
                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME ||
                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO)
        ) {
            ejecutivos = ejecutivoListaJson.filter((x) => x.b2CId == idUsuarioLogueado);
            this.state.ejecutivoLista = ejecutivos;
            this.setState({
                ejecutivoLista: ejecutivos,
                ejecutivoSeleccionado: ejecutivos[0],
            });
        }

        if (idRol == REACT_APP_ROL_INCLUSION && this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD) {
            let usuarioReciente = this.state.idOperativoReciente;
            if (this.state.idEjecutivoFodemipymeReciente != null) {
                usuarioReciente = this.state.idEjecutivoFodemipymeReciente;
            }
            ejecutivos = ejecutivoListaJson.filter((x) => x.b2CId == usuarioReciente);
            this.state.ejecutivoLista = ejecutivos;
            this.setState({
                ejecutivoLista: ejecutivos,
                ejecutivoSeleccionado: ejecutivos[0],
            });
        }
        if (idRol == REACT_APP_ROL_ADMINISTRATIVO && this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA) {
            ejecutivos = ejecutivoListaJson.filter(
                (x) => x.b2CId == this.state.idUsuarioAsignado
            );
            this.state.ejecutivoLista = ejecutivos;
            this.setState({
                ejecutivoLista: ejecutivos,
                ejecutivoSeleccionado: ejecutivos[0],
            });
        }
        if (idRol == REACT_APP_ROL_INCLUSION && this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME) {
            let usuarioReciente = this.state.idOperativoReciente;
            if (this.state.idEjecutivoFodemipymeReciente != null) {
                usuarioReciente = this.state.idEjecutivoFodemipymeReciente;
                var data = {
                    IdUsuario: usuarioReciente,
                };

                ObtenerUsuarioPorID(data).then((res) => {
                    if (res) {
                        if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                            swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                            return;
                        }
                        ejecutivos.push(res.json.usuario);
                        this.setState({
                            ejecutivoLista: ejecutivos,
                            ejecutivoSeleccionado: res.json.usuario,
                        });
                    }
                });
            } else {
                ejecutivos = ejecutivoListaJson.filter(
                    (x) => x.b2CId == usuarioReciente
                );
                this.state.ejecutivoLista = ejecutivos;
                this.setState({
                    ejecutivoLista: ejecutivos,
                    ejecutivoSeleccionado: ejecutivos[0],
                });
            }
        }
        if (
            idRol == REACT_APP_ROL_JEFATURA &&
            (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME ||
                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA)
        ) {
            ejecutivos = ejecutivoListaJson.filter((x) => x.b2CId == idUsuarioLogueado);
            this.state.ejecutivoLista = ejecutivos;
            this.setState({
                ejecutivoLista: ejecutivos,
                ejecutivoSeleccionado: ejecutivos[0],
            });
        }
        if (
            idRol == REACT_APP_ROL_EJECUTIVO &&
            (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME ||
                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO)
        ) {
            ejecutivos = ejecutivoListaJson.filter((x) => x.b2CId == idUsuarioLogueado);
            this.state.ejecutivoLista = ejecutivos;
            this.setState({
                ejecutivoLista: ejecutivos,
                ejecutivoSeleccionado: ejecutivos[0],
            });
        }
        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD) {
            ejecutivos = ejecutivoListaJson.filter(
                (x) => x.b2CId == this.state.idInclusorReciente
            );
            this.state.ejecutivoLista = ejecutivos;
            this.setState({
                ejecutivoLista: ejecutivos,
                ejecutivoSeleccionado: ejecutivos[0],
            });
        }
        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME) {
            ejecutivos = ejecutivoListaJson.filter(
                (x) => x.b2CId == this.state.idInclusorReciente
            );
            this.state.ejecutivoLista = ejecutivos;
            this.setState({
                ejecutivoLista: ejecutivos,
                ejecutivoSeleccionado: ejecutivos[0],
            });
        }
    };
    /**
       * 
       * @param {*} event
       * @returns Cambiar el ejecutivo seleccionado 
       **/
    cambioEjecutivo = (event) => {
        let ejecutivo = this.state.ejecutivoLista.filter(
            (x) => x.b2CId == event.target.value
        );
        this.setState({ ejecutivoSeleccionado: ejecutivo[0] });
    };
    /**
     * 
     * @param {*} event 
     * @returns Alamacena en el State lo digitado en el formulario. 
     **/
    encargarseCambio = (event) => {
        if (event.target.name === "fechaPresentacionDemanda") {
            this.setState({ indFechaPresentacionDemanda: true });
        }
        if (
            event.target.name === "observacion" &&
            event.target.value.length > 1000
        ) {
            swal(
                "SAAG",
                "Ha sobrepasado la cantidad máxima de caracteres permitidos para la observación",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        this.setState({ [event.target.name]: event.target.value });
    };
    /**
     * 
     * @param {*} event 
     * @returns Alamacena en el State los archivos del formulario. 
     **/
    encargarseCambioArchivos = (event) => {
        if (event.target.files.length === 0) {
            this.setState({ [event.target.name]: null });
            return;
        }
        if (event.target.files[0].type != "application/pdf") {
            swal("Honramiento", "El archivo tiene que ser de tipo PDF", "error", { button: "Aceptar", });
            return;
        }
        this.setState({ [event.target.name]: event.target.files[0] });
    };
    /**
     * 
     * @param {*} event 
     * @returns Descargar el archivo demanda
     **/
    descargarArchivoDemanda = (event) => {
        event.preventDefault();
        if (this.state.indDescargarArchivoDemanda) return;

        if (this.state.nombreArchivoDemanda == "") {
            swal(
                "Descarga de Archivos",
                "Hubo un error al descargar el archivo. Intente mas tarde",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        const urlDescarga = `https://${window.location.host}/api/DescargarArchivo?nombreArchivo=${this.state.nombreArchivoDemanda}&fondo=${localStorage.getItem("idFondo")}`;
        window.location.assign(urlDescarga);
    };
    /**
     * 
     * @param {*} event 
     * @returns Descargar el archivo irrecuperable
     **/
    descargarArchivoIrrecuperable = (event) => {
        event.preventDefault();
        if (this.state.indDescargarArchivoIrrecuperable) return;

        if (this.state.nombreArchivoEvidenciaIrrecuperable == "") {
            swal(
                "Descarga de Archivos",
                "Hubo un error al descargar el archivo. Intente mas tarde",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        const urlDescarga = `https://${window.location.host}/api/DescargarArchivo?nombreArchivo=${this.state.nombreArchivoEvidenciaIrrecuperable}&fondo=${localStorage.getItem("idFondo")}`;
        window.location.assign(urlDescarga);
    };
    /**
     * 
     * @param {*} event 
     * @returns Descargar el archivo evidencia del honramiento
     **/
    descargarArchivoEvidenciaHonramiento = (event) => {
        event.preventDefault();
        if (this.state.indDescargarArchivoEvidencia) return;

        if (this.state.nombreArchivoEvidenciaHonramiento == "") {
            swal(
                "Descarga de Archivos",
                "Hubo un error al descargar el archivo. Intente mas tarde",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        const urlDescarga = `https://${window.location.host}/api/DescargarArchivo?nombreArchivo=${this.state.nombreArchivoEvidenciaHonramiento}&fondo=${localStorage.getItem("idFondo")}`;
        window.location.assign(urlDescarga);
    };
    /**
      * 
      * @param {*} event
      * @returns Habiltiar el campo para adjutar la evidencia de honramiento irrecuperable 
      **/
    mostrarInputArchivoIrrecuperable = (event) => {
        event.preventDefault();
        $("#nombreArchivoEvidenciaIrrecuperable").show();
        this.state.indDescargarArchivoIrrecuperable = true;
        this.setState({ indDescargarArchivoIrrecuperable: true });
    };
    /**
     * 
     * @param {*} event
     * @returns Habiltiar el campo para adjutar la demanda 
     **/
    mostrarInputArchivoDemanda = (event) => {
        event.preventDefault();
        $("#nombreArchivoDemanda").show();
        this.state.indDescargarArchivoDemanda = true;
        this.setState({ indDescargarArchivoDemanda: true });
    };
    /**
     * 
     * @param {*} event
     * @returns Habiltiar el campo para adjutar la evidencia de honramiento 
     **/
    mostrarInputArchivoEvidenciaHonramiento = (event) => {
        event.preventDefault();
        $("#nombreArchivoEvidenciaHonramiento").show();
        this.state.indDescargarArchivoEvidencia = true;
        this.setState({ indDescargarArchivoEvidencia: true });
    };
    /**
     * 
     * @param {*} event 
     * @returns Modificacion de un honramiento 
     **/
    modificarHonramiento = (event) => {
        event.preventDefault();
        $("#guardando").show();
        $("#floppy").hide();
        const numeros = new RegExp(/^[0-9,.]*$/);
        const soloNumerosMontoHonramiento = numeros.test(this.state.montoHonramiento);
        if (
            this.state.montoHonramiento === "" || Number(this.state.montoHonramiento)
                ? this.state.montoHonramiento == 0
                : parseFloat(this.state.montoHonramiento.replace(/,/g, "")) == 0
        ) {
            swal("Honramiento", "El monto del honramiento es requerido", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }
        if (!soloNumerosMontoHonramiento) {
            swal(
                "Honramiento",
                "El monto del honramiento no tiene un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (
            Number(this.state.montoHonramiento)
                ? this.state.montoHonramiento < 0
                : parseFloat(this.state.montoHonramiento.replace(/,/g, "")) < 0) {
            swal(
                "Honramiento",
                "El monto del honramiento no puede ser menor a 0",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (
            Number(this.state.montoHonramiento)
                ? this.state.montoHonramiento > REACT_APP_LONGITUD_CAMPO_MONTOS
                : parseFloat(this.state.montoHonramiento.replace(/,/g, "")).toFixed(2) >
                REACT_APP_LONGITUD_CAMPO_MONTOS) {
            swal(
                "Honramiento",
                "El monto del honramiento no puede ser mayor a 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.diasMorosidad === "") {
            swal("Honramiento", "Los días de morosidad son requeridos", "error", { button: "Aceptar", });
            return;
        }
        if (this.state.diasMorosidad.length < 0) {
            swal("Honramiento", "Los días de morosidad no pueden ser un número negativo", "error", { button: "Aceptar", });
            return;
        }
        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA) {
            if (this.state.fechaPresentacionDemanda == null) {
                swal(
                    "Honramiento",
                    "La fecha de presentación de la demanda es requerida",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
            if (this.state.nombreArchivoDemanda == null) {
                swal(
                    "Honramiento",
                    "El archivo de la demanda es requerido",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
        }
        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE) {
            if (this.state.nombreArchivoEvidenciaIrrecuperable == null) {
                swal(
                    "Honramiento",
                    "El archivo de evidencia del honramiento irrecuperable es requerido para pasar el honramiento a estado irrecuperable",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
        }
        let data = {
            Id: parseInt(this.state.id),
            ArchivoDemanda: this.state.nombreArchivoDemanda,
            MontoHonramiento: Number(this.state.montoHonramiento)
                ? this.state.montoHonramiento
                : parseFloat(this.state.montoHonramiento.replace(/,/g, "")),
            FechaPresentacionDemanda: this.state.indFechaPresentacionDemanda
                ? this.state.fechaPresentacionDemanda
                : null,
            IdEjecutivo: this.state.ejecutivoSeleccionado.b2CId,
            IdEstado: parseInt(this.state.estadoSeleccionado.id),
            ArchivoEvidenciaHonramiento: this.state.nombreArchivoEvidenciaHonramiento,
            ArchivoEvidenciaIrrecuperable: this.state.nombreArchivoEvidenciaIrrecuperable,
            DiasMorosidad: this.state.diasMorosidad
        };
        let texto = "";
        texto = "Estado de Honramiento: " + this.state.estadoSeleccionado.valor;
        swal({
            title: "Estás seguro/segura?",
            text: texto,
            icon: "warning",
            buttons: ["NO", "SÍ"],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.setState({ indButton: true });
                ModificarHornamiento(data).then((res) => {
                    if (res) {
                        swal(
                            fondoSeleccionado.nombreFondo,
                            "¡Honramiento editado con éxito!",
                            "success", { button: "Aceptar", }
                        ).then((value) => {
                            window.location.href = "/honramientos";
                        });
                    }
                    $("#guardando").hide();
                    $("#floppy").show();
                    this.setState({ indButton: false });
                });
            } else {
                $("#guardando").hide();
                $("#floppy").show();
            }
        });
    };
    render() {
        return (
            <div className="container text-center ">
                <table id="tabla1"></table>
                <center>
                    <h2>
                        Editar Honramiento: <b>#{this.state.id}</b>
                    </h2>
                </center>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="/admin">Inicio</a>
                        </li>
                        <li className="breadcrumb-item">
                            <a href="/honramientos">Honramientos</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Editar Honramiento
                        </li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="form-crear-honramiento">
                        <div className="col-md-12">
                            <Container fluid="md" style={{ padding: "20px 0 10px 0" }}>
                                <Row>
                                    <Col md={12}>
                                        <Form.Row>
                                            <Col xs={12} md={5}>
                                                <Form.Group>
                                                    <Form.Label>Monto Honramiento</Form.Label>
                                                    <NumberFormat
                                                        thousandSeparator={true}
                                                        type="text"
                                                        name="montoHonramiento"
                                                        className="form-control"
                                                        onChange={this.encargarseCambio}
                                                        min="0"
                                                        max="999999999999"
                                                        placeholder="0"
                                                        value={this.state.montoHonramiento}
                                                        required
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={2}>
                                                <Form.Group>
                                                    <Form.Label>Días Morosidad</Form.Label>
                                                    <input
                                                        type="number"
                                                        name="diasMorosidad"
                                                        className="form-control"
                                                        onChange={this.encargarseCambio}
                                                        min="0"
                                                        max="999999999999"
                                                        placeholder="0"
                                                        value={this.state.diasMorosidad}
                                                        required
                                                        disabled={this.state.indButton || this.state.indNoEditar}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={5}>
                                                <Form.Label>
                                                    {" "}
                                                    <strong>Archivo Evidencia Honramiento</strong>
                                                </Form.Label>
                                                {this.state.indArchivoEvidenciaHonramiento ? (
                                                    <Form.Group>
                                                        <a
                                                            onClick={this.descargarArchivoEvidenciaHonramiento}
                                                            className="btn btn-lg"
                                                            style={{ cursor: "pointer", borderRadius: "20px" }}
                                                            hidden={this.state.indDescargarArchivoEvidencia}
                                                        >
                                                            <img
                                                                src="/assets/images/pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>{" "}
                                                        <a
                                                            onClick={this.mostrarInputArchivoEvidenciaHonramiento}
                                                            className="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                            hidden={this.state.indNoEditar}
                                                        >
                                                            <img
                                                                src="/assets/images/upload-pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.File
                                                            name="nombreArchivoEvidenciaHonramiento"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled={this.state.indButton}
                                                        />
                                                    </Form.Group>
                                                )}
                                                <Form.Group id="nombreArchivoEvidenciaHonramiento">
                                                    <Form.File
                                                        name="nombreArchivoEvidenciaHonramiento"
                                                        onChange={this.encargarseCambioArchivos}
                                                        accept="application/pdf"
                                                        disabled={this.state.indButton}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                        <Form.Row>
                                            <Col xs={12} md={6}>
                                                {this.state.indNoEditarEstado ? (
                                                    <Form.Group>
                                                        <Form.Label>Estado Honramiento</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={this.cambioEstadoAval}
                                                            disabled
                                                            value={this.state.estadoSeleccionado.id}
                                                        >
                                                            {this.state.estadosLista.map(function (data, key) {
                                                                return (
                                                                    <option key={key} value={data.id}>
                                                                        {data.valor}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.Label>Estado Honramiento</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={this.cambioEstadoAval}
                                                            disabled={this.state.indButton}
                                                            value={this.state.estadoSeleccionado.id}
                                                        >
                                                            {this.state.estadosLista.map(function (data, key) {
                                                                return (
                                                                    <option key={key} value={data.id}>
                                                                        {data.valor}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                    </Form.Group>
                                                )}
                                                <span className="input-group-addon" id="spinEstado">
                                                    <i className="fa fa-refresh fa-spin"></i>
                                                </span>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                {this.state.indNoEditarEjecutivo ? (
                                                    <Form.Group>
                                                        <Form.Label>Usuario</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={this.cambioEjecutivo}
                                                            disabled
                                                            value={this.state.ejecutivoSeleccionado.b2CId}
                                                        >
                                                            {this.state.ejecutivoLista.map(function (
                                                                data,
                                                                key
                                                            ) {
                                                                return (
                                                                    <option key={key} value={data.b2CId}>
                                                                        {data.correoElectronico}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.Label>Usuario</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={this.cambioEjecutivo}
                                                            disabled={this.state.indButton}
                                                            value={this.state.ejecutivoSeleccionado.b2CId}
                                                        >
                                                            {this.state.ejecutivoLista.map(function (
                                                                data,
                                                                key
                                                            ) {
                                                                return (
                                                                    <option key={key} value={data.b2CId}>
                                                                        {data.correoElectronico}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                    </Form.Group>
                                                )}
                                                <span className="input-group-addon" id="spinUsuario">
                                                    <i className="fa fa-refresh fa-spin"></i>
                                                </span>
                                            </Col>
                                        </Form.Row>
                                        <hr hidden={!this.state.indHonramientoConDemanda}></hr>
                                        <Form.Row>
                                            <Col xs={12} md={6}>
                                                {this.state.indHonramientoConDemanda ? (
                                                    <Form.Group>
                                                        <Form.Label>Fecha Presentación Demanda</Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            name="fechaPresentacionDemanda"
                                                            className="form-control"
                                                            onChange={this.encargarseCambio}
                                                            value={this.state.fechaPresentacionDemanda}
                                                            required
                                                            disabled={this.state.indButton || this.state.indNoEditarFechaDemanda}
                                                        />
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group></Form.Group>
                                                )}
                                            </Col>
                                            <Col xs={12} md={6} hidden={!this.state.indHonramientoConDemanda}>
                                                <Form.Label>
                                                    {" "}
                                                    <strong>Archivo Evidencia Demanda</strong>
                                                </Form.Label>
                                                {this.state.indNombreArchivoDemanda ? (
                                                    <Form.Group>
                                                        <a
                                                            onClick={this.descargarArchivoDemanda}
                                                            className="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                            hidden={this.state.indDescargarArchivoDemanda}
                                                        >
                                                            <img
                                                                src="/assets/images/pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>{" "}
                                                        <a
                                                            onClick={this.mostrarInputArchivoDemanda}
                                                            className="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                            hidden
                                                        >
                                                            <img
                                                                src="/assets/images/upload-pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.File
                                                            id="nombreArchivoDemandaInputFile"
                                                            name="nombreArchivoDemanda"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled={this.state.indButton}
                                                        />
                                                    </Form.Group>
                                                )}
                                                <Form.Group id="nombreArchivoDemanda">
                                                    <Form.File
                                                        id="nombreArchivoDemandaInputFile"
                                                        name="nombreArchivoDemanda"
                                                        onChange={this.encargarseCambioArchivos}
                                                        accept="application/pdf"
                                                        disabled={this.state.indButton}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                        <hr hidden={!this.state.indHonramientoIrrecuperable}></hr>
                                        <Form.Row>
                                            <Col xs={12} md={3}></Col>
                                            <Col xs={12} md={6} hidden={!this.state.indHonramientoIrrecuperable}>
                                                <Form.Label>
                                                    {" "}
                                                    <strong>Archivo Evidencia Honramiento Irrecuperable</strong>
                                                </Form.Label>
                                                {this.state.indArchivoInputIrrecuperable ? (
                                                    <Form.Group>
                                                        <a
                                                            onClick={this.descargarArchivoIrrecuperable}
                                                            className="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                            hidden={this.state.indDescargarArchivoIrrecuperable}
                                                        >
                                                            <img
                                                                src="/assets/images/pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>{" "}
                                                        <a
                                                            onClick={this.mostrarInputArchivoIrrecuperable}
                                                            className="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                            hidden
                                                        >
                                                            <img
                                                                src="/assets/images/upload-pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.File
                                                            name="nombreArchivoEvidenciaIrrecuperable"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled={this.state.indButton}
                                                        />
                                                    </Form.Group>
                                                )}
                                            </Col>
                                            <Col xs={12} md={3}></Col>
                                        </Form.Row>
                                        <hr></hr>
                                        <Row style={{ padding: "0 0 20px 0" }}>
                                            <Col xs={12} md={12}>
                                                {this.state.indNoEditar &&
                                                    this.state.indNoEditarObservaciones ? (
                                                    <Form.Group>
                                                        <Form.Label>Observación</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            name="observacion"
                                                            onChange={this.encargarseCambio}
                                                            value={this.state.observacion}
                                                            rows={3}
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.Label>Observación</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            name="observacion"
                                                            onChange={this.encargarseCambio}
                                                            value={this.state.observacion}
                                                            rows={3}
                                                            disabled={this.state.indButton}
                                                        />
                                                    </Form.Group>
                                                )}
                                            </Col>
                                            <Col xs={12} md={12}>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary border-radius"
                                                    disabled={this.state.indButton}
                                                    hidden={
                                                        this.state.indNoEditarObservaciones ||
                                                        this.state.indEditarObservaciones
                                                    }
                                                    onClick={this.AgregarObservacionHonramiento}
                                                >
                                                    Guardar observación
                                                    {" "}
                                                    <i className="fa fa-edit" aria-hidden="true"></i>
                                                </button>
                                            </Col>
                                            {this.state.indEditarObservaciones ? (
                                                <Col xs={12} md={12}>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary border-radius"
                                                        disabled={this.state.indButton}
                                                        onClick={this.EditarObservacionHonramiento}
                                                    >
                                                        Editar observación
                                                        {" "}
                                                        <i className="fa fa-edit" aria-hidden="true"></i>
                                                    </button>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-danger border-radius"
                                                        disabled={this.state.indButton}
                                                        onClick={this.CancelarEdicionObservacion}
                                                    >
                                                        Cancelar
                                                        {" "}
                                                        <i className="fa fa-window-close" aria-hidden="true"></i>
                                                    </button>
                                                </Col>
                                            ) : (
                                                <Col></Col>
                                            )}
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                {this.state.indMostrarHistoricoObservaciones ? (
                                                    <Form.Row>
                                                        <Col xs={12} md={12}>
                                                            <div className="table-responsive">
                                                                <table
                                                                    className="table table-striped table-bordered"
                                                                    style={{ width: "100%" }}
                                                                >
                                                                    <thead>
                                                                        <tr>
                                                                            <th colSpan="6">Histórico observaciones</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th className="text-center">Fecha creación</th>
                                                                            <th className="text-center">Usuario</th>
                                                                            <th className="text-center">Observación</th>
                                                                            <th>Acciones</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.historicoObservaciones.map(
                                                                            (c, index) => {
                                                                                return (
                                                                                    <tr id={c.id}>
                                                                                        <td>
                                                                                            {moment(c.fechaCreacion)
                                                                                                .locale("en")
                                                                                                .format("YYYY-MM-DD HH:mm:ss")}
                                                                                        </td>
                                                                                        <td>
                                                                                            {c.ejecutivoCreador.correoElectronico}
                                                                                        </td>
                                                                                        <td>{c.observaciones}</td>
                                                                                        <td>
                                                                                            {c.ejecutivoCreador.b2CId !=
                                                                                                idUsuarioConectado ||
                                                                                                this.state
                                                                                                    .indNoEditarObservaciones ? (
                                                                                                <a
                                                                                                    className="btn btn-primary btn-sm border-radius"
                                                                                                    style={{ cursor: "pointer" }}
                                                                                                    onClick={() =>
                                                                                                        this.MostrarEditarObservaciones(
                                                                                                            c,
                                                                                                            index
                                                                                                        )
                                                                                                    }
                                                                                                    hidden
                                                                                                >
                                                                                                    <i className="fa fa-pencil"></i>
                                                                                                </a>
                                                                                            ) : (
                                                                                                <a
                                                                                                    className="btn btn-primary btn-sm border-radius"
                                                                                                    style={{ cursor: "pointer" }}
                                                                                                    onClick={() =>
                                                                                                        this.MostrarEditarObservaciones(
                                                                                                            c,
                                                                                                            index
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <i className="fa fa-pencil"></i>
                                                                                                </a>
                                                                                            )}
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Col>
                                                    </Form.Row>
                                                ) : (
                                                    <Form.Row></Form.Row>
                                                )}
                                            </Col>
                                        </Row>
                                        <button
                                            className="btn-flotante border-radius"
                                            disabled={this.state.indButton}
                                            onClick={this.modificarHonramiento}
                                            hidden={this.state.indNoEditar && this.state.indNoEditarObservaciones}
                                        >
                                            <span
                                                id="guardando"
                                                className="spinner-border text-light"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                            <span id="guardando" className="sr-only">
                                                Loading...
                                            </span>
                                            <i
                                                id="floppy"
                                                className="fa fa-pencil-square-o"
                                                aria-hidden="true"
                                                style={{ color: "white" }}
                                            ></i>
                                        </button>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
