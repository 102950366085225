import React from "react";
import EditarHonramientoComponent from "../../Components/Honramiento/EditarHonramiento";
const EditarHonramientoView = () => {
  return (
    <div>
      <EditarHonramientoComponent />
    </div>
  );
};

export default EditarHonramientoView;
