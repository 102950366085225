import React from "react";
import ReporteGeneralAvalesComponent from "../../Components/ReporteGeneralAvales/ReporteGeneralAvales";

const ReporteGeneralAvalesView = () => {
  return (
    <div>
      <ReporteGeneralAvalesComponent />
    </div>
  );
};

export default ReporteGeneralAvalesView;
