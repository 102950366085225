import {
    REACT_APP_CODIGO_RESPUESTA_401
} from "../environment";
import {
    ObtenerUserAgent,
    ObtenerNombreUsuario,
} from "../Utils/BaseSolicitudModel";
import { BorrarLocalStorage } from "../Utils/BorrarInfoLocalStorage";
import swal from "sweetalert";

export const CrearCliente = async (request) => {
    let userAgent = ObtenerUserAgent();
    let nombreUsuario = ObtenerNombreUsuario();
    try {
        var data = {
            IP: localStorage.getItem("ip"),
            UserAgent: userAgent,
            NombreUsuario: nombreUsuario,
            Latitud: localStorage.getItem("latitud"),
            Longitud: localStorage.getItem("longitud"),
            IdFondo: request.IdFondo,
            NombreOperadora: request.NombreOperadora,
            CedulaJuridica: request.CedulaJuridica,
            Caracteristicas: request.Caracteristicas
        };
        const guardarCliente = await fetch(
            `/api/CrearCliente?fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        if (guardarCliente.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then(() => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const respuesta = {
            json: "",
            codigo: 0,
        };
        respuesta.json = await guardarCliente.json();
        respuesta.codigo = guardarCliente.status;

        return respuesta;
    } catch (error) {
        throw error;
    }
};

export const ModificarCliente = async (request) => {
    let userAgent = ObtenerUserAgent();
    let nombreUsuario = ObtenerNombreUsuario();
    try {
        var data = {
            IP: localStorage.getItem("ip"),
            UserAgent: userAgent,
            NombreUsuario: nombreUsuario,
            Latitud: localStorage.getItem("latitud"),
            Longitud: localStorage.getItem("longitud"),
            IdFondo: request.IdFondo,
            IdCliente: request.IdCliente,
            NombreOperadora: request.NombreOperadora,
            CedulaJuridica: request.CedulaJuridica,
            Caracteristicas: request.Caracteristicas,
        };
        const modificarCliente = await fetch(
            `/api/ModificarCliente?fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        if (modificarCliente.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then(() => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const respuesta = {
            json: "",
            codigo: 0,
        };
        respuesta.json = await modificarCliente.json();
        respuesta.codigo = modificarCliente.status;

        return respuesta;
    } catch (error) {
        throw error;
    }
};
