const $ = require("jquery");

export const ObtenerIp = () => {
  let ip;
  $.getJSON("https://api.ipify.org?format=json", function (data) {
    ip = data.ip;
    localStorage.setItem("ip", data.ip);
  });
  return ip;
};

export const ObtenerGeolocalizacion = () => {
  localStorage.setItem("latitud", "0");
  localStorage.setItem("longitud", "0");
};

export const ObtenerUserAgent = () => {
  var sBrowser,
    sUsrAg = navigator.userAgent;
  if (sUsrAg.indexOf("Chrome") > -1) {
    sBrowser = "Google Chrome";
  } else if (sUsrAg.indexOf("Safari") > -1) {
    sBrowser = "Apple Safari";
  } else if (sUsrAg.indexOf("Opera") > -1) {
    sBrowser = "Opera";
  } else if (sUsrAg.indexOf("Firefox") > -1) {
    sBrowser = "Mozilla Firefox";
  } else if (sUsrAg.indexOf("MSIE") > -1) {
    sBrowser = "Microsoft Internet Explorer";
  }

  return sBrowser;
};

export const ObtenerNombreUsuario = () => {
  var nombreUsuario = localStorage.getItem("usuarioLDAP");
  return nombreUsuario;
};

export const ObtenerFechaActual = () => {
  const tiempoTranscurrido = Date.now();
  const hoy = new Date(tiempoTranscurrido);

  return hoy.toLocaleDateString();
};
