import React from "react";
import ReporteHonramientosComponent from "../../Components/ReporteHonramientos/ReporteHonramientos";

const ReporteHonramientosView = () => {
  return (
    <div>
      <ReporteHonramientosComponent />
    </div>
  );
};

export default ReporteHonramientosView;
