import React, { Component } from "react";
import {
    REACT_APP_API_URL,
    REACT_APP_CODE,
    REACT_APP_CODIGO_RESPUESTA_200,
    REACT_APP_CODIGO_RESPUESTA_401,
    REACT_APP_ROL_ADMINISTRATIVO,
    REACT_APP_ROL_CONTABILIDAD,
    REACT_APP_ROL_SEGUIMIENTO,
    REACT_APP_ROL_REPRESENTANTE_FODEMIPYME,
    REACT_APP_MAXIMO_DIAS_RANGO_FECHA_PERMITIDO_REPORTES
} from "../../environment";
import { GenerarReporteLineasCreditoExcel } from "../../Services/ReporteServices";
import swal from "sweetalert";
import moment from "moment";
import { BorrarLocalStorage } from "../../Utils/BorrarInfoLocalStorage";
import { ObtenerUserAgent } from "../../Utils/BaseSolicitudModel";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import jszip from "jszip";
window.jszip = jszip;
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const $ = require("jquery");

$.DataTable = require("datatables.net-bs4");
require("datatables.net-responsive");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.print");
const userAgent = ObtenerUserAgent();
export default class ReporteLineaCreditoComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fechaInicio: moment().locale("en").format("YYYY-MM-DD"),
            fechaFin: moment().locale("en").format("YYYY-MM-DD"),
            datatable: null,
            token: null,
            mostrarDescargarReporte: false
        };
    }
    /**
     * Llamado inmediatamente después de montar un componente. 
     * Establecer el estado aquí activará la re-renderización.
     **/
    componentDidMount() {
        /**
         * Obtener el token de sesion
         **/
        const tokenContent = localStorage.getItem("isLoggedIn");
        if (!tokenContent) window.location.href = `https://${window.location.host}/seguridad/logout-b2c`
        this.setState({ token: tokenContent });
        /**
         * Creacion del DataTable
         **/
        $('#tabla tfoot th').each(function () {
            var title = $(this).text();
            $(this).html('<input type="text" class="columnaFiltroTabla" id="' + title + '" placeholder="Filtrar ' + title + '" />');
        });
        this.state.datatable = $("#tabla").DataTable({
            lengthChange: true,
            dom: "lBfrtip",
            buttons: [],
            processing: true,
            serverSide: true,
            language: {
                searchPlaceholder: "Buscar...",
                url: "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json",
            },
            initComplete: function () {
                // Apply the search
                this.api()
                    .columns()
                    .every(function () {
                        var that = this;
                        $('.columnaFiltroTabla', this.footer()).on('change clear', function () {
                            if (that.search() !== this.value) {
                                that.search(this.value).draw();
                            }
                        });

                        var table = $('#tabla').DataTable();
                        $(".dataTables_filter input")
                            .off()
                            .on('change clear', function (e) {
                                if (table.search() !== this.value) {
                                    table.search(this.value)
                                        .draw();
                                }
                            });
                    });
            },
            ajax: {
                url: `/api/ReporteLineaCredito?fondo=${localStorage.getItem("idFondo")}&fechaInicio=${this.state.fechaInicio}&fechaFin=${this.state.fechaFin}&ip=${localStorage.getItem("ip")}&latitud=${localStorage.getItem("latitud")}&longitud=${localStorage.getItem("longitud")}&userAgent=${userAgent}&usuario=${localStorage.getItem("idUsuarioCreador")}`,
                error: function (xhr, error, thrown) {
                    if (xhr.status == REACT_APP_CODIGO_RESPUESTA_401) {
                        swal({
                            title: "¡Sesión Expiró!",
                            text: "Por favor vuelva a iniciar sesión",
                            icon: "warning",
                            button: "Aceptar",
                        }).then((value) => {
                            BorrarLocalStorage();
                            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`
                        });
                        return;
                    }
                    if (xhr.status != REACT_APP_CODIGO_RESPUESTA_200) {
                        swal(
                            "Error al obtener el reporte general de avales",
                            thrown,
                            "error", { button: "Aceptar", }
                        );
                    }
                },
                type: "POST",
            },
            columns: [
                {
                    data: "nombreEntidadFinancieraAutorizada",
                    name: "nombreEntidadFinancieraAutorizada",
                },
                {
                    data: "estadoEntidadFinancieraAutorizada",
                    name: "estadoEntidadFinancieraAutorizada",
                },
                { data: "nombreBeneficiario", name: "nombreBeneficiario" },
                { data: "cedulaBeneficiario", name: "cedulaBeneficiario" },
                { data: "naturaleza", name: "naturaleza" },
                { data: "idLineaMadre", name: "idLineaMadre" },
                { data: "estadoAvalMadre", name: "estadoAvalMadre" },
                {
                    data: "idAvalAsociado",
                    render: function (idAvalAsociado) {
                        if (idAvalAsociado != null) {
                            return idAvalAsociado;
                        } else {
                            return "-";
                        }
                    },
                    name: "idAvalAsociado"
                },
                {
                    data: "estadoAvalHijo",
                    render: function (estadoAvalHijo) {
                        if (estadoAvalHijo != null) {
                            return estadoAvalHijo;
                        } else {
                            return "-";
                        }
                    },
                    name: "estadoAvalHijo"
                },
                {
                    data: "fechaCreacion",
                    render: function (fechaCreacion) {
                        return moment(fechaCreacion)
                            .locale("en")
                            .format("YYYY-MM-DD");
                    },
                    name: "fechaCreacion",
                },
                {
                    data: "fechaFormalizacion",
                    render: function (fechaFormalizacion) {
                        if (fechaFormalizacion != null) {
                            return moment(fechaFormalizacion)
                                .locale("en")
                                .format("YYYY-MM-DD");
                        } else {
                            return "-";
                        }
                    },
                    name: "fechaFormalizacion",
                },
                {
                    data: "fechaHonramiento",
                    render: function (fechaHonramiento) {
                        if (fechaHonramiento != null) {
                            return moment(fechaHonramiento)
                                .locale("en")
                                .format("YYYY-MM-DD");
                        } else {
                            return "-";
                        }
                    },
                    name: "fechaHonramiento",
                },
            ],
        });
    }
    /**
  * 
  * @param {*} event 
  * @returns Alamacena en el State lo que digitado en el formulario. 
  **/
    encargarseCambio = (event) => {
        if (event.target.name === "fechaInicio") {
            this.state.fechaInicio = event.target.value
        }
        if (event.target.name === "fechaFin") {
            this.state.fechaFin = event.target.value
        }
        this.setState({ [event.target.name]: event.target.value });
    }
    /**
    * 
    * @param {*} event 
    * @returns Genera la informacion en el datatable. 
    **/
    buscarReporte = (event) => {
        event.preventDefault();
        if (this.state.fechaInicio == null || this.state.fechaInicio == "") {
            swal(
                "Reporte Líneas de Crédito",
                "La fecha de inicio es inválida",
                "error", { button: "Aceptar", }
            );
            return;
        }
        if (this.state.fechaFin == null || this.state.fechaFin == "") {
            swal(
                "Reporte Líneas de Crédito",
                "La fecha fin es inválida",
                "error", { button: "Aceptar", }
            );
            return;
        }
        var fechaInicio = moment(this.state.fechaInicio);
        var fechaFin = moment(this.state.fechaFin);

        if (fechaInicio > fechaFin) {
            swal(
                "Reporte Líneas de Crédito",
                "Rango de fechas inválido.",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        var diasDiferencia = fechaFin.diff(fechaInicio, 'days');
        if (diasDiferencia > REACT_APP_MAXIMO_DIAS_RANGO_FECHA_PERMITIDO_REPORTES) {
            swal(
                "Reporte Líneas de Crédito",
                "Está excediendo el  rango permitido de 3 meses de diferencia entre la fecha de inicio y fecha fin.",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        this.state.datatable.ajax.url(`/api/ReporteLineaCredito?fondo=${localStorage.getItem("idFondo")}&fechaInicio=${this.state.fechaInicio}&fechaFin=${this.state.fechaFin}&ip=${localStorage.getItem("ip")}&latitud=${localStorage.getItem("latitud")}&longitud=${localStorage.getItem("longitud")}&userAgent=${userAgent}&usuario=${localStorage.getItem("idUsuarioCreador")}`).load();
    };
    /**
    * 
    * @param {*} event 
    * @returns Generar el documento de excel. 
    **/
    generarReporte = (event) => {
        var fechaInicio = moment(this.state.fechaInicio);
        var fechaFin = moment(this.state.fechaFin);

        if (fechaInicio > fechaFin) {
            swal(
                "Reporte Líneas de Crédito",
                "Rango de fechas inválido.",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        var diasDiferencia = fechaFin.diff(fechaInicio, 'days');
        if (diasDiferencia > REACT_APP_MAXIMO_DIAS_RANGO_FECHA_PERMITIDO_REPORTES) {
            swal(
                "Reporte Líneas de Crédito",
                "Está excediendo el  rango permitido de 3 meses de diferencia entre la fecha de inicio y fecha fin.",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        var data = {
            FechaInicio: this.state.fechaInicio,
            FechaFin: this.state.fechaFin,
            EntidadFinanciera: document.getElementById("Entidad Financiera").value,
            EstadoEntidadFinanciera: document.getElementById("Estado Entidad Financiera").value,
            Beneficiario: document.getElementById("Beneficiario").value,
            CedulaBeneficiario: document.getElementById("Cédula Beneficiario").value,
            TipoSolicitud: document.getElementById("Naturaleza").value,
            IDLíneaCredito: document.getElementById("ID Línea de Crédito").value,
            EstadoLineaCredito: document.getElementById("Estado Línea de Crédito").value,
            IDSubCredito: document.getElementById("ID Aval + Línea de Crédito").value,
            EstadoSubCredito: document.getElementById("Estado Aval + Línea de Crédito").value,
            FechaCreacion: document.getElementById("Fecha Creación").value,
            FechaFormalizacion: document.getElementById("Fecha Formalización").value,
            FechaHonramiento: document.getElementById("Fecha Honramiento").value,
            SearchValue: document.getElementById("tabla_filter").children[0].children[0].value
        };
        GenerarReporteLineasCreditoExcel(data, (estado) => {
            switch (estado) {
                case 0:
                    this.setState({ mostrarDescargarReporte: true });
                    break;
                case 1:
                    this.setState({ mostrarDescargarReporte: false });
                    break;
                case 2:
                    this.setState({ mostrarDescargarReporte: false });
                    swal(
                        "Reporte Líneas de Crédito",
                        "Ha ocurrrido un error inesperado, por favor intente de nuevo",
                        "error", { button: "Aceptar", }
                    );
                    break;
            }
        });
    };
    render() {
        const idRol = localStorage.getItem("idRol");
        if (idRol) {
            if (idRol != REACT_APP_ROL_ADMINISTRATIVO && idRol != REACT_APP_ROL_CONTABILIDAD && idRol != REACT_APP_ROL_SEGUIMIENTO && idRol != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME)
                window.location.href = "/mantenimiento-reportes";
        } else {
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`
        }
        switch (idRol) {
            //REACT_APP_ROL_ADMINISTRATIVO
            case "1":
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Reporte Líneas de Crédito</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/mantenimiento-reportes">Reportes</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Reporte Líneas de Crédito
                                </li>
                            </ol>
                        </nav>
                        <nav className="navbar navbar-light">
                            <button type="button" className="btn btn-success border-radius" onClick={this.generarReporte} disabled={this.state.mostrarDescargarReporte}>
                                <i className="fa fa-file-excel-o" aria-hidden="true"></i>{" "}
                                Exportar Excel {" "}
                                <i className="fa fa-refresh fa-spin fa-fw" hidden={!this.state.mostrarDescargarReporte}></i>
                                <span className="sr-only">Loading...</span>
                            </button>
                        </nav>
                        <div className="container" style={{ padding: "0 0 40px 0" }}>
                            <div className="row justify-content-center">
                                <div className="form-inline center">
                                    <div className="form-group" style={{ padding: "0 20px 0 0" }}>
                                        <label>Fecha Inicio</label>
                                        <input
                                            type="date"
                                            name="fechaInicio"
                                            id="fechaInicio"
                                            className="form-control"
                                            value={this.state.fechaInicio}
                                            disabled={this.state.mostrarDescargarReporte}
                                            onChange={this.encargarseCambio} />
                                    </div>
                                    <div className="form-group" style={{ padding: "0 20px 0 0" }}>
                                        <label>Fecha Fin</label>
                                        <input
                                            type="date"
                                            name="fechaFin"
                                            id="fechaFin"
                                            className="form-control"
                                            value={this.state.fechaFin}
                                            disabled={this.state.mostrarDescargarReporte}
                                            onChange={this.encargarseCambio} />
                                    </div>
                                    <button
                                        type="submit"
                                        className="btn btn-primary border-radius"
                                        disabled={this.state.mostrarDescargarReporte}
                                        onClick={this.buscarReporte}
                                    >
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Entidad Financiera</th>
                                        <th>Estado Entidad Financiera</th>
                                        <th>Beneficiario</th>
                                        <th>Cédula Beneficiario</th>
                                        <th>Naturaleza</th>
                                        <th>ID Línea de Crédito</th>
                                        <th>Estado Línea de Crédito</th>
                                        <th>ID Aval + Línea de Crédito</th>
                                        <th>Estado Aval + Línea de Crédito</th>
                                        <th>Fecha Creación</th>
                                        <th>Fecha Formalización</th>
                                        <th>Fecha Honramiento</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th>Entidad Financiera</th>
                                        <th>Estado Entidad Financiera</th>
                                        <th>Beneficiario</th>
                                        <th>Cédula Beneficiario</th>
                                        <th>Naturaleza</th>
                                        <th>ID Línea de Crédito</th>
                                        <th>Estado Línea de Crédito</th>
                                        <th>ID Aval + Línea de Crédito</th>
                                        <th>Estado Aval + Línea de Crédito</th>
                                        <th>Fecha Creación</th>
                                        <th>Fecha Formalización</th>
                                        <th>Fecha Honramiento</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                );
            //REACT_APP_ROL_CONTABILIDAD
            case "6": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Reporte Líneas de Crédito</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/mantenimiento-reportes">Reportes</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Reporte Líneas de Crédito
                                </li>
                            </ol>
                        </nav>
                        <nav className="navbar navbar-light">
                            <button type="button" className="btn btn-success border-radius" onClick={this.generarReporte} disabled={this.state.mostrarDescargarReporte}>
                                <i className="fa fa-file-excel-o" aria-hidden="true"></i>{" "}
                                Exportar Excel {" "}
                                <i className="fa fa-refresh fa-spin fa-fw" hidden={!this.state.mostrarDescargarReporte}></i>
                                <span className="sr-only">Loading...</span>
                            </button>
                        </nav>
                        <div className="container" style={{ padding: "0 0 40px 0" }}>
                            <div className="row justify-content-center">
                                <div className="form-inline center">
                                    <div className="form-group" style={{ padding: "0 20px 0 0" }}>
                                        <label>Fecha Inicio</label>
                                        <input
                                            type="date"
                                            name="fechaInicio"
                                            id="fechaInicio"
                                            className="form-control"
                                            value={this.state.fechaInicio}
                                            disabled={this.state.mostrarDescargarReporte}
                                            onChange={this.encargarseCambio} />
                                    </div>
                                    <div className="form-group" style={{ padding: "0 20px 0 0" }}>
                                        <label>Fecha Fin</label>
                                        <input
                                            type="date"
                                            name="fechaFin"
                                            id="fechaFin"
                                            className="form-control"
                                            value={this.state.fechaFin}
                                            disabled={this.state.mostrarDescargarReporte}
                                            onChange={this.encargarseCambio} />
                                    </div>
                                    <button
                                        type="submit"
                                        className="btn btn-primary border-radius"
                                        disabled={this.state.mostrarDescargarReporte}
                                        onClick={this.buscarReporte}
                                    >
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Entidad Financiera</th>
                                        <th>Estado Entidad Financiera</th>
                                        <th>Beneficiario</th>
                                        <th>Cédula Beneficiario</th>
                                        <th>Naturaleza</th>
                                        <th>ID Línea de Crédito</th>
                                        <th>Estado Línea de Crédito</th>
                                        <th>ID Aval + Línea de Crédito</th>
                                        <th>Estado Aval + Línea de Crédito</th>
                                        <th>Fecha Creación</th>
                                        <th>Fecha Formalización</th>
                                        <th>Fecha Honramiento</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th>Entidad Financiera</th>
                                        <th>Estado Entidad Financiera</th>
                                        <th>Beneficiario</th>
                                        <th>Cédula Beneficiario</th>
                                        <th>Naturaleza</th>
                                        <th>ID Línea de Crédito</th>
                                        <th>Estado Línea de Crédito</th>
                                        <th>ID Aval + Línea de Crédito</th>
                                        <th>Estado Aval + Línea de Crédito</th>
                                        <th>Fecha Creación</th>
                                        <th>Fecha Formalización</th>
                                        <th>Fecha Honramiento</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_SEGUIMIENTO
            case "7": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Reporte Líneas de Crédito</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/mantenimiento-reportes">Reportes</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Reporte Líneas de Crédito
                                </li>
                            </ol>
                        </nav>
                        <nav className="navbar navbar-light">
                            <button type="button" className="btn btn-success border-radius" onClick={this.generarReporte} disabled={this.state.mostrarDescargarReporte}>
                                <i className="fa fa-file-excel-o" aria-hidden="true"></i>{" "}
                                Exportar Excel {" "}
                                <i className="fa fa-refresh fa-spin fa-fw" hidden={!this.state.mostrarDescargarReporte}></i>
                                <span className="sr-only">Loading...</span>
                            </button>
                        </nav>
                        <div className="container" style={{ padding: "0 0 40px 0" }}>
                            <div className="row justify-content-center">
                                <div className="form-inline center">
                                    <div className="form-group" style={{ padding: "0 20px 0 0" }}>
                                        <label>Fecha Inicio</label>
                                        <input
                                            type="date"
                                            name="fechaInicio"
                                            id="fechaInicio"
                                            className="form-control"
                                            value={this.state.fechaInicio}
                                            disabled={this.state.mostrarDescargarReporte}
                                            onChange={this.encargarseCambio} />
                                    </div>
                                    <div className="form-group" style={{ padding: "0 20px 0 0" }}>
                                        <label>Fecha Fin</label>
                                        <input
                                            type="date"
                                            name="fechaFin"
                                            id="fechaFin"
                                            className="form-control"
                                            value={this.state.fechaFin}
                                            disabled={this.state.mostrarDescargarReporte}
                                            onChange={this.encargarseCambio} />
                                    </div>
                                    <button
                                        type="submit"
                                        className="btn btn-primary border-radius"
                                        disabled={this.state.mostrarDescargarReporte}
                                        onClick={this.buscarReporte}
                                    >
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Entidad Financiera</th>
                                        <th>Estado Entidad Financiera</th>
                                        <th>Beneficiario</th>
                                        <th>Cédula Beneficiario</th>
                                        <th>Naturaleza</th>
                                        <th>ID Línea de Crédito</th>
                                        <th>Estado Línea de Crédito</th>
                                        <th>ID Aval + Línea de Crédito</th>
                                        <th>Estado Aval + Línea de Crédito</th>
                                        <th>Fecha Creación</th>
                                        <th>Fecha Formalización</th>
                                        <th>Fecha Honramiento</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th>Entidad Financiera</th>
                                        <th>Estado Entidad Financiera</th>
                                        <th>Beneficiario</th>
                                        <th>Cédula Beneficiario</th>
                                        <th>Naturaleza</th>
                                        <th>ID Línea de Crédito</th>
                                        <th>Estado Línea de Crédito</th>
                                        <th>ID Aval + Línea de Crédito</th>
                                        <th>Estado Aval + Línea de Crédito</th>
                                        <th>Fecha Creación</th>
                                        <th>Fecha Formalización</th>
                                        <th>Fecha Honramiento</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_REPRESENTANTE_FODEMIPYME
            case "8":
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Reporte Líneas de Crédito</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/mantenimiento-reportes">Reportes</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Reporte Líneas de Crédito
                                </li>
                            </ol>
                        </nav>
                        <nav className="navbar navbar-light">
                            <button type="button" className="btn btn-success border-radius" onClick={this.generarReporte} disabled={this.state.mostrarDescargarReporte}>
                                <i className="fa fa-file-excel-o" aria-hidden="true"></i>{" "}
                                Exportar Excel {" "}
                                <i className="fa fa-refresh fa-spin fa-fw" hidden={!this.state.mostrarDescargarReporte}></i>
                                <span className="sr-only">Loading...</span>
                            </button>
                        </nav>
                        <div className="container" style={{ padding: "0 0 40px 0" }}>
                            <div className="row justify-content-center">
                                <div className="form-inline center">
                                    <div className="form-group" style={{ padding: "0 20px 0 0" }}>
                                        <label>Fecha Inicio</label>
                                        <input
                                            type="date"
                                            name="fechaInicio"
                                            id="fechaInicio"
                                            className="form-control"
                                            value={this.state.fechaInicio}
                                            disabled={this.state.mostrarDescargarReporte}
                                            onChange={this.encargarseCambio} />
                                    </div>
                                    <div className="form-group" style={{ padding: "0 20px 0 0" }}>
                                        <label>Fecha Fin</label>
                                        <input
                                            type="date"
                                            name="fechaFin"
                                            id="fechaFin"
                                            className="form-control"
                                            value={this.state.fechaFin}
                                            disabled={this.state.mostrarDescargarReporte}
                                            onChange={this.encargarseCambio} />
                                    </div>
                                    <button
                                        type="submit"
                                        className="btn btn-primary border-radius"
                                        disabled={this.state.mostrarDescargarReporte}
                                        onClick={this.buscarReporte}
                                    >
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Entidad Financiera</th>
                                        <th>Estado Entidad Financiera</th>
                                        <th>Beneficiario</th>
                                        <th>Cédula Beneficiario</th>
                                        <th>Naturaleza</th>
                                        <th>ID Línea de Crédito</th>
                                        <th>Estado Línea de Crédito</th>
                                        <th>ID Aval + Línea de Crédito</th>
                                        <th>Estado Aval + Línea de Crédito</th>
                                        <th>Fecha Creación</th>
                                        <th>Fecha Formalización</th>
                                        <th>Fecha Honramiento</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th>Entidad Financiera</th>
                                        <th>Estado Entidad Financiera</th>
                                        <th>Beneficiario</th>
                                        <th>Cédula Beneficiario</th>
                                        <th>Naturaleza</th>
                                        <th>ID Línea de Crédito</th>
                                        <th>Estado Línea de Crédito</th>
                                        <th>ID Aval + Línea de Crédito</th>
                                        <th>Estado Aval + Línea de Crédito</th>
                                        <th>Fecha Creación</th>
                                        <th>Fecha Formalización</th>
                                        <th>Fecha Honramiento</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                );
        }
    }
}
