import React, { Component } from "react";
import {
    REACT_APP_AVAL_ESTADO_DEBE_CONDICIONADO_FODEMIPYME,
    REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME,
    REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD,
    REACT_APP_AVAL_ESTADO_ACEPTADO,
    REACT_APP_AVAL_ESTADO_PROCESANDO_MENSUALMENTE,
    REACT_APP_AVAL_ESTADO_EN_ATRASO,
    REACT_APP_AVAL_ESTADO_ATRASO_EFA,
    REACT_APP_AVAL_ESTADO_FINALIZADO,
    REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD,
    REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD,
    REACT_APP_AVAL_ESTADO_AUTORIZADO,
    REACT_APP_AVAL_ESTADO_FORMALIZADO,
    REACT_APP_AVAL_ESTADO_ARREGLO_PAGO,
    REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO,
    REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_RECUPERABLE,
    REACT_APP_MONEDA_COLONES,
    REACT_APP_TIPO_PROGRAMA_INDIVIDUAL,
    REACT_APP_ROL_ADMINISTRATIVO,
    REACT_APP_ROL_EJECUTIVO,
    REACT_APP_ROL_INCLUSION,
    REACT_APP_ROL_OPERATIVO,
    REACT_APP_ROL_JEFATURA,
    REACT_APP_ROL_CONTABILIDAD,
    REACT_APP_ROL_SEGUIMIENTO,
    REACT_APP_ROL_REPRESENTANTE_FODEMIPYME,
    REACT_APP_CODIGO_RESPUESTA_200,
    REACT_APP_CODIGO_RESPUESTA_401,
    REACT_APP_OPERADORA_FODEMIPYME,
    REACT_APP_OPERADORA_FNA,
    REACT_APP_LONGITUD_CAMPO_MONTOS
} from "../../environment";
import swal from "sweetalert";
import ReactDOM from "react-dom";
import { Button, Form, Modal, Col } from "react-bootstrap";
import {
    CargaMensualAval,
    ActualizacionSaldosIndividual,
    ModificarAval,
} from "../../Services/AvalService";
import { BorrarLocalStorage } from "../../Utils/BorrarInfoLocalStorage";
import { ObtenerUserAgent } from "../../Utils/BaseSolicitudModel";
import {
    CrearHornamiento,
    puedeHonar,
} from "../../Services/HonramientoService";
import NumberFormat from "react-number-format";
const $ = require("jquery");
$.DataTable = require("datatables.net-bs4");
/**
 * Obtener el Fondo seleccionado en iniciar sesion
 **/
const fondoSeleccionado = JSON.parse(localStorage.getItem("fondoSeleccionado"));
/**
 * Obtener el ID del Fondo seleccionado en iniciar sesion
 **/
const idFondo = localStorage.getItem("idFondo");
export default class AvalComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idAval: 0,
            mostrarModalCargaMasiva: false,
            monto: "",
            numeroOperacion: 0,
            mostrarModalCargaIndividual: false,
            mostrarModalCrearHonramiento: false,
            mostrarModalCancelar: false,
            montoSugerido: 0,
            montoHonramiento: "",
            mensajeHonramiento: "",
            nombreArchivCargaMasiva: null,
            token: "",
            indButton: false,
            programaSeleccionado: "",
            montoCredito: "",
            porcAval: 0,
            saldoCredito: "",
            saldoAval: "",
            simboloMoneda: "",
            diasMorosidad: "",
            nombreArchivoEvidenciaHonramiento: null,
            indicadorActTotalSaldos: false,
            indicadorComisionAdministracion: true,
            indMostrarCargaMasiva: true,
            nuevoSaldoCredito: "",
            nuevoSaldoAval: "",
            diasAtraso: "",
            porcentajeTotalAvalSolicitado: 0,
            indicadorModificarNuevoSaldoCredito: false,
            indicadorDiasAtraso: false
        };
    }
    /**
     * Llamado inmediatamente después de montar un componente. 
     * Establecer el estado aquí activará la re-renderización.
     **/
    componentDidMount() {
        const idRol = localStorage.getItem("idRol");
        const tokenContent = localStorage.getItem("isLoggedIn");
        const idOperadora = localStorage.getItem("idOperadora");
        const morosidadDiaHabil = parseInt(localStorage.getItem("MOROSIDAD_DIA_HABIL"));
        let idUsuarioLogueado = localStorage.getItem("idUsuarioCreador");
        if (!tokenContent) window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        this.state.token = tokenContent;
        const userAgent = ObtenerUserAgent();
        sessionStorage.removeItem("infoAvalEditar");
        sessionStorage.removeItem("idLineaCredito");
        sessionStorage.removeItem("nombreSolicitanteTemporal");
        sessionStorage.removeItem("numeroIdentificacionSolicitanteTemporal");
        sessionStorage.removeItem("tipoIdentificacionTemporal");
        localStorage.removeItem("datatable");
        const fecha = new Date();
        const dia = fecha.getDate();
        const numeroDia = fecha.getDay();
        var finDeSemana = false;
        if (numeroDia == 6 || numeroDia == 0) finDeSemana = true;

        var numDiasTrabajo = 0;
        var limiteDiaHabil = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
        var ultimoDiaFechaActual = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);
        while (limiteDiaHabil <= ultimoDiaFechaActual && numDiasTrabajo <= morosidadDiaHabil) {
            if (limiteDiaHabil.getDay() !== 6 && limiteDiaHabil.getDay() !== 0) numDiasTrabajo++;

            limiteDiaHabil = new Date(limiteDiaHabil.getFullYear(), limiteDiaHabil.getMonth(), limiteDiaHabil.getDate() + 1);
        }
        //Sabado
        if (limiteDiaHabil.getDay() == 6) {
            limiteDiaHabil = new Date(limiteDiaHabil.getFullYear(), limiteDiaHabil.getMonth(), limiteDiaHabil.getDate() + 2);
        }
        //Domingo
        if (limiteDiaHabil.getDay() == 0) {
            limiteDiaHabil = new Date(limiteDiaHabil.getFullYear(), limiteDiaHabil.getMonth(), limiteDiaHabil.getDate() + 1);
        }

        if ((dia >= 1 && dia < limiteDiaHabil.getDate() && finDeSemana) ||
            (dia >= 1 && dia >= limiteDiaHabil.getDate())) {
            this.state.indMostrarCargaMasiva = false;
            this.setState({ indMostrarCargaMasiva: false });
        }
        /**
         * Definicion de las columnas 
         **/
        let columnas = [
            { data: "id", name: "id" },
            { data: "tipoPrograma.valor", name: "tipoPrograma.valor" },
            {
                data: "entidadFinancieraAutorizada.nombreOperadora",
                name: "entidadFinancieraAutorizada.nombreOperadora",
            },
            { data: "moneda.valor", name: "moneda.valor" },
            { data: "nombreSolicitante", name: "nombreSolicitante" },
            {
                data: "numeroIdentificacionSolicitante",
                name: "numeroIdentificacionSolicitante",
            },
            {
                data: "montoTotalAvalSolicitado",
                render: function (montoTotalAvalSolicitado) {
                    return montoTotalAvalSolicitado
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                name: "montoTotalAvalSolicitado",
            },
            {
                data: "porcentajeTotalAvalSolicitado",
                render: function (porcentajeTotalAvalSolicitado) {
                    return porcentajeTotalAvalSolicitado.toFixed(2);
                },
                name: "porcentajeTotalAvalSolicitado",
            },
            {
                data: "saldoCredito",
                render: function (saldoCredito) {
                    return saldoCredito
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                name: "saldoCredito",
            },
            {
                data: "saldoAval",
                render: function (saldoAval) {
                    return saldoAval
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                name: "saldoAval",
            },
            {
                data: "estado.valor",
                name: "estado.valor",
            },
            { data: null, name: "Acciones" },
            // { data: null, name: "Cancelar" },
        ];
        /**
         * Mostrar el boton de editar segun el rol y estado del aval
         **/
        let definicionColumnas = [
            {
                targets: 11,
                orderable: false,
                createdCell: (td, cellData, rowData, row, col) => {
                    var ejecutivoCreador = [];
                    ejecutivoCreador = rowData.ejecutivoCreador.permisos.filter(function (permiso) {
                        return (
                            permiso.fondo.id == idFondo
                        );
                    });
                    ((idOperadora == REACT_APP_OPERADORA_FODEMIPYME || idOperadora == REACT_APP_OPERADORA_FNA) &&
                        (idRol == REACT_APP_ROL_ADMINISTRATIVO ||
                            idRol == REACT_APP_ROL_EJECUTIVO ||
                            idRol == REACT_APP_ROL_SEGUIMIENTO ||
                            idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME)) ||
                        ((idOperadora == REACT_APP_OPERADORA_FODEMIPYME || idOperadora == REACT_APP_OPERADORA_FNA) &&
                            idRol == REACT_APP_ROL_CONTABILIDAD &&
                            (rowData.estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO ||
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_EN_ATRASO ||
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_FINALIZADO ||
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_ATRASO_EFA ||
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_PROCESANDO_MENSUALMENTE ||
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME ||
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD ||
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD ||
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME ||
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD ||
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME ||
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA ||
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA ||
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_RECUPERABLE ||
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE ||
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO))
                        ? ReactDOM.render(
                            <a
                                className="btn btn-primary btn-sm border-radius"
                                style={{ cursor: "pointer" }}
                                onClick={() => this.editar(rowData)}
                            >
                                <i className="fa fa-pencil"></i>
                            </a>,
                            td
                        )
                        : ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                            rowData.entidadFinancieraAutorizada.id == idOperadora &&
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD &&
                            idRol == REACT_APP_ROL_JEFATURA) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD &&
                                ejecutivoCreador[0].rol.id == REACT_APP_ROL_INCLUSION &&
                                rowData.ejecutivoCreador.b2CId == idUsuarioLogueado) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                idRol == REACT_APP_ROL_OPERATIVO &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD &&
                                ejecutivoCreador[0].rol.id == REACT_APP_ROL_INCLUSION) ||
                            (ejecutivoCreador[0].rol.id == REACT_APP_ROL_OPERATIVO &&
                                rowData.ejecutivoCreador.b2CId == idUsuarioLogueado) ||
                            (((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD &&
                                rowData.ejecutivoCreador.b2CId == idUsuarioLogueado) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD &&
                                idRol == REACT_APP_ROL_JEFATURA) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_DEBE_CONDICIONADO_FODEMIPYME &&
                                rowData.ejecutivoCreador.b2CId == idUsuarioLogueado) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_DEBE_CONDICIONADO_FODEMIPYME &&
                                idRol == REACT_APP_ROL_JEFATURA) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_ACEPTADO &&
                                idRol == REACT_APP_ROL_JEFATURA) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_ACEPTADO &&
                                idRol == REACT_APP_ROL_JEFATURA &&
                                ejecutivoCreador[0].rol.id == REACT_APP_ROL_OPERATIVO) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_ACEPTADO &&
                                idRol == REACT_APP_ROL_OPERATIVO &&
                                rowData.idOperativoReciente == idUsuarioLogueado) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_PROCESANDO_MENSUALMENTE &&
                                (idRol == REACT_APP_ROL_INCLUSION || idRol == REACT_APP_ROL_OPERATIVO || idRol == REACT_APP_ROL_JEFATURA)) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO &&
                                rowData.ejecutivoCreador.b2CId == idUsuarioLogueado) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME &&
                                idRol == REACT_APP_ROL_JEFATURA) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME &&
                                (idRol == REACT_APP_ROL_INCLUSION || idRol == REACT_APP_ROL_OPERATIVO) &&
                                (rowData.idInclusorReciente == idUsuarioLogueado ||
                                    rowData.idOperativoReciente == idUsuarioLogueado)) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD &&
                                (idRol == REACT_APP_ROL_INCLUSION || idRol == REACT_APP_ROL_OPERATIVO) &&
                                (rowData.idInclusorReciente == idUsuarioLogueado ||
                                    rowData.idOperativoReciente == idUsuarioLogueado)) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD &&
                                idRol == REACT_APP_ROL_JEFATURA) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO &&
                                rowData.idOperativoReciente == idUsuarioLogueado) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO &&
                                rowData.ejecutivoCreador.b2CId == idUsuarioLogueado) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO &&
                                idRol == REACT_APP_ROL_JEFATURA &&
                                rowData.idJefaturaReciente == idUsuarioLogueado) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO &&
                                idRol == REACT_APP_ROL_JEFATURA &&
                                rowData.tipoPrograma.id == REACT_APP_TIPO_PROGRAMA_INDIVIDUAL) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA &&
                                rowData.idOperativoReciente == idUsuarioLogueado) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA &&
                                rowData.ejecutivoCreador.b2CId == idUsuarioLogueado) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA &&
                                idRol == REACT_APP_ROL_JEFATURA &&
                                rowData.idJefaturaReciente == idUsuarioLogueado)
                            ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO &&
                                rowData.idOperativoReciente == idUsuarioLogueado) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO &&
                                rowData.ejecutivoCreador.b2CId == idUsuarioLogueado) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO &&
                                idRol == REACT_APP_ROL_JEFATURA &&
                                rowData.idJefaturaReciente == idUsuarioLogueado)
                            ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO &&
                                (idRol == REACT_APP_ROL_INCLUSION || idRol == REACT_APP_ROL_OPERATIVO || idRol == REACT_APP_ROL_JEFATURA) &&
                                (rowData.idInclusorReciente == idUsuarioLogueado ||
                                    rowData.idOperativoReciente == idUsuarioLogueado ||
                                    (rowData.idJefaturaReciente == idUsuarioLogueado ||
                                        rowData.idJefaturaReciente != idUsuarioLogueado))) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO &&
                                idRol == REACT_APP_ROL_JEFATURA &&
                                rowData.tipoPrograma.id == REACT_APP_TIPO_PROGRAMA_INDIVIDUAL) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO &&
                                idRol == REACT_APP_ROL_JEFATURA &&
                                rowData.idJefaturaReciente == idUsuarioLogueado) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO &&
                                idRol == REACT_APP_ROL_JEFATURA &&
                                rowData.tipoPrograma.id == REACT_APP_TIPO_PROGRAMA_INDIVIDUAL) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_FINALIZADO &&
                                (idRol == REACT_APP_ROL_JEFATURA || idRol == REACT_APP_ROL_OPERATIVO)) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_EN_ATRASO &&
                                (idRol == REACT_APP_ROL_INCLUSION || idRol == REACT_APP_ROL_JEFATURA || idRol == REACT_APP_ROL_OPERATIVO)) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_ATRASO_EFA &&
                                (idRol == REACT_APP_ROL_JEFATURA || idRol == REACT_APP_ROL_OPERATIVO))
                            ? ReactDOM.render(
                                <a
                                    className="btn btn-primary btn-sm border-radius"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.editar(rowData)}
                                >
                                    <i className="fa fa-pencil"></i>
                                </a>,
                                td
                            )
                            : ReactDOM.render("", td);
                },
            },
        ];
        /**
         * Mostrar el boton de honramiento segun el rol y estado del aval
         **/
        if (idRol == REACT_APP_ROL_INCLUSION) {
            columnas.push({ data: null, name: "Honramiento" });
            definicionColumnas.push({
                targets: 12,
                orderable: false,
                createdCell: (td, cellData, rowData, row, col) =>
                    rowData.estado.id == REACT_APP_AVAL_ESTADO_EN_ATRASO
                        ? ReactDOM.render(
                            <a
                                className="btn btn-success btn-sm border-radius"
                                style={{ cursor: "pointer" }}
                                onClick={() => this.validarHonramiento(rowData)}
                            >
                                <i className="fa fa-file-text" aria-hidden="true"></i>
                            </a>,
                            td
                        )
                        : ReactDOM.render("", td),
            });
        }
        /**
         * Mostrar el boton de carga individual segun el rol y estado del aval
         **/
        if (idRol == REACT_APP_ROL_OPERATIVO || idRol == REACT_APP_ROL_ADMINISTRATIVO || idRol == REACT_APP_ROL_CONTABILIDAD) {
            columnas.push({ data: null, name: "Carga Individual" });
            definicionColumnas.push({
                targets: 12,
                orderable: false,
                createdCell: (td, cellData, rowData, row, col) => {
                    const fecha = new Date();
                    const dia = fecha.getDate();
                    const numeroDia = fecha.getDay();
                    var mesActual = new Date().getMonth() + 1;
                    var mesUltimoPago = 0;
                    var pago = rowData.pagos;
                    var alDia = false;
                    var finDeSemana = false;
                    var ultimoFechaPago;
                    if (pago.length > 0) {
                        let ultimoRegistro = pago[pago.length - 1];
                        if (ultimoRegistro.mensaje == "Comisión de Formalización") {
                            ultimoFechaPago = rowData.fechaFormalizacion;
                        } else {
                            ultimoFechaPago = ultimoRegistro.fechaReporte;
                        }
                        var fechaReporte = new Date(ultimoFechaPago);
                        mesUltimoPago = fechaReporte.getMonth() + 1;
                    }
                    if (mesActual == mesUltimoPago) alDia = true;

                    if (numeroDia == 6 || numeroDia == 0) finDeSemana = true;

                    var numDiasTrabajo = 0;
                    var limiteDiaHabil = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
                    var ultimoDiaFechaActual = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);
                    while (limiteDiaHabil <= ultimoDiaFechaActual && numDiasTrabajo <= morosidadDiaHabil) {
                        if (limiteDiaHabil.getDay() !== 6 && limiteDiaHabil.getDay() !== 0) numDiasTrabajo++;

                        limiteDiaHabil = new Date(limiteDiaHabil.getFullYear(), limiteDiaHabil.getMonth(), limiteDiaHabil.getDate() + 1);
                    }
                    //Sabado
                    if (limiteDiaHabil.getDay() == 6) {
                        limiteDiaHabil = new Date(limiteDiaHabil.getFullYear(), limiteDiaHabil.getMonth(), limiteDiaHabil.getDate() + 2);
                    }
                    //Domingo
                    if (limiteDiaHabil.getDay() == 0) {
                        limiteDiaHabil = new Date(limiteDiaHabil.getFullYear(), limiteDiaHabil.getMonth(), limiteDiaHabil.getDate() + 1);
                    }

                    (idRol == REACT_APP_ROL_ADMINISTRATIVO || idRol == REACT_APP_ROL_CONTABILIDAD) &&
                        (rowData.estado.id == REACT_APP_AVAL_ESTADO_PROCESANDO_MENSUALMENTE ||
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_ATRASO_EFA ||
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO)
                        ? ReactDOM.render(
                            <a
                                className="btn btn-success btn-sm border-radius"
                                style={{ cursor: "pointer" }}
                                onClick={() => this.cargarMasivaIndividual(rowData)}
                            >
                                <i className="fa fa-money"></i>
                            </a>,
                            td
                        )
                        : !alDia &&
                            dia >= 1 &&
                            dia < limiteDiaHabil.getDate() &&
                            !finDeSemana &&
                            (rowData.estado.id == REACT_APP_AVAL_ESTADO_PROCESANDO_MENSUALMENTE ||
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO) &&
                            idRol == REACT_APP_ROL_OPERATIVO
                            ? ReactDOM.render(
                                <a
                                    className="btn btn-success btn-sm border-radius"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.cargarMasivaIndividual(rowData)}
                                >
                                    <i className="fa fa-money"></i>
                                </a>,
                                td
                            )
                            : ReactDOM.render("", td);
                },
            });
        }
        /**
         * Creacion del DataTable
         **/
        $("#tabla").DataTable({
            processing: true,
            serverSide: true,
            language: {
                searchPlaceholder: "Buscar...",
                url: "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json",
            },
            initComplete: function () {
                this.api()
                    .column()
                    .each(function () {
                        var table = $('#tabla').DataTable();
                        $(".dataTables_filter input")
                            .off()
                            .on('change clear', function (e) {
                                if (table.search() !== this.value) {
                                    table.search(this.value)
                                        .draw();
                                }
                            });
                    });
            },
            ajax: {
                url: `/api/ListarAvales?fondo=${localStorage.getItem("idFondo")}&ip=${localStorage.getItem("ip")}&latitud=${localStorage.getItem("latitud")}&longitud=${localStorage.getItem("longitud")}&userAgent=${userAgent}&usuario=${localStorage.getItem("idUsuarioCreador")}`,
                error: function (xhr, error, thrown) {
                    if (xhr.status == REACT_APP_CODIGO_RESPUESTA_401) {
                        swal({
                            title: "¡Sesión Expiró!",
                            text: "Por favor vuelva a iniciar sesión",
                            icon: "warning",
                            button: "Aceptar",
                        }).then((value) => {
                            BorrarLocalStorage();
                            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                        });
                        return;
                    }
                    if (xhr.status != REACT_APP_CODIGO_RESPUESTA_200) {
                        swal("Error al obtener los avales", thrown, "error", { button: "Aceptar", });
                    }
                },
                type: "POST",
            },
            columns: columnas,
            columnDefs: definicionColumnas,
        });
    }
    /**
     * 
     * @param {*} columna 
     * @returns valida si el aval puede ser honrado
     **/
    validarHonramiento = (row) => {
        let idUsuarioCrea = localStorage.getItem("idUsuarioCreador");
        let data = {
            IdAval: parseInt(row.id),
            IdEjecutivo: idUsuarioCrea,
            IdCliente: parseInt(localStorage.getItem("idOperadora"))
        };
        this.state.idAval = row.id;
        let ultimoRegistro = row.pagos[row.pagos.length - 1];
        if (ultimoRegistro != null) {
            this.state.diasMorosidad = ultimoRegistro.diasAtraso;
            this.setState({ idAval: ultimoRegistro.diasAtraso });
        }
        if (row.moneda.id == REACT_APP_MONEDA_COLONES) {
            this.state.simboloMoneda = "¢";
            this.setState({ simboloMoneda: "¢" });
        } else {
            this.state.simboloMoneda = "$";
            this.setState({ simboloMoneda: "$" });
        }
        this.setState({ idAval: row.id });
        this.setState({ indButton: false });
        puedeHonar(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    this.setState({ indButton: false });
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    this.setState({ monto: "" });
                    return;
                }
                if (res.json.puedeHonrar) {
                    this.setState({ montoSugerido: res.json.montoSugerido });
                    this.setState({
                        montoHonramiento: res.json.montoSugerido.toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    });
                    this.setState({ mensajeHonramiento: res.json.mensaje });
                    this.setState({ mostrarModalCrearHonramiento: true });
                } else {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "warning", { button: "Aceptar", });
                }
                this.setState({ indButton: false });
            }
            this.setState({ indButton: false });
        });
    };
    /**
     * 
     * @param {*} event 
     * @returns Crear honramiento
     */
    crearHonramiento = (event) => {
        event.preventDefault();
        let idUsuarioCrea = localStorage.getItem("idUsuarioCreador");
        const numeros = new RegExp(/^[0-9,.]*$/);
        const soloNumerosMontoHonramiento = numeros.test(this.state.montoHonramiento);
        if (
            this.state.montoHonramiento === "" || Number(this.state.montoHonramiento)
                ? this.state.montoHonramiento == 0
                : parseFloat(this.state.montoHonramiento.replace(/,/g, "")) == 0
        ) {
            swal("Honramiento", "El monto del honramiento es requerido", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }
        if (!soloNumerosMontoHonramiento) {
            swal(
                "Honramiento",
                "El monto del honramiento no tiene un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (
            Number(this.state.montoHonramiento)
                ? this.state.montoHonramiento < 0
                : parseFloat(this.state.montoHonramiento.replace(/,/g, "")) < 0) {
            swal(
                "Honramiento",
                "El monto del honramiento no puede ser menor a 0",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (
            Number(this.state.montoHonramiento)
                ? this.state.montoHonramiento > REACT_APP_LONGITUD_CAMPO_MONTOS
                : parseFloat(this.state.montoHonramiento.replace(/,/g, "")).toFixed(2) >
                REACT_APP_LONGITUD_CAMPO_MONTOS) {
            swal(
                "Honramiento",
                "El monto del honramiento no puede ser mayor a 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.diasMorosidad === "") {
            swal("Honramiento", "Los días de morosidad son requeridos", "error", { button: "Aceptar", });
            return;
        }
        if (this.state.diasMorosidad < 0) {
            swal("Honramiento", "Los días de morosidad no pueden ser un número negativo", "error", { button: "Aceptar", });
            return;
        }
        if (this.state.nombreArchivoEvidenciaHonramiento == null) {
            swal("Honramiento", "El archivo de evidencia es requerido", "error", { button: "Aceptar", });
            return;
        }
        let data = {
            IdAval: parseInt(this.state.idAval),
            IdEjecutivo: idUsuarioCrea,
            MontoHonramiento: Number(this.state.montoHonramiento)
                ? this.state.montoHonramiento
                : parseFloat(this.state.montoHonramiento.replace(/,/g, "")),
            ArchivoEvidenciaHonramiento: this.state.nombreArchivoEvidenciaHonramiento,
            DiasMorosidad: parseInt(this.state.diasMorosidad)
        };
        this.setState({ indButton: true });

        swal({
            title: "¿Desea continuar?",
            text: "Ha completado los datos necesarios para la creación de la solicitud de honramiento asociada al aval: " + this.state.idAval,
            icon: "warning",
            buttons: ["NO", "SÍ"],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                CrearHornamiento(data).then((res) => {
                    if (res) {
                        this.setState({ mostrarModalCrearHonramiento: false });
                        this.setState({ montoHonramiento: 0 });
                        swal(fondoSeleccionado.nombreFondo, "¡Honramiento creado con éxito!", "success", { button: "Aceptar", }).then((value) => {
                            let oTable = $("#tabla").DataTable();
                            oTable.draw();
                            window.location.href = "/honramientos";
                        });
                        this.setState({ indButton: false });
                    }
                    this.setState({ indButton: false });
                });
            } else {
                this.setState({ indButton: false });
            }
        });


    };
    /**
     * 
     * @param {*} columna
     * @returns Mostrar daros del aval para realizar pago individual 
     **/
    cargarMasivaIndividual = (columna) => {

        this.state.idAval = columna.id;
        this.setState({ idAval: columna.id });

        this.state.numeroOperacion = columna.numeroOperacion;
        this.setState({ numeroOperacion: columna.numeroOperacion });

        this.state.mostrarModalCargaIndividual = true;
        this.setState({ mostrarModalCargaIndividual: true });

        this.state.programaSeleccionado = columna.programa;
        this.setState({ programaSeleccionado: columna.programa });

        this.state.montoCredito = columna.montoCredito.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({ montoCredito: columna.montoCredito.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") });

        this.state.porcAval = columna.porcentajeTotalAvalSolicitado;
        this.setState({ porcAval: columna.porcentajeTotalAvalSolicitado });

        this.state.saldoCredito = columna.saldoCredito.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({ saldoCredito: columna.saldoCredito.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") });

        this.state.saldoAval = columna.saldoAval.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.setState({ saldoAval: columna.saldoAval.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") });

        this.state.porcentajeTotalAvalSolicitado = columna.porcentajeTotalAvalSolicitado;
        this.setState({ porcentajeTotalAvalSolicitado: columna.porcentajeTotalAvalSolicitado });

        this.state.nuevoSaldoCredito = this.state.saldoCredito;
        this.setState({ nuevoSaldoCredito: this.state.saldoCredito });
        this.state.nuevoSaldoAval = this.state.saldoAval;
        this.setState({ nuevoSaldoAval: this.state.saldoAval });

        if (columna.moneda.id == REACT_APP_MONEDA_COLONES) {
            this.state.simboloMoneda = "¢";
            this.setState({ simboloMoneda: "¢" });
        } else {
            this.state.simboloMoneda = "$";
            this.setState({ simboloMoneda: "$" });
        }
    };
    /**
     * Deshabilitar el modal de carga masiva
     **/
    cerrarModalCargaMasiva = () => {
        this.setState({ mostrarModalCargaMasiva: false });
        this.state.nombreArchivCargaMasiva = null;
        this.setState({ nombreArchivCargaMasiva: null });
    };
    /**
     * Deshabilitar el modal de carga individual
     **/
    cerrarModalCargaIndividual = () => {
        this.setState({ mostrarModalCargaIndividual: false });
        this.setState({ nuevoSaldoCredito: "" });
        this.setState({ nuevoSaldoAval: "" });
        this.setState({ diasAtraso: "" });
        this.setState({ idAval: 0 });
        this.setState({ indicadorModificarNuevoSaldoCredito: false });
        this.setState({ indicadorDiasAtraso: false });
    };
    /**
     * Deshabilitar el modal de crear honramiento
     **/
    cerrarModalCrearHonramiento = () => {
        this.setState({ mostrarModalCrearHonramiento: false });
        this.setState({ nombreArchivoEvidenciaHonramiento: null });
        this.setState({ diasMorosidad: "" });
    };
    /**
     * 
     * @param {*} event 
     * @returns Alamacena en el State lo digitado en el formulario. 
     **/
    encargarseCambioHonramiento = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };
    /**
     * 
     * @param {*} columna 
     **/
    abrirModalCancelar = (columna) => {
        let idUsuarioLogin = localStorage.getItem("idUsuarioCreador");
        let idOperadora = localStorage.getItem("idOperadora");
        let idEstado = REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD;
        let titulo = "¿Estás seguro que desea desestimar este Aval?";
        let texto = "Una vez desestimado, ¡no podrá revertir este cambio!";
        let mensajeExitoso = "¡Aval desestimado con éxito!";
        if (idOperadora == REACT_APP_OPERADORA_FODEMIPYME) {
            titulo = "¿Estás seguro que desea rechazar este Aval?";
            texto = "Una vez rechazado, ¡no podrá revertir este cambio!";
            mensajeExitoso = "¡Aval rechazado con éxito!";
            idEstado = REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
        }
        let data = {
            Id: columna.id,
            IdEstado: idEstado,
            IdEjecutivo: idUsuarioLogin,
            IdTipoPrograma: columna.tipoPrograma.id,
            IdPrograma: columna.programa.id,
            IdEntidadFinancieraAutorizada: columna.programa.cliente.id,
            IdMoneda: columna.moneda.id,
        };
        swal({
            title: titulo,
            text: texto,
            icon: "warning",
            buttons: ["NO", "SÍ"],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                swal("cancelando....");
                ModificarAval(data).then((res) => {
                    if (res) {
                        if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                            swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                            return;
                        }
                        swal(fondoSeleccionado.nombreFondo, mensajeExitoso, "success", { button: "Aceptar", }).then((value) => {
                            let oTable = $("#tabla").DataTable();
                            oTable.draw();
                        });
                    }
                });
            }
        });
    };
    /**
     * 
     * @param {*} columna 
     * @returns Redirecciona a la vista de editar aval
     **/
    editar = (columna) => {
        sessionStorage.setItem("infoAvalEditar", JSON.stringify(columna));
        window.location.href = "aval/" + columna.id;
    };
    /**
     * 
     * @param {*} event 
     * @returns Mostrar modal para la carga masiva
     **/
    abrirModalCargarMasiva = (event) => {
        event.preventDefault();
        this.state.mostrarModalCargaMasiva = true;
        this.setState({ mostrarModalCargaMasiva: true });
    };
    /**
     * 
     * @param {*} event 
     * @returns Alamacena en el State el archivo de excel de la carga masiva. 
     **/
    encargarseCambioExcel = (event) => {
        if (event.target.files.length === 0) {
            this.state.nombreArchivCargaMasiva = null;
            this.setState({ [event.target.name]: null });
            return;
        }
        if (event.target.files[0].type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            swal(
                fondoSeleccionado.nombreFondo,
                "El archivo tiene que ser de formato Excel",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        this.setState({
            nombreArchivCargaMasiva: event.target.files[0],
        });
    };
    /**
   * 
   * @param {*} event 
   * @returns Alamacena en el State el archivo de honramiento. 
   **/
    encargarseCambioArchivos = (event) => {
        if (event.target.files.length === 0) {
            this.setState({ nombreArchivoEvidenciaHonramiento: null });
            return;
        }
        if (event.target.files[0].type != "application/pdf") {
            swal(
                "Crear Honramiento",
                "El archivo tiene que ser de tipo PDF",
                "error", { button: "Aceptar", }
            );
            return;
        }
        this.setState({ [event.target.name]: event.target.files[0] });
    };
    /**
     * 
     * @param {*} event 
     * @returns Alamacena en el State el archivo de excel de la carga masiva. 
     **/
    encargarseCambio = (event) => {
        var preg = /^([0-9]+\.?[0-9]{0,2})$/;
        if (event.target.name === "nuevoSaldoCredito") {
            if (!this.state.indicadorModificarNuevoSaldoCredito) {
                this.state.indicadorModificarNuevoSaldoCredito = true;
                this.setState({ indicadorModificarNuevoSaldoCredito: true });
            }
            const soloNumerosNuevoSaldoCredito = preg.test(
                event.target.value.replace(/,/g, "")
            );

            if (!soloNumerosNuevoSaldoCredito) {
                swal(
                    "Carga Masiva Individual",
                    "El monto del nuevo saldo del crédito no tiene un formato válido",
                    "warning", { button: "Aceptar", }
                );
                var montoNuevoSaldoCreditoFloat = parseFloat(event.target.value.replace(/,/g, "")).toFixed(2);
                this.setState({ nuevoSaldoCredito: montoNuevoSaldoCreditoFloat });

                this.state.nuevoSaldoAval = this.state.saldoAval;
                this.setState({ nuevoSaldoAval: this.state.saldoAval });

                return;
            }

            let nuevoSaldoCredito = parseFloat(event.target.value.replace(/,/g, "")).toFixed(2);
            let saldoCredito = parseFloat(this.state.saldoCredito.replace(/,/g, "")).toFixed(2);
            nuevoSaldoCredito = parseFloat(nuevoSaldoCredito);
            saldoCredito = parseFloat(saldoCredito);

            if (nuevoSaldoCredito == 0) {
                this.setState({ diasAtraso: 0 });
                this.setState({ indicadorDiasAtraso: true });
            } else {
                if (this.state.diasAtraso == 0) this.setState({ diasAtraso: "" });

                this.setState({ indicadorDiasAtraso: false });
            }

            if (nuevoSaldoCredito == saldoCredito) {
                this.state.indicadorModificarNuevoSaldoCredito = false;
                this.setState({ indicadorModificarNuevoSaldoCredito: false });
            }
            if (nuevoSaldoCredito > saldoCredito) {
                swal(
                    "Carga Masiva Individual",
                    "El monto del nuevo saldo del crédito no puede mayor al saldo actual del crédito",
                    "warning", { button: "Aceptar", }
                );
                this.state.nuevoSaldoCredito = this.state.saldoCredito;
                this.setState({ nuevoSaldoCredito: this.state.saldoCredito });
                this.state.nuevoSaldoAval = this.state.saldoAval;
                this.setState({ nuevoSaldoAval: this.state.saldoAval });
                this.state.indicadorModificarNuevoSaldoCredito = false;
                this.setState({ indicadorModificarNuevoSaldoCredito: false });
                return;
            }

            if (nuevoSaldoCredito == parseFloat(this.state.saldoCredito.replace(/,/g, "")).toFixed(2)) {
                this.state.nuevoSaldoAval = this.state.saldoAval;
                this.setState({ nuevoSaldoAval: this.state.saldoAval });
            } else {
                nuevoSaldoCredito = parseFloat(nuevoSaldoCredito);
                let porcAvalSolicitado = parseFloat(this.state.porcentajeTotalAvalSolicitado).toFixed(2);
                porcAvalSolicitado = parseFloat(porcAvalSolicitado);
                let nuevoSaldoAval = nuevoSaldoCredito * (porcAvalSolicitado / 100);
                nuevoSaldoAval = nuevoSaldoAval.toFixed(2);

                this.state.nuevoSaldoAval = nuevoSaldoAval;
                this.setState({ nuevoSaldoAval: nuevoSaldoAval });
            }
        }
        this.setState({ [event.target.name]: event.target.value });
    };
    /**
     * 
     * @param {*} event 
     * @returns Actualiza el saldo
     **/
    actualizarAvalIndividual = (event) => {
        event.preventDefault();
        let idUsuarioCrea = localStorage.getItem("idUsuarioCreador");
        let idOperadora = localStorage.getItem("idOperadora");
        const numeros = new RegExp(/^[0-9,.]*$/);
        const soloNumerosNuevoSaldoCredito = numeros.test(this.state.nuevoSaldoCredito);
        if (
            this.state.numeroOperacion === "" ||
            this.state.numeroOperacion == null
        ) {
            swal(
                "Carga Masiva Individual",
                "El número de operación es requerido",
                "error", { button: "Aceptar", }
            );
            return;
        }

        if (
            this.state.nuevoSaldoCredito === "" || this.state.nuevoSaldoCredito == null
        ) {
            swal("Carga Masiva Individual", "El monto del nuevo saldo del crédito es requerido", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }
        if (!soloNumerosNuevoSaldoCredito) {
            swal(
                "Carga Masiva Individual",
                "El monto del nuevo saldo del crédito no tiene un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (
            Number(this.state.nuevoSaldoCredito)
                ? this.state.nuevoSaldoCredito < 0
                : parseFloat(this.state.nuevoSaldoCredito.replace(/,/g, "")) < 0) {
            swal(
                "Carga Masiva Individual",
                "El monto del nuevo saldo del crédito no puede ser menor a 0",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (
            Number(this.state.nuevoSaldoCredito)
                ? this.state.nuevoSaldoCredito > REACT_APP_LONGITUD_CAMPO_MONTOS
                : parseFloat(this.state.nuevoSaldoCredito.replace(/,/g, "")).toFixed(2) >
                REACT_APP_LONGITUD_CAMPO_MONTOS) {
            swal(
                "Carga Masiva Individual",
                "El monto del nuevo saldo del crédito no puede ser mayor a 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.diasAtraso === "") {
            swal("Carga Masiva Individual", "Los días de atraso son requeridos", "error", { button: "Aceptar" });
            return;
        }
        if (this.state.diasAtraso < 0) {
            swal("Carga Masiva Individual", "Los días de atraso no pueden ser un número negativo", "error", { button: "Aceptar", });
            return;
        }

        let texto = "Ha realizado la actualización de los saldos. Si continúa, no podrá cambiarlos. ¿Desea continuar?";
        if (!this.state.indicadorModificarNuevoSaldoCredito) {
            texto = "No ha realizado la actualización de los saldos. Si continúa, no podrá cambiarlo y se cobrará la comisión de administración con base en los saldos actuales. ¿Desea continuar?";
        }
        swal({
            title: "Estás seguro/segura?",
            text: texto,
            icon: "warning",
            buttons: ["NO", "SÍ"],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                let data = {
                    IdEntidadFinanciera: parseInt(idOperadora),
                    IdEjecutivo: idUsuarioCrea,
                    NumeroOperacion: this.state.numeroOperacion,
                    IdPrograma: parseInt(this.state.programaSeleccionado.id),
                    ComisionCuota: this.state.indicadorComisionAdministracion,
                    SaldoCredito: parseFloat(this.state.nuevoSaldoCredito.replace(/,/g, "")),
                    DiasAtraso: parseInt(this.state.diasAtraso)
                };
                this.setState({ indButton: true });
                ActualizacionSaldosIndividual(data).then((res) => {
                    if (res) {
                        if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                            this.setState({ indButton: false });

                            if (res.json.mensaje == "Este aval ya recibió un abono para el mes actual.") {
                                swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "warning", { button: "Aceptar", }).then(
                                    (value) => {
                                        this.cerrarModalCargaIndividual();
                                        let oTable = $("#tabla").DataTable();
                                        oTable.draw();
                                    }
                                );
                            } else {
                                swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                            }
                            return;
                        }
                        swal(fondoSeleccionado.nombreFondo, "¡Aval actualizado con éxito!", "success", { button: "Aceptar", }).then(
                            (value) => {
                                this.cerrarModalCargaIndividual();
                                let oTable = $("#tabla").DataTable();
                                oTable.draw();
                            }
                        );
                        this.setState({ indButton: false });
                    }
                    this.setState({ indButton: false });
                });
            }
        });
    };
    /**
     * 
     * @param {*} event 
     * @returns Ejecuta carga masiva
     **/
    enviarArchivoExcel = (event) => {
        event.preventDefault();
        let idUsuarioCrea = localStorage.getItem("idUsuarioCreador");
        let idOperadora = localStorage.getItem("idOperadora");
        if (this.state.nombreArchivCargaMasiva == null) {
            swal(fondoSeleccionado.nombreFondo, "El archivo es requerido", "error", { button: "Aceptar", });
            return;
        }
        if (
            this.state.nombreArchivCargaMasiva.type !=
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
            swal(fondoSeleccionado.nombreFondo, "El archivo tiene que ser de formato Excel", "warning", { button: "Aceptar", });
            return;
        }
        let data = {
            IdEntidadFinanciera: parseInt(idOperadora),
            IdEjecutivo: idUsuarioCrea,
            ArchivoExcel: this.state.nombreArchivCargaMasiva,
        };
        this.setState({ indButton: true });
        CargaMensualAval(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    this.setState({ indButton: false });
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                swal(fondoSeleccionado.nombreFondo, "¡Archivo subido con éxito!", "success", { button: "Aceptar", });
                this.cerrarModalCargaMasiva();
                this.setState({ indButton: false });
            }
            this.setState({ indButton: false });
        });
    };
    /**
     * Redirecciona a la vista de crear aval
     **/
    crearAval = () => {
        window.location.href = "aval/crear";
    };
    render() {
        const idRol = localStorage.getItem("idRol");
        switch (idRol) {
            //REACT_APP_ROL_ADMINISTRATIVO
            case "1": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Avales</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Avales
                                </li>
                            </ol>
                        </nav>
                        <nav className="navbar navbar-light">
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={this.abrirModalCargarMasiva}
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i> Carga Masiva
                            </button>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Tipo Programa</th>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Moneda</th>
                                        <th>Nombre Solicitante</th>
                                        <th>Identificación Solicitante</th>
                                        <th>Monto Aval</th>
                                        <th>% Aval</th>
                                        <th>Saldo del Crédito</th>
                                        <th>Saldo del Aval</th>
                                        <th>Estado</th>
                                        <th>Editar</th>
                                        <th>Carga Individual</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <Modal
                            show={this.state.mostrarModalCargaMasiva}
                            onHide={this.cerrarModalCargaMasiva}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Carga Masiva</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {" "}
                                <Form.Label>
                                    {" "}
                                    <strong>Carga archivo Excel</strong>
                                </Form.Label>
                                <Form.Group>
                                    <Form.File
                                        onChange={this.encargarseCambioExcel}
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    />
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="danger"
                                    disabled={this.state.indButton}
                                    onClick={this.cerrarModalCargaMasiva}
                                >
                                    Cerrar
                                    {" "}
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </Button>
                                <Button
                                    variant="primary"
                                    disabled={this.state.indButton}
                                    onClick={this.enviarArchivoExcel}
                                >
                                    <div
                                        hidden={!this.state.indButton}
                                        className="spinner-grow spinner-grow-sm text-light"
                                        role="status"
                                    >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    Cargar archivo
                                    {" "}
                                    <i className="fa fa-upload" aria-hidden="true"></i>
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal
                            show={this.state.mostrarModalCargaIndividual}
                            onHide={this.cerrarModalCargaIndividual}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Carga Individual - Aval # {this.state.idAval}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Row>
                                    <Col xs={12} md={6}>
                                        <Form.Label>
                                            <b>Monto del crédito:</b>
                                        </Form.Label>
                                        <Form.Group>
                                            <h5>
                                                {" "}
                                                {this.state.simboloMoneda}
                                                {this.state.montoCredito}
                                            </h5>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Label>
                                            <b>Porcentaje aval solicitado:</b>
                                        </Form.Label>
                                        <Form.Group>
                                            <h5> {this.state.porcAval.toFixed(2)} %</h5>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col xs={12} md={6}>
                                        <Form.Label>
                                            <b>Saldo actual del crédito:</b>
                                        </Form.Label>
                                        <Form.Group>
                                            <h5>
                                                {this.state.simboloMoneda}
                                                {this.state.saldoCredito}
                                            </h5>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Label>
                                            <b>Saldo actual del aval:</b>
                                        </Form.Label>
                                        <Form.Group>
                                            <h5>
                                                {this.state.simboloMoneda}
                                                {this.state.saldoAval}
                                            </h5>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <hr />
                                <Form.Row>
                                    <Col xs={12} md={6}>
                                        <Form.Label>
                                            <b>Nuevo saldo del crédito:</b>
                                        </Form.Label>
                                        <Form.Group>
                                            <NumberFormat
                                                thousandSeparator={true}
                                                type="text"
                                                name="nuevoSaldoCredito"
                                                min="0"
                                                value={this.state.nuevoSaldoCredito}
                                                className="form-control"
                                                onChange={this.encargarseCambio}
                                                required
                                                placeholder="0"
                                                disabled={this.state.indButton}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Label>
                                            <b>Nuevo saldo del aval:</b>
                                        </Form.Label>
                                        <Form.Group>
                                            <NumberFormat
                                                thousandSeparator={true}
                                                type="text"
                                                name="nuevoSaldoAval"
                                                min="0"
                                                value={this.state.nuevoSaldoAval}
                                                className="form-control"
                                                onChange={this.encargarseCambio}
                                                required
                                                placeholder="0"
                                                disabled
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col xs={12} md={6}>
                                        <Form.Label>
                                            <b>Días de atraso:</b>
                                        </Form.Label>
                                        <Form.Group>
                                            <input
                                                type="number"
                                                name="diasAtraso"
                                                min="0"
                                                value={this.state.diasAtraso}
                                                className="form-control"
                                                onChange={this.encargarseCambio}
                                                required
                                                placeholder="Ingresar días"
                                                disabled={this.state.indButton || this.state.indicadorDiasAtraso}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Label><b>Comisión de administración:</b></Form.Label>
                                        <Form.Control
                                            type="checkbox"
                                            name="indicadorComisionAdministracion"
                                            onChange={this.encargarseCambio}
                                            value={this.state.indicadorComisionAdministracion}
                                            checked={this.state.indicadorComisionAdministracion}
                                            disabled
                                        />
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                </Form.Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="danger"
                                    disabled={this.state.indButton}
                                    onClick={this.cerrarModalCargaIndividual}
                                >
                                    Cerrar {" "}<i className="fa fa-times" aria-hidden="true"></i>
                                </Button>
                                <Button
                                    variant="primary"
                                    disabled={this.state.indButton}
                                    onClick={this.actualizarAvalIndividual}
                                >
                                    <div
                                        hidden={!this.state.indButton}
                                        className="spinner-grow spinner-grow-sm text-light"
                                        role="status"
                                    >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    Actualizar {" "}<i className="fa fa-refresh" aria-hidden="true"></i>
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                );
            }
            //REACT_APP_ROL_EJECUTIVO
            case "2": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Avales</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Avales
                                </li>
                            </ol>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Tipo Programa</th>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Moneda</th>
                                        <th>Nombre Solicitante</th>
                                        <th>Identificación Solicitante</th>
                                        <th>Monto Aval</th>
                                        <th>% Aval</th>
                                        <th>Saldo del Crédito</th>
                                        <th>Saldo del Aval</th>
                                        <th>Estado</th>
                                        <th>Editar</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_INCLUSION
            case "3": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Avales</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Avales
                                </li>
                            </ol>
                        </nav>
                        <nav className="navbar navbar-light">
                            <button
                                type="button"
                                className="btn btn-gris"
                                onClick={this.crearAval}
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i> Nuevo Aval
                            </button>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Tipo Programa</th>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Moneda</th>
                                        <th>Nombre Solicitante</th>
                                        <th>Identificación Solicitante</th>
                                        <th>Monto Aval</th>
                                        <th>% Aval</th>
                                        <th>Saldo del Crédito</th>
                                        <th>Saldo del Aval</th>
                                        <th>Estado</th>
                                        <th>Editar</th>
                                        <th>Honramientos</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <Modal
                            show={this.state.mostrarModalCrearHonramiento}
                            onHide={this.cerrarModalCrearHonramiento}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    Crear Honramiento - Aval # {this.state.idAval}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Row>
                                    <Col xs={12} md={6}>
                                        <Form.Label>
                                            <b>Monto disponible para honrar:</b>
                                        </Form.Label>
                                        <Form.Group>
                                            <b>{" "}{this.state.simboloMoneda}{this.state.montoHonramiento}</b>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col xs={12} md={4}>
                                        <Form.Label>
                                            <b>Días de morosidad:</b>
                                        </Form.Label>
                                        <Form.Group>
                                            <input
                                                type="number"
                                                name="diasMorosidad"
                                                min="0"
                                                value={this.state.diasMorosidad}
                                                className="form-control"
                                                onChange={this.encargarseCambioHonramiento}
                                                required
                                                placeholder="0"
                                                disabled={this.state.indButton}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col xs={12} md={12}>
                                        <Form.Label>
                                            <b>Archivo evidencia:</b>
                                        </Form.Label>
                                        <Form.Group>
                                            <Form.File
                                                name="nombreArchivoEvidenciaHonramiento"
                                                onChange={this.encargarseCambioArchivos}
                                                accept="application/pdf"
                                                disabled={this.state.indButton}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="secondary"
                                    disabled={this.state.indButton}
                                    onClick={this.cerrarModalCrearHonramiento}
                                >
                                    Cerrar
                                    {" "}
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </Button>
                                <Button
                                    variant="primary"
                                    disabled={this.state.indButton}
                                    onClick={this.crearHonramiento}
                                >
                                    <div
                                        hidden={!this.state.indButton}
                                        className="spinner-grow spinner-grow-sm text-light"
                                        role="status"
                                    >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    Crear
                                    {" "}
                                    <i className="fa fa-save" aria-hidden="true"></i>
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                );
            }
            //REACT_APP_ROL_OPERATIVO
            case "4": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Avales</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Avales
                                </li>
                            </ol>
                        </nav>
                        <nav className="navbar navbar-light">
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={this.abrirModalCargarMasiva}
                                hidden={!this.state.indMostrarCargaMasiva}
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i> Carga Masiva
                            </button>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Tipo Programa</th>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Moneda</th>
                                        <th>Nombre Solicitante</th>
                                        <th>Identificación Solicitante</th>
                                        <th>Monto Aval</th>
                                        <th>% Aval</th>
                                        <th>Saldo del Crédito</th>
                                        <th>Saldo del Aval</th>
                                        <th>Estado</th>
                                        <th>Editar</th>
                                        <th>Carga Individual</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <Modal
                            show={this.state.mostrarModalCargaMasiva}
                            onHide={this.cerrarModalCargaMasiva}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Carga Masiva</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {" "}
                                <Form.Label>
                                    {" "}
                                    <strong>Carga archivo Excel</strong>
                                </Form.Label>
                                <Form.Group>
                                    <Form.File
                                        onChange={this.encargarseCambioExcel}
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    />
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="danger"
                                    disabled={this.state.indButton}
                                    onClick={this.cerrarModalCargaMasiva}
                                >
                                    Cerrar
                                    {" "}
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </Button>
                                <Button
                                    variant="primary"
                                    disabled={this.state.indButton}
                                    onClick={this.enviarArchivoExcel}
                                >
                                    <div
                                        hidden={!this.state.indButton}
                                        className="spinner-grow spinner-grow-sm text-light"
                                        role="status"
                                    >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    Cargar archivo
                                    {" "}
                                    <i className="fa fa-upload" aria-hidden="true"></i>
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal
                            show={this.state.mostrarModalCargaIndividual}
                            onHide={this.cerrarModalCargaIndividual}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Carga Individual - Aval # {this.state.idAval}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Row>
                                    <Col xs={12} md={6}>
                                        <Form.Label>
                                            <b>Monto del crédito:</b>
                                        </Form.Label>
                                        <Form.Group>
                                            <h5>
                                                {" "}
                                                {this.state.simboloMoneda}
                                                {this.state.montoCredito}
                                            </h5>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Label>
                                            <b>Porcentaje aval solicitado:</b>
                                        </Form.Label>
                                        <Form.Group>
                                            <h5> {this.state.porcAval.toFixed(2)} %</h5>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col xs={12} md={6}>
                                        <Form.Label>
                                            <b>Saldo actual del crédito:</b>
                                        </Form.Label>
                                        <Form.Group>
                                            <h5>
                                                {this.state.simboloMoneda}
                                                {this.state.saldoCredito}
                                            </h5>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Label>
                                            <b>Saldo actual del aval:</b>
                                        </Form.Label>
                                        <Form.Group>
                                            <h5>
                                                {this.state.simboloMoneda}
                                                {this.state.saldoAval}
                                            </h5>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <hr />
                                <Form.Row>
                                    <Col xs={12} md={6}>
                                        <Form.Label>
                                            <b>Nuevo saldo del crédito:</b>
                                        </Form.Label>
                                        <Form.Group>
                                            <NumberFormat
                                                thousandSeparator={true}
                                                type="text"
                                                name="nuevoSaldoCredito"
                                                min="0"
                                                value={this.state.nuevoSaldoCredito}
                                                className="form-control"
                                                onChange={this.encargarseCambio}
                                                required
                                                placeholder="0"
                                                disabled={this.state.indButton}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Label>
                                            <b>Nuevo saldo del aval:</b>
                                        </Form.Label>
                                        <Form.Group>
                                            <NumberFormat
                                                thousandSeparator={true}
                                                type="text"
                                                name="nuevoSaldoAval"
                                                min="0"
                                                value={this.state.nuevoSaldoAval}
                                                className="form-control"
                                                onChange={this.encargarseCambio}
                                                required
                                                placeholder="0"
                                                disabled
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col xs={12} md={6}>
                                        <Form.Label>
                                            <b>Días de atraso:</b>
                                        </Form.Label>
                                        <Form.Group>
                                            <input
                                                type="number"
                                                name="diasAtraso"
                                                min="0"
                                                value={this.state.diasAtraso}
                                                className="form-control"
                                                onChange={this.encargarseCambio}
                                                required
                                                placeholder="Ingresar días"
                                                disabled={this.state.indButton || this.state.indicadorDiasAtraso}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Label><b>Comisión de administración:</b></Form.Label>
                                        <Form.Control
                                            type="checkbox"
                                            name="indicadorComisionAdministracion"
                                            onChange={this.encargarseCambio}
                                            value={this.state.indicadorComisionAdministracion}
                                            checked={this.state.indicadorComisionAdministracion}
                                            disabled
                                        />
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                </Form.Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="danger"
                                    disabled={this.state.indButton}
                                    onClick={this.cerrarModalCargaIndividual}
                                >
                                    Cerrar {" "}<i className="fa fa-times" aria-hidden="true"></i>
                                </Button>
                                <Button
                                    variant="primary"
                                    disabled={this.state.indButton}
                                    onClick={this.actualizarAvalIndividual}
                                >
                                    <div
                                        hidden={!this.state.indButton}
                                        className="spinner-grow spinner-grow-sm text-light"
                                        role="status"
                                    >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    Actualizar {" "}<i className="fa fa-refresh" aria-hidden="true"></i>
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                );
            }
            //REACT_APP_ROL_JEFATURA
            case "5": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Avales</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Avales
                                </li>
                            </ol>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Tipo Programa</th>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Moneda</th>
                                        <th>Nombre Solicitante</th>
                                        <th>Identificación Solicitante</th>
                                        <th>Monto Aval</th>
                                        <th>% Aval</th>
                                        <th>Saldo del Crédito</th>
                                        <th>Saldo del Aval</th>
                                        <th>Estado</th>
                                        <th>Editar</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_CONTABILIDAD
            case "6": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Avales</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Avales
                                </li>
                            </ol>
                        </nav>
                        <nav className="navbar navbar-light">
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={this.abrirModalCargarMasiva}
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i> Carga Masiva
                            </button>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Tipo Programa</th>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Moneda</th>
                                        <th>Nombre Solicitante</th>
                                        <th>Identificación Solicitante</th>
                                        <th>Monto Aval</th>
                                        <th>% Aval</th>
                                        <th>Saldo del Crédito</th>
                                        <th>Saldo del Aval</th>
                                        <th>Estado</th>
                                        <th>Editar</th>
                                        <th>Carga Individual</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <Modal
                            show={this.state.mostrarModalCargaMasiva}
                            onHide={this.cerrarModalCargaMasiva}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Carga Masiva</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {" "}
                                <Form.Label>
                                    {" "}
                                    <strong>Carga archivo Excel</strong>
                                </Form.Label>
                                <Form.Group>
                                    <Form.File
                                        onChange={this.encargarseCambioExcel}
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    />
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="danger"
                                    disabled={this.state.indButton}
                                    onClick={this.cerrarModalCargaMasiva}
                                >
                                    Cerrar
                                    {" "}
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </Button>
                                <Button
                                    variant="primary"
                                    disabled={this.state.indButton}
                                    onClick={this.enviarArchivoExcel}
                                >
                                    <div
                                        hidden={!this.state.indButton}
                                        className="spinner-grow spinner-grow-sm text-light"
                                        role="status"
                                    >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    Cargar archivo
                                    {" "}
                                    <i className="fa fa-upload" aria-hidden="true"></i>
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal
                            show={this.state.mostrarModalCargaIndividual}
                            onHide={this.cerrarModalCargaIndividual}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Carga Individual - Aval # {this.state.idAval}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Row>
                                    <Col xs={12} md={6}>
                                        <Form.Label>
                                            <b>Monto del crédito:</b>
                                        </Form.Label>
                                        <Form.Group>
                                            <h5>
                                                {" "}
                                                {this.state.simboloMoneda}
                                                {this.state.montoCredito}
                                            </h5>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Label>
                                            <b>Porcentaje aval solicitado:</b>
                                        </Form.Label>
                                        <Form.Group>
                                            <h5> {this.state.porcAval.toFixed(2)} %</h5>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col xs={12} md={6}>
                                        <Form.Label>
                                            <b>Saldo actual del crédito:</b>
                                        </Form.Label>
                                        <Form.Group>
                                            <h5>
                                                {this.state.simboloMoneda}
                                                {this.state.saldoCredito}
                                            </h5>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Label>
                                            <b>Saldo actual del aval:</b>
                                        </Form.Label>
                                        <Form.Group>
                                            <h5>
                                                {this.state.simboloMoneda}
                                                {this.state.saldoAval}
                                            </h5>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <hr />
                                <Form.Row>
                                    <Col xs={12} md={6}>
                                        <Form.Label>
                                            <b>Nuevo saldo del crédito:</b>
                                        </Form.Label>
                                        <Form.Group>
                                            <NumberFormat
                                                thousandSeparator={true}
                                                type="text"
                                                name="nuevoSaldoCredito"
                                                min="0"
                                                value={this.state.nuevoSaldoCredito}
                                                className="form-control"
                                                onChange={this.encargarseCambio}
                                                required
                                                placeholder="0"
                                                disabled={this.state.indButton}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Label>
                                            <b>Nuevo saldo del aval:</b>
                                        </Form.Label>
                                        <Form.Group>
                                            <NumberFormat
                                                thousandSeparator={true}
                                                type="text"
                                                name="nuevoSaldoAval"
                                                min="0"
                                                value={this.state.nuevoSaldoAval}
                                                className="form-control"
                                                onChange={this.encargarseCambio}
                                                required
                                                placeholder="0"
                                                disabled
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col xs={12} md={6}>
                                        <Form.Label>
                                            <b>Días de atraso:</b>
                                        </Form.Label>
                                        <Form.Group>
                                            <input
                                                type="number"
                                                name="diasAtraso"
                                                min="0"
                                                value={this.state.diasAtraso}
                                                className="form-control"
                                                onChange={this.encargarseCambio}
                                                required
                                                placeholder="Ingresar días"
                                                disabled={this.state.indButton || this.state.indicadorDiasAtraso}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Label><b>Comisión de administración:</b></Form.Label>
                                        <Form.Control
                                            type="checkbox"
                                            name="indicadorComisionAdministracion"
                                            onChange={this.encargarseCambio}
                                            value={this.state.indicadorComisionAdministracion}
                                            checked={this.state.indicadorComisionAdministracion}
                                            disabled
                                        />
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                </Form.Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="danger"
                                    disabled={this.state.indButton}
                                    onClick={this.cerrarModalCargaIndividual}
                                >
                                    Cerrar {" "}<i className="fa fa-times" aria-hidden="true"></i>
                                </Button>
                                <Button
                                    variant="primary"
                                    disabled={this.state.indButton}
                                    onClick={this.actualizarAvalIndividual}
                                >
                                    <div
                                        hidden={!this.state.indButton}
                                        className="spinner-grow spinner-grow-sm text-light"
                                        role="status"
                                    >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    Actualizar {" "}<i className="fa fa-refresh" aria-hidden="true"></i>
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                );
            }
            //REACT_APP_ROL_SEGUIMIENTO
            case "7": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Avales</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Avales
                                </li>
                            </ol>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Tipo Programa</th>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Moneda</th>
                                        <th>Nombre Solicitante</th>
                                        <th>Identificación Solicitante</th>
                                        <th>Monto Aval</th>
                                        <th>% Aval</th>
                                        <th>Saldo del Crédito</th>
                                        <th>Saldo del Aval</th>
                                        <th>Estado</th>
                                        <th>Editar</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_REPRESENTANTE_FODEMIPYME
            case "8": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Avales</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Avales
                                </li>
                            </ol>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Tipo Programa</th>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Moneda</th>
                                        <th>Nombre Solicitante</th>
                                        <th>Identificación Solicitante</th>
                                        <th>Monto Aval</th>
                                        <th>% Aval</th>
                                        <th>Saldo del Crédito</th>
                                        <th>Saldo del Aval</th>
                                        <th>Estado</th>
                                        <th>Editar</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            }
        }
    }
}
