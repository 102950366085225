import React, { useState } from 'react';
import {
    REACT_APP_ESTADO_CLIENTE_SUSPENDIDO
} from "../../../environment";
import { BorrarLocalStorage } from "../../../Utils/BorrarInfoLocalStorage";
import {
    obtenerFondosActivos,
    obtenerFondosAsignadosUsuario
} from "../../../Services/LoginService";
import swal from "sweetalert";

const cerrarSesion = (e) => {
    e.preventDefault();
    localStorage.clear();
    BorrarLocalStorage();
    window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
};

function AdminLayout({ children }) {
    const [fondosLista, setFondosLista] = useState([]);
    const [cargandoFondos, setCargandoFondos] = useState(false);
    const fecha = new Date();
    const anno = fecha.getFullYear();
    var vistaReportes = false;

    var pathname = children.props.location.pathname;
    if (pathname.split('/')[1] == "sub-creditos") pathname = "/sub-creditos";
    switch (pathname) {
        case "/reporte-general-avales": vistaReportes = true;
        case "/reporte-impacto-cualitativo": vistaReportes = true;
        case "/reporte-gestion-fodemipyme": vistaReportes = true;
        case "/reporte-honramiento": vistaReportes = true;
        case "/reporte-provisiones": vistaReportes = true;
        case "/reporte-linea-credito": vistaReportes = true;
        case "/usuarios": vistaReportes = true;
        case "/clientes": vistaReportes = true;
        case "/programas-individuales": vistaReportes = true;
        case "/programas-carteras": vistaReportes = true;
        case "/programas-perdidas": vistaReportes = true;
        case "/avales": vistaReportes = true;
        case "/lineas-creditos": vistaReportes = true;
        case "/sub-creditos": vistaReportes = true;
        case "/honramientos": vistaReportes = true;
    }
    const usuarioLDAP = localStorage.getItem("usuarioLDAP");
    const fondoSeleccionadoJson = JSON.parse(localStorage.getItem("fondoSeleccionado"));
    const fondosAsignadosJson = JSON.parse(localStorage.getItem("fondosAsignados"));
    var fondosActivos = JSON.parse(localStorage.getItem("fondosActivos"));
    if (fondosActivos != null && fondosLista.length == 0) {
        setFondosLista(fondosActivos);
    } else if (fondosLista.length == 0) {
        obtenerFondosActivos(localStorage.getItem("id_token")).then((res) => {
            if (res) {
                setFondosLista(res.fondos);
                localStorage.setItem("fondosActivos", JSON.stringify(res.fondos));
            }
        });
    }

    function cambionFondo(event) {
        const nuevoIdFondo = event.target.value;
        setCargandoFondos(true);
        obtenerFondosAsignadosUsuario().then((res) => {
            if (res) {
                localStorage.setItem("fondosAsignados", JSON.stringify(res.fondosEntidadesFinancieras));
                if (res.fondosEntidadesFinancieras.length == 1) {
                    localStorage.setItem("idFondo", res.fondosEntidadesFinancieras[0].fondo.id);
                    localStorage.setItem("fondoSeleccionado", JSON.stringify(res.fondosEntidadesFinancieras[0].fondo));
                }
                var fondosActivos = JSON.parse(localStorage.getItem("fondosActivos"));
                const fondoSeleccionadoJson = JSON.parse(localStorage.getItem("fondoSeleccionado"));
                const fondosAsignadosJson = JSON.parse(localStorage.getItem("fondosAsignados"));
                //FODEMIPYME
                var obtenerFondo = fondosAsignadosJson.filter(function (fondo) {
                    return (
                        fondo.fondo.id == nuevoIdFondo
                    );
                });
                var obtenerEFAOperadora = obtenerFondo[0].entidadFinancieraAutorizada.caracteristicas.filter(function (efa) {
                    if (efa.fondo != null) {
                        return (
                            efa.fondo.id == nuevoIdFondo
                        );
                    }
                });
                if (obtenerEFAOperadora[0].estado.id == REACT_APP_ESTADO_CLIENTE_SUSPENDIDO) {
                    swal(fondoSeleccionadoJson.nombreFondo, "La entidad financiera a la que pertenece el fondo seleccionado se encuentra en estado: " + obtenerEFAOperadora[0].estado.valor + ". Por favor comunicarse con el administrador", "error", { button: "Aceptar", });
                    setCargandoFondos(false);
                    return;
                }
                setCargandoFondos(false);
                swal({
                    title: "Estás seguro/segura?",
                    text: "Todos los cambios no guardados se perderán.",
                    icon: "warning",
                    buttons: ["No", "Si"],
                    dangerMode: true,
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            let fondo = fondosActivos.filter(
                                (fondo) => fondo.id == nuevoIdFondo
                            );

                            localStorage.removeItem("fondoSeleccionado");
                            localStorage.setItem("fondoSeleccionado", JSON.stringify(fondo[0]));

                            localStorage.removeItem("idfondo");
                            localStorage.setItem("idFondo", fondo[0].id);

                            localStorage.removeItem("nombreOperadora");
                            localStorage.setItem("nombreOperadora", obtenerFondo[0].entidadFinancieraAutorizada.nombreOperadora);

                            localStorage.removeItem("idOperadora");
                            localStorage.setItem("idOperadora", obtenerFondo[0].entidadFinancieraAutorizada.id);

                            window.location.href = "/admin";
                        }
                    });
            }
        });
    }

    return (
        <>
            <div>
                <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom fixed-top">
                    <div className="container">
                        <a className="navbar-brand" href="/admin">
                            <img src={fondoSeleccionadoJson.urlLogo} alt="logo" />
                        </a>
                        <ul className="nav navbar-nav float-lg-right mai-user-nav">
                            <li class="nav-item dropdown" style={{ padding: "5px 10px 0 0" }} hidden={!cargandoFondos}>
                                <div class="spinner-border spinner-border-sm" role="status">
                                    <span class="sr-only">Cargando...</span>
                                </div>
                            </li>
                            <li class="nav-item dropdown">
                                <select
                                    onChange={cambionFondo}
                                    class="form-control"
                                    value={fondoSeleccionadoJson.id}
                                    hidden={fondosAsignadosJson.length == 1}
                                    disabled={cargandoFondos}
                                >
                                    {fondosLista.map(
                                        function (data, key) {
                                            return (
                                                <option key={key} value={data.id}>
                                                    {data.nombreFondo}
                                                </option>
                                            );
                                        }
                                    )}
                                </select>
                            </li>
                            <li className="nav-item dropdown">
                                <span className="p-2 navbar-text" style={{ color: "black" }}>
                                    {usuarioLDAP}
                                </span>
                            </li>
                            <li className="nav-item dropdown">
                                <a
                                    className="nav-link"
                                    style={{ color: "black", cursor: "pointer" }}
                                    onClick={cerrarSesion}
                                >
                                    <i className="fa fa-sign-out"></i>Salir
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
                <div
                    className="container-fluid padding-main"
                    style={{ marginTop: "150px" }}
                >
                    <main role="main" className={vistaReportes ? "container-fluid" : "container"}>
                        {children}
                    </main>
                </div>
                <footer
                    style={{
                        background: "#6f6f71",
                        bottom: "0",
                        position: "fixed",
                        width: "100%",
                    }}
                >
                    <div className="container">
                        <div className="row align-items-center justify-content-md-between py-2 mt-2 delimiter-top">
                            <div className="col-md-6">
                                <div
                                    className="copyright text-sm font-weight-bold text-center text-md-left"
                                    style={{ color: "white" }}
                                >
                                    {fondoSeleccionadoJson.leyenda}
                                    <a
                                        href="#"
                                        className="font-weight-bold"
                                        style={{ color: "white" }}
                                        target="_blank"
                                    >
                                        {" "}
                                        ({fondoSeleccionadoJson.nombreFondo})
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <ul className="nav justify-content-center justify-content-md-end mt-3 mt-md-0">
                                    <li className="nav-item">
                                        <p className="font-weight-bold" style={{ color: "white" }}>
                                            &copy; {anno}. Reservados todos los derechos.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
};

export default AdminLayout;
