import { REACT_APP_CODIGO_RESPUESTA_401, REACT_APP_CODIGO_RESPUESTA_200 } from "../environment";
import { BorrarLocalStorage } from "../Utils/BorrarInfoLocalStorage";
import swal from "sweetalert";
/**
 * Obtener el Fondo seleccionado en iniciar sesion
 **/
const fondoSeleccionado = JSON.parse(localStorage.getItem("fondoSeleccionado"));

export const ObtenerPersonaPadron = async (request) => {
    try {
        const respuesta = await fetch(
            `/api/ObtenerPersonaPadron?fondo=${localStorage.getItem("idFondo")}&cedula=${request.Cedula}`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (respuesta.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
            }).then(() => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        if (respuesta.status != REACT_APP_CODIGO_RESPUESTA_200) {
            try {
                var respuestaJson = await respuesta.json();
                if (respuestaJson && respuestaJson.mensaje) {
                    swal({
                        title: fondoSeleccionado.nombreFondo,
                        text: respuestaJson.mensaje,
                        icon: "error",
                    });
                } else {
                    swal({
                        title: fondoSeleccionado.nombreFondo,
                        text: "Ha ocurrido un error al obtener la persona física, no se obtuvo respuesta del servidor",
                        icon: "error",
                    });
                }
                return;
            } catch (e) {
                swal({
                    title: fondoSeleccionado.nombreFondo,
                    text: "Ha ocurrido un error al obtener la persona física",
                    icon: "error",
                });
                throw e;
            }
        }
        return respuesta.json();
    } catch (error) {
        throw error;
    }
};