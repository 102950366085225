import React from "react";
import swal from "sweetalert";
import {
    REACT_APP_ROL_ADMINISTRATIVO,
    REACT_APP_CODIGO_RESPUESTA_200,
    REACT_APP_TIEMPO_EXPIRACION_CLAVE,
    REACT_APP_ASUNTO_CLAVE_TEMPORAL,
    REACT_APP_TIEMPO_INACTIVACION_USUARIO,
    REACT_APP_FECHA_ACEPTAR_TERMINOS_CONDICIONES,
    REACT_APP_TERMINOS_CONDICIONES
} from "../../environment";
import { ObtenerParametro, ModificarParametro } from "../../Services/ParametroService";
import moment from "moment";
const $ = require("jquery");
$.DataTable = require("datatables.net-bs4");
/**
 * Obtener el Fondo seleccionado en iniciar sesion
 **/
const fondoSeleccionado = JSON.parse(localStorage.getItem("fondoSeleccionado"));
class EditarParametroComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            valor: 0,
            indButton: false,
            llave: ""
        };
    }
    /**
     * Llamado inmediatamente después de montar un componente. 
     * Establecer el estado aquí activará la re-renderización.
     **/
    componentDidMount() {
        $("#guardando").hide();
        $("#spinValor").hide();
        const tokenContent = localStorage.getItem("isLoggedIn");
        if (!tokenContent) window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;

        const valorParametro = sessionStorage.getItem("valorParametro");
        if (!valorParametro) window.location.href = "/mantenimiento-parametros";
        this.state.llave = valorParametro;
        this.setState({ llave: valorParametro });
        this.obtenerParametro();
    }
    /**
     * 
     * @param {*} event 
     * @returns Obtener el valor del parametro. 
     **/
    obtenerParametro = () => {
        $("#spinValor").show();
        this.setState({ indButton: true });
        ObtenerParametro(this.state.llave).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Parametro", "Ha ocurrido un error al obtener el parámetro de " + this.state.llave, "error");
                    this.setState({ indButton: false });
                    return;
                }
                if (this.state.llave == REACT_APP_FECHA_ACEPTAR_TERMINOS_CONDICIONES) {
                    this.setState({
                        valor: moment(res.json.valor)
                            .locale("en")
                            .format("YYYY-MM-DD"),
                    });
                } else {
                    this.setState({ valor: res.json.valor });
                }
                this.setState({ indButton: false });
                $("#spinValor").hide();
            }
        });
    }
    /**
      * 
      * @param {*} event 
      * @returns Alamacena en el State lo que digitado en el formulario. 
      **/
    encargarseCambio = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }
    /**
     * 
     * @param {*} event 
     * @returns Editar valor del parametro. 
     **/
    modificarParametro = (event) => {
        event.preventDefault();
        $("#guardando").show();
        var numeros = /^([0-9]{0,2})$/;
        const letras = new RegExp(/^[a-zA-ZáéíñóúüÁÉÍÑÓÚÜ ]*$/);
        const soloNumerosValor = numeros.test(
            Number(this.state.valor)
        );
        const asuntoCorreoValido = letras.test(this.state.valor);
        if (!this.state.valor) {
            swal(
                "Editar Parámetro",
                "El valor del parámetro es requerido",
                "error"
            );
            $("#guardando").hide();
            return;
        }
        switch (this.state.llave) {
            case REACT_APP_ASUNTO_CLAVE_TEMPORAL:
                if (!asuntoCorreoValido) {
                    swal("Editar Parámetro", "El valor del parámetro " + this.state.llave + " debe contener solo letras", "error");
                    $("#guardando").hide();
                    return;
                }
                if (this.state.valor.length < 6) {
                    swal(
                        "Editar Parámetro",
                        "El valor del parámetro " + this.state.llave + " no puede tener menos de 6 caracteres",
                        "error"
                    );
                    $("#guardando").hide();
                    return;
                }
                if (this.state.valor.length > 200) {
                    swal(
                        "Editar Parámetro",
                        "El valor del parámetro " + this.state.llave + " no puede tener mas de 200 caracteres",
                        "error"
                    );
                    $("#guardando").hide();
                    return;
                }
                break;
            case REACT_APP_TERMINOS_CONDICIONES:
                if (this.state.valor.length < 6) {
                    swal(
                        "Editar Parámetro",
                        "El valor del parámetro " + this.state.llave + " no puede tener menos de 6 caracteres",
                        "error"
                    );
                    $("#guardando").hide();
                    return;
                }
                if (this.state.valor.length > 4000) {
                    swal(
                        "Editar Parámetro",
                        "El valor del parámetro " + this.state.llave + " no puede tener mas de 4000 caracteres",
                        "error"
                    );
                    $("#guardando").hide();
                    return;
                }
                break;
            case REACT_APP_FECHA_ACEPTAR_TERMINOS_CONDICIONES:
                const fechaActual = new Date();
                var fechaValor = new Date(this.state.valor);
                var fechaTerminosCondiciones = new Date(fechaValor.getFullYear(), fechaValor.getMonth(), fechaValor.getDate() + 1);
                fechaActual.setHours(0, 0, 0, 0);
                fechaTerminosCondiciones.setHours(0, 0, 0, 0);
                if (fechaTerminosCondiciones.getTime() < fechaActual.getTime()) {
                    swal(
                        "Editar Parámetro",
                        "Rango de fecha inválido. La fecha de términos y condiciones tiene que ser igual o mayor a hoy.",
                        "warning", { button: "Aceptar", }
                    );
                    $("#guardando").hide();
                    return;
                }
                break;
            default:
                if (this.state.valor == 0) {
                    swal(
                        "Editar Parámetro",
                        "El valor del parámetro no es válido",
                        "error"
                    );
                    $("#guardando").hide();
                    return;
                }
                if (this.state.valor > 20 && this.state.llave != REACT_APP_TIEMPO_EXPIRACION_CLAVE && this.state.llave != REACT_APP_TIEMPO_INACTIVACION_USUARIO) {
                    swal(
                        "Editar Parámetro",
                        "El valor del parámetro excede el límite permitido: 20",
                        "error"
                    );
                    $("#guardando").hide();
                    return;
                }
                if (this.state.valor > 99 && (this.state.llave == REACT_APP_TIEMPO_EXPIRACION_CLAVE || this.state.llave == REACT_APP_TIEMPO_INACTIVACION_USUARIO)) {
                    swal(
                        "Editar Parámetro",
                        "El valor del parámetro excede el límite permitido: 99",
                        "error"
                    );
                    $("#guardando").hide();
                    return;
                }
                if (!soloNumerosValor) {
                    swal(
                        "Editar Parámetro",
                        "El valor del parámetro no tiene un formato válido o excede el límite permitido",
                        "error"
                    );
                    $("#guardando").hide();
                    return;
                }
                break;

        }

        let data = {
            Valor: this.state.valor,
            Llave: this.state.llave
        };

        this.setState({ indButton: true });
        ModificarParametro(data).then((res) => {
            if (res) {
                if (res.codigo !== REACT_APP_CODIGO_RESPUESTA_200) {
                    swal(fondoSeleccionado.nombreFondo, res.json == "" ? "Error al editar el parámetro: " + this.state.llave : res.json.mensaje, "error");
                    $("#guardando").hide();
                    this.setState({ indButton: false });
                    return;
                }
                $("#guardando").hide();
                this.setState({ indButton: false });
                swal(fondoSeleccionado.nombreFondo, "¡Parámetro editado con éxito!", "success").then(
                    () => {
                        window.location.href = "/mantenimiento-parametros";
                    }
                );
            }
        });
    };
    render() {
        const idRol = localStorage.getItem("idRol");
        if (idRol) {
            if (idRol != REACT_APP_ROL_ADMINISTRATIVO) window.location.href = "/admin";
        } else {
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        }
        switch (idRol) {
            case "1": {
                return (
                    <div className="container text-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/mantenimiento">Mantenimientos</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/mantenimiento-parametros">Parámetros</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Editar Parámetro
                                </li>
                            </ol>
                        </nav>
                        <div className="row">
                            <form className="form-crear" onSubmit={this.modificarParametro}>
                                <h1 className="h3 mb-3 font-weight-normal">
                                    Editar Parámetro: <b> {this.state.llave}</b>
                                </h1>
                                {this.state.llave == REACT_APP_FECHA_ACEPTAR_TERMINOS_CONDICIONES ? (
                                    <div className="row form-padding">
                                        <div className="col-md-3"></div>
                                        <div className="col-md-6">
                                            <label>Valor </label>
                                            <input
                                                type="date"
                                                name="valor"
                                                className="form-control"
                                                value={this.state.valor}
                                                onChange={this.encargarseCambio}
                                                disabled={this.state.indButton}
                                                required
                                            />
                                            <span className="input-group-addon" id="spinValor">
                                                <i className="fa fa-refresh fa-spin"></i>
                                            </span>
                                        </div>
                                        <div className="col-md-3"></div>
                                    </div>

                                ) : this.state.llave == REACT_APP_TERMINOS_CONDICIONES ? (
                                    <div className="row form-padding">
                                        <div className="col-md-3"></div>
                                        <div className="col-md-12">
                                            <label>Valor </label>
                                            <textarea
                                                className="form-control"
                                                minLength="6"
                                                maxLength="4000"
                                                name="valor"
                                                value={this.state.valor}
                                                onChange={this.encargarseCambio}
                                                required
                                                rows="10"
                                                placeholder="Valor del parámetro"
                                                title="La Dirección debe contener mínimo 6 y máximo 4000 caracteres"
                                                disabled={this.state.indButton}
                                            ></textarea>
                                            <span className="input-group-addon" id="spinValor">
                                                <i className="fa fa-refresh fa-spin"></i>
                                            </span>
                                        </div>
                                        <div className="col-md-3"></div>
                                    </div>
                                ) : (
                                    <div className="row form-padding">
                                        <div className="col-md-3"></div>
                                        <div className="col-md-6">
                                            <label>Valor </label>
                                            <input
                                                type="text"
                                                name="valor"
                                                maxlength={this.state.llave != REACT_APP_ASUNTO_CLAVE_TEMPORAL ? 20 : 200}
                                                className="form-control"
                                                value={this.state.valor}
                                                onChange={this.encargarseCambio}
                                                disabled={this.state.indButton}
                                                required
                                                placeholder="Valor del parámetro"
                                            />
                                            <span className="input-group-addon" id="spinValor">
                                                <i className="fa fa-refresh fa-spin"></i>
                                            </span>
                                        </div>
                                        <div className="col-md-3"></div>
                                    </div>
                                )}
                                <div className="row form-padding">
                                    <div className="col-md-3"></div>
                                    <div className="col-md-6">
                                        <button
                                            type="submit"
                                            className="btn btn-primary border-radius"
                                            disabled={this.state.indButton}
                                        >
                                            <span
                                                id="guardando"
                                                className="spinner-grow spinner-grow-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                            <span id="guardando" className="sr-only">
                                                Loading...
                                            </span>
                                            Guardar
                                            {" "}
                                            <i className="fa fa-edit" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                    <div className="col-md-3"></div>
                                </div>
                            </form>
                        </div>
                    </div>
                );
            }
        }
    }
}
export default EditarParametroComponent;