import {
    REACT_APP_CODIGO_RESPUESTA_401,
    REACT_APP_CODIGO_RESPUESTA_200
} from "../environment";
import {
    ObtenerUserAgent,
    ObtenerNombreUsuario,
} from "../Utils/BaseSolicitudModel";
import { BorrarLocalStorage } from "../Utils/BorrarInfoLocalStorage";
import swal from "sweetalert";
/**
 * Obtener el Fondo seleccionado en iniciar sesion
 **/
const fondoSeleccionado = JSON.parse(localStorage.getItem("fondoSeleccionado"));
export const CrearHornamiento = async (request) => {
    let userAgent = ObtenerUserAgent();
    let nombreUsuario = ObtenerNombreUsuario();
    try {
        var data = {
            IP: localStorage.getItem("ip"),
            UserAgent: userAgent,
            NombreUsuario: nombreUsuario,
            Latitud: localStorage.getItem("latitud"),
            Longitud: localStorage.getItem("longitud"),
            IdFondo: localStorage.getItem("idFondo"),
            IdAval: request.IdAval,
            IdEjecutivo: request.IdEjecutivo,
            MontoHonramiento: request.MontoHonramiento,
            DiasMorosidad: request.DiasMorosidad
        };

        let formData = new FormData();
        formData.append("Solicitud", JSON.stringify(data));

        formData.append("ArchivoEvidenciaHonramiento", request.ArchivoEvidenciaHonramiento);

        const respuesta = await fetch(
            `/api/CrearHonramiento?fondo=${localStorage.getItem("idFondo")}`,
            {
                timeout: 60000,
                method: "POST",
                credentials: "include",
                body: formData,
            }
        );
        if (respuesta.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then(() => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        if (respuesta.status != REACT_APP_CODIGO_RESPUESTA_200) {
            try {
                var respuestaJson = await respuesta.json();
                var textoError = respuesta.status == 500 ? `Si se ha presentado un error inesperado, por favor enviar el siguiente código de error al administrador de FODEMIPYME : ${respuestaJson.identificacionSolicitud}` : respuestaJson.mensaje;
                if (respuestaJson && respuestaJson.mensaje) {
                    swal({
                        title: fondoSeleccionado.nombreFondo,
                        text: textoError,
                        icon: "error",
                    });
                } else {
                    swal({
                        title: fondoSeleccionado.nombreFondo,
                        text: "Ha ocurrido un error al guardar el aval, no se obtuvo respuesta del servidor",
                        icon: "error",
                    });
                }
                return;
            } catch (e) {
                swal({
                    title: fondoSeleccionado.nombreFondo,
                    text: "Ha ocurrido un error al guardar el aval",
                    icon: "error",
                });
                throw e;
            }
        }
        return respuesta;
    } catch (error) {
        throw error;
    }
};

export const ModificarHornamiento = async (request) => {
    let userAgent = ObtenerUserAgent();
    let nombreUsuario = ObtenerNombreUsuario();
    try {
        var data = {
            IP: localStorage.getItem("ip"),
            UserAgent: userAgent,
            NombreUsuario: nombreUsuario,
            Latitud: localStorage.getItem("latitud"),
            Longitud: localStorage.getItem("longitud"),
            IdFondo: localStorage.getItem("idFondo"),
            Id: request.Id,
            MontoHonramiento: request.MontoHonramiento,
            DiasMorosidad: request.DiasMorosidad,
            FechaPresentacionDemanda: request.FechaPresentacionDemanda,
            IdEjecutivo: request.IdEjecutivo,
            IdEstado: request.IdEstado
        };
        let formData = new FormData();
        formData.append("Solicitud", JSON.stringify(data));
        formData.append("ArchivoDemanda", request.ArchivoDemanda);
        formData.append("ArchivoEvidenciaHonramiento", request.ArchivoEvidenciaHonramiento);
        formData.append("ArchivoEvidenciaIrrecuperable", request.ArchivoEvidenciaIrrecuperable);
        const respuesta = await fetch(
            `/api/ModificarHonramiento?fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "POST",
                credentials: "include",
                body: formData,
            }
        );
        if (respuesta.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then(() => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        if (respuesta.status != REACT_APP_CODIGO_RESPUESTA_200) {
            try {
                var respuestaJson = await respuesta.json();
                var textoError = respuesta.status == 500 ? `Si se ha presentado un error inesperado, por favor enviar el siguiente código de error al administrador de FODEMIPYME : ${respuestaJson.identificacionSolicitud}` : respuestaJson.mensaje;
                if (respuestaJson && respuestaJson.mensaje) {
                    swal({
                        title: fondoSeleccionado.nombreFondo,
                        text: textoError,
                        icon: "error",
                    });
                } else {
                    swal({
                        title: fondoSeleccionado.nombreFondo,
                        text: "Ha ocurrido un error al guardar el aval, no se obtuvo respuesta del servidor",
                        icon: "error",
                    });
                }
                return;
            } catch (e) {
                swal({
                    title: fondoSeleccionado.nombreFondo,
                    text: "Ha ocurrido un error al guardar el aval",
                    icon: "error",
                });
                throw e;
            }
        }
        return respuesta;
    } catch (error) {
        throw error;
    }
};

export const puedeHonar = async (request) => {
    let userAgent = ObtenerUserAgent();
    let nombreUsuario = ObtenerNombreUsuario();
    try {
        var data = {
            IP: localStorage.getItem("ip"),
            UserAgent: userAgent,
            NombreUsuario: nombreUsuario,
            Latitud: localStorage.getItem("latitud"),
            Longitud: localStorage.getItem("longitud"),
            IdAval: request.IdAval,
            IdEjecutivo: request.IdEjecutivo,
            IdCliente: request.IdCliente
        };

        const crearHonramiento = await fetch(
            `/api/PuedeHonrar?fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        if (crearHonramiento.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then(() => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const respuesta = {
            json: "",
            codigo: 0,
        };
        respuesta.json = await crearHonramiento.json();
        respuesta.codigo = crearHonramiento.status;

        return respuesta;
    } catch (error) {
        throw error;
    }
};
export const validarDiasAtrasoSubcreditos = async (request) => {
    let ip = localStorage.getItem("ip");
    let userAgent = ObtenerUserAgent();
    let nombreUsuario = ObtenerNombreUsuario();
    let latitud = localStorage.getItem("latitud");
    let longitud = localStorage.getItem("longitud");
    try {
        var data = {
            IP: ip,
            UserAgent: userAgent,
            NombreUsuario: nombreUsuario,
            Latitud: latitud,
            Longitud: longitud,
            IdAval: request.IdAval,
            IdEjecutivo: request.IdEjecutivo
        };

        const crearHonramiento = await fetch(
            `/api/ValidarDiasAtrasoSubcreditos?fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        if (crearHonramiento.status === 401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then(() => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const respuesta = {
            json: "",
            codigo: 0,
        };
        respuesta.json = await crearHonramiento.json();
        respuesta.codigo = crearHonramiento.status;

        return respuesta;
    } catch (error) {
        throw error;
    }
};
export const ObtenerObservacionesXHonramiento = async (request) => {
    try {
        const respuesta = await fetch(
            `/api/ObtenerObservacionesXHonramiento?IdAval=${request.IdAval}&fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (respuesta.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then(() => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const res = {
            json: "",
            codigo: 0,
        };

        res.json = await respuesta.json();
        res.codigo = respuesta.status;
        return res;
    } catch (error) {
        throw error;
    }
};
export const AgregarObservacionHonramiento = async (request) => {
    let userAgent = ObtenerUserAgent();
    let nombreUsuario = ObtenerNombreUsuario();

    try {
        var data = {
            IP: localStorage.getItem("ip"),
            UserAgent: userAgent,
            NombreUsuario: nombreUsuario,
            Latitud: localStorage.getItem("latitud"),
            Longitud: localStorage.getItem("longitud"),
            IdAval: request.IdAval,
            IdEjecutivo: request.IdEjecutivo,
            Observacion: request.Observacion,
        };
        const respuesta = await fetch(
            `/api/AgregarObservacionHonramiento?fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        if (respuesta.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then(() => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const res = {
            json: "",
            codigo: 0,
        };

        res.json = await respuesta.json();
        res.codigo = respuesta.status;
        return res;
    } catch (error) {
        throw error;
    }
};
export const EditarObservacionHonramiento = async (request) => {
    let userAgent = ObtenerUserAgent();
    let nombreUsuario = ObtenerNombreUsuario();

    try {
        var data = {
            IP: localStorage.getItem("ip"),
            UserAgent: userAgent,
            NombreUsuario: nombreUsuario,
            Latitud: localStorage.getItem("latitud"),
            Longitud: localStorage.getItem("longitud"),
            Id: request.Id,
            IdAval: request.IdAval,
            IdEjecutivo: request.IdEjecutivo,
            Observacion: request.Observacion,
        };
        const respuesta = await fetch(
            `/api/EditarObservacionHonramiento?fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        if (respuesta.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
                button: "Aceptar",
            }).then(() => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const res = {
            json: "",
            codigo: 0,
        };

        res.json = await respuesta.json();
        res.codigo = respuesta.status;
        return res;
    } catch (error) {
        throw error;
    }
};