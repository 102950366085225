import React, { Component } from "react";
import swal from "sweetalert";
import {
    REACT_APP_OBTENER_ROLES_FODEMIPYME,
    REACT_APP_OBTENER_ROLES_OPERADORA,
    REACT_APP_CATALOGO_OBTENER_TIPO_IDENTIFICACION,
    REACT_APP_CEDULA_BP,
    REACT_APP_FONDO_ESTADO_ACTIVO,
    REACT_APP_CODIGO_RESPUESTA_200,
    REACT_APP_CODIGO_RESPUESTA_401,
    REACT_APP_OPERADORA_FODEMIPYME,
    REACT_APP_OPERADORA_FNA,
    REACT_APP_ROL_ADMINISTRATIVO,
    REACT_APP_ROL_EJECUTIVO,
    REACT_APP_ROL_CONTABILIDAD,
    REACT_APP_ROL_SEGUIMIENTO,
    REACT_APP_ROL_REPRESENTANTE_FODEMIPYME,
    REACT_APP_IDENTIFICACION_USUARIO_FISICA_NACIONAL,
    REACT_APP_IDENTIFICACION_USUARIO_DIMEX
} from "../../environment";
import { GetGenerico, ObtenerCatalogo } from "../../Services/GenericoService";
import { ObtenerPersonaPadron } from "../../Services/PadronService";
import { BorrarLocalStorage } from "../../Utils/BorrarInfoLocalStorage";
import { ObtenerUserAgent } from "../../Utils/BaseSolicitudModel";
import {
    CrearUsuario,
    ListarProvincias,
    ListarCentrosEmpresariales,
} from "../../Services/UsuarioService";
import { Usuario } from "./Usuario.css";

const $ = require("jquery");
$.DataTable = require("datatables.net-bs4");
/**
 * Obtener el Fondo seleccionado en iniciar sesion
 **/
const fondoSeleccionado = JSON.parse(localStorage.getItem("fondoSeleccionado"));
/**
 * Obtener el ID del Fondo seleccionado en iniciar sesion
 **/
const idFondo = localStorage.getItem("idFondo");
class CrearUsuarioComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientes: [],
            clienteSeleccionado: "",
            roles: [],
            rolSeleccionado: "",
            clave: "",
            nombrePersona: "",
            apellidoPersona: "",
            cedulaPersona: "",
            correoElectronico: "",
            tipoIdentificacionLista: [],
            tipoIdentificacionSeleccionado: "",
            indButton: false,
            indCargando: false,
            provinciasLista: [],
            provinciaSeleccionada: "",
            centrosEmpresarialesLista: [],
            ccLista: [],
            centroEmpresarialSelecionado: "",
            estadoCivil: "",
            gradoAcademico: "",
            profesion: "",
            direccion: "",
            indicadorFODEMIPYME: true,
            indicadorFNA: true,
            fondosAgregados: [],
            fondosActivos: [],
            indRolEjecutivoFODEMIPYME: false,
            indRolEjecutivoFNA: false
        };
    }
    /**
     * Llamado inmediatamente después de montar un componente. 
     * Establecer el estado aquí activará la re-renderización.
     **/
    componentDidMount() {
        $("#guardando").hide();
        $("#spinRol").hide();
        $("#spinEFA").show();
        $("#spinNombre").hide();
        $("#spinApellido").hide();
        $("#mostrarCentroEmpresariales").hide();
        const tokenContent = localStorage.getItem("isLoggedIn");
        if (!tokenContent) {
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        }
        const userAgent = ObtenerUserAgent();
        const fondosActivosLocal = localStorage.getItem("fondosActivos");
        const fondosActivosLocalJson = JSON.parse(fondosActivosLocal);
        this.state.fondosActivos = fondosActivosLocalJson;
        this.setState({ fondosActivos: fondosActivosLocalJson });

        this.state.fondosActivos.forEach((fondo) => {
            this.state.fondosAgregados.push(fondo.id);
        })
        this.cargarTiposIdentificacionUsuario();
        /**
         * Creacion del DataTable
         **/
        $("#tabla1").DataTable({
            processing: true,
            serverSide: true,
            pageLength: 50,
            ajax: {
                url: `/api/ObtenerClientes?fondo=${localStorage.getItem("idFondo")}&ip=${localStorage.getItem("ip")}&latitud=${localStorage.getItem("latitud")}&longitud=${localStorage.getItem("longitud")}&userAgent=${userAgent}&usuario=${localStorage.getItem("idUsuarioCreador")}`,
                error: function (xhr, thrown) {
                    if (xhr.status == REACT_APP_CODIGO_RESPUESTA_401) {
                        swal({
                            title: "¡Sesión Expiró!",
                            text: "Por favor vuelva a iniciar sesión",
                            icon: "warning",
                            button: "Aceptar",
                        }).then(() => {
                            BorrarLocalStorage();
                            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                        });
                        return;
                    }
                    if (xhr.status !== REACT_APP_CODIGO_RESPUESTA_200) {
                        swal("Error al obtener los clientes", thrown, "error", { button: "Aceptar", });
                        $("#spinEFA").hide();
                        return;
                    }
                },
                success: (data) => {
                    var filtroClientes = [];
                    var estadoActivo = 3;
                    if (data != null) {
                        $("#spinEFA").hide();
                        var idFondo = localStorage.getItem("idFondo");
                        filtroClientes = data.data.filter(function (cliente) {
                            var caract = [];
                            caract = cliente.caracteristicas.filter(function (val) {
                                return (
                                    val.fondo.id == idFondo
                                );
                            });
                            return (
                                caract[0].estado.id == estadoActivo
                            );
                        });
                        this.setState({
                            clientes: filtroClientes,
                            clienteSeleccionado: filtroClientes[0],
                        });
                        if (filtroClientes[0].cedulaJuridica == REACT_APP_CEDULA_BP) {
                            const provinciasLista = localStorage.getItem("provinciasLista");
                            const provinciasListaJson = JSON.parse(provinciasLista);
                            if (provinciasListaJson == null) {
                                ListarProvincias().then((res) => {
                                    if (res) {
                                        localStorage.setItem(
                                            "provinciasLista",
                                            JSON.stringify(res.json.provincias)
                                        );
                                        this.state.provinciasLista = res.json.provincias;
                                        this.setState({
                                            provinciasLista: res.json.provincias,
                                            provinciaSeleccionada: res.json.provincias[0],
                                        });
                                        $("#mostrarCentroEmpresariales").show();
                                        this.cargarCentrosEmpresariales();
                                    }
                                });
                            } else {
                                this.state.provinciasLista = provinciasListaJson;
                                this.setState({
                                    provinciasLista: provinciasListaJson,
                                    provinciaSeleccionada: provinciasListaJson[0],
                                });
                                $("#mostrarCentroEmpresariales").show();
                                this.cargarCentrosEmpresariales();
                            }
                        } else {
                            $("#mostrarCentroEmpresariales").hide();
                            this.setState({ ccLista: [], centroEmpresarialSelecionado: "" });
                        }
                    }
                    this.listarRoles();
                },
                type: "POST",
            },
            columns: [{ data: "id", name: "id" }],
        });
    }
    /**
     * 
     * @param {*} event
     * @returns Lista de roles
     **/
    listarRoles = (event) => {
        $("#spinRol").show();
        this.setState({ indCargando: true });
        if (this.state.clienteSeleccionado.id == REACT_APP_OPERADORA_FODEMIPYME || this.state.clienteSeleccionado.id == REACT_APP_OPERADORA_FNA) {
            GetGenerico(REACT_APP_OBTENER_ROLES_FODEMIPYME).then(
                (res) => {
                    if (res) {
                        if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                            swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                            return;
                        }
                        $("#spinRol").hide();
                        this.setState({ indCargando: false });
                        this.setState({
                            roles: res.json.roles,
                            rolSeleccionado: res.json.roles[0],
                        });
                    }
                }
            );
        } else {
            GetGenerico(REACT_APP_OBTENER_ROLES_OPERADORA).then(
                (res) => {
                    if (res) {
                        if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                            swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                            return;
                        }
                        $("#spinRol").hide();
                        this.setState({ indCargando: false });
                        this.setState({
                            roles: res.json.roles,
                            rolSeleccionado: res.json.roles[0],
                        });
                    }
                }
            );
        }
    };
    /**
     * Listar los tipos de identificacion
     **/
    cargarTiposIdentificacionUsuario = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_TIPO_IDENTIFICACION,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                this.setState({
                    tipoIdentificacionLista: res.json.catalogoValores,
                });
                this.setState({
                    tipoIdentificacionSeleccionado: res.json.catalogoValores[0],
                });
            }
        });
    };
    /**
     * 
     * @param {*} event 
     * @returns Alamacena en el State lo digitado en el formulario. 
     **/
    encargarseCambio = (event) => {
        if (event.target.name === "indicadorFODEMIPYME") {
            if (!event.target.checked && !this.state.indicadorFNA) {
                swal(
                    "Crear Usuario",
                    "Al menos un fondo es requerido",
                    "warning", { button: "Aceptar", }
                );
                return;
            }
            let fondo = this.state.fondosActivos.filter((fondo) => fondo.id == REACT_APP_OPERADORA_FODEMIPYME);
            if (fondo.length > 0 && event.target.checked) {
                this.state.fondosAgregados.push(fondo[0].id);
            } else if (fondo.length > 0 && !event.target.checked) {
                var arregloIdFondos = [];
                arregloIdFondos = this.state.fondosAgregados;
                let index = arregloIdFondos.indexOf(fondo[0].id);
                arregloIdFondos.splice(index, 1);
                this.state.fondosAgregados = arregloIdFondos;
                this.setState({ fondosAgregados: arregloIdFondos });
            }
            this.setState({ [event.target.name]: event.target.checked });
            return;
        }
        if (event.target.name === "indicadorFNA") {
            if (!event.target.checked && !this.state.indicadorFODEMIPYME) {
                swal(
                    "Crear Usuario",
                    "Al menos un fondo es requerido",
                    "warning", { button: "Aceptar", }
                );
                return;
            }
            let fondo = this.state.fondosActivos.filter((fondo) => fondo.id == REACT_APP_OPERADORA_FNA);
            if (fondo.length > 0 && event.target.checked) {
                this.state.fondosAgregados.push(fondo[0].id);
            } else if (fondo.length > 0 && !event.target.checked) {
                var arregloIdFondos = [];
                arregloIdFondos = this.state.fondosAgregados;
                let index = arregloIdFondos.indexOf(fondo[0].id);
                arregloIdFondos.splice(index, 1);
                this.state.fondosAgregados = arregloIdFondos;
                this.setState({ fondosAgregados: arregloIdFondos });
            }

            this.setState({ [event.target.name]: event.target.checked });
            return;
        }
        if (event.target.name === "cedulaPersona" &&
            event.target.value.length == 9 &&
            this.state.tipoIdentificacionSeleccionado.id == REACT_APP_IDENTIFICACION_USUARIO_FISICA_NACIONAL) {
            this.buscarPersonaPadron(event.target.value);
        }
        this.setState({ [event.target.name]: event.target.value });
    };
    /**
     * 
     * @param {*} event 
     * @returns Buscar persona en el padron. 
     **/
    buscarPersonaPadron = (cedula) => {
        let data = {
            Cedula: cedula,
        };
        this.setState({ indButton: true });
        $("#spinNombre").show();
        $("#spinApellido").show();
        ObtenerPersonaPadron(data).then((res) => {
            if (res) {
                this.state.nombrePersona = res.nombre;
                this.setState({ nombrePersona: res.nombre });

                this.state.apellidoPersona = res.apellido1 + " " + res.apellido2;
                this.setState({ apellidoPersona: res.apellido1 + " " + res.apellido2 });
            } else {
                this.setState({ nombrePersona: "" });
                this.setState({ apellidoPersona: "" });
            }
            $("#spinNombre").hide();
            $("#spinApellido").hide();
            this.setState({ indButton: false });
        });
    };
    /**
     * Listar los centros empresariales
     **/
    cargarCentrosEmpresariales = () => {
        const centrosEmpresarialesLista = localStorage.getItem(
            "centrosEmpresarialesLista"
        );
        const centrosEmpresarialesListaJson = JSON.parse(centrosEmpresarialesLista);
        if (centrosEmpresarialesListaJson == null) {
            ListarCentrosEmpresariales().then((res) => {
                if (res) {
                    if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                        swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                        return;
                    }
                    localStorage.setItem(
                        "centrosEmpresarialesLista",
                        JSON.stringify(res.json.centrosEmpresariales)
                    );
                    this.state.centrosEmpresarialesLista = res.json.centrosEmpresariales;
                    this.setState({
                        centrosEmpresarialesLista: res.json.centrosEmpresariales,
                    });
                    let centroEmpresarial = this.state.centrosEmpresarialesLista.filter(
                        (cEmpresarial) =>
                            cEmpresarial.idProvincia == this.state.provinciasLista[0].id
                    );
                    this.setState({
                        ccLista: centroEmpresarial,
                        centroEmpresarialSelecionado: centroEmpresarial[0],
                    });
                }
            });
        } else {
            this.state.centrosEmpresarialesLista = centrosEmpresarialesListaJson;
            this.setState({
                centrosEmpresarialesLista: centrosEmpresarialesListaJson,
            });
            let centroEmpresarial = this.state.centrosEmpresarialesLista.filter(
                (cEmpresarial) =>
                    cEmpresarial.idProvincia == this.state.provinciasLista[0].id
            );
            this.setState({
                ccLista: centroEmpresarial,
                centroEmpresarialSelecionado: centroEmpresarial[0],
            });
        }
    };
    /**
     * 
     * @param {*} event
     * @returns Cambiar el centro empresarial 
     **/
    cambioCentroEmpresarial = (event) => {
        let centro = this.state.ccLista.filter(
            (centro) => centro.id == event.target.value
        );
        this.setState({ centroEmpresarialSelecionado: centro[0] });
    };
    /**
     * 
     * @param {*} event
     * @returns Cambiar de provincia 
     **/
    cambioProvincias = (event) => {
        let provincia = this.state.provinciasLista.filter(
            (provincia) => provincia.id == event.target.value
        );
        this.setState({ provinciaSeleccionada: provincia[0] });

        let centroEmpresarial = this.state.centrosEmpresarialesLista.filter(
            (cEmpresarial) => cEmpresarial.idProvincia == provincia[0].id
        );
        this.setState({
            ccLista: centroEmpresarial,
            centroEmpresarialSelecionado: centroEmpresarial[0],
        });
    };
    /**
     * 
     * @param {*} event
     * @returns Cambiar de operadora.  
     **/
    cambioOperadora = (event) => {
        $("#spinRol").show();
        let operadora = this.state.clientes.filter(
            (operadora) => operadora.id == event.target.value
        );
        this.setState({ clienteSeleccionado: operadora[0] });

        if (operadora[0].cedulaJuridica == REACT_APP_CEDULA_BP) {
            const provinciasLista = localStorage.getItem("provinciasLista");
            const provinciasListaJson = JSON.parse(provinciasLista);
            if (provinciasListaJson == null) {
                ListarProvincias().then((res) => {
                    if (res) {
                        if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                            swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                            return;
                        }
                        localStorage.setItem(
                            "provinciasLista",
                            JSON.stringify(res.json.provincias)
                        );
                        this.state.provinciasLista = res.json.provincias;
                        this.setState({
                            provinciasLista: res.json.provincias,
                            provinciaSeleccionada: res.json.provincias[0],
                        });
                        $("#mostrarCentroEmpresariales").show();
                        this.cargarCentrosEmpresariales();
                    }
                });
            } else {
                this.state.provinciasLista = provinciasListaJson;
                this.setState({
                    provinciasLista: provinciasListaJson,
                    provinciaSeleccionada: provinciasListaJson[0],
                });
                $("#mostrarCentroEmpresariales").show();
                this.cargarCentrosEmpresariales();
            }
        } else {
            $("#mostrarCentroEmpresariales").hide();
            this.setState({ ccLista: [], centroEmpresarialSelecionado: "" });
        }

        // validar fondo activo
        let fondo = this.state.fondosActivos.filter((fondo) => fondo.id == event.target.value);
        if (fondo.length > 0 && fondo[0].estado.id == REACT_APP_FONDO_ESTADO_ACTIVO) {
            GetGenerico(REACT_APP_OBTENER_ROLES_FODEMIPYME).then(
                (res) => {
                    if (res) {
                        if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                            swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                            return;
                        }
                        $("#spinRol").hide();
                        this.state.roles = res.json.roles;
                        this.state.rolSeleccionado = res.json.roles[0];
                        if (!this.state.indicadorFNA) {
                            this.state.indRolEjecutivoFNA = false;
                            this.state.indicadorFNA = true;
                            let fondo = this.state.fondosActivos.filter((fondo) => fondo.id == REACT_APP_OPERADORA_FNA);
                            this.state.fondosAgregados.push(fondo[0].id);
                        }

                        if (!this.state.indicadorFODEMIPYME) {
                            this.state.indRolEjecutivoFODEMIPYME = false;
                            this.state.indicadorFODEMIPYME = true;
                            let fondo = this.state.fondosActivos.filter((fondo) => fondo.id == REACT_APP_OPERADORA_FODEMIPYME);
                            this.state.fondosAgregados.push(fondo[0].id);
                        }

                        this.setState({
                            roles: res.json.roles,
                            rolSeleccionado: res.json.roles[0],
                        });
                    }
                }
            );
        } else {
            GetGenerico(REACT_APP_OBTENER_ROLES_OPERADORA).then(
                (res) => {
                    if (res) {
                        if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                            swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                            return;
                        }
                        $("#spinRol").hide();
                        if (!this.state.indicadorFNA) {
                            this.state.indRolEjecutivoFNA = false;
                            this.state.indicadorFNA = true;
                            let fondo = this.state.fondosActivos.filter((fondo) => fondo.id == REACT_APP_OPERADORA_FNA);
                            this.state.fondosAgregados.push(fondo[0].id);
                        }
                        if (!this.state.indicadorFODEMIPYME) {
                            this.state.indRolEjecutivoFODEMIPYME = false;
                            this.state.indicadorFODEMIPYME = true;
                            let fondo = this.state.fondosActivos.filter((fondo) => fondo.id == REACT_APP_OPERADORA_FODEMIPYME);
                            this.state.fondosAgregados.push(fondo[0].id);
                        }
                        this.state.roles = res.json.roles;
                        this.state.rolSeleccionado = res.json.roles[0];
                        this.setState({
                            roles: res.json.roles,
                            rolSeleccionado: res.json.roles[0],
                        });
                    }
                }
            );
        }
    };
    /**
     * 
     * @param {*} event
     * @returns Cambiar de rol 
     **/
    cambioRole = (event) => {
        var rolAnterior = this.state.rolSeleccionado.id;
        let rol = this.state.roles.filter((rol) => rol.id == event.target.value);
        this.setState({ rolSeleccionado: rol[0] });
        if (rol[0].id == REACT_APP_ROL_EJECUTIVO) {
            if (idFondo == REACT_APP_OPERADORA_FODEMIPYME) {
                this.setState({ indRolEjecutivoFNA: true });
                if (!this.state.indicadorFODEMIPYME) {
                    let fondo = this.state.fondosActivos.filter((fondo) => fondo.id == REACT_APP_OPERADORA_FODEMIPYME);
                    this.state.fondosAgregados.push(fondo[0].id);
                    this.state.indicadorFODEMIPYME = true;
                    this.setState({ indicadorFODEMIPYME: true });
                }
                if (this.state.indicadorFNA) {
                    let fondo = this.state.fondosActivos.filter((fondo) => fondo.id == REACT_APP_OPERADORA_FNA);
                    var arregloIdFondos = [];
                    arregloIdFondos = this.state.fondosAgregados;
                    let index = arregloIdFondos.indexOf(fondo[0].id);
                    arregloIdFondos.splice(index, 1);
                    this.state.fondosAgregados = arregloIdFondos;
                    this.setState({ fondosAgregados: arregloIdFondos });
                    this.state.indicadorFNA = false;
                    this.setState({ indicadorFNA: false });
                }
            } else {
                this.setState({ indRolEjecutivoFODEMIPYME: true });
            }
            if (idFondo == REACT_APP_OPERADORA_FNA) {
                this.setState({ indRolEjecutivoFODEMIPYME: true });

                if (!this.state.indicadorFNA) {
                    let fondo = this.state.fondosActivos.filter((fondo) => fondo.id == REACT_APP_OPERADORA_FNA);
                    this.state.fondosAgregados.push(fondo[0].id);
                    this.state.indicadorFNA = true;
                    this.setState({ indicadorFNA: true });
                }

                if (this.state.indicadorFODEMIPYME) {
                    let fondo = this.state.fondosActivos.filter((fondo) => fondo.id == REACT_APP_OPERADORA_FODEMIPYME);
                    var arregloIdFondos = [];
                    arregloIdFondos = this.state.fondosAgregados;
                    let index = arregloIdFondos.indexOf(fondo[0].id);
                    arregloIdFondos.splice(index, 1);
                    this.state.fondosAgregados = arregloIdFondos;
                    this.setState({ fondosAgregados: arregloIdFondos });
                    this.state.indicadorFODEMIPYME = false;
                    this.setState({ indicadorFODEMIPYME: false });
                }

            } else {
                this.setState({ indRolEjecutivoFNA: true });
            }
        } else {
            if (idFondo == REACT_APP_OPERADORA_FODEMIPYME && rolAnterior == REACT_APP_ROL_EJECUTIVO && !this.state.indicadorFNA) {
                let fondo = this.state.fondosActivos.filter((fondo) => fondo.id == REACT_APP_OPERADORA_FNA);
                this.state.fondosAgregados.push(fondo[0].id);
                this.state.indicadorFNA = true;
                this.setState({ indicadorFNA: true });
                this.setState({ indRolEjecutivoFNA: false });
            }
            if (idFondo == REACT_APP_OPERADORA_FNA && rolAnterior == REACT_APP_ROL_EJECUTIVO && !this.state.indicadorFODEMIPYME) {
                let fondo = this.state.fondosActivos.filter((fondo) => fondo.id == REACT_APP_OPERADORA_FODEMIPYME);
                this.state.fondosAgregados.push(fondo[0].id);
                this.state.indicadorFODEMIPYME = true;
                this.setState({ indicadorFODEMIPYME: true });
                this.setState({ indRolEjecutivoFODEMIPYME: false });
            }
        }
    };
    /**
     * 
     * @param {*} event
     * @returns Cambiar el tipo de identificacion 
     **/
    cambioTipoIdentificacion = (event) => {
        let tipoIdentificacion = this.state.tipoIdentificacionLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ tipoIdentificacionSeleccionado: tipoIdentificacion[0] });
        this.setState({ nombrePersona: "" });
        this.setState({ apellidoPersona: "" });
        this.setState({ cedulaPersona: "" });
    };
    /**
     * 
     * @param {*} event 
     * @returns Usuario nuevo
     **/
    crearUsuario = (event) => {
        event.preventDefault();
        $("#guardando").show();
        let idUsuarioCrea = localStorage.getItem("idUsuarioCreador");
        const letras = new RegExp(/^[a-zA-ZáéíñóúüÁÉÍÑÓÚÜ ]*$/);
        const correo = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const correoValido = correo.test(this.state.correoElectronico);
        const nombreValido = letras.test(this.state.nombrePersona);
        const apellidoValido = letras.test(this.state.apellidoPersona);

        if (!this.state.indicadorFODEMIPYME && !this.state.indicadorFNA) {
            swal("Crear Usuario", "Al menos un fondo es requerido", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }

        if (this.state.nombrePersona == "") {
            swal("Crear Usuario", "El nombre es requerido", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }
        if (this.state.apellidoPersona == "") {
            swal("Crear Usuario", "El apellido es requerido", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }
        if (this.state.correoElectronico == "") {
            swal("Crear Usuario", "El correo electrónico es requerido", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }
        if (!correoValido) {
            swal("Crear Usuario", "El correo electrónico no es válido", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }
        if (!nombreValido) {
            swal("Crear Usuario", "El nombre debe contener solo letras", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }
        if (!apellidoValido) {
            swal("Crear Usuario", "El apellido debe contener solo letras", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }
        if (this.state.cedulaPersona == "") {
            swal("Crear Usuario", "La Cédula es requerida", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }

        if (
            this.state.tipoIdentificacionSeleccionado.id == REACT_APP_IDENTIFICACION_USUARIO_FISICA_NACIONAL &&
            (this.state.cedulaPersona.toString().length != 9)
        ) {
            swal(
                "Crear Cliente",
                "La Cédula Física debe contener 9 dígitos",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (
            this.state.tipoIdentificacionSeleccionado.id == REACT_APP_IDENTIFICACION_USUARIO_DIMEX &&
            (this.state.cedulaPersona.toString().length != 12)
        ) {
            swal(
                "Crear Cliente",
                "La Cédula DIMEX debe contener 12 dígitos",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }

        if (this.state.clienteSeleccionado == "") {
            swal("Crear Usuario", "La Entidad Operadora es requerida", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }
        if (this.state.rolSeleccionado == "") {
            swal("Crear Usuario", "La rol es requerido", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }
        if (this.state.rolSeleccionado.id == REACT_APP_ROL_EJECUTIVO && this.state.fondosAgregados.length > 1) {
            swal("Crear Usuario", "El rol ejecutivo debe de pertenecer únicamente a un fondo", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }
        if (this.state.clienteSeleccionado.cedulaJuridica == REACT_APP_CEDULA_BP) {
            if (this.state.centroEmpresarialSelecionado == "") {
                swal("Crear Usuario", "El Centro Empresarial es requerido", "error", { button: "Aceptar", });
                $("#guardando").hide();
                return;
            }
        }
        if (this.state.rolSeleccionado.id == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME && this.state.direccion == "") {
            swal(
                "Crear Usuario",
                "La dirección es requerida cuando el rol seleccionado es: Representante Fodemipyme",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.rolSeleccionado.id == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME && this.state.estadoCivil == "") {
            swal(
                "Crear Usuario",
                "Estado civil es requerido cuando el rol seleccionado es: Representante Fodemipyme",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.rolSeleccionado.id == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME && this.state.gradoAcademico == "") {
            swal(
                "Crear Usuario",
                "El grado académico es requerido cuando el rol seleccionado es: Representante Fodemipyme",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.rolSeleccionado.id == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME && this.state.profesion == "") {
            swal(
                "Crear Usuario",
                "La profesión es requerida cuando el rol seleccionado es: Representante Fodemipyme",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        var idClienteSeleccionado = 0;
        if (this.state.clienteSeleccionado.id == 1 && this.state.fondosAgregados.length == 1 && this.state.fondosAgregados[0] == 1) {
            idClienteSeleccionado = this.state.clienteSeleccionado.id;
        } else if (this.state.clienteSeleccionado.id == 2 && this.state.fondosAgregados.length == 1 && this.state.fondosAgregados[0] == 2) {
            idClienteSeleccionado = this.state.clienteSeleccionado.id;
        } else if ((this.state.clienteSeleccionado.id == 1 || this.state.clienteSeleccionado.id == 2) && this.state.fondosAgregados.length == 2) {
            idClienteSeleccionado = null;
        } else if (this.state.clienteSeleccionado.id == 1 && this.state.fondosAgregados.length == 1 && this.state.fondosAgregados[0] == 2) {
            idClienteSeleccionado = REACT_APP_OPERADORA_FNA;
        } else if (this.state.clienteSeleccionado.id == 2 && this.state.fondosAgregados.length == 1 && this.state.fondosAgregados[0] == 1) {
            idClienteSeleccionado = REACT_APP_OPERADORA_FODEMIPYME;
        } else if (this.state.clienteSeleccionado.id > 2) {
            idClienteSeleccionado = this.state.clienteSeleccionado.id;
        }
        let data = {
            NombrePersona: this.state.nombrePersona,
            ApellidoPersona: this.state.apellidoPersona,
            CedulaPersona: this.state.cedulaPersona,
            CorreoElectronico: this.state.correoElectronico,
            IdEntidadFinancieraAutorizada: idClienteSeleccionado,
            IdRol: this.state.rolSeleccionado.id,
            IdUsuarioCreador: idUsuarioCrea,
            IdTipoIdentificacionUsuario: this.state.tipoIdentificacionSeleccionado.id,
            IdCentroEmpresarial:
                this.state.clienteSeleccionado.cedulaJuridica == REACT_APP_CEDULA_BP
                    ? this.state.centroEmpresarialSelecionado.id
                    : null,
            EstadoCivil:
                this.state.rolSeleccionado.id == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME ? this.state.estadoCivil : null,
            GradoAcademico:
                this.state.rolSeleccionado.id == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME ? this.state.gradoAcademico : null,
            Profesion:
                this.state.rolSeleccionado.id == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME ? this.state.profesion : null,
            Direccion:
                this.state.rolSeleccionado.id == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME ? this.state.direccion : null,
            IdFondos: this.state.fondosAgregados
        };
        this.setState({ indButton: true });
        CrearUsuario(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    try {
                        var textoError = res.codigo == 500 ? `Si se ha presentado un error inesperado, por favor enviar el siguiente código de error al administrador de FODEMIPYME : ${res.json.identificacionSolicitud}` : res.json.mensaje + ". Código de error: " + res.json.identificacionSolicitud;
                        if (res.json && res.json.mensaje) {
                            swal(fondoSeleccionado.nombreFondo, textoError, "error", { button: "Aceptar", });
                        } else {
                            swal(fondoSeleccionado.nombreFondo, "Ha ocurrido un error al crear el usuario, no se obtuvo respuesta del servidor." + " Código de error: " + res.json.identificacionSolicitud, "error", { button: "Aceptar", });
                        }
                        $("#guardando").hide();
                        this.setState({ indButton: false });
                        return;
                    } catch (e) {
                        swal(fondoSeleccionado.nombreFondo, "Ha ocurrido un error al crear el usuario" + " Código de error: " + res.json.identificacionSolicitud, "error", { button: "Aceptar", });
                        $("#guardando").hide();
                        this.setState({ indButton: false });
                        throw e;
                    }
                    return;
                }
                this.setState({ indButton: false });
                $("#guardando").hide();
                swal(fondoSeleccionado.nombreFondo, "¡Usuario creado con éxito!", "success", { button: "Aceptar", }).then(
                    () => {
                        window.location.href = "/usuarios";
                    }
                );
            }
        });
    };
    render() {
        const idRol = localStorage.getItem("idRol");
        if (idRol) {
            if (idRol != REACT_APP_ROL_ADMINISTRATIVO &&
                idRol != REACT_APP_ROL_CONTABILIDAD &&
                idRol != REACT_APP_ROL_SEGUIMIENTO) window.location.href = "/admin";
        } else {
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        }
        switch (idRol) {
            //REACT_APP_ROL_ADMINISTRATIVO
            case "1":
                return (
                    <div className="container text-center padding-top">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/mantenimiento">Mantenimientos</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/usuarios">Usuarios</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Crear Usuario
                                </li>
                            </ol>
                        </nav>
                        <div className="row">
                            <form className="form-crear">
                                <h1 className="h3 mb-3 font-weight-normal">Crear Usuario</h1>
                                <div className="row form-padding">
                                    <div className="col-md-6">
                                        <label>Entidad Operadora</label>
                                        <select
                                            className="form-control"
                                            disabled={this.state.indButton}
                                            onChange={this.cambioOperadora}
                                        >
                                            {this.state.clientes.map(function (data, key) {
                                                return (
                                                    <option key={key} value={data.id}>
                                                        {data.nombreOperadora}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <span className="input-group-addon" id="spinEFA">
                                            <i className="fa fa-refresh fa-spin"></i>
                                        </span>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Rol</label>
                                        <select className="form-control" onChange={this.cambioRole} disabled={this.state.indButton} value={this.state.rolSeleccionado.id}>
                                            {this.state.roles.map(function (data, key) {
                                                return (
                                                    <option key={key} value={data.id}>
                                                        {data.nombreRol}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <span className="input-group-addon" id="spinRol">
                                            <i className="fa fa-refresh fa-spin"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="row form-padding" id="mostrarCentroEmpresariales">
                                    <div className="col-md-6">
                                        <label>Provincias</label>
                                        <select
                                            className="form-control"
                                            onChange={this.cambioProvincias}
                                            value={this.state.provinciaSeleccionada.id}
                                            disabled={this.state.indButton}
                                        >
                                            {this.state.provinciasLista.map(function (data, key) {
                                                return (
                                                    <option key={key} value={data.id}>
                                                        {data.nombre}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Oficinas comerciales</label>
                                        <select
                                            className="form-control"
                                            onChange={this.cambioCentroEmpresarial}
                                            disabled={this.state.indButton}
                                        >
                                            {this.state.ccLista.map(function (data, key) {
                                                return (
                                                    <option key={key} value={data.id}>
                                                        {data.centroEmpresarial}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="row form-padding">
                                    <div className="col-md-6">
                                        <label>Tipo de Identificación</label>
                                        <select
                                            className="form-control"
                                            onChange={this.cambioTipoIdentificacion}
                                            disabled={this.state.indButton}
                                        >
                                            {this.state.tipoIdentificacionLista.map(function (
                                                data,
                                                key
                                            ) {
                                                return (
                                                    <option key={key} value={data.id}>
                                                        {data.valor}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Cédula Identificación</label>
                                        <input
                                            type="number"
                                            name="cedulaPersona"
                                            className="form-control"
                                            min="0"
                                            minLength="9"
                                            maxLength="9"
                                            value={this.state.cedulaPersona}
                                            onChange={this.encargarseCambio}
                                            required
                                            placeholder="Cédula Identificación"
                                            disabled={this.state.indButton}
                                            title="(Persona Física) debe contener un máximo 9 de caracteres. (Persona DIMEX) debe contener un máximo 12 de caracteres"
                                        />
                                    </div>
                                </div>
                                <div className="row form-padding">
                                    <div className="col-md-6">
                                        <label>Nombre </label>
                                        <input
                                            type="text"
                                            name="nombrePersona"
                                            className="form-control"
                                            value={this.state.nombrePersona}
                                            onChange={this.encargarseCambio}
                                            required
                                            placeholder="Nombre"
                                            title="El nombre debe contener solo letras"
                                            disabled={this.state.tipoIdentificacionSeleccionado.id == REACT_APP_IDENTIFICACION_USUARIO_FISICA_NACIONAL}
                                        />
                                        <span className="input-group-addon" id="spinNombre">
                                            <i className="fa fa-refresh fa-spin"></i>
                                        </span>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Apellidos </label>
                                        <input
                                            type="text"
                                            name="apellidoPersona"
                                            className="form-control"
                                            value={this.state.apellidoPersona}
                                            onChange={this.encargarseCambio}
                                            minLength={3}
                                            maxLength={100}
                                            required
                                            placeholder="Apellidos"
                                            disabled={this.state.tipoIdentificacionSeleccionado.id == REACT_APP_IDENTIFICACION_USUARIO_FISICA_NACIONAL}
                                            title="Los apellidos deben contener solo letras"
                                        />
                                        <span className="input-group-addon" id="spinApellido">
                                            <i className="fa fa-refresh fa-spin"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="row form-padding">
                                    <div className="col-md-6">
                                        <label>Correo Electrónico</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="correoElectronico"
                                            minLength="5"
                                            maxLength="100"
                                            value={this.state.correoElectronico}
                                            onChange={this.encargarseCambio}
                                            required
                                            placeholder="jhon@midominio.com"
                                            title="El correo debe tener un formato correcto. Ejemplo: jhon@midominio.com"
                                            disabled={this.state.indButton}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label>Fondos</label>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="indicadorFODEMIPYME"
                                                id="indicadorFODEMIPYME"
                                                onChange={this.encargarseCambio}
                                                value={this.state.indicadorFODEMIPYME}
                                                checked={this.state.indicadorFODEMIPYME}
                                                disabled={this.state.indRolEjecutivoFODEMIPYME || this.state.indButton}
                                            />
                                            <label className="form-check-label">
                                                FODEMIPYME
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="indicadorFNA"
                                                id="indicadorFNA"
                                                onChange={this.encargarseCambio}
                                                value={this.state.indicadorFNA}
                                                checked={this.state.indicadorFNA}
                                                disabled={this.state.indRolEjecutivoFNA || this.state.indButton}
                                            />
                                            <label className="form-check-label">
                                                FNA
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="row form-padding"
                                    hidden={this.state.rolSeleccionado.id != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME}
                                >
                                    <div className="col-md-3"></div>
                                    <div className="col-md-6">
                                        <label style={{ color: "blue" }}>
                                            Información Representante Legal
                                        </label>
                                    </div>
                                    <div className="col-md-3"></div>
                                </div>
                                <div
                                    className="row form-padding"
                                    hidden={this.state.rolSeleccionado.id != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME}
                                >
                                    <div className="col">
                                        <label>Dirección</label>
                                        <textarea
                                            className="form-control"
                                            id="exampleFormControlTextarea1"
                                            minLength="5"
                                            maxLength="100"
                                            name="direccion"
                                            value={this.state.direccion}
                                            onChange={this.encargarseCambio}
                                            required
                                            rows="3"
                                            placeholder="Dirección"
                                            title="La Dirección debe contener mínimo 5 y máximo 100 caracteres"
                                            disabled={this.state.indButton}
                                        ></textarea>
                                    </div>
                                </div>
                                <div
                                    className="row form-padding"
                                    hidden={this.state.rolSeleccionado.id != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME}
                                >
                                    <div className="col-md-6">
                                        <label>Grado Académico</label>
                                        <input
                                            type="text"
                                            name="gradoAcademico"
                                            className="form-control"
                                            value={this.state.gradoAcademico}
                                            onChange={this.encargarseCambio}
                                            minLength="5"
                                            maxLength="50"
                                            required
                                            title="El grado académico debe contener mínimo 5 y máximo 50 caracteres"
                                            placeholder="Grado Académico"
                                            disabled={this.state.indButton}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label>Profesión</label>
                                        <input
                                            type="text"
                                            name="profesion"
                                            className="form-control"
                                            value={this.state.profesion}
                                            onChange={this.encargarseCambio}
                                            minLength="5"
                                            maxLength="50"
                                            required
                                            title="La profesión debe contener mínimo 5 y máximo 50 caracteres"
                                            placeholder="Profesión"
                                            disabled={this.state.indButton}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="row form-padding"
                                    hidden={this.state.rolSeleccionado.id != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME}
                                >
                                    <div className="col-md-3"></div>
                                    <div className="col-md-6">
                                        <label>Estado civil</label>
                                        <input
                                            type="text"
                                            name="estadoCivil"
                                            className="form-control"
                                            value={this.state.estadoCivil}
                                            onChange={this.encargarseCambio}
                                            minLength="5"
                                            maxLength="50"
                                            required
                                            title="El civil debe contener mínimo 5 y máximo 50 caracteres"
                                            placeholder="Estado civil"
                                            disabled={this.state.indButton}
                                        />
                                    </div>
                                    <div className="col-md-3"></div>
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-primary border-radius"
                                    onClick={this.crearUsuario}
                                    disabled={this.state.indButton}
                                >
                                    <span
                                        id="guardando"
                                        className="spinner-grow spinner-grow-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    <span id="guardando" className="sr-only">
                                        Loading...
                                    </span>
                                    Guardar
                                    {" "}
                                    <i className="fa fa-edit" aria-hidden="true"></i>
                                </button>
                            </form>
                        </div>
                        <table id="tabla1"></table>
                    </div>
                );
            //REACT_APP_ROL_CONTABILIDAD
            case "6":
                return (
                    <div className="container text-center padding-top">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/mantenimiento">Mantenimientos</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/usuarios">Usuarios</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Crear Usuario
                                </li>
                            </ol>
                        </nav>
                        <div className="row">
                            <form className="form-crear">
                                <h1 className="h3 mb-3 font-weight-normal">Crear Usuario</h1>
                                <div className="row form-padding">
                                    <div className="col-md-6">
                                        <label>Entidad Operadora</label>
                                        <select
                                            className="form-control"
                                            disabled={this.state.indButton}
                                            onChange={this.cambioOperadora}
                                        >
                                            {this.state.clientes.map(function (data, key) {
                                                return (
                                                    <option key={key} value={data.id}>
                                                        {data.nombreOperadora}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <span className="input-group-addon" id="spinEFA">
                                            <i className="fa fa-refresh fa-spin"></i>
                                        </span>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Rol</label>
                                        <select className="form-control" onChange={this.cambioRole} disabled={this.state.indButton} value={this.state.rolSeleccionado.id}>
                                            {this.state.roles.map(function (data, key) {
                                                return (
                                                    <option key={key} value={data.id}>
                                                        {data.nombreRol}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <span className="input-group-addon" id="spinRol">
                                            <i className="fa fa-refresh fa-spin"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="row form-padding" id="mostrarCentroEmpresariales">
                                    <div className="col-md-6">
                                        <label>Provincias</label>
                                        <select
                                            className="form-control"
                                            onChange={this.cambioProvincias}
                                            value={this.state.provinciaSeleccionada.id}
                                            disabled={this.state.indButton}
                                        >
                                            {this.state.provinciasLista.map(function (data, key) {
                                                return (
                                                    <option key={key} value={data.id}>
                                                        {data.nombre}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Oficinas comerciales</label>
                                        <select
                                            className="form-control"
                                            onChange={this.cambioCentroEmpresarial}
                                            disabled={this.state.indButton}
                                        >
                                            {this.state.ccLista.map(function (data, key) {
                                                return (
                                                    <option key={key} value={data.id}>
                                                        {data.centroEmpresarial}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="row form-padding">
                                    <div className="col-md-6">
                                        <label>Tipo de Identificación</label>
                                        <select
                                            className="form-control"
                                            onChange={this.cambioTipoIdentificacion}
                                            disabled={this.state.indButton}
                                        >
                                            {this.state.tipoIdentificacionLista.map(function (
                                                data,
                                                key
                                            ) {
                                                return (
                                                    <option key={key} value={data.id}>
                                                        {data.valor}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Cédula Identificación</label>
                                        <input
                                            type="number"
                                            name="cedulaPersona"
                                            className="form-control"
                                            min="0"
                                            minLength="9"
                                            maxLength="9"
                                            value={this.state.cedulaPersona}
                                            onChange={this.encargarseCambio}
                                            required
                                            placeholder="Cédula Identificación"
                                            disabled={this.state.indButton}
                                            title="(Persona Física) debe contener un máximo 9 de caracteres. (Persona DIMEX) debe contener un máximo 12 de caracteres"
                                        />
                                    </div>
                                </div>
                                <div className="row form-padding">
                                    <div className="col-md-6">
                                        <label>Nombre </label>
                                        <input
                                            type="text"
                                            name="nombrePersona"
                                            className="form-control"
                                            value={this.state.nombrePersona}
                                            onChange={this.encargarseCambio}
                                            required
                                            placeholder="Nombre"
                                            title="El nombre debe contener solo letras"
                                            disabled={this.state.tipoIdentificacionSeleccionado.id == REACT_APP_IDENTIFICACION_USUARIO_FISICA_NACIONAL}
                                        />
                                        <span className="input-group-addon" id="spinNombre">
                                            <i className="fa fa-refresh fa-spin"></i>
                                        </span>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Apellidos </label>
                                        <input
                                            type="text"
                                            name="apellidoPersona"
                                            className="form-control"
                                            value={this.state.apellidoPersona}
                                            onChange={this.encargarseCambio}
                                            minLength={3}
                                            maxLength={100}
                                            required
                                            placeholder="Apellidos"
                                            disabled={this.state.tipoIdentificacionSeleccionado.id == REACT_APP_IDENTIFICACION_USUARIO_FISICA_NACIONAL}
                                            title="Los apellidos deben contener solo letras"
                                        />
                                        <span className="input-group-addon" id="spinApellido">
                                            <i className="fa fa-refresh fa-spin"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="row form-padding">
                                    <div className="col-md-6">
                                        <label>Correo Electrónico</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="correoElectronico"
                                            minLength="5"
                                            maxLength="100"
                                            value={this.state.correoElectronico}
                                            onChange={this.encargarseCambio}
                                            required
                                            placeholder="jhon@midominio.com"
                                            title="El correo debe tener un formato correcto. Ejemplo: jhon@midominio.com"
                                            disabled={this.state.indButton}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label>Fondos</label>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="indicadorFODEMIPYME"
                                                id="indicadorFODEMIPYME"
                                                onChange={this.encargarseCambio}
                                                value={this.state.indicadorFODEMIPYME}
                                                checked={this.state.indicadorFODEMIPYME}
                                                disabled={this.state.indRolEjecutivoFODEMIPYME || this.state.indButton}
                                            />
                                            <label className="form-check-label">
                                                FODEMIPYME
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="indicadorFNA"
                                                id="indicadorFNA"
                                                onChange={this.encargarseCambio}
                                                value={this.state.indicadorFNA}
                                                checked={this.state.indicadorFNA}
                                                disabled={this.state.indRolEjecutivoFNA || this.state.indButton}
                                            />
                                            <label className="form-check-label">
                                                FNA
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="row form-padding"
                                    hidden={this.state.rolSeleccionado.id != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME}
                                >
                                    <div className="col-md-3"></div>
                                    <div className="col-md-6">
                                        <label style={{ color: "blue" }}>
                                            Información Representante Legal
                                        </label>
                                    </div>
                                    <div className="col-md-3"></div>
                                </div>
                                <div
                                    className="row form-padding"
                                    hidden={this.state.rolSeleccionado.id != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME}
                                >
                                    <div className="col">
                                        <label>Dirección</label>
                                        <textarea
                                            className="form-control"
                                            id="exampleFormControlTextarea1"
                                            minLength="5"
                                            maxLength="100"
                                            name="direccion"
                                            value={this.state.direccion}
                                            onChange={this.encargarseCambio}
                                            required
                                            rows="3"
                                            placeholder="Dirección"
                                            title="La Dirección debe contener mínimo 5 y máximo 100 caracteres"
                                            disabled={this.state.indButton}
                                        ></textarea>
                                    </div>
                                </div>
                                <div
                                    className="row form-padding"
                                    hidden={this.state.rolSeleccionado.id != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME}
                                >
                                    <div className="col-md-6">
                                        <label>Grado Académico</label>
                                        <input
                                            type="text"
                                            name="gradoAcademico"
                                            className="form-control"
                                            value={this.state.gradoAcademico}
                                            onChange={this.encargarseCambio}
                                            minLength="5"
                                            maxLength="50"
                                            required
                                            title="El grado académico debe contener mínimo 5 y máximo 50 caracteres"
                                            placeholder="Grado Académico"
                                            disabled={this.state.indButton}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label>Profesión</label>
                                        <input
                                            type="text"
                                            name="profesion"
                                            className="form-control"
                                            value={this.state.profesion}
                                            onChange={this.encargarseCambio}
                                            minLength="5"
                                            maxLength="50"
                                            required
                                            title="La profesión debe contener mínimo 5 y máximo 50 caracteres"
                                            placeholder="Profesión"
                                            disabled={this.state.indButton}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="row form-padding"
                                    hidden={this.state.rolSeleccionado.id != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME}
                                >
                                    <div className="col-md-3"></div>
                                    <div className="col-md-6">
                                        <label>Estado civil</label>
                                        <input
                                            type="text"
                                            name="estadoCivil"
                                            className="form-control"
                                            value={this.state.estadoCivil}
                                            onChange={this.encargarseCambio}
                                            minLength="5"
                                            maxLength="50"
                                            required
                                            title="El civil debe contener mínimo 5 y máximo 50 caracteres"
                                            placeholder="Estado civil"
                                            disabled={this.state.indButton}
                                        />
                                    </div>
                                    <div className="col-md-3"></div>
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-primary border-radius"
                                    onClick={this.crearUsuario}
                                    disabled={this.state.indButton}
                                >
                                    <span
                                        id="guardando"
                                        className="spinner-grow spinner-grow-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    <span id="guardando" className="sr-only">
                                        Loading...
                                    </span>
                                    Guardar
                                    {" "}
                                    <i className="fa fa-edit" aria-hidden="true"></i>
                                </button>
                            </form>
                        </div>
                        <table id="tabla1"></table>
                    </div>
                );
            //REACT_APP_ROL_SEGUIMIENTO
            case "7":
                return (
                    <div className="container text-center padding-top">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/mantenimiento">Mantenimientos</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/usuarios">Usuarios</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Crear Usuario
                                </li>
                            </ol>
                        </nav>
                        <div className="row">
                            <form className="form-crear">
                                <h1 className="h3 mb-3 font-weight-normal">Crear Usuario</h1>
                                <div className="row form-padding">
                                    <div className="col-md-6">
                                        <label>Entidad Operadora</label>
                                        <select
                                            className="form-control"
                                            disabled={this.state.indButton}
                                            onChange={this.cambioOperadora}
                                        >
                                            {this.state.clientes.map(function (data, key) {
                                                return (
                                                    <option key={key} value={data.id}>
                                                        {data.nombreOperadora}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <span className="input-group-addon" id="spinEFA">
                                            <i className="fa fa-refresh fa-spin"></i>
                                        </span>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Rol</label>
                                        <select className="form-control" onChange={this.cambioRole} disabled={this.state.indButton} value={this.state.rolSeleccionado.id}>
                                            {this.state.roles.map(function (data, key) {
                                                return (
                                                    <option key={key} value={data.id}>
                                                        {data.nombreRol}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <span className="input-group-addon" id="spinRol">
                                            <i className="fa fa-refresh fa-spin"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="row form-padding" id="mostrarCentroEmpresariales">
                                    <div className="col-md-6">
                                        <label>Provincias</label>
                                        <select
                                            className="form-control"
                                            onChange={this.cambioProvincias}
                                            value={this.state.provinciaSeleccionada.id}
                                            disabled={this.state.indButton}
                                        >
                                            {this.state.provinciasLista.map(function (data, key) {
                                                return (
                                                    <option key={key} value={data.id}>
                                                        {data.nombre}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Oficinas comerciales</label>
                                        <select
                                            className="form-control"
                                            onChange={this.cambioCentroEmpresarial}
                                            disabled={this.state.indButton}
                                        >
                                            {this.state.ccLista.map(function (data, key) {
                                                return (
                                                    <option key={key} value={data.id}>
                                                        {data.centroEmpresarial}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="row form-padding">
                                    <div className="col-md-6">
                                        <label>Tipo de Identificación</label>
                                        <select
                                            className="form-control"
                                            onChange={this.cambioTipoIdentificacion}
                                            disabled={this.state.indButton}
                                        >
                                            {this.state.tipoIdentificacionLista.map(function (
                                                data,
                                                key
                                            ) {
                                                return (
                                                    <option key={key} value={data.id}>
                                                        {data.valor}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Cédula Identificación</label>
                                        <input
                                            type="number"
                                            name="cedulaPersona"
                                            className="form-control"
                                            min="0"
                                            minLength="9"
                                            maxLength="9"
                                            value={this.state.cedulaPersona}
                                            onChange={this.encargarseCambio}
                                            required
                                            placeholder="Cédula Identificación"
                                            disabled={this.state.indButton}
                                            title="(Persona Física) debe contener un máximo 9 de caracteres. (Persona DIMEX) debe contener un máximo 12 de caracteres"
                                        />
                                    </div>
                                </div>
                                <div className="row form-padding">
                                    <div className="col-md-6">
                                        <label>Nombre </label>
                                        <input
                                            type="text"
                                            name="nombrePersona"
                                            className="form-control"
                                            value={this.state.nombrePersona}
                                            onChange={this.encargarseCambio}
                                            required
                                            placeholder="Nombre"
                                            title="El nombre debe contener solo letras"
                                            disabled={this.state.tipoIdentificacionSeleccionado.id == REACT_APP_IDENTIFICACION_USUARIO_FISICA_NACIONAL}
                                        />
                                        <span className="input-group-addon" id="spinNombre">
                                            <i className="fa fa-refresh fa-spin"></i>
                                        </span>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Apellidos </label>
                                        <input
                                            type="text"
                                            name="apellidoPersona"
                                            className="form-control"
                                            value={this.state.apellidoPersona}
                                            onChange={this.encargarseCambio}
                                            minLength={3}
                                            maxLength={100}
                                            required
                                            placeholder="Apellidos"
                                            disabled={this.state.tipoIdentificacionSeleccionado.id == REACT_APP_IDENTIFICACION_USUARIO_FISICA_NACIONAL}
                                            title="Los apellidos deben contener solo letras"
                                        />
                                        <span className="input-group-addon" id="spinApellido">
                                            <i className="fa fa-refresh fa-spin"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="row form-padding">
                                    <div className="col-md-6">
                                        <label>Correo Electrónico</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="correoElectronico"
                                            minLength="5"
                                            maxLength="100"
                                            value={this.state.correoElectronico}
                                            onChange={this.encargarseCambio}
                                            required
                                            placeholder="jhon@midominio.com"
                                            title="El correo debe tener un formato correcto. Ejemplo: jhon@midominio.com"
                                            disabled={this.state.indButton}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label>Fondos</label>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="indicadorFODEMIPYME"
                                                id="indicadorFODEMIPYME"
                                                onChange={this.encargarseCambio}
                                                value={this.state.indicadorFODEMIPYME}
                                                checked={this.state.indicadorFODEMIPYME}
                                                disabled={this.state.indRolEjecutivoFODEMIPYME || this.state.indButton}
                                            />
                                            <label className="form-check-label">
                                                FODEMIPYME
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="indicadorFNA"
                                                id="indicadorFNA"
                                                onChange={this.encargarseCambio}
                                                value={this.state.indicadorFNA}
                                                checked={this.state.indicadorFNA}
                                                disabled={this.state.indRolEjecutivoFNA || this.state.indButton}
                                            />
                                            <label className="form-check-label">
                                                FNA
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="row form-padding"
                                    hidden={this.state.rolSeleccionado.id != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME}
                                >
                                    <div className="col-md-3"></div>
                                    <div className="col-md-6">
                                        <label style={{ color: "blue" }}>
                                            Información Representante Legal
                                        </label>
                                    </div>
                                    <div className="col-md-3"></div>
                                </div>
                                <div
                                    className="row form-padding"
                                    hidden={this.state.rolSeleccionado.id != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME}
                                >
                                    <div className="col">
                                        <label>Dirección</label>
                                        <textarea
                                            className="form-control"
                                            id="exampleFormControlTextarea1"
                                            minLength="5"
                                            maxLength="100"
                                            name="direccion"
                                            value={this.state.direccion}
                                            onChange={this.encargarseCambio}
                                            required
                                            rows="3"
                                            placeholder="Dirección"
                                            title="La Dirección debe contener mínimo 5 y máximo 100 caracteres"
                                            disabled={this.state.indButton}
                                        ></textarea>
                                    </div>
                                </div>
                                <div
                                    className="row form-padding"
                                    hidden={this.state.rolSeleccionado.id != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME}
                                >
                                    <div className="col-md-6">
                                        <label>Grado Académico</label>
                                        <input
                                            type="text"
                                            name="gradoAcademico"
                                            className="form-control"
                                            value={this.state.gradoAcademico}
                                            onChange={this.encargarseCambio}
                                            minLength="5"
                                            maxLength="50"
                                            required
                                            title="El grado académico debe contener mínimo 5 y máximo 50 caracteres"
                                            placeholder="Grado Académico"
                                            disabled={this.state.indButton}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label>Profesión</label>
                                        <input
                                            type="text"
                                            name="profesion"
                                            className="form-control"
                                            value={this.state.profesion}
                                            onChange={this.encargarseCambio}
                                            minLength="5"
                                            maxLength="50"
                                            required
                                            title="La profesión debe contener mínimo 5 y máximo 50 caracteres"
                                            placeholder="Profesión"
                                            disabled={this.state.indButton}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="row form-padding"
                                    hidden={this.state.rolSeleccionado.id != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME}
                                >
                                    <div className="col-md-3"></div>
                                    <div className="col-md-6">
                                        <label>Estado civil</label>
                                        <input
                                            type="text"
                                            name="estadoCivil"
                                            className="form-control"
                                            value={this.state.estadoCivil}
                                            onChange={this.encargarseCambio}
                                            minLength="5"
                                            maxLength="50"
                                            required
                                            title="El civil debe contener mínimo 5 y máximo 50 caracteres"
                                            placeholder="Estado civil"
                                            disabled={this.state.indButton}
                                        />
                                    </div>
                                    <div className="col-md-3"></div>
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-primary border-radius"
                                    onClick={this.crearUsuario}
                                    disabled={this.state.indButton}
                                >
                                    <span
                                        id="guardando"
                                        className="spinner-grow spinner-grow-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    <span id="guardando" className="sr-only">
                                        Loading...
                                    </span>
                                    Guardar
                                    {" "}
                                    <i className="fa fa-edit" aria-hidden="true"></i>
                                </button>
                            </form>
                        </div>
                        <table id="tabla1"></table>
                    </div>
                );
        }
    }
}
export default CrearUsuarioComponent;