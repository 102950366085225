import React from "react";
import swal from "sweetalert";
import {
    REACT_APP_CATALOGO_OBTENER_ESTADO_PROGRAMAS,
    REACT_APP_OBTENER_FRECUENCIAS,
    REACT_APP_CODIGO_RESPUESTA_200,
    REACT_APP_CODIGO_RESPUESTA_401,
    REACT_APP_ESTADO_CLIENTE_ACTIVO,
    REACT_APP_ROL_ADMINISTRATIVO,
    REACT_APP_ROL_INCLUSION,
    REACT_APP_ROL_OPERATIVO,
    REACT_APP_ROL_JEFATURA,
    REACT_APP_ROL_CONTABILIDAD,
    REACT_APP_ROL_SEGUIMIENTO,
    REACT_APP_ESTADO_PROGRAMA_ACTIVO,
    REACT_APP_ESTADO_PROGRAMA_INACTIVO,
    REACT_APP_ESTADO_PROGRAMA_DESESTIMADO,
    REACT_APP_ESTADO_PROGRAMA_RECHAZADO,
    REACT_APP_OPERADORA_FODEMIPYME,
    REACT_APP_PUEDE_CALCULAR_MORA_LEGAL,
    REACT_APP_VIGENCIA_MAX_PROG_CARTERA_PERDIDA_ESPERADA,
    REACT_APP_OBTENER_PORCENTAJE_MORA_LEGAL,
    REACT_APP_LONGITUD_CAMPO_MONTOS
} from "../../environment";
import { ObtenerCatalogo } from "../../Services/GenericoService";
import { MoraLegal } from "../../Services/AvalService";
import { ModificarProgramaCarteraPE } from "../../Services/ProgramaCarteraPEService";
import { ObtenerParametro } from "../../Services/ParametroService";
import moment from "moment";
import { LoginEstilos } from "../../Views/Login/LoginEstilos.css";
import { BorrarLocalStorage } from "../../Utils/BorrarInfoLocalStorage";
import { ObtenerUserAgent } from "../../Utils/BaseSolicitudModel";
import NumberFormat from "react-number-format";

const $ = require("jquery");
$.DataTable = require("datatables.net-bs4");
/**
 * Obtener el ID del Fondo seleccionado en iniciar sesion
 **/
const idFondo = localStorage.getItem("idFondo");
/**
 * Obtener el Fondo seleccionado en iniciar sesion
 **/
const fondoSeleccionado = JSON.parse(localStorage.getItem("fondoSeleccionado"));
/**
 * Valida si es posible obtener la mora legal
 **/
const calcularMoralLegal = localStorage.getItem("calcularMoralLegal");
class EditarProgramaCarteraPEComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            idProgramaPE: 0,
            clientes: [],
            clienteSeleccionado: "",
            frencuencias: [],
            frecuenciaSeleccionado: "",
            idFrecuenciaRevision: 0,
            montoCarteraAvalar: "",
            porcentajeAvalado: 0,
            montoMaximoCredito: "",
            porcentajeMaximoAvalCredito: 0,
            montoProgramaAsignado: "",
            fechaAprobacion: moment().locale("en").format("YYYY-MM-DD"),
            fechaInicio: moment().locale("en").format("YYYY-MM-DD"),
            vigenciaPrograma: 0,
            porcentajePerdidaEsperada: 0,
            montoMaximoHonrar: "",
            porcentajeComisionFormalizacion: 0,
            porcentajeComisionAdministracion: 0,
            estados: [],
            estadoSeleccionado: "",
            indButton: false,
            indFechaInicio: false,
            indFechaAprobacion: false,
            indRechazadoDesestimado: false,
            indEstados: false,
            indMoraLegal: false,
            moraLegal: "",
            indicadorConsumoWSRiesgo: false,
            porcentajePerdidaEsperadaCartera: 0,
            montoPerdidadEsperadaCartera: "",
            nombrePrograma: "",
            vigenciaMaximaFODEMIPYME: 0,
            vigenciaMaximaFNA: 0
        };
    }
    /**
     * Llamado inmediatamente después de montar un componente. 
     * Establecer el estado aquí activará la re-renderización.
     **/
    componentDidMount() {
        $("#guardando").hide();
        $("#spinEFA").show();
        const tokenContent = localStorage.getItem("isLoggedIn");
        const infoEditar = sessionStorage.getItem(
            "infoProgramaCarteraPerdidaEditar"
        );
        if (!tokenContent) window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        if (!infoEditar) window.location.href = "/programas-perdidas";
        const userAgent = ObtenerUserAgent();

        const idRol = localStorage.getItem("idRol");
        if (idRol != REACT_APP_ROL_ADMINISTRATIVO) {
            this.state.indEstados = true;
            this.setState({ indEstados: true });
        }
        const infoEditarJson = JSON.parse(infoEditar);
        if (infoEditarJson != null) {
            var inputWSRiesgo = document.querySelector(
                'input[id="indicadorConsumoWSRiesgo"]'
            );

            this.setState({ id: infoEditarJson.id });
            this.setState({
                idProgramaPE: infoEditarJson.detallePrograma.id,
            });
            inputWSRiesgo.checked =
                infoEditarJson.detallePrograma.indicadorConsumoWSRiesgo;
            this.setState({
                indicadorConsumoWSRiesgo:
                    infoEditarJson.detallePrograma.indicadorConsumoWSRiesgo,
            });

            this.setState({ clienteSeleccionado: infoEditarJson.cliente });
            this.setState({
                montoCarteraAvalar: infoEditarJson.detallePrograma.montoCarteraAvalar,
            });
            this.setState({
                porcentajeAvalado: infoEditarJson.detallePrograma.porcentajeAvalado,
            });
            this.setState({
                montoMaximoCredito: infoEditarJson.detallePrograma.montoMaximoCredito,
            });
            this.setState({
                porcentajeMaximoAvalCredito:
                    infoEditarJson.detallePrograma.porcentajeMaximoAvalCredito,
            });
            this.setState({
                montoProgramaAsignado:
                    infoEditarJson.detallePrograma.montoProgramaAsignado,
            });
            this.setState({
                fechaAprobacion: moment(infoEditarJson.detallePrograma.fechaAprobacion)
                    .locale("en")
                    .format("YYYY-MM-DD"),
            });
            this.setState({
                fechaInicio: moment(infoEditarJson.detallePrograma.fechaInicio)
                    .locale("en")
                    .format("YYYY-MM-DD"),
            });
            this.setState({
                nombrePrograma: infoEditarJson.detallePrograma.nombrePrograma,
            });
            this.setState({
                vigenciaPrograma: infoEditarJson.detallePrograma.vigenciaPrograma,
            });
            this.setState({
                porcentajePerdidaEsperada:
                    infoEditarJson.detallePrograma.porcentajePerdidaEsperada,
            });
            this.setState({
                montoMaximoHonrar: infoEditarJson.detallePrograma.montoMaximoHonrar,
            });
            this.setState({
                porcentajeComisionFormalizacion:
                    infoEditarJson.detallePrograma.porcentajeComisionFormalizacion,
            });
            this.setState({
                porcentajeComisionAdministracion:
                    infoEditarJson.detallePrograma.porcentajeComisionAdministracion,
            });
            this.setState({
                idFrecuenciaRevision:
                    infoEditarJson.detallePrograma.frecuenciaRevision.id,
            });
            this.setState({ estadoSeleccionado: infoEditarJson.estado });
            if (infoEditarJson.estado.id == REACT_APP_ESTADO_PROGRAMA_DESESTIMADO || infoEditarJson.estado.id == REACT_APP_ESTADO_PROGRAMA_RECHAZADO) {
                this.state.indRechazadoDesestimado = true;
                this.setState({ indRechazadoDesestimado: true });
            }
            if (idRol == REACT_APP_ROL_INCLUSION || idRol == REACT_APP_ROL_OPERATIVO || idRol == REACT_APP_ROL_JEFATURA || idRol == REACT_APP_ROL_CONTABILIDAD) {
                this.state.indRechazadoDesestimado = true;
                this.setState({ indRechazadoDesestimado: true });
            }
            this.setState({
                porcentajePerdidaEsperadaCartera:
                    infoEditarJson.detallePrograma.porcentajePerdidaEsperadaCartera,
            });
            this.setState({
                montoPerdidadEsperadaCartera:
                    infoEditarJson.detallePrograma.montoPerdidadEsperadaCartera,
            });
        }
        this.cargarEstado();
        this.cargarFrecuencia();
        this.obtenerParametroVigencia();
        /**
         * Creacion del DataTable, para obtener los clientes
         **/
        $("#tabla1").DataTable({
            processing: true,
            serverSide: true,
            pageLength: 50,
            ajax: {
                url: `/api/ObtenerClientes?fondo=${localStorage.getItem("idFondo")}&ip=${localStorage.getItem("ip")}&latitud=${localStorage.getItem("latitud")}&longitud=${localStorage.getItem("longitud")}&userAgent=${userAgent}&usuario=${localStorage.getItem("idUsuarioCreador")}`,
                error: function (xhr, error, thrown) {
                    if (xhr.status == REACT_APP_CODIGO_RESPUESTA_401) {
                        swal({
                            title: "¡Sesión Expiró!",
                            text: "Por favor vuelva a iniciar sesión",
                            icon: "warning",
                            button: "Aceptar"
                        }).then((value) => {
                            BorrarLocalStorage();
                            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                        });

                        return;
                    }
                    if (xhr.status != REACT_APP_CODIGO_RESPUESTA_200) {
                        swal("Error al obtener los clientes", "", "error", { button: "Aceptar", });
                        $("#spinEFA").show();
                        return;
                    }
                },
                success: (data) => {
                    if (data != null) {
                        $("#spinEFA").hide();
                        var filtroClientesActivos = [];
                        var idClienteSeleccionado = this.state.clienteSeleccionado.id;
                        filtroClientesActivos = data.data.filter(function (cliente) {
                            var caract = [];
                            caract = cliente.caracteristicas.filter(function (val) {
                                return (
                                    val.fondo.id == idFondo
                                );
                            });
                            return (
                                caract[0].estado.id == REACT_APP_ESTADO_CLIENTE_ACTIVO || caract[0].entidadFinancieraAutorizada.id == idClienteSeleccionado
                            );
                        });
                        this.setState({
                            clientes: filtroClientesActivos,
                        });
                        if (calcularMoralLegal == REACT_APP_PUEDE_CALCULAR_MORA_LEGAL) {
                            ObtenerParametro(REACT_APP_OBTENER_PORCENTAJE_MORA_LEGAL).then((res) => {
                                if (res) {
                                    if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                                        swal("Mora Legal", "Ha ocurrido un error al obtener el porcentaje máximo de mora legal permitido. Por favor recargue de nuevo la página o bien, comuníquese con el adminsitrador.", "error", { button: "Aceptar", });
                                        return;
                                    }
                                    localStorage.setItem("porcentajeMoralLegal", res.json.valor);
                                    this.obtenerMoraLegal();
                                }
                            });
                        } else {
                            $("#spinMoraLegal").hide();
                        }
                    }

                },
                type: "POST",
            },
            columns: [{ data: "id", name: "id" }],
        });
    }
    /**
     * Obtener la vigencia maxima del programa
     **/
    obtenerParametroVigencia = () => {
        ObtenerParametro(REACT_APP_VIGENCIA_MAX_PROG_CARTERA_PERDIDA_ESPERADA).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Programa Individual", "Ha ocurrido un error al obtener el parámetro para obtener la vigencia programa (meses). Por favor recargue de nuevo la página o bien, comuníquese con el adminsitrador.", "error", { button: "Aceptar", });
                    return;
                }
                if (idFondo == REACT_APP_OPERADORA_FODEMIPYME) {
                    this.state.vigenciaMaximaFODEMIPYME = parseInt(res.json.valor);
                    this.setState({ vigenciaMaximaFODEMIPYME: parseInt(res.json.valor) });
                } else {
                    this.state.vigenciaMaximaFNA = parseInt(res.json.valor);
                    this.setState({ vigenciaMaximaFNA: parseInt(res.json.valor) });
                }
            }
        });
    }
    /**
     * Obtener la moral legal por Entidad Financiera Autorizada
     **/
    obtenerMoraLegal = () => {
        $("#spinMoraLegal").show();
        var data = {
            IdEntidadFinanciera: this.state.clienteSeleccionado.id,
        };
        MoraLegal(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Mora Legal", res.json.mensaje, "error", { button: "Aceptar", });
                    this.state.indMoraLegal = true;
                    this.setState({ indMoraLegal: true });
                    $("#spinMoraLegal").hide();
                    return;
                }
                $("#spinMoraLegal").hide();
                const porcentajeMoralLegal = parseInt(localStorage.getItem("porcentajeMoralLegal"));
                this.state.indMoraLegal = false;
                this.setState({ indMoraLegal: false });
                const moraLegal = parseFloat(res.json.moraLegal);
                this.setState({ moraLegal: moraLegal.toFixed(2) });
                if (moraLegal.toFixed(2) > porcentajeMoralLegal) {
                    swal(
                        "Mora Legal",
                        "La mora legal excede lo permito para crear programas con la Entidad Financiera Autorizada seleccionada.",
                        "warning", { button: "Aceptar", }
                    );
                    this.state.indMoraLegal = true;
                    this.setState({ indMoraLegal: true });
                    return;
                }
            }
        });
    };
    /**
     * Cargar estados
     **/
    cargarEstado = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_ESTADO_PROGRAMAS,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Error", res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                if (this.state.estadoSeleccionado.id == REACT_APP_ESTADO_PROGRAMA_DESESTIMADO) {
                    var nuevoEstados = [];
                    nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                        return estado.id == REACT_APP_ESTADO_PROGRAMA_DESESTIMADO;
                    });
                    this.setState({
                        estados: nuevoEstados,
                    });
                    return;
                }
                if (this.state.estadoSeleccionado.id == REACT_APP_ESTADO_PROGRAMA_RECHAZADO) {
                    var nuevoEstados = [];
                    nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                        return estado.id == REACT_APP_ESTADO_PROGRAMA_RECHAZADO;
                    });
                    this.setState({
                        estados: nuevoEstados,
                    });
                    return;
                }
                if (this.state.estadoSeleccionado.id == REACT_APP_ESTADO_PROGRAMA_ACTIVO) {
                    var nuevoEstados = [];
                    nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                        return (
                            estado.id == REACT_APP_ESTADO_PROGRAMA_ACTIVO ||
                            estado.id == REACT_APP_ESTADO_PROGRAMA_INACTIVO ||
                            estado.id == REACT_APP_ESTADO_PROGRAMA_DESESTIMADO ||
                            estado.id == REACT_APP_ESTADO_PROGRAMA_RECHAZADO
                        );
                    });
                    this.setState({
                        estados: nuevoEstados,
                    });
                    return;
                }
                if (this.state.estadoSeleccionado.id == REACT_APP_ESTADO_PROGRAMA_INACTIVO) {
                    var nuevoEstados = [];
                    nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                        return estado.id == REACT_APP_ESTADO_PROGRAMA_ACTIVO || estado.id == REACT_APP_ESTADO_PROGRAMA_INACTIVO;
                    });
                    this.setState({
                        estados: nuevoEstados,
                    });
                    return;
                }
                this.setState({
                    estados: res.json.catalogoValores,
                });
            }
        });
    };
    /**
     * Cargar frecuencias
     **/
    cargarFrecuencia = () => {
        var data = {
            idCatalogo: REACT_APP_OBTENER_FRECUENCIAS,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Error", res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                this.setState({
                    frencuencias: res.json.catalogoValores,
                });
                let frecuencia = this.state.frencuencias.filter(
                    (x) => x.id == this.state.idFrecuenciaRevision
                );
                this.setState({ frecuenciaSeleccionado: frecuencia[0] });
            }
        });
    };
    /**
     * 
     * @param {*} event 
     * @returns Alamacena en el State lo digitado en el formulario. 
     **/
    encargarseCambio = (event) => {

        var preg = /^([0-9]+\.?[0-9]{0,2})$/;

        if (event.target.name === "fechaInicio") {
            this.setState({ indFechaInicio: true });
        }
        if (event.target.name === "fechaAprobacion") {
            this.setState({ indFechaAprobacion: true });
        }
        if ([event.target.name] == "montoCarteraAvalar") {

            const soloNumerosMontoCarteraAvalar = preg.test(
                event.target.value.replace(/,/g, "")
            );

            if (!soloNumerosMontoCarteraAvalar) {
                swal(
                    "Editar Programa Cartera Pérdida Esperada",
                    "El monto de cartera por avalar no tiene un formato válido",
                    "warning", { button: "Aceptar", }
                );
                var montoCarteraAvalarFloat = parseFloat(event.target.value.replace(/,/g, "")).toFixed(2);
                this.setState({ montoCarteraAvalar: montoCarteraAvalarFloat });
                $("#guardando").hide();
                return;
            }

            const montoProgramaAsignado =
                parseFloat(event.target.value.replace(/,/g, "")) *
                (parseFloat(this.state.porcentajeAvalado) / 100);

            const montoProgramaAsignadoFloat = parseFloat(montoProgramaAsignado.toFixed(2));

            const montoPerdidaEsperadaCartera =
                parseFloat(event.target.value.replace(/,/g, "")) *
                (parseFloat(this.state.porcentajePerdidaEsperadaCartera) / 100);

            const montoPerdidaEsperadaCarteraFloat = parseFloat(montoPerdidaEsperadaCartera.toFixed(2));

            const montoMaximoHonrar =
                montoPerdidaEsperadaCarteraFloat *
                (parseFloat(this.state.porcentajePerdidaEsperada) / 100);

            const montoMaximoHonrarFloat = parseFloat(montoMaximoHonrar.toFixed(2));

            this.state.montoProgramaAsignado = montoProgramaAsignadoFloat;
            this.setState({ montoProgramaAsignado: montoProgramaAsignadoFloat });

            this.state.montoPerdidadEsperadaCartera = montoPerdidaEsperadaCarteraFloat;
            this.setState({
                montoPerdidadEsperadaCartera: montoPerdidaEsperadaCarteraFloat,
            });

            this.state.montoMaximoHonrar = montoMaximoHonrarFloat;
            this.setState({ montoMaximoHonrar: montoMaximoHonrarFloat });
        }

        if ([event.target.name] == "montoMaximoCredito") {
            const soloNumerosMontoMaximoCartera = preg.test(
                event.target.value.replace(/,/g, "")
            );

            if (!soloNumerosMontoMaximoCartera) {
                swal(
                    "Editar Programa Cartera Pérdida Esperada",
                    "El monto máximo para cada crédito no tiene un formato válido",
                    "warning", { button: "Aceptar", }
                );
                var montoMaximoFloat = parseFloat(event.target.value.replace(/,/g, "")).toFixed(2);
                this.setState({ montoMaximoCredito: montoMaximoFloat });
                $("#guardando").hide();
                return;
            }
        }

        if ([event.target.name] == "porcentajeAvalado") {

            const soloNumerosPorcentajeAvalado = preg.test(
                event.target.value.replace(/,/g, "")
            );

            if (!soloNumerosPorcentajeAvalado) {
                swal(
                    "Editar Programa Cartera Pérdida Esperada",
                    "El % avalado de la cartera no tiene un formato válido",
                    "warning", { button: "Aceptar", }
                );
                var porcentajeAvaladoFloat = parseFloat(event.target.value.replace(/,/g, "")).toFixed(2);
                this.setState({ porcentajeAvalado: porcentajeAvaladoFloat });
                $("#guardando").hide();
                return;
            }

            const montoCartera = Number(this.state.montoCarteraAvalar)
                ? this.state.montoCarteraAvalar
                : parseFloat(this.state.montoCarteraAvalar.replace(/,/g, ""));
            const montoPrograma = montoCartera * (parseFloat(event.target.value) / 100);

            const montoProgramaFloat = parseFloat(montoPrograma.toFixed(2));

            this.state.montoProgramaAsignado = montoProgramaFloat;
            this.setState({ montoProgramaAsignado: montoProgramaFloat });
        }
        if ([event.target.name] == "porcentajePerdidaEsperadaCartera") {

            const soloNumerosPorcentajePerdidaEsperadaCartera = preg.test(
                event.target.value.replace(/,/g, "")
            );

            if (!soloNumerosPorcentajePerdidaEsperadaCartera) {
                swal(
                    "Editar Programa Cartera Pérdida Esperada",
                    "El % pérdida esperada cartera no tiene un formato válido",
                    "warning", { button: "Aceptar", }
                );
                var porcentajePerdidaEsperadaCarteraFloat = parseFloat(event.target.value.replace(/,/g, "")).toFixed(2);
                this.setState({ porcentajePerdidaEsperadaCartera: porcentajePerdidaEsperadaCarteraFloat });
                $("#guardando").hide();
                return;
            }

            let montoCarteta = Number(this.state.montoCarteraAvalar)
                ? this.state.montoCarteraAvalar
                : parseFloat(this.state.montoCarteraAvalar.replace(/,/g, ""));
            const montoPerdidaEsperadaCartera =
                montoCarteta * (parseFloat(event.target.value) / 100);

            const montoPerdidaEsperadaCarteraFloat = parseFloat(montoPerdidaEsperadaCartera.toFixed(2));

            this.state.montoPerdidadEsperadaCartera = montoPerdidaEsperadaCarteraFloat;
            this.setState({
                montoPerdidadEsperadaCartera: montoPerdidaEsperadaCarteraFloat,
            });
        }

        if ([event.target.name] == "porcentajePerdidaEsperada") {

            const soloNumerosPorcentajePerdidaEsperada = preg.test(
                event.target.value.replace(/,/g, "")
            );

            if (!soloNumerosPorcentajePerdidaEsperada) {
                swal(
                    "Editar Programa Cartera Pérdida Esperada",
                    "El % pérdida esperada por pagar no tiene un formato válido",
                    "warning", { button: "Aceptar", }
                );
                var porcentajePerdidaEsperadaFloat = parseFloat(event.target.value.replace(/,/g, "")).toFixed(2);
                this.setState({ porcentajePerdidaEsperada: porcentajePerdidaEsperadaFloat });
                $("#guardando").hide();
                return;
            }

            const montoMaximoHonrar =
                this.state.montoPerdidadEsperadaCartera *
                (parseFloat(event.target.value) / 100);

            const montoMaximoHonrarFloat = parseFloat(montoMaximoHonrar.toFixed(2));

            this.state.montoMaximoHonrar = montoMaximoHonrarFloat;
            this.setState({ montoMaximoHonrar: montoMaximoHonrarFloat });
        }

        if ([event.target.name] == "porcentajeMaximoAvalCredito") {
            const soloNumerosPorcentajeMaximoAvalEsperada = preg.test(
                event.target.value.replace(/,/g, "")
            );

            if (!soloNumerosPorcentajeMaximoAvalEsperada) {
                swal(
                    "Editar Programa Cartera Pérdida Esperada",
                    "El % máximo aval en cada crédito no tiene un formato válido",
                    "warning", { button: "Aceptar", }
                );
                var porcentajeMaximoAvalCreditoFloat = parseFloat(event.target.value.replace(/,/g, "")).toFixed(2);
                this.setState({ porcentajeMaximoAvalCredito: porcentajeMaximoAvalCreditoFloat });
                $("#guardando").hide();
                return;
            }
        }

        if ([event.target.name] == "porcentajeComisionFormalizacion") {
            const soloNumerosPorcentajeFormalizacion = preg.test(
                event.target.value.replace(/,/g, "")
            );

            if (!soloNumerosPorcentajeFormalizacion) {
                swal(
                    "Editar Programa Cartera Pérdida Esperada",
                    "La comisión de formalización no tiene un formato válido",
                    "warning", { button: "Aceptar", }
                );
                var porcentajeComisionFormalizacionFloat = parseFloat(event.target.value.replace(/,/g, "")).toFixed(2);
                this.setState({ porcentajeComisionFormalizacion: porcentajeComisionFormalizacionFloat });
                $("#guardando").hide();
                return;
            }
        }

        if ([event.target.name] == "porcentajeComisionAdministracion") {
            const soloNumerosPorcentajeAdministracion = preg.test(
                event.target.value.replace(/,/g, "")
            );

            if (!soloNumerosPorcentajeAdministracion) {
                swal(
                    "Editar Programa Cartera Pérdida Esperada",
                    "La comisión de administración no tiene un formato válido",
                    "warning", { button: "Aceptar", }
                );
                var porcentajeComisionAdministracionFloat = parseFloat(event.target.value.replace(/,/g, "")).toFixed(2);
                this.setState({ porcentajeComisionAdministracion: porcentajeComisionAdministracionFloat });
                $("#guardando").hide();
                return;
            }
        }

        if (event.target.name === "indicadorConsumoWSRiesgo") {
            this.setState({ [event.target.name]: event.target.checked });
            return;
        }
        this.setState({ [event.target.name]: event.target.value });
    };
    /**
     * 
     * @param {*} event 
     * @returns Cambiar la Entidad Financiera seleccionada
     **/
    cambioOperadora = (event) => {
        let operadora = this.state.clientes.filter(
            (operadora) => operadora.id == event.target.value
        );
        this.state.clienteSeleccionado = operadora[0];
        this.setState({ clienteSeleccionado: operadora[0] });
        if (calcularMoralLegal == REACT_APP_PUEDE_CALCULAR_MORA_LEGAL) this.obtenerMoraLegal();
    };
    /**
     * 
     * @param {*} event 
     * @returns Cambiar estado seleccionado
     **/
    cambioEstado = (event) => {
        let estado = this.state.estados.filter((x) => x.id == event.target.value);
        this.setState({ estadoSeleccionado: estado[0] });
    };
    /**
     * 
     * @param {*} event 
     * @returns Cambiar frecuencia seleccionada
     **/
    cambioFrecuencia = (event) => {
        let frecuencia = this.state.frencuencias.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ frecuenciaSeleccionado: frecuencia[0] });
    };
    /**
     * 
     * @param {*} event 
     * @returns Modificacion de un programa 
     **/
    modificarPrograma = (event) => {
        event.preventDefault();
        let idFondo = localStorage.getItem("idFondo");
        var numeros = /^([0-9]+\.?[0-9]{0,2})$/;
        const soloNumerosMontoCarteraAvalar = numeros.test(
            Number(this.state.montoCarteraAvalar) ? this.state.montoCarteraAvalar : this.state.montoCarteraAvalar.replace(/,/g, "")
        );
        const soloNumerosMontoMaximoCredito = numeros.test(
            Number(this.state.montoMaximoCredito) ? this.state.montoMaximoCredito : this.state.montoMaximoCredito.replace(/,/g, "")
        );
        const soloNumerosMontoProgramaAsignado = numeros.test(
            this.state.montoProgramaAsignado
        );
        const soloNumerosMontoMaximoHonrar = numeros.test(
            this.state.montoMaximoHonrar
        );
        const soloNumerosMontoPerdidaEsperadaCartera = numeros.test(
            this.state.montoPerdidadEsperadaCartera
        );
        $("#guardando").show();
        let idUsuarioCrea = localStorage.getItem("idUsuarioCreador");
        if (this.state.nombrePrograma === "") {
            swal(
                "Editar Programa Cartera Pérdida Esperada",
                "El Nombre del Programa es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.nombrePrograma.length < 5) {
            swal(
                "Editar Programa Cartera Pérdida Esperada",
                "El Nombre del Programa debe contener mínimo 5 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.nombrePrograma.length > 100) {
            swal(
                "Editar Programa Cartera Pérdida Esperada",
                "El Nombre del Programa debe contener máximo 100 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.montoCarteraAvalar === "") {
            swal(
                "Editar Programa Cartera Pérdida Esperada",
                "El monto de cartera por avalar es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (!soloNumerosMontoCarteraAvalar) {
            swal(
                "Editar Programa Cartera Pérdida Esperada",
                "El monto de cartera por avalar no tiene un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (
            Number(this.state.montoCarteraAvalar)
                ? this.state.montoCarteraAvalar > REACT_APP_LONGITUD_CAMPO_MONTOS
                : this.state.montoCarteraAvalar === 0
                    ? this.state.montoCarteraAvalar > REACT_APP_LONGITUD_CAMPO_MONTOS
                    : this.state.montoCarteraAvalar.replace(/,/g, "").length > 12
        ) {
            swal(
                "Editar Programa Cartera Pérdida Esperada",
                "El monto de cartera por avalar no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.porcentajeAvalado.toString().length > 5) {
            swal(
                "Editar Programa Cartera Pérdida Esperada",
                "El porcentaje avalado de la cartera debe de contener como máximo 4 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.porcentajeMaximoAvalCredito.toString().length > 5) {
            swal(
                "Editar Programa Cartera Pérdida Esperada",
                "El porcentaje máximo en cada crédito debe de contener como máximo 4 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.montoMaximoCredito === "") {
            swal(
                "Editar Programa Cartera Pérdida Esperada",
                "El monto máximo de cada crédito es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (!soloNumerosMontoMaximoCredito) {
            swal(
                "Editar Programa Cartera Pérdida Esperada",
                "El monto máximo de cada crédito no tiene un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (
            Number(this.state.montoMaximoCredito)
                ? this.state.montoMaximoCredito > REACT_APP_LONGITUD_CAMPO_MONTOS
                : this.state.montoMaximoCredito === 0
                    ? this.state.montoMaximoCredito > REACT_APP_LONGITUD_CAMPO_MONTOS
                    : this.state.montoMaximoCredito.replace(/,/g, "").length > 12
        ) {
            swal(
                "Editar Programa Cartera Pérdida Esperada",
                "El monto máximo de cada crédito no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.montoProgramaAsignado === "") {
            swal(
                "Editar Programa Cartera Pérdida Esperada",
                "El monto programa asignado es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (!soloNumerosMontoProgramaAsignado) {
            swal(
                "Editar Programa Cartera Pérdida Esperada",
                "El monto del programa asignado no tiene un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (
            Number(this.state.montoProgramaAsignado)
                ? this.state.montoProgramaAsignado > REACT_APP_LONGITUD_CAMPO_MONTOS
                : this.state.montoProgramaAsignado === 0
                    ? this.state.montoProgramaAsignado > REACT_APP_LONGITUD_CAMPO_MONTOS
                    : this.state.montoProgramaAsignado.replace(/,/g, "").length > 12
        ) {
            swal(
                "Editar Programa Cartera Pérdida Esperada",
                "El monto programa asignado no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.porcentajeAvalado === "") {
            swal(
                "Editar Programa Cartera Pérdida Esperada",
                "El porcentaje avalado de la cartera es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.porcentajeMaximoAvalCredito === "") {
            swal(
                "Editar Programa Cartera Pérdida Esperada",
                "El porcentaje máximo en cada crédito es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.montoMaximoHonrar === "") {
            swal(
                "Editar Programa Cartera Pérdida Esperada",
                "El monto máximo por honrar es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (!soloNumerosMontoMaximoHonrar) {
            swal(
                "Editar Programa Cartera Pérdida Esperada",
                "El monto máximo por honrar no tiene un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (
            Number(this.state.montoMaximoHonrar)
                ? this.state.montoMaximoHonrar > REACT_APP_LONGITUD_CAMPO_MONTOS
                : this.state.montoMaximoHonrar === 0
                    ? this.state.montoMaximoHonrar > REACT_APP_LONGITUD_CAMPO_MONTOS
                    : this.state.montoMaximoHonrar.replace(/,/g, "").length > 12
        ) {
            swal(
                "Editar Programa Cartera Pérdida Esperada",
                "El monto máximo por honrar no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.vigenciaPrograma < 0) {
            swal(
                "Editar Programa Pérdida Esperada",
                "La vigencia del programa no puede ser menor a 0",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (idFondo == REACT_APP_OPERADORA_FODEMIPYME) {
            if (this.state.vigenciaPrograma > this.state.vigenciaMaximaFODEMIPYME) {
                swal(
                    "Editar Programa Pérdida Esperada",
                    "La vigencia del programa no puede ser mayor a " + this.state.vigenciaMaximaFODEMIPYME,
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                return;
            }
        } else {
            if (this.state.vigenciaPrograma > this.state.vigenciaMaximaFNA) {
                swal(
                    "Editar Programa Pérdida Esperada",
                    "La vigencia del programa no puede ser mayor a " + this.state.vigenciaMaximaFNA,
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                return;
            }
        }
        if (this.state.montoPerdidadEsperadaCartera === "") {
            swal(
                "Editar Programa Cartera Pérdida Esperada",
                "El monto de la pérdida esperada cartera es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (!soloNumerosMontoPerdidaEsperadaCartera) {
            swal(
                "Editar Programa Cartera Pérdida Esperada",
                "El monto de la pérdida esperada cartera no tiene un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.montoPerdidadEsperadaCartera > REACT_APP_LONGITUD_CAMPO_MONTOS) {
            swal(
                "Editar Programa Cartera Pérdida Esperada",
                "El monto de la pérdida esperada cartera no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.porcentajePerdidaEsperada === "") {
            swal(
                "Editar Programa Cartera Pérdida Esperada",
                "El porcentaje de pérdida esperada es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.porcentajePerdidaEsperadaCartera === "") {
            swal(
                "Editar Programa Cartera Pérdida Esperada",
                "El porcentaje de pérdida esperada cartera es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.porcentajeComisionAdministracion.toString().length > 5) {
            swal(
                "Editar Programa Cartera Pérdida Esperada",
                "El porcentaje de adminstración debe de contener como máximo 4 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.porcentajeComisionFormalizacion.toString().length > 5) {
            swal(
                "Editar Programa Cartera Pérdida Esperada",
                "El porcentaje de adminstración debe de contener como máximo 4 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        let data = {
            IdPrograma: this.state.id,
            IdCliente: this.state.clienteSeleccionado.id,
            IdEstado: this.state.estadoSeleccionado.id,
            IdUsuarioCreador: idUsuarioCrea,
            MontoCarteraAvalar: Number(this.state.montoCarteraAvalar)
                ? this.state.montoCarteraAvalar
                : this.state.montoCarteraAvalar === 0
                    ? this.state.montoCarteraAvalar
                    : parseFloat(this.state.montoCarteraAvalar.replace(/,/g, "")),
            PorcentajeAvalado: this.state.porcentajeAvalado,
            MontoMaximoCredito: Number(this.state.montoMaximoCredito)
                ? this.state.montoMaximoCredito
                : this.state.montoMaximoCredito === 0
                    ? this.state.montoMaximoCredito
                    : parseFloat(this.state.montoMaximoCredito.replace(/,/g, "")),
            PorcentajeMaximoAvalCredito: this.state.porcentajeMaximoAvalCredito,
            MontoProgramaAsignado: Number(this.state.montoProgramaAsignado)
                ? this.state.montoProgramaAsignado
                : this.state.montoProgramaAsignado === 0
                    ? this.state.montoProgramaAsignado
                    : parseFloat(this.state.montoProgramaAsignado.replace(/,/g, "")),
            FechaAprobacion: this.state.fechaAprobacion,
            FechaInicio: this.state.fechaInicio,
            VigenciaPrograma: this.state.vigenciaPrograma,
            PorcentajePerdidaEsperada: this.state.porcentajePerdidaEsperada,
            MontoMaximoHonrar: Number(this.state.montoMaximoHonrar)
                ? this.state.montoMaximoHonrar
                : this.state.montoMaximoHonrar === 0
                    ? this.state.montoMaximoHonrar
                    : parseFloat(this.state.montoMaximoHonrar.replace(/,/g, "")),
            IdFrecuenciaRevision: this.state.frecuenciaSeleccionado.id,
            IndicadorConsumoWSRiesgo: this.state.indicadorConsumoWSRiesgo,
            PorcentajePerdidaEsperadaCartera: this.state
                .porcentajePerdidaEsperadaCartera,
            MontoPerdidadEsperadaCartera: Number(
                this.state.montoPerdidadEsperadaCartera
            )
                ? this.state.montoPerdidadEsperadaCartera
                : this.state.montoPerdidadEsperadaCartera === 0
                    ? this.state.montoPerdidadEsperadaCartera
                    : parseFloat(this.state.montoPerdidadEsperadaCartera.replace(/,/g, "")),
            PorcentajeComisionFormalizacion: this.state
                .porcentajeComisionFormalizacion,
            PorcentajeComisionAdministracion: this.state
                .porcentajeComisionAdministracion,
            NombrePrograma: this.state.nombrePrograma
        };

        let texto = "Este programa SI va a consumir el modelo de riesgo";
        if (!this.state.indicadorConsumoWSRiesgo) {
            texto = "Este programa NO va a consumir el modelo de riesgo";
        }
        swal({
            title: "Estás seguro/segura?",
            text: texto,
            icon: "warning",
            buttons: ["NO", "SÍ"],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.setState({ indButton: true });
                ModificarProgramaCarteraPE(data).then((res) => {
                    if (res) {
                        if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                            swal("Error", res.json.mensaje, "error", { button: "Aceptar", });
                            $("#guardando").hide();
                            this.setState({ indButton: false });
                            return;
                        }
                        swal(
                            "Programa Pérdida Esperada",
                            "¡Programa editado con éxito!",
                            "success", { button: "Aceptar", }
                        ).then((value) => {
                            window.location.href = "/programas-perdidas";
                        });
                        $("#guardando").hide();
                        this.setState({ indButton: false });
                    }
                });
            } else {
                $("#guardando").hide();
            }
        });
    };
    render() {
        const idRol = localStorage.getItem("idRol");
        const idFondo = localStorage.getItem("idFondo");
        if (idRol) {
            if (idRol == REACT_APP_ROL_SEGUIMIENTO)
                window.location.href = "/programas-perdidas";
        } else {
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        }
        return (
            <div className="container text-center">
                <table id="tabla1"></table>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="/admin">Inicio</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            <a href="/mantenimiento">Mantenimientos</a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            <a href="/mantenimiento-programas">Mantenimiento Programas</a>
                        </li>
                        <li className="breadcrumb-item">
                            <a href="/programas-perdidas">
                                Programas Cartera Pérdida Esperada
                            </a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Editar Programa Cartera Pérdida Esperada
                        </li>
                    </ol>
                </nav>
                <div className="row">
                    <form className="form-crear" onSubmit={this.modificarPrograma}>
                        <h1 className="h3 mb-3 font-weight-normal">
                            Editar Programa Cartera Pérdida Esperada{" "}
                            <b># {this.state.idProgramaPE}</b>
                        </h1>
                        {calcularMoralLegal == REACT_APP_PUEDE_CALCULAR_MORA_LEGAL ? (
                            <div className="row form-padding">
                                <div className="col-md-3"></div>
                                <div className="col-md-6">
                                    <label
                                        className={this.state.indMoraLegal ? "mora-legal-red" : ""}
                                    >
                                        Mora Legal: {this.state.moraLegal}
                                    </label>
                                    <span className="input-group-addon" id="spinMoraLegal">
                                        <i className="fa fa-refresh fa-spin"></i>
                                    </span>
                                </div>
                                <div className="col-md-3">
                                    <button
                                        type="button"
                                        className="btn btn-success border-radius"
                                        disabled={this.indRechazadoDesestimado}
                                        onClick={this.obtenerMoraLegal}
                                    >
                                        <i className="fa fa-refresh"></i>
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className="row form-padding"></div>
                        )}
                        <div className="row form-padding">
                            <div className="col-md-3"></div>
                            <div className="col-md-6">
                                <label>Moneda: CRC</label>
                            </div>
                            <div className="col-md-3"></div>
                        </div>
                        <div className="row form-padding">
                            <div className="col-md-3"></div>
                            <div className="col-md-6">
                                <label className="form-check-label">
                                    ¿Consumir Modelo de Riesgo?
                                </label>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="indicadorConsumoWSRiesgo"
                                        id="indicadorConsumoWSRiesgo"
                                        onChange={this.encargarseCambio}
                                        value={this.state.indicadorConsumoWSRiesgo}
                                        disabled={this.state.indRechazadoDesestimado}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                        </div>
                        <hr></hr>
                        <div className="row form-padding">
                            <div className="col-md-6">
                                <label>Entidad Operadora</label>
                                <select
                                    className="form-control"
                                    onChange={this.cambioOperadora}
                                    value={this.state.clienteSeleccionado.id}
                                    disabled={this.state.indRechazadoDesestimado}
                                >
                                    {this.state.clientes.map(function (data, key) {
                                        return (
                                            <option key={key} value={data.id}>
                                                {data.nombreOperadora}
                                            </option>
                                        );
                                    })}
                                </select>
                                <span className="input-group-addon" id="spinEFA">
                                    <i className="fa fa-refresh fa-spin"></i>
                                </span>
                            </div>
                            <div className="col-md-6">
                                <label>Monto cartera por Avalar</label>
                                <NumberFormat
                                    thousandSeparator={true}
                                    type="text"
                                    className="form-control"
                                    name="montoCarteraAvalar"
                                    value={this.state.montoCarteraAvalar}
                                    onChange={this.encargarseCambio}
                                    disabled={this.state.indRechazadoDesestimado}
                                    required
                                    placeholder="Monto cartera por Avalar"
                                />
                            </div>
                        </div>
                        <div className="row form-padding">
                            <div className="col-md-3"></div>
                            <div className="col-md-6">
                                <label>Nombre Programa</label>
                                <input
                                    type="text"
                                    name="nombrePrograma"
                                    className="form-control"
                                    value={this.state.nombrePrograma}
                                    onChange={this.encargarseCambio}
                                    disabled={this.state.indRechazadoDesestimado}
                                    required
                                    placeholder="Nombre Programa"
                                />
                            </div>
                            <div className="col-md-3"></div>
                        </div>
                        <div className="row form-padding">
                            <div className="col-md-6">
                                <label>% Avalado de la Cartera</label>
                                <input
                                    type="number"
                                    name="porcentajeAvalado"
                                    min="0"
                                    max="100"
                                    step="any"
                                    className="form-control"
                                    value={this.state.porcentajeAvalado}
                                    onChange={this.encargarseCambio}
                                    disabled={this.state.indRechazadoDesestimado}
                                    required
                                    placeholder="%"
                                />
                            </div>
                            <div className="col-md-6">
                                <label>Monto Máximo Cada Crédito</label>
                                <NumberFormat
                                    thousandSeparator={true}
                                    type="text"
                                    className="form-control"
                                    name="montoMaximoCredito"
                                    value={this.state.montoMaximoCredito}
                                    onChange={this.encargarseCambio}
                                    disabled={this.state.indRechazadoDesestimado}
                                    required
                                    placeholder="Monto Máximo Cada Crédito"
                                />
                            </div>
                        </div>
                        <div className="row form-padding">
                            <div className="col-md-6">
                                <label>% Máximo Aval en cada Crédito</label>
                                <input
                                    type="number"
                                    name="porcentajeMaximoAvalCredito"
                                    min="0"
                                    max="75"
                                    step="any"
                                    className="form-control"
                                    value={this.state.porcentajeMaximoAvalCredito}
                                    onChange={this.encargarseCambio}
                                    disabled={this.state.indRechazadoDesestimado}
                                    required
                                    placeholder="%"
                                />
                            </div>
                            <div className="col-md-6">
                                <label>Monto del Programa Asignado</label>
                                <NumberFormat
                                    thousandSeparator={true}
                                    type="text"
                                    className="form-control"
                                    name="montoProgramaAsignado"
                                    value={this.state.montoProgramaAsignado}
                                    onChange={this.encargarseCambio}
                                    disabled
                                    required
                                    placeholder="Monto del Programa Asignado"
                                />
                            </div>
                        </div>
                        <div className="row form-padding">
                            <div className="col-md-6">
                                <label>Fecha de Aprobación</label>
                                <input
                                    type="date"
                                    name="fechaAprobacion"
                                    className="form-control"
                                    value={this.state.fechaAprobacion}
                                    onChange={this.encargarseCambio}
                                    disabled={this.state.indRechazadoDesestimado}
                                    required
                                />
                            </div>
                            <div className="col-md-6">
                                <label>Fecha Inicio</label>
                                <input
                                    type="date"
                                    name="fechaInicio"
                                    className="form-control"
                                    value={this.state.fechaInicio}
                                    onChange={this.encargarseCambio}
                                    disabled={this.state.indRechazadoDesestimado}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row form-padding">
                            <div className="col-md-6">
                                <label>% Pérdida Esperada Cartera</label>
                                <input
                                    type="number"
                                    name="porcentajePerdidaEsperadaCartera"
                                    min="0"
                                    max="100"
                                    step="any"
                                    className="form-control"
                                    value={this.state.porcentajePerdidaEsperadaCartera}
                                    onChange={this.encargarseCambio}
                                    required
                                    placeholder="% Pérdida Esperada Cartera"
                                    disabled={this.state.indRechazadoDesestimado}
                                />
                            </div>
                            <div className="col-md-6">
                                <label>Monto de la Pérdida Esperada Cartera</label>
                                <NumberFormat
                                    thousandSeparator={true}
                                    type="text"
                                    className="form-control"
                                    name="montoPerdidadEsperadaCartera"
                                    value={this.state.montoPerdidadEsperadaCartera}
                                    onChange={this.encargarseCambio}
                                    required
                                    placeholder="Monto de la Pérdida Esperada Cartera"
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="row form-padding">
                            <div className="col-md-6">
                                <label>Pérdida Esperada por Pagar</label>
                                <input
                                    type="number"
                                    name="porcentajePerdidaEsperada"
                                    min="0"
                                    max="100"
                                    step="any"
                                    className="form-control"
                                    value={this.state.porcentajePerdidaEsperada}
                                    onChange={this.encargarseCambio}
                                    disabled={this.state.indRechazadoDesestimado}
                                    required
                                    placeholder="Pérdida Esperada por Pagar"
                                />
                            </div>
                            <div className="col-md-6">
                                <label>Monto Máximo por Honrar</label>
                                <NumberFormat
                                    thousandSeparator={true}
                                    type="text"
                                    className="form-control"
                                    name="montoMaximoHonrar"
                                    value={this.state.montoMaximoHonrar}
                                    onChange={this.encargarseCambio}
                                    disabled
                                    required
                                    placeholder="Monto Máximo por Honrar"
                                />
                            </div>
                        </div>
                        <div className="row form-padding">
                            {idFondo == REACT_APP_OPERADORA_FODEMIPYME ? (
                                <div className="col-md-6">
                                    <label>Vigencia Programa (meses)</label>
                                    <input
                                        type="number"
                                        name="vigenciaPrograma"
                                        min="0"
                                        max={this.state.vigenciaMaximaFODEMIPYME}
                                        className="form-control"
                                        value={this.state.vigenciaPrograma}
                                        onChange={this.encargarseCambio}
                                        disabled={this.state.indRechazadoDesestimado}
                                        required
                                        placeholder="Vigencia Programa"
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                </div>
                            ) : (
                                <div className="col-md-6">
                                    <label>Vigencia Programa (meses)</label>
                                    <input
                                        type="number"
                                        name="vigenciaPrograma"
                                        min="0"
                                        max={this.state.vigenciaMaximaFNA}
                                        className="form-control"
                                        value={this.state.vigenciaPrograma}
                                        onChange={this.encargarseCambio}
                                        disabled={this.state.indRechazadoDesestimado}
                                        required
                                        placeholder="Vigencia Programa"
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                </div>
                            )}
                            <div className="col-md-6">
                                <label>Frecuencia de Revisión P.E</label>
                                <select
                                    className="form-control"
                                    onChange={this.cambioFrecuencia}
                                    value={this.state.frecuenciaSeleccionado.id}
                                    disabled={this.state.indRechazadoDesestimado}
                                >
                                    {this.state.frencuencias.map(function (data, key) {
                                        return (
                                            <option key={key} value={data.id}>
                                                {data.valor}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="row form-padding">
                            <div className="col-md-6">
                                <label>% Comisión de Formalización</label>
                                <input
                                    type="number"
                                    name="porcentajeComisionFormalizacion"
                                    min="0"
                                    max="100"
                                    step="any"
                                    className="form-control"
                                    value={this.state.porcentajeComisionFormalizacion}
                                    onChange={this.encargarseCambio}
                                    disabled={this.state.indRechazadoDesestimado}
                                    required
                                    placeholder="%"
                                />
                            </div>
                            <div className="col-md-6">
                                <label>% Comisión de Administración</label>
                                <input
                                    type="number"
                                    name="porcentajeComisionAdministracion"
                                    min="0"
                                    max="100"
                                    step="any"
                                    className="form-control"
                                    value={this.state.porcentajeComisionAdministracion}
                                    onChange={this.encargarseCambio}
                                    disabled={this.state.indRechazadoDesestimado}
                                    required
                                    placeholder="%"
                                />
                            </div>
                        </div>
                        <div className="row form-padding">
                            <div className="col">
                                <label>Estado</label>
                                <select
                                    className="form-control"
                                    onChange={this.cambioEstado}
                                    value={this.state.estadoSeleccionado.id}
                                    disabled={
                                        this.state.indRechazadoDesestimado || this.state.indEstados
                                    }
                                >
                                    {this.state.estados.map(function (data, key) {
                                        return (
                                            <option key={key} value={data.id}>
                                                {data.valor}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="btn btn-primary border-radius"
                            disabled={this.state.indButton}
                            hidden={
                                this.state.indRechazadoDesestimado || this.state.indMoraLegal
                            }
                        >
                            <span
                                id="guardando"
                                className="spinner-grow spinner-grow-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            <span id="guardando" className="sr-only">
                                Loading...
                            </span>
                            Guardar
                            {" "}
                            <i class="fa fa-edit" aria-hidden="true"></i>
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}
export default EditarProgramaCarteraPEComponent;
