import React from "react";
import HonramientoComponent from "../../Components/Honramiento/Honramiento";
const HonramientosView = () => {
  return (
    <div>
      <HonramientoComponent />
    </div>
  );
};

export default HonramientosView;
