import React from "react";
import {
  Button,
  Accordion,
  Card,
  Tabs,
  Tab,
  Row,
  Col,
  Nav,
} from "react-bootstrap";
import { Collapse } from "react-collapse";
import ReactWizard from "react-bootstrap-wizard";

const $ = require("jquery");

var i = 1;
class CrearAvalPruebaComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      abrir1: false,
      abrir2: false,
      key: "home",
      verticalKey: "first",
      selectedFile: null,
      name: "",
      date: "",
      status: "",
      lista: [
        {
          id: 1,
          name: "Diego Armando Lira Rodríguez",
          date: "10-10-1992",
          status: "Activo",
        },
        {
          id: 2,
          name: "Héctor Iván González Castaño",
          date: "21-10-1992",
          status: "Activo",
        },
      ],
    };
  }
  onFileChange = (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
  };
  toggle = () => {
    if (this.state.abrir1 == false) {
      this.setState({ abrir1: true });
    } else {
      this.setState({ abrir1: false });
    }
  };
  setKey = (k) => {
    this.setState({ key: k });
  };
  setVerticalKey = (k) => {
    this.setState({ verticalKey: k });
  };
  tab2 = () => {
    this.setState({ verticalKey: "second" });
  };
  addRow = (event) => {
    event.preventDefault();
    $("#addr" + i).html(
      "<td>" +
        (i + 1) +
        "</td><td><input name='name" +
        i +
        "' type='text' placeholder='Name' class='form-control input-md'  /> </td><td><input  name='mail" +
        i +
        "' type='text' placeholder='Mail'  class='form-control input-md'></td><td><input  name='mobile" +
        i +
        "' type='text' placeholder='Mobile'  class='form-control input-md'></td>"
    );

    $("#tab_logic").append('<tr id="addr' + (i + 1) + '"></tr>');
    i++;
  };
  addRowB = (event) => {
    event.preventDefault();
    $("#b" + i).html(
      "<td>" +
        (i + 1) +
        "</td><td><input name='name" +
        i +
        "' type='text' placeholder='Name' class='form-control input-md'  /> </td><td><input  name='mail" +
        i +
        "' type='text' placeholder='Mail'  class='form-control input-md'></td><td><input  name='mobile" +
        i +
        "' type='text' placeholder='Mobile'  class='form-control input-md'></td>"
    );

    $("#tab_logic_b").append('<tr id="b' + (i + 1) + '"></tr>');
    i++;
  };
  addRowC = (event) => {
    event.preventDefault();
    $("#c" + i).html(
      "<td>" +
        (i + 1) +
        "</td><td><input name='name" +
        i +
        "' type='text' placeholder='Name' class='form-control input-md'  /> </td><td><input  name='mail" +
        i +
        "' type='text' placeholder='Mail'  class='form-control input-md'></td><td><input  name='mobile" +
        i +
        "' type='text' placeholder='Mobile'  class='form-control input-md'></td>"
    );

    $("#tab_logic_c").append('<tr id="c' + (i + 1) + '"></tr>');
    i++;
  };
  encargarseCambio = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  deleteRow = (event) => {
    event.preventDefault();
    if (i > 1) {
      $("#addr" + (i - 1)).html("");
      i--;
    }
  };
  add = () => {
    if (this.state.name == "") {
      alert("Name required");
      return;
    }
    var last_element = this.state.lista[this.state.lista.length - 1];
    var nuevoId = last_element.id + 1;
    var nuevoRow = {
      id: nuevoId,
      name: this.state.name,
      date: this.state.date,
      status: this.state.status,
    };
    this.state.lista.push(nuevoRow);
    var actualizarLista = this.state.lista;
    this.setState({ lista: actualizarLista });
  };
  delete = (index) => {
    this.state.lista.splice(index, 1);
    var nuevaLista = this.state.lista;
    this.setState({ lista: nuevaLista });
  };
  render() {
    return (
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <Accordion defaultActiveKey="0">
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    Click me!
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>Hello! I'm the body</Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    Click me!
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <div class="container">
                      <div class="row clearfix">
                        <div class="col-md-12 column">
                          <table
                            class="table table-bordered table-hover"
                            id="tab_logic"
                          >
                            <thead>
                              <tr>
                                <th class="text-center">#</th>
                                <th class="text-center">Name</th>
                                <th class="text-center">Mail</th>
                                <th class="text-center">Mobile</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr id="addr0">
                                <td>1</td>
                                <td>
                                  <input
                                    type="text"
                                    name="name0"
                                    placeholder="Name"
                                    class="form-control"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name="mail0"
                                    placeholder="Mail"
                                    class="form-control"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name="mobile0"
                                    placeholder="Mobile"
                                    class="form-control"
                                  />
                                </td>
                              </tr>
                              <tr id="addr1"></tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <button
                        type="button"
                        onClick={this.addRow}
                        class="btn btn-warning pull-left"
                      >
                        Add Row
                      </button>
                      <button
                        type="button"
                        onClick={this.deleteRow}
                        class="pull-right btn btn-warning"
                      >
                        Delete Row
                      </button>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <hr />
            <Tabs
              activeKey={this.state.key}
              transition={false}
              onSelect={(k) => this.setKey(k)}
              id="noanim-tab-example"
            >
              <Tab eventKey="home" title="Home">
                <p>
                  Lo, as a careful housewife runs to catch One of her feather'd
                  creatures broke away, Sets down her babe, and makes all swift
                  dispatch In pursuit of the thing she would have stay; Whilst
                  her neglected child holds her in chase, Cries to catch her
                  whose busy care is bent To follow that which flies before her
                  face, Not prizing her poor infant's discontent; So runn'st
                  thou after that which flies from thee, Whilst I thy babe chase
                  thee afar behind;
                </p>
              </Tab>
              <Tab eventKey="profile" title="Profile">
                <div class="container">
                  <div class="row clearfix">
                    <div class="col-md-12 column">
                      <table
                        class="table table-bordered table-hover"
                        id="tab_logic_b"
                      >
                        <thead>
                          <tr>
                            <th class="text-center">#</th>
                            <th class="text-center">Name</th>
                            <th class="text-center">Mail</th>
                            <th class="text-center">Mobile</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr id="b0">
                            <td>1</td>
                            <td>
                              <input
                                type="text"
                                name="name0"
                                placeholder="Name"
                                class="form-control"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="mail0"
                                placeholder="Mail"
                                class="form-control"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="mobile0"
                                placeholder="Mobile"
                                class="form-control"
                              />
                            </td>
                          </tr>
                          <tr id="b1"></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={this.addRowB}
                    class="btn btn-warning pull-left"
                  >
                    Add Row
                  </button>
                  <button
                    type="button"
                    onClick={this.deleteRow}
                    class="pull-right btn btn-warning"
                  >
                    Delete Row
                  </button>
                </div>
              </Tab>
              <Tab eventKey="contact" title="Contact">
                <input type="file" onChange={this.onFileChange} />
              </Tab>
            </Tabs>
            <hr />
            <Tab.Container
              id="left-tabs-example"
              activeKey={this.state.verticalKey}
              onSelect={(k) => this.setVerticalKey(k)}
            >
              <Row>
                <Col sm={3}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Tab 1</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Tab 2</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <p>
                        Not prizing her poor infant's discontent; So runn'st
                        thou after that which flies from thee, Whilst I thy babe
                        chase thee afar behind;
                      </p>
                      <button onClick={this.tab2}>Tab2</button>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <div class="container">
                        <div class="row clearfix">
                          <div class="col-md-12 column">
                            <table
                              class="table table-bordered table-hover"
                              id="tab_logic-c"
                            >
                              <thead>
                                <tr>
                                  <th class="text-center">Name</th>
                                  <th class="text-center">Date</th>
                                  <th class="text-center">Status</th>
                                  <th class="text-center">Add</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr id="c0">
                                  <td>
                                    <input
                                      type="text"
                                      name="name"
                                      placeholder="Name"
                                      onChange={this.encargarseCambio}
                                      required
                                      value={this.state.name}
                                      class="form-control"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      name="date"
                                      placeholder="date"
                                      onChange={this.encargarseCambio}
                                      required
                                      value={this.state.date}
                                      class="form-control"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      name="status"
                                      placeholder="status"
                                      required
                                      onChange={this.encargarseCambio}
                                      value={this.state.status}
                                      class="form-control"
                                    />
                                  </td>
                                  <td>
                                    <button
                                      type="button"
                                      onClick={this.add}
                                      class="btn btn-warning pull-left"
                                    >
                                      <i
                                        class="fa fa-plus"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </td>
                                </tr>
                                <tr id="c1"></tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-md-12 column">
                            <table
                              class="table table-bordered table-hover"
                              id="tab_logic-c"
                            >
                              <thead>
                                <tr>
                                  <th class="text-center">#</th>
                                  <th class="text-center">Name</th>
                                  <th class="text-center">Date</th>
                                  <th class="text-center">Status</th>
                                  <th class="text-center">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.lista.map((c, index) => {
                                  return (
                                    <tr id={c.id}>
                                      <td>{c.id}</td>
                                      <td>{c.name}</td>
                                      <td>{c.date}</td>
                                      <td>{c.status}</td>
                                      <td>
                                        <a
                                          class="btn btn-primary btn-sm"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => this.delete(index)}
                                        >
                                          <i className="fa fa-minus-circle"></i>
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </div>
    );
  }
}

export default CrearAvalPruebaComponent;
