import React, { Component } from "react";
import {
    REACT_APP_API_URL,
    REACT_APP_CODE,
    REACT_APP_CODIGO_RESPUESTA_200,
    REACT_APP_CODIGO_RESPUESTA_401,
    REACT_APP_ROL_ADMINISTRATIVO,
    REACT_APP_ROL_CONTABILIDAD,
    REACT_APP_ROL_SEGUIMIENTO,
    REACT_APP_ROL_REPRESENTANTE_FODEMIPYME
} from "../../environment";
import swal from "sweetalert";
import { GenerarReporteGestionFODEMIPYMEExcel } from "../../Services/ReporteServices";
import { BorrarLocalStorage } from "../../Utils/BorrarInfoLocalStorage";
import { ObtenerUserAgent } from "../../Utils/BaseSolicitudModel";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import jszip from "jszip";
window.jszip = jszip;
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const $ = require("jquery");

$.DataTable = require("datatables.net-bs4");
require("datatables.net-responsive");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.print");
const userAgent = ObtenerUserAgent();
export default class ReporteGestionFODEMIPYMEComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            datatable: null,
            token: null,
            mostrarDescargarReporte: false,
        };
    }
    /**
     * Llamado inmediatamente después de montar un componente. 
     * Establecer el estado aquí activará la re-renderización.
     **/
    componentDidMount() {
        /**
         * Obtener el token de sesion
         **/
        const tokenContent = localStorage.getItem("isLoggedIn");
        if (!tokenContent) window.location.href = `https://${window.location.host}/seguridad/logout-b2c`
        this.setState({ token: tokenContent });
        /**
         * Creacion del DataTable
         **/
        $('#tabla tfoot th').each(function () {
            var title = $(this).text();
            $(this).html('<input type="text" class="columnaFiltroTabla" id="' + title + '" placeholder="Filtrar ' + title + '" />');
        });
        $("#tabla").DataTable({
            lengthChange: true,
            dom: "lBfrtip",
            buttons: [],
            processing: true,
            serverSide: true,
            language: {
                searchPlaceholder: "Buscar...",
                url: "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json",
            },
            initComplete: function () {
                // Apply the search
                this.api()
                    .columns()
                    .every(function () {
                        var that = this;
                        $('.columnaFiltroTabla', this.footer()).on('change clear', function () {
                            if (that.search() !== this.value) {
                                that.search(this.value).draw();
                            }
                        });

                        var table = $('#tabla').DataTable();
                        $(".dataTables_filter input")
                            .off()
                            .on('change clear', function (e) {
                                if (table.search() !== this.value) {
                                    table.search(this.value)
                                        .draw();
                                }
                            });

                    });
            },
            ajax: {
                url: `/api/ReporteGestionFodemipyme?fondo=${localStorage.getItem("idFondo")}&ip=${localStorage.getItem("ip")}&latitud=${localStorage.getItem("latitud")}&longitud=${localStorage.getItem("longitud")}&userAgent=${userAgent}&usuario=${localStorage.getItem("idUsuarioCreador")}`,
                error: function (xhr, error, thrown) {
                    if (xhr.status == REACT_APP_CODIGO_RESPUESTA_401) {
                        swal({
                            title: "¡Sesión Expiró!",
                            text: "Por favor vuelva a iniciar sesión",
                            icon: "warning",
                            button: "Aceptar",
                        }).then((value) => {
                            BorrarLocalStorage();
                            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`
                        });

                        return;
                    }
                    if (xhr.status != REACT_APP_CODIGO_RESPUESTA_200) {
                        swal(
                            "Error al obtener el reporte gestión FODEMIPYME",
                            thrown,
                            "error", { button: "Aceptar", }
                        );
                    }
                },
                type: "POST",
            },
            columns: [
                {
                    data: "nombreEntidadFinancieraAutorizada",
                    name: "nombreEntidadFinancieraAutorizada",
                },
                {
                    data: "cedulaEntidadFinancieraAutorizada",
                    name: "cedulaEntidadFinancieraAutorizada",
                },
                { data: "cantidadHonramientos", name: "cantidadHonramientos" },
                {
                    data: "porcentajeSiniestralidad",
                    render: function (porcentajeSiniestralidad) {
                        return porcentajeSiniestralidad.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    },
                    name: "porcentajeSiniestralidad"
                },
                { data: "moneda", name: "moneda" },
                {
                    data: "monto",
                    render: function (monto) {
                        return monto.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    },
                    name: "monto"
                },
                { data: "sectorEconomico", name: "sectorEconomico" },
                { data: "programa", name: "programa" },
            ],
        });
    }
    /**
     * 
     * @param {*} event 
     * @returns Generar el documento de excel. 
     **/
    generarReporte = (event) => {
        var data = {
            EntidadFinanciera: document.getElementById("Nombre Entidad Financiera Autorizada").value,
            CedulaEntidadFinancieraAutorizada: document.getElementById("Cédula Entidad Financiera Autorizada").value,
            CantidadHonramientos: document.getElementById("Cantidad Honramientos").value,
            Porcentaje: document.getElementById("Porcentaje").value,
            Moneda: document.getElementById("Moneda").value,
            Monto: document.getElementById("Monto").value,
            SectorEconomico: document.getElementById("Sector Económico").value,
            Programa: document.getElementById("Programa").value,
            SearchValue: document.getElementById("tabla_filter").children[0].children[0].value
        };
        GenerarReporteGestionFODEMIPYMEExcel(data, (estado) => {
            switch (estado) {
                case 0:
                    this.setState({ mostrarDescargarReporte: true });
                    break;
                case 1:
                    this.setState({ mostrarDescargarReporte: false });
                    break;
                case 2:
                    this.setState({ mostrarDescargarReporte: false });
                    swal(
                        "Reporte Gestión FODEMIPYME",
                        "Ha ocurrrido un error inesperado, por favor intente de nuevo",
                        "error", { button: "Aceptar", }
                    );
                    break;
            }
        });
    };
    render() {
        const idRol = localStorage.getItem("idRol");
        if (idRol) {
            if (idRol != REACT_APP_ROL_ADMINISTRATIVO && idRol != REACT_APP_ROL_CONTABILIDAD && idRol != REACT_APP_ROL_SEGUIMIENTO && idRol != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME)
                window.location.href = "/mantenimiento-reportes";
        } else {
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`
        }
        switch (idRol) {
            //REACT_APP_ROL_ADMINISTRATIVO
            case "1":
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Reporte Gestión FODEMIPYME</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/mantenimiento-reportes">Reportes</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Reporte Gestión FODEMIPYME
                                </li>
                            </ol>
                        </nav>
                        <nav className="navbar navbar-light">
                            <button type="button" className="btn btn-success border-radius" onClick={this.generarReporte} disabled={this.state.mostrarDescargarReporte}>
                                <i className="fa fa-file-excel-o" aria-hidden="true"></i>{" "}
                                Exportar Excel {" "}
                                <i className="fa fa-refresh fa-spin fa-fw" hidden={!this.state.mostrarDescargarReporte}></i>
                                <span className="sr-only">Loading...</span>
                            </button>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Nombre Entidad Financiera Autorizada</th>
                                        <th>Cédula Entidad Financiera Autorizada</th>
                                        <th>Cantidad Honramientos</th>
                                        <th>Porcentaje</th>
                                        <th>Moneda</th>
                                        <th>Monto</th>
                                        <th>Sector Económico</th>
                                        <th>Programa</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th>Nombre Entidad Financiera Autorizada</th>
                                        <th>Cédula Entidad Financiera Autorizada</th>
                                        <th>Cantidad Honramientos</th>
                                        <th>Porcentaje</th>
                                        <th>Moneda</th>
                                        <th>Monto</th>
                                        <th>Sector Económico</th>
                                        <th>Programa</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                );
            //REACT_APP_ROL_CONTABILIDAD
            case "6": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Reporte Gestión FODEMIPYME</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/mantenimiento-reportes">Reportes</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Reporte Gestión FODEMIPYME
                                </li>
                            </ol>
                        </nav>
                        <nav className="navbar navbar-light">
                            <button type="button" className="btn btn-success border-radius" onClick={this.generarReporte} disabled={this.state.mostrarDescargarReporte}>
                                <i className="fa fa-file-excel-o" aria-hidden="true"></i>{" "}
                                Exportar Excel {" "}
                                <i className="fa fa-refresh fa-spin fa-fw" hidden={!this.state.mostrarDescargarReporte}></i>
                                <span className="sr-only">Loading...</span>
                            </button>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Nombre Entidad Financiera Autorizada</th>
                                        <th>Cédula Entidad Financiera Autorizada</th>
                                        <th>Cantidad Honramientos</th>
                                        <th>Porcentaje</th>
                                        <th>Moneda</th>
                                        <th>Monto</th>
                                        <th>Sector Económico</th>
                                        <th>Programa</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th>Nombre Entidad Financiera Autorizada</th>
                                        <th>Cédula Entidad Financiera Autorizada</th>
                                        <th>Cantidad Honramientos</th>
                                        <th>Porcentaje</th>
                                        <th>Moneda</th>
                                        <th>Monto</th>
                                        <th>Sector Económico</th>
                                        <th>Programa</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_SEGUIMIENTO
            case "7": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Reporte Gestión FODEMIPYME</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/mantenimiento-reportes">Reportes</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Reporte Gestión FODEMIPYME
                                </li>
                            </ol>
                        </nav>
                        <nav className="navbar navbar-light">
                            <button type="button" className="btn btn-success border-radius" onClick={this.generarReporte} disabled={this.state.mostrarDescargarReporte}>
                                <i className="fa fa-file-excel-o" aria-hidden="true"></i>{" "}
                                Exportar Excel {" "}
                                <i className="fa fa-refresh fa-spin fa-fw" hidden={!this.state.mostrarDescargarReporte}></i>
                                <span className="sr-only">Loading...</span>
                            </button>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Nombre Entidad Financiera Autorizada</th>
                                        <th>Cédula Entidad Financiera Autorizada</th>
                                        <th>Cantidad Honramientos</th>
                                        <th>Porcentaje</th>
                                        <th>Moneda</th>
                                        <th>Monto</th>
                                        <th>Sector Económico</th>
                                        <th>Programa</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th>Nombre Entidad Financiera Autorizada</th>
                                        <th>Cédula Entidad Financiera Autorizada</th>
                                        <th>Cantidad Honramientos</th>
                                        <th>Porcentaje</th>
                                        <th>Moneda</th>
                                        <th>Monto</th>
                                        <th>Sector Económico</th>
                                        <th>Programa</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_REPRESENTANTE_FODEMIPYME
            case "8":
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Reporte Gestión FODEMIPYME</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/mantenimiento-reportes">Reportes</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Reporte Gestión FODEMIPYME
                                </li>
                            </ol>
                        </nav>
                        <nav className="navbar navbar-light">
                            <button type="button" className="btn btn-success border-radius" onClick={this.generarReporte} disabled={this.state.mostrarDescargarReporte}>
                                <i className="fa fa-file-excel-o" aria-hidden="true"></i>{" "}
                                Exportar Excel {" "}
                                <i className="fa fa-refresh fa-spin fa-fw" hidden={!this.state.mostrarDescargarReporte}></i>
                                <span className="sr-only">Loading...</span>
                            </button>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Nombre Entidad Financiera Autorizada</th>
                                        <th>Cédula Entidad Financiera Autorizada</th>
                                        <th>Cantidad Honramientos</th>
                                        <th>Porcentaje</th>
                                        <th>Moneda</th>
                                        <th>Monto</th>
                                        <th>Sector Económico</th>
                                        <th>Programa</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th>Nombre Entidad Financiera Autorizada</th>
                                        <th>Cédula Entidad Financiera Autorizada</th>
                                        <th>Cantidad Honramientos</th>
                                        <th>Porcentaje</th>
                                        <th>Moneda</th>
                                        <th>Monto</th>
                                        <th>Sector Económico</th>
                                        <th>Programa</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                );
        }
    }
}
