import React, { Component } from "react";
import {
    REACT_APP_ROL_OPERATIVO,
    REACT_APP_ROL_JEFATURA,
    REACT_APP_ROL_CONTABILIDAD
} from "../../environment";
class MantenimientoProgramasComponent extends Component {
    componentDidMount() {
        const tokenContent = localStorage.getItem("isLoggedIn");
        if (!tokenContent) {
            window.location.href = "/";
        }
    }
    irProgramasIndividual = () => {
        window.location.href = "/programas-individuales";
    };
    irProgramaCartera = () => {
        window.location.href = "/programas-carteras";
    };
    irProgramaPerdidaEsperada = () => {
        window.location.href = "/programas-perdidas";
    };
    render() {
        const idRol = localStorage.getItem("idRol");
        if (idRol) {
            if (idRol == REACT_APP_ROL_OPERATIVO && idRol == REACT_APP_ROL_JEFATURA && idRol == REACT_APP_ROL_CONTABILIDAD)
                window.location.href = "/mantenimiento";
        } else {
            window.location.href = "/";
        }
        return (
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a href="/admin">Inicio</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            <a href="/mantenimiento">Mantenimientos</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Mantenimiento Programas
                        </li>
                    </ol>
                </nav>
                <div class="row">
                    <div class="col-lg-4 col-sm-6 mt-4">
                        <div
                            class="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                            onClick={this.irProgramasIndividual}
                            style={{ cursor: "pointer" }}
                        >
                            <div class="card-body">
                                <i
                                    class="fa fa-fw fa-product-hunt"
                                    style={{ fontSize: "35px" }}
                                    aria-hidden="true"
                                ></i>
                            </div>
                            <div class="px-4 pb-2">
                                <h5>Programas Individuales</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 mt-4">
                        <div
                            class="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                            onClick={this.irProgramaCartera}
                            style={{ cursor: "pointer" }}
                        >
                            <div class="card-body">
                                <i
                                    class="fa fa-fw fa-product-hunt"
                                    style={{ fontSize: "35px" }}
                                    aria-hidden="true"
                                ></i>
                            </div>
                            <div class="px-4 pb-2">
                                <h5>Programas de Cartera</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 mt-4">
                        <div
                            class="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                            onClick={this.irProgramaPerdidaEsperada}
                            style={{ cursor: "pointer" }}
                        >
                            <div class="card-body">
                                <i
                                    class="fa fa-fw fa-product-hunt"
                                    style={{ fontSize: "35px" }}
                                    aria-hidden="true"
                                ></i>
                            </div>
                            <div class="px-4 pb-2">
                                <h5>Programas Pérdida Esperada</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default MantenimientoProgramasComponent;
