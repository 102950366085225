import React from 'react'
import CrearUsuarioComponent from '../../Components/Usuario/CrearUsuario'

const CrearUsuarioView = () => {
  return (
      <div>
          <CrearUsuarioComponent/>
      </div>
  )
}

export default CrearUsuarioView   