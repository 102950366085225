import React from 'react'
import UsuarioComponent from '../../Components/Usuario/Usuario'

const UsuariosView = () => {
  return (
      <div>
          <UsuarioComponent/>
      </div>
  )
}

export default UsuariosView   