import React from 'react'
import ProgramaCarteraComponent from '../../Components/ProgramaCartera/ProgramaCartera'
const ProgramaCarteraView = () => {
  return (
    <div>
      <ProgramaCarteraComponent/>
    </div>
  )
}

export default ProgramaCarteraView    