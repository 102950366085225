import { REACT_APP_CODIGO_RESPUESTA_401 } from "../environment";
import { BorrarLocalStorage } from "../Utils/BorrarInfoLocalStorage";
import swal from "sweetalert";

export const POST = async (metodo, request) => {
    try {
        var data = {
            B2CIds: request.B2CIds
        };
        const respuestaPost = await fetch(
            `/api/${metodo}?fondo=${localStorage.getItem("idFondo")}`,
            {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        );
        if (respuestaPost.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
            }).then(() => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        const respuesta = {
            codigo: 0,
        };
        respuesta.codigo = respuestaPost.status;

        return respuesta;
    } catch (error) {
        throw error;
    }
};