import React from 'react'
import { BorrarLocalStorage } from "../Utils/BorrarInfoLocalStorage";
const NotFound = () => {
    function eventoCambioValor(e) {
        e.preventDefault();
        localStorage.clear();
        BorrarLocalStorage();
        window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
    }
    return (
        <div class="page-wrap d-flex flex-row align-items-center">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-12 text-center">
                        <span class="display-1 d-block">404</span>
                        <div class="mb-4 lead">No se encontró la página que busca.</div>
                        <div class="mb-4 lead">Fodempyme le informa, que su usuario no ha iniciado sesión, para hacer login de clic en el siguiente botón.</div>
                        <div class="mb-4 lead">
                            <button
                                type="button"
                                onClick={eventoCambioValor}
                                className="btn btn-gris"
                            >
                                <i className="fa fa-sign-in" aria-hidden="true"></i>  Iniciar Sesión
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound