import React from "react";
import AvalComponent from "../../Components/Aval/Aval";

const AvalesView = () => {
  return (
    <div>
      <AvalComponent />
    </div>
  );
};

export default AvalesView;
