import { ObtenerUserAgent } from "../Utils/BaseSolicitudModel";
export const GenerarReporteGeneralAvalesExcel = async (request, callbackEstado) => {
    let userAgent = ObtenerUserAgent();
    try {
        callbackEstado(0);
        const respuesta = await fetch(
            `/api/GenerarReporteGeneralAvalesExcel?FechaInicio=${request.FechaInicio}&FechaFin=${request.FechaFin}&Id=${request.Id}&NombreEntidadFinanciera=${request.NombreEntidadFinanciera}&Naturaleza=${request.Naturaleza}&TipoPrograma=${request.TipoPrograma}&IdPrograma=${request.IdPrograma}&NombreBeneficiario=${request.NombreBeneficiario}&CedulaBeneficiario=${request.CedulaBeneficiario}&Moneda=${request.Moneda}&Estado=${request.Estado}&MontoCredito=${request.MontoCredito}&MontoAval=${request.MontoAval}&Morosidad=${request.Morosidad}&PorcentajeAval=${request.PorcentajeAval}&CodigoCIIU=${request.CodigoCIIU}&UsuarioCreador=${request.UsuarioCreador}&PorcentajeComisionAdministración=${request.PorcentajeComisionAdministración}&PorcentajeComisionFormalizacion=${request.PorcentajeComisionFormalizacion}&FechaCreacion=${request.FechaCreacion}&FechaFormalizacion=${request.FechaFormalizacion}&FechaHonramiento=${request.FechaHonramiento}&SearchValue=${request.SearchValue}&fondo=${localStorage.getItem("idFondo")}&ip=${localStorage.getItem("ip")}&latitud=${localStorage.getItem("latitud")}&longitud=${localStorage.getItem("longitud")}&userAgent=${userAgent}&usuario=${localStorage.getItem("idUsuarioCreador")}`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
            .then((response) => {
                if (response.status == 200) {
                    callbackEstado(1);
                    return response.blob()
                } else {
                    callbackEstado(2);
                    throw new Error("Error Excel");
                }
            })
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `ReporteGeneralAvales.xlsx`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            }).
            catch((error) => {
                throw error;
            });
    } catch (error) {
        throw error;
    }
};
export const GenerarReporteCualitativoExcel = async (request, callbackEstado) => {
    let userAgent = ObtenerUserAgent();
    try {
        callbackEstado(0);
        const respuesta = await fetch(
            `/api/GenerarReporteCualitativoExcel?NombreEntidadFinanciera=${request.NombreEntidadFinanciera}&CedulaEntidadFinancieraAutorizada=${request.CedulaEntidadFinancieraAutorizada}&TipoSolicitud=${request.TipoSolicitud}&TipoIdentificacionSolicitante=${request.TipoIdentificacionSolicitante}&TamanoEmpresa=${request.TamanoEmpresa}&TotalHombres=${request.TotalHombres}&TotalMujeres=${request.TotalMujeres}&Estado=${request.Estado}&SectorEconomico=${request.SectorEconomico}&Actividad=${request.Actividad}&Provincia=${request.Provincia}&Canton=${request.Canton}&SearchValue=${request.SearchValue}&fondo=${localStorage.getItem("idFondo")}&ip=${localStorage.getItem("ip")}&latitud=${localStorage.getItem("latitud")}&longitud=${localStorage.getItem("longitud")}&userAgent=${userAgent}&usuario=${localStorage.getItem("idUsuarioCreador")}`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
            .then((response) => {
                if (response.status == 200) {
                    callbackEstado(1);
                    return response.blob()
                } else {
                    callbackEstado(2);
                    throw new Error("Error Excel");
                }
            })
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `ReporteCualitativo.xlsx`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            }).
            catch((error) => {
                throw error;
            });
    } catch (error) {
        throw error;
    }
};
export const GenerarReporteLineasCreditoExcel = async (request, callbackEstado) => {
    let userAgent = ObtenerUserAgent();
    try {
        callbackEstado(0);
        const respuesta = await fetch(
            `/api/GenerarReporteLineasCreditoExcel?FechaInicio=${request.FechaInicio}&FechaFin=${request.FechaFin}&EntidadFinanciera=${request.EntidadFinanciera}&EstadoEntidadFinanciera=${request.EstadoEntidadFinanciera}&Beneficiario=${request.Beneficiario}&CedulaBeneficiario=${request.CedulaBeneficiario}&TipoSolicitud=${request.TipoSolicitud}&IDLíneaCredito=${request.IDLíneaCredito}&EstadoLineaCredito=${request.EstadoLineaCredito}&IDSubCredito=${request.IDSubCredito}&EstadoSubCredito=${request.EstadoSubCredito}&FechaCreacion=${request.FechaCreacion}&FechaFormalizacion=${request.FechaFormalizacion}&FechaHonramiento=${request.FechaHonramiento}&SearchValue=${request.SearchValue}&fondo=${localStorage.getItem("idFondo")}&ip=${localStorage.getItem("ip")}&latitud=${localStorage.getItem("latitud")}&longitud=${localStorage.getItem("longitud")}&userAgent=${userAgent}&usuario=${localStorage.getItem("idUsuarioCreador")}`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
            .then((response) => {
                if (response.status == 200) {
                    callbackEstado(1);
                    return response.blob()
                } else {
                    callbackEstado(2);
                    throw new Error("Error Excel");
                }
            })
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `ReporteLineasCredito.xlsx`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            }).
            catch((error) => {
                throw error;
            });
    } catch (error) {
        throw error;
    }
};
export const GenerarReporteProvisionesExcel = async (request, callbackEstado) => {
    let userAgent = ObtenerUserAgent();
    try {
        callbackEstado(0);
        const respuesta = await fetch(
            `/api/GenerarReporteProvisionesExcel?FechaInicio=${request.FechaInicio}&FechaFin=${request.FechaFin}&EntidadFinanciera=${request.EntidadFinanciera}&Programa=${request.Programa}&NombreBeneficiario=${request.NombreBeneficiario}&CedulaBeneficiario=${request.CedulaBeneficiario}&EstadoCredito=${request.EstadoCredito}&Moneda=${request.Moneda}&SaldoAvalado=${request.SaldoAvalado}&DiasAtraso=${request.DiasAtraso}&MontoEstimacion=${request.MontoEstimacion}&FechaCreacion=${request.FechaCreacion}&FechaFormalizacion=${request.FechaFormalizacion}&FechaHonramiento=${request.FechaHonramiento}&SearchValue=${request.SearchValue}&fondo=${localStorage.getItem("idFondo")}&ip=${localStorage.getItem("ip")}&latitud=${localStorage.getItem("latitud")}&longitud=${localStorage.getItem("longitud")}&userAgent=${userAgent}&usuario=${localStorage.getItem("idUsuarioCreador")}`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
            .then((response) => {
                if (response.status == 200) {
                    callbackEstado(1);
                    return response.blob()
                } else {
                    callbackEstado(2);
                    throw new Error("Error Excel");
                }
            })
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `ReporteProvisiones.xlsx`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            }).
            catch((error) => {
                throw error;
            });
    } catch (error) {
        throw error;
    }
};
export const GenerarReporteHonramientosExcel = async (request, callbackEstado) => {
    let userAgent = ObtenerUserAgent();
    try {
        callbackEstado(0);
        const respuesta = await fetch(
            `/api/GenerarReporteHonramientosExcel?FechaInicio=${request.FechaInicio}&FechaFin=${request.FechaFin}&EntidadFinanciera=${request.EntidadFinanciera}&CedulaEntidadFinancieraAutorizada=${request.CedulaEntidadFinancieraAutorizada}&CedulaBeneficiario=${request.CedulaBeneficiario}&NombreBeneficiario=${request.NombreBeneficiario}&Estado=${request.Estado}&Programa=${request.Programa}&FechaHonramiento=${request.FechaHonramiento}&SearchValue=${request.SearchValue}&fondo=${localStorage.getItem("idFondo")}&ip=${localStorage.getItem("ip")}&latitud=${localStorage.getItem("latitud")}&longitud=${localStorage.getItem("longitud")}&userAgent=${userAgent}&usuario=${localStorage.getItem("idUsuarioCreador")}`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
            .then((response) => {
                if (response.status == 200) {
                    callbackEstado(1);
                    return response.blob()
                } else {
                    callbackEstado(2);
                    throw new Error("Error Excel");
                }
            })
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `ReporteHonramientos.xlsx`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            }).
            catch((error) => {
                throw error;
            });
    } catch (error) {
        throw error;
    }
};
export const GenerarReporteGestionFODEMIPYMEExcel = async (request, callbackEstado) => {
    let userAgent = ObtenerUserAgent();
    try {
        callbackEstado(0);
        const respuesta = await fetch(
            `/api/GenerarReporteGestionFODEMIPYMEExcel?EntidadFinanciera=${request.EntidadFinanciera}&CedulaEntidadFinancieraAutorizada=${request.CedulaEntidadFinancieraAutorizada}&CantidadHonramientos=${request.CantidadHonramientos}&Porcentaje=${request.Porcentaje}&Moneda=${request.Moneda}&Monto=${request.Monto}&SectorEconomico=${request.SectorEconomico}&Programa=${request.Programa}&SearchValue=${request.SearchValue}&fondo=${localStorage.getItem("idFondo")}&ip=${localStorage.getItem("ip")}&latitud=${localStorage.getItem("latitud")}&longitud=${localStorage.getItem("longitud")}&userAgent=${userAgent}&usuario=${localStorage.getItem("idUsuarioCreador")}`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
            .then((response) => {
                if (response.status == 200) {
                    callbackEstado(1);
                    return response.blob()
                } else {
                    callbackEstado(2);
                    throw new Error("Error Excel");
                }
            })
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `ReporteGestionFODEMIPYME.xlsx`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            }).
            catch((error) => {
                throw error;
            });
    } catch (error) {
        throw error;
    }
};