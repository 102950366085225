import React, { Component } from "react";
import {
    REACT_APP_ROL_ADMINISTRATIVO,
    REACT_APP_ROL_EJECUTIVO,
    REACT_APP_ROL_SEGUIMIENTO,
    REACT_APP_ROL_CONTABILIDAD,
    REACT_APP_ROL_REPRESENTANTE_FODEMIPYME,
    REACT_APP_CODIGO_RESPUESTA_200,
    REACT_APP_CODIGO_RESPUESTA_401
} from "../../environment";
import { BorrarLocalStorage } from "../../Utils/BorrarInfoLocalStorage";
import { ObtenerUserAgent } from "../../Utils/BaseSolicitudModel";
import swal from "sweetalert";
import ReactDOM from "react-dom";

const $ = require("jquery");
$.DataTable = require("datatables.net-bs4");
/**
 * Obtener el Fondo seleccionado en iniciar sesion
 */
const fondoSeleccionado = JSON.parse(localStorage.getItem("fondoSeleccionado"));
export default class ClienteComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            indUbicacion: false,
        };
    }
    /**
   * Llamado inmediatamente después de montar un componente. 
   * Establecer el estado aquí activará la re-renderización.
   */
    componentDidMount() {
        const tokenContent = localStorage.getItem("isLoggedIn");
        const idRol = localStorage.getItem("idRol");
        if (!tokenContent) {
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        }
        const userAgent = ObtenerUserAgent();
        sessionStorage.removeItem("infoClienteEditar");
        
        /**
         * Definicion de las columnas 
         */
        let columnas = [
            { data: "id", name: "id" },
            { data: "nombreOperadora", name: "nombreOperadora" },
            { data: "cedulaJuridica", name: "cedulaJuridica" },
            {
                data: "direccion",
                render: function (row, type, val, meta) {
                    var filtro = [];
                    var idFondo = localStorage.getItem("idFondo");
                    filtro = val.caracteristicas.filter(function (caracteristicas) {
                        return (
                            caracteristicas.fondo.id == idFondo
                        );
                    });
                    return filtro.length > 0 ? filtro[0].direccion : "";
                },
                name: "direccion"
            },
            {
                data: "nombreRepresentante",
                render: function (row, type, val, meta) {
                    var filtro = [];
                    var idFondo = localStorage.getItem("idFondo");
                    filtro = val.caracteristicas.filter(function (caracteristicas) {
                        return (
                            caracteristicas.fondo.id == idFondo
                        );
                    });
                    return filtro.length > 0 ? filtro[0].nombreRepresentante : "";
                },
                name: "nombreRepresentante"
            },
            {
                data: "correosAdministrativos",
                render: function (row, type, val, meta) {
                    var filtro = [];
                    var idFondo = localStorage.getItem("idFondo");
                    filtro = val.caracteristicas.filter(function (caracteristicas) {
                        return (
                            caracteristicas.fondo.id == idFondo
                        );
                    });
                    return filtro.length > 0 ? filtro[0].correosAdministrativos : "";
                },
                name: "correosAdministrativos"
            },
            {
                data: "estado.valor",
                render: function (row, type, val, meta) {
                    var filtro = [];
                    var idFondo = localStorage.getItem("idFondo");
                    filtro = val.caracteristicas.filter(function (caracteristicas) {
                        return (
                            caracteristicas.fondo.id == idFondo
                        );
                    });
                    return filtro.length > 0 ? filtro[0].estado.valor : "";
                },
                name: "estado.valor"
            },
        ];
        let definicionColumnas = [];
        if (idRol == REACT_APP_ROL_ADMINISTRATIVO || idRol == REACT_APP_ROL_EJECUTIVO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME) {
            columnas.push({ data: null, name: "Acciones" });
            definicionColumnas.push({
                targets: 7,
                orderable: false,
                createdCell: (td, cellData, rowData, row, col) =>
                    ReactDOM.render(
                        <a
                            className="btn btn-primary btn-sm border-radius"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.editar(rowData)}
                        >
                            <i className="fa fa-pencil"></i>
                        </a>,
                        td
                    ),
            });
        }
        /**
         * Creacion del DataTable
         */
        $("#tabla").DataTable({
            processing: true,
            serverSide: true,
            language: {
                searchPlaceholder: "Buscar...",
                url: "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json",
            },
            initComplete: function () {
                this.api()
                    .column()
                    .each(function () {
                        var table = $('#tabla').DataTable();
                        $(".dataTables_filter input")
                            .off()
                            .on('change clear', function (e) {
                                if (table.search() !== this.value) {
                                    table.search(this.value)
                                        .draw();
                                }
                            });
                    });
            },
            ajax: {
                url: `/api/ObtenerClientes?fondo=${localStorage.getItem("idFondo")}&ip=${localStorage.getItem("ip")}&latitud=${localStorage.getItem("latitud")}&longitud=${localStorage.getItem("longitud")}&userAgent=${userAgent}&usuario=${localStorage.getItem("idUsuarioCreador")}`,
                error: function (xhr, error, thrown) {
                    if (xhr.status == REACT_APP_CODIGO_RESPUESTA_401) {
                        swal({
                            title: "¡Sesión Expiró!",
                            text: "Por favor vuelva a iniciar sesión",
                            icon: "warning",
                            button: "Aceptar",
                        }).then((value) => {
                            BorrarLocalStorage();
                            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                        });
                        return;
                    }
                    if (xhr.status != REACT_APP_CODIGO_RESPUESTA_200) {
                        swal("Error al obtener los clientes", xhr.responseJSON.mensaje, "error", { button: "Aceptar", });
                        return;
                    }
                },
                type: "POST",
            },
            columns: columnas,
            columnDefs: definicionColumnas,
        });
    }
    /**
     * 
     * @param {*} columna 
     * @returns Redirecciona a la vista de Editar Cliente
     */
    editar = (columna) => {
        sessionStorage.setItem("infoClienteEditar", JSON.stringify(columna));
        window.location.href = "cliente/" + columna.id;
    };
    /**
     * Se llama inmediatamente antes de que se destruya un componente. 
     * Realice cualquier limpieza necesaria en este método, como solicitudes de red canceladas o la limpieza de cualquier elemento DOM creado en componentDidMount.
     */
    componentWillUnmount() {
        $(".data-table-wrapper").find("table").DataTable().destroy(true);
    }
    /**
     * 
     * @returns Si se devuelve falso, no se llamará a Component#render, componentWillUpdate y componentDidUpdate.
     */
    shouldComponentUpdate() {
        return false;
    }
    /**
     * 
     * @returns Redirecciona a la vista de Crear Cliente
     */
    crearCliente = () => {
        window.location.href = "cliente/crear";
    };
    render() {
        const idRol = localStorage.getItem("idRol");
        if (idRol) {
            if (idRol != REACT_APP_ROL_ADMINISTRATIVO &&
                idRol != REACT_APP_ROL_EJECUTIVO &&
                idRol != REACT_APP_ROL_SEGUIMIENTO &&
                idRol != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME)
                window.location.href = "/mantenimiento";
        } else {
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        }
        switch (idRol) {
            // REACT_APP_ROL_ADMINISTRATIVO
            case "1": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Clientes</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <a href="/mantenimiento">Mantenimientos</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Clientes
                                </li>
                            </ol>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Nombre Operadora</th>
                                        <th>Cédula Jurídica</th>
                                        <th>Dirección</th>
                                        <th>Representante</th>
                                        <th>Correos Electrónicos</th>
                                        <th>Estado</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            }
            // REACT_APP_ROL_EJECUTIVO
            case "2": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Clientes</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <a href="/mantenimiento">Mantenimientos</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Clientes
                                </li>
                            </ol>
                        </nav>
                        <nav className="navbar navbar-light">
                            <button
                                type="button"
                                onClick={this.crearCliente}
                                className="btn btn-gris"
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i> Nuevo Cliente{" "}
                            </button>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Nombre Operadora</th>
                                        <th>Cédula Jurídica</th>
                                        <th>Dirección</th>
                                        <th>Representante</th>
                                        <th>Correos Electrónicos</th>
                                        <th>Estado</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            }
            // REACT_APP_ROL_SEGUIMIENTO
            case "7": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Clientes</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <a href="/mantenimiento">Mantenimientos</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Clientes
                                </li>
                            </ol>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Nombre Operadora</th>
                                        <th>Cédula Jurídica</th>
                                        <th>Dirección</th>
                                        <th>Representante</th>
                                        <th>Correos Electrónicos</th>
                                        <th>Estado</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            }
            // REACT_APP_ROL_REPRESENTANTE_FODEMIPYME
            case "8": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Clientes</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <a href="/mantenimiento">Mantenimientos</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Clientes
                                </li>
                            </ol>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Nombre Operadora</th>
                                        <th>Cédula Jurídica</th>
                                        <th>Dirección</th>
                                        <th>Representante</th>
                                        <th>Correos Electrónicos</th>
                                        <th>Estado</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            }
        }
    }
}
