import React from 'react'
import ProgramaIndividualComponent from '../../Components/ProgramaIndividual/ProgramaIndividual'

const ProgramaIndividualView = () => {
  return (
    <div>
      <ProgramaIndividualComponent/>
    </div>
  )
}

export default ProgramaIndividualView   