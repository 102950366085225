import React, { Component } from "react";
import { CrearHornamiento } from "../../Services/HonramientoService";
import { REACT_APP_API_URL, REACT_APP_CODE } from "../../environment";
import swal from "sweetalert";

const $ = require("jquery");
$.DataTable = require("datatables.net-bs4");

const tokenContent = localStorage.getItem("id_token");
const fondoSeleccionado = JSON.parse(localStorage.getItem("fondoSeleccionado"));
export default class CrearHonramientoComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avalesLista: [],
      avalSeleccionado: "",
      montoHonramiento: 0,
      indButton: false,
    };
  }
  componentDidMount() {
    $("#guardando").hide();
    const tokenContent = localStorage.getItem("id_token");
    if (!tokenContent) {
      window.location.href = "/";
    }
  }
  render() {
    return (
      <div class="container text-center padding-top">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/honramientos">Honramientos</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Crear Honramiento
            </li>
          </ol>
        </nav>
      </div>
    );
  }
}
