import {
    REACT_APP_CODIGO_RESPUESTA_200,
    REACT_APP_CODIGO_RESPUESTA_401
} from "../environment";
import { BorrarLocalStorage } from "../Utils/BorrarInfoLocalStorage";
import swal from "sweetalert";
/**
 * Obtener el Fondo seleccionado en iniciar sesion
 **/
const fondoSeleccionado = JSON.parse(localStorage.getItem("fondoSeleccionado"));
export const obtenerLogs = async (nombreEntidad, idEntidad, idUsuario, nombreUsuario, cedulaUsuario, correoUsuario, fechaDesde, fechaHasta) => {
    try {
        var idFondo = localStorage.getItem("idFondo");
        const respuesta = await fetch(
            `api/ObtenerLogs?fondo=${idFondo}&entidad=${nombreEntidad}&idEntidad=${idEntidad}&idUsuario=${idUsuario}&nombreUsuario=${nombreUsuario}&correoUsuario=${correoUsuario}&cedulaUsuario=${cedulaUsuario}&fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (respuesta.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
            }).then(() => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        if (respuesta.status != REACT_APP_CODIGO_RESPUESTA_200) {
            try {
                var respuestaJson = await respuesta.json();
                if (respuestaJson && respuestaJson.mensaje) {
                    swal({
                        title: fondoSeleccionado.nombreFondo,
                        text: respuestaJson.mensaje,
                        icon: "error",
                    });
                } else {
                    swal({
                        title: fondoSeleccionado.nombreFondo,
                        text: "Ha ocurrido un error al obtener los logs, no se obtuvo respuesta del servidor",
                        icon: "error",
                    });
                }
                return;
            } catch (e) {
                swal({
                    title: fondoSeleccionado.nombreFondo,
                    text: "Ha ocurrido un error al obtener los logs",
                    icon: "error",
                });
                throw e;
            }
        }
        return respuesta.json();
    } catch (error) {
        throw error;
    }
};

export const obtenerLogsRegistroConsulta = async (nombreEntidad, idUsuario, nombreUsuario, cedulaUsuario, correoUsuario, fechaDesde, fechaHasta) => {
    try {
        var idFondo = localStorage.getItem("idFondo");
        const respuesta = await fetch(
            `api/ObtenerLogsRegistroConsulta?fondo=${idFondo}&entidad=${nombreEntidad}&idUsuario=${idUsuario}&nombreUsuario=${nombreUsuario}&correoUsuario=${correoUsuario}&cedulaUsuario=${cedulaUsuario}&fechaDesde=${fechaDesde}&fechaHasta=${fechaHasta}`,
            {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (respuesta.status === REACT_APP_CODIGO_RESPUESTA_401) {
            swal({
                title: "¡Sesión Expiró!",
                text: "Por favor vuelva a iniciar sesión",
                icon: "warning",
            }).then(() => {
                BorrarLocalStorage();
                window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
            });
            return;
        }
        if (respuesta.status != REACT_APP_CODIGO_RESPUESTA_200) {
            try {
                var respuestaJson = await respuesta.json();
                if (respuestaJson && respuestaJson.mensaje) {
                    swal({
                        title: fondoSeleccionado.nombreFondo,
                        text: respuestaJson.mensaje,
                        icon: "error",
                    });
                } else {
                    swal({
                        title: fondoSeleccionado.nombreFondo,
                        text: "Ha ocurrido un error al obtener los logs, no se obtuvo respuesta del servidor",
                        icon: "error",
                    });
                }
                return;
            } catch (e) {
                swal({
                    title: fondoSeleccionado.nombreFondo,
                    text: "Ha ocurrido un error al obtener los logs",
                    icon: "error",
                });
                throw e;
            }
        }
        return respuesta.json();
    } catch (error) {
        throw error;
    }
};