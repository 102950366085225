import React from 'react'
import EditarParametroComponent from '../../Components/Parametro/EditarParametro'

const EditarParametroView = () => {
  return (
      <div>
          <EditarParametroComponent />
      </div>
  )
}

export default EditarParametroView  